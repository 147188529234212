import React from "react";
import { buttonTypes, buttonIcon } from "../../../utils/buttonsUtil";

const MultiFile = React.forwardRef(({ files = [], onChange, handleRemove, ...props}, ref) => {
    const renderFileNames = () => (
        <>
            {
                files.length > 0 && (
                    <div className="custom-multifile-names-container">
                        {files.map((e, index) => (
                            <span className="custom-multifile-names" key={index}>
                                {e.name}
                                <button
                                    className={`custom-multifile-remove btn btn-sm ${buttonTypes.Danger} ${buttonIcon.Trash}`}
                                    onClick={() => handleRemove(index)}
                                />
                            </span>
                        ))}
                    </div>
                )
            }
        </>
    )

    return (
        <div className="d-flex flex-column">
            <label htmlFor="custom-multifile-input" className="custom-multifile-label">Seleccionar archivos</label>
            <input
                {...props}
                id='custom-multifile-input'
                className="custom-multifile-input"
                type="file" 
                multiple
                onChange={onChange}
                ref={ref}
            />
            {renderFileNames()}
        </div>
    )
});

export default MultiFile;