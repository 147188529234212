import React, { forwardRef, useEffect, useState, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import moment from 'moment-timezone';
import { Button } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';

import Sidebar from '../../components/Navigation/Sidebar';
import Topbar from '../../components/Navigation/Topbar';
import Grid from '../../components/Grid';
import { PermissionContext } from '../../hooks/PermissionContext';
import { LabelByState } from '../../utils/functionsUtils';
import Select from 'react-select'
import { getStatusBadgeByCode } from '../../utils/StatusUtils';

const Swal = require('sweetalert2');

const OrderAssigned = ({ reduxGetOrdersAssigned, ordersAssigned, reduxGetCompanies, companies }) => {

  const { hasPermission } = useContext(PermissionContext);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
    setValue,
    getValues,
  } = useForm();
  const {
    register: registerSearch,
    handleSubmit: handleSubmitSearch,
    formState: { errors: errorsSearch },
    reset: resetSearch,
    setValue: setValueSearch,
    getValues: getValuesSearch,
  } = useForm({
    defaultValues: {
      idCompany: '',
      idOperator: '',
      date: null,
    },
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(10);
  const [search, setSearch] = useState('');
  const [startDate2, setStartDate2] = useState(null);
  const DatePickerCustom2 = forwardRef(({ value, onClick, onChange }, ref) => (
    <>
      <input
        className={`form-control`}
        onClick={onClick}
        onChange={onChange}
        ref={ref}
        value={value}
        autoComplete='Off'
        {...registerSearch('date')}
      />
    </>
  ));

  useEffect(() => {
    reduxGetCompanies({
      page: 1,
      offset: offset,
      search: '',
    });
    reduxGetOrdersAssigned({
      page: 1,
      offset: offset,
      search: '',
    });
  }, []);

  const columns = [
    {
      title: '#',
      render: (rowData) => {
        return <span>{rowData.idOrder}</span>;
      },
      field: 'idOrder',
      searchable: true,
    },
    {
      title: 'Cliente',
      render: (rowData) => {
        return (
          <span>
            {rowData.customer.firstName} {rowData.customer.lastName}
          </span>
        );
      },
      field: 'customer.firstName-lastName',
      searchable: true,
    },
    {
      title: 'Compañia',
      render: (rowData) => {
        return <span>{rowData.company ? rowData.company.description : ''}</span>;
      },
    },
    {
      title: 'Valor a Recaudar',
      render: (rowData) => {
        return <span>{rowData.ammount}</span>;
      },
    },
    {
      title: 'Guía',
      render: (rowData) => {
        return <span>{rowData.address.trackingId}</span>;
      },
      field: 'address.trackingId',
      searchable: true,
    },
    ...(hasPermission('feature:show-recipient-address')
      ? [
        {
          title: 'Dirección Destinatario',
          render: (rowData) => {
            return <span>{rowData.address.address}</span>;
          },
          field: 'address.address',
          searchable: true,
        },
      ]
      : []),
    {
      title: 'Ciudad',
      render: (rowData) => {
        return <span>{rowData.address.city ? rowData.address.city.description : ''}</span>;
      },
      field: 'address.city.description',
      searchable: true,
    },
    {
      title: 'Correo',
      render: (rowData) => {
        return <span>{rowData.email}</span>;
      },
      field: 'email',
      searchable: true,
    },
    {
      title: 'Fecha',
      render: (rowData) => {
        return <span>{moment(rowData.date).tz('America/Bogota').format('DD-MM-YYYY')}</span>;
      },
    },
    {
      title: 'ID externo',
      render: (rowData) => {
        return <span>{rowData.externalId}</span>;
      },
      field: 'externalId',
      searchable: true,
    },
    {
      title: 'Operador',
      render: (rowData) => {
        return <span>{rowData.operator ? rowData.operator.description : ''}</span>;
      },
    },
    {
      title: 'Estado',
      render: (rowData) => getStatusBadgeByCode(rowData.address.newState.code),
      field: 'address.state',
      searchable: true,
    },
  ];

  useEffect(() => {
    reduxGetOrdersAssigned({
      page: currentPage,
      offset: offset,
      search: search,
    });
  }, [currentPage]);

  const onSubmitSearch = (data) => {
    reduxGetOrdersAssigned({
      ...data,
      page: 1,
      offset: offset,
      search: '',
    });
  };

  const handledChange2 = (date) => {
    setStartDate2(date);
    setValueSearch('date', moment(date).tz('America/Bogota').format('MM-DD-YYYY'));
  };

  return (
    <div>
      <div id='wrapper'>
        <Sidebar />
        <div
          id='content-wrapper'
          className='d-flex flex-column'>
          <div id='content'>
            <Topbar />
            <div className='container-fluid'>
              <div className='d-sm-flex align-items-center mb-1'>
                <h1 className='h3 mb-0 text-gray-800 mr-3'>Órdenes asignadas</h1>
              </div>
              <p className='mb-4'>Módulo de órdenes asignadas</p>
              <form onSubmit={handleSubmitSearch(onSubmitSearch)}>
                <div className='row'>
                  <div className='col s6 m2'>
                    <div className='form-group'>
                      <label
                        htmlFor='date'
                        className='form-label'>
                        Fecha
                      </label>
                      <div>
                        <DatePicker
                          selected={startDate2}
                          onChange={(date) => handledChange2(date)}
                          dateFormat='MM-dd-yyyy'
                          customInput={<DatePickerCustom2 />}
                          maxDate={new Date()}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='col s6 m2'>
                    <div className='form-group'>
                      <label
                        htmlFor='idOrder'
                        className='form-label'>
                        ID orden
                      </label>
                      <input
                        id='idOrder'
                        type='number'
                        className={`form-control form-control-user ${errorsSearch.idOrder && 'is-invalid'}`}
                        {...registerSearch('idOrder')}
                      />
                    </div>
                  </div>
                  <div className='col s6 m4'>
                    <div className='form-group'>
                      <label
                        htmlFor='idCompany'
                        className='form-label'>
                        Compañia
                      </label>
                      <Select
                        isClearable={true}
                        {...registerSearch('idCompany')}
                        id='idCompany'
                        onChange={(value) => setValueSearch('idCompany', value?.value || '')}
                        options=
                        {companies &&
                          Object.keys(companies).length > 0 &&
                          companies.items
                            .filter((f) => f.isActive == true)
                            .map((ele) => ({
                              value: ele.idCompany,
                              label: ele.description,
                            }))
                        }
                      />
                    </div>
                  </div>
                  <div className='col s6 m4'>
                    <div className='form-group'>
                      <label
                        htmlFor='idOperator'
                        className='form-label'>
                        Operador
                      </label>
                      <Select
                        isClearable={true}
                        {...registerSearch('idOperator')}
                        id='idOperator'
                        onChange={(value) => setValueSearch('idOperator', value?.value || '')}
                        options=
                        {companies &&
                          Object.keys(companies).length > 0 &&
                          companies.items
                            .filter((f) => f.isActive == true)
                            .map((ele) => ({
                              value: ele.idCompany,
                              label: ele.description,
                            }))
                        }
                      />
                    </div>
                  </div>
                  <div className='col s12 m1'>
                    <Button
                      variant='primary'
                      style={{ marginTop: '32px' }}
                      type='submit'
                      id='btnSearch'>
                      Buscar
                    </Button>
                  </div>
                </div>
                <div className='card shadow mb-4'>
                  <div className='card-header py-3'>
                    <h6 className='m-0 font-weight-bold text-primary'>Listado de Órdenes asignadas</h6>
                  </div>
                  <div className='card-body'>
                    <Grid
                      cols={columns}
                      data={ordersAssigned && Object.keys(ordersAssigned).length > 0 ? ordersAssigned.items : []}
                      page={
                        ordersAssigned && Object.keys(ordersAssigned).length > 0
                          ? Number(ordersAssigned.page)
                          : currentPage
                      }
                      pages={
                        ordersAssigned && Object.keys(ordersAssigned).length > 0 ? Number(ordersAssigned.totalPages) : 1
                      }
                      total={ordersAssigned && ordersAssigned.hasOwnProperty('total') ? ordersAssigned.total : 0}
                      offset={offset}
                      onChangePage={(page) => setCurrentPage(page)}
                      onChangeRange={(value) => {
                        setOffset(value);
                        reduxGetOrdersAssigned({
                          page: 1,
                          offset: value,
                          search: search,
                        });
                      }}
                      defaultValue={search}
                      onChangeSearch={(value) => {
                        setSearch(value);
                        reduxGetOrdersAssigned({
                          page: 1,
                          offset: offset,
                          search: value,
                        });
                      }}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    ordersAssigned: state.orderState.ordersAssigned,
    companies: state.companyState.companies,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    reduxGetOrdersAssigned: (payload) =>
      dispatch({
        type: 'FETCH_ORDERSASSIGNED_REQUEST',
        value: payload,
      }),
    reduxGetCompanies: (payload) =>
      dispatch({
        type: 'FETCH_COMPANIES_REQUEST',
        value: payload,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderAssigned);
