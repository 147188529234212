import axios from 'axios';
import Swal from 'sweetalert2';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

const axiosInstance2 = axios.create({
  baseURL: process.env.REACT_APP_API_URL
}); 

function parseJSON(response) {
  if (response.status === 204 || response.status === 205) {
    return null;
  }
  return response.json();
}

function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  if (response.status === 401 ) {
    window.localStorage.removeItem('token');
    return;
  }

  if (response.status === 422) {
    Swal.fire({
      title: "Session Closed",
      text: "Su sesión ha expirado. Por favor, vuelva a iniciar sesión.",
      icon: "error",
      confirmButtonColor: "#3085d6",
      confirmButtonText: "Iniciar sesión"
    }).then((result) => {
      if (result.isConfirmed) {
        window.localStorage.removeItem('token');
        window.location.reload();
      }
    });
  }
  
  if(response.status === 404) {
    return response;
  }
  const error = new Error(response.statusText);
  error.response = response;
  throw error;
}

export default function request(url, options) {
  return fetch(url, options).then(checkStatus).then(parseJSON);
}

axiosInstance.interceptors.response.use(response => {
  
  const contentType = response.headers['content-type'];
  if (contentType && contentType.includes('text/csv')) {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'errors_detail'+ new Date().toISOString() +'.csv'); 
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    return Promise.resolve(null);
  }

  return response;
}, error => {
  return Promise.reject(error);
});

function checkStatusAxios(error) {
  if (!error.response) {
    throw error;
  }

  const { status, statusText, data } = error.response;

  if (status === 401) {
    window.localStorage.removeItem('token');
  } else {
    const newError = new Error(statusText);
    newError.response = data;
    throw newError;
  }
}
function parseJSONAxios(response) {
  if (response?.status === 204 || response?.status === 205) {
    return null;
  }
  return response?.data;
}
export function requestAxios(url, options) {
  return axiosInstance({ url, headers: options.headers, method: options.method, data: options.body  })
    .then(parseJSONAxios)
    .catch(checkStatusAxios);
}

axiosInstance2.interceptors.response.use(response => {
  
  const contentType = response.headers['content-type'];
  if (contentType && contentType.includes('text/csv')) {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'Example_Template'+'.csv'); 
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    return Promise.resolve(null);
  }

  return response;
}, error => {
  return Promise.reject(error);
});


export function requestAxiosDownload(url, options) {
  return axiosInstance2({ url, headers: options.headers, method: options.method, responseType: 'blob' })
}