import { call, put, takeLatest } from "redux-saga/effects";
import request from "../../utils/request";

function* fetchAll(payload) {
  try {
    yield put({
      type: "SHOW_LOADING",
    });

    yield put({
      type: "FETCH_ALERT_SOURCES_REQUESTING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/alertSources?page=${payload.value.page}&offset=${payload.value.offset}&search=${payload.value.search}`;

    const headers = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "FETCH_ALERT_SOURCES_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Load failed",
        message: "Loading Alert Sources",
      },
    });
    yield put({
      type: "FETCH_ALERT_SOURCES_ERROR"
    });
  }
  finally {
    yield put({
      type: "HIDE_LOADING",
    });
  }
}

function* fetchOne(payload) {
  try {
    yield put({
      type: "SHOW_LOADING",
    });

    yield put({
      type: "FETCH_ONE_ALERT_SOURCE_REQUESTING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/alertSources/${payload.value.idAlertSource}`;

    const headers = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "FETCH_ONE_ALERT_SOURCE_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Load failed",
        message: "Loading Alert source",
      },
    });
    yield put({
      type: "FETCH_ONE_ALERT_SOURCE_ERROR"
    });
  }
  finally {
    yield put({
      type: "HIDE_LOADING",
    });
  }
}

function* create(payload) {
  try {
    yield put({
      type: "SHOW_LOADING",
    });

    yield put({
      type: "CREATE_ALERT_SOURCE_REQUESTING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/alertSources`;

    const headers = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload.value),
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "CREATE_ALERT_SOURCE_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Create failed",
        message: "Alert source was not created",
      },
    });
    yield put({
      type: "CREATE_ALERT_SOURCE_ERROR"
    });
  }
  finally {
    yield put({
      type: "HIDE_LOADING",
    });
  }
}

function* update(payload) {
  try {
    yield put({
      type: "SHOW_LOADING",
    });

    yield put({
      type: "UPDATE_ALERT_SOURCE_REQUESTING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/alertSources`;

    const headers = {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload.value),
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "UPDATE_ALERT_SOURCE_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Update failed",
        message: "Alerts source was not updated",
      },
    });
    yield put({
      type: "UPDATE_ALERT_SOURCE_ERROR"
    });
  }
  finally {
    yield put({
      type: "HIDE_LOADING",
    });
  }
}

export function* watchAlertSources() {
  yield takeLatest("FETCH_ALERT_SOURCES_REQUEST", fetchAll);
  yield takeLatest("FETCH_ONE_ALERT_SOURCE_REQUEST", fetchOne);
  yield takeLatest("CREATE_ALERT_SOURCE_REQUEST", create);
  yield takeLatest("UPDATE_ALERT_SOURCE_REQUEST", update);
}
