import React, {forwardRef, useCallback, useEffect, useMemo, useState} from "react";
import Grid from "../../../components/Grid";
import moment from "moment-timezone";
import momentCustom from "../../../utils/momentCustomInstance";
moment.tz(moment(), Intl.DateTimeFormat().resolvedOptions().timeZone)
const CartaPorteTable = ({data, setFilter, dataFormFilter}) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [offset, setOffset] = useState(10);
    const [search, setSearch] = useState("");

    const renderBadgeSuccessStatus = useCallback((success) =>
        success
            ? <span className="badge badge-pill badge-success">Enviado</span>
            : <span className="badge badge-pill badge-danger">Fallido</span>
    ,[data])

    const columns = useMemo(() =>  [
        {
            title: "ID",
            render: (rowData) => {
                return <span>{rowData.logId}</span>;
            },
            field: 'logId',
            searchable: true
        },
        {
            title: "Estado",
            render: (rowData) => {
                return renderBadgeSuccessStatus(rowData.success);
            },
            field: 'success',
            searchable: false
        },
        {
            title: "Fecha/Hora",
            render: (rowData) => {
                return  momentCustom(rowData.requestTimestamp).format('DD-MM-YYYY, h:mm:ss a');
            },
            field: 'requestTimestamp',
            searchable: false
        },
        {
            title: "Guía",
            render: (rowData) => {
                return <span>{rowData.tracking_id}</span>;
            },
            field: 'tracking_id',
            searchable: true
        },
        {
            title: "Mensaje",
            render: (rowData) => {
                return <span>{rowData.message}</span>;
            },
            field: 'slug',
        },
        {
            title: "Recurso",
            render: (rowData) => {
                return <span>{rowData.resource_name}</span>;
            },
            field: 'resource_name',
            searchable: true
        }
    ], [data]);

    const handleChangeRange = (value) => {
        setOffset(value)
        setFilter({
            page: 1,
            offset: value,
            search: search,
            ...dataFormFilter
        });
    }

    const handleChangeSearch = (value) => {
        setSearch(value);
        debugger
        setFilter({
            page: 1,
            offset: offset,
            search: value,
            ...dataFormFilter
        })
    };

    useEffect(() => {
        setFilter({
            page: currentPage,
            offset: offset,
            search: search,
            ...dataFormFilter
        })

    },[currentPage])

    return (
        <>
            <Grid
                data={
                    data && Object.keys(data).length > 0 ? data.items : []
                }
                cols={columns}
                actionsButton={true}
                offset={offset}
                page={
                    data && Object.keys(data).length > 0
                    ? Number(data.page)
                        : currentPage
                }
                pages={
                    data && Object.keys(data).length > 0
                        ? Number(data.totalPages)
                        : 0
                }
                defaultValue={search}
                onChangeRange={handleChangeRange}
                onChangePage={(page) => setCurrentPage(page)}
                onChangeSearch={handleChangeSearch}
                total={
                    data && data.hasOwnProperty("total") ? data.total : 0
                }
            />
        </>
    );
}

export default CartaPorteTable