import React, { useState, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { connect } from "react-redux";
import { Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";

import Sidebar from "../../components/Navigation/Sidebar";
import Topbar from "../../components/Navigation/Topbar";
import Grid from "../../components/Grid";
import { Show } from "../../hooks/Show";

import {
  capitalizeFirstLetter,
  createWordFromPhrase,
  onlyLetters
} from "../../utils/functionsUtils";

const Swal = require("sweetalert2");

const Statuses = ({
  statuses,
  rowUpdated,
  reduxGetStatuses,
  reduxPostStatus,
  reduxUpdateStatus,
  reduxResetStatusForm,
  errorsCreateStatus,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(10);
  const [search, setSearch] = useState("");
  const [showModal, setShowModal] = useState(false);

  const methods = useForm({
    defaultValues: {
      code: "",
      name: "",
    },
  });

  const {
    register,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = methods;

  const handleChange = (text) => {
    const textValid = onlyLetters(text)
    setValue("name", capitalizeFirstLetter(textValid))
    setValue("code", createWordFromPhrase(textValid, 6).toUpperCase())
  }

  const onUpdate = (data) => {
    reduxUpdateStatus({
      isActive: !data.isActive,
      id: data.id,
    });

    reset();
    setShowModal(false);

    let filters = {
      page: currentPage,
      offset: offset,
      search: search,
    };
    reduxGetStatuses(filters);
  };

  const onSubmit = (data) => {
    if (data.name.trim() !== "" && data.code !== "") {
      reduxPostStatus({
        code: data.code.toUpperCase(),
        name: capitalizeFirstLetter(data.name),
      });

      reset();
      reduxResetStatusForm();
      setShowModal(false);

      let filters = {
        page: currentPage,
        offset: offset,
        search: search,
      };
      reduxGetStatuses(filters);
    } else {
      Swal.fire({
        title: "Error",
        text: "los campos no pueden estar vacíos",
        icon: "error",
      });
    }
  };

  const handleShow = () => {
    reduxResetStatusForm();
    reset();
    setShowModal(true);
  };

  useEffect(() => {
    if (errorsCreateStatus) {
      reduxResetStatusForm();
      Swal.fire("Proceso no exitoso!", `El estado ya existe`, "error");
    }
  }, [errorsCreateStatus]);

  useEffect(() => {
    reduxGetStatuses({
      page: 1,
      offset: offset,
      search: "",
    });
  }, [offset]);

  useEffect(() => {
    let filters = {
      page: currentPage,
      offset: offset,
      search: search,
    };
    reduxGetStatuses(filters);
  }, [currentPage]);

  useEffect(() => {
    let filters = {
      page: currentPage,
      offset: offset,
      search: search,
    };
    reduxGetStatuses(filters);
  }, [rowUpdated]);

  const columns = [
    {
      title: "#",
      render: (rowData) => {
        return <span>{rowData.idStatus}</span>;
      },
      field: "idStatus",
      searchable: false,
    },
    {
      title: "Código",
      render: (rowData) => {
        return <span>{rowData.code}</span>;
      },
      field: "code",
      searchable: true,
    },
    {
      title: "Estado",
      render: (rowData) => {
        return <span>{rowData.name}</span>;
      },
      field: "name",
      searchable: true,
    },
    {
      title: "Opciones",
      render: (rowData) => {
        return (
          <>
            {rowData.isActive ? (
              <>
                <Show when="feature:manage-status">
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 50, hide: 50 }}
                    overlay={renderTooltip("Desactivar")}
                  >
                    <button
                      className="btn btn-sm btn-danger btn-circle"
                      type="button"
                      onClick={(e) => onUpdate(rowData)}
                    >
                      <i className="fas fa-times-circle fa-xs"></i>
                    </button>
                  </OverlayTrigger>
                </Show>
              </>
            ) : (
              <Show when="feature:manage-status">
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 50, hide: 50 }}
                  overlay={renderTooltip("Activar")}
                >
                  <button
                    className="btn btn-sm btn-primary btn-circle"
                    type="button"
                    onClick={(e) => onUpdate(rowData)}
                  >
                    <i className="fas fa-check-circle fa-xs"></i>
                  </button>
                </OverlayTrigger>
              </Show>
            )}
          </>
        );
      },
    },
  ];

  const handleClose = () => {
    setValue("code", "")
    setValue("name", "")
    setShowModal(false);
  };

  const renderTooltip = (title) => (
    <Tooltip id="button-tooltip">{title}</Tooltip>
  );

  return (
    <div>
      <div id="wrapper">
        <Sidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Topbar />
            <div className="container-fluid">
              <div className="d-sm-flex align-items-center mb-1">
                <h1 className="h3 mb-0 text-gray-800 mr-3">Estados</h1>
                <div>
                  <Show when="feature:create-address">
                    <OverlayTrigger
                      placement="top"
                      delay={{ show: 50, hide: 50 }}
                      overlay={renderTooltip("Crear Estado")}
                    >
                      <button
                        className="btn btn-primary btn-circle"
                        type="button"
                        onClick={handleShow}
                      >
                        <i className="fas fa-plus fa-sm"></i>
                      </button>
                    </OverlayTrigger>
                  </Show>
                </div>
              </div>
              <p className="mb-4">Módulo de Estados</p>
              <div className="card shadow mb-4">
                <div className="card-header py-3">
                  <h6 className="m-0 font-weight-bold text-primary">
                    Listado de Estados
                  </h6>
                </div>
                <div className="card-body">
                  <Grid
                    cols={columns}
                    data={
                      statuses && Object.keys(statuses).length > 0
                        ? statuses.items
                        : []
                    }
                    total={
                      statuses && statuses.hasOwnProperty("total")
                        ? statuses.total
                        : 0
                    }
                    page={
                      statuses && Object.keys(statuses).length > 0
                        ? Number(statuses.page)
                        : currentPage
                    }
                    pages={
                      statuses && Object.keys(statuses).length > 0
                        ? Number(statuses.totalPages)
                        : 0
                    }
                    offset={offset}
                    onChangePage={(page) => setCurrentPage(page)}
                    onChangeRange={(value) => {
                      setOffset(value);
                    }}
                    defaultValue={search}
                    onChangeSearch={(value) => {
                      setSearch(value);
                      let filters = {
                        page: 1,
                        offset: offset,
                        search: value,
                      };
                      reduxGetStatuses(filters);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Modal
          show={showModal}
          onHide={handleClose}
          onEscapeKeyDown={handleClose}
          backdrop="static"
          keyboard={true}
          size="md"
        >
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Modal.Header closeButton>
                <Modal.Title>Nuevo Estado</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="form-group col-md-12">
                    <label htmlFor="name" className="form-label">
                      Estado
                    </label>
                    <input
                      id="name"
                      type="text"
                      maxLength={40}
                      className={`form-control form-control-user`}
                      {...register("name", {
                        maxLength: {
                          value: 40,
                          message: "El límite de caracteres es de 40",
                        },
                        required: {
                          value: true,
                          message: "El campo Nombre no puede estar vacío",
                        },
                        pattern: {
                          value: /^[a-zA-Z ]+$/,
                          message: 'Por favor ingresa solo letras',
                        },
                      })}
                      onChange={(e) => handleChange(e.target.value)}
                      aria-invalid={errors.name ? "true" : "false"}
                    />
                    {errors.name && (
                      <span class="text-danger fw-bold">{errors.name?.message}</span>
                    )}
                  </div>
                  <div className="form-group col-md-12">
                    <label htmlFor="code" className="form-label">
                      Código
                    </label>
                    <input
                      id="code"
                      type="text"
                      className={`form-control form-control-user`}
                      maxLength={6}
                      {...register("code", {
                        maxLength: {
                          value: 6,
                          message: "El límite de caracteres es de 6",
                        },
                        required: {
                          value: true,
                          message: "El campo Código no puede estar vacío",
                        },
                        pattern: {
                          value: /^[a-zA-Z0-9]+$/,
                          message: 'Por favor ingresa solo letras y números',
                        },
                      })}
                    />
                    {errors.code && (
                      <span class="text-danger fw-bold">{errors.code?.message}</span>
                    )}
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Cancelar
                </Button>
                <Button variant="primary" type="submit">
                  Guardar
                </Button>
              </Modal.Footer>
            </form>
          </FormProvider>
        </Modal>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    errorsCreateStatus: state.statusesState.errorsCreateStatus,
    rowUpdated: state.statusesState.rowUpdated,
    rowEdited: state.statusesState.rowEdited,
    statuses: state.statusesState.statuses,
    errorFetchStatuses: state.statusesState.errorFetchStatuses,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    reduxGetStatuses: (payload) =>
      dispatch({
        type: "FETCH_NEW_STATUS_REQUEST",
        value: payload,
      }),
    reduxPostStatus: (payload) =>
      dispatch({
        type: "CREATE_NEW_STATUS_REQUEST",
        value: payload,
      }),
    reduxUpdateStatus: (payload) =>
      dispatch({
        type: "UPDATE_NEW_STATUS_REQUEST",
        value: payload,
      }),
    reduxResetStatusForm: () =>
      dispatch({
        type: "RESET_NEW_STATUS_FORM",
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Statuses);
