import React, {useState, useEffect, useContext} from "react";
import { Nav, Tab } from "react-bootstrap";
import Swal from "sweetalert2";

import { Show } from "../../hooks/Show";
import { PermissionContext } from "../../hooks/PermissionContext";

import ContentWrapper from "../Auditoria/cartaPorte/../../../components/Common/ContentWrapper";
import FilterComponent from "./FilterComponent";
import OrdersPendingTableComponent from "./OrdersPendingTableComponent";
import BlockedOrdersTableComponent from "./BlockedOrdersTableComponent";

const OrdersLockedComponent = ({ 
    pendingOrders, 
    totalPendingOrders,
    lockedOrders, 
    successfulFetchConfirmOrder,
    successUploadOrdersToConfirm,
    successfulFetchDeleteOrder,
    reduxGetPendingOrders,
    reduxGetLockedOrders,
    reduxConfirmOrder, 
    reduxConfirmAllOrders, 
    reduxCountOrdersPending, 
    reduxDeleteOrdersPending, 
    startDate, 
    endDate ,
    setEndDateFilter, 
    setStartDateFilter
}) => {
    const { hasPermission } = useContext(PermissionContext);
    const [currentPage, setCurrentPage] = useState(1);
    const [offset, setOffset] = useState(10);
    const [search, setSearch] = useState("");
    let cleanDateFilter = false;

    useEffect(() => {
        setStartDateFilter(null)
        setEndDateFilter(null)
        cleanDateFilter = true;
        if(hasPermission('feature:read-orders-locked-pending')){
            reduxCountOrdersPending();
            searchByPendingByFilter();
        }
    }, []);
    
    useEffect(() => {
        if (successUploadOrdersToConfirm) {
            searchByPendingByFilter();
            reduxCountOrdersPending();
        }
    }, [successUploadOrdersToConfirm]);

    const handleConfirm = (order) => {
        Swal.fire({
            title: 'Confirmación',
            text: 'Está seguro de bloquear la órden seleccionada?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#21b5ea',
            cancelButtonColor: '#858796',
            confirmButtonText: 'Bloquear',
        }).then((result) => {
            if (result.isConfirmed) {
                reduxConfirmOrder({ 
                    order_locked_id: order.order_locked_id,
                    page: currentPage,
                    offset: offset,
                    search: search
                })
            }
        });
    }

    const handleConfirmAll = (idsToConfirm) => {
        Swal.fire({
            title: 'Confirmación',
            text: 'Está seguro de bloquear las órdenes seleccionadas?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#21b5ea',
            cancelButtonColor: '#858796',
            confirmButtonText: 'Bloquear',
        }).then((result) => {
            if (result.isConfirmed) {
                reduxConfirmAllOrders({
                    idsToConfirm,
                    page: currentPage,
                    offset: offset,
                    search: search
                })
            }
        });
    };

    const searchByPendingByFilter = () => {
        let filters = {
            page: currentPage,
            offset: offset,
            search: search,
        };
        if (startDate && endDate && !cleanDateFilter) {
            filters = {
                ...filters,
                startDate: startDate,
                endDate: endDate,
            };
        }
        reduxGetPendingOrders(filters);
        cleanDateFilter = false;
    }

    const searchByLockedByFilter = () => {
        let filters = {
            page: currentPage,
            offset: offset,
            search: search,
        };
        if (startDate && endDate && !cleanDateFilter) {
            filters = {
                ...filters,
                startDate: startDate,
                endDate: endDate,
            };
        }
        reduxGetLockedOrders(filters);
        cleanDateFilter = false;
    }

    const handleSelect = (tabSelected) => {
        setStartDateFilter(null)
        setEndDateFilter(null)
        cleanDateFilter = true;
        if(tabSelected === 'pendingToLock'){
            reduxCountOrdersPending();
            searchByPendingByFilter();
        } else if(tabSelected === 'locked'){
            searchByLockedByFilter();
        }
    }

    return (
        <>
            <ContentWrapper>
                <div className="d-sm-flex align-items-center mb-1">
                    <h1 className="h3 mb-0 text-gray-800 mr-3">Bloqueo de Ordenes</h1>
                </div>
                <p className="mb-4">Módulo de Ordenes Bloqueadas</p>
                <Tab.Container defaultActiveKey="pendingToLock" mountOnEnter={true} onSelect={(k) => handleSelect(k)}>
                    <Nav variant="tabs" className=" d-flex flex-nowrap flex-row mt-2" >
                        <Show when='feature:read-orders-locked-pending'>
                            <Nav.Item className='d-flex flex-column'>
                                <Nav.Link eventKey="pendingToLock">
                                    Pendientes <span class="badge rounded-pill bg-danger text-white">{totalPendingOrders}</span>
                                </Nav.Link>
                            </Nav.Item>
                        </Show>
                        <Show when='feature:tab-orders-locked'>
                            <Nav.Item className='d-flex flex-column'>
                                <Nav.Link eventKey="locked">
                                    Bloqueadas
                                </Nav.Link>
                            </Nav.Item>
                        </Show>
                    </Nav>
                    <Show when='feature:read-orders-locked-pending'>
                        <Tab.Content>
                            <Tab.Pane eventKey="pendingToLock">
                                <div className='card shadow mb-4'>
                                    <div className='card-header py-1'>
                                        <h6 className='m-0 font-weight-bold text-primary'>Listado de Ordenes</h6>
                                    </div>
                                    <div className='card-body'>
                                        <FilterComponent 
                                            onSubmit={searchByPendingByFilter} 
                                            renderUploadBtn={true}/>
                                        <OrdersPendingTableComponent 
                                            data={pendingOrders}
                                            paginate={{
                                                currentPage,
                                                offset,
                                                search,
                                                setCurrentPage,
                                                setOffset,
                                                setSearch,
                                            }}
                                            filterFields={{
                                                startDate,
                                                endDate
                                            }}
                                            reduxGetOrders={reduxGetPendingOrders}
                                            handleConfirm={handleConfirm}
                                            handleConfirmAllOrders={handleConfirmAll}
                                            handleDelete={reduxDeleteOrdersPending}
                                            searchRedux={searchByPendingByFilter}
                                            successfulFetchDeleteOrder={successfulFetchDeleteOrder}
                                        />
                                    </div>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="locked">
                                <div className='card shadow mb-4'>
                                    <div className='card-header py-1'>
                                        <h6 className='m-0 font-weight-bold text-primary'>Listado de Ordenes</h6>
                                    </div>
                                    <div className='card-body'>
                                        <FilterComponent onSubmit={searchByLockedByFilter} renderUploadBtn={false} />
                                        <BlockedOrdersTableComponent 
                                            data={lockedOrders}
                                            paginate={{
                                                currentPage,
                                                offset,
                                                search,
                                                setCurrentPage,
                                                setOffset,
                                                setSearch,
                                            }}
                                            filterFields={{
                                                startDate,
                                                endDate
                                            }}
                                            reduxGetOrders={reduxGetLockedOrders}
                                            searchRedux={searchByLockedByFilter}
                                        />
                                    </div>
                                </div>
                            </Tab.Pane>
                        </Tab.Content>
                    </Show>
                </Tab.Container>
            </ContentWrapper>
        </>
    )
}

export default OrdersLockedComponent;