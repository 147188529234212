const initialState = {
    requestingFetchOrdersToAssignCourier: false,
    successfulFetchOrdersToAssignCourier: false,
    errorFetchOrdersToAssignCourier: false,
    orders: {}
  };
  
  const dspOrderAssignCourierReducer = (state = initialState, action) => {
    switch (action.type) {
      case "FETCH_DSP_ORDERS_ASSIGN_COURIER_REQUESTING":
        return {
          ...state,
          requestingFetchOrdersToAssignCourier: true,
          successfulFetchOrdersToAssignCourier: false,
          errorFetchOrdersToAssignCourier: false,
        };
      case "FETCH_DSP_ORDER_ASSIGN_COURIER_SUCCESS":
        return {
          ...state,
          requestingFetchOrdersToAssignCourier: false,
          successfulFetchOrdersToAssignCourier: true,
          errorFetchOrdersToAssignCourier: false,
          dspOrdersToAssignCourier: action.value,
        };
      case "FETCH_DSP_ORDER_ASSIGN_COURIER_ERROR":
        return {
          ...state,
          requestingFetchOrdersToAssignCourier: true,
          successfulFetchOrdersToAssignCourier: false,
          errorFetchOrdersToAssignCourier: false,
        };      
      case "RESET":
        return initialState;
      default:
        return state;
    }
  };
  
  export default dspOrderAssignCourierReducer;
  