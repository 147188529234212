import {call, put, takeLatest} from "redux-saga/effects";
import request from "../../utils/request";
import {addressInsideTypeActions} from "../reducers/addressInsideTypeReducer";

function* getAllAddressInsideType(payload) {
    try {
        yield put({
            type: addressInsideTypeActions.FECTCHIN_ALL_ADDRESSINSIDETYPE,
        });

        yield put({
            type: "SHOW_LOADING",
        });

        const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/addressInsideTypes?page=${payload.value.page}&search=${payload.value.search}&offset=${payload.value.offset}${payload.value.isActive ? '&isActive=' + payload.value.isActive: '' }`;
        const headers = {
            method: "GET",
            headers: new Headers({
                Authorization: `Bearer ${window.localStorage.getItem("token")}`,
                "Content-Type": "application/json",
            }),
        };
        const response = yield call(request, requestURL, headers);

        yield put({
            type: addressInsideTypeActions.FETCH_ADDRESSINSIDETYPE_SUCCESS,
            value: response,
        });
        yield put({
            type: "HIDE_LOADING",
        });
    } catch (error) {
        yield put({
            type: "HIDE_LOADING",
          });
        console.log(error);

    }
}

function* postAddressInsideType(payload) {
    try {
        yield put({type: addressInsideTypeActions.CREATE_ADDRESSINSIDETYPE_REQUESTING});

        const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/addressInsideTypes`;

        const headers = {
            method: "POST",
            headers: new Headers({
                Authorization: `Bearer ${window.localStorage.getItem("token")}`,
                "Content-Type": "application/json",
            }),
            body: JSON.stringify(payload.value),
        };

        const response = yield call(request, requestURL, headers);

        yield put({
            type: "HIDE_LOADING",
        });
        yield put({
            type: "SHOW_ALERT",
            value: {
                type: "success",
                title: "Successful save",
                message: "Successful save Address Cardinal Point ",
            },
        });
        yield put({
            type: addressInsideTypeActions.CREATE_ADDRESSINSIDETYPE_SUCCESS,
            value: response,
        });
    } catch (error) {
        yield put({
            type: "HIDE_LOADING",
        });
        yield put({
            type: "SHOW_ALERT",
            value: {
                type: "danger",
                title: "Failed save",
                message: "Failed save City",
            },
        });
        yield put({
            type: addressInsideTypeActions.CREATE_ADDRESSINSIDETYPE_ERROR,
        });
    }
}

function* getAddressInsideTypeById(payload) {
    try {
        yield put({
            type: addressInsideTypeActions.READ_ADDRESSINSIDETYPE_REQUESTING,
        });

        yield put({
            type: "SHOW_LOADING",
        });

        const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/addressInsideTypes/${payload.value.id}`;

        const headers = {
            method: "GET",
            headers: new Headers({
                Authorization: `Bearer ${window.localStorage.getItem("token")}`,
                "Content-Type": "application/json",
            }),
        };
        const response = yield call(request, requestURL, headers);

        yield put({
            type: addressInsideTypeActions.READ_ADDRESSINSIDETYPE_SUCCESS,
            value: response,
        });

        yield put({
            type: addressInsideTypeActions.READ_ADDRESSINSIDETYPE_SUCCESS,
            value: response,
        });

        yield put({
            type: "HIDE_LOADING",
        });
    } catch (error) {
        yield put({
            type: "HIDE_LOADING",
        });
        yield put({
            type: addressInsideTypeActions.READ_ADDRESSINSIDETYPE_ERROR,
        });
    }
}

function* deleteAddressInsideTypeById(payload) {
    try {
        yield put({
            type: addressInsideTypeActions.DELETE_ADDRESSINSIDETYPE_REQUESTING,
        });

        yield put({
            type: "SHOW_LOADING",
        });

        const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/addressInsideTypes/changeState/${payload.value.idAddressInsideType}`;

        const headers = {
            method: "PATCH",
            headers: new Headers({
                Authorization: `Bearer ${window.localStorage.getItem("token")}`,
                "Content-Type": "application/json",
            }),
            body: JSON.stringify(payload.value),
        };

        const response = yield call(request, requestURL, headers);

        yield put({
            type: addressInsideTypeActions.DELETE_ADDRESSINSIDETYPE_SUCCESS,
            value: response,
        });
        yield put({
            type: "HIDE_LOADING",
        });
        yield put({
            type: "SHOW_ALERT",
            value: {
                type: "success",
                title: "Successful disable",
                message: "Successful disable addressInsideType",
            },
        });
    } catch (error) {
        yield put({
            type: "HIDE_LOADING",
        });
        yield put({
            type: "SHOW_ALERT",
            value: {
                type: "danger",
                title: "Failed disable",
                message: "Failed disable addressInsideType",
            },
        });
        yield put({
            type: addressInsideTypeActions.DELETE_ADDRESSINSIDETYPE_ERROR,
        });
    }
}

function* updateAddressInsideTypeById(payload) {
    try {
        yield put({
            type: addressInsideTypeActions.UPDATE_ADDRESSINSIDETYPE_REQUESTING,
        });

        yield put({
            type: "SHOW_LOADING",
        });

        const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/addressInsideTypes/${payload.value.idAddressInsideType}`;

        const headers = {
            method: "PATCH",
            headers: new Headers({
                Authorization: `Bearer ${window.localStorage.getItem("token")}`,
                "Content-Type": "application/json",
            }),
            body: JSON.stringify(payload.value),
        };

        const response = yield call(request, requestURL, headers);

        yield put({
            type: addressInsideTypeActions.UPDATE_ADDRESSINSIDETYPE_SUCCESS,
            value: response,
        });
        yield put({
            type: "HIDE_LOADING",
        });
        yield put({
            type: "SHOW_ALERT",
            value: {
                type: "success",
                title: "Successful update",
                message: "Successful update Country",
            },
        });
    } catch (error) {
        yield put({
            type: "HIDE_LOADING",
        });
        yield put({
            type: "SHOW_ALERT",
            value: {
                type: "danger",
                title: "Failed update",
                message: "Failed update Country",
            },
        });
        yield put({
            type: addressInsideTypeActions.UPDATE_UPDATE_ADDRESSINSIDETYPE_ERROR,
        });
    }
}


export function* watchAddressInsideType() {
    yield takeLatest(addressInsideTypeActions.FETCH_ADDRESSINSIDETYPE_REQUEST, getAllAddressInsideType);
    yield takeLatest(addressInsideTypeActions.CREATE_ADDRESSINSIDETYPE_REQUEST, postAddressInsideType);
    yield takeLatest(addressInsideTypeActions.READ_ADDRESSINSIDETYPE_REQUEST, getAddressInsideTypeById);
    yield takeLatest(addressInsideTypeActions.DELETE_ADDRESSINSIDETYPE_REQUEST, deleteAddressInsideTypeById);
    yield takeLatest(addressInsideTypeActions.UPDATE_ADDRESSINSIDETYPE_REQUEST, updateAddressInsideTypeById);
}
