const initialState = {
    requestingFetchUnconfirmed: false,
    successfulFetchUnconfirmed: false,
    errorFetchUnconfirmed: false,
    unconfirmed: {},
    requestingUploadFileUnconfirmed: false,
    successfulUploadFileUnconfirmed: false,
    errorsUploadFileUnconfirmed: false,
    fileUploaded: {},
  };
  

  const financialReducer = (state = initialState, action) => {
    switch (action.type) {
        case "FETCH_UNCONFIRMED_REQUESTING":
            return {
                ...state,
                requestingFetchUnconfirmed: true,
                successfulFetchUnconfirmed: false,
                errorFetchUnconfirmed: false,
            };
        case "FETCH_UNCONFIRMED_SUCCESS":
            return {
                ...state,
                errorFetchUnconfirmed: false,
                requestingFetchUnconfirmed: false,
                successfulFetchUnconfirmed: true,
                unconfirmed: action.value,
            };
        case "FETCH_UNCONFIRMED_ERROR":
            return {
                ...state,
                errorFetchUnconfirmed: true,
                requestingFetchUnconfirmed: false,
                successfulFetchUnconfirmed: false,
            };
        case "UPLOAD_UNCONFIRMED_REQUESTING":
            return {
                ...state,
                requestingUploadFileUnconfirmed: true,
                successfulUploadFileUnconfirmed: false,
                errorsUploadFileUnconfirmed: false,
            };
        case "UPLOAD_UNCONFIRMED_SUCCESS":
            return {
                ...state,
                errorsUploadFileUnconfirmed: false,
                requestingUploadFileUnconfirmed: false,
                successfulUploadFileUnconfirmed: true,
                fileUploaded: action.value,
            };
        case "UPLOAD_UNCONFIRMED_ERROR":
            return {
                ...state,
                errorsUploadFileUnconfirmed: true,
                requestingUploadFileUnconfirmed: false,
                successfulUploadFileUnconfirmed: false,
            };
        case "RESET_UNCONFIRMED_FORM":
            return {
                ...state,
                requestingFetchUnconfirmed: false,
                successfulFetchUnconfirmed: false,
                errorFetchUnconfirmed: false,
                unconfirmed: {},
                requestingUploadFileUnconfirmed: false,
                successfulUploadFileUnconfirmed: false,
                errorsUploadFileUnconfirmed: false,
                fileUploaded: {},
                confirmCollection:null,
            }
        case "CONFIRM_COLLECTION_REQUESTING":
            return {
                ...state,
                requestingUploadFileUnconfirmed: true,
                successfulUploadFileUnconfirmed: false,
                errorsUploadFileUnconfirmed: false,
            };
        case "CONFIRM_COLLECTION_SUCCESS":
            return {
                ...state,
                errorsUploadFileUnconfirmed: false,
                requestingUploadFileUnconfirmed: false,
                successfulUploadFileUnconfirmed: true,
                confirmCollection: action.value,
            };
        case "CONFIRM_COLLECTION_ERROR":
            return {
                ...state,
                errorsUploadFileUnconfirmed: true,
                requestingUploadFileUnconfirmed: false,
                successfulUploadFileUnconfirmed: false,
            };
        default:
        return state;
    }
};

export default financialReducer;
