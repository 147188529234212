const initialState = {
  requestingFetchRecords: false,
  successfulFetchRecords: false,
  errorFetchRecords: false,
  records: {},
  requestingCreateRecord: false,
  successfulCreateRecord: false,
  errorsCreateRecord: false,
  record: null,
  requestingReadRecord: false,
  successfulReadRecord: false,
  errorsReadRecord: false,
  rowEdited: null,
  requestingDeleteRecord: false,
  successfulDeleteRecord: false,
  errorsDeleteRecord: false,
  rowDeleted: null,
  requestingUpdateRecord: false,
  successfulUpdateRecord: false,
  errorsUpdateRecord: false,
  rowUpdated: null,
};

const recordReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_RECORDS_REQUESTING":
      return {
        ...state,
        requestingFetchRecords: true,
        successfulFetchRecords: false,
        errorsFetchRecords: false,
      };
    case "FETCH_RECORD_SUCCESS":
      return {
        ...state,
        errorFetchRecords: false,
        requestingFetchRecords: false,
        successfulFetchRecords: true,
        records: action.value,
      };
    case "FETCH_RECORD_ERROR":
      return {
        ...state,
        errorFetchRecords: true,
        requestingFetchRecords: false,
        successfulFetchRecords: false,
      };
    case "CREATE_RECORD_REQUESTING":
      return {
        ...state,
        requestingCreateRecord: true,
        successfulCreateRecord: false,
        errorsCreateRecord: false,
      };
    case "CREATE_RECORD_SUCCESS":
      return {
        ...state,
        errorsCreateRecord: false,
        requestingCreateRecord: false,
        successfulCreateRecord: true,
        record: action.value,
      };
    case "CREATE_RECORD_ERROR":
      return {
        ...state,
        errorsCreateRecord: true,
        requestingCreateRecord: false,
        successfulCreateRecord: false,
      };
    case "READ_RECORD_REQUESTING":
      return {
        ...state,
        requestingReadRecord: true,
        successfulReadRecord: false,
        errorsReadRecord: false,
      };
    case "READ_RECORD_SUCCESS":
      return {
        ...state,
        errorsReadRecord: false,
        requestingReadRecord: false,
        successfulReadRecord: true,
        rowEdited: action.value,
      };
    case "READ_RECORD_ERROR":
      return {
        ...state,
        errorsReadRecord: true,
        requestingReadRecord: false,
        successfulReadRecord: false,
      };
    case "DELETE_RECORD_REQUESTING":
      return {
        ...state,
        requestingDeleteRecord: true,
        successfulDeleteRecord: false,
        errorsDeleteRecord: false,
      };
    case "DELETE_RECORD_SUCCESS":
      return {
        ...state,
        errorsDeleteRecord: false,
        requestingDeleteRecord: false,
        successfulDeleteRecord: true,
        rowDeleted: action.value,
      };
    case "DELETE_RECORD_ERROR":
      return {
        ...state,
        errorsDeleteRecord: true,
        requestingDeleteRecord: false,
        successfulDeleteRecord: false,
      };
    case "UPDATE_RECORD_REQUESTING":
      return {
        ...state,
        requestingUpdateRecord: true,
        successfulUpdateRecord: false,
        errorsUpdateRecord: false,
      };
    case "UPDATE_RECORD_SUCCESS":
      return {
        ...state,
        errorsUpdateRecord: false,
        requestingUpdateRecord: false,
        successfulUpdateRecord: true,
        rowUpdated: action.value,
      };
    case "UPDATE_RECORD_ERROR":
      return {
        ...state,
        errorsUpdateRecord: true,
        requestingUpdateRecord: false,
        successfulUpdateRecord: false,
      };
    case "RESET_RECORD_FORM":
      return {
        ...state,
        requestingCreateRecord: false,
        successfulCreateRecord: false,
        errorsCreateRecord: false,
        record: null,
        requestingReadRecord: false,
        successfulReadRecord: false,
        errorsReadRecord: false,
        rowEdited: null,
        requestingDeleteRecord: false,
        successfulDeleteRecord: false,
        errorsDeleteRecord: false,
        rowDeleted: null,
        requestingUpdateRecord: false,
        successfulUpdateRecord: false,
        errorsUpdateRecord: false,
        rowUpdated: null,
      };
    case "RESET":
      return initialState;
    default:
      return state;
  }
};

export default recordReducer;
