import {call, put, takeLatest} from "redux-saga/effects";
import request from "../../utils/request";

function* getAllUsers(payload) {
  try {
    yield put({
      type: "FETCH_USERS_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/users?page=${payload.value.page}&search=${payload.value.search}&offset=${payload.value.offset}${payload.value.isActive ? '&isActive=' + payload.value.isActive: '' }`;
    const headers = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "FETCH_USER_SUCCESS",
      value: response,
    });
    yield put({
      type: "HIDE_LOADING",
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed load",
        message: "Failed load Users",
      },
    });
    yield put({
      type: "FETCH_USER_ERROR",
    });
  }
}

function* postUser(payload) {
  try {
    yield put({ type: "CREATE_USER_REQUESTING" });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/users`;

    const headers = {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(payload.value),
    };

    const response = yield call(request, requestURL, headers);

    yield put({
      type: "CREATE_USER_SUCCESS",
      value: response,
    });
    yield put({
      type: "HIDE_LOADING",
    });
    if (response.message) {
      yield put({
        type: "SHOW_ALERT",
        value: {
          type: "danger",
          title: "Failed save",
          message: response.message,
        },
      });
    } else {
      yield put({
        type: "SHOW_ALERT",
        value: {
          type: "success",
          title: "Successful save",
          message: "Successful save User",
        },
      });
    }
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed save",
        message: "Failed save User",
      },
    });
    yield put({
      type: "CREATE_USER_ERROR",
    });
  }
}

function* getUserById(payload) {
  try {
    yield put({
      type: "READ_USER_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/users/${payload.value.id}`;

    const headers = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "READ_USER_SUCCESS",
      value: response,
    });
    yield put({
      type: "HIDE_LOADING",
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "READ_USER_ERROR",
    });
  }
}

function* deleteUserById(payload) {
  try {
    yield put({
      type: "DELETE_USER_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/users/changeState/${payload.value.idUser}`;

    const headers = {
      method: "PATCH",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(payload.value),
    };

    const response = yield call(request, requestURL, headers);

    yield put({
      type: "DELETE_USER_SUCCESS",
      value: response,
    });
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "success",
        title: "Successful disable",
        message: "Successful disable User",
      },
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed disable",
        message: "Failed disable User",
      },
    });
    yield put({
      type: "DELETE_USER_ERROR",
    });
  }
}

function* updateUser(payload) {
  try {
    yield put({
      type: "UPDATE_USER_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/users/${payload.value.id}`;

    const headers = {
      method: "PATCH",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(payload.value),
    };

    const response = yield call(request, requestURL, headers);

    yield put({
      type: "UPDATE_USER_SUCCESS",
      value: response,
    });
    yield put({
      type: "HIDE_LOADING",
    });
    if (response.message) {
      yield put({
        type: "SHOW_ALERT",
        value: {
          type: "danger",
          title: "Failed update",
          message: response.message,
        },
      });
    } else {
      yield put({
        type: "SHOW_ALERT",
        value: {
          type: "success",
          title: "Successful update",
          message: "Successful update User",
        },
      });
    }
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed update",
        message: "Failed update User",
      },
    });
    yield put({
      type: "UPDATE_USER_ERROR",
    });
  }
}

function* getAllCouriers(payload) {
  try {
    yield put({
      type: "FETCH_COURIERS_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    let requestURL = `${process.env.REACT_APP_API_URL}/api/v1/users/couriers`;
    if (payload?.value?.validateDsp) {
      requestURL += "?validateDsp=true";
    }
    const headers = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "FETCH_COURIER_SUCCESS",
      value: response,
    });
    yield put({
      type: "HIDE_LOADING",
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed load",
        message: "Failed load Couriers",
      },
    });
    yield put({
      type: "FETCH_COURIER_ERROR",
    });
  }
}

export function* watchUser() {
  yield takeLatest("FETCH_USERS_REQUEST", getAllUsers);
  yield takeLatest("CREATE_USER_REQUEST", postUser);
  yield takeLatest("READ_USER_REQUEST", getUserById);
  yield takeLatest("DELETE_USER_REQUEST", deleteUserById);
  yield takeLatest("UPDATE_USER_REQUEST", updateUser);
  yield takeLatest("FETCH_COURIERS_REQUEST", getAllCouriers);
}
