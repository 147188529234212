import React, {
  useState,
  useRef,
  useEffect,
} from "react";
import { Controller, useForm } from "react-hook-form";
import Sidebar from "../../components/Navigation/Sidebar";
import Topbar from "../../components/Navigation/Topbar";
import { connect } from "react-redux";
import { Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import Select from 'react-select'
import { isNullOrUndefined } from "../../utils/functionsUtils";
const Swal = require("sweetalert2");

const UpdateStates = ({
  reduxGetStatuses,
  statuses,
  novelties,
  reduxGetNovelties,
  reduxGetCouriers,
  couriers,
  reduxPatchAddresses,
  guidesState,
  reduxUploadFile,
  fileUploaded,
}) => {
  const renderTooltip = (title) => (
    <Tooltip id="button-tooltip">{title}</Tooltip>
  );

  const {
    watch,
    control,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    reset,
  } = useForm();

  const noveltySelected = watch('novelty', null);

  const noveltiesToCanUploadImage = ['N-ENEF','N-EES','N-INDE','N-EPDI',
  'N-RVU','N-PCVN','N-CLIM','N-ZRPD','N-RES','N-ORPU','N-REAN','N-FALL',
  'N-LOCE','N-CNL','N-CNRS','N-CNRV','N-CNRN','N-DIIN','N-DIER','N-CNR',
  'N-VNAA','N-CNLD','N-CNLI'];

  const {
    register: registerUpload,
    handleSubmit: handleSubmitUpload,
    watch: watchUpload,
    formState: { errors: errorsUpload },
    reset: resetUpload,
  } = useForm({});

  const [recordsFiltered, setRecordsFiltered] = useState([]);
  const [fileName, setFileName] = useState(null);
  const [fileError, setFileError] = useState(null);
  const [evidenceImageError, setEvidenceImageError] = useState(null);
  const [evidenceImageName, setEvidenceImageName] = useState(null);
  const [showUpload, setShowUpload] = useState(false);
  const file = useRef({});
  file.current = watchUpload("file", "");
  
  const evidenceImage = useRef({});
  evidenceImage.current = watch("evidenceImage", "");

  useEffect(() => {
    setValue('courier', null)
  },[noveltySelected])

  useEffect(() => {
    if (file.current != null && file.current.length > 0) {
      setFileName(file.current[0].name);
    }
  }, [file.current]);
  
  useEffect(() => {
    if (evidenceImage.current != null && evidenceImage.current.length > 0) {
      setEvidenceImageName(evidenceImage.current[0].name);
    }
  }, [evidenceImage.current]);

  useEffect(() => {
    reduxGetStatuses({
      page: 1,
      search: "",
      offset: 1000,
    });
    reduxGetNovelties({
      page: 1,
      search: "",
      offset: 1000,
      status: "",
    });
    reduxGetCouriers({});
    guidesState = undefined;
    setValue("byTrackings", "1");
  }, []);

  useEffect(() => {
    if (guidesState) {
      Swal.fire(
        "Proceso exitoso!",
        `Se actualizaron ${guidesState.updated} guías para un total de ${guidesState.total}. ` +
          (guidesState.errorState
          ? `Alguna(s) guía(s) ingresada(s) ya tiene(n) asignado el estado seleccionado. `
          : ``) + (guidesState.errorFinal
          ? `Alguna(s) guía(s) tiene) estados finales y no puede(n) ser actualizada(s).`
          : ``),
        "success"
      );
      setValue("state", null);
      setValue("novelty", null);
      setValue("comment", null);
    }
  }, [guidesState]);

  useEffect(() => {
    if (fileUploaded) {
      if (
        fileUploaded.errorState ||
        fileUploaded.errorCourier ||
        fileUploaded.errorRecord ||
        fileUploaded.errorNote ||
        fileUploaded.errorReschedule
      ) {
        Swal.fire(
          "Falló!",
          `Alguna información diligenciada en el archivo no coincide con la información en base de datos respecto a estados, mensajeros, novedades y/o notas.
          Por favor revisar y corregir archivo. En total se procesaron ` +
          fileUploaded.updated +
          " para un total de " +
          fileUploaded.total +
          " cargados.",
          "error"
        );
      } else {
        Swal.fire(
          "Proceso exitoso!",
          "En total se procesaron " +
          fileUploaded.updated +
          " para un total de " +
          fileUploaded.total +
          " cargados.",
          "success"
        );
      }
    }
  }, [fileUploaded]);

  const handleChangeState = (selectedState) => {
    setValue("state", selectedState);
    setValue("novelty", null);
    setValue("courier", null);
    if (getValues('state')) {
      setRecordsFiltered(
        novelties.items.filter(
          (record) => record.idStatus === Number(selectedState.value)
        )
      );
    } else {
      setRecordsFiltered([]);
    }
  };

  const onSubmit = (data) => {
    if (data.trackings) {
      const cleanedTrackings = data.trackings
        .split(/\s+/)
        .filter(tracking => tracking.trim() !== '')
        .join('\n');
      
      data.trackings = cleanedTrackings;
    }
    
    if(!isNullOrUndefined(data.evidenceImage) && data.evidenceImage.length > 0){
      const evidenceImage = data.evidenceImage[0];
      const typeImagesAllowed = ["image/jpg", "image/png", "image/jpeg"];
      if (evidenceImage && typeImagesAllowed.indexOf(evidenceImage.type) === -1) {
        return Swal.fire(
          'Archivo errado',
          `Señor usuario, el archivo cargado no cumple con los formatos permitidos (JPG, JPEG, PNG)`,
          'error'
        );
      }
    }
    reduxPatchAddresses(data);
    reset({
      byTrackings: "1",
      state: null,
      novelty: null,
      courier: null,
      comment: null,
    });
    setEvidenceImageName(null);
    setEvidenceImageError(null);
  };

  const handleShowUpload = () => {
    setFileName(null);
    resetUpload();
    setFileError(null);
    setShowUpload(true);
  };

  const handleCloseUpload = () => {
    setFileName(null);
    resetUpload();
    setFileError(null);
    setShowUpload(false);
  };

  const validateStateIsCorrect = (state) => {
    const foundState = statuses.items.find(st => st.code === state && st.isActive);
    return !!foundState;
  }

  const onSubmitUpload = (data) => {
    const file = data.file[0];
    const MAX_FIELDS = 6;
    const MIN_FIELDS = 2;

    if (file && file.type === 'text/csv') {
      const reader = new FileReader();
      reader.readAsText(file);

      reader.onload = () => {
        const csvData = reader.result;

        if(statuses && statuses.items){
          const data = csvData.split('\n');
          for (const datum of data) {
            const dataLine = datum.split(';')
            const index = data.indexOf(datum);
            if(index === (data.length -1) && (dataLine.length <= 1)) continue;
            if (dataLine.length < MIN_FIELDS || dataLine.length > MAX_FIELDS)
              return Swal.fire(
                  'Archivo errado',
                  `Señor usuario el archivo contiene un registro errado <br/>
                   Fila errada: ${index+1} (${dataLine.length < MIN_FIELDS ? 'No cumple los datos mínimos' : 'Posee informacion de mas'}) <br/>
                   Mínimo de columnas por registro ${MIN_FIELDS} y máximo ${MAX_FIELDS}<br/>
                   Verifique que el archivo contenga los datos necesarios y que no tenga espacios de más`,
                  'error'
              );
            if (!validateStateIsCorrect(dataLine[1] && dataLine[1].trim()))
              return Swal.fire(
                  'Archivo errado',
                  `¡Señor usuario el archivo contiene estados errados! <br/>
                   Estado errado: ${dataLine[1]} <br/>
                   Verifque que se encuentre bien escrito
                  `,
                  'error'
              );
          }
        }

        const isCorrectDelimited = csvData.split('\n')[0].split(';').length > 1;
        if (!isCorrectDelimited)
          return Swal.fire('Archivo errado', 'Señor usuario el archivo debe tener el formato \'CSV UTF-8 delimitado por comas\'', 'error');

        reduxUploadFile({
          file: new File([reader.result], file.name, { type: 'text/csv;charset=utf-8' }),
        });

        resetUpload();
        setFileName(null);
        setFileError(null);
        setShowUpload(false);
      };
    } else return Swal.fire('Archivo errado', 'Señor usuario el archivo debe tener el formato \'CSV UTF-8 delimitado por comas\'', 'error');
  };

  return (
    <div>
      <div id="wrapper">
        <Sidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Topbar />
            <div className="container-fluid">
              <div className="d-sm-flex align-items-center mb-1">
                <h1 className="h3 mb-0 text-gray-800 mr-3">
                  Actualización de Estados
                </h1>
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 50, hide: 50 }}
                  overlay={renderTooltip("Cargar archivo")}
                >
                  <button
                    className="btn btn-primary btn-circle"
                    type="button"
                    onClick={handleShowUpload}
                  >
                    <i className="fas fa-upload fa-sm"></i>
                  </button>
                </OverlayTrigger>
              </div>
              <div className="card shadow mb-4">
                <div className="card-body">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <table style={{ width: "100%" }}>
                      <tr className="row">
                        <td style={{ width: "20%" }}>
                          <div className="form-group col-md-12">
                            <input type="radio" value="1" {...register("byTrackings")}/> Guías
                            <input type="radio" value="2" style={{ marginLeft: '40px'}} {...register("byTrackings")}/> ID dirección
                            <textarea
                              id="trackings"
                              className={`form-control form-control-user ${
                                errors.trackings && "is-invalid"
                              }`}
                              {...register("trackings", {
                                required: true,
                                pattern: {
                                  value: /^[a-zA-Z0-9\s]*$/i,
                                  message: "invalid guide",
                                },
                              })}
                              rows={12}
                              cols={5}
                            />
                            {errors.trackings && (
                              <span className="invalid-feedback">
                                Las guías no son válidas
                              </span>
                            )}
                          </div>
                        </td>
                        <td className="d-flex flex-column" style={{ width: "50%" }}>
                          <div className="form-group">
                            <label htmlFor="state" className="form-label">
                              Estado
                            </label>
                            <Controller
                              name='state'
                              control={control}
                              rules={{ required: true }}
                              defaultValue={null}
                              render={({ field }) => (
                                <Select
                                  {...field}
                                  isClearable
                                  onChange={(value) => handleChangeState(value)}
                                  options={
                                    statuses && Object.keys(statuses).length > 0
                                      ? [
                                        ...statuses.items
                                            .filter(stt => stt.isActive)
                                          .map((ele) => ({
                                            value: ele.idStatus,
                                            label: ele.name,
                                          })),
                                      ]
                                      : []
                                  }
                                />
                              )}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="novelty" className="form-label">
                              Novedad
                            </label>
                            {/* Estado ya homologado en el isDisabled */}
                            <Controller
                              control={control}
                              name='novelty'
                              rules={{ required: true }}
                              defaultValue={null}
                              render={({ field }) => (
                                <Select 
                                  {...field}
                                  id='novelty'
                                  isClearable
                                  isDisabled={
                                    recordsFiltered.length === 0 ||
                                    !getValues("state")
                                  }
                                  options={
                                  recordsFiltered && Object.keys(recordsFiltered).length > 0
                                      ? [
                                      ...recordsFiltered
                                      .map((ele) => ({                                    
                                          value: ele.code,
                                          label: ele.description,
                                      })),
                                      ]
                                      : []
                                  }
                                />
                              )}
                            />
                          </div>
                          {noveltySelected &&
                            noveltiesToCanUploadImage.indexOf(noveltySelected.value) > -1 && (
                            <div className="form-group">
                              <label htmlFor="evidenceImage" className="form-label">
                                Imagen de evidencia
                              </label>
                              <div className="custom-file evidence-image-file">
                                <input
                                  type="file"
                                  accept="image/jpg, image/png, image/jpeg"
                                  id="evidenceImage"
                                  name="evidenceImage"
                                  {...register("evidenceImage", { required: false })}
                                  className={`custom-file-input ${
                                    errors.evidenceImage && "is-invalid"
                                  }`}
                                />{" "}
                                <label
                                  className="custom-file-label"
                                  htmlFor="validatedCustomFile"
                                >
                                  { evidenceImageError
                                    ? evidenceImageError
                                    : evidenceImageName
                                    ? evidenceImageName
                                    : "Seleccionar archivo..."}
                                </label>
                              </div>
                            </div>
                          )}
                          {/* State ya homologado de 5 a 4 */}
                          {noveltySelected && noveltySelected.value === 'N-AAUM' && (
                            <div className="form-group">
                              <label htmlFor="courier" className="form-label">
                                Mensajero
                              </label>
                              <Controller
                              control={control}
                              name='courier'
                              rules={{ required: true }}
                              render={({ field }) => (
                                <Select 
                                  {...field}
                                  id='courier'
                                  isClearable
                                  options={
                                    couriers && Object.keys(couriers).length > 0
                                      ? [
                                      ...couriers
                                      .map((ele) => ({                                    
                                          value: ele.idUser,
                                          label: ele.nameHeadquarters != null
                                            ? `${ele.nameHeadquarters} - ${ele.firstName} ${ele.lastName}`
                                            : `${ele.firstName} ${ele.lastName}`,
                                      })),
                                      ]
                                      : []
                                  }
                                />
                              )}
                            />
                            </div>
                          )}
                          <div className="form-group">
                            <label htmlFor="comment" className="form-label">
                              Comentario
                            </label>
                            {/* Estado homologado de 5 a 4 */}
                            <Controller
                              control={control}
                              name='comment'
                              render={({ field }) => (
                                <input
                                  {...field}
                                  id="comment"
                                  type="text"
                                  className={`form-control form-control-user`}
                                  style={{ marginTop: "5px", marginBottom: "10px" }}
                                />
                              )}
                            />
                          </div>
                          <div className="d-flex form-group justify-content-center">
                            <Button
                              variant="primary"
                              type="submit"
                              id="btnSearch"
                            >
                              Guardar
                            </Button>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Modal
          show={showUpload}
          onHide={handleCloseUpload}
          onEscapeKeyDown={handleCloseUpload}
          backdrop="static"
          keyboard={true}
        >
          <form onSubmit={handleSubmitUpload(onSubmitUpload)}>
            <Modal.Header closeButton>
              <Modal.Title>Cargar archivo</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="form-group col-md-12">
                  <div className="custom-file">
                    <input
                      type="file"
                      id="file"
                      name="file"
                      {...registerUpload("file", { required: true })}
                      className={`custom-file-input ${
                        errorsUpload.file && "is-invalid"
                      }`}
                    />{" "}
                    <label
                      className="custom-file-label"
                      htmlFor="validatedCustomFile"
                    >
                      {fileError
                        ? fileError
                        : fileName
                        ? fileName
                        : "Seleccionar archivo..."}
                    </label>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseUpload}>
                Cancelar
              </Button>
              <Button variant="primary" type="submit">
                Cargar
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    statuses: state.statusesState.statuses,
    novelties: state.noveltyState.novelties,
    couriers: state.userState.couriers,
    guidesState: state.addressState.guidesState,
    fileUploaded: state.addressState.fileUploadedStates,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    reduxGetStatuses: (payload) =>
      dispatch({
        type: "FETCH_NEW_STATUS_REQUEST",
        value: payload,
      }),
    reduxGetNovelties: (payload) =>
      dispatch({
        type: "FETCH_NOVELTIES_REQUEST",
        value: payload,
      }),
    reduxGetCouriers: (payload) =>
      dispatch({
        type: "FETCH_COURIERS_REQUEST",
        value: payload,
      }),
    reduxPatchAddresses: (payload) =>
      dispatch({
        type: "UPDATE_GUIDES_STATE_REQUEST",
        value: payload,
      }),
    reduxUploadFile: (payload) =>
      dispatch({
        type: "UPLOAD_ADDRESSES_STATES_REQUEST",
        value: payload,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateStates);
