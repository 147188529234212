import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";

const Swal = require("sweetalert2");

const ForgotPassword = ({ forgotPassword, successful, error }) => {
  const history = useHistory();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = (values) => {
    forgotPassword(values);
  };

  useEffect(() => {
    document.getElementById("body").className = "bg-default";
  }, []);

  useEffect(() => {
    if (successful == true) {
      reset();
      history.push("/");
      Swal.fire("Proceso exitoso!", "Por favor revisa tu correo.", "success");
    }
  }, [successful]);

  useEffect(() => {
    if (error == true) {
      Swal.fire(
        "La contraseña no pudo ser cambiada... verifica el correo ingresado.",
        "error"
      );
    }
  }, [error]);

  return (
    <>
      <div className="container vh-100">
        <div className="row vh-100 justify-content-center align-items-center">
          <div className="col-xl-10 col-lg-12 col-md-9">
            <div className="card o-hidden border-0 shadow-lg my-5">
              <div className="card-body p-0">
                <div className="row">
                  <div className="col-lg-6 d-none d-lg-block bg-login-image"></div>
                  <div className="col-lg-6">
                    <div className="p-5">
                      <div className="text-center">
                        <h1 className="h4 text-gray-900 mb-4">
                          ¡Recuperar contraseña!
                        </h1>
                      </div>
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-group">
                          <input
                            type="text"
                            className={`form-control form-control-user ${errors.email && "is-invalid"
                              }`}
                            placeholder="Correo electrónico"
                            {...register("email", { required: true })}
                          />
                          {errors.email && (
                            <span className="invalid-feedback">
                              El correo electrónico es requerido
                            </span>
                          )}
                        </div>
                        <button
                          type="submit"
                          className="btn btn-primary btn-user btn-block"
                        >
                          Enviar
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    successful: state.forgotPasswordState.successful,
    error: state.forgotPasswordState.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    forgotPassword: (data) =>
      dispatch({
        type: "FORGOTPASSWORD_REQUEST",
        data,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
