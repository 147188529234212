import React, { useEffect, useState } from "react";

const ImageS3 = ({ picture, className = "" }) => {
  const [url, setUrl] = useState(null);

  useEffect(() => {
    if (picture) {
      const token = window.localStorage.getItem("token");

      fetch(`${process.env.REACT_APP_API_URL}/api/v1/uploads/${picture}`, {
        method: "GET",
        headers: new Headers({
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          setUrl(response.url);
        });
    }

    return () => {
      setUrl(null);
    };
  }, [picture]);

  return url ? <img src={url} className={className} /> : <></>;
};

export default ImageS3;
