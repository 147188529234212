const initialState = {
  requestingFetchCustomers: false,
  successfulFetchCustomers: false,
  errorFetchCustomers: false,
  customers: {},
  requestingCreateCustomer: false,
  successfulCreateCustomer: false,
  errorsCreateCustomer: false,
  customer: null,
  requestingReadCustomer: false,
  successfulReadCustomer: false,
  errorsReadCustomer: false,
  rowEdited: null,
  requestingDeleteCustomer: false,
  successfulDeleteCustomer: false,
  errorsDeleteCustomer: false,
  rowDeleted: null,
  requestingUpdateCustomer: false,
  successfulUpdateCustomer: false,
  errorsUpdateCustomer: false,
  rowUpdated: null,
  requestingDeleteSoftCustomer: false,
  successfulDeleteSoftCustomer: false,
  errorsDeleteSoftCustomer: false,
  rowDeletedSoft: null,
};

const customerReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_CUSTOMERS_REQUESTING":
      return {
        ...state,
        requestingFetchCustomers: true,
        successfulFetchCustomers: false,
        errorsFetchCustomers: false,
      };
    case "FETCH_CUSTOMER_SUCCESS":
      return {
        ...state,
        errorFetchCustomers: false,
        requestingFetchCustomers: false,
        successfulFetchCustomers: true,
        customers: action.value,
      };
    case "FETCH_CUSTOMER_ERROR":
      return {
        ...state,
        errorFetchCustomers: true,
        requestingFetchCustomers: false,
        successfulFetchCustomers: false,
      };
    case "CREATE_CUSTOMER_REQUESTING":
      return {
        ...state,
        requestingCreateCustomer: true,
        successfulCreateCustomer: false,
        errorsCreateCustomer: false,
      };
    case "CREATE_CUSTOMER_SUCCESS":
      return {
        ...state,
        errorsCreateCustomer: false,
        requestingCreateCustomer: false,
        successfulCreateCustomer: true,
        customer: action.value,
      };
    case "CREATE_CUSTOMER_ERROR":
      return {
        ...state,
        errorsCreateCustomer: true,
        requestingCreateCustomer: false,
        successfulCreateCustomer: false,
      };
    case "READ_CUSTOMER_REQUESTING":
      return {
        ...state,
        requestingReadCustomer: true,
        successfulReadCustomer: false,
        errorsReadCustomer: false,
      };
    case "READ_CUSTOMER_SUCCESS":
      return {
        ...state,
        errorsReadCustomer: false,
        requestingReadCustomer: false,
        successfulReadCustomer: true,
        rowEdited: action.value,
      };
    case "READ_CUSTOMER_ERROR":
      return {
        ...state,
        errorsReadCustomer: true,
        requestingReadCustomer: false,
        successfulReadCustomer: false,
      };
    case "DELETE_CUSTOMER_REQUESTING":
      return {
        ...state,
        requestingDeleteCustomer: true,
        successfulDeleteCustomer: false,
        errorsDeleteCustomer: false,
      };
    case "DELETE_CUSTOMER_SUCCESS":
      return {
        ...state,
        errorsDeleteCustomer: false,
        requestingDeleteCustomer: false,
        successfulDeleteCustomer: true,
        rowDeleted: action.value,
      };
    case "DELETE_CUSTOMER_ERROR":
      return {
        ...state,
        errorsDeleteCustomer: true,
        requestingDeleteCustomer: false,
        successfulDeleteCustomer: false,
      };
    case "UPDATE_CUSTOMER_REQUESTING":
      return {
        ...state,
        requestingUpdateCustomer: true,
        successfulUpdateCustomer: false,
        errorsUpdateCustomer: false,
      };
    case "UPDATE_CUSTOMER_SUCCESS":
      return {
        ...state,
        errorsUpdateCustomer: false,
        requestingUpdateCustomer: false,
        successfulUpdateCustomer: true,
        rowUpdated: action.value,
      };
    case "UPDATE_CUSTOMER_ERROR":
      return {
        ...state,
        errorsUpdateCustomer: true,
        requestingUpdateCustomer: false,
        successfulUpdateCustomer: false,
      };
    case "DELETE_SOFT_CUSTOMER_REQUESTING":
      return {
        ...state,
        requestingDeleteSoftCustomer: true,
        successfulDeleteSoftCustomer: false,
        errorsDeleteSoftCustomer: false,
      };
    case "DELETE_SOFT_CUSTOMER_SUCCESS":
      return {
        ...state,
        errorsDeleteSoftCustomer: false,
        requestingDeleteSoftCustomer: false,
        successfulDeleteSoftCustomer: true,
        rowDeletedSoft: action.value,
      };
    case "DELETE_SOFT_CUSTOMER_ERROR":
      return {
        ...state,
        errorsDeleteSoftCustomer: true,
        requestingDeleteSoftCustomer: false,
        successfulDeleteSoftCustomer: false,
      };
    case "RESET_CUSTOMER_FORM":
      return {
        ...state,
        requestingCreateCustomer: false,
        successfulCreateCustomer: false,
        errorsCreateCustomer: false,
        customer: null,
        requestingReadCustomer: false,
        successfulReadCustomer: false,
        errorsReadCustomer: false,
        rowEdited: null,
        requestingDeleteCustomer: false,
        successfulDeleteCustomer: false,
        rowDeleted: null,
        requestingUpdateCustomer: false,
        successfulUpdateCustomer: false,
        errorsUpdateCustomer: false,
        rowUpdated: null,
        requestingDeleteSoftCustomer: false,
        successfulDeleteSoftCustomer: false,
        errorsDeleteSoftCustomer: false,
        rowDeletedSoft: null,
      };
    case "RESET":
      return initialState;
    default:
      return state;
  }
};

export default customerReducer;
