const initialState = {
    requestingFetchAlertTypes: false,
    successfulFetchAlertTypes: false,
    errorFetchAlertTypes: false,
    alertTypes: [],
    requestingFetchOneAlertTypes: false,
    successfulFetchOneAlertTypes: false,
    errorFetchOneAlertTypes: false,
    alertType: null,
    requestingCreateAlertType: false,
    successfulCreateAlertType: false,
    errorCreateAlertType: false,
    requestingUpdateAlertType: false,
    successfulUpdateAlertType: false,
    errorUpdateAlertType: false,
    upsertResult: null,
};

const alertTypeReducer = (state = initialState, action) => {
    switch (action.type) {
        case "FETCH_ALERT_TYPES_REQUESTING":
            return {
                ...state,
                requestingFetchAlertTypes: true,
                successfulFetchAlertTypes: false,
                errorFetchAlertTypes: false,
            };
        case "FETCH_ALERT_TYPES_SUCCESS":
            return {
                ...state,
                requestingFetchAlertTypes: false,
                successfulFetchAlertTypes: true,
                errorFetchAlertTypes: false,
                alertTypes: action.value,
            };
        case "FETCH_ALERT_TYPES_ERROR":
            return {
                ...state,
                requestingFetchAlertTypes: false,
                successfulFetchAlertTypes: false,
                errorFetchAlertTypes: true,
            };
        case "FETCH_ONE_ALERT_TYPE_REQUESTING":
            return {
                ...state,
                requestingFetchOneAlertTypes: true,
                successfulFetchOneAlertTypes: false,
                errorFetchOneAlertTypes: false,
            };
        case "FETCH_ONE_ALERT_TYPE_SUCCESS":
            return {
                ...state,
                requestingFetchOneAlertTypes: false,
                successfulFetchOneAlertTypes: true,
                errorFetchOneAlertTypes: false,
                alertType: action.value,
            };
        case "FETCH_ONE_ALERT_TYPE_ERROR":
            return {
                ...state,
                requestingFetchOneAlertTypes: false,
                successfulFetchOneAlertTypes: false,
                errorFetchOneAlertTypes: true,
            };
        case "CREATE_ALERT_TYPE_REQUESTING":
            return {
                ...state,
                requestingCreateAlertType: true,
                successfulCreateAlertType: false,
                errorCreateAlertType: false,
            };
        case "CREATE_ALERT_TYPE_SUCCESS":
            return {
                ...state,
                requestingCreateAlertType: false,
                successfulCreateAlertType: true,
                errorCreateAlertType: false,
                upsertResult: action.value,
            };
        case "CREATE_ALERT_TYPE_ERROR":
            return {
                ...state,
                requestingCreateAlertType: false,
                successfulCreateAlertType: false,
                errorCreateAlertType: true,
                upsertResult: action.value,
            };
        case "UPDATE_ALERT_TYPE_REQUESTING":
            return {
                ...state,
                requestingUpdateAlertType: true,
                successfulUpdateAlertType: false,
                errorUpdateAlertType: false,
            };
        case "UPDATE_ALERT_TYPE_SUCCESS":
            return {
                ...state,
                requestingUpdateAlertType: false,
                successfulUpdateAlertType: true,
                errorUpdateAlertType: false,
                upsertResult: action.value,
            };
        case "UPDATE_ALERT_TYPE_ERROR":
            return {
                ...state,
                requestingUpdateAlertType: false,
                successfulUpdateAlertType: false,
                errorUpdateAlertType: true,
                upsertResult: action.value,
            };
        case "RESET_ALERT_TYPE_FORM":
            return {
                ...state,
                alertType: null,
                requestingCreateAlertType: false,
                successfulCreateAlertType: false,
                errorCreateAlertType: false,
                requestingUpdateAlertType: false,
                successfulUpdateAlertType: false,
                errorUpdateAlertType: false,
                upsertResult: null,
            }
        default:
            return state;
    }
};

export default alertTypeReducer;
