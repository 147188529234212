import React, {useState} from 'react';
import {Badge, Button, Card, Col, Row, Stack} from 'react-bootstrap';
import {BiArrowToRight} from 'react-icons/bi'
import TooltipWrapper from "../../components/Common/TooltipWrapper";
import {formattedDate} from "../../utils/formatDate";
import { TfiReload } from "react-icons/tfi";
import BasicSpinner from "../../components/Common/BasicSpinner";


const Progreso = ({asignadas, procesadas, fallidas, total, handleDetail, parent, nIntents}) => {
    const porcentajeProcesado = total > 0 ? (procesadas / total) * 100 : 0;
    const porcentajeFallido = total > 0 ? (fallidas / total) * 100 : 0;
    const porcentajeAsignado = total > 0 ? (asignadas / total) * 100 : 0;
    const [isReassign, setIsReassign] = useState(false);

    const handleReassign = async (idParent) => {
        const token = window.localStorage.getItem("token");
        try {
            setIsReassign(true)
            const response = await fetch(`${process.env.REACT_APP_ASSIGN_URL}/api/v1/assignOperator/AssignmentParentsReintentFails`, {
                method: 'POST',
                headers: new Headers({
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }),
                body: JSON.stringify({idParent: idParent})
            });
            if (!response.ok) {
                setIsReassign(false)
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            setIsReassign(false)
        } catch (err) {
            console.log(err);
        }
    }
    return (
        <div className="container-fluid mt-4">
            <Card>
                <div className="row mb-2 mt-3 align-items-center">

                    <div className="col-8 col-md-8 col-sm-12">
                        <div className='row-column w-100'>
                            <Col>
                                <div className="progress mb-2" title={`${procesadas} de ${total} procesadas`}>
                                    <div
                                        className="progress-bar"
                                        role="progressbar"
                                        style={{width: `${porcentajeProcesado}%`}}
                                        aria-valuenow={porcentajeProcesado}
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                    >
                                        {procesadas} de {total} procesadas
                                    </div>
                                </div>
                            </Col>
                            <Col>
                                <div className="progress mb-2" title={`${asignadas} asignadas ${Math.round(porcentajeAsignado)} %`}>
                                    <div
                                        className="progress-bar bg-success"
                                        role="progressbar"
                                        style={{width: `${porcentajeAsignado}%`}}
                                        aria-valuenow={porcentajeAsignado}
                                        aria-valuemin="0"
                                        aria-valuemax={total}
                                    >
                                        {asignadas} asignadas {Math.round(porcentajeAsignado)} %
                                    </div>
                                </div>
                            </Col>
                            <Col>
                                <div className="progress" title={`${fallidas} fallidas`}>
                                    <div
                                        className="progress-bar bg-danger"
                                        role="progressbar"
                                        style={{width: `${porcentajeFallido}%`}}
                                        aria-valuenow={porcentajeFallido}
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                    >
                                        {fallidas} fallidas
                                    </div>
                                </div>
                            </Col>
                        </div>
                    </div>
                    <div className="col-2 text-left d-sm-none d-md-block col-md-2 col-lg-2 text-left">

                        <Stack direction={'horizontal'} gap={1} className="text-left">
                            <Row>
                                <small className={'text-muted'}>Creado el: <span>{formattedDate(parent.createdAt)}</span></small>
                            </Row>
                            <hr className="dropdown-divider"/>
                            <Row>
                                <small className={'text-muted'}>Tiempo de ejecución: <span>{parent.performance_time}</span></small>
                            </Row>
                            <hr className="dropdown-divider"/>
                            <Row>
                                <small className={'text-muted'}>Operador: <span>{parent.operator_name}</span></small>
                            </Row>
                            <hr className="dropdown-divider"/>
                            <Row>
                                { procesadas === total && fallidas > 0 && (

                                    <TooltipWrapper text={ isReassign ? 'Reintentando' :'Reintentar Asignar fallidas'}>
                                        <Badge as={'button'}
                                               style={{cursor: isReassign ? 'block' : 'pointer', border: 'none'}}
                                               bg={'success'}
                                               type={'button'}
                                               onClick={() => {
                                                   if(isReassign) return null;
                                                   console.log('hans peter')
                                                   return handleReassign(parent.id)
                                               }}
                                               text={'white'}>

                                            {isReassign ? (
                                                <BasicSpinner size={1}/>
                                            ): (
                                                <TfiReload/>
                                            ) }

                                        </Badge>

                                    </TooltipWrapper>
                                ) }
                                <Col>
                                    <TooltipWrapper text={'Numero de intentos'}>
                                        <Badge text={'white'}>{nIntents}</Badge>
                                    </TooltipWrapper>

                                </Col>


                            </Row>
                        </Stack>


                    </div>
                    <div className="col-2 col-md-2 col-lg-2 text-left">
                        <div className="col text-right">
                            <TooltipWrapper text={'Ver Detalle de fallidos'}>
                                <button autoFocus={false} className="btn btn-sm btn-info"
                                        onClick={handleDetail}>
                                    <BiArrowToRight size={22}></BiArrowToRight></button>
                            </TooltipWrapper>

                        </div>
                    </div>
                </div>
            </Card>
        </div>
    )
        ;
};

export default Progreso;
