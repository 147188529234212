import React, { useState, useRef, useEffect, forwardRef, useContext } from 'react';
import { connect } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';
import { Button, Modal, Tooltip, OverlayTrigger } from 'react-bootstrap';
import moment from 'moment-timezone';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import 'react-datepicker/dist/react-datepicker.css';

import Sidebar from '../../components/Navigation/Sidebar';
import Topbar from '../../components/Navigation/Topbar';
import Grid from '../../components/Grid';
import InputList, { SelectConstEnabled } from '../../components/Common/inputList';
import { PermissionContext } from '../../hooks/PermissionContext';
import { Show } from '../../hooks/Show';
import { useAuth } from '../../hooks/useAuth';
import { getStatusBadgeByCode } from '../../utils/StatusUtils';

const Swal = require('sweetalert2');

const Order = ({
  reduxGetOrders,
  orders,
  reduxGetOrder,
  order,
  reduxPostOrder,
  rowEdited,
  reduxDeleteOrder,
  rowDeleted,
  reduxPatchOrder,
  rowUpdated,
  reduxResetOrderForm,
  reduxGetOrderDetails,
  orderDetails,
  reduxGetCustomers,
  customers,
  reduxGetProducts,
  products,
  reduxGetCities,
  cities,
  reduxGetNeighborhoodsByCity,
  neighborhoodsByCity,
  reduxGetDepartmentsByCountry,
  departmentsByCountry,
  reduxGetCitiesByDepartment,
  citiesByDepartment,
  countries,
  reduxGetCountries,
  reduxSetDepartment,
  reduxSetCity,
  reduxSetNeighborhood,
  address,
  reduxGetCompanies,
  companies,
  bulkAddress,
  reduxPostBulkAddress,
  reduxDeleteSoftOrder,
  rowDeletedSoft,
  reduxGetBranches,
  branches,
}) => {
  const { hasPermission } = useContext(PermissionContext);


  const methods = useForm({
    defaultValues: {
      indicativeRoad: '',
      roadNumber: '',
      appendixRoad: '',
      crossoverRoad: '',
      crossoverNumber: '',
      appendixCrossingNumber: '',
      orientationCrossingNumber: '',
      insideTypes: '',
      doorNumber: '',
      inside: '',
      city: '',
      neighborhood: '',
      country: '',
      department: '',
    },
  });
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
    setValue,
    getValues,
  } = methods;

  const {
    register: registerAdd,
    handleSubmit: handleSubmitAdd,
    formState: { errors: errorsAdd },
    reset: resetAdd,
    setValue: setValueAdd,
    getValues: getValuesAdd,
  } = useForm();
  const {
    register: registerAssign,
    handleSubmit: handleSubmitAssign,
    formState: { errors: errorsAssign },
    reset: resetAssign,
    setValue: setValueAssign,
    getValues: getValuesAssign,
  } = useForm();
  const {
    register: registerSearch,
    handleSubmit: handleSubmitSearch,
    formState: { errors: errorsSearch },
    reset: resetSearch,
    setValue: setValueSearch,
    getValues: getValuesSearch,
  } = useForm({
    defaultValues: {
      idCompany: '',
      idOperator: '',
      date: null,
    },
  });
  const {
    register: registerDates,
    formState: { errors: errorRange },
    handleSubmit: handleSubmitDates,
    setValue: setValueDates,
    getValues: getValuesDates,
    reset: resetDates,
    clearErrors: clearErrorsDates,
  } = useForm({});
  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(10);
  const { session } = useAuth();
  const [show, setShow] = useState(false);
  const [showModalDates, setShowModalDates] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [isClearableCompanies, setIsClearableCompanies] = useState(true);
  const [isClearableOperator, setIsClearableOperator] = useState(true);

  const DatePickerCustom = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      className='form-control'
      onClick={onClick}
      onChange={onChange}
      ref={ref}
      value={value}
      autoComplete='Off'
      {...register('date')}
    />
  ));
  const [dataDetail, setDataDetail] = useState([]);
  const [productPriceSelected, setProductPriceSelected] = useState(0);
  const [total, setTotal] = useState(0);
  const [customerSelected, setCustomerSelected] = useState(null);
  const [cityFound, setCityFound] = useState('');
  const [countryFound, setCountryFound] = useState('');
  const [departmentFound, setDepartmentFound] = useState('');
  const [neighborhoodFound, setNeighborhoodFound] = useState('');
  const [showAssign, setShowAssign] = useState(false);
  const [objOrden, setObjOrden] = useState(null);
  const [startDate2, setStartDate2] = useState(null);
  const [ordersError, setOrdersError] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [objOrderDetailEdit, setObjOrderDetailEdit] = useState(null);
  const [isReading, setIsReading] = useState(false);
  const [finishLoad, setFinishLoad] = useState(false);
  const DatePickerCustom2 = forwardRef(({ value, onClick, onChange }, ref) => (
    <>
      <input
        className={`form-control`}
        onClick={onClick}
        onChange={onChange}
        ref={ref}
        value={value}
        autoComplete='Off'
        {...registerSearch('date')}
      />
    </>
  ));

  const [deliveryDate, setDeliveryDate] = useState(null);

  const DatePickerCustomDeliveryDate = forwardRef(({ value, onClick, onChange, readOnly }, ref) => (
    <input
      disabled={readOnly}
      className='form-control'
      onClick={onClick}
      onChange={onChange}
      ref={ref}
      value={value}
      autoComplete='Off'
    />
  ));

  const indicativeRoad = useRef({});
  indicativeRoad.current = watch('indicativeRoad', '');

  const roadNumber = useRef({});
  roadNumber.current = watch('roadNumber', '');

  const appendixRoad = useRef({});
  appendixRoad.current = watch('appendixRoad', '');

  const crossoverRoad = useRef({});
  crossoverRoad.current = watch('crossoverRoad', '');

  const crossoverNumber = useRef({});
  crossoverNumber.current = watch('crossoverNumber', '');

  const appendixCrossingNumber = useRef({});
  appendixCrossingNumber.current = watch('appendixCrossingNumber', '');

  const orientationCrossingNumber = useRef({});
  orientationCrossingNumber.current = watch('orientationCrossingNumber', '');

  const insideTypes = useRef({});
  insideTypes.current = watch('insideTypes', '');

  const doorNumber = useRef({});
  doorNumber.current = watch('doorNumber', '');

  const inside = useRef({});
  inside.current = watch('inside', '');

  const city = useRef({});
  city.current = watch('city', '');

  const neighborhood = useRef({});
  neighborhood.current = watch('neighborhood', '');

  const country = useRef({});
  country.current = watch('country', '');

  const department = useRef({});
  department.current = watch('department', '');

  const handledChange = (date) => {
    setStartDate(date);
    setValue('date', moment(date).tz('America/Bogota').format('MM-DD-YYYY'));
  };

  const handleClose = () => {
    reduxResetOrderForm();
    setShow(false);
    setIsReading(false);
    setFinishLoad(false);
  };

  const handleShow = (row, readOnlyOrder) => {
    if (readOnlyOrder) {
      setIsReading(readOnlyOrder);
    } else {
      setIsReading(false);
    }
    reduxResetOrderForm();
    reset();
    resetAdd();
    setShow(true);
    setDataDetail([]);
    if (row.idOrder) {
      reduxGetOrder({
        id: row.idOrder,
      });
    } else {
      setStartDate(new Date());
      setValue('date', moment(new Date()).tz('America/Bogota').format('MM-DD-YYYY'));
      setValue('ammount', 0);
      setValue('comment', '');
      setTotal(0);
      setProductPriceSelected(0);
      setCustomerSelected(null);
      setShow(true);
    }
  };

  const [search, setSearch] = useState('');

  const columns = [
    {
      title: (rowData) => {
        return (
          <>
            <div className='dropdown btn btn-light options-btn'>
              <input
                id='all'
                onClick={(e) => checkAll(e)}
                type='checkbox'
                defaultChecked={false}
                {...registerSearch('all')}
              />
            </div>
          </>
        );
      },
      render: (rowData) => {
        return (
          <>
            {!rowData.idOperator && (
              <input
                name='ordersChecked'
                key={rowData.idOrder}
                type='checkbox'
                checked={rowData.isChecked}
                value={rowData.idOrder}
                onChange={(e) => {
                  rowData.isChecked = e.target.checked;
                }}
                {...registerSearch('ordersChecked')}
              />
            )}
          </>
        );
      },
    },
    {
      title: '#',
      render: (rowData) => {
        return <span>{rowData.idOrder}</span>;
      },
      field: 'idOrder',
      searchable: true,
    },
    {
      title: 'Ciudad',
      render: (rowData) => {
        return <span>{rowData.address.city ? rowData.address.city.description : ''}</span>;
      },
      field: 'address.city.description',
      searchable: true,
    },
    {
      title: 'Departamento',
      render: (rowData) => {
        return (
          <span>
            {rowData.address.city && rowData.address.city.department ? rowData.address.city.department.description : ''}
          </span>
        );
      },
      field: 'address.city.department.description',
      searchable: true,
    },
    ...(hasPermission('feature:show-recipient-address')
      ? [
        {
          title: 'Dirección Destinatario',
          render: (rowData) => {
            return <span>{rowData.address.address}</span>;
          },
          field: 'address.address',
          searchable: true,
        },
      ]
      : []),
    {
      title: 'Fecha de Entrega',
      render: (rowData) => {
        return <span>{rowData.address.deliveryDate && rowData.address.deliveryDate}</span>;
      },
    },
    {
      title: 'Cliente',
      render: (rowData) => {
        return (
          <span>
            {rowData.address.firstName} {rowData.address.lastName}
          </span>
        );
      },
      field: 'address.firstName-lastName',
      searchable: true,
    },
    {
      title: 'Guía',
      render: (rowData) => {
        return <span>{rowData.address.trackingId}</span>;
      },
      field: 'address.trackingId',
      searchable: true,
    },
    {
      title: 'Valor a Recaudar',
      render: (rowData) => {
        return <span>{rowData.ammount}</span>;
      },
      field: 'ammount',
      searchable: true,
    },
    {
      title: 'Compañia',
      render: (rowData) => {
        return <span>{rowData.company ? rowData.company.description : ''}</span>;
      },
      field: 'company.description',
      searchable: false,
    },
    {
      title: 'Correo',
      render: (rowData) => {
        return <span>{rowData.email}</span>;
      },
      field: 'email',
      searchable: true,
    },
    {
      title: 'Fecha',
      render: (rowData) => {
        return <span>{moment(rowData.date).tz('America/Bogota').format('DD-MM-YYYY')}</span>;
      },
    },
    {
      title: 'ID externo',
      render: (rowData) => {
        return <span>{rowData.externalId}</span>;
      },
      field: 'externalId',
      searchable: true,
    },
    {
      title: 'Operador',
      render: (rowData) => {
        return <span>{rowData.operator ? rowData.operator.description : ''}</span>;
      },
      field: 'operator.description',
      searchable: false,
    },
    {
      title: 'Estado',
      render: (rowData) => rowData.address.newState && getStatusBadgeByCode(rowData.address.newState.code),
      field: 'address.state',
      searchable: true,
    },
    {
      title: 'Opciones',
      render: (rowData) => {
        return (
          <>
            {rowData.isActive ? (
              <>
                <Show when='feature:read-order'>
                  <button
                    title='Ver'
                    className='btn btn-primary btn-sm btn-circle mr-2'
                    type='button'
                    onClick={(e) => handleShow(rowData, true)}>
                    <i className='fas fa-eye fa-xs'></i>
                  </button>
                </Show>
                {rowData.idOperator == null && (
                  <Show when='feature:edit-order'>
                    <button
                      title='Editar'
                      className='btn btn-primary btn-sm btn-circle mr-2'
                      type='button'
                      onClick={(e) => handleShow(rowData)}>
                      <i className='fas fa-edit fa-xs'></i>
                    </button>
                  </Show>
                )}
                <Show when='feature:disabled-order'>
                  <button
                    title='Desactivar'
                    className='btn btn-danger btn-sm btn-circle mr-2'
                    type='button'
                    onClick={(e) => handleDelete(rowData)}>
                    <i className='fas fa-times-circle fa-xs'></i>
                  </button>
                </Show>
                <Show when='feature:delete-order'>
                  <button
                    title='Eliminar'
                    className='btn btn-danger btn-sm btn-circle'
                    type='button'
                    onClick={(e) => handleDeleteSoft(rowData)}>
                    <i className='fas fa-trash-alt fa-xs'></i>
                  </button>
                </Show>
              </>
            ) : (
              <Show when='feature:enabled-order'>
                <button
                  title='Activar'
                  className='btn btn-primary btn-sm  btn-circle mr-2'
                  type='button'
                  onClick={(e) => handleActive(rowData)}>
                  <i className='fas fa-check-circle fa-xs'></i>
                </button>
              </Show>
            )}
          </>
        );
      },
    },
  ];

  const onSubmitAssign = (data) => {
    console.log(data);
    const objOrdersChecked = getValuesSearch('ordersChecked');
    if (objOrdersChecked == null || objOrdersChecked == []) {
      setOrdersError(true);
      return;
    }
    let objAddressDto;
    if (objOrdersChecked.length > 0) {
      objAddressDto = {
        idOperator: data.idOperator,
        ordersChecked: typeof objOrdersChecked == 'string' ? [objOrdersChecked] : objOrdersChecked,
        idBranch: Number(data.idBranch),
      };
      reduxPostBulkAddress(objAddressDto);
    }
  };

  const handleActive = (row) => {
    if (!row.isActive) {
      reduxDeleteOrder(row);
    }
  };

  useEffect(() => {
    if (rowEdited) {
      setValue('idCustomer', rowEdited.idCustomer);
      setValue('ammount', rowEdited.ammount);
      setValue('comment', rowEdited.address.comment);
      setValue('address', rowEdited.address.addressClient);
      setValue('email', rowEdited.email);
      setValue('externalId', rowEdited.externalId);
      setValue('senderEmail', rowEdited.address.senderEmail);
      setValue('senderPhone', rowEdited.address.senderPhone);
      setValue('deliveryDate', rowEdited.address.deliveryDate);

      setStartDate(new Date(rowEdited.date));
      setValue('date', moment(new Date(rowEdited.date)).tz('America/Bogota').format('MM-DD-YYYY'));
      setValue('idCompany', rowEdited.idCompany);
      setValue('currentAddress', rowEdited.address.address);
      // setValue("shippingAddress", null);
      if (rowEdited.address && rowEdited.address.boxedAddress != null) {
        reduxGetDepartmentsByCountry({
          idCountry: Number(rowEdited.address.boxedAddress.country),
        });
        // reduxGetCitiesByDepartment({
        //   idDepartment: Number(rowEdited.address.boxedAddress.department),
        // });
        // reduxGetNeighborhoodsByCity({
        //   idCity: Number(rowEdited.address.boxedAddress.city),
        // });
        setValue('indicativeRoad', rowEdited.address.boxedAddress.indicativeRoad);
        setValue('roadNumber', rowEdited.address.boxedAddress.roadNumber);
        setValue('appendixRoad', rowEdited.address.boxedAddress.appendixRoad);
        setValue('crossoverRoad', rowEdited.address.boxedAddress.crossoverRoad);
        setValue('crossoverNumber', rowEdited.address.boxedAddress.crossoverNumber);
        setValue('appendixCrossingNumber', rowEdited.address.boxedAddress.appendixCrossingNumber);
        setValue('orientationCrossingNumber', rowEdited.address.boxedAddress.orientationCrossingNumber);
        setValue('doorNumber', rowEdited.address.boxedAddress.doorNumber);
        setValue('insideTypes', rowEdited.address.boxedAddress.insideTypes);
        setValue('inside', rowEdited.address.boxedAddress.inside);
        setValue('country', rowEdited.address.boxedAddress.country);
        // setCountryFound(
        //   countries.items.filter(
        //     (c) => c.idCountry == Number(rowEdited.address.boxedAddress.country)
        //   )[0].description
        // );
        // setValue("department", rowEdited.address.boxedAddress.department);
        // setValue("city", rowEdited.address.boxedAddress.city);
        // setValue("neighborhood", rowEdited.address.boxedAddress.neighborhood);
      }
      if (rowEdited.orderDetails) {
        let arrayProducts = [];
        let resTotal = 0;
        for (let index = 0; index < rowEdited.orderDetails.length; index++) {
          const element = rowEdited.orderDetails[index];
          arrayProducts.push({
            idOrderDetail: element.idOrderDetail,
            idProduct: element.product.idProduct,
            productName: element.product.name,
            price: element.price,
            quantity: element.quantity,
            deleted: 0,
            edited: 0,
          });
          resTotal += element.price * element.quantity;
        }
        setDataDetail(arrayProducts);
        setTotal(resTotal);
      }
      // if (!isReading) {
      //   setTimeout(() => {
      //     setShow(true);
      //   }, 2000);
      // } else {
      //   setShow(true);
      // }
      // setShow(true);
      // if (isReading) {
      //   setShow(true);
      // }
    }
  }, [rowEdited]);

  const handleDelete = (row) => {
    reduxDeleteOrder(row);
  };

  useEffect(() => {
    reduxGetCompanies({
      page: 1,
      offset: 10000,
      search: '',
      isActive: true,
    });
    reduxGetOrders({
      page: 1,
      offset: offset,
      search: '',
    });
    reduxGetCustomers({
      page: 1,
      offset: 100000,
      search: '',
      isActive: true,
    });
    reduxGetProducts({
      page: 1,
      offset: 100000,
      search: '',
      isActive: true,
    });
    reduxGetCities({
      page: 1,
      offset: 100000,
      search: '',
      isActive: true,
    });
    reduxGetCountries({
      page: 1,
      offset: 1000,
      search: '',
      isActive: true,
    });
    bulkAddress = undefined;
    reduxGetBranches({
      page: 1,
      offset: 1000,
      search: '',
      isActive: true,
    });
  }, []);

  useEffect(() => {
    if (currentPage && orders && Object.keys(orders).length > 0) {
      let filters = {
        page: currentPage,
        offset: offset,
        search: search,
      };
      if (getValuesSearch('date')) {
        filters = {
          ...filters,
          date: moment(getValuesSearch('date')).tz('America/Bogota').format('MM-DD-YYYY'),
        };
      }
      if (getValuesSearch('deliveryDate')) {
        filters = {
          ...filters,
          deliveryDate: getValuesSearch('deliveryDate'),
        };
      }
      if (getValuesSearch('idOrder')) {
        filters = {
          ...filters,
          idOrder: getValuesSearch('idOrder'),
        };
      }
      if (getValuesSearch('idCompany')) {
        filters = {
          ...filters,
          idCompany: getValuesSearch('idCompany'),
        };
      }
      if (getValuesSearch('idOperator')) {
        filters = {
          ...filters,
          idOperator: getValuesSearch('idOperator'),
        };
      }
      reduxGetOrders(filters);
    }
  }, [currentPage]);

  const onSubmit = (data) => {
    if (dataDetail.filter((data) => data.deleted == 0).length == 0) {
      Swal.fire('Información!', 'La orden debe tener un detalle', 'warning');
      return;
    }
    const newAddress = `${indicativeRoad.current} ${roadNumber.current}${appendixRoad.current} ${crossoverRoad.current
      } ${crossoverNumber.current ? '#' : ''}${crossoverNumber.current}${appendixCrossingNumber.current}${orientationCrossingNumber.current ? ' ' : ''
      }${orientationCrossingNumber.current}${doorNumber.current ? '-' : ''}${doorNumber.current}${insideTypes.current ? ' ' + insideTypes.current + ' ' : ''
      }${inside.current}${neighborhood.current ? ',' : ''} ${neighborhood.current ? neighborhoodFound : ''}${city.current ? ', ' + cityFound : ''
      }${department.current ? ', ' + departmentFound : ''}${country.current ? ', ' + countryFound : ''}`;
    if (rowEdited) {
      reduxPatchOrder({
        ...data,
        id: rowEdited.idOrder,
        rowsDetails: dataDetail,
        ammount: Number(total),
        idAddress: Number(rowEdited.idAddress),
        date: moment(data.date).tz('America/Bogota').format('YYYY-MM-DD'),
        boxedAddress:
          newAddress.trim() != ''
            ? {
              indicativeRoad: data.indicativeRoad ? data.indicativeRoad : '',
              roadNumber: data.roadNumber ? data.roadNumber : '',
              appendixRoad: data.appendixRoad ? data.appendixRoad : '',
              crossoverRoad: data.crossoverRoad ? data.crossoverRoad : '',
              crossoverNumber: data.crossoverNumber ? data.crossoverNumber : '',
              appendixCrossingNumber: data.appendixCrossingNumber ? data.appendixCrossingNumber : '',
              orientationCrossingNumber: data.orientationCrossingNumber ? data.orientationCrossingNumber : '',
              doorNumber: data.doorNumber ? data.doorNumber : '',
              insideTypes: data.insideTypes ? data.insideTypes : '',
              inside: data.inside ? data.inside : '',
              country: data.country ? data.country : '',
              department: data.department ? data.department : '',
              city: data.city ? data.city : '',
              neighborhood: data.neighborhood ? data.neighborhood : '',
            }
            : rowEdited.boxedAddress,
        shippingAddress: newAddress.trim() != '' ? newAddress : rowEdited.shippingAddress,
        addressReceiver: newAddress.trim() != '' ? newAddress : rowEdited.addressReceiver,
        neighborhoodNameReceiver: neighborhood.current ? neighborhoodFound : '',
        cityNameReceiver: city.current ? cityFound : rowEdited.cityNameReceiver,
        stateNameReceiver: department.current ? departmentFound : rowEdited.stateNameReceiver,
        countryNameReceiver: country.current ? countryFound : rowEdited.countryNameReceiver,
      });
    } else {
      let calculatedTotal = 0;
      data.rowsDetails.forEach(detail => {
        calculatedTotal += detail.price;
      });

      const totalAmount = data.ammount || calculatedTotal;

      reduxPostOrder({
        ...data,
        date: moment(data.date).tz('America/Bogota').format('YYYY-MM-DD'),
        externalId: data.externalId ? data.externalId : null,
        rowsDetails: dataDetail,
        ammount: totalAmount,
        boxedAddress: newAddress
          ? {
            indicativeRoad: data.indicativeRoad ? data.indicativeRoad : '',
            roadNumber: data.roadNumber ? data.roadNumber : '',
            appendixRoad: data.appendixRoad ? data.appendixRoad : '',
            crossoverRoad: data.crossoverRoad ? data.crossoverRoad : '',
            crossoverNumber: data.crossoverNumber ? data.crossoverNumber : '',
            appendixCrossingNumber: data.appendixCrossingNumber ? data.appendixCrossingNumber : '',
            orientationCrossingNumber: data.orientationCrossingNumber ? data.orientationCrossingNumber : '',
            doorNumber: data.doorNumber ? data.doorNumber : '',
            insideTypes: data.insideTypes ? data.insideTypes : '',
            inside: data.inside ? data.inside : '',
            country: data.country ? data.country : '',
            department: data.department ? data.department : '',
            city: data.city ? data.city : '',
            neighborhood: data.neighborhood ? data.neighborhood : '',
          }
          : null,
        shippingAddress: newAddress,
        addressReceiver: newAddress,
        neighborhoodNameReceiver: neighborhood.current ? neighborhoodFound : '',
        cityNameReceiver: city.current ? cityFound : '',
        stateNameReceiver: department.current ? departmentFound : '',
        countryNameReceiver: country.current ? countryFound : '',
      });
    }
    reset();
    resetAdd();
    reduxResetOrderForm();
    setShow(false);
  };

  useEffect(() => {
    if (order || rowUpdated || rowDeleted || address || bulkAddress || rowDeletedSoft) {
      if (
        (order && order.message == undefined) ||
        (rowUpdated && rowUpdated.message == undefined) ||
        (rowDeletedSoft && rowDeletedSoft.message == undefined) ||
        rowDeleted ||
        address ||
        bulkAddress
      ) {
        reduxGetOrders({
          page: currentPage,
          offset: offset,
          search: search,
        });
        reduxResetOrderForm();
        setObjOrden(null);
        setShowAssign(false);
        setValueSearch('ordersChecked', []);
        setValueSearch('all', false);
        resetSearch();
      }
    }
  }, [order, rowUpdated, rowDeleted, address, bulkAddress, rowDeletedSoft]);

  const onSubmitAdd = () => {
    if (
      (getValuesAdd('idProduct') == '' || !getValuesAdd('idProduct')) &&
      (getValuesAdd('quantity') == '' || !getValuesAdd('quantity'))
    ) {
      Swal.fire('Información!', 'Debe ingresar producto y cantidad del producto.', 'warning');
      return;
    }
    if (getValuesAdd('idProduct') == '' || !getValuesAdd('idProduct')) {
      Swal.fire('Información!', 'Debe seleccionar producto.', 'warning');
      return;
    }
    if (getValuesAdd('quantity') == '' || !getValuesAdd('quantity')) {
      Swal.fire('Información!', 'Debe ingresar  la cantidad del producto.', 'warning');
      return;
    }
    const productExists = dataDetail.filter((det) => det.idProduct == getValuesAdd('idProduct') && det.deleted == 0);
    if (productExists.length > 0) {
      Swal.fire('Información!', 'El producto que intenta ingresar ya existe en el detalle', 'warning');
      resetAdd();
      setProductPriceSelected(0);
      return;
    }

    const objStockProduct = products.items.filter((prod) => prod.idProduct == getValuesAdd('idProduct'))[0].stock;
    if (Number(objStockProduct) < Number(getValuesAdd('quantity'))) {
      Swal.fire('Información!', 'No hay existencia de la cantidad de producto solicitada.', 'warning');
      return;
    }
    if (Number(getValuesAdd('quantity')) == 0) {
      Swal.fire('Información!', 'La cantidad es requerida mayor o igual a 1.', 'warning');
      return;
    }

    if (getValuesAdd('idProduct') && productPriceSelected != 0 && getValuesAdd('quantity')) {
      setValue(
        'ammount',
        total
          ? total + productPriceSelected
          : productPriceSelected
      );
      setTotal(
        total
          ? total + productPriceSelected
          : productPriceSelected
      );
      const productDeleted = dataDetail.filter((det) => det.idProduct == getValuesAdd('idProduct') && det.deleted == 1);
      if (productDeleted.length > 0) {
        productDeleted[0].deleted = 0;
        productDeleted[0].quantity = getValuesAdd('quantity');
        for (let index = 0; index < dataDetail.length; index++) {
          const element = dataDetail[index];
          if (element.idProduct == getValuesAdd('idProduct')) {
            dataDetail[index] = productDeleted[0];
          }
        }
      } else {
        setDataDetail([
          ...dataDetail,
          {
            idOrderDetail: 0,
            idOrder: rowEdited ? rowEdited.idOrder : 0,
            idProduct: getValuesAdd('idProduct'),
            productName: products.items.filter((prod) => prod.idProduct == getValuesAdd('idProduct'))[0].name,
            price: productPriceSelected,
            quantity: getValuesAdd('quantity'),
            deleted: 0,
            edited: 0,
          },
        ]);
      }
      resetAdd();
      setProductPriceSelected(0);
    }
  };

  useEffect(() => {
    if (orderDetails) {
      let total = [];
      for (let index = 0; index < orderDetails.length; index++) {
        const element = orderDetails[index];
        total.push({
          idOrderDetail: element.idOrderDetail,
          idOrder: element.idOrder,
          idProduct: element.idProduct,
          productName: element.product.name,
          price: element.price,
          quantity: element.quantity,
        });
      }
      setDataDetail(total);
    }
  }, [orderDetails]);

  const columnsDetail = [
    {
      title: 'ID Producto',
      render: (rowData) => {
        return <span>{rowData.idProduct}</span>;
      },
      field: 'idProduct',
      searchable: true,
    },
    {
      title: 'Producto',
      render: (rowData) => {
        return <span>{rowData.productName}</span>;
      },
    },
    {
      title: 'Precio',
      render: (rowData) => {
        return <span>{rowData.price}</span>;
      },
    },
    {
      title: 'Cantidad',
      render: (rowData) => {
        return <span>{rowData.quantity}</span>;
      },
    },
    ...(rowEdited && rowEdited.idOperator == null && !isReading
      ? [
        {
          title: 'Opciones',
          render: (rowData) => {
            return (
              <>
                <button
                  title='Editar'
                  className='btn btn-primary btn-sm btn-circle mr-2'
                  type='button'
                  onClick={(e) => handleEditDetail(rowData)}>
                  <i className='fas fa-edit fa-xs'></i>
                </button>
                <button
                  title='Eliminar'
                  className='btn btn-danger btn-sm btn-circle'
                  type='button'
                  onClick={(e) => handleDeleteDetail(rowData)}>
                  <i className='fas fa-trash-alt fa-xs'></i>
                </button>
              </>
            );
          },
        },
      ]
      : []),
  ];

  const handleEditDetail = (detail) => {
    setValueAdd('idProduct', detail.idProduct);
    setValueAdd('price', detail.price);
    setValueAdd('quantity', detail.quantity);
    setIsEditing(true);
    setObjOrderDetailEdit(detail);
  };

  const handleDeleteDetail = (detail) => {
    for (let index = 0; index < dataDetail.length; index++) {
      let element = dataDetail[index];
      if (element.idOrderDetail == detail.idOrderDetail && element.idProduct == detail.idProduct) {
        element = { ...element, deleted: 1, edited: 0 };
        dataDetail[index] = element;
        setValue('ammount', total - detail.price);
        setTotal(total - detail.price);
        break;
      }
    }
    setDataDetail(dataDetail);
    setObjOrderDetailEdit(null);
  };

  const onSubmitEdit = () => {
    if (Number(getValuesAdd('quantity')) == 0) {
      Swal.fire('Información!', 'La cantidad es requerida mayor o igual a 1.', 'warning');
      return;
    }
    const objRow = dataDetail.filter((det) => det.idProduct == objOrderDetailEdit.idProduct);
    if (Number(getValuesAdd('quantity')) > Number(objRow[0].quantity)) {
      const objStockProduct = products.items.filter((prod) => prod.idProduct == objOrderDetailEdit.idProduct)[0].stock;
      if (Number(objStockProduct) < Number(getValuesAdd('quantity')) - Number(objRow[0].quantity)) {
        Swal.fire('Información!', 'No hay existencia de la cantidad de producto solicitada.', 'warning');
        return;
      }
    }
    let count = 0;
    for (let index = 0; index < dataDetail.length; index++) {
      let element = dataDetail[index];
      if (element.idOrderDetail == objOrderDetailEdit.idOrderDetail) {
        element = {
          ...element,
          deleted: 0,
          edited: 1,
          quantity: Number(getValuesAdd('quantity')),
        };
        dataDetail[index] = element;
      }
      count += element.price;
    }
    setValue('ammount', count);
    setTotal(count);
    resetAdd();
    setProductPriceSelected(0);
    setIsEditing(false);
    setObjOrderDetailEdit(null);
  };

  const onSubmitCancel = () => {
    resetAdd();
    setProductPriceSelected(0);
    setIsEditing(false);
    setObjOrderDetailEdit(null);
  };

  const getPrice = (productSelected) => {
    const priceSelected = products.items.filter((prod) => prod.idProduct == Number(productSelected.target.value))[0]
      .price;
    setProductPriceSelected(Number(priceSelected));
    setValueAdd('price', Number(priceSelected));
  };

  const getAddress = (client) => {
    setCustomerSelected(null);
    if (client.target.value != '') {
      const clientSelected = customers.items.filter((cust) => cust.idCustomer == Number(client.target.value))[0];
      setCustomerSelected(clientSelected);
      if (clientSelected.billingAddress && clientSelected.billingAddress != '') {
        setValue('address', clientSelected.billingAddress);
      }
      if (clientSelected.billingAddress && clientSelected.email != '') {
        setValue('email', clientSelected.email);
      }
    }
  };

  useEffect(() => {
    if (country.current != '' && country.current != undefined && country.current != null) {
      if (
        rowEdited &&
        rowEdited.address.boxedAddress != null &&
        rowEdited.address.boxedAddress.country != country.current
      ) {
        rowEdited.address.boxedAddress.country = null;
        rowEdited.address.boxedAddress.department = null;
        rowEdited.address.boxedAddress.city = null;
        rowEdited.address.boxedAddress.neighborhood = null;
      }
      setValue('department', '');
      setValue('city', '');
      setValue('neighborhood', '');
      reduxSetDepartment();
      reduxSetCity();
      reduxSetNeighborhood();
      const countrySelected =
        countries &&
        Object.keys(countries).length > 0 &&
        countries.items.find((value) => value.idCountry == country.current);
      setCountryFound(countrySelected ? countrySelected.description : '');
      reduxGetDepartmentsByCountry({
        idCountry: country.current,
      });
    }
  }, [country.current]);

  useEffect(() => {
    if (
      departmentsByCountry &&
      Object.keys(departmentsByCountry).length > 0 &&
      rowEdited &&
      rowEdited.address.boxedAddress != null
    ) {
      setValue('department', rowEdited.address.boxedAddress.department);
    }
  }, [departmentsByCountry]);

  useEffect(() => {
    if (
      citiesByDepartment &&
      Object.keys(citiesByDepartment).length > 0 &&
      rowEdited &&
      rowEdited.address.boxedAddress != null
    ) {
      setValue('city', rowEdited.address.boxedAddress.city);
    }
  }, [citiesByDepartment]);

  useEffect(() => {
    if (
      neighborhoodsByCity &&
      Object.keys(neighborhoodsByCity).length > 0 &&
      rowEdited &&
      rowEdited.address.boxedAddress != null
    ) {
      setValue('neighborhood', rowEdited.address.boxedAddress.neighborhood);
    }
  }, [neighborhoodsByCity]);

  useEffect(() => {
    if (department.current != '' && department.current != undefined) {
      setValue('city', '');
      setValue('neighborhood', '');
      reduxSetCity();
      reduxSetNeighborhood();
      const departmentSelected =
        departmentsByCountry &&
        Object.keys(departmentsByCountry).length > 0 &&
        departmentsByCountry.find((value) => value.idDepartment == department.current);
      setDepartmentFound(departmentSelected ? departmentSelected.description : '');
      reduxGetCitiesByDepartment({
        idDepartment: department.current,
      });
    }
  }, [department.current]);

  useEffect(() => {
    if (city.current != '' && city.current != undefined) {
      setValue('neighborhood', '');
      reduxSetNeighborhood();
      const citySelected =
        citiesByDepartment &&
        Object.keys(citiesByDepartment).length > 0 &&
        citiesByDepartment.find((value) => value.idCity == city.current);
      setCityFound(citySelected ? citySelected.description : '');
      reduxGetNeighborhoodsByCity({
        idCity: city.current,
      });
    }
  }, [city.current]);

  useEffect(() => {
    if (neighborhood.current != '' && neighborhood.current != undefined) {
      const neighborhoodSelected =
        neighborhoodsByCity &&
        Object.keys(neighborhoodsByCity).length > 0 &&
        neighborhoodsByCity.find((value) => value.idNeighborhood == neighborhood.current);
      setNeighborhoodFound(neighborhoodSelected ? neighborhoodSelected.description : '');
    }
  }, [neighborhood.current]);

  useEffect(() => {
    setValue(
      'shippingAddress',
      `${indicativeRoad.current} ${roadNumber.current}${appendixRoad.current} ${crossoverRoad.current} ${crossoverNumber.current ? '#' : ''
      }${crossoverNumber.current}${appendixCrossingNumber.current}${orientationCrossingNumber.current ? ' ' : ''}${orientationCrossingNumber.current
      }${doorNumber.current ? '-' : ''}${doorNumber.current}${insideTypes.current ? ' ' + insideTypes.current + ' ' : ''
      }${inside.current}${neighborhood.current ? ',' : ''} ${neighborhood.current ? neighborhoodFound : ''}${city.current ? ', ' + cityFound : ''
      }${department.current ? ', ' + departmentFound : ''}${country.current ? ', ' + countryFound : ''}`
    );
  }, [
    indicativeRoad.current,
    roadNumber.current,
    appendixRoad.current,
    crossoverRoad.current,
    crossoverNumber.current,
    appendixCrossingNumber.current,
    orientationCrossingNumber.current,
    doorNumber.current,
    insideTypes.current,
    neighborhood.current,
    city.current,
    department.current,
    country.current,
    cityFound,
    departmentFound,
    countryFound,
    neighborhoodFound,
  ]);

  const handledChange2 = (date) => {
    setStartDate2(date);
    setValueSearch('date', moment(date).tz('America/Bogota').format('YYYY-MM-DD'));
  };

  const onSubmitSearch = (data) => {
    reduxGetOrders({
      ...data,
      page: 1,
      offset: offset,
      search: '',
    });
  };

  const checkAll = (e) => {
    if (e.target.checked) {
      setValueSearch(
        'ordersChecked',
        orders ? orders.items.filter((f) => f.idOperator == null).map((order) => order.idOrder.toString()) : []
      );
    } else {
      setValueSearch('ordersChecked', []);
      resetSearch();
    }
  };

  useEffect(() => {
    if (bulkAddress) {
      Swal.fire(
        'Proceso exitoso!',
        (bulkAddress.total == 1
          ? 'Se asignó ' + bulkAddress.total + ' orden '
          : 'Se asignaron ' + bulkAddress.total + ' órdenes ') +
        'al operador ' +
        bulkAddress.operator,
        'success'
      );
      setValueSearch('ordersChecked', []);
      setValueSearch('all', false);
      reduxResetOrderForm();
    }
  }, [bulkAddress]);

  const handleDeleteSoft = (row) => {
    reduxDeleteSoftOrder(row);
  };

  const handleShowDownload = () => {
    const token = window.localStorage.getItem('token');
    let URI = `${process.env.REACT_APP_REPORT_URL}/api/v1/orders/download?&start=${moment(
      getValuesDates('start')
    )
      .tz('America/Bogota')
      .format('YYYY-MM-DD')}&end=${moment(getValuesDates('end')).tz('America/Bogota').format('YYYY-MM-DD')}`;
    if (getValuesSearch('idCompany')) {
      URI += `&idCompany=${getValuesSearch('idCompany')}`;
    }
    if (getValuesSearch('date')) {
      URI += `&date=${getValuesSearch('date')}`;
    }
    if (getValuesSearch('deliveryDate')) {
      URI += `&deliveryDate=${getValuesSearch('deliveryDate')}`;
    }
    if (getValuesSearch('idOrder')) {
      URI += `&idOrder=${getValuesSearch('idOrder')}`;
    }
    if (getValuesSearch('idOperator')) {
      URI += `&idOperator=${getValuesSearch('idOperator')}`;
    }
    fetch(URI, {
      method: 'POST',
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      }),
    })
      .then((response) => response.blob())
      .then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = `${moment(Date.now()).tz('America/Bogota').format('YYYY-MM-DD')}-orders.xlsx`;
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
  };

  const openModalDates = () => {
    setShowModalDates(true);
    resetDates();
  };

  const handleDownload = () => {
    if (moment(getValuesDates('start')) > moment(getValuesDates('end'))) {
      Swal.fire('Información', 'La fecha inicio debe ser menor o igual a la fecha fin', 'info');
      return;
    }

    setShowModalDates(false);

    Swal.fire(
      'Información',
      'El proceso puede tardar un poco... al finalizar se abrirá el Excel con la información.',
      'info'
    );
    handleShowDownload();
  };

  const renderTooltip = (title) => <Tooltip id='button-tooltip'>{title}</Tooltip>;

  const handledDeliveryDate = (date) => {
    setDeliveryDate(moment(date).tz('America/Bogota').format('YYYY-MM-DD'));
    setValueSearch('deliveryDate', moment(date).tz('America/Bogota').format('YYYY-MM-DD'));
  };

  return (
    <div>
      <div id='wrapper'>
        <Sidebar />
        <div
          id='content-wrapper'
          className='d-flex flex-column'>
          <div id='content'>
            <Topbar />
            <div className='container-fluid'>
              <div className='d-sm-flex align-items-center mb-1'>
                <h1 className='h3 mb-0 text-gray-800 mr-3'>Órdenes</h1>
                <div>
                  <Show when='feature:create-order'>
                    <OverlayTrigger
                      placement='top'
                      delay={{ show: 50, hide: 50 }}
                      overlay={renderTooltip('Crear órden')}>
                      <button
                        className='btn btn-primary btn-circle'
                        type='button'
                        onClick={handleShow}>
                        <i className='fas fa-plus fa-sm'></i>
                      </button>
                    </OverlayTrigger>
                  </Show>
                </div>
                <div className='ml-2'>
                  <Show when='feature:download-order'>
                    <OverlayTrigger
                      placement='top'
                      delay={{ show: 50, hide: 50 }}
                      overlay={renderTooltip('Descargar órdenes')}>
                      <button
                        className='btn btn-danger btn-circle'
                        type='button'
                        onClick={(e) => openModalDates()}>
                        <i className='fas fa-download fa-sm'></i>
                      </button>
                    </OverlayTrigger>
                  </Show>
                </div>
              </div>
              <p className='mb-4'>Módulo de Administración de órdenes</p>
              <form onSubmit={handleSubmitSearch(onSubmitSearch)}>
                <div className='row'>
                  <div className='col s6 m2'>
                    <div className='form-group'>
                      <label
                        htmlFor='date'
                        className='form-label'>
                        Fecha
                      </label>
                      <div>
                        <DatePicker
                          selected={startDate2}
                          onChange={(date) => handledChange2(date)}
                          dateFormat='MM-dd-yyyy'
                          customInput={<DatePickerCustom2 />}
                          maxDate={new Date()}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='col s6 m2'>
                    <div className='form-group'>
                      <label
                        htmlFor='idOrder'
                        className='form-label'>
                        ID orden
                      </label>
                      <input
                        id='idOrder'
                        type='number'
                        className={`form-control form-control-user ${errorsSearch.idOrder && 'is-invalid'}`}
                        {...registerSearch('idOrder')}
                      />
                    </div>
                  </div>
                  <div className='col s6 m4'>
                    <div className='form-group'>
                      <label
                        htmlFor='idCompany'
                        className='form-label'>
                        Compañia
                      </label>
                      <Select
                        isClearable={isClearableCompanies}
                        {...registerSearch('idCompany')}
                        id='idCompany'
                        onChange={(value) => setValueSearch('idCompany', value?.value || '')}
                        options={
                          companies &&
                          Object.keys(companies).length > 0 &&
                          companies.items
                            .filter((f) => f.isActive === true)
                            .map((ele, key) => ({
                              value: ele.idCompany,
                              label: ele.description,
                            }))
                        }
                      />
                    </div>
                  </div>
                  <div className='col s6 m4'>
                    <div className='form-group'>
                      <label
                        htmlFor='idOperator'
                        className='form-label'>
                        Operador
                      </label>
                      <Select
                        isClearable={isClearableOperator}
                        {...registerSearch('idOperator')}
                        id='idOperator'
                        onChange={(value) => setValueSearch('idOperator', value?.value || '')}
                        options={
                          companies &&
                          Object.keys(companies).length > 0 &&
                          companies.items
                            .filter((f) => f.isActive === true)
                            .map((ele, key) => ({
                              value: ele.idCompany,
                              label: ele.description,
                            }))
                        }
                      />
                    </div>
                  </div>
                  <div className='col col-md-2'>
                    <div className='form-group'>
                      <label
                        htmlFor='to'
                        className='form-label'>
                        Fecha de entrega
                      </label>
                      <div>
                        <DatePicker
                          {...registerSearch('deliveryDate')}
                          selected={deliveryDate !== null ? moment(deliveryDate).tz('America/Bogota').toDate() : null}
                          onChange={(date) =>
                            handledDeliveryDate(date !== null ? moment(date).tz('America/Bogota').toDate() : null)
                          }
                          dateFormat='yyyy-MM-dd'
                          customInput={<DatePickerCustomDeliveryDate />}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='col s12 m1'>
                    <Button
                      variant='primary'
                      style={{ marginTop: '32px' }}
                      type='submit'
                      id='btnSearch'>
                      Buscar
                    </Button>
                  </div>
                </div>
                <div className='card shadow mb-4'>
                  <div className='card-header py-3'>
                    <h6 className='m-0 font-weight-bold text-primary'>Listado de Órdenes</h6>
                  </div>
                  <div className='card-body'>
                    <Grid
                      cols={columns}
                      data={orders && Object.keys(orders).length > 0 ? orders.items : []}
                      page={orders && Object.keys(orders).length > 0 ? Number(orders.page) : currentPage}
                      pages={orders && Object.keys(orders).length > 0 ? Number(orders.totalPages) : 1}
                      total={orders && orders.hasOwnProperty('total') ? orders.total : 0}
                      offset={offset}
                      onChangePage={(page) => setCurrentPage(page)}
                      onChangeRange={(value) => {
                        setOffset(value);
                        let filters = {
                          page: 1,
                          offset: value,
                          search: search,
                        };
                        if (getValuesSearch('date')) {
                          filters = {
                            ...filters,
                            date: moment(getValuesSearch('date')).tz('America/Bogota').format('MM-DD-YYYY'),
                          };
                        }
                        if (getValuesSearch('deliveryDate')) {
                          filters = {
                            ...filters,
                            deliveryDate: 'deliveryDate',
                          };
                        }

                        if (getValuesSearch('idOrder')) {
                          filters = {
                            ...filters,
                            idOrder: getValuesSearch('idOrder'),
                          };
                        }
                        if (getValuesSearch('idCompany')) {
                          filters = {
                            ...filters,
                            idCompany: getValuesSearch('idCompany'),
                          };
                        }
                        if (getValuesSearch('idOperator')) {
                          filters = {
                            ...filters,
                            idOperator: getValuesSearch('idOperator'),
                          };
                        }
                        reduxGetOrders(filters);
                      }}
                      defaultValue={search}
                      onChangeSearch={(value) => {
                        setSearch(value);
                        let filters = {
                          page: 1,
                          offset: offset,
                          search: value,
                        };
                        if (getValuesSearch('date')) {
                          filters = {
                            ...filters,
                            date: moment(getValuesSearch('date')).tz('America/Bogota').format('MM-DD-YYYY'),
                          };
                        }
                        if (getValuesSearch('deliveryDate')) {
                          filters = {
                            ...filters,
                            deliveryDate: 'deliveryDate',
                          };
                        }
                        if (getValuesSearch('idOrder')) {
                          filters = {
                            ...filters,
                            idOrder: getValuesSearch('idOrder'),
                          };
                        }
                        if (getValuesSearch('idCompany')) {
                          filters = {
                            ...filters,
                            idCompany: getValuesSearch('idCompany'),
                          };
                        }
                        if (getValuesSearch('idOperator')) {
                          filters = {
                            ...filters,
                            idOperator: getValuesSearch('idOperator'),
                          };
                        }
                        reduxGetOrders(filters);
                      }}
                    />
                  </div>
                </div>
              </form>
              
            </div>
          </div>
        </div>
      </div>
      <div>
        <Modal
          show={show}
          onHide={handleClose}
          onEscapeKeyDown={handleClose}
          backdrop='static'
          keyboard={true}
          size='xl'>
          <Modal.Header closeButton>
            <Modal.Title>{!rowEdited ? 'Nueva ' : isReading ? 'Ver detalle de la ' : 'Editar '} orden</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                  <div className='row'>
                    <div className='col s12 m4'>
                      <div className='form-group'>
                        <label
                          htmlFor='idCustomer'
                          className='form-label'>
                          Cliente
                        </label>
                        <select
                          {...register('idCustomer', { required: true })}
                          id='idCustomer'
                          className={`custom-select ${((!rowEdited && errors.idCustomer) ||
                            (rowEdited && errors.idCustomer && getValues('idCustomer') == '')) &&
                            'is-invalid'
                            }`}
                          onChange={(client) => getAddress(client)}
                          disabled={isReading ? true : false}>
                          <option value={''}>Seleccionar…</option>
                          {customers &&
                            Object.keys(customers).length > 0 &&
                            customers.items.map((ele, key) => (
                              <option
                                key={key}
                                value={ele.idCustomer}>
                                {ele.firstName} {ele.lastName}
                              </option>
                            ))}
                        </select>
                        {((!rowEdited && errors.idCustomer) ||
                          (rowEdited && errors.idCustomer && getValues('idCustomer') == '')) && (
                            <span className='invalid-feedback'>El cliente es requerido</span>
                          )}
                      </div>
                    </div>
                    <div className='col s12 m4'>
                      <div className='form-group'>
                        <label
                          htmlFor='address'
                          className='form-label'>
                          Dirección
                        </label>
                        <input
                          id='address'
                          type='text'
                          className={`form-control form-control-user ${((!rowEdited && errors.address) ||
                            (rowEdited && errors.address && getValues('address') == '')) &&
                            'is-invalid'
                            }`}
                          {...register('address', { required: true })}
                          disabled={isReading ? true : false}
                        />
                        {((!rowEdited && errors.address) ||
                          (rowEdited && errors.address && getValues('address') == '')) && (
                            <span className='invalid-feedback'>La dirección es requerida</span>
                          )}
                      </div>
                    </div>
                    <div className='col s12 m4'>
                      <div className='form-group'>
                        <div className='form-group'>
                          <label
                            htmlFor='email'
                            className='form-label'>
                            Correo
                          </label>
                          <input
                            id='email'
                            type='text'
                            className={`form-control form-control-user ${((!rowEdited && errors.email) ||
                              (rowEdited && errors.email && getValues('email') == '')) &&
                              'is-invalid'
                              }`}
                            {...register('email', { required: true })}
                            disabled={isReading ? true : false}
                          />
                          {((!rowEdited && errors.email) ||
                            (rowEdited && errors.email && getValues('email') == '')) && (
                              <span className='invalid-feedback'>El correo es requerido</span>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col s12 m4'>
                      <div className='form-group'>
                        <label
                          htmlFor='date2'
                          className='form-label'>
                          Fecha
                        </label>
                        <div>
                          <DatePicker
                            selected={startDate}
                            onChange={(date) => handledChange(date)}
                            dateFormat='MM-dd-yyyy'
                            customInput={<DatePickerCustom />}
                            required
                            disabled={isReading ? true : false}
                            maxDate={new Date()}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='col s12 m4'>
                      <div className='form-group'>
                        <label
                          htmlFor='externalId'
                          className='form-label'>
                          ID externo
                        </label>
                        <input
                          id='externalId'
                          type='text'
                          className={`form-control form-control-user ${errors.externalId && 'is-invalid'}`}
                          {...register('externalId')}
                          disabled={isReading ? true : false}
                        />
                      </div>
                    </div>
                    <div className='col s12 m4'>
                      <div className='form-group'>
                        <label
                          htmlFor='deliveryDate'
                          className='form-label'>
                          Fecha de entrega
                        </label>
                        <input
                          id='deliveryDate'
                          type='date'
                          className={`form-control form-control-user ${errors.deliveryDate && 'is-invalid'}`}
                          {...register('deliveryDate')}
                          disabled={isReading}
                        />
                      </div>
                    </div>
                    <div className='col s12 m4'>
                      <div className='form-group'>
                        <label
                          htmlFor='senderEmail'
                          className='form-label'>
                          Correo remitente
                        </label>
                        <input
                          id='senderEmail'
                          type='text'
                          className={`form-control form-control-user ${errors.senderEmail && 'is-invalid'}`}
                          {...register('senderEmail', {
                            pattern: {
                              value: /^(?:[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,6})?$/i,
                              message: 'Por favor introduzca una dirección de correo electrónico válida',
                            },
                          })}
                          disabled={isReading ? true : false}
                        />
                        {!rowEdited && errors.senderEmail && (
                          <span className='invalid-feedback'>{errors.senderEmail.message}</span>
                        )}
                      </div>
                    </div>
                    <div className='col s12 m4'>
                      <div className='form-group'>
                        <label
                          htmlFor='senderPhone'
                          className='form-label'>
                          Telefono del remitente
                        </label>
                        <input
                          id='senderPhone'
                          type='text'
                          className={`form-control form-control-user ${errors.senderPhone && 'is-invalid'}`}
                          {...register('senderPhone', {
                            pattern: {
                              value: /^(\s*|\d+)$/i,
                              message: 'Por favor introduzca un numero de telefono valido',
                            },
                          })}
                          disabled={isReading ? true : false}
                        />
                        {!rowEdited && errors.senderPhone && (
                          <span className='invalid-feedback'>{errors.senderPhone.message}</span>
                        )}
                      </div>
                    </div>
                  </div>
                  {rowEdited && (
                    <div className='row'>
                      <div className='col s12 m12'>
                        <h6 className='m-0 font-weight-bold text-primary'>Dirección de envío actual</h6>
                        <input
                          id='currentAddress'
                          readOnly='true'
                          type='text'
                          className={`form-control form-control-user ${errors.name && 'is-invalid'}`}
                          {...register('currentAddress')}
                        />
                      </div>
                    </div>
                  )}
                  <br />
                  {(!rowEdited || (rowEdited.idOperator == null && !isReading)) && (
                    <div
                      style={{
                        border: '1px solid #e3e6f0',
                        borderRadius: '0.35rem ',
                        margin: '20px 0px 10px 0px',
                      }}>
                      <div
                        className='row'
                        style={{ marginLeft: '5px', marginRight: '5px' }}>
                        <div className='col s12 m6'>
                          <div className='form-group'>
                            <label
                              htmlFor='shippingAddress'
                              className='form-label'>
                              Generador de direcciones
                            </label>
                            <input
                              {...register('shippingAddress', {
                                required: rowEdited ? false : true,
                              })}
                              id='shippingAddress'
                              type='text'
                              className={`form-control form-control-user ${!rowEdited && errors.shippingAddress && 'is-invalid'
                                }`}
                              disabled={true}
                            />
                            {!rowEdited && errors.shippingAddress && (
                              <span className='invalid-feedback'>La dirección de envío es requerida</span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div
                        className='row'
                        style={{ marginLeft: '5px' }}>
                        <div className='col s12 m12'>
                          <div className='mb-2'>
                            <h6 className='m-0 font-weight-bold text-primary'>Vía</h6>
                          </div>
                        </div>
                      </div>
                      <div
                        className='row'
                        style={{
                          border: '1px solid #e3e6f0',
                          borderRadius: '0.35rem ',
                          marginLeft: '5px',
                          marginRight: '5px',
                        }}>
                        <div className='form-group col-md-3'>
                          <label
                            htmlFor='indicativeRoad'
                            className='form-label'>
                            Indicativo de la vía
                          </label>
                          <InputList
                            id={'indicativeRoad'}
                            type={SelectConstEnabled._addressRoadType}
                            value={getValues('indicativeRoad')}
                            required={true}></InputList>
                          {errors.indicativeRoad && (
                            <span className='invalid-feedback'>El indicativo es requerido</span>
                          )}
                        </div>
                        <div className='form-group col-md-3'>
                          <label
                            htmlFor='roadNumber'
                            className='form-label'>
                            Numero de la vía
                          </label>
                          <input
                            {...register('roadNumber', {
                              required: true,
                            })}
                            id='roadNumber'
                            type='text'
                            className={`form-control form-control-user ${errors.roadNumber && 'is-invalid'}`}
                          />
                          {errors.roadNumber && <span className='invalid-feedback'>El número es requerido</span>}
                        </div>
                        <div className='form-group col-md-3'>
                          <label
                            htmlFor='appendixRoad'
                            className='form-label'>
                            Apéndice
                          </label>
                          <InputList
                            id={'appendixRoad'}
                            type={SelectConstEnabled._addressNomenclature}
                            value={getValues('appendixRoad')}
                            required={false}></InputList>
                        </div>
                        <div className='form-group col-md-3'>
                          <label
                            htmlFor='crossoverRoad'
                            className='form-label'>
                            Orientación
                          </label>
                          <InputList
                            id={'crossoverRoad'}
                            type={SelectConstEnabled._addressCardinal}
                            value={getValues('crossoverRoad')}
                            required={false}></InputList>
                        </div>
                      </div>
                      <div
                        className='row'
                        style={{ marginLeft: '5px', marginTop: '5px' }}>
                        <div className='col s12 m12'>
                          <div className='mb-2'>
                            <h6 className='m-0 font-weight-bold text-primary'>Cruce</h6>
                          </div>
                        </div>
                      </div>
                      <div
                        className='row'
                        style={{
                          border: '1px solid #e3e6f0',
                          borderRadius: '0.35rem ',
                          marginLeft: '5px',
                          marginRight: '5px',
                        }}>
                        <div className='form-group col-md-3'>
                          <label
                            htmlFor='crossoverNumber'
                            className='form-label'>
                            Numero de cruce
                          </label>
                          <input
                            {...register('crossoverNumber', {
                              required: true,
                            })}
                            id='crossoverNumber'
                            type='text'
                            className={`form-control form-control-user ${errors.crossoverNumber && 'is-invalid'}`}
                          />
                          {errors.crossoverNumber && <span className='invalid-feedback'>El número es requerido</span>}
                        </div>
                        <div className='form-group col-md-3'>
                          <label
                            htmlFor='appendixCrossingNumber'
                            className='form-label'>
                            Apéndice
                          </label>
                          <InputList
                            id={'appendixCrossingNumber'}
                            type={SelectConstEnabled._addressNomenclature}
                            value={getValues('appendixCrossingNumber')}
                            required={false}></InputList>
                        </div>
                        <div className='form-group col-md-3'>
                          <label
                            htmlFor='orientationCrossingNumber'
                            className='form-label'>
                            Orientación
                          </label>
                          <InputList
                            id={'orientationCrossingNumber'}
                            type={SelectConstEnabled._addressCardinal}
                            value={getValues('orientationCrossingNumber')}
                            required={false}></InputList>
                        </div>
                        <div className='form-group col-md-3'>
                          <label
                            htmlFor='doorNumber'
                            className='form-label'>
                            Numero de la puerta
                          </label>
                          <input
                            {...register('doorNumber', {
                              required: true,
                            })}
                            id='doorNumber'
                            type='text'
                            className={`form-control form-control-user ${errors.doorNumber && 'is-invalid'}`}
                          />
                          {errors.doorNumber && <span className='invalid-feedback'>El número es requerido</span>}
                        </div>
                      </div>
                      <div
                        className='row'
                        style={{
                          marginLeft: '5px',
                          marginTop: '5px',
                          marginRight: '5px',
                        }}>
                        <div className='form-group col-md-3'>
                          <label
                            htmlFor='insideTypes'
                            className='form-label'>
                            Tipos de Interior
                          </label>
                          <InputList
                            id={'insideTypes'}
                            type={SelectConstEnabled._addressInsideType}
                            value={getValues('insideTypes')}
                            required={false}></InputList>
                        </div>
                        <div className='form-group col-md-3'>
                          <label
                            htmlFor='inside'
                            className='form-label'>
                            Interior
                          </label>
                          <input
                            {...register('inside')}
                            id='inside'
                            type='text'
                            className={`form-control`}
                          />
                        </div>
                      </div>
                      <div
                        className='row'
                        style={{
                          marginLeft: '5px',
                          marginRight: '5px',
                        }}>
                        <div className='form-group col-md-3'>
                          <label
                            htmlFor='country'
                            className='form-label'>
                            Pais
                          </label>
                          <select
                            {...register('country', {
                              required: true,
                            })}
                            id='country'
                            className={`custom-select ${((!rowEdited && errors.country) ||
                              (rowEdited && errors.country && getValues('country') == '')) &&
                              'is-invalid'
                              }`}>
                            <option value={''}>Seleccionar…</option>
                            {countries &&
                              Object.keys(countries).length > 0 &&
                              countries.items.map((ele, key) => (
                                <option
                                  key={key}
                                  value={ele.idCountry}>
                                  {ele.description}
                                </option>
                              ))}
                          </select>
                          {((!rowEdited && errors.country) ||
                            (rowEdited && errors.country && getValues('country') == '')) && (
                              <span className='invalid-feedback'>El pais es requerido</span>
                            )}
                        </div>
                        <div className='form-group col-md-3'>
                          <label
                            htmlFor='department'
                            className='form-label'>
                            Departamento
                          </label>
                          <select
                            {...register('department', {
                              required: true,
                            })}
                            id='department'
                            className={`custom-select ${((!rowEdited && errors.department) ||
                              (rowEdited && errors.department && getValues('department') == '')) &&
                              'is-invalid'
                              }`}>
                            <option value={''}>Seleccionar…</option>
                            {departmentsByCountry &&
                              Object.keys(departmentsByCountry).length > 0 &&
                              departmentsByCountry.map((ele, key) => (
                                <option
                                  key={key}
                                  value={ele.idDepartment}>
                                  {ele.description}
                                </option>
                              ))}
                          </select>
                          {((!rowEdited && errors.department) ||
                            (rowEdited && errors.department && getValues('department') == '')) && (
                              <span className='invalid-feedback'>El departamento es requerido</span>
                            )}
                        </div>
                        <div className='form-group col-md-3'>
                          <label
                            htmlFor='city'
                            className='form-label'>
                            Ciudad
                          </label>
                          <select
                            {...register('city', {
                              required: true,
                            })}
                            id='city'
                            className={`custom-select ${((!rowEdited && errors.city) || (rowEdited && errors.city && getValues('city') == '')) &&
                              'is-invalid'
                              }`}>
                            <option value={''}>Seleccionar…</option>
                            {citiesByDepartment &&
                              Object.keys(citiesByDepartment).length > 0 &&
                              citiesByDepartment.map((ele, key) => (
                                <option
                                  key={key}
                                  value={ele.idCity}>
                                  {ele.description}
                                </option>
                              ))}
                          </select>
                          {((!rowEdited && errors.city) || (rowEdited && errors.city && getValues('city') == '')) && (
                            <span className='invalid-feedback'>La ciudad es requerida</span>
                          )}
                        </div>
                        <div className='form-group col-md-3'>
                          <label
                            htmlFor='neighborhood'
                            className='form-label'>
                            Barrio
                          </label>
                          <select
                            {...register('neighborhood', {
                              required: true,
                            })}
                            id='neighborhood'
                            className={`custom-select ${((!rowEdited && errors.neighborhood) ||
                              (rowEdited && errors.neighborhood && getValues('neighborhood') == '')) &&
                              'is-invalid'
                              }`}>
                            <option value={''}>Seleccionar…</option>
                            {neighborhoodsByCity &&
                              Object.keys(neighborhoodsByCity).length > 0 &&
                              neighborhoodsByCity.map((ele, key) => (
                                <option
                                  key={key}
                                  value={ele.idNeighborhood}>
                                  {ele.description}
                                </option>
                              ))}
                          </select>
                          {((!rowEdited && errors.neighborhood) ||
                            (rowEdited && errors.neighborhood && getValues('neighborhood') == '')) && (
                              <span className='invalid-feedback'>El Barrio es requerido</span>
                            )}
                        </div>
                        {session && session.role.idCompany == null && (
                          <div className='form-group col-md-3'>
                            <label
                              htmlFor='idCompany'
                              className='form-label'>
                              Empresa
                            </label>
                            <select
                              {...register('idCompany', {
                                required: true,
                              })}
                              id='idCompany'
                              className={`custom-select ${errors.idCompany && 'is-invalid'}`}>
                              <option value={''}>Seleccionar…</option>
                              {companies &&
                                Object.keys(companies).length > 0 &&
                                companies.items
                                  .filter((f) => f.isActive == true)
                                  .map((ele, key) => (
                                    <option
                                      key={key}
                                      value={ele.idCompany}>
                                      {ele.description}
                                    </option>
                                  ))}
                            </select>
                            {errors.idCompany && <span className='invalid-feedback'>La empresa es requerida</span>}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
                <form>
                  <div className='card'>
                    <h5 className='card-header'>Detalle</h5>
                    <div className='card-body'>
                      {/* {(!rowEdited || (rowEdited && rowEdited.idOperator == null) || !isReading ) && ( */}
                      <div className='row'>
                        <div className='col s12 m3'>
                          <div className='form-group'>
                            <label
                              htmlFor='idProduct'
                              className='form-label'>
                              Producto
                            </label>
                            <select
                              {...registerAdd('idProduct', {
                                required: true,
                              })}
                              id='idProduct'
                              className={`custom-select ${errorsAdd.status && 'is-invalid'}`}
                              onChange={(product) => getPrice(product)}
                              disabled={
                                isReading || (rowEdited && rowEdited.idOperator != null) || isEditing ? true : false
                              }>
                              <option value={''}>Seleccionar…</option>
                              {products &&
                                Object.keys(products).length > 0 &&
                                products.items
                                  .filter((p) => p.stock && Number(p.stock) > 0)
                                  .map((ele, key) => (
                                    <option
                                      key={key}
                                      value={ele.idProduct}>
                                      {ele.name}
                                    </option>
                                  ))}
                            </select>
                            {errorsAdd.idProduct && <span className='invalid-feedback'>El producto es requerido</span>}
                          </div>
                        </div>
                        <div className='col s12 m3'>
                          <div className='form-group'>
                            <label
                              htmlFor='price'
                              className='form-label'>
                              Precio
                            </label>
                            <input
                              id='price'
                              type='number'
                              className={`form-control form-control-user ${errorsAdd.price && 'is-invalid'}`}
                              {...registerAdd('price', { required: true })}
                              disabled={true}
                            />
                            {errorsAdd.price && <span className='invalid-feedback'>El precio es requerido</span>}
                          </div>
                        </div>
                        <div className='col s12 m3'>
                          <div className='form-group'>
                            <label
                              htmlFor='quantity'
                              className='form-label'>
                              Cantidad
                            </label>
                            <input
                              id='quantity'
                              type='number'
                              className={`form-control form-control-user ${errorsAdd.quantity && 'is-invalid'}`}
                              {...registerAdd('quantity', { required: true })}
                              disabled={isReading || (rowEdited && rowEdited.idOperator != null) ? true : false}
                            />
                            {errorsAdd.quantity && <span className='invalid-feedback'>La cantidad es requerida</span>}
                          </div>
                        </div>
                        <div className='col s12 m3'>
                          {!isEditing && (
                            <Button
                              variant='primary'
                              style={{ marginTop: '32px' }}
                              onClick={onSubmitAdd}
                              disabled={isReading || (rowEdited && rowEdited.idOperator != null) ? true : false}>
                              Agregar
                            </Button>
                          )}
                          {isEditing && (
                            <>
                              <Button
                                variant='primary'
                                style={{
                                  marginTop: '32px',
                                  marginRight: '10px',
                                }}
                                onClick={onSubmitEdit}>
                                Editar
                              </Button>
                              <Button
                                variant='primary'
                                style={{ marginTop: '32px' }}
                                onClick={onSubmitCancel}>
                                Cancelar
                              </Button>
                            </>
                          )}
                        </div>
                      </div>
                      {/* )} */}
                      <div style={{ overflowY: 'scroll' }}>
                        <Grid
                          cols={columnsDetail}
                          offset={offset}
                          data={
                            dataDetail && Object.keys(dataDetail).length > 0
                              ? dataDetail.filter((d) => d.deleted == 0)
                              : []
                          }
                          total={dataDetail && dataDetail.hasOwnProperty('total') ? dataDetail.total : 0}
                        />
                      </div>
                    </div>
                  </div>
                </form>

                <div
                  style={{
                    marginTop: '5px',
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}>
                  <label
                    htmlFor='ammount'
                    className='form-label'
                    style={{ marginTop: '5px' }}>
                    Monto
                  </label>
                  <input
                    id='ammount'
                    type='number'
                    style={{
                      width: '250px',
                      textAlign: 'right',
                      marginLeft: '10px',
                    }}
                    className={`form-control form-control-user ${!rowEdited && errors.ammount && 'is-invalid'}`}
                    {...register('ammount')}
                    disabled={true}
                  />
                </div>
                <div className='col s12 m4'>
                  <div className='form-group'>
                    <label
                      htmlFor='address'
                      className='form-label'>
                      Observaciones
                    </label>
                    <input
                      maxLength={300}
                      id='comment'
                      type='text'
                      className={`form-control form-control-user `}
                      {...register('comment', { required: false })}
                      disabled={isReading ? true : false}
                    />
                  </div>
                </div>
                <div>
                  {(!rowEdited || (rowEdited.idOperator == null && !isReading)) && (
                    <div style={{ textAlignLast: 'center', marginTop: '10px' }}>
                      <Button
                        variant='primary'
                        type='submit'>
                        {'Guardar'}
                      </Button>
                    </div>
                  )}
                </div>
              </form>
            </FormProvider>
          </Modal.Body>
        </Modal>
      </div>
      <div>
        <Modal
          size='md'
          show={showModalDates}
          onHide={() => setShowModalDates(false)}
          onEscapeKeyDown={() => setShowModalDates(false)}
          backdrop='static'
          keyboard={true}>
          <Modal.Header closeButton>
            <Modal.Title>{'Descargar órdenes'}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleSubmitDates(handleDownload)}>
              <div className={'row'}>
                <div className={'col-6 form-group'}>
                  <label>Desde</label>
                  <input
                    type='date'
                    className={`form-control ${errorRange.start && 'is-invalid'}`}
                    name={'start'}
                    {...registerDates('start', { required: true })}
                    max={moment().tz('America/Bogota').format('YYYY-MM-DD')}
                  // onChange={(e) =>
                  //   setValueDates("start", moment(e).tz("America/Bogota").format("YYYY-MM-DD"))
                  // }
                  />
                  {errorRange.start && (
                    <span className='invalid-feedback d-block'>La fecha de inicio es obligatoria</span>
                  )}
                </div>
                <div className={'col-6  form-group'}>
                  <label>Hasta</label>
                  <input
                    type={'date'}
                    className={`form-control ${errorRange.end && 'is-invalid'}`}
                    name={'end'}
                    {...registerDates('end', { required: true })}
                    max={moment().tz('America/Bogota').format('YYYY-MM-DD')}
                  // onChange={(e) =>
                  //   setValueDates("end", moment(e).tz("America/Bogota").format("YYYY-MM-DD"))
                  // }
                  />
                  {errorRange.end && <span className='invalid-feedback d-block'>La fecha final es obligatoria</span>}
                </div>
              </div>
              <div className={'row'}>
                <div className={'col-12'}>
                  <button
                    className={'btn  btn-primary float-right'}
                    type={'submit'}>
                    Descargar
                  </button>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    orders: state.orderState.orders,
    order: state.orderState.order,
    rowEdited: state.orderState.rowEdited,
    rowDeleted: state.orderState.rowDeleted,
    rowUpdated: state.orderState.rowUpdated,
    orderDetails: state.orderDetailState.orderDetailByOrder,
    customers: state.customerState.customers,
    products: state.productState.products,
    cities: state.cityState.cities,
    neighborhoodsByCity: state.neighborhoodState.neighborhoodsByCity,
    countries: state.countryState.countries,
    departmentsByCountry: state.departmentState.departmentsByCountry,
    citiesByDepartment: state.cityState.citiesByDepartment,
    address: state.addressState.address,
    companies: state.companyState.companies,
    bulkAddress: state.addressState.bulkAddress,
    rowDeletedSoft: state.orderState.rowDeletedSoft,
    branches: state.branchState.branches,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    reduxGetOrders: (payload) =>
      dispatch({
        type: 'FETCH_ORDERS_REQUEST',
        value: payload,
      }),
    reduxPostOrder: (payload) =>
      dispatch({
        type: 'CREATE_ORDER_REQUEST',
        value: payload,
      }),
    reduxGetOrder: (payload) =>
      dispatch({
        type: 'READ_ORDER_REQUEST',
        value: payload,
      }),
    reduxDeleteOrder: (payload) =>
      dispatch({
        type: 'DELETE_ORDER_REQUEST',
        value: payload,
      }),
    reduxPatchOrder: (payload) =>
      dispatch({
        type: 'UPDATE_ORDER_REQUEST',
        value: payload,
      }),
    reduxResetOrderForm: () =>
      dispatch({
        type: 'RESET_ORDER_FORM',
      }),
    reduxGetOrderDetails: (payload) =>
      dispatch({
        type: 'READBYORDER_ORDERDETAIL_REQUEST',
        value: payload,
      }),
    reduxGetCustomers: (payload) =>
      dispatch({
        type: 'FETCH_CUSTOMERS_REQUEST',
        value: payload,
      }),
    reduxGetProducts: (payload) =>
      dispatch({
        type: 'FETCH_PRODUCTS_REQUEST',
        value: payload,
      }),
    reduxPostAddress: (payload) =>
      dispatch({
        type: 'CREATE_ADDRESS_REQUEST',
        value: payload,
      }),
    reduxGetCities: (payload) =>
      dispatch({
        type: 'FETCH_CITIES_REQUEST',
        value: payload,
      }),
    reduxGetNeighborhoodsByCity: (payload) =>
      dispatch({
        type: 'READBYCITY_NEIGHBORHOOD_REQUEST',
        value: payload,
      }),
    reduxGetCountries: (payload) =>
      dispatch({
        type: 'FETCH_COUNTRIES_REQUEST',
        value: payload,
      }),
    reduxGetDepartmentsByCountry: (payload) =>
      dispatch({
        type: 'READBYCOUNTRY_DEPARTMENT_REQUEST',
        value: payload,
      }),
    reduxGetCitiesByDepartment: (payload) =>
      dispatch({
        type: 'READBYDEPARTMENT_CITY_REQUEST',
        value: payload,
      }),
    reduxSetDepartment: (payload) =>
      dispatch({
        type: 'RESET_BYCOUNTRY_DEPARTMENT',
        value: payload,
      }),
    reduxSetCity: (payload) =>
      dispatch({
        type: 'RESET_BYDEPARTMENT_CITY',
        value: payload,
      }),
    reduxSetNeighborhood: (payload) =>
      dispatch({
        type: 'RESET_BYCITY_NEIGHBORHOOD',
        value: payload,
      }),
    reduxGetCompanies: (payload) =>
      dispatch({
        type: 'FETCH_COMPANIES_REQUEST',
        value: payload,
      }),
    reduxGetOrdersByUser: (payload) =>
      dispatch({
        type: 'FETCH_ORDERSBYUSER_REQUEST',
        value: payload,
      }),
    reduxPostBulkAddress: (payload) =>
      dispatch({
        type: 'CREATE_BULK_ADDRESS_REQUEST',
        value: payload,
      }),
    reduxDeleteSoftOrder: (payload) =>
      dispatch({
        type: 'DELETE_SOFT_ORDER_REQUEST',
        value: payload,
      }),
    reduxGetBranches: (payload) =>
      dispatch({
        type: 'FETCH_BRANCHES_REQUEST',
        value: payload,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Order);
