import {useForm} from "react-hook-form";
import React, {forwardRef, useRef, useState} from "react";
import {Button, Col, Form, Row} from "react-bootstrap";
import DatePicker from "react-datepicker";
import momentCustom from "../../../utils/momentCustomInstance";
import Select from "react-select";


const FilterCartaPorte = ({onSubmit}) => {
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange
    const [disableSearchButton, setDisableSearchButton] = useState(true)
    const { handleSubmit, getValues, setValue, register ,formState } = useForm();
    const rangeRef = useRef()

    const changeEndDate = (dates) => {
        const [start, end] = dates;
        setDateRange(dates)
        setDisableSearchButton(!!start && !!end)
        rangeRef.current.handleFocus()
    };

    const onSubmitFilter = (data) => {

        onSubmit({startDate: momentCustom(startDate).format('YYYY-MM-DD'), endDate: momentCustom(endDate).format('YYYY-MM-DD'), success: data.success })
    }

    return (
        <>
            <Form onSubmit={handleSubmit(onSubmitFilter)}>
                <Row className={'align-items-end justify-content-start'}>
                    <Col sm={12} md={4} lg={4} >
                        <Form.Group>
                            <Form.Label>Desde - Hasta</Form.Label>
                            <div className={'w-100'}>
                                <DatePicker
                                    startDate={startDate}
                                    endDate={endDate}
                                    selectsRange
                                    ref={rangeRef}
                                    selectsDisabledDaysInRange
                                    onChange={changeEndDate}
                                    dateFormat="yyyy-MM-dd"
                                   className={'form-control'}
                                    maxDate={new Date()}
                                    shouldCloseOnSelect={false}
                                />
                            </div>
                        </Form.Group>
                    </Col>
                    <Col sm={12} md={3} lg={3}>
                        <Form.Group>
                            <Form.Label>Estado</Form.Label>
                            <Select
                                id="selectField"
                                name="selectField"
                                options={[
                                    { value: '1', label: 'Enviado' },
                                    { value: '0', label: 'Fallido' }
                                ]}
                                isClearable
                                onChange={(selectedOption) => setValue('success', selectedOption.value)}
                            />
                        </Form.Group>

                    </Col>
                    <Col sm={12} md={4} lg={4}>
                        <Form.Group>
                            <Button type={'submit'}  disabled={!disableSearchButton}>Buscar</Button>
                        </Form.Group>
                    </Col>
                </Row>

            </Form>
        </>
    )
}

export default FilterCartaPorte