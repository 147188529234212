import React, {
  useEffect,
  useRef,
  useState,
  useContext,
} from "react";
import { connect, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import moment from "moment-timezone";
import Select from "react-select";

import Sidebar from "../../../components/Navigation/Sidebar";
import Topbar from "../../../components/Navigation/Topbar";
import MultiTable from "../../../components/MultiTable";
import { Show } from "../../../hooks/Show";
import { useAuth } from "../../../hooks/useAuth";
import { PermissionContext } from "../../../hooks/PermissionContext";
import formatDateWithCustomFormat, { FormatsDate } from "../../../utils/formatDate";

const Swal = require("sweetalert2");

const RecordsReport = ({
  statuses,
  reduxGetStatuses,
  cities,
  reduxGetCities,
  novelties,
  reduxGetNovelties,
  couriers,
  reduxGetCouriers,
  recordsReports,
  reduxGetRecordsReports,
  reduxGetCompanies,
  companies,
  reduxResetRecordsReportForm,
  reduxGetRecordsDetails,
  recordsDetails,
  reduxResetRecordsDetails,
}) => {

  const { hasPermission } = useContext(PermissionContext);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
    setValue,
    getValues,
  } = useForm();
  const [startDateFrom, setStartDateFrom] = useState(null);
  const [startDateTo, setStartDateTo] = useState(null);
  const [idUser, setIdUser] = useState("");
  const selectInputRef = useRef();
  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(10);
  const [search, setSearch] = useState("");
  const [isClearableEstado, setIsClearableEstado] = useState(true);
  const [isClearableCliente, setIsClearableCliente] = useState(true);
  const [isClearableOperador, setIsClearableOperador] = useState(true);
  const [isClearableCiudad, setIsClearableCiudad] = useState(true)
  const [isClearableNovedad, setIsClearableNovedad] = useState(true)
  const [isClearableMensajero, setIsClearableMensajero] = useState(true)
  const [cityOptions, setCityOptions] = useState([]);

  const { session } = useAuth();
  const dispatch = useDispatch();

  const download = () => {
    if (startDateTo < startDateFrom) {
      Swal.fire(
        "Información",
        "La fecha inicial debe ser  menor a la fecha final , intenta de nuevo",
        "info"
      );
      return;
    }
    const token = window.localStorage.getItem("token");
    let _data = {
      from: formatDateWithCustomFormat(getValues("from"), { format: FormatsDate.FULL24 }),
      to: formatDateWithCustomFormat(getValues("to"), { format: "YYYY-MM-DD 23:59:59" }),
      idCity: getValues("idCity"),
      state: getValues("state"),
      idRecord: getValues("idRecord"),
      idUser: idUser,
      idObservation: getValues("idObservation"),
      idClient: getValues("idClient"),
      idOperator: getValues("idOperator"),
      page: currentPage,
      offset: offset,
      search: search ? search : "",
      highPriority: getValues("highPriority"),
    };

    fetch(`${process.env.REACT_APP_REPORT_URL}/api/v1/reports/records`, {
      method: "POST",
      body: JSON.stringify(_data),
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      }),
    })
      .then((response) => response.blob())
      .then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download =
          a.download = moment(new Date()).tz("America/Bogota").format("YYYY-MM-DD_HHmmss") + "-records-report.csv";
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
  };

  const handledChangeFrom = (date) => {
    setStartDateFrom(date);
    setValue("from", formatDateWithCustomFormat(date, { format: FormatsDate.FULL24 }));
  };
  const handledChangeTo = (date) => {
    setStartDateTo(date);
    setValue("to", formatDateWithCustomFormat(date, { format: "YYYY-MM-DD 23:59:59" }));
  };

  const onSubmit = (data) => {
    if (startDateTo < startDateFrom) {
      Swal.fire(
        "Información",
        "La fecha inicial debe ser  menor a la fecha final , intenta de nuevo",
        "info"
      );
      return;
    }

    reduxGetRecordsReports({
      ...data,
      page: 1,
      search: search ? search : "",
      offset: offset,
      idUser: idUser,
    });
  };

  useEffect(() => {
    reduxGetStatuses({
      page: 1,
      search: "",
      offset: 1000,
    });
    reduxGetCities({
      page: 1,
      search: "",
      offset: 100000,
    });
    reduxGetNovelties({
      page: 1,
      search: "",
      offset: 1000,
      status: "",
    });
    reduxGetCouriers({});
    reduxGetCompanies({
      page: 1,
      search: "",
      offset: 1000,
    });
    reset();
    reduxResetRecordsReportForm();
    setStartDateFrom(null);
    setStartDateTo(null);
    if (hasPermission("feature:isClient")  ) {
      if(session && session.role ){
        localStorage.setItem("idClient", session.role.idCompany);
        setValue("idClient",  localStorage.getItem("idClient"));
      }
      if(localStorage.getItem("idClient")){
      setValue("idClient",  localStorage.getItem("idClient"));
      }

    } else {
      localStorage.removeItem("idClient");
    }
  }, []);

  useEffect(() => {
    if (cities.items && Object.keys(cities.items).length > 0) {
      const options = cities.items
        .filter((city) => city.isActive)
        .map((city) => ({
          value: city.idCity,
          label: city.description,
        }));
      setCityOptions(options);
    } else {
      setCityOptions([]);
    }
  }, [cities]);

  const isAllowedWithPermission = (permission) => {
    if (session) {
      let isAllowed = session.role.permissionsRoles.filter(
        (r) => r.permission.name == permission
      );
      return isAllowed.length > 0 ? true : false;
    }
    return false;
  };

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const onMenuOpen = () => {setIsMenuOpen(true)};
  const onMenuClose = () => setIsMenuOpen(false);
  const selectCourier = (e) => {
    if (e) {
      setIdUser(e.value);
    } else {
      setIdUser(null);
    }
  };  

  const [detailsCache, setDetailsCache] = useState({});

  useEffect(() => {
    if (Object.keys(recordsDetails).length === 0 && Object.keys(detailsCache).length === 0)
    return;
    if (!detailsCache[recordsDetails[0].idAddressRecord]) {
      setDetailsCache(prevCache => ({
        ...prevCache,
        [recordsDetails[0].idAddressRecord]:recordsDetails
      
      }));
    }  
  }, [recordsDetails, detailsCache, reduxResetRecordsDetails])
  
  const getDetails = async (idAddressRecord) => {
      reduxGetRecordsDetails(idAddressRecord);
  };
  
  const columns = React.useMemo(
    () => [
      {
        Header: () => null,
        id: "expander",
        Cell: ({ row }) => (
          <span {...row.getToggleRowExpandedProps()}>
            {row.isExpanded ? (
              <i className="fas fa-sort-down"></i>
            ) : (
              <i className="fas fa-caret-right" onClick={() => getDetails(row.original.idAddressRecord)}> </i>
            )}
          </span>
        ),
      },
      {
        Header: "# Dirección",
        title: "# Dirección",
        accessor: "idAddress",
        field: 'idAddress',
        searchable: true
      },
      {
        Header: "Fecha",
        title: "Fecha",
        accessor: "stateDate",
      },
      {
        Header: "Hora",
        title: "Hora",
        accessor: "stateHour",
      },
      {
        Header: "Ciudad",
        title: "Ciudad",
        accessor: "city",
        field: 'city',
        searchable: false
      },
      ...(hasPermission("feature:show-client-records-report")
        ? [
          {
            Header: "Cliente",
            title: "Cliente",
            accessor: "client",
            field: 'client',
            searchable: false
          },
        ]
        : []),
      ...(hasPermission("feature:show-operator-records-report")
        ? [
          {
            Header: "Operador",
            title: "Operador",
            accessor: "operator",
            field: 'operator',
            searchable: false
          },
        ]
        : []),
      {
        Header: "Guía",
        title: "Guía",
        accessor: "trackingId",
        field: 'trackingId',
        searchable: true
      },
      {
        Header: "Estado",
        title: "Estado",
        accessor: "state",
        field: 'state',
        searchable: false
      },
      {
        Header: "Días transcurridos",
        title: "Días transcurridos",
        accessor: "days",
        field: 'days',
        searchable: false
      },
      {
        Header: "Fecha de entrega",
        title: "Fecha de entrega",
        accessor: "deliveryDate",
      },
      {
        Header: "Dirección",
        title: "Dirección",
        accessor: "address",
        field: 'address',
      },
      {
        Header: "Teléfono Destinatario",
        title: "Teléfono Destinatario",
        accessor: "reference1",
        field: 'reference1',
      },
      {
        Header: "Observaciones",
        title: "Observaciones",
        accessor: "reference2",
        field: 'reference2',
      },
      {
        Header: "Valor del recaudo",
        title: "Valor del recaudo",
        accessor: "ammount",
      },
      {
        Header: "Guía cliente",
        title: "Guía cliente",
        accessor: "clientTrackingId",
        field: 'clientTrackingId',
        searchable: true
      },
      {
        Header: "Ubicación de Entrega",
        title: "Ubicación de Entrega",
        accessor: "deliveryLocation",
        field: 'deliveryLocation',
      },
      {
        Header: "Nombre del cliente",
        title: "Nombre del cliente",
        accessor: "name",
        field: 'name',
      },
      {
        Header: "Novedad",
        title: "Novedad",
        accessor: "routeObservation",
        field: 'routeObservation',
        searchable: false
      },
      {
        Header: "Nota",
        title: "Nota",
        accessor: "record",
      },
      {
        Header: "Comentario de la dirección",
        title: "Comentario de la dirección",
        accessor: "comment",
      },
      {
        Header: "Comentario de la novedad/nota",
        title: "Comentario de la novedad/nota",
        accessor: "detailObservation",
      },
      {
        Header: "Intento de entrega",
        title: "Intento de entrega",
        accessor: "attempt",
      },
      {
        Header: "Días de retraso",
        title: "Días de retraso",
        accessor: "delay",
      },
      {
        Header: "Mensajero asignado",
        title: "Mensajero asignado",
        accessor: "courier",
      },
      {
        Header: "Recaudo",
        title: "Recaudo",
        accessor: "finishedType",
      },
      {
        Header: "Observacion de Cierre",
        title: "Observacion de Cierre",
        accessor: "finishedDescription",
      },
      {
        Header: "ID externo",
        title: "ID externo",
        accessor: "externalId",
        field: 'externalId',
        searchable: true
      },
    ],
    [detailsCache]
  );

  useEffect(() => {
    if (
      currentPage &&
      recordsReports &&
      Object.keys(recordsReports).length > 0 &&
      getValues("from") &&
      getValues("to")
    ) {
      let filters = {
        page: currentPage,
        offset: offset,
        search: search,
      };
      if (getValues("idCity")) {
        filters = {
          ...filters,
          idCity: getValues("idCity"),
        };
      }
      if (getValues("state")) {
        filters = {
          ...filters,
          state: getValues("state"),
        };
      }
      if (getValues("idRecord")) {
        filters = {
          ...filters,
          idRecord: getValues("idRecord"),
        };
      }
      if (getValues("idUser")) {
        filters = {
          ...filters,
          idUser: getValues("idUser"),
        };
      }
      if (getValues("idClient")) {
        filters = {
          ...filters,
          idClient: getValues("idClient"),
        };
      }
      if (getValues("idOperator")) {
        filters = {
          ...filters,
          idOperator: getValues("idOperator"),
        };
      }
      if (getValues("from") && getValues("to")) {
        filters = {
          ...filters,
          from: getValues("from"),
          to: getValues("to"),
        };
      }
      if (getValues("highPriority")) {
        filters = {
          ...filters,
          highPriority: getValues("highPriority"),
        };
      }
      reduxGetRecordsReports(filters);
    }
  }, [currentPage]);

  return (
    <div>
      <div id="wrapper">
        <Sidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Topbar />
            <div className="container-fluid">
              <div className="d-sm-flex align-items-center mb-1">
                <h1 className="h3 mb-0 text-gray-800 mr-3">
                  Reporte de Novedades
                </h1>
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="col s1 m2">
                    <div className="form-group">
                      <label htmlFor="from" className="form-label">
                        Desde <span className={"text-danger"}>*</span>
                      </label>
                      <div>
                        <DatePicker
                          id="from"
                          {...register("from", { required: true })}
                          className={`form-control form-control-user ${errors.from && "is-invalid"}`}
                          selected={startDateFrom}
                          onChange={(date) => handledChangeFrom(date)}
                          dateFormat="yyyy-MM-dd"
                          maxDate={new Date()}
                          autoComplete="off"
                        />
                        {errors.from && (
                          <span className="invalid-feedback">Fecha inicial es requerida</span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col s1 m2">
                    <div className="form-group">
                      <label htmlFor="to" className="form-label">
                        Hasta <span className={"text-danger"}>*</span>
                      </label>
                      <div>
                        <DatePicker
                          id="to"
                          {...register("to", { required: true })}
                          className={`form-control form-control-user ${errors.to && "is-invalid"}`}
                          selected={startDateTo}
                          onChange={(date) => handledChangeTo(date)}
                          dateFormat="yyyy-MM-dd"
                          maxDate={new Date()}
                          autoComplete="off"
                        />
                        {errors.to && (
                          <span className="invalid-feedback">Fecha final es requerida</span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col s3 m3">
                    <div className="form-group">
                      <label htmlFor="state" className="form-label">
                        Estado
                      </label>

                      <Select
                        isClearable={isClearableEstado}
                        isDisabled={!startDateFrom || !startDateTo}
                        {...register("state")}
                        id="state"
                        onChange={(value) => setValue("state", value?.value.toString() || "")}
                        options={
                          statuses && Object.keys(statuses).length > 0
                            ? [
                              ...statuses.items.map((ele, key) => ({
                                value: ele.idStatus,
                                label: ele.name,
                              })),
                              { value: "-1", label: "Eliminados" }
                            ]
                            : [{ value: "", label: "Seleccionar…" }]
                        }
                      />


                    </div>
                  </div>
                  <Show when="feature:show-client-records-report">
                    <div className="col s3 m3">
                      <div className="form-group">
                        <label htmlFor="idClient" className="form-label">
                          Cliente
                        </label>
                        <Select
                          isClearable={isClearableCliente}
                          isDisabled={!startDateFrom || !startDateTo}
                          {...register("idClient")}
                          id="idClient"
                          onChange={(value) => setValue("idClient", value?.value.toString() || "")}
                          options={
                            companies && Object.keys(companies).length > 0
                              ? companies.items
                                .filter((f) => f.isActive)
                                .map((ele, key) => ({
                                  value: ele.idCompany,
                                  label: ele.description,
                                }))
                              : []
                          }
                        />
                      </div>
                    </div>
                  </Show>
                  <div className="col s12 m1">
                    <Button
                      variant="primary"
                      style={{ marginTop: "32px" }}
                      type="submit"
                      id="btnSearch"
                    >
                      Buscar
                    </Button>
                  </div>

                  {recordsReports &&
                    recordsReports.items &&
                    Object.keys(recordsReports.items).length > 0 && (
                      <div className="col s12 m1">
                        <Button
                          variant="primary"
                          style={{ marginTop: "32px" }}
                          id="btnSearch"
                          onClick={download}
                        >
                          Descargar
                        </Button>
                      </div>
                    )}
                </div>
                <div className="row">
                  <Show when="feature:show-operator-records-report">
                    <div className="col s3 m3">
                      <div className="form-group">
                        <label htmlFor="idOperator" className="form-label">
                          Operador
                        </label>
                        <Select
                          isClearable={isClearableOperador}
                          isDisabled={!startDateFrom || !startDateTo}
                          {...register("idOperator")}
                          id="idOperator"
                          onChange={(value) => setValue("idOperator", value?.value.toString() || "")}
                          options={
                            companies && Object.keys(companies).length > 0
                              ? companies.items
                                .filter((f) => f.isActive)
                                .map((ele, key) => ({
                                  value: ele.idCompany,
                                  label: ele.description,
                                }))
                              : []
                          }
                        />
                      </div>
                    </div>
                  </Show>
                  <div className="col s3 m3">
                    <div className="form-group">
                      <label htmlFor="idCity" className="form-label">
                        Ciudad
                      </label>

                      <Select
                        isClearable={isClearableCiudad}
                        isDisabled={!startDateFrom || !startDateTo}
                        {...register("idCity")}
                        id="idCity"
                        onChange={(value) => setValue("idCity", value?.value.toString() || "")}
                        options={cityOptions}
                      />
                    </div>
                  </div>

                  <div className="col s3 m3">
                    <div className="form-group">
                      <label htmlFor="idRecord" className="form-label">
                        Novedad
                      </label>

                      <Select
                        isClearable={isClearableNovedad}
                        isDisabled={!startDateFrom || !startDateTo}
                        {...register("idRecord")}
                        id="idRecord"
                        onChange={(value) => setValue("idRecord", value?.value.toString() || "")}
                        options={
                          novelties &&
                          Object.keys(novelties).length > 0 &&
                          novelties.items
                            .filter((f) => f.isActive)
                            .map((ele, key) => ({
                              value: ele.idNovelty,
                              label: ele.description,
                            }))
                        }
                      />
                    </div>
                  </div>
                  <Show when="feature:show-courier-records-report">
                    <div className="col s3 m3">
                      <div className="form-group">
                        <label htmlFor="idUser" className="form-label">
                          Mensajero
                        </label>
                        <Select
                          id="idUser"
                          name="idUser"
                          isDisabled={!startDateFrom || !startDateTo}
                          isClearable={isClearableMensajero}
                          onMenuOpen={onMenuOpen}
                          onMenuClose={onMenuClose}
                          onChange={selectCourier}
                          ref={selectInputRef}
                          options={
                            couriers &&
                            Object.keys(couriers).length > 0 &&
                            couriers.map((element) => {
                              return {
                                value: element.idUser,
                                label:
                                  element.nameHeadquarters != null
                                    ? `${element.nameHeadquarters} - ${element.firstName} ${element.lastName}`
                                    : `${element.firstName} ${element.lastName}`,
                              };
                            })
                          }
                        />
                      </div>
                    </div>
                  </Show>
                  <div className="col s3 m3">
                    <div className="form-group">
                      <label htmlFor="generateGuide">High priority</label>
                      <input
                        id="generateGuide"
                        type="checkbox"
                        // checked={getValues("highPriority")}
                        {...register("highPriority")}
                        style={{
                          marginRight: "10px",
                          marginLeft: "10px",
                          marginTop: "10px",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </form>
              <div className="card shadow mb-4">
                <div className="card-body">
                  <MultiTable
                    columns={columns}
                    detailsCache={detailsCache}
                    data={
                      recordsReports && Object.keys(recordsReports).length > 0
                        ? recordsReports.items
                        : []
                    }
                    page={
                      recordsReports && Object.keys(recordsReports).length > 0
                        ? Number(recordsReports.page)
                        : currentPage
                    }
                    pages={
                      recordsReports && Object.keys(recordsReports).length > 0
                        ? Number(recordsReports.totalPages)
                        : 1
                    }
                    total={
                      recordsReports && recordsReports.hasOwnProperty("total")
                        ? recordsReports.total
                        : 0
                    }
                    onChangePage={(page) => setCurrentPage(page)}
                    onChangeRange={(value) => {
                      setOffset(value);
                      let filters = {
                        page: 1,
                        offset: value,
                        search: search,
                      };
                      if (getValues("idCity")) {
                        filters = {
                          ...filters,
                          idCity: getValues("idCity"),
                        };
                      }
                      if (getValues("state")) {
                        filters = {
                          ...filters,
                          state: getValues("state"),
                        };
                      }
                      if (getValues("idRecord")) {
                        filters = {
                          ...filters,
                          idRecord: getValues("idRecord"),
                        };
                      }
                      if (getValues("idUser")) {
                        filters = {
                          ...filters,
                          idUser: getValues("idUser"),
                        };
                      }
                      if (getValues("idClient")) {
                        filters = {
                          ...filters,
                          idClient: getValues("idClient"),
                        };
                      }
                      if (getValues("idOperator")) {
                        filters = {
                          ...filters,
                          idOperator: getValues("idOperator"),
                        };
                      }
                      if (getValues("from") && getValues("to")) {
                        if (startDateTo < startDateFrom) {
                          Swal.fire(
                            "Información",
                            "La fecha inicial debe ser  menor a la fecha final , intenta de nuevo",
                            "info"
                          );
                          return;
                        }
                        filters = {
                          ...filters,
                          from: formatDateWithCustomFormat(getValues("from"), { format: FormatsDate.FULL24 }),
                          to: formatDateWithCustomFormat(getValues("to"), { format: "YYYY-MM-DD 23:59:59" }),
                        };
                      }
                      if (getValues("highPriority")) {
                        filters = {
                          ...filters,
                          highPriority: getValues("highPriority"),
                        };
                      }
                      reduxGetRecordsReports(filters);
                    }}
                    defaultValue={search}
                    onChangeSearch={(value) => {
                      if (!getValues("from") || !getValues("to")) {
                        Swal.fire(
                          "Información",
                          "El rango de fechas es obligatorio",
                          "info"
                        );
                        return;
                      }
                      setSearch(value);
                      let filters = {
                        page: 1,
                        offset: offset,
                        search: value,
                      };
                      if (getValues("idCity")) {
                        filters = {
                          ...filters,
                          idCity: getValues("idCity"),
                        };
                      }
                      if (getValues("state")) {
                        filters = {
                          ...filters,
                          state: getValues("state"),
                        };
                      }
                      if (getValues("idRecord")) {
                        filters = {
                          ...filters,
                          idRecord: getValues("idRecord"),
                        };
                      }
                      if (getValues("idUser")) {
                        filters = {
                          ...filters,
                          idUser: getValues("idUser"),
                        };
                      }
                      if (getValues("idClient")) {
                        filters = {
                          ...filters,
                          idClient: getValues("idClient"),
                        };
                      }
                      if (getValues("idOperator")) {
                        filters = {
                          ...filters,
                          idOperator: getValues("idOperator"),
                        };
                      }
                      if (getValues("from") && getValues("to")) {
                        if (startDateTo < startDateFrom) {
                          Swal.fire(
                            "Información",
                            "La fecha inicial debe ser  menor a la fecha final , intenta de nuevo",
                            "info"
                          );
                          return;
                        }
                        filters = {
                          ...filters,
                          from: formatDateWithCustomFormat(getValues("from"), { format: FormatsDate.FULL24 }),
                          to: formatDateWithCustomFormat(getValues("to"), { format: "YYYY-MM-DD 23:59:59" }),
                        };
                      }
                      if (getValues("highPriority")) {
                        filters = {
                          ...filters,
                          highPriority: getValues("highPriority"),
                        };
                      }
                      reduxGetRecordsReports(filters);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    statuses: state.statusesState.statuses,
    cities: state.cityState.cities,
    novelties: state.noveltyState.novelties,
    couriers: state.userState.couriers,
    recordsReports: state.reportState.recordsReports,
    // observations: state.observationState.observations,
    companies: state.companyState.companies,
    recordsDetails: state.reportState.recordsReportsDetails
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    reduxGetStatuses: (payload) =>
      dispatch({
        type: "FETCH_NEW_STATUS_REQUEST",
        value: payload,
      }),
    reduxGetCities: (payload) =>
      dispatch({
        type: "FETCH_CITIES_REQUEST",
        value: payload,
      }),
    reduxGetNovelties: (payload) =>
      dispatch({
        type: "FETCH_NOVELTIES_REQUEST",
        value: payload,
      }),
    reduxGetCouriers: (payload) =>
      dispatch({
        type: "FETCH_COURIERS_REQUEST",
        value: payload,
      }),
    reduxGetRecordsReports: (payload) =>
      dispatch({
        type: "FETCH_RECORDSREPORTS_REQUEST",
        value: payload,
      }),
    reduxGetCompanies: (payload) =>
      dispatch({
        type: "FETCH_COMPANIES_REQUEST",
        value: payload,
      }),
    reduxResetRecordsReportForm: () =>
      dispatch({
        type: "RESET_RECORDSREPORTS_FORM",
      }),
    reduxGetRecordsDetails: (payload) =>
      dispatch({
        type: "FETCH_RECORDSDETAILS_REQUEST",
        value: payload,
      }),
    reduxResetRecordsDetails: () =>
      dispatch({
        type: "RESET_RECORDSDETAILS_FORM",
      }),
      
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RecordsReport);
