const initialState = {
  requestingFetchOrderDetails: false,
  successfulFetchOrderDetails: false,
  errorFetchOrderDetails: false,
  orderDetails: {},
  requestingCreateOrderDetail: false,
  successfulCreateOrderDetail: false,
  errorsCreateOrderDetail: false,
  orderDetail: null,
  requestingReadOrderDetail: false,
  successfulReadOrderDetail: false,
  errorsReadOrderDetail: false,
  rowEdited: null,
  requestingDeleteOrderDetail: false,
  successfulDeleteOrderDetail: false,
  errorsDeleteOrderDetail: false,
  rowDeleted: null,
  requestingUpdateOrderDetail: false,
  successfulUpdateOrderDetail: false,
  errorsUpdateOrderDetail: false,
  rowUpdated: null,
  requestingReadOrderDetailByOrder: false,
  successfulReadOrderDetailByOrder: false,
  errorsReadOrderDetailByOrder: false,
  orderDetailByOrder: null,
};

const orderDetailReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_ORDERDETAILS_REQUESTING":
      return {
        ...state,
        requestingFetchOrderDetails: true,
        successfulFetchOrderDetails: false,
        errorsFetchOrderDetails: false,
      };
    case "FETCH_ORDERDETAIL_SUCCESS":
      return {
        ...state,
        errorFetchOrderDetails: false,
        requestingFetchOrderDetails: false,
        successfulFetchOrderDetails: true,
        orderDetails: action.value,
      };
    case "FETCH_ORDERDETAIL_ERROR":
      return {
        ...state,
        errorFetchOrderDetails: true,
        requestingFetchOrderDetails: false,
        successfulFetchOrderDetails: false,
      };
    case "CREATE_ORDERDETAIL_REQUESTING":
      return {
        ...state,
        requestingCreateOrderDetail: true,
        successfulCreateOrderDetail: false,
        errorsCreateOrderDetail: false,
      };
    case "CREATE_ORDERDETAIL_SUCCESS":
      return {
        ...state,
        errorsCreateOrderDetail: false,
        requestingCreateOrderDetail: false,
        successfulCreateOrderDetail: true,
        orderDetail: action.value,
      };
    case "CREATE_ORDERDETAIL_ERROR":
      return {
        ...state,
        errorsCreateOrderDetail: true,
        requestingCreateOrderDetail: false,
        successfulCreateOrderDetail: false,
      };
    case "READ_ORDERDETAIL_REQUESTING":
      return {
        ...state,
        requestingReadOrderDetail: true,
        successfulReadOrderDetail: false,
        errorsReadOrderDetail: false,
      };
    case "READ_ORDERDETAIL_SUCCESS":
      return {
        ...state,
        errorsReadOrderDetail: false,
        requestingReadOrderDetail: false,
        successfulReadOrderDetail: true,
        rowEdited: action.value,
      };
    case "READ_ORDERDETAIL_ERROR":
      return {
        ...state,
        errorsReadOrderDetail: true,
        requestingReadOrderDetail: false,
        successfulReadOrderDetail: false,
      };
    case "DELETE_ORDERDETAIL_REQUESTING":
      return {
        ...state,
        requestingDeleteOrderDetail: true,
        successfulDeleteOrderDetail: false,
        errorsDeleteOrderDetail: false,
      };
    case "DELETE_ORDERDETAIL_SUCCESS":
      return {
        ...state,
        errorsDeleteOrderDetail: false,
        requestingDeleteOrderDetail: false,
        successfulDeleteOrderDetail: true,
        rowDeleted: action.value,
      };
    case "DELETE_ORDERDETAIL_ERROR":
      return {
        ...state,
        errorsDeleteOrderDetail: true,
        requestingDeleteOrderDetail: false,
        successfulDeleteOrderDetail: false,
      };
    case "UPDATE_ORDERDETAIL_REQUESTING":
      return {
        ...state,
        requestingUpdateOrderDetail: true,
        successfulUpdateOrderDetail: false,
        errorsUpdateOrderDetail: false,
      };
    case "UPDATE_ORDERDETAIL_SUCCESS":
      return {
        ...state,
        errorsUpdateOrderDetail: false,
        requestingUpdateOrderDetail: false,
        successfulUpdateOrderDetail: true,
        rowUpdated: action.value,
      };
    case "READBYORDER_ORDERDETAIL_REQUESTING":
      return {
        ...state,
        requestingReadOrderDetailByOrder: true,
        successfulReadOrderDetailByOrder: false,
        errorsReadOrderDetailByOrder: false,
      };
    case "READBYORDER_ORDERDETAIL_SUCCESS":
      return {
        ...state,
        errorsReadOrderDetailByOrder: false,
        requestingReadOrderDetailByOrder: false,
        successfulReadOrderDetailByOrder: true,
        orderDetailByOrder: action.value,
      };
    case "READBYORDER_ORDERDETAIL_ERROR":
      return {
        ...state,
        errorsReadOrderDetailByOrder: true,
        requestingReadOrderDetailByOrder: false,
        successfulReadOrderDetailByOrder: false,
      };
    case "UPDATE_ORDERDETAIL_ERROR":
      return {
        ...state,
        errorsUpdateOrderDetail: true,
        requestingUpdateOrderDetail: false,
        successfulUpdateOrderDetail: false,
      };
    case "RESET_ORDERDETAIL_FORM":
      return {
        ...state,
        requestingCreateOrderDetail: false,
        successfulCreateOrderDetail: false,
        errorsCreateOrderDetail: false,
        orderDetail: null,
        requestingReadOrderDetail: false,
        successfulReadOrderDetail: false,
        errorsReadOrderDetail: false,
        rowEdited: null,
        requestingDeleteOrderDetail: false,
        successfulDeleteOrderDetail: false,
        rowDeleted: null,
        requestingUpdateOrderDetail: false,
        successfulUpdateOrderDetail: false,
        errorsUpdateOrderDetail: false,
        rowUpdated: null,
        requestingReadOrderDetailByOrder: false,
        successfulReadOrderDetailByOrder: false,
        errorsReadOrderDetailByOrder: false,
        orderDetailByOrder: null,
      };
    case "RESET":
      return initialState;
    default:
      return state;
  }
};

export default orderDetailReducer;
