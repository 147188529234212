import React, { useEffect, useState } from "react";
import ContentWrapper from "../../components/Common/ContentWrapper";
import FilterFormComponent from "./FilterFormComponent";
import TableComponent from "./TableComponent";
import ModalsComponent from "./ModalsComponent";
import { AddressStatus } from "../../utils/enumsUtil";
const Swal = require("sweetalert2");

const AddressCloseConfirmation = ({
  addressCloseConfirmations,
  getAll,
  setShowModal,
  filterFields,
  setFilterFields,
}) => {
  const [addressSelected, setAddresSelected] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(10);
  const [search, setSearch] = useState("");

  const handleSearch = (dataFrom) => {
    if (dataFrom.startDate && dataFrom.endDate) {
      if (dataFrom.endDate < dataFrom.startDate) {
        Swal.fire(
          "Información",
          "La fecha inicial debe ser  menor a la fecha final , intenta de nuevo",
          "info"
        );
        return;
      }
    }
    
    if (dataFrom.city === undefined) {
      delete dataFrom.city;
    }
    
    setFilterFields(dataFrom);
    let perpage = {
      ...dataFrom,
      page: 1,
      offset: offset,
      search: "",
    };
    getAll(perpage);
  };

  const handleConfirm = (address) => {
    setShowModal(true);
    setAddresSelected(address);
  };
  const handleConfirmAll = (idsToConfirm, state) => {
    setShowModal(true);
    setAddresSelected({ ids: idsToConfirm, state });
  };

  return (
    <ContentWrapper>
      <div className="d-sm-flex align-items-center mb-1">
        <h1 className="h3 mb-0 text-gray-800 mr-3">Confirmacion de cierre</h1>
      </div>
      <div className="card pb-0 mt-3">
        <div className="card-body">
          <FilterFormComponent onSubmit={handleSearch} />
        </div>
      </div>
      <div className="card pb-0 mt-3">
        <div className="card-body">
          <TableComponent
            data={addressCloseConfirmations}
            paginate={{
              currentPage,
              offset,
              search,
              setCurrentPage,
              setOffset,
              setSearch,
            }}
            camposDeFiltro={filterFields}
            handleConfirm={handleConfirm}
            handleConfirmAll={handleConfirmAll}
            searchRedux={getAll}
          />
        </div>
      </div>
      <ModalsComponent
        address={addressSelected}
        handleClose={() => setShowModal(false)}
      />
    </ContentWrapper>
  );
};

export default AddressCloseConfirmation;
