const initialState = {
  requestingCreateRouteRecord: false,
  successfulCreateRouteRecord: false,
  errorsCreateRouteRecord: false,
  routeRecord: null,
};

const routeRecordReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CREATE_ROUTE_RECORD_REQUESTING":
      return {
        ...state,
        requestingCreateRouteRecord: true,
        successfulCreateRouteRecord: false,
        errorsCreateRouteRecord: false,
      };
    case "CREATE_ROUTE_RECORD_SUCCESS":
      return {
        ...state,
        errorsCreateRouteRecord: false,
        requestingCreateRouteRecord: false,
        successfulCreateRouteRecord: true,
        routeRecord: action.value,
      };
    case "CREATE_ROUTE_RECORD_ERROR":
      return {
        ...state,
        errorsCreateRouteRecord: true,
        requestingCreateRouteRecord: false,
        successfulCreateRouteRecord: false,
      };
    case "RESET_ROUTE_RECORD_FORM":
      return {
        ...state,
        requestingCreateRouteRecord: false,
        successfulCreateRouteRecord: false,
        errorsCreateRouteRecord: false,
        routeRecord: null,
      };
    case "RESET":
      return initialState;
    default:
      return state;
  }
};

export default routeRecordReducer;
