

const initialState = {
    addressCardinals:{},
    fetching_address:false,
    requestingFetchAddressCardinal: false,
    successfulFetchAddressCardinal: false,
    errorFetchAddressCardinal: false,
    requestingCreateAddressCardinal: false,
    successfulCreateAddressCardinal: false,
    errorsCreateAddressCardinal: false,
    addressCardinal: null,
    requestingReadAddressCardinal: false,
    successfulReadAddressCardinal: false,
    errorsReadAddressCardinal: false,
    rowEdited: null,
    requestingDeleteAddressCardinal: false,
    successfulDeleteAddressCardinal: false,
    errorsDeleteAddressCardinal: false,
    rowDeleted: null,
    requestingUpdateAddressCardinal: false,
    successfulUpdateAddressCardinal: false,
    errorsUpdateAddressCardinal: false,
    rowUpdated: null,
}

const addressCardinalReducer = (state = initialState, payload) => {
    switch (payload.type) {
      case addressCardinalActions.FECTCHIN_ALL_ADDRESSCARDINAL:
        return {
          ...state,
          fetching_address: payload.value 
        };
    case addressCardinalActions.FETCH_ADDRESSES_CARDINAL_SUCCESS:
      return {
        ...state,
        errorFetchAddressCardinal: false,
        requestingFetchAddressCardinal: false,
        successfulFetchAddressCardinal: true,
        addressCardinals: payload.value,
      };
    case addressCardinalActions.FETCH_ADDRESSCARDINAL_ERROR:
        return {
          ...state,
          errorFetchAddressCardinal: true,
          requestingFetchAddressCardinal: false,
          successfulFetchAddressCardinal: false,
        };
    case addressCardinalActions.CREATE_ADDRESSCARDINAL_REQUESTING:
        return {
          ...state,
          requestingCreateAddressCardinal: true,
          successfulCreateAddressCardinal: false,
          errorsCreateAddressCardinal: false,
        };
    case addressCardinalActions.CREATE_ADDRESSCARDINAL_SUCCESS:
        return {
          ...state,
          errorsCreateAddressCardinal: false,
          requestingCreateAddressCardinal: false,
          successfulCreateAddressCardinal: true,
          addressCardinal: payload.value,
        };
    case addressCardinalActions.CREATE_ADDRESSCARDINAL_ERROR:
      return {
        ...state,
        errorsCreateAddressCardinal: true,
        requestingCreateAddressCardinal: false,
        successfulCreateAddressCardinal: false,
      };
    case addressCardinalActions.READ_ADDRESSCARDINAL_REQUESTING:
      return {
        ...state,
        requestingReadAddressCardinal: true,
        successfulReadAddressCardinal: false,
        errorsReadAddressCardinal: false,
      };
    case addressCardinalActions.READ_ADDRESSCARDINAL_SUCCESS:
      return {
        ...state,
        errorsReadAddressCardinal: false,
        requestingReadAddressCardinal: false,
        successfulReadAddressCardinal: true,
        rowEdited: payload.value,
      };
    case addressCardinalActions.READ_ADDRESSCARDINAL_ERROR:
      return {
        ...state,
        errorsReadAddressCardinal: true,
        requestingReadAddressCardinal: false,
        successfulReadAddressCardinal: false,
      };
    case addressCardinalActions.DELETE_ADDRESSCARDINAL_REQUESTING:
      return {
        ...state,
        requestingDeleteAddressCardinal: true,
        successfulDeleteAddressCardinal: false,
        errorsDeleteAddressCardinal: false,
      };
    case addressCardinalActions.DELETE_ADDRESSCARDINAL_SUCCESS:
      return {
        ...state,
        errorsDeleteAddressCardinal: false,
        requestingDeleteAddressCardinal: false,
        successfulDeleteAddressCardinal: true,
        rowDeleted: payload.value,
      };
    case addressCardinalActions.DELETE_ADDRESSCARDINAL_ERROR:
      return {
        ...state,
        errorsDeleteAddressCardinal: true,
        requestingDeleteAddressCardinal: false,
        successfulDeleteAddressCardinal: false,
      };
    case addressCardinalActions.UPDATE_ADDRESSCARDINAL_REQUESTING:
      return {
        ...state,
        requestingUpdateAddressCardinal: true,
        successfulUpdateAddressCardinal: false,
        errorsUpdateAddressCardinal: false,
      };
        case addressCardinalActions.UPDATE_ADDRESSCARDINAL_SUCCESS:
      return {
        ...state,
        errorsUpdateAddressCardinal: false,
        requestingUpdateAddressCardinal: false,
        successfulUpdateAddressCardinal: true,
        rowUpdated: payload.value,
      };
    case addressCardinalReducer.UPDATE_UPDATE_ADDRESSCARDINAL_ERROR:
      return {
        ...state,
        errorsUpdateAddressCardinal: true,
        requestingUpdateAddressCardinal: false,
        successfulUpdateAddressCardinal: false,
      };
    case addressCardinalReducer.RESET_ADDRESSCARDINAL_FORM:
      return {
        ...state,
        requestingCreateAddressCardinal: false,
        successfulCreateAddressCardinal: false,
        errorsCreateAddressCardinal: false,
        country: null,
        requestingReadAddressCardinal: false,
        successfulReadAddressCardinal: false,
        errorsReadAddressCardinal: false,
        rowEdited: null,
        requestingDeleteAddressCardinal: false,
        successfulDeleteAddressCardinal: false,
        errorsDeleteAddressCardinal: false,
        rowDeleted: null,
        requestingUpdateAddressCardinal: false,
        successfulUpdateAddressCardinal: false,
        errorsUpdateAddressCardinal: false,
        rowUpdated: null,
      };                  
    default: 
        return state;
    }
  };

  export const addressCardinalActions = {
    FECTCHIN_ALL_ADDRESSCARDINAL: 'FECTCHIN_ALL_ADDRESSCARDINAL',
    CREATE_ADDRESSCARDINAL_REQUESTING: 'CREATE_ADDRESSCARDINAL_REQUESTING',
    FETCH_ADDRESSES_CARDINAL_POINTS_REQUEST : 'FETCH_ADDRESSES_CARDINAL_POINTS_REQUEST',
    FETCH_ADDRESSES_CARDINAL_SUCCESS : 'FETCH_ADDRESSES_CARDINAL_SUCCESS',
    CREATE_ADDRESSCARDINAL_REQUEST: 'CREATE_ADDRESSCARDINAL_REQUEST',
    CREATE_ADDRESSCARDINAL_SUCCESS: "CREATE_ADDRESSCARDINAL_SUCCESS",
    FETCH_ADDRESSCARDINAL_ERROR: 'FETCH_ADDRESSCARDINAL_ERROR',
    CREATE_ADDRESSCARDINAL_ERROR: 'CREATE_ADDRESSCARDINAL_ERROR',
    READ_ADDRESSCARDINAL_REQUESTING: 'READ_ADDRESSCARDINAL_REQUESTING',
    READ_ADDRESSCARDINAL_SUCCESS: "READ_ADDRESSCARDINAL_SUCCESS",
    READ_ADDRESSCARDINAL_ERROR: "READ_ADDRESSCARDINAL_ERROR",
    DELETE_ADDRESSCARDINAL_REQUESTING: "DELETE_ADDRESSCARDINAL_REQUESTING",
    DELETE_ADDRESSCARDINAL_SUCCESS: "DELETE_ADDRESSCARDINAL_SUCCESS",
    DELETE_ADDRESSCARDINAL_ERROR: "DELETE_ADDRESSCARDINAL_ERROR",
    UPDATE_ADDRESSCARDINAL_REQUESTING: "UPDATE_ADDRESSCARDINAL_REQUESTING",
    UPDATE_ADDRESSCARDINAL_SUCCESS: 'UPDATE_ADDRESSCARDINAL_SUCCESS',
    UPDATE_UPDATE_ADDRESSCARDINAL_ERROR: 'UPDATE_UPDATE_ADDRESSCARDINAL_ERROR',
    RESET_ADDRESSCARDINAL_FORM: 'RESET_ADDRESSCARDINAL_FORM',
    READ_ADDRESSCARDINAL_REQUEST: 'READ_ADDRESSCARDINAL_REQUEST',
    DELETE_ADDRESSCARDINAL_REQUEST: 'ADDRESSCARDINAL',
    UPDATE_ADDRESSCARDINAL_REQUEST: 'UPDATE_ADDRESSCARDINAL_REQUEST',
    READBYDEPARTMENT_ADDRESSCARDINAL_REQUEST: 'READBYDEPARTMENT_ADDRESSCARDINAL_REQUEST'
  }
  
  export default addressCardinalReducer;
  