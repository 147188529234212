import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import Select from 'react-select';
import Swal from "sweetalert2";
import { DateTime, IANAZone } from "luxon";

import { isNullOrUndefined, exportCSV, isValidCSV } from '../../../../../utils/functionsUtils';
import { MimeTypesEnum, MovementTypesDetail } from "../../../../../utils/enumsUtil";

const WMSMassivePreparation = ({
    show,
    handleClose,
    reduxUploadMassivePreparation,
    reduxResetUploadMassivePreparation,
    masivPreparedWMSOrder,
}) => {
    const {
        handleSubmit: uploadModalSumbit,
        control: uploadModalControl,
        formState: { errors: uploadModalErrors },
        watch: uploadWatch,
        setValue: uploadModalSetValue,
        reset: resetUploadModal,
    } = useForm({
        defaultValues: {
            file: null,
        }
    });

    const fileWatcher = uploadWatch('file', null);
    const [showUploadPreparedModal, setShowUploadPreparedModal] = useState(false);
    const customStyles = {
        menu: (base) => ({ ...base, zIndex: 9999 }),
        valueContainer: (baseStyles) => ({ ...baseStyles, maxHeight: "3.5vw", overflowY: "auto" }),
        control: (baseStyles) => ({ ...baseStyles, flexWrap: "nowrap" }) 
    };

    useEffect(() => {
        resetUploadModal();
    }, []);

    const resetFileSelection = () => {
        uploadModalSetValue('file', '');
    };

    const uploadMassivePreparation = (data) => {
        Swal.fire({
            html: `¿Está seguro de actualizar estas ordenes al estado de despachadas?`,
            icon: 'question',
            showConfirmButton: true,
            confirmButtonText: "OK",
            showCancelButton: true,
            cancelButtonText: "Cancelar",
        }).then(result => {
            if (!result.isConfirmed) return;

            const reader = new FileReader();
            reader.readAsText(data.file);
            reader.onload = () => {
                if (!isValidCSV(reader.result, 1, 1)) {
                    resetFileSelection();
                    return;
                }

                reduxUploadMassivePreparation({
                    file: data.file,
                });

            }
            reader.onerror = (error) => {
                resetFileSelection();
            }
        })
    }

    useEffect(() => {
      if (masivPreparedWMSOrder) {
          if (Array.isArray(masivPreparedWMSOrder.results) && masivPreparedWMSOrder.results.some(result => result.status === 'error')) {
              const errorOrders = masivPreparedWMSOrder.results.filter(result => result.status === 'error');
              const csvData = errorOrders.map(order => `${order.trackingId.trim()};${order.message}`).join('\n');
              const fileName = DateTime.now().setZone(new IANAZone('America/Bogota')).toFormat('yyyy-MM-dd') + '-masivPreparedWMSOrderErrors.csv';
              const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
              const link = document.createElement('a');
              if (link.download !== undefined) {
                  const url = URL.createObjectURL(blob);
                  link.setAttribute('href', url);
                  link.setAttribute('download', fileName);
                  link.style.visibility = 'hidden';
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
              }
              Swal.fire(
                'Proceso finalizado con errores',
                `${masivPreparedWMSOrder.message}. Se ha generado un archivo CSV con los detalles de los errores.`,
                'error'
              );
              handleClose();
              
          resetFileSelection();
          resetUploadModal();
          } else {
            Swal.fire(
                'Proceso finalizado con éxito',
                `${masivPreparedWMSOrder.message}`,
                'success'
            );
            handleClose();
            
          resetFileSelection();
          resetUploadModal();
          }

      }
  }, [masivPreparedWMSOrder]);

    const handleCloseUploadMassivePreparation = () => {
      setShowUploadPreparedModal(false);
    }

    const renderHelpTooltip = (title) => <Tooltip id='button-tooltip'>
        <h6>El excel debe tener formato CSV y no debe tener encabezado!</h6>
    </Tooltip>;

    return (
        <>
            <Modal
                show={show}
                onHide={() => { handleClose(); resetFileSelection(); }}
                onEscapeKeyDown={() => { handleClose(); resetFileSelection(); }}
                backdrop="static"
                keyboard={false}
                size="lg"
            >
                <form onSubmit={uploadModalSumbit(uploadMassivePreparation)}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="mx-2">
                                    <OverlayTrigger
                                        placement='bottom'
                                        overlay={renderHelpTooltip("Formato de Cargue")}>
                                        <i className="fas fa-info-circle fa-sm"></i>
                                    </OverlayTrigger>                                     
                                </div>
                                <div>Cargar Archivo de Ordenes a Despachar</div>
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                          <div className="row">
                            <div className="form-group col-md-12">
                                <label htmlFor="file" className="form-label">
                                    Archivo *
                                </label>
                                <Controller
                                    control={uploadModalControl}
                                    name="file"
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'Debe seleccionar un archivo'
                                        },
                                        validate: (value) => {
                                            return !isNullOrUndefined(MimeTypesEnum.CSV.find(e => e === value.type)) || "El archivo debe ser formato CSV";
                                        },
                                    }}
                                    render={({ field }) => (
                                        <div className="custom-file">
                                            <input
                                                type='file'
                                                accept=".csv"
                                                onChange={(e) => {
                                                    const file = e.target.files[0];
                                                    uploadModalSetValue('file', file);
                                                }}
                                                onClick={(e) => e.target.value = null}
                                                className={`custom-file-input ${uploadModalErrors.file && 'is-invalid'}`}
                                            />
                                            <label
                                                className='custom-file-label'
                                                htmlFor='validatedCustomFile'
                                                onClick={() => document.getElementById('validatedCustomFile').click()}
                                            >
                                                {fileWatcher ? fileWatcher.name : 'Seleccionar archivo...'}
                                            </label>
                                            {
                                                uploadModalErrors.file &&
                                                <span className="error-message">{uploadModalErrors.file.message}</span>
                                            }
                                        </div>
                                    )}
                                />
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => { handleClose(); resetFileSelection(); }}>
                            Cancelar
                        </Button>
                        <Button variant="primary" type="submit">
                            Guardar
                        </Button>
                    </Modal.Footer>
                </form >
            </Modal>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
      masivPreparedWMSOrder: state.warehouseState.masivPreparedWMSOrder,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        reduxUploadMassivePreparation: (payload) =>
            dispatch({
                type: "UPLOAD_MASIV_PREPARED_WMSORDER_REQUEST",
                value: payload,
            }),
        reduxResetUploadMassivePreparation: () =>
            dispatch({
                type: "RESET_UPLOAD_MASSIVE_PREPARED_WMSORDER_REQUEST",
            })
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(WMSMassivePreparation);