const initialState = {
  requestingFetchRecordsReports: false,
  successfulFetchRecordsReports: false,
  errorFetchRecordsReports: false,
  recordsReports: {},
  requestingFetchCollectsReports: false,
  successfulFetchCollectsReports: false,
  errorFetchCollectsReports: false,
  collectsReports: {},
  requestingFetchSessionsReports: false,
  successfulFetchSessionsReports: false,
  errorFetchSessionsReports: false,
  sessionsReports: {},
  requestingFetchShipperUnconfirmed: false,
  successfulFetchShipperUnconfirmed: true,
  errorFetchShipperUnconfirmed: false,
  shipperConfirm: {},
  requestingFetchRecordsDetails: false,
  successfulFetchRecordsDetails: false,
  errorsFetchRecordsDetails: false,
  recordsReportsDetails: {},
};

const reportReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_RECORDSREPORTS_REQUESTING":
      return {
        ...state,
        requestingFetchRecordsReports: true,
        successfulFetchRecordsReports: false,
        errorsFetchRecordsReports: false,
      };
    case "FETCH_RECORDSREPORTS_SUCCESS":
      return {
        ...state,
        errorFetchRecordsReports: false,
        requestingFetchRecordsReports: false,
        successfulFetchRecordsReports: true,
        recordsReports: action.value,
      };
    case "FETCH_RECORDSREPORTS_ERROR":
      return {
        ...state,
        errorFetchRecordsReports: true,
        requestingFetchRecordsReports: false,
        successfulFetchRecordsReports: false,
      };
    case "FETCH_COLLECTSREPORTS_REQUESTING":
      return {
        ...state,
        requestingFetchCollectsReports: true,
        successfulFetchCollectsReports: false,
        errorFetchCollectsReports: false,
      };
    case "FETCH_COLLECTSREPORTS_SUCCESS":
      return {
        ...state,
        errorFetchCollectsReports: false,
        requestingFetchCollectsReports: false,
        successfulFetchCollectsReports: true,
        collectsReports: action.value,
      };
    case "FETCH_COLLECTSREPORTS_ERROR":
      return {
        ...state,
        errorFetchCollectsReports: true,
        requestingFetchCollectsReports: false,
        successfulFetchCollectsReports: false,
      };
    case "FETCH_SESSIONSREPORTS_REQUESTING":
      return {
        ...state,
        requestingFetchSessionsReports: true,
        successfulFetchSessionsReports: false,
        errorsFetchSessionsReports: false,
      };
    case "FETCH_SESSIONSREPORTS_SUCCESS":
      return {
        ...state,
        errorsFetchSessionsReports: false,
        requestingFetchSessionsReports: false,
        successfulFetchSessionsReports: true,
        sessionsReports: action.value,
      };
    case "FETCH_SESSIONSREPORTS_ERROR":
      return {
        ...state,
        errorsFetchSessionsReports: true,
        requestingFetchSessionsReports: false,
        successfulFetchSessionsReports: false,
      };
    case "FETCH_CONFIRMREPORT_REQUESTING":
      return {
          ...state,
          requestingFetchUnconfirmed: true,
          successfulFetchUnconfirmed: false,
          errorFetchUnconfirmed: false,
        };
    case "FETCH_CONFIRMREPORT_SUCCESS":
        return {
            ...state,
            errorFetchUnconfirmed: false,
            requestingFetchUnconfirmed: false,
            successfulFetchUnconfirmed: true,
            confirm: action.value,
        };
    case "FETCH_CONFIRMREPORT_ERROR":
        return {
            ...state,
            errorFetchUnconfirmed: true,
            requestingFetchUnconfirmed: false,
            successfulFetchUnconfirmed: false,
      };
    case "FETCH_SHIPPERCONFIRMREPORT_REQUESTING":
      return {
        ...state,
        requestingFetchShipperUnconfirmed: true,
        successfulFetchShipperUnconfirmed: false,
        errorFetchShipperUnconfirmed: false,
      };
    case "FETCH_SHIPPERCONFIRMREPORT_SUCCESS":
      return {
        ...state,
        requestingFetchShipperUnconfirmed: false,
        successfulFetchShipperUnconfirmed: true,
        errorFetchShipperUnconfirmed: false,
        shipperConfirm: action.value,
      };
    case "FETCH_SHIPPERCONFIRMREPORT_ERROR":
      return {
        ...state,
        requestingFetchShipperUnconfirmed: false,
        successfulFetchShipperUnconfirmed: false,
        errorFetchShipperUnconfirmed: true,
      };
    case "RESET_RECORDSREPORTS_FORM":
      return {
        ...state,
        requestingFetchRecordsReports: false,
        successfulFetchRecordsReports: false,
        errorFetchRecordsReports: false,
        recordsReports: {},
        requestingFetchCollectsReports: false,
        successfulFetchCollectsReports: false,
        errorFetchCollectsReports: false,
        collectsReports: {},
        requestingFetchSessionsReports: false,
        successfulFetchSessionsReports: false,
        errorFetchSessionsReports: false,
        sessionsReports: {},
      };
    case "RESET_CONFIRMREPORT_FORM":
      return {
        ...state,
        errorFetchUnconfirmed: false,
        requestingFetchUnconfirmed: false,
        successfulFetchUnconfirmed: false,
        confirm:{},
      };
    case "RESET_SHIPPERCONFIRMREPORT_FORM":
      return {
        ...state,
        errorFetchShipperUnconfirmed: false,
        requestingFetchShipperUnconfirmed: false,
        successfulFetchShipperUnconfirmed: false,
        shipperConfirm: {},
      };
    case "FETCH_RECORDSDETAILS_REQUESTING":
      return {
        ...state,
        requestingFetchRecordsDetails: true,
        successfulFetchRecordsDetails: false,
        errorsFetchRecordsDetails: false,
      };
      ;
    case "FETCH_RECORDSDETAILS_SUCCESS":
      return {
        ...state,
        errorsFetchRecordsDetails: false,
        requestingFetchRecordsDetails: false,
        successfulFetchRecordsDetails: true,
        recordsReportsDetails: action.value,
      };
    case "FETCH_RECORDSDETAILS_ERROR":
      return {
        ...state,
        errorsFetchRecordsDetails: true,
        requestingFetchRecordsDetails: false,
        successfulFetchRecordsDetails: false,
      };
    case "RESET_RECORDSDETAILS_FORM":
      return {
        ...state,
        errorsFetchRecordsDetails: false,
        requestingFetchRecordsDetails: false,
        successfulFetchRecordsDetails: false,
        recordsReportsDetails: {},
      };
    case "RESET":
      return initialState;
    default:
      return state;
  }
};

export default reportReducer;
