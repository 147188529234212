import { put, takeEvery, call } from 'redux-saga/effects';
import request from "../../utils/request";

function* fetchData(action) {
    try {
        const url = `${process.env.REACT_APP_API_URL}/api/v1/fdx-logs-carta-porte?page=${action.payload.page}&start=${action.payload.startDate}&end=${action.payload.endDate}&search=${action.payload.search}&offset=${action.payload.offset}&success=${action.payload.success || ''}`;
        const headers = {
            method: "GET",
            headers: new Headers({
                Authorization: `Bearer ${window.localStorage.getItem("token")}`,
                "Content-Type": "application/json",
            }),
        };
        yield put({
            type: "SHOW_LOADING",
        });
        const response = yield call(request, url, headers);
        if (response.statusCode === 200) {
            yield put({ type: 'FETCH_DATA_SUCCESS', payload: response.data });
        } else {
            yield put({ type: 'FETCH_DATA_FAILURE', error: response.message });
        }
        yield put({
            type: "HIDE_LOADING",
        });
    } catch (e) {
        yield put({ type: 'FETCH_DATA_FAILURE', error: e.message });
        yield put({
            type: "HIDE_LOADING",
        });
    }
}

export function* watchFetchData() {
    yield takeEvery('FETCH_DATA_REQUEST', fetchData);
}
