import { useEffect, useState } from "react";
import { connect } from "react-redux";

import Sidebar from "../../components/Navigation/Sidebar";
import Topbar from "../../components/Navigation/Topbar";
import Header from "../../components/Page/header";
import Grid from '../../components/Grid';

import { Show } from "../../hooks/Show";

import { buttonIcon, buttonTypes } from "../../utils/buttonsUtil";
import Hierarchy from "../../components/Modal/WMS/Warehouse/hierarchy";
import Upsert from "../../components/Modal/WMS/Warehouse/upsert";

const Warehouse = ({
  reduxGetWarehouses,
  warehouses,
  reduxPatchWarehouse,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(10);
  const [search, setSearch] = useState('');

  const [parentData, setParentData] = useState(null);
  const [showHierarchyModal, setShowHierarchyModal] = useState(false);

  const [idWarehouse, setIdWarehouse] = useState(null);
  const [showUpsertModal, setShowUpsertModal] = useState(false);

  useEffect(() => {
    reduxGetWarehouses({
      page: currentPage,
      offset: offset,
      search: search,
    });
  }, [currentPage, offset, search, reduxGetWarehouses])

  const handleOpenUpsertModal = (data) => {
    setIdWarehouse(data.idWarehouse)
    setShowUpsertModal(true);
  };

  const handleCloseUpsertModal = () => {
    setIdWarehouse(null);
    setShowUpsertModal(false);
    reduxGetWarehouses({
      page: currentPage,
      offset: offset,
      search: search,
    });
  }

  const handleOpenHierarchyModal = (data) => {
    setParentData(data);
    setShowHierarchyModal(true)
  }

  const handleCloseHierarchyModal = () => {
    setParentData(null);
    setShowHierarchyModal(false)
  }

  const handleChangeState = (data) => {
    const warehouseObj = {
      warehouseCode: data.warehouseCode,
      isActive: !data.isActive
    }
    reduxPatchWarehouse(warehouseObj);
  };

  const actionButtons = [
    { permission: 'feature:warehouse-create', onClick: handleOpenUpsertModal, buttonType: buttonTypes.Primary, buttonIcon: buttonIcon.Add }
  ];

  const columns = [
    {
      title: "#",
      render: (rowData) => {
        return <span>{rowData.idWarehouse}</span>;
      },
    },
    {
      title: "Nombre",
      render: (rowData) => {
        return <span>{rowData.name}</span>;
      },
      field: "TMW.name",
      searchable: true,
    },
    {
      title: "Código",
      render: (rowData) => {
        return <span>{rowData.warehouseCode}</span>;
      },
      field: "TMW.warehouseCode",
      searchable: true,
    },
    {
      title: "Acciones",
      render: (rowData) => {
        return (
          <>
            {
              rowData.isActive ?
                (
                  <>
                    <Show when="feature:warehouse-edit">
                      <button
                        title="Jerarquía"
                        className="btn btn-primary btn-sm btn-circle mr-2"
                        type="button"
                        onClick={e => handleOpenHierarchyModal(rowData)}
                      >
                        <i className={buttonIcon.Columns}></i>
                      </button>
                    </Show>
                    <Show when="feature:warehouse-edit">
                      <button
                        title="Editar"
                        className="btn btn-primary btn-sm btn-circle mr-2"
                        type="button"
                        onClick={e => handleOpenUpsertModal(rowData)}
                      >
                        <i className={buttonIcon.Edit}></i>
                      </button>
                    </Show>
                    <Show when="feature:warehouse-disable">
                      <button
                        title="Desactivar"
                        className="btn btn-danger btn-sm btn-circle"
                        type="button"
                        onClick={e => handleChangeState(rowData)}
                      >
                        <i className="fas fa-times-circle"></i>
                      </button>
                    </Show>
                  </>
                ) : (
                  <Show when="feature:warehouse-enable">
                    <button
                      title="Activar"
                      className="btn btn-primary btn-sm btn-circle mr-2"
                      type="button"
                      onClick={e => handleChangeState(rowData)}
                    >
                      <i className="fas fa-check-circle"></i>
                    </button>
                  </Show>
                )
            }
          </>
        );
      },
    },
  ];

  return (
    <div>
      <div>
        <div>
          <div id="wrapper">
            <Sidebar />
            <div id="content-wrapper" className="d-flex flex-column">
              <div id="content">
                <Topbar />
                <div className="container-fluid">
                  <Header title={"Bodegas"} subtitle={"Módulo para la gestión de Bodegas"} actionButtons={actionButtons} />
                </div>
                <div className="card shadow mb-4">
                  <div className="card-header py-3">
                    <h6 className="m-0 font-weight-bold text-primary">
                      Listado de Bodegas
                    </h6>
                  </div>
                  <div className="card-body">
                    <Grid
                      cols={columns}
                      data={
                        warehouses && Object.keys(warehouses).length > 0 ? warehouses.items : []
                      }
                      page={
                        warehouses && Object.keys(warehouses).length > 0
                          ? Number(warehouses.page)
                          : currentPage
                      }
                      pages={
                        warehouses && Object.keys(warehouses).length > 0
                          ? Number(warehouses.totalPages)
                          : 0
                      }
                      total={
                        warehouses && warehouses.hasOwnProperty("total") ? warehouses.total : 0
                      }
                      offset={offset}
                      onChangePage={(page) => setCurrentPage(page)}
                      onChangeRange={(value) => {
                        setOffset(value);
                        reduxGetWarehouses({
                          page: 1,
                          offset: value,
                          search: search,
                        });
                      }}
                      defaultValue={search}
                      onChangeSearch={(value) => {
                        setSearch(value);
                        reduxGetWarehouses({
                          page: 1,
                          offset: offset,
                          search: value,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Upsert show={showUpsertModal} idWarehouse={idWarehouse} handleClose={handleCloseUpsertModal} />
      <Hierarchy show={showHierarchyModal} parent={parentData} handleClose={handleCloseHierarchyModal} />
    </div>
  );

};

const mapStateToProps = (state) => {
  return {
    warehouses: state.warehouseState.warehouses,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    reduxGetWarehouses: (payload) =>
      dispatch({
        type: "FETCH_WAREHOUSES_REQUEST",
        value: payload,
      }),
    reduxPatchWarehouse: (payload) =>
      dispatch({
        type: 'UPDATE_WAREHOUSE_REQUEST',
        value: payload,
      }),
    
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Warehouse);