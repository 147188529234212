import { useEffect } from "react";
import { connect } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { Button, Modal } from "react-bootstrap";
import Select from 'react-select';
import { DateTime, IANAZone } from "luxon";

const InventoryReport = ({
    show,
    handleClose,
    reduxGetClients,
    shippers,
    reduxDownloadInventoryIncsOutsReport,
}) => {
    const {
        handleSubmit: downloadModalSumbit,
        control: downloadModalControl,
        formState: { errors: downloadModalErrors },
        reset: downloadModalReset,
    } = useForm({
        defaultValues: {
            shipper: null,
        }
    });

    const customStyles = {
        menu: (base) => ({ ...base, zIndex: 9999 }),
        valueContainer: (baseStyles) => ({ ...baseStyles, maxHeight: "3.5vw", overflowY: "auto" }),
        control: (baseStyles) => ({ ...baseStyles, flexWrap: "nowrap" }) // Ajusta el valor según tus necesidades
    };

    const handleResetModal = () => {
        downloadModalReset();
    }

    useEffect(() => {
        handleResetModal();
        reduxGetClients();
    }, [])

    const handleCloseModal = () => {
        handleResetModal()
        handleClose();
    }

    const handleDownloadReport = (data) => {
        reduxDownloadInventoryIncsOutsReport({
            fileName: `Ingresos_Salidas_${data.shipper.label}_${DateTime.now().setZone(new IANAZone('America/Bogota')).toFormat('yyyyLLdd_hhmm')}.csv`,
            shipper: data.shipper.value,
        })
    }

    return (
        <>
            <Modal
                show={show}
                onHide={handleCloseModal}
                onEscapeKeyDown={handleCloseModal}
                backdrop="static"
                keyboard={false}
            >
                <form onSubmit={downloadModalSumbit(handleDownloadReport)}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <div className="d-flex justify-content-between align-items-center">
                                Descargar reporte de Ingresos y Salidas
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className='form-group col'>
                                <label
                                    htmlFor='shipper'
                                    className='form-label'>
                                    Remitente *
                                </label>
                                <Controller
                                    control={downloadModalControl}
                                    rules={{
                                        required: 'El remitente es obligatorio'
                                    }}
                                    name="shipper"
                                    render={({ field }) => (
                                        <>
                                            <Select
                                                {...field}
                                                isClearable
                                                styles={customStyles}
                                                options={
                                                    shippers && Object.keys(shippers).length > 0
                                                        ? shippers.items
                                                            .map((ele, key) => ({
                                                                value: ele.idCompany,
                                                                label: ele.description,
                                                            }))
                                                        : []
                                                }
                                            />
                                            {
                                                downloadModalErrors.shipper &&
                                                <span className="error-message">
                                                    {downloadModalErrors.shipper.message}
                                                </span>
                                            }
                                        </>
                                    )}
                                />
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseModal}>
                            Cancelar
                        </Button>
                        <Button variant="primary" type="submit">
                            Descargar
                        </Button>
                    </Modal.Footer>
                </form >
            </Modal>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        shippers: state.companyState.clients,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        reduxGetClients: (payload) =>
            dispatch({
                type: 'FETCH_COMPANIESBYSHIPPER_REQUEST',
                value: payload,
            }),
        reduxDownloadInventoryIncsOutsReport: (payload) =>
            dispatch({
                type: "DOWNLOAD_INCOMINGS_OUTGOINGS_REPORT_REQUEST",
                value: payload,
            }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(InventoryReport);