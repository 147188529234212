const initialState = {
  requestingFetchCollects: false,
  successfulFetchCollects: false,
  errorFetchCollects: false,
  collects: {},
  requestingCreateCollect: false,
  successfulCreateCollect: false,
  errorsCreateCollect: false,
  collect: null,
  requestingReadCollect: false,
  successfulReadCollect: false,
  errorsReadCollect: false,
  rowEdited: null,
  requestingUpdateCollect: false,
  successfulUpdateCollect: false,
  errorsUpdateCollect: false,
  rowUpdated: null,
  requestingFetchCollectsWithoutVerify: false,
  successfulFetchCollectsWithoutVerify: false,
  errorFetchCollectsWithoutVerify: false,
  collectsWithoutVerify: {},
};

const collectReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_COLLECTS_REQUESTING":
      return {
        ...state,
        requestingFetchCollects: true,
        successfulFetchCollects: false,
        errorsFetchCollects: false,
      };
    case "FETCH_COLLECT_SUCCESS":
      return {
        ...state,
        errorFetchCollects: false,
        requestingFetchCollects: false,
        successfulFetchCollects: true,
        collects: action.value,
      };
    case "FETCH_COLLECT_ERROR":
      return {
        ...state,
        errorFetchCollects: true,
        requestingFetchCollects: false,
        successfulFetchCollects: false,
      };
    case "CREATE_COLLECT_REQUESTING":
      return {
        ...state,
        requestingCreateCollect: true,
        successfulCreateCollect: false,
        errorsCreateCollect: false,
      };
    case "CREATE_COLLECT_SUCCESS":
      return {
        ...state,
        errorsCreateCollect: false,
        requestingCreateCollect: false,
        successfulCreateCollect: true,
        collect: action.value,
      };
    case "CREATE_COLLECT_ERROR":
      return {
        ...state,
        errorsCreateCollect: true,
        requestingCreateCollect: false,
        successfulCreateCollect: false,
      };
    case "READ_COLLECT_REQUESTING":
      return {
        ...state,
        requestingReadCollect: true,
        successfulReadCollect: false,
        errorsReadCollect: false,
      };
    case "READ_COLLECT_SUCCESS":
      return {
        ...state,
        errorsReadCollect: false,
        requestingReadCollect: false,
        successfulReadCollect: true,
        rowEdited: action.value,
      };
    case "READ_COLLECT_ERROR":
      return {
        ...state,
        errorsReadCollect: true,
        requestingReadCollect: false,
        successfulReadCollect: false,
      };
    case "UPDATE_COLLECT_REQUESTING":
      return {
        ...state,
        requestingUpdateCollect: true,
        successfulUpdateCollect: false,
        errorsUpdateCollect: false,
      };
    case "UPDATE_COLLECT_SUCCESS":
      return {
        ...state,
        errorsUpdateCollect: false,
        requestingUpdateCollect: false,
        successfulUpdateCollect: true,
        rowUpdated: action.value,
      };
    case "UPDATE_COLLECT_ERROR":
      return {
        ...state,
        errorsUpdateCollect: true,
        requestingUpdateCollect: false,
        successfulUpdateCollect: false,
      };
    case "FETCH_COLLECTS_WITHOUT_VERIFY_REQUESTING":
      return {
        ...state,
        requestingFetchCollectsWithoutVerify: true,
        successfulFetchCollectsWithoutVerify: false,
        errorFetchCollectsWithoutVerify: false,
      };
    case "FETCH_COLLECT_WITHOUT_VERIFY_SUCCESS":
      return {
        ...state,
        errorFetchCollectsWithoutVerify: false,
        requestingFetchCollectsWithoutVerify: false,
        successfulFetchCollectsWithoutVerify: true,
        collectsWithoutVerify: action.value,
      };
    case "FETCH_COLLECT_WITHOUT_VERIFY_ERROR":
      return {
        ...state,
        errorFetchCollectsWithoutVerify: true,
        requestingFetchCollectsWithoutVerify: false,
        successfulFetchCollectsWithoutVerify: false,
      };
    case "RESET_COLLECT_FORM":
      return {
        ...state,
        requestingCreateCollect: false,
        successfulCreateCollect: false,
        errorsCreateCollect: false,
        collect: null,
        requestingReadCollect: false,
        successfulReadCollect: false,
        errorsReadCollect: false,
        rowEdited: null,
        requestingUpdateCollect: false,
        successfulUpdateCollect: false,
        errorsUpdateCollect: false,
        rowUpdated: null,
        requestingFetchCollectsWithoutVerify: false,
        successfulFetchCollectsWithoutVerify: false,
        errorFetchCollectsWithoutVerify: false,
        collectsWithoutVerify: {},
      };
    case "RESET":
      return initialState;
    default:
      return state;
  }
};

export default collectReducer;
