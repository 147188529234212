import React, {useEffect, useRef, useState} from "react";
import {useForm} from "react-hook-form";
import Sidebar from "../../components/Navigation/Sidebar";
import Topbar from "../../components/Navigation/Topbar";
import {connect} from "react-redux";
import Grid from "../../components/Grid";
import "react-datepicker/dist/react-datepicker.css";
import {Button} from "react-bootstrap";
import Select from "react-select";
import moment from "moment-timezone";

const Swal = require("sweetalert2");
const Collect = ({
  reduxGetCouriers,
  couriers,
  reduxGetCollects,
  collects,
  reduxPatchCollect,
  rowUpdated,
  reduxResetForm,
}) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
    setValue,
    getValues,
  } = useForm();
  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(10);
  const [search, setSearch] = useState("");
  const [idUser, setIdUser] = useState(null);
  const selectInputRef = useRef();
  const [courierError, setCourierError] = useState(false);
  const [collectError, setCollectError] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const onMenuOpen = () => setIsMenuOpen(true);
  const onMenuClose = () => setIsMenuOpen(false);

  const onClear = () => {
    selectInputRef.current.select.clearValue();
  };

  const columns = [
    {
      title: "",
      render: (rowData) => {
        return (
          <>
            <input
              name="collectChecked"
              key={rowData.idCollect}
              type="checkbox"
              checked={rowData.isChecked}
              value={rowData.idCollect}
              onChange={(e) => {
                rowData.isChecked = e.target.checked;
              }}
              {...register("collectChecked")}
            />{" "}
          </>
        );
      },
    },
    {
      title: "#",
      render: (rowData) => {
        return <span>{rowData.idAddress}</span>;
      },
    },
    {
      title: "Dirección",
      render: (rowData) => {
        return <span>{rowData.address.address}</span>;
      },
    },
    {
      title: "Recaudo",
      render: (rowData) => {
        return <span>{rowData.value}</span>;
      },
    },
    {
      title: "Fecha",
      render: (rowData) => {
        return <span>{ moment(rowData.createdAt).tz("America/Bogota").format("DD-MM-YYYY")}</span>;
      },
    },
  ];

  useEffect(() => {
    reduxGetCouriers({});
    onClear();
    reduxResetForm();
  }, []);

  useEffect(() => {
    if (currentPage && idUser) {
      let filters = {
        page: currentPage,
        offset: offset,
        search: search,
        idCourier: idUser,
      };
      reduxGetCollects(filters);
    }
  }, [currentPage]);

  const onSubmit = (data) => {
    if (idUser == null || idUser == undefined) {
      setCourierError(true);
      return;
    }
    if (data.collectChecked == null || data.collectChecked == undefined) {
      setCollectError(true);
      return;
    }
    data.collectChecked =
      typeof data.collectChecked == "string"
        ? [data.collectChecked]
        : data.collectChecked;
    reduxPatchCollect({ ...data, idUser: idUser });
  };

  useEffect(() => {
    if (rowUpdated) {
      let filters = {
        page: currentPage,
        offset: offset,
        search: search,
        idCourier: idUser,
      };
      reduxGetCollects(filters);
      setValue("description", undefined);
      setValue("collectChecked", undefined);
    }
  }, [rowUpdated]);

  const selectCourier = (e) => {
    if (e) {
      setIdUser(e.value);
      setCourierError(false);
      reduxGetCollects({
        page: 1,
        offset: offset,
        search: "",
        idCourier: e.value,
      });
    }
  };

  return (
    <>
      <div>
        <div id="wrapper">
          <Sidebar />
          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">
              <Topbar />
              <div className="container-fluid">
                <div className="d-sm-flex align-items-center mb-1">
                  <h1 className="h3 mb-0 text-gray-800 mr-3">
                    Verificar recaudos
                  </h1>
                </div>
                <p className="mb-4">
                  Módulo para validar el recaudo de un mensajero específico
                </p>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="card shadow mb-4">
                    <div className="card-body">
                      <div className="row">
                        <div className="form-group col-md-12">
                          <label htmlFor="idUser" className="form-label">
                            Mensajero
                          </label>
                          <Select
                            id="idUser"
                            name="idUser"
                            onMenuOpen={onMenuOpen}
                            onMenuClose={onMenuClose}
                            onChange={selectCourier}
                            ref={selectInputRef}
                            options={
                              couriers &&
                              Object.keys(couriers).length > 0 &&
                              couriers.map((element) => {
                                return {
                                  value: element.idUser,
                                  label:
                                    element.nameHeadquarters != null
                                      ? `${element.nameHeadquarters} - ${element.firstName} ${element.lastName}`
                                      : `${element.firstName} ${element.lastName}`,
                                };
                              })
                            }
                          />
                          {courierError && (
                            <p
                              style={{
                                color: "red",
                                fontStyle: "italic",
                                textAlign: "center",
                              }}
                            >
                              Debe seleccionar un mensajero.
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card shadow mb-4">
                    <div className="card-header py-3">
                      <h6 className="m-0 font-weight-bold text-primary">
                        Listado de Recaudos
                      </h6>
                    </div>
                    <div className="card-body">
                      <Grid
                        cols={columns}
                        data={
                          collects && Object.keys(collects).length > 0
                            ? collects.items
                            : []
                        }
                        page={
                          collects && Object.keys(collects).length > 0
                            ? Number(collects.page)
                            : currentPage
                        }
                        pages={
                          collects && Object.keys(collects).length > 0
                            ? Number(collects.totalPages)
                            : 1
                        }
                        total = {
                          collects && collects.hasOwnProperty('total') ?
                              collects.total :
                              0
                        }
                        offset={offset}
                        onChangePage={(page) => setCurrentPage(page)}
                         onChangeRange={(value) => {
                      setOffset(value);
                          if (idUser) {
                            let filters = {
                              page: 1,
                              offset: value,
                              search: search,
                              idCourier: idUser,
                            };
                            reduxGetCollects(filters);
                          }
                        }}
                        defaultValue={search}
                        onChangeSearch={(value) => {
                          if (idUser) {
                            setSearch(value);
                            let filters = {
                              page: 1,
                              offset: 10,
                              search: value,
                              idCourier: idUser,
                            };
                            reduxGetCollects(filters);
                          }
                        }}
                      />
                      {collectError && (
                        <p
                          style={{
                            color: "red",
                            fontStyle: "italic",
                            textAlign: "center",
                          }}
                        >
                          Debe seleccionar un recaudo.
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="card shadow mb-4">
                    <div className="row">
                      <div className="card-body">
                        <div className="form-group col-md-9">
                          <label htmlFor="description" className="form-label">
                            Descripción
                          </label>
                          <input
                            id="description"
                            type="text"
                            className={`form-control form-control-user`}
                            {...register("description")}
                          />
                        </div>
                      </div>
                      <div className="form-group col-md-3">
                        <Button
                          variant="primary"
                          type="submit"
                          style={{ marginTop: "54px" }}
                        >
                          Verificar
                        </Button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    collects: state.collectState.collectsWithoutVerify,
    couriers: state.userState.couriers,
    rowUpdated: state.collectState.rowUpdated,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    reduxGetCouriers: (payload) =>
      dispatch({
        type: "FETCH_COURIERS_REQUEST",
        value: payload,
      }),
    reduxGetCollects: (payload) =>
      dispatch({
        type: "FETCH_COLLECTS_WITHOUT_VERIFY_REQUEST",
        value: payload,
      }),
    reduxPatchCollect: (payload) =>
      dispatch({
        type: "UPDATE_COLLECT_REQUEST",
        value: payload,
      }),
    reduxResetForm: () => {
      dispatch({
        type: "RESET_COLLECT_FORM",
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Collect);
