import React, { useMemo, useEffect, useContext } from "react";

import Grid from "../../components/Grid";

import formatDateWithCustomFormat, { FormatsDate } from "../../utils/formatDate";

import { PermissionContext } from "../../hooks/PermissionContext";
import momentCustom from "../../utils/momentCustomInstance";

const BlockedOrdersTableComponent = ({ data, reduxGetOrders, handleConfirmAllOrders, handleConfirm, searchRedux, filterFields, paginate }) => {

    const { hasPermission } = useContext(PermissionContext);

    useEffect(() => {
      let filters = {
        ...filterFields,
        page: paginate.currentPage,
        offset: paginate.offset,
        search: paginate.search,
      };
      searchRedux(filters);
    }, [paginate.currentPage]);

    const columns = useMemo(
        () => [
          {
            title: 'Guía',
            render: (rowData) => {
              return <span>{rowData.address.trackingId}</span>;
            },
            field: 'address.trackingId',
            searchable: true,
          },
          {
            title: 'Id Externo',
            render: (rowData) => {
              return <span>{rowData.address.externalId}</span>;
            },
            field: 'address.externalId',
            searchable: true,
          },
          {
            title: 'Estado',
            render: (rowData) => {
              return <span>{rowData.address.newState.name}</span>;
            },
            field: 'address.newState.name',
            searchable: true,
          },
          {
            title: 'Novedad',
            render: (rowData) => {
              return <span>{rowData.address.novelty.description}</span>;
            },
            field: 'address.novelty.description',
            searchable: true,
          },
          {
            title: 'Fecha solicitud',
            render: (rowData) => {
              return <span>{momentCustom(rowData.request_date).format('YYYY-MM-DD hh:mm A')}</span>;
            },
            searchable: false,
          },
          {
            title: 'Usuario Solicitud',
            searchable: true,
            field: 'userRequest.firstName-lastName',
            render: (rowData) => {
              return <span>{rowData.userRequest.firstName} {rowData.userRequest.lastName}</span>;
            },
          },
          {
            title: 'Fecha Confirmación',
            render: (rowData) => {
              return <span>{momentCustom(rowData.confirmation_date).format('YYYY-MM-DD hh:mm A')}</span>;
            },
            searchable: false,
          },
          {
            title: 'Usuario Confirmación',
            render: (rowData) => {
              return <span>{`${rowData.userConfirm.firstName} ${rowData.userConfirm.lastName}`}</span>;
            },
            searchable: true,
          },
          ...(hasPermission('feature:confirm-orders-locked-pending') ? [{
            title: 'Acciones',
            render: (rowData) => {
            },
          }]: []),
        ],
        [data]
    );

    return (
        <Grid
            cols={columns}
            data={data && Object.keys(data).length > 0 ? data.items : []}
            total={data && data.hasOwnProperty('total') ? data.total : 0}
            page={data && Object.keys(data).length > 0 ? Number(data.page) : paginate.currentPage}
            pages={data && Object.keys(data).length > 0 ? Number(data.totalPages) : 0}
            offset={paginate.offset}
            onChangePage={(page) => paginate.setCurrentPage(page)}
            onChangeRange={(value) => {
                paginate.setOffset(value);
                let filters = {
                    page: 1,
                    offset: value,
                    search: paginate.search,
                };
                if (filterFields.startDate && filterFields.endDate) {
                    filters = {
                      ...filters,
                      startDate: formatDateWithCustomFormat(filterFields.startDate, {
                          format: FormatsDate.FULL24,
                      }),
                      endDate: formatDateWithCustomFormat(filterFields.endDate, {
                          format: FormatsDate.FULL24,
                      }),
                    };
                }
                reduxGetOrders(filters);
            }}
            defaultValue={paginate.search}
            onChangeSearch={(value) => {
              paginate.setSearch(value);
              let filters = {
                  page: 1,
                  offset: paginate.offset,
                  search: value,
              };
              if (filterFields.startDate && filterFields.endDate) {
                  filters = {
                  ...filters,
                  startDate: formatDateWithCustomFormat(filterFields.startDate, {
                      format: FormatsDate.FULL24,
                  }),
                  endDate: formatDateWithCustomFormat(filterFields.endDate, {
                      format: FormatsDate.FULL24,
                  }),
                  };
              }
              reduxGetOrders(filters);
            }}
        />
    )
}

export default BlockedOrdersTableComponent;