import { useEffect, useState } from "react";
import { connect } from "react-redux";

import { Show } from "../../hooks/Show";

import Grid from "../../components/Grid";
import UpsertAlertSource from "../../components/Modal/Alerts/upsertAlertSource";

const AlertSources = ({
    activeTab,
    reduxGetAlertSources,
    alertSources,
    reduxResetAlertSourceForm,
}) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [offset, setOffset] = useState(10);
    const [search, setSearch] = useState('');

    const [showUpsertAlertSourceModal, setShowUpsertAlertSourceModal] = useState(false);
    const [upsertAlertSourceData, setUpsertAlertSourceData] = useState(null);

    useEffect(() => {
        if(!activeTab) return;
        
        reduxGetAlertSources({
            page: currentPage,
            offset: offset,
            search: search,
        })
    }, [activeTab, currentPage, offset, search, reduxGetAlertSources]);

    const handleOpenUpsertModal = (data) => {
        setUpsertAlertSourceData(data)
        setShowUpsertAlertSourceModal(true);
    }

    const handleCloseUpsertModal = () => {
        setUpsertAlertSourceData(null)
        setShowUpsertAlertSourceModal(false);
        reduxResetAlertSourceForm();
        reduxGetAlertSources({
            page: currentPage,
            offset: offset,
            search: search,
        })
    }

    const columns = [
        {
            title: "#",
            render: (rowData) => {
                return <span>{rowData.idAlertSource}</span>;
            },
        },
        {
            title: "Código",
            render: (rowData) => {
                return <span>{rowData.code}</span>;
            },
            field: "code",
            searchable: true,
        },
        {
            title: "Acciones",
            render: (rowData) => {
                return (
                    <>
                        <Show when="feature:alerts-source-upsert">
                            <button
                                title="Editar"
                                className="btn btn-primary btn-sm btn-circle mr-2"
                                type="button"
                                onClick={e => handleOpenUpsertModal(rowData)}
                            >
                                <i className="fas fa-edit fa-xs"></i>
                            </button>
                        </Show>
                    </>
                );
            },
        },
    ];

    return (
        <>
            <div className="card-body">
                <Show when="feature:alerts-source-upsert">
                    <div className="pb-3">
                        <button className='btn btn-primary' onClick={() => handleOpenUpsertModal()}>Nueva fuente de alerta</button>
                    </div>
                </Show>
                <Grid
                    cols={columns}
                    data={
                        alertSources && Object.keys(alertSources).length > 0 ? alertSources.items : []
                    }
                    page={
                        alertSources && Object.keys(alertSources).length > 0
                            ? Number(alertSources.page)
                            : currentPage
                    }
                    pages={
                        alertSources && Object.keys(alertSources).length > 0
                            ? Number(alertSources.totalPages)
                            : 0
                    }
                    total={
                        alertSources && alertSources.hasOwnProperty("total") ? alertSources.total : 0
                    }
                    offset={offset}
                    onChangePage={(page) => setCurrentPage(page)}
                    onChangeRange={(value) => {
                        setOffset(value);
                        reduxGetAlertSources({
                            page: 1,
                            offset: value,
                            search: search,
                        });
                    }}
                    defaultValue={search}
                    onChangeSearch={(value) => {
                        setSearch(value);
                        reduxGetAlertSources({
                            page: 1,
                            offset: offset,
                            search: value,
                        });
                    }}
                />
            </div>
            <UpsertAlertSource show={showUpsertAlertSourceModal} handleClose={handleCloseUpsertModal} alertSourceData={upsertAlertSourceData} />
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        alertSources: state.alertSourceState.alertSources,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        reduxGetAlertSources: (payload) =>
            dispatch({
                type: "FETCH_ALERT_SOURCES_REQUEST",
                value: payload,
            }),
        reduxResetAlertSourceForm: () =>
            dispatch({
                type: "RESET_ALERT_SOURCE_FORM"
            }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AlertSources);