import React, { useState, useEffect, forwardRef } from "react";
import { Button, Form, FormControl } from "react-bootstrap";
import Feedback from "react-bootstrap/Feedback";
import { connect } from "react-redux";
import Select from "react-select";
import { useForm } from "react-hook-form";
import { ConfirmacionCierreEnum, EstadoEnum } from "../../../utils/enumsUtil";
import moment from "moment-timezone";
import DatePicker from "react-datepicker";
import { addressCloseConfirmationReportActions } from "../../../redux/reducers/AddressCloseConfirmationReducer";
import formatDateWithCustomFormat, {FormatsDate} from "../../../utils/formatDate";

const perpage = {
  page: 1,
  offset: 100000,
  search: "",
  isActive: true,
};

const FormFilter = ({
  onSubmit,
  reduxGetCompanies,
  reduxGetCities,
  reduxGetCouriers,
  cities,
  couriers,
  companies,
}) => {
  const [endDate, setEndDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const formuState = useForm();
  const {
    formState: { errors: errorRange },
  } = formuState;

  const changeInput = (el) => {
    formuState.setValue(el.currentTarget.name, el.currentTarget.value);
  };

  const changeStartDate = (date) => {
    setStartDate(date);
    formuState.setValue("startDate", formatDateWithCustomFormat(date, {format: FormatsDate.SHORT}));
  };

  const changeEndDate = (date) => {
    setEndDate(date);
    formuState.setValue("endDate", formatDateWithCustomFormat(date, {format: FormatsDate.SHORT}));
  };
  const DatePickerCustomFrom = forwardRef(
    ({ value, onClick, onChange }, ref) => (
      <>
        <input
          className={`form-control form-control-user ${
            formuState.formState.errors.startDate && "is-invalid"
          }`}
          onClick={onClick}
          onChange={onChange}
          ref={ref}
          value={value}
          autoComplete="Off"
          {...formuState.register("startDate", { required: true })}
        />
      </>
    )
  );
  const DatePickerCustomTo = forwardRef(({ value, onClick, onChange }, ref) => (
    <>
      <input
        className={`form-control form-control-user ${
          formuState.formState.errors.endDate && "is-invalid"
        }`}
        onClick={onClick}
        onChange={onChange}
        ref={ref}
        value={value}
        autoComplete="Off"
        {...formuState.register("endDate", { required: true })}
      />
    </>
  ));

  useEffect(() => {
    reduxGetCities(perpage);
    reduxGetCouriers(perpage);
    reduxGetCompanies(perpage);
  }, []);

  return (
    <>
      <form
        onSubmit={formuState.handleSubmit(onSubmit)}
        className={"form-row align-items-end"}
      >
        <Form.Group className="mb-0 col-2" controlId="startDate">
          <Form.Label>
            Fecha inicial <span className={"text-danger"}>*</span>
          </Form.Label>
          <div>
            <DatePicker
              selected={startDate}
              onChange={(date) => changeStartDate(date)}
              dateFormat="yyyy-MM-dd"
              customInput={<DatePickerCustomFrom />}
              maxDate={new Date()}
            />
          </div>
        </Form.Group>
        <Form.Group className="mb-0 col-2" controlId="endDate">
          <Form.Label>
            Fecha final <span className={"text-danger"}>*</span>
          </Form.Label>
          <div>
            <DatePicker
              selected={endDate}
              onChange={(date) => changeEndDate(date)}
              dateFormat="yyyy-MM-dd"
              customInput={<DatePickerCustomTo />}
              maxDate={new Date()}
            />
          </div>
        </Form.Group>
        <Form.Group className="mb-0 col-2">
          <label htmlFor="operator" className="form-label">
            Operador
          </label>
          
          <Select
            isClearable={true}
            {...formuState.register('operator')}
            id='operator'
            onChange={(value) => formuState.setValue('operator', value?.value || '')}
            options=
            {companies &&
              Object.keys(companies).length > 0 &&
              companies.items
                .map((ele) => ({                                    
                    value: ele.idCompany,
                    label: ele.description,
                  }))
            }
          />
        </Form.Group>
        <Form.Group className="mb-0 col-2" controlId="ciudad">
          <Form.Label>Ciudad</Form.Label>
          
          <Select
            isClearable={true}
            {...formuState.register('city')}
            id='city'
            onChange={(value) => formuState.setValue('city', value?.value || '')}
            options=
            {cities &&
              Object.keys(cities).length > 0 &&
              cities.items
                .map((ele) => ({                                    
                    value: ele.idCity,
                    label: ele.description,
                  }))
            }
          />
        </Form.Group>
        <Form.Group className="mb-0 col-2" controlId="mensajero">
          <Form.Label>Mensajero</Form.Label>
          <Select
            isClearable={true}
            {...formuState.register('courier')}
            id='courier'
            onChange={(value) => formuState.setValue('courier', value?.value || '')}
            options=
            {couriers &&
              Object.keys(couriers).length > 0 &&
              couriers
                .map((ele) => ({                                    
                    value: ele.idUser,
                    label: ele.nameHeadquarters != null
                      ? `${ele.nameHeadquarters} - ${ele.firstName} ${ele.lastName}`
                      : `${ele.firstName} ${ele.lastName}`,
                  }))
            }
          />
        </Form.Group>
        <Form.Group className="mb-0 col-2">
          <label>Confirmación de cierre</label>
          <Select
            isClearable={true}
            {...formuState.register("type", { required: false })}
            id='type'
            onChange={(value) => formuState.setValue('type', value?.value || '')}
            options={[
              {
                value: ConfirmacionCierreEnum.PAQUETE_NO_ENTREGADO,
                label: EstadoEnum[ConfirmacionCierreEnum.PAQUETE_NO_ENTREGADO]
              },
              {
                value: ConfirmacionCierreEnum.PAQUETE_INCOMPLETO,
                label: EstadoEnum[ConfirmacionCierreEnum.PAQUETE_INCOMPLETO]
              },
              {
                value: ConfirmacionCierreEnum.EFECTIVO_INCOMPLETO,
                label: EstadoEnum[ConfirmacionCierreEnum.EFECTIVO_INCOMPLETO]
              },
              {
                value: ConfirmacionCierreEnum.EFECTIVO_NO_ENTREGADO,
                label: EstadoEnum[ConfirmacionCierreEnum.EFECTIVO_NO_ENTREGADO]
              }
            ]}
          />
        </Form.Group>
        <Form.Group className="mb-0 col-2 align-content-end" controlId="ciudad">
          <Button type="submit">Buscar</Button>
        </Form.Group>
      </form>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    cities: state.cityState.cities,
    couriers: state.userState.couriers,
    companies: state.companyState.companies,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    reduxGetCities: (payload) =>
      dispatch({
        type: "FETCH_CITIES_REQUEST",
        value: payload,
      }),
    reduxGetCouriers: (payload) =>
      dispatch({
        type: "FETCH_COURIERS_REQUEST",
        value: payload,
      }),
    reduxGetClients: (payload) =>
      dispatch({
        type: "FETCH_COMPANIES_REQUEST",
        value: payload,
      }),
    reduxGetCompanies: (payload) =>
      dispatch({
        type: "FETCH_COMPANIES_REQUEST",
        value: payload,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormFilter);
