import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, Modal } from "react-bootstrap";

import { isNullOrUndefined } from "../../../utils/functionsUtils";
import formatDateWithCustomFormat, { formatDateWithoutTimeZone, FormatsDate } from "../../../utils/formatDate";
import Grid from "../../Grid";

const AlertTimeline = ({
    daemonAlertId,
    show,
    handleClose,
    reduxFetchDaemonAlertLog,
    daemonAlertLog
}) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [offset, setOffset] = useState(10);

    useEffect(() => {
        if(isNullOrUndefined(daemonAlertId)) return;

        reduxFetchDaemonAlertLog({
            id: daemonAlertId,
            page: currentPage,
            offset: offset,
        })
    }, [daemonAlertId, currentPage, offset])

    const columns = [
        {
            title: "#",
            render: (rowData) => {
                return <span>{rowData.id}</span>;
            },
        },
        {
            title: "Mensaje",
            render: (rowData) => {
                return <span>{rowData.message}</span>;
            },
        },
        {
            title: "Intento",
            render: (rowData) => {
                return <span>{rowData.attemptSeq}</span>;
            },
        },
        {
            title: "Fecha",
            render: (rowData) => {
                return <span>{formatDateWithCustomFormat(rowData.date, { format: FormatsDate.FULL24 })}</span>;
            },
        },
        {
            title: "Usuario",
            render: (rowData) => {
                return <span>{ rowData.user && `${rowData.user.firstName} ${rowData.user.lastName}`}</span>;
            },
        },
    ];

    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                onEscapeKeyDown={handleClose}
                backdrop="static"
                keyboard={false}
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <div className="d-flex justify-content-between align-items-center">
                            Timeline de Alerta
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Grid
                        showFilters={false}
                        cols={columns}
                        data={
                            daemonAlertLog && Object.keys(daemonAlertLog).length > 0 ? daemonAlertLog.items : []
                        }
                        page={
                            daemonAlertLog && Object.keys(daemonAlertLog).length > 0
                                ? Number(daemonAlertLog.page)
                                : currentPage
                        }
                        pages={
                            daemonAlertLog && Object.keys(daemonAlertLog).length > 0
                                ? Number(daemonAlertLog.totalPages)
                                : 0
                        }
                        total={
                            daemonAlertLog && daemonAlertLog.hasOwnProperty("total") ? daemonAlertLog.total : 0
                        }
                        offset={offset}
                        onChangePage={(page) => setCurrentPage(page)}
                        onChangeRange={(value) => {
                            setOffset(value);
                            reduxFetchDaemonAlertLog({
                                id: daemonAlertId,
                                page: 1,
                                offset: value,
                            });
                        }}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        daemonAlertLog: state.alertState.daemonAlertLog,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        reduxFetchDaemonAlertLog: (payload) =>
            dispatch({
                type: "FETCH_DAEMON_ALERT_LOG_REQUEST",
                value: payload,
            }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AlertTimeline);