const initialState = {
  requestingFetchCoverage: false,
  successfulFetchCoverage: false,
  errorsFetchCoverage: false,
  coverage: {},
  requestingCreateCoverage: false,
  successfulCreateCoverage: false,
  errorsCreateCoverage: false,
  requestingReadCoverage: false,
  successfulReadCoverage: false,
  errorsReadCoverage: false,
  rowEdited: null,
  requestingUpdateStatus: false,
  successfulUpdateStats: false,
  errorsUpdateStatus: false,
  rowUpdateStatus: null,
  requestingUpdateCoverage: false,
  successfulUpdateCoverage: false,
  errorsUpdateCoverage: false,
  rowUpdated: null,
  requestingFetchOneCoverage: false,
  successfulFetchOneCoverage: false,
  errorFetchOneCoverage: false,
  oneCoverage: {},
  errorCoverageType: false,
  requestingCoverageType: false,
  successfulCoverageType: false,
  coverageType: null,
  requestingUpdateOperatorCoverage: false,
  successfulUpdateOperatorCoverage: false,
  errorsUpdateOperatorCoverage: false,
  requestingAssignOperatorCoverage: false,
  successfulAssignOperatorCoverage: false,
  errorsAssignOperatorCoverage: false,
};

const coverageReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_COVERAGE_REQUESTING":
      return {
        ...state,
        requestingFetchCoverage: true,
        successfulFetchCoverage: false,
        errorsFetchCoverage: false,
      };
    case "FETCH_COVERAGE_SUCCESS":
      return {
        ...state,
        errorFetchCoverage: false,
        requestingFetchCoverage: false,
        successfulFetchCoverage: true,
        coverage: action.value,
      };
    case "FETCH_COVERAGE_ERROR":
      return {
        ...state,
        errorFetchCoverage: true,
        requestingFetchCoverage: false,
        successfulFetchCoverage: false,
      };
    case "CREATE_COVERAGE_REQUESTING":
      return {
        ...state,
        requestingCreateCoverage: true,
        successfulCreateCoverage: false,
        errorsCreateCoverage: false,
      };
    case "CREATE_COVERAGE_SUCCESS":
      return {
        ...state,
        errorsCreateCoverage: false,
        requestingCreateCoverage: false,
        successfulCreateCoverage: true,
        coverage: action.value,
      };
    case "CREATE_COVERAGR_ERROR":
      return {
        ...state,
        errorsCreateCoverage: true,
        requestingCreateCoverage: false,
        successfulCreateCoverage: false,
      };
    case "READ_COVERAGE_REQUESTING":
      return {
        ...state,
        requestingReadCoverage: true,
        successfulReadCoverage: false,
        errorsReadCoverage: false,
      };
    case "READ_COVERAGE_SUCCESS":
      return {
        ...state,
        errorsReadCoverage: false,
        requestingReadCoverage: false,
        successfulReadCoverage: true,
        rowEdited: action.value,
      };
    case "READ_COVERAGE_ERROR":
      return {
        ...state,
        errorsReadCoverage: true,
        requestingReadCoverage: false,
        successfulReadCoverage: false,
      };
    case "UPDATE_STATUS_COVERAGE_REQUESTING":
      return {
        ...state,
        requestingUpdateStatus: true,
        successfulUpdateStatus: false,
        errorsUpdateStatus: false,
      };
    case "UPDATE_STATUS_COVERAGE_SUCCESS":
      return {
        ...state,
        errorsUpdateStatus: false,
        requestingUpdateStatus: false,
        successfulUpdateStatus: true,
        rowUpdateStatus: action.value,
      };
    case "UPDATE_STATUS_COVERAGE_ERROR":
      return {
        ...state,
        errorsUpdateStatus: true,
        requestingUpdateStatus: false,
        successfulUpdateStatus: false,
      };
    case "UPDATE_COVERAGE_REQUESTING":
      return {
        ...state,
        requestingUpdateCoverage: true,
        successfulUpdateCoverage: false,
        // errorsUpdateCoverage: false,
      };
    case "UPDATE_COVERAGE_SUCCESS":
      return {
        ...state,
        errorsUpdateCoverage: false,
        requestingUpdateCoverage: false,
        successfulUpdateCoverage: true,
        rowUpdated: action.value,
      };
    case "UPDATE_COVERAGE_ERROR":
      return {
        ...state,
        errorsUpdateCoverage: true,
        requestingUpdateCoverage: false,
        successfulUpdateCoverage: false,
      };
    case "FETCH_ONECOVERAGE_REQUESTING":
      return {
        ...state,
        requestingFetchOneCoverage: true,
        successfulFetchOneCoverage: false,
        errorsFetchOneCoverage: false,
      };
    case "FETCH_ONECOVERAGE_SUCCESS":
      return {
        ...state,
        errorsFetchOneCoverage: false,
        requestingFetchOneCoverage: false,
        successfulFetchOneCoverage: true,
        oneCoverage: action.value,
      };
    case "FETCH_ONECOVERAGE_ERROR":
      return {
        ...state,
        errorsFetchOneCoverage: true,
        requestingFetchOneCoverage: false,
        successfulFetchOneCoverage: false,
      };
    case "FETCH_COVERAGETYPE_REQUESTING":
      return {
        ...state,
        requestingCoverageType: true,
        successfulCoverageType: false,
        errorsCoverageType: false,
      };
    case "FETCH_COVERAGETYPE_SUCCESS":
      return {
        ...state,
        errorCoverageType: false,
        requestingCoverageType: false,
        successfulCoverageType: true,
        coverageTypes: action.value,
      };
    case "FETCH_COVERAGETYPE_ERROR":
      return {
        ...state,
        errorCoverageType: true,
        requestingCoverageType: false,
        successfulCoverageType: false,
      };
    case "UPDATE_COVERAGE_OPERATOR_REQUESTING":
      return {
        ...state,
        requestingUpdateOperatorCoverage: true,
        successfulUpdateOperatorCoverage: false,
        errorsUpdateOperatorCoverage: false,
      };
    case "UPDATE_COVERAGE_OPERATOR_SUCCESS":
      return {
        ...state,
        requestingUpdateOperatorCoverage: false,
        successfulUpdateOperatorCoverage: true,
        errorsUpdateOperatorCoverage: false,
      };
    case "UPDATE_COVERAGE_OPERATOR_ERROR":
      return {
        ...state,
        requestingUpdateOperatorCoverage: false,
        successfulUpdateOperatorCoverage: false,
        errorsUpdateOperatorCoverage: true,
      };
    case "ASSIGN_COVERAGE_OPERATOR_REQUESTING":
      return {
        ...state,
        requestingAssignOperatorCoverage: true,
        successfulAssignOperatorCoverage: false,
        errorsAssignOperatorCoverage: false,
      };
    case "ASSIGN_COVERAGE_OPERATOR_SUCCESS":
      return {
        ...state,
        requestingAssignOperatorCoverage: false,
        successfulAssignOperatorCoverage: true,
        errorsAssignOperatorCoverage: false,
      };
    case "ASSIGN_COVERAGE_OPERATOR_ERROR":
      return {
        ...state,
        requestingAssignOperatorCoverage: false,
        successfulAssignOperatorCoverage: false,
        errorsAssignOperatorCoverage: true,
      };
    case "RESET_COVERAGE_FORM":
      return {
        ...state,
        requestingCreateCoverage: false,
        successfulCreateCoverage: false,
        errorsCreateCoverage: false,
        requestingReadCoverage: false,
        successfulReadCoverage: false,
        errorsReadCoverage: false,
        rowEdited: null,
        requestingDeleteCoverage: false,
        successfulDeleteCoverage: false,
        errorsDeleteCoverage: false,
        rowDeleted: null,
        requestingUpdateCoverage: false,
        successfulUpdateCoverage: false,
        errorsUpdateCoverage: false,
        rowUpdated: null,
        requestingFetchOneCoverage: false,
        successfulFetchOneCoverage: false,
        errorFetchOneCoverage: false,
        oneCoverage: {},
        requestingUpdateOperatorCoverage: false,
        successfulUpdateOperatorCoverage: false,
        errorsUpdateOperatorCoverage: false,
        requestingAssignOperatorCoverage: false,
        successfulAssignOperatorCoverage: false,
        errorsAssignOperatorCoverage: false,
      };
    case "RESET":
      return initialState;
    default:
      return state;
  }
};

export default coverageReducer;
