import io from "socket.io-client";


let socket;
const SOCKET_SERVER_URL = process.env.REACT_APP_REPORT_URL;

export const initiateSocket = (room) => {
    socket = io.connect(SOCKET_SERVER_URL, { query: `room=${room}`});
    console.log(`Connecting socket...${room} ${socket}`)

    if (!socket && !room) return;

    socket.emit('join', room)
}

export const disconnectSocket = () => {
    console.log(`Disconnecting socket...`);
    if (socket) socket.disconnect();
}

export const subscribeToProcess = (cb, key) => {
    if (!socket) return true;
    socket.on(key, msg => {
        console.log('Websocket event received!')
        return cb(null, msg)
    })
}

export const sendMessage = (room, message) => {
    if (socket) socket.emit('chat', {message, room})
}