import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  formatDateWithCustomFormat,
  FormatsDate,
} from "../../../../../utils/formatDate";
import { isNullOrUndefined } from "../../../../../utils/functionsUtils";
import { getStatusBadgeByCode, getStatusDataByCode } from "../../../../../utils/StatusUtils";

const Timeline = ({
  numOrder,
  reduxGetWMSOrderTracking,
  ordersEvents,
}) => {
  const [timeline, setTimeline] = useState([]);
  const getDetailEvent = (ele) => {return renderFullDetail(ele)};

  const renderFullDetail = (ele) => {
    const firstName = ele.user && ele.user.firstName ? ele.user.firstName : null;
    const lastName = ele.user && ele.user.lastName ? ele.user.lastName : null;
    const date = ele.date || null;
    const notes = ele.notes && ele.notes ? ele.notes : null;
    const idUser = ele.idUser && ele.idUser ? ele.idUser : null;
    const status = ele.status || null;

    return (
      <>
        <h2 className="mb-1">
        <a href="#">
        {firstName !== null && firstName !== '' && idUser !== null && (  
            `${firstName} ${lastName}`
        )}  
        </a>{" "}
          <span>
            Cambio estado a: <div className={`badge ${status.style.badge}`}>{status.description}</div>
          </span>
        </h2>        
        {date !== null && date !== '' && (
          <div>
            <span className="text-muted">Fecha :</span>{" "}
            {formatDateWithCustomFormat(date, {format: "DD-MM-YYYY HH:mm:ss"})}
          </div>
        )}
        {notes != null && (
          <div>
            <span>Nota :</span> {notes}
          </div>
        )}
      </>
    );
  };

  useEffect(() => {    
    if(numOrder && (!isNullOrUndefined(numOrder))){
        reduxGetWMSOrderTracking(numOrder);
    }
  }, [numOrder, reduxGetWMSOrderTracking]);

  useEffect(() => {
    if (ordersEvents && Object.keys(ordersEvents).length > 0) {
      setTimeline(ordersEvents);
    }
  }, [ordersEvents]);

  return (
    <div className="container">
      <div className="card shadow mb-4">
        <div className="row mt-4">
          <div className="col-md-10">
            <ul className="cbp_tmtimeline">
              {timeline.map((ele, key) => {
                return (
                  <li key={key}>
                    <time className="cbp_tmtime" dateTime="2017-10-22T12:13">
                    <span>
                        {formatDateWithCustomFormat(ele.date, {
                          format: FormatsDate.TIME_HOURS,
                        })}
                      </span>
                      <span>
                        {formatDateWithCustomFormat(ele.date, {
                          format: "DD-MM-YYYY",
                        })}
                      </span>
                    </time>
                    <div className={`cbp_tmicon bg-${(ele.status.style).bg}`}>
                      <i className={`${(ele.status.style).icon}`}></i>
                    </div>
                    <div className="cbp_tmlabel">{getDetailEvent(ele)}</div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    ordersEvents: state.warehouseState.ordersEvents,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    reduxGetWMSOrderTracking: (payload) =>
      dispatch({
        type: 'READ_WMSORDER_TRACKING_REQUEST',
        value: payload,
      })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Timeline);
