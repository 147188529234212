import React, { useEffect, useState } from "react";
import Sidebar from "../../components/Navigation/Sidebar";
import Topbar from "../../components/Navigation/Topbar";
import Grid from "../../components/Grid";
import { connect } from "react-redux";
import { Show } from "../../hooks/Show";
import EditForm from "./editForm.jsx";

const Role = ({
  roles,
  reduxGetRoles,
  reduxGetPermissions,
  permissions,
  reduxGetCompanies,
  companies,
  reduxPostRole,
  reduxPatchRole,
  reduxGetRole,
  rowEdited,
  reduxDeleteRole,
  rowDeleted,
  reduxResetRoleForm,
}) => {
  /* STATES */

  const [page, setPage] = useState(1);
  const [offset, setOffset] = useState(10);
  const [search, setSearch] = useState("");
  const [role, setRole] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [showModal, setShowModal] = useState(false);

  /* METHODS */

  const loadData = async () => {
    await reduxGetRoles({
      page,
      offset,
      search
    });
    await reduxGetPermissions({
      page: 1,
      search: "",
      offset: 1000,
      isActive: true
    });
    await reduxGetCompanies({
      page: 1,
      search: "",
      offset: 1000,
      isActive: true
    });
  };

  const handleCreateClick = () => {
    reduxResetRoleForm();
    setRole(null);
    setEditMode(false);
    setShowModal(true);
  };

  const handleEditClick = async (roleTemp) => {
    setEditMode(true);
    reduxGetRole({ id: roleTemp.idRole });
    setRole(rowEdited);
    setShowModal(true);
  };

  const handleDelete = async (role) => {
    try {
      await Promise.all([
        reduxDeleteRole(role),
        reduxResetRoleForm(),
        loadData()
      ]);
    } catch (error) {
      console.log(`Error al ejecutar las funciones: ${error}`);
    }
    await loadData();
  };

  const handleSubmit = async (event, permissions) => {
    try {
      event.preventDefault();
      const form = event.target.elements;
      const multiselectRef = permissions.map((permission) => {
        delete permission.isSelected;
        return { ...permission };
      });

      let idCompany = form.idCompany.value ? (form.idCompany.value === '0' ? null : form.idCompany.value) : null

      const data = {
        name: form.name.value,
        description: form.description.value,
        idCompany: idCompany,
        multiselectRef
      };

      if (editMode) {
        data.id = form.idRole.value;
        await reduxPatchRole({
          ...data,
          id: rowEdited.idRole,
          idCompany: data.idCompany === "" ? null : data.idCompany
        });
      } else {
        await reduxPostRole(data);
      }
      await reduxResetRoleForm();
      await reduxGetRoles({
        page: 1,
        offset: 10,
        search: ""
      });
      await loadData();
    } catch (error) {
      console.log(error);
    }
  };

  /* USE EFFECT */

  useEffect(() => {
    loadData().then();
  }, [page, offset, search, role, rowEdited]);

  /* TEMPLATE */

  const columns = [
    {
      title: "#",
      render: (rowData) => {
        return <span>{rowData.idRole}</span>;
      },
      field: "idRole",
      searchable: true
    },
    {
      title: "Nombre",
      render: (rowData) => {
        return <span>{rowData.name}</span>;
      },
      field: "name",
      searchable: true
    },
    {
      title: "Descripción",
      render: (rowData) => {
        return <span>{rowData.description}</span>;
      },
      field: "description",
      searchable: true
    },
    {
      title: "Empresa",
      render: (rowData) => {
        return (
          <span>{rowData.company ? rowData.company.description : ""}</span>
        );
      },
      field: "company.description",
      searchable: true
    },
    {
      title: "Opciones",
      render: (rowData) => {
        return (
          <>
            {rowData.isActive ? (
              <>
                <Show when="feature:edit-role">
                  <button
                    title="Editar"
                    className="btn btn-primary btn-sm  btn-circle mr-2"
                    type="button"
                    onClick={() => handleEditClick(rowData)}
                  >
                    <i className="fas fa-edit fa-xs"></i>
                  </button>
                </Show>
                <Show when="feature:disabled-role">
                  <button
                    title="Desactivar"
                    className="btn btn-danger btn-sm btn-circle"
                    type="button"
                    onClick={() => handleDelete(rowData)}
                  >
                    <i className="fas fa-times-circle fa-xs"></i>
                  </button>
                </Show>
              </>
            ) : (
              <Show when="feature:enabled-role">
                <button
                  title="Activar"
                  className="btn btn-primary btn-sm  btn-circle mr-2"
                  type="button"
                  onClick={() => handleDelete(rowData)}
                >
                  <i className="fas fa-check-circle fa-xs"></i>
                </button>
              </Show>
            )}
          </>
        );
      }
    }
  ];

  return (
    <div>
      <div id="wrapper">
        <Sidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Topbar />
            <div className="container-fluid">
              <div className="d-sm-flex align-items-center mb-1">
                <h1 className="h3 mb-0 text-gray-800 mr-3">Roles</h1>
                <Show when="feature:create-role">
                  <button
                    className="btn btn-primary btn-circle"
                    type="button"
                    onClick={handleCreateClick}
                  >
                    <i className="fas fa-plus fa-sm"></i>
                  </button>
                </Show>
              </div>
              <p className="mb-4">Módulo de Administración de roles</p>
              <div className="card shadow mb-4">
                <div className="card-header py-3">
                  <h6 className="m-0 font-weight-bold text-primary">
                    Listado de Roles
                  </h6>
                </div>
                <div className="card-body">
                  <Grid
                    cols={columns}
                    data={roles.items}
                    page={page}
                    pages={roles ? Number(roles.totalPages) : 0}
                    total={roles ? roles.total : 0}
                    offset={offset}
                    onChangePage={(page) => setPage(page)}
                    onChangeRange={(value) => {
                      setOffset(value);
                      setPage(1);
                    }}
                    defaultValue={search}
                    onChangeSearch={(value) => {
                      setSearch(value);
                      setPage(1);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <EditForm
        role={rowEdited}
        show={showModal}
        onHide={() => {
          setShowModal(false);
          reduxResetRoleForm();
        }}
        editMode={editMode}
        submit={handleSubmit}
        permissions={permissions.items}
        companies={companies.items}
        postsubmit={loadData}
      />
    </div>
  );
};

/* OTHERS */

const mapStateToProps = (state) => {
  return {
    roles: state.roleState.roles,
    role: state.roleState.role,
    rowEdited: state.roleState.rowEdited,
    rowDeleted: state.roleState.rowDeleted,
    rowUpdated: state.roleState.rowUpdated,
    companies: state.companyState.companies,
    permissions: state.permissionState.permissions,
    permissionsRoles: state.permissionRoleState.permissionsRoles
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    reduxGetCompanies: (payload) =>
      dispatch({
        type: "FETCH_COMPANIES_REQUEST",
        value: payload
      }),
    reduxGetPermissions: (payload) =>
      dispatch({
        type: "FETCH_PERMISSIONS_REQUEST",
        value: payload
      }),
    reduxGetPermissionsRoles: (payload) =>
      dispatch({
        type: "FETCH_PERMISSIONS_ROLES_REQUEST",
        value: payload
      }),
    reduxGetRoles: (payload) =>
      dispatch({
        type: "FETCH_ROLES_REQUEST",
        value: payload
      }),
    reduxPostRole: (payload) =>
      dispatch({
        type: "CREATE_ROLE_REQUEST",
        value: payload
      }),
    reduxGetRole: (payload) =>
      dispatch({
        type: "READ_ROLE_REQUEST",
        value: payload
      }),
    reduxDeleteRole: (payload) =>
      dispatch({
        type: "DELETE_ROLE_REQUEST",
        value: payload
      }),
    reduxPatchRole: (payload) =>
      dispatch({
        type: "UPDATE_ROLE_REQUEST",
        value: payload
      }),
    reduxResetRoleForm: () =>
      dispatch({
        type: "RESET_ROLE_FORM"
      })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Role);
