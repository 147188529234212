import React, { useEffect, useState, useContext } from "react";
import moment from "moment-timezone";
import { connect } from "react-redux";
import ImageS3 from "../ImageS3";
import { AddressStatus, StatusEnum } from "../../utils/enumsUtil";
import {
  formatDateWithCustomFormat,
  FormatsDate,
} from "../../utils/formatDate";
import { isNullOrUndefined } from '../../utils/functionsUtils';
import { getStatusBadgeByCode, getStatusDataByCode } from "../../utils/StatusUtils";
import { PermissionContext } from '../../hooks/PermissionContext';
const Timeline = ({
  idAddress,
  reduxGetAddressesEventByidAddress,
  addressesEvents,
}) => {
  const [timeline, setTimeline] = useState([]);
  const [address, setAddress] = useState(null);
  const { hasPermission } = useContext(PermissionContext);

  const getDetailEvent = (ele) => {
    switch (ele.statusCode) {
      case StatusEnum.NOENTR:
      case StatusEnum.CREADO:
      case StatusEnum.ENTRAN:
      case StatusEnum.ALISTA:
      case StatusEnum.ENESPE:
      case StatusEnum.ENTREG:
      case StatusEnum.DESPAC:
      case StatusEnum.CENRET:
      case StatusEnum.CENDIS:
      case StatusEnum.ANULAD:
      case StatusEnum.ASIGNA:
      case StatusEnum.REPROG:
        return renderFullDetail(ele);
    }
  };

  const renderFullDetail = (ele) => {
    return (
      <>
        <h2 className="mb-1">
          <a href="#">{getFullName(ele)}</a>{" "}
          <span>Cambio estado a: {getStatusBadgeByCode(ele.statusCode)}</span>
        </h2>
        {ele.userFirstName !== null && ele.userFirstName !== '' && ele.idUser !== null && (
          <div>
            <span className="text-muted">Mensajero :</span>{" "}
            {ele.userFirstName} {ele.userLastName}
          </div>
        )}
        {ele.noveltyname != null && (
          <div>
            <span className="text-muted">Novedad :</span>{" "}
            {ele.noveltyname}
          </div>
        )}
        {ele.noteDescription != null && (
          <div>
            <span>Nota :</span> {ele.noteDescription}
          </div>
        )}
        {ele.txtNote != null && ele.txtNote != "" && (
          <div>
            <span>{"Comentario"} : </span> {ele.txtNote}
          </div>
        )}
        {!isNullOrUndefined(ele.deliveryLocation) && (
          <div>
            <span>{"Ubicación de Entrega"} : </span> {ele.deliveryLocation}
          </div>
        )}
        {!isNullOrUndefined(ele.picture) && (
          <div className="row mt-2">
            <div className="col-lg-12">
              <span>Fotos evidencia: </span>
              <div className="d-flex">
                <div className="mt-2">
                  <ImageS3
                    picture={`${ele.picture}`}
                    alt=""
                    className="img-thumbnail"
                  />
                </div>
                {!isNullOrUndefined(ele.picture_2) && (
                  <div className="mt-2">
                    <ImageS3
                      picture={`${ele.picture_2}`}
                      alt=""
                      className="img-thumbnail"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        {ele.signature != null && ele.signature != "" && (
          <div className="row clearfix mt-2">
            <div className="col-lg-12">
              <span>Firma: </span>
              <div className="map m-t-10 mt-2">
                <ImageS3
                  picture={`${ele.signature}`}
                  alt=""
                  className="img-fluid img-thumbnail m-t-30"
                />
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  const getFullName = (record) => {
    let firstName;
    let lastName;
    if (hasPermission('feature:isClient') && record.userFirstName !== null){
        firstName = record.userFirstName;
        lastName = record.userLastName;
    } else {
        firstName = record.userSessionFirstName || record.userFirstName || "N/A";
        lastName = record.userSessionLastName || record.userLastName || "";
    }
    return `${firstName} ${lastName}`;
  };

  useEffect(() => {
    reduxGetAddressesEventByidAddress({
      idAddress: idAddress,
    });
  }, []);

  useEffect(() => {
    if (addressesEvents && Object.keys(addressesEvents).length > 0) {
      setTimeline(addressesEvents);
      setAddress(addressesEvents[0].address);
    }
  }, [addressesEvents]);

  return (
    <div className="container">
      {address != null && (
        <div className="card shadow mb-4">
          <li className="list-group-item d-flex justify-content-between lh-condensed">
            <div>
              <h6 className="my-0 font-weight-bold">
                {address.firstName} {address.lastName}
              </h6>
              <h6 className="my-0 text-muted">{address.address}</h6>
              <small className="text-muted">{address.reference1}</small>{" "}
              <small className="text-muted">{address.reference2}</small>
            </div>
          </li>
        </div>
      )}
      <div className="card shadow mb-4">
        <div className="row mt-4">
          <div className="col-md-10">
            <ul className="cbp_tmtimeline">
              {timeline.map((ele, key) => {
                return (
                  <li key={key}>
                    <time className="cbp_tmtime" dateTime="2017-10-22T12:13">
                      <span>
                        {formatDateWithCustomFormat(ele.date, {
                          format: FormatsDate.TIME_HOURS,
                        })}
                      </span>
                      <span>
                        {formatDateWithCustomFormat(ele.date, {
                          format: "DD-MM-YYYY",
                        })}
                      </span>
                    </time>
                    <div className={`cbp_tmicon bg-${getStatusDataByCode(ele.statusCode).class.bg}`}>
                      <i className={`${getStatusDataByCode(ele.statusCode).class.icon}`}></i>
                    </div>
                    <div className="cbp_tmlabel">{getDetailEvent(ele)}</div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    addressesEvents: state.addressesEventState.addressesEventsByIdAddress,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    reduxGetAddressesEventByidAddress: (payload) =>
      dispatch({
        type: "READ_BY_IDADDRESS_ADDRESSES_EVENT_REQUEST",
        value: payload,
      }),
    reduxResetAddressesEvents: () =>
      dispatch({
        type: "RESET_READ_BY_IDADDRESS_ADDRESSES_EVENT",
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Timeline);
