import React from "react";
import {Image} from "react-bootstrap";
import spinner from '../../assets/img/Logo.gif'
const Loader = ({ show }) => {
    return (
        <div
            id="overlay"
            className={show ? "show-spinner" : "hide-spinner"}
        >
            <Image title={'Loading...'} src={spinner} style={{ width: '75px', height: '75px' }}></Image>
        </div>
    );
};

export default Loader;