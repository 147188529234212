
const initialState = {

    items: [],
    requesting: false,
    succefullFetch: false,
    errorFetch: false,
    filterFields: {}

}

const auditoriaNotificationsReducers = (state = initialState, payload) => {
    switch (payload.type) {
        case auditoriaNotificationsActions.FECTCHIN_ALL:
            return {
                ...state,
                requesting: payload.value
            }
        case auditoriaNotificationsActions.FETCH_SUCCESS:
            return  {
                ...state,
                errorFetch: false,
                requesting: false,
                succefullFetch: true,
                items: payload.value
            }
        case auditoriaNotificationsActions.FETCH_ERROR:
            return {
                ...state,
                errorFetch: true,
                requesting: false,
                succefullFetch: false,
            }
        default:
            return state;
    }
}


export const auditoriaNotificationsActions = {
    FECTCHIN_ALL: 'FECTCHIN_ALL',
    FECTCH_ALL: 'FECTCH_ALL',
    FETCH_SUCCESS: 'FETCH_SUCCESS',
    FETCH_ERROR: 'FETCH_ERROR'
}

export default auditoriaNotificationsReducers