const initialState = {
  requestingFetchStatuses: false,
  successfulFetchStatuses: false,
  errorFetchStatuses: false,
  statuses: {},
  requestingCreateStatus: false,
  successfulCreateStatus: false,
  errorsCreateStatus: false,
  status: null,
  requestingUpdateStatus: false,
  successfulUpdateStatus: false,
  errorsUpdateStatus: false,
  rowUpdated: null,
  requestingReadStatus: false,
  successfulReadStatus: false,
  errorsReadStatus: false,
  rowEdited: null,
  requestingDeleteStatus: false,
  successfulDeleteStatus: false,
  errorsDeleteStatus: false,
  rowDeleted: null,
};

const statusesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_NEW_STATUS_REQUESTING":
      return {
        ...state,
        requestingFetchStatuses: true,
        successfulFetchStatuses: false,
        errorFetchStatuses: false,
      };
    case "FETCH_NEW_STATUS_SUCCESS":
      return {
        ...state,
        errorFetchStatuses: false,
        requestingFetchStatuses: false,
        successfulFetchStatuses: true,
        statuses: action.value,
      };
    case "FETCH_NEW_STATUS_ERROR":
      return {
        ...state,
        errorFetchStatuses: true,
        requestingFetchStatuses: false,
        successfulFetchStatuses: false,
      };
    case "CREATE_NEW_STATUS_REQUESTING":
      return {
        ...state,
        requestingCreateStatus: true,
        successfulCreateStatus: false,
        errorsCreateStatus: false,
      };
    case "CREATE_NEW_STATUS_SUCCESS":
      return {
        ...state,
        errorsCreateStatus: false,
        requestingCreateStatus: false,
        successfulCreateStatus: true,
        status: action.value,
      };
    case "CREATE_NEW_STATUS_ERROR":
      return {
        ...state,
        errorsCreateStatus: true,
        requestingCreateStatus: false,
        successfulCreateStatus: false,
      };
    case "UPDATE_NEW_STATUS_REQUESTING":
      return {
        ...state,
        requestingUpdateStatus: true,
        successfulUpdateStatus: false,
        errorsUpdateStatus: false,
      };
    case "UPDATE_NEW_STATUS_SUCCESS":
      return {
        ...state,
        errorsUpdateStatus: false,
        requestingUpdateStatus: false,
        successfulUpdateStatus: true,
        rowUpdated: action.value,
      };
    case "UPDATE_NEW_STATUS_ERROR":
      return {
        ...state,
        errorsUpdateStatus: true,
        requestingUpdateStatus: false,
        successfulUpdateStatus: false,
      };
    case "READ_NEW_STATUS_REQUESTING":
      return {
        ...state,
        requestingReadStatus: true,
        successfulReadStatus: false,
        errorsReadStatus: false,
      };
    case "READ_NEW_STATUS_SUCCESS":
      return {
        ...state,
        errorsReadStatus: false,
        requestingReadStatus: false,
        successfulReadStatus: true,
        rowEdited: action.value,
      };
    case "READ_NEW_STATUS_ERROR":
      return {
        ...state,
        errorsReadStatus: true,
        requestingReadStatus: false,
        successfulReadStatus: false,
      };
    case "DELETE_NEW_STATUS_REQUESTING":
      return {
        ...state,
        requestingDeleteStatus: true,
        successfulDeleteStatus: false,
        errorsDeleteStatus: false,
      };
    case "RESET_NEW_STATUS_FORM":
      return {
        ...state,
        requestingFetchStatuses: false,
        successfulFetchStatuses: false,
        errorFetchStatuses: false,
        requestingCreateStatus: false,
        successfulCreateStatus: false,
        errorsCreateStatus: false,
        status: null,
        requestingUpdateStatus: false,
        successfulUpdateStatus: false,
        errorsUpdateStatus: false,
        rowUpdated: null,
        requestingReadStatus: false,
        successfulReadStatus: false,
        errorsReadStatus: false,
        rowEdited: null,
        requestingDeleteStatus: false,
        successfulDeleteStatus: false,
        errorsDeleteStatus: false,
        rowDeleted: null,
      };
    case "RESET":
      return initialState;
    default:
      return state;
  }
};

export default statusesReducer;
