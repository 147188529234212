import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { connect } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import DatePicker from 'react-datepicker';
import Sidebar from "../../../components/Navigation/Sidebar";
import Topbar from "../../../components/Navigation/Topbar";
import Header from "../../../components/Page/header";
import Grid from '../../../components/Grid';
import Select from 'react-select'
import { Button } from "react-bootstrap";
import { Show } from "../../../hooks/Show";

import { buttonIcon, buttonTypes } from "../../../utils/buttonsUtil";
import { formatDateWithCustomFormat, FormatsDate } from "../../../utils/formatDate"
import { isNullOrUndefined } from "../../../utils/functionsUtils";

const Incomings = ({
    reduxFetchIncomings,
    incomings,
    reduxGetProducts,
    products,
    reduxGetWarehouses,
    warehouses,
    reduxGetShippers,
    shippers,
    reduxFetchMovements,
    movements,
    reduxDownloadMovements,
}) => {
    const {
        handleSubmit: searchSubmit,
        control: searchControl,
        formState: { errors: searchErrors },
        setValue: setSearchValue,
        reset: resetSearch,
        watch: searchWatch,
    } = useForm({
        defaultValues: {
            shipper: null,
            warehouses: null,
            products: null,
            movementType: null,
            dates: null,
        }
    });

    const history = useHistory();
    const userData = JSON.parse(window.localStorage.getItem('userData'));
    const countryUser = userData?.idCountry;

    const [currentPage, setCurrentPage] = useState(1);
    const [offset, setOffset] = useState(10);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [fromDateFormatted, setFromDateFormatted] = useState(null);
    const [toDateFormatted, setToDateFormatted] = useState(null);

    const shipperWatcher = searchWatch("shipper", null);
    const movementTypeWatcher = searchWatch("movementType", null);
    const warehouseWatcher = searchWatch("warehouses", null);
    const productsWatcher = searchWatch("products", null);

    const customStyles = {
        menu: (base) => ({ ...base, zIndex: 9999 }),
        valueContainer: (baseStyles) => ({ ...baseStyles, maxHeight: "4.5vw", overflowY: "auto" }),
        control: (baseStyles) => ({ ...baseStyles, flexWrap: "nowrap" }) 
    };

    const changeDates = (dates) => {
        const [start, end] = dates;

        setStartDate(start);
        setEndDate(end);
      };

    useEffect(() => {
        reduxGetShippers();
        reduxGetProducts({
            page: 1,
            offset: 1000,
        });
        reduxGetWarehouses({
            page: 1,
            offset: 1000,
        });
        performSearch(null);
    }, [])

    const performSearch = (data) => {
        
        let start = null;
        let end = null;
        
        if(!isNullOrUndefined(startDate) && !isNullOrUndefined(endDate)){
            const fromDateFormatted = formatDateWithCustomFormat(startDate);
            const toDateFormatted = formatDateWithCustomFormat(endDate);
            start = `${fromDateFormatted} 00:00:00`;
            end = `${toDateFormatted} 23:59:59`;
        }

        setFromDateFormatted(start);
        setToDateFormatted(end);
        
        reduxFetchMovements({
            page: 1,
            offset: offset,
            shipper: shipperWatcher && shipperWatcher?.value || null,
            warehouses: warehouseWatcher && warehouseWatcher?.map(e => e.value).join(',') || null,
            products: productsWatcher && productsWatcher?.map(e => e.value).join(',') || null,
            movementType: movementTypeWatcher && movementTypeWatcher?.value || null,
            startDate: start || null,
            endDate: end || null,
        })
        
    }
    const downloadMovementsReport = (data) => {
        let start = null;
        let end = null;
        
        if(!isNullOrUndefined(startDate) && !isNullOrUndefined(endDate)){
            const fromDateFormatted = formatDateWithCustomFormat(startDate);
            const toDateFormatted = formatDateWithCustomFormat(endDate);
            start = `${fromDateFormatted} 00:00:00`;
            end = `${toDateFormatted} 23:59:59`;
        }
        setFromDateFormatted(start);
        setToDateFormatted(end);
        
        reduxDownloadMovements({
            shipper: shipperWatcher && shipperWatcher?.value || null,
            warehouses: warehouseWatcher && warehouseWatcher?.map(e => e.value).join(',') || null,
            products: productsWatcher && productsWatcher?.map(e => e.value).join(',') || null,
            movementType: movementTypeWatcher && movementTypeWatcher?.value || null,
            startDate: start || null,
            endDate: end || null,
        })
    }

    const actionButtons = [
        {
            onClick: () => history.push("/inventory"),
            description: "Regresar a Inventario",
            buttonType: buttonTypes.Primary,
            buttonIcon: buttonIcon.Arrow_LeftReply,
        },
        {
            permission: 'feature:wms-inventory-receivings-process',
            onClick: () => history.push("/wms/receiving"),
            description: "Procesar Nueva Recepción",
            buttonType: buttonTypes.Success,
            buttonIcon: buttonIcon.Inbox,
        },
        {
            permission: 'feature:wms-inventory-returns-process',
            onClick: () => history.push("/wms/pending-returns"),
            description: "Devoluciones Pendientes",
            buttonType: buttonTypes.Success,
            buttonIcon: buttonIcon.Exchange,
        },
        {
            permission: 'feature:wms-inventory-relocation-process',
            onClick: () => history.push("/wms/incomingRelocations"),
            description: "Procesar Transferencia",
            buttonType: buttonTypes.Success,
            buttonIcon: buttonIcon.TruckMoving,
        },
    ];

    const columns = [
        {
            title: "Id Movimiento",
            render: (rowData) => {
                return <span>{rowData.idMovement}</span>;
            },
        },
        {
            title: "Bodega",
            render: (rowData) => {
                return <span>{rowData.inventory.subWarehouse.name}</span>;
            },
        },
        {
            title: "Producto",
            render: (rowData) => {
                return <span>{rowData.inventory.product.name}</span>;
            },
        },
        {
            title: "Remitente",
            render: (rowData) => {
                return <span>{rowData.inventory.product.company.description}</span>;
            },
        },
        {
            title: "Cantidad Total",
            render: (rowData) => {
                return <span>{rowData.quantity}</span>;
            },
        },
        {
            title: "Notas",
            render: (rowData) => {
                return <span>{rowData.notes}</span>;
            },
        },
        {
            title: "Tipo Movimiento",
            render: (rowData) => {
                if (rowData.type === "INCOMING" && rowData.subtype === "RETURN") {
                    return <span>Devolucion</span>;
                } else if (rowData.type === "INCOMING") {
                    return <span>Ingreso</span>;
                } else if (rowData.type === "OUTGOING") {
                    return <span>Salida</span>;
                }
            },
        },
        {
            title: "Fecha",
            render: (rowData) => {
                return <span>{formatDateWithCustomFormat(rowData.date, { format: 'DD/MM/yyyy hh:mm:ss' })}</span>;
            },
        },
        {
            title: "Lote",
            render: (rowData) => {
                return <span>{rowData.wmsLots ? `Num: ${rowData.wmsLots.number} - Serial: ${rowData.wmsLots.serial} - Exp: ${new Date(rowData.wmsLots.dateExp).toLocaleDateString(undefined, { year: 'numeric', month: '2-digit', day: '2-digit' })}` : ''}</span>;
            },
        },
        // {
        //     title: "Acciones",
        //     render: (rowData) => {
        //         return (
        //             <>
        //                 {/* <Show when='feature:wms-inventory-receivings-manifest-show'>
        //                     <button
        //                         title='Inventario por Lotes'
        //                         className="btn btn-success btn-sm  btn-circle mr-2"
        //                         type="button"
        //                         onClick={() => handleOpenInventoryLots(rowData.idInventory, rowData.idWarehouse, rowData.product.idCompany)}
        //                     >
        //                         <i className='fa fa-th-large'></i>
        //                     </button>
        //                 </Show> */}
        //             </>
        //         );
        //     },
        // },
    ]

    return (
        <>
            <div>
                <div id="wrapper">
                    <Sidebar />
                    <div id="content-wrapper" className="d-flex flex-column">
                        <div id="content">
                            <Topbar />
                            <div className="container-fluid">
                                <Header title={"Ingresos"} subtitle={"Módulo para la gestión de ingresos de inventario"} actionButtons={actionButtons} />
                            </div>
                            <div className="card shadow mb-4">
                                <div className="card-header py-3">
                                    <h6 className="m-0 font-weight-bold text-primary">
                                        Listado de Movimientos
                                    </h6>
                                </div>
                                <div className="card-body">
                                    <form onSubmit={searchSubmit(performSearch)}>
                                        <div className="d-flex">
                                            <Show whenNot={["feature:isClient"]}>
                                                <div className='form-group w-25 mr-2'>
                                                    <label
                                                        htmlFor='shipper'
                                                        className='form-label'>
                                                        Remitente
                                                    </label>
                                                    <Controller
                                                        control={searchControl}
                                                        name="shipper"
                                                        render={({ field }) => (
                                                            <>
                                                                <Select
                                                                    {...field}
                                                                    isClearable
                                                                    styles={customStyles}
                                                                    options={
                                                                        shippers && Object.keys(shippers).length > 0
                                                                            ? shippers.items
                                                                                .filter(e => e.isActive === true && e.country == countryUser && countryUser)
                                                                                .map((ele, key) => ({
                                                                                    value: ele.idCompany,
                                                                                    label: ele.description,
                                                                                }))
                                                                            : []
                                                                    }
                                                                />
                                                                {
                                                                    searchErrors.shipper &&
                                                                    <span className="error-message">
                                                                        {searchErrors.shipper.message}
                                                                    </span>
                                                                }
                                                            </>
                                                        )}
                                                    />
                                                </div>
                                            </Show>
                                            <div className='form-group w-25 mr-2'>
                                                <label
                                                    htmlFor='warehouses'
                                                    className='form-label'>
                                                    Bodega
                                                </label>
                                                <Controller
                                                    control={searchControl}
                                                    name="warehouses"
                                                    render={({ field }) => (
                                                        <>
                                                            <Select
                                                                {...field}
                                                                isMulti
                                                                isClearable
                                                                styles={customStyles}
                                                                options={
                                                                    warehouses && warehouses.items
                                                                        ? warehouses.items
                                                                            .filter(e => e.isActive === true && countryUser && e.warehouseHubs[0]?.hub?.idCountry == countryUser)
                                                                            .map(ele => ({
                                                                                value: ele.idWarehouse,
                                                                                label: ele.name || ele.warehouseCode,
                                                                            }))
                                                                        : []
                                                                }
                                                            />
                                                        </>
                                                    )}
                                                />
                                            </div>
                                            <div className='form-group w-25 mr-2'>
                                                <label
                                                    htmlFor='products'
                                                    className='form-label'>
                                                    Producto
                                                </label>
                                                <Controller
                                                    control={searchControl}
                                                    name="products"
                                                    render={({ field }) => (
                                                        <>
                                                            <Select
                                                                {...field}
                                                                isMulti
                                                                isClearable
                                                                styles={customStyles}
                                                                isDisabled={!shipperWatcher}
                                                                options={
                                                                    products && products.items
                                                                        ? products.items
                                                                            .filter(e => e.isActive === true && shipperWatcher && e.idShipper == shipperWatcher.value)
                                                                            .map(ele => ({
                                                                                value: ele.idProduct,
                                                                                label: ele.name,
                                                                            }))
                                                                        : []
                                                                }
                                                            />
                                                        </>
                                                    )}
                                                />
                                            </div>
                                            <div className='form-group w-25 mr-2'>
                                                <label
                                                    htmlFor='movementType'
                                                    className='form-label'>
                                                    Tipo de Movimiento
                                                </label>
                                                <Controller
                                                    control={searchControl}
                                                    name="movementType"
                                                    render={({ field }) => (
                                                        <>
                                                            <Select
                                                                {...field}
                                                                isClearable
                                                                styles={customStyles}
                                                                options={
                                                                    [
                                                                        { value: "INCOMING", label: "Ingreso" },
                                                                        { value: "OUTGOING", label: "Salida" },
                                                                        { value: "RETURN", label: "Devolución" },
                                                                    ]
                                                                }
                                                            />
                                                        </>
                                                    )}
                                                />
                                            </div>
                                            <div className='form-group w-25 mr-2'>
                                                <label
                                                    htmlFor='dates'
                                                    className='form-label'>
                                                    Desde - Hasta *
                                                </label>
                                                <Controller
                                                    control={searchControl}
                                                    name="dates"
                                                    render={({ field }) => (
                                                        <>
                                                            <DatePicker
                                                                className={'form-control'}
                                                                startDate={startDate}
                                                                endDate={endDate}
                                                                selectsRange
                                                                onChange={(value) => {
                                                                    field.onChange(value);
                                                                    changeDates(value);
                                                                }}
                                                                dateFormat="yyyy-MM-dd"
                                                                maxDate={new Date()}
                                                                shouldCloseOnSelect={false}
                                                                isClearable
                                                            />
                                                        </>
                                                    )}
                                                />
                                            </div>
                                            <div className='form-group mr-2 mt-4'>
                                                <Button
                                                    variant='primary'
                                                    type='submit'
                                                    id='btnSearch'>
                                                    Buscar
                                                </Button>
                                            </div>
                                            <div className='form-group mr-2 mt-4'>
                                                <Button
                                                    variant='primary'
                                                    onClick={() => downloadMovementsReport(movements.items)}
                                                    id='btnSearch'>
                                                    Descargar
                                                </Button>
                                            </div>
                                        </div>
                                    </form>
                                    <Grid
                                        showFilters={false}
                                        cols={columns}
                                        data={
                                            movements && Object.keys(movements).length > 0 ? movements.items : []
                                        }
                                        page={
                                            movements && Object.keys(movements).length > 0
                                                ? Number(movements.page)
                                                : currentPage
                                        }
                                        pages={
                                            movements && Object.keys(movements).length > 0
                                                ? Number(movements.totalPages)
                                                : 0
                                        }
                                        total={
                                            movements && movements.hasOwnProperty("total") ? movements.total : 0
                                        }
                                        offset={offset}
                                        onChangePage={(value) => {
                                            setCurrentPage(value)
                                            reduxFetchMovements({
                                                page: value,
                                                offset: offset,
                                                shipper: shipperWatcher && shipperWatcher?.value || null,
                                                warehouses: warehouseWatcher && warehouseWatcher?.map(e => e.value).join(',') || null,
                                                products: productsWatcher && productsWatcher?.map(e => e.value).join(',') || null,
                                                movementType: movementTypeWatcher && movementTypeWatcher?.value || null,
                                                startDate: fromDateFormatted || null,
                                                endDate: toDateFormatted || null,
                                            });
                                        }}
                                        onChangeRange={(value) => {
                                            setOffset(value);
                                            reduxFetchMovements({
                                                page: 1,
                                                offset: value,
                                                shipper: shipperWatcher && shipperWatcher?.value || null,
                                                warehouses: warehouseWatcher && warehouseWatcher?.map(e => e.value).join(',') || null,
                                                products: productsWatcher && productsWatcher?.map(e => e.value).join(',') || null,
                                                movementType: movementTypeWatcher && movementTypeWatcher?.value || null,
                                                startDate: fromDateFormatted || null,
                                                endDate: toDateFormatted || null,
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        incomings: state.wmsState.incomings,
        products: state.productState.products,
        shippers: state.companyState.clients,
        warehouses: state.warehouseState.warehouses,
        movements: state.wmsState.movements,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        reduxFetchIncomings: (payload) =>
            dispatch({
                type: "FETCH_WMS_RECEIVINGS_REQUEST",
                value: payload,
            }),
        reduxGetProducts: (payload) =>
            dispatch({
                type: "FETCH_PRODUCTS_REQUEST",
                value: payload,
            }),
        reduxGetShippers: (payload) =>
            dispatch({
                type: 'FETCH_COMPANIESBYSHIPPER_REQUEST',
                value: payload,
            }),
        reduxGetWarehouses: (payload) =>
            dispatch({
                type: "FETCH_WAREHOUSES_REQUEST",
                value: payload,
            }),
        reduxFetchMovements: (payload) =>
            dispatch({
                type: "FETCH_WMS_MOVEMENTS_REQUEST",
                value: payload,
            }),
        reduxDownloadMovements: (payload) =>
            dispatch({
                type: "DOWNLOAD_WMS_MOVEMENTS_REQUEST",
                value: payload,
            }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Incomings);