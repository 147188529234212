import React, { useState, useRef, useEffect, useCallback } from "react";
import { useForm, FormProvider, Controller } from "react-hook-form";
import Sidebar from "../../components/Navigation/Sidebar";
import Topbar from "../../components/Navigation/Topbar";
import Grid from "../../components/Grid";
import { connect } from "react-redux";
import { Modal, Button, Form, Row, Col, Table } from "react-bootstrap";
import { Show } from "../../hooks/Show";
import GoogleMapsAutocomplete from "../../components/GoogleMapsAutocomplete";
import Select from "react-select";
import { CompanyTypes, CompanyTypesEnum } from "../../utils/enumsUtil";
import RadioButton from "../../components/Inputs/RadioButton";
import Swal from "sweetalert2";
import { isNullOrUndefined } from "../../utils/functionsUtils";

const Company = ({
  reduxGetCompanies,
  companies,
  reduxGetAllCompanies,
  allCompanies,
  reduxPostCompany,
  company,
  reduxGetCompany,
  rowEdited,
  reduxDeleteCompany,
  rowDeleted,
  reduxPatchCompany,
  rowUpdated,
  reduxResetCompanyForm,
  reduxGetNeighborhoodsByCity,
  neighborhoodsByCity,
  reduxGetDepartmentsByCountry,
  departmentsByCountry,
  reduxGetCitiesByDepartment,
  citiesByDepartment,
  countries,
  reduxGetCountries,
  reduxSetDepartment,
  reduxSetCity,
  reduxGetBranches,
  branches,
  errorsUpdateCompany,
  errorsCreateCompany,
  reduxDeleteOperatorBranch
}) => {
  const modalForm = useForm({
    defaultValues: {
      serviceType: "UM",
      indicativeRoad: "",
      roadNumber: "",
      appendixRoad: "",
      crossoverRoad: "",
      crossoverNumber: "",
      appendixCrossingNumber: "",
      orientationCrossingNumber: "",
      insideTypes: "",
      doorNumber: "",
      inside: "",
      city: "",
      neighborhood: "",
      country: "",
      department: "",
      prefix: "",
    },
  });

  const {
    control: formControl,
    formState: { errors: formErrors },
    watch: formWatch,
    setValue: setFormValue,
    getValues: getFormValues,
    setError: formSetError,
    clearErrors: formClearErrors,
    reset: resetForm,
  } = useForm({
    defaultValues: {
      operatorsBranch: [],
    },
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(10);
  const [show, setShow] = useState(false);
  const [currentCompany, setCurrentCompany] = useState("");
  const [currentCity, setCurrentCity] = useState("");
  const [currentCountry, setCurrentCountry] = useState("");
  const [currentDepartment, setCurrentDepartment] = useState("");
  const [currentNeighborhood, setCurrentNeighborhood] = useState("");

  /* IMPLEMENTACION DE DSP -> Operador por defecto y Sucursal por defecto pasan a ser DEPRECADOS

  const [currentOperator, setCurrentOperator] = useState("");
  const [currentBranch, setCurrentBranch] = useState(""); */

  const [valueManualAddress, setValueManualAddress] = useState("");
  const [isCartaPorteChecked, setisCartaPorteChecked] = useState(false);
  const [search, setSearch] = useState("");
  const [newBranchMap, setNewBranchMap] = useState([]);

  const OperatorBranchWatcher = formWatch('operatorsBranch', []);

  const serviceTypeSelected = modalForm.watch("serviceType", "UM");

  /* IMPLEMENTACION DE DSP -> Operador por defecto y Sucursal por defecto pasan a ser DEPRECADOS

  const idDefaultOperator = useRef({});
  idDefaultOperator.current = modalForm.watch("idDefaultOperator", "");

  const branch = useRef({});
  branch.current = modalForm.watch("idBranch", "");*/

  const companyType = useRef({});
  companyType.current = modalForm.watch("companyType", "");

  const indicativeRoad = useRef({});
  indicativeRoad.current = modalForm.watch("indicativeRoad", "");

  const roadNumber = useRef({});
  roadNumber.current = modalForm.watch("roadNumber", "");

  const appendixRoad = useRef({});
  appendixRoad.current = modalForm.watch("appendixRoad", "");

  const crossoverRoad = useRef({});
  crossoverRoad.current = modalForm.watch("crossoverRoad", "");

  const crossoverNumber = useRef({});
  crossoverNumber.current = modalForm.watch("crossoverNumber", "");

  const appendixCrossingNumber = useRef({});
  appendixCrossingNumber.current = modalForm.watch("appendixCrossingNumber", "");

  const orientationCrossingNumber = useRef({});
  orientationCrossingNumber.current = modalForm.watch("orientationCrossingNumber", "");

  const insideTypes = useRef({});
  insideTypes.current = modalForm.watch("insideTypes", "");

  const doorNumber = useRef({});
  doorNumber.current = modalForm.watch("doorNumber", "");

  const inside = useRef({});
  inside.current = modalForm.watch("inside", "");

  const city = useRef({});
  city.current = modalForm.watch("city", "");

  const neighborhood = useRef({});
  neighborhood.current = modalForm.watch("neighborhood", "");

  const country = useRef({});
  country.current = modalForm.watch("country", "");

  const department = useRef({});
  department.current = modalForm.watch("department", "");

  const servicesTypes = [
    {name: 'Ultima Milla', value: 'UM', checked: !serviceTypeSelected || serviceTypeSelected === 'UM' },
    {name: 'Fulfillment', value: 'FM', checked: serviceTypeSelected === 'FM' },
    {name: 'Cross Docking', value: 'CD', checked: serviceTypeSelected === 'CD' },
  ];

  useEffect(() => {
    setFormValue('operatorsBranch', newBranchMap);
  }, [newBranchMap])

  useEffect(() => {
    reduxGetCompanies({
      page: 1,
      offset: offset,
      search: "",
    });
  }, []);

  useEffect(() => {
    if (companyType.current !== '' && companyType.current !== undefined && companyType.current !== null) {
      const selected = CompanyTypes && Object.keys(CompanyTypes).length > 0 && CompanyTypes.find((e) => e.value === companyType.current)
      setCurrentCompany({ label: selected.label, value: selected.value })
      if(selected.value === CompanyTypesEnum.DSP){
        modalForm.setValue("systemKey", CompanyTypesEnum.DSP)
      } else {
        if(!rowEdited){
          modalForm.setValue("systemKey", null)
        }else{
          modalForm.setValue("systemKey", rowEdited.systemKey)
        }
      }
    } else {
      setCurrentCompany({ label: 'Seleccionar', value: '' })
    }
  }, [companyType.current])

  /* IMPLEMENTACION DE DSP -> Operador por defecto y Sucursal por defecto pasan a ser DEPRECADOS

  useEffect(() => {
    if (idDefaultOperator.current !== '' && idDefaultOperator.current !== undefined && idDefaultOperator.current !== null) {
      const selected = allCompanies && Object.keys(allCompanies).length > 0 && allCompanies.items.find((e) => e.idCompany === idDefaultOperator.current)
      setCurrentOperator({ label: selected.description, value: selected.idCompany })
    } else {
      setCurrentOperator({ label: 'Seleccionar', value: '' })
    }
  }, [idDefaultOperator.current])

  useEffect(() => {
    if (branch.current !== '' && branch.current !== undefined && branch.current !== null) {
      const selected = branches && Object.keys(branches).length > 0 && branches.items.find((e) => e.idBranch === branch.current)
      setCurrentBranch({ label: selected.branchName, value: selected.idBranch })
    } else {
      setCurrentBranch({ label: 'Seleccionar', value: '' })
    }S
  }, [branch.current])*/

  useEffect(() => {
    setCurrentCountry({ label: 'Seleccionar', value: '' })
    setCurrentDepartment({ label: 'Seleccionar', value: '' })
    setCurrentCity({ label: 'Seleccionar', value: '' })
    setCurrentNeighborhood({ label: 'Seleccionar', value: '' })

    if (country.current !== '' && country.current !== undefined && country.current !== null) {
      const selected = countries && Object.keys(countries).length > 0 && countries.items.find((e) => e.idCountry === Number(country.current))
      setCurrentCountry({ label: selected.description, value: selected.idCountry })

      reduxGetDepartmentsByCountry({
        idCountry: country.current
      })
    } else {
      setCurrentCountry({ label: 'Seleccionar', value: '' })
    }
  }, [country.current]);

  useEffect(() => {
    setCurrentDepartment({ label: 'Seleccionar', value: '' })
    setCurrentCity({ label: 'Seleccionar', value: '' })
    setCurrentNeighborhood({ label: 'Seleccionar', value: '' })

    if (department.current !== '' && department.current !== undefined && department.current !== null) {
      const selected = departmentsByCountry && Object.keys(departmentsByCountry).length > 0 && departmentsByCountry.find((e) => e.idDepartment === Number(department.current))
      setCurrentDepartment(selected ? { label: selected.description, value: selected.idDepartment } : { label: 'Seleccionar', value: '' })

      selected && reduxGetCitiesByDepartment({
        idDepartment: department.current
      })
    } else {
      setCurrentDepartment({ label: 'Seleccionar', value: '' })
    }
  }, [department.current, departmentsByCountry]);

  useEffect(() => {
    setCurrentCity({ label: 'Seleccionar', value: '' })
    setCurrentNeighborhood({ label: 'Seleccionar', value: '' })

    if (city.current !== '' && city.current !== undefined && city.current !== null) {
      const selected = citiesByDepartment && Object.keys(citiesByDepartment).length > 0 && citiesByDepartment.find((e) => e.idCity === Number(city.current))
      setCurrentCity(selected ? { label: selected.description, value: selected.idCity } : { label: 'Seleccionar', value: '' })

      selected && reduxGetNeighborhoodsByCity({
        idCity: city.current
      })
    } else {
      setCurrentCity({ label: 'Seleccionar', value: '' })
    }
  }, [city.current, citiesByDepartment]);

  useEffect(() => {
    setCurrentNeighborhood({ label: 'Seleccionar', value: '' })

    if (neighborhood.current !== '' && neighborhood.current !== undefined && neighborhood.current !== null) {
      const selected = neighborhoodsByCity && Object.keys(neighborhoodsByCity).length > 0 && neighborhoodsByCity.find((e) => e.idNeighborhood === Number(neighborhood.current))
      setCurrentNeighborhood(selected ? { label: selected.description, value: selected.idNeighborhood }: { label: 'Seleccionar', value: '' })
    } else {
      setCurrentNeighborhood({ label: 'Seleccionar', value: '' })
    }
  }, [neighborhood.current, neighborhoodsByCity]);

  useEffect(() => {
    if (rowEdited) {
      setValueManualAddress(rowEdited.address)

      const branchesUpdated = rowEdited.operatorBranch.map(element => ({
        idBranch: element.idBranch,
        branch: { label: element?.branch.branchName, value: element.idBranch },
        account: element.account,
        requestDelete: true,
        idOperatorBranch: element.idOperatorBranch
      }));
  
      setNewBranchMap([...newBranchMap, ...branchesUpdated])

      modalForm.setValue("companyType", rowEdited.companyType);
      modalForm.setValue("serviceType", rowEdited.serviceType);
      modalForm.setValue("businessName", rowEdited.businessName);
      modalForm.setValue("email", rowEdited.email);
      modalForm.setValue("postalCode", rowEdited.postalCode);
      modalForm.setValue("description", rowEdited.description);
      modalForm.setValue("lat", rowEdited.lat);
      modalForm.setValue("lon", rowEdited.lon);
      modalForm.setValue("gcp_pwd", rowEdited.gcp_pwd);
      modalForm.setValue("apiUrlGuia", rowEdited.apiUrlGuia);
      modalForm.setValue("apiUrlPDFGuia", rowEdited.apiUrlPDFGuia);
      /* IMPLEMENTACION DE DSP -> Operador por defecto y Sucursal por defecto pasan a ser DEPRECADOS
      modalForm.setValue("idDefaultOperator", rowEdited.idDefaultOperator);
      modalForm.setValue("idBranch", rowEdited.idBranch);*/
      modalForm.setValue("nit", rowEdited.nit);
      modalForm.setValue("phone", rowEdited.phone);
      modalForm.setValue("zipCode", rowEdited.zipCode);
      modalForm.setValue("currentAddress", rowEdited.address);
      modalForm.setValue("systemKey", rowEdited.systemKey);
      modalForm.setValue("genericName", rowEdited.genericName);;
      modalForm.setValue("prefix", rowEdited.prefix)

      modalForm.setValue("country", rowEdited.country);
      reduxGetDepartmentsByCountry({
        idCountry: country.current
      })

      modalForm.setValue("department", rowEdited.department);
      reduxGetCitiesByDepartment({
        idDepartment: department.current
      })

      modalForm.setValue("city", rowEdited.city);

      modalForm.setValue("neighborhood", rowEdited.neighborhood);

      if (rowEdited.boxedAddress) {
        modalForm.setValue("indicativeRoad", rowEdited.boxedAddress.indicativeRoad);
        modalForm.setValue("roadNumber", rowEdited.boxedAddress.roadNumber);
        modalForm.setValue("appendixRoad", rowEdited.boxedAddress.appendixRoad);
        modalForm.setValue("crossoverRoad", rowEdited.boxedAddress.crossoverRoad);
        modalForm.setValue("crossoverNumber", rowEdited.boxedAddress.crossoverNumber);
        modalForm.setValue(
          "appendixCrossingNumber",
          rowEdited.boxedAddress.appendixCrossingNumber
        );
        modalForm.setValue(
          "orientationCrossingNumber",
          rowEdited.boxedAddress.orientationCrossingNumber
        );
        modalForm.setValue("doorNumber", rowEdited.boxedAddress.doorNumber);
        modalForm.setValue("insideTypes", rowEdited.boxedAddress.insideTypes);
        modalForm.setValue("inside", rowEdited.boxedAddress.inside);
        modalForm.setValue("country", rowEdited.country);
        modalForm.setValue("department", rowEdited.department);
        modalForm.setValue("city", rowEdited.city);
        modalForm.setValue("neighborhood", rowEdited.neighborhood);
      }
      if (rowEdited.cartaPorte) {
      modalForm.setValue("weightUnitDescription",rowEdited.cartaPorte.weightUnitDescription);
      modalForm.setValue("weightUnitCode",rowEdited.cartaPorte.weightUnitCode);
      modalForm.setValue("productServiceDescription",rowEdited.cartaPorte.productServiceDescription);
      modalForm.setValue("productServiceCode",rowEdited.cartaPorte.productServiceCode);
      setisCartaPorteChecked(true)
      }else{
        modalForm.setValue("weightUnitDescription","");
        modalForm.setValue("weightUnitCode","");
        modalForm.setValue("productServiceDescription","");
        modalForm.setValue("productServiceCode","");
      }
    }
  }, [rowEdited]);

  useEffect(() => {
    reduxGetCompanies({
      page: currentPage,
      offset: offset,
      search: search,
    });
  }, [currentPage]);

  useEffect(()=>{
    if (!errorsUpdateCompany){
      modalForm.reset();
      reduxResetCompanyForm();
      setShow(false);
      setValueManualAddress("");
      setNewBranchMap([])
      resetForm()
    }
  }, [errorsUpdateCompany])

  useEffect(()=>{
    if (!errorsCreateCompany){
      modalForm.reset();
      reduxResetCompanyForm();
      setShow(false);
      setValueManualAddress("");
      setNewBranchMap([])
      resetForm()
    }
  }, [errorsCreateCompany])

  useEffect(() => {
    if (company || rowUpdated || rowDeleted) {
      reduxGetCompanies({
        page: currentPage,
        offset: offset,
        search: search,
      });
      reduxResetCompanyForm();
    }
  }, [company, rowUpdated, rowDeleted]);

  useEffect(() => {
    modalForm.setValue(
      "address",
      `${indicativeRoad.current} ${roadNumber.current}${appendixRoad.current} ${
        crossoverRoad.current
      } ${crossoverNumber.current ? "#" : ""}${crossoverNumber.current}${
        appendixCrossingNumber.current
      }${orientationCrossingNumber.current ? " " : ""}${
        orientationCrossingNumber.current
      }${doorNumber.current ? "-" : ""}${doorNumber.current}${
        insideTypes.current ? " " + insideTypes.current + " " : ""
      }${inside.current}${neighborhood.current ? "," : ""} ${
        neighborhood.current ? currentNeighborhood : ""
      }${city.current ? ", " + currentCity : ""}${
        department.current ? ", " + currentDepartment : ""
      }${country.current ? ", " + currentCountry : ""}`
    );
  }, [
    indicativeRoad.current,
    roadNumber.current,
    appendixRoad.current,
    crossoverRoad.current,
    crossoverNumber.current,
    appendixCrossingNumber.current,
    orientationCrossingNumber.current,
    doorNumber.current,
    insideTypes.current,
    neighborhood.current,
    city.current,
    department.current,
    country.current,
    currentCity,
    currentDepartment,
    currentCountry,
    currentNeighborhood,
  ]);

  useEffect(() => {
    if (company || rowUpdated || rowDeleted) {
      if (
          (company && company.message === undefined) ||
          (rowUpdated && rowUpdated.message === undefined) ||
          rowDeleted
      ) {
        reduxGetCompanies({
          page: currentPage,
          offset: offset,
          search: search,
        });
        reduxResetCompanyForm();
        setShow(false);
        setValueManualAddress("");
        setNewBranchMap([])
        resetForm()
      }
    }
  }, [company, rowUpdated, rowDeleted]);

  useEffect(() => {
    if(companyType.current !== CompanyTypesEnum.SHIPPER){
      setisCartaPorteChecked(false)
    }
  },[currentCompany.current])

  const autocompleteAddresses = (data) => {
    if(data && data.address_components){
      const address = data.address_components.map((ad) => ad.long_name).join(", ");
      setValueManualAddress(address);
      const countryAddress = (data.address_components.find((ad) => ad.types[0] === "country"))?.long_name.toUpperCase();
      data.address_components
          ? modalForm.setValue("manual", data.address_components)
          : modalForm.setValue("manual", data);
      modalForm.setValue("country", null);
      modalForm.setValue("department", "");
      modalForm.setValue("city", "");
      reduxSetDepartment();
      reduxSetCity();
      if(countries && countries.items){
        const currentCountry = countries.items.find(country => country.description === countryAddress);
        if(currentCountry)
          modalForm.setValue("country", currentCountry.idCountry);
      }
    }
  }

  const handleClose = () => {
    reduxResetCompanyForm();
    modalForm.reset();
    setShow(false);
    setValueManualAddress("");
    setNewBranchMap([])
    resetForm()
  };

  const handleOpenModal = (row) => {
    reduxGetAllCompanies({
      page: 1,
      offset: 100000,
      search: "",
      isActive: true,
    });
    reduxGetCountries({
      page: 1,
      offset: 1000,
      search: "",
      isActive: true,
    });
    reduxGetBranches({
      page: 1,
      offset: 1000,
      search: "",
      isActive: true,
    });
    if (!row || Object.keys(row).length === 0) {
      reduxResetCompanyForm();
      modalForm.reset();
    } else {
      reduxGetCompany({
        id: row.idCompany,
      });  
    }

    setShow(true);
  };

  const handleActive = (row) => {
    if (!row.isActive) {
      reduxDeleteCompany(row);
    }
  };

  const handleCheckCartaPorte = useCallback((event) => {
    setisCartaPorteChecked(event.currentTarget.checked)
  },[isCartaPorteChecked])

  const onSubmit = (data) => {
    const cartaPorte = isCartaPorteChecked
    ? {
        productServiceCode: data.productServiceCode,
        productServiceDescription: data.productServiceDescription,
        weightUnitCode: data.weightUnitCode,
        weightUnitDescription: data.weightUnitDescription,
      }
    : null;

    const companyDTO = {
      companyType: data.companyType,
      serviceType: data.companyType !== CompanyTypesEnum.SHIPPER ? null : data.serviceType,
      description: data.description,
      businessName: data.businessName,
      nit: data.nit,
      phone: data.phone,
      email: data.email,
      postalCode: data.postalCode,
      address: data.currentAddress,
      country: data.country,
      department: data.department,
      city: data.city,
      neighborhood: data.neighborhood,
      /* IMPLEMENTACION DE DSP -> Operador por defecto y Sucursal por defecto pasan a ser DEPRECADOS
      idDefaultOperator: data.idDefaultOperator && data.idDefaultOperator !== ''?  data.idDefaultOperator : null,
      idBranch: data.idBranch && data.idBranch !== ''? data.idBranch : null,*/
      systemKey: data.companyType === CompanyTypesEnum.DSP ? CompanyTypesEnum.DSP : data.systemKey,
      cartaPorte: cartaPorte,
      genericName: data.genericName,
      operatorBranches: OperatorBranchWatcher.map((branch) => {
        return {
          account: branch.account,
          idBranch: branch.idBranch,
        };
      }),
      prefix: data.prefix,
    };

    if (rowEdited)
      reduxPatchCompany({ ...companyDTO, id: rowEdited.idCompany });
    else
      reduxPostCompany({ ...companyDTO });
  };

  const handleDelete = (row) => {
    reduxDeleteCompany(row);
  };

  const columns = [
    {
      title: "#",
      render: (rowData) => {
        return <span>{rowData.idCompany}</span>;
      },
      field: "idCompany",
      searchable: true,
    },
    {
      title: "Descripción",
      render: (rowData) => {
        return <span>{rowData.description}</span>;
      },
      field: "description",
      searchable: true,
    },
    {
      title: "Dirección",
      render: (rowData) => {
        return <span>{rowData.address}</span>;
      },
      field: "address",
      searchable: true,
    },
    {
      title: "Opciones",
      render: (rowData) => {
        return (
          <>
            {rowData.isActive ? (
              <>
                <Show when="feature:edit-company">
                  <button
                    title="Editar"
                    className="btn btn-primary btn-sm  btn-circle mr-2"
                    type="button"
                    onClick={e => handleOpenModal(rowData)}
                  >
                    <i className="fas fa-edit fa-xs"></i>
                  </button>
                </Show>
                <Show when="feature:disabled-company">
                  <button
                    title="Desactivar"
                    className="btn btn-danger btn-sm btn-circle"
                    type="button"
                    onClick={(e) => handleDelete(rowData)}
                  >
                    <i className="fas fa-times-circle fa-xs"></i>
                  </button>
                </Show>
              </>
            ) : (
              <Show when="feature:enabled-company">
                <button
                  title="Activar"
                  className="btn btn-primary btn-sm  btn-circle mr-2"
                  type="button"
                  onClick={(e) => handleActive(rowData)}
                >
                  <i className="fas fa-check-circle fa-xs"></i>
                </button>
              </Show>
            )}
          </>
        );
      },
    },
  ];

  const branchFormIsValid = () => {
    formClearErrors();
    var isValid = true;
    const data = getFormValues();

    if (isNullOrUndefined(data.branch)) {
      formSetError("branch", {
        type: "manual",
        message: "El HUB es requerido",
      })
      isValid = false;
    }

    if (isNullOrUndefined(data.account)) {
      formSetError("account", {
        type: "manual",
        message: "La cuenta es requerida",
      })
      isValid = false;
    }

    return isValid;
  }

  const addBranch = () => {
    if (!branchFormIsValid()) return;
    const data = getFormValues();

    const newBranch = {
      idBranch: data.branch.value,
      branch: data.branch,
      account: data.account,
      requestDelete: false
    };

    const isDuplicate = OperatorBranchWatcher.some(newBranchExist => {
      return (
        newBranchExist.idBranch === newBranch.idBranch
      );
    });

    if (isDuplicate) {
      Swal.fire('No se puede agregar el HUB porque ya se asignó.');
      return;
    }

    setNewBranchMap([...newBranchMap, newBranch]);

    setFormValue('branch', null);
    setFormValue('account', null);
  };

  const handleRemoveOperator = (element) => {
    Swal.fire({
      html: `¿Está seguro de eliminar este HUB?`,
      icon: 'question',
      showConfirmButton: true,
      confirmButtonText: "SI",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
    }).then(result => {
      if(result.isConfirmed){
        const newOperatorsArray = newBranchMap.filter((item) => item.idBranch !== element.idBranch);
        setNewBranchMap(newOperatorsArray)
        if(element.requestDelete){
          reduxDeleteOperatorBranch({
            idOperatorBranch: element.idOperatorBranch,
          });
        }
      }
    })
  }

  return (
    <div>
      <div id="wrapper">
        <Sidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Topbar />
            <div className="container-fluid">
              <div className="d-sm-flex align-items-center mb-1">
                <h1 className="h3 mb-0 text-gray-800 mr-3">Empresas</h1>
                <Show when="feature:create-company">
                  <button
                    className="btn btn-primary btn-circle"
                    type="button"
                    onClick={e => handleOpenModal()}
                  >
                    <i className="fas fa-plus fa-sm"></i>
                  </button>
                </Show>
              </div>
              <p className="mb-4">Módulo de Administración de empresas</p>
              <div className="card shadow mb-4">
                <div className="card-header py-3">
                  <h6 className="m-0 font-weight-bold text-primary">
                    Listado de Empresas
                  </h6>
                </div>
                <div className="card-body">
                  <Grid
                    cols={columns}
                    data={
                      companies && Object.keys(companies).length > 0
                        ? companies.items
                        : []
                    }
                    page={
                      companies && Object.keys(companies).length > 0
                        ? Number(companies.page)
                        : currentPage
                    }
                    pages={
                      companies && Object.keys(companies).length > 0
                        ? Number(companies.totalPages)
                        : 0
                    }
                    total={
                      companies && companies.hasOwnProperty("total")
                        ? companies.total
                        : 0
                    }
                    offset={offset}
                    onChangePage={(page) => setCurrentPage(page)}
                    onChangeRange={(value) => {
                      setOffset(value);
                      reduxGetCompanies({
                        page: 1,
                        offset: value,
                        search: search,
                      });
                    }}
                    defaultValue={search}
                    onChangeSearch={(value) => {
                      setSearch(value);
                      reduxGetCompanies({
                        page: 1,
                        offset: offset,
                        search: value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Modal
          size="xl"
          show={show}
          onHide={handleClose}
          onEscapeKeyDown={handleClose}
          backdrop="static"
          keyboard={true}
        >
          <FormProvider {...modalForm}>
            <Modal.Header closeButton>
              <Modal.Title>
                {rowEdited ? "Editar" : "Nueva"} empresa
              </Modal.Title>
            </Modal.Header>
            <Form style={{ padding: '20px' }} onSubmit={modalForm.handleSubmit(onSubmit)}>
              <Form.Group as={Row}>
                <Col md={4}>
                  <Form.Label>
                    Tipo de empresa *
                  </Form.Label>
                  <Controller
                    control={modalForm.control}
                    name="companyType"
                    rules={{ required: 'Seleccione el tipo de empresa' }}
                    render={({ field }) => (
                      <>
                        <Select
                          {...field}
                          isClearable
                          value={currentCompany}
                          onChange={(e) => modalForm.setValue('companyType', e?.value || '')}
                          options={
                            CompanyTypes && CompanyTypes.length > 0
                              ? CompanyTypes : []
                          }
                        />
                        {
                          modalForm.formState.errors.companyType &&
                          (
                            <span className="error-message">
                              {modalForm.formState.errors.companyType.message}
                            </span>
                          )
                        }
                      </>
                    )}
                  />
                </Col>
                {
                  companyType.current === CompanyTypesEnum.SHIPPER &&
                  (
                    <>
                      <Col md={3}>
                        <Form.Label column sm={12}>
                          { }
                        </Form.Label>
                        <Form.Check // prettier-ignore
                          type="switch"
                          id="custom-switch"
                          label="Aplica carta porte"
                          checked={isCartaPorteChecked}
                          onChange={handleCheckCartaPorte}
                        />
                      </Col>
                      <Col md={4}>
                        <Form.Label column sm={12}>
                          Tipo de Servicio
                        </Form.Label>
                        <Controller
                          control={modalForm.control}
                          name="serviceType"
                          render={({ field }) => (
                              <RadioButton                              
                              options={servicesTypes} 
                              fieldController={field}
                              />                            
                          )}
                        />
                      </Col>
                    </>
                  )
                }
              </Form.Group>
              <Form.Group as={Row}>
                <Col md={4}>
                  <Form.Label>
                    Razón social *
                  </Form.Label>
                  <Controller
                    control={modalForm.control}
                    name="description"
                    rules={{ required: 'La razón social es requerida' }}
                    render={({ field }) => (
                      <>
                        <Form.Control
                          className={`form-control ${modalForm.formState.errors.description && "is-invalid"}`}
                          {...field}
                        />
                        {
                          modalForm.formState.errors.description &&
                          (
                            <span className="error-message">
                              {modalForm.formState.errors.description.message}
                            </span>
                          )
                        }
                      </>
                    )}
                  />
                </Col>
                <Col md={4}>
                  <Form.Label>
                    Nombre comercial *
                  </Form.Label>
                  <Controller
                    control={modalForm.control}
                    name="businessName"
                    rules={{ required: 'El nombre comercial es requerido' }}
                    render={({ field }) => (
                      <>
                        <Form.Control
                          className={`form-control ${modalForm.formState.errors.businessName && "is-invalid"}`}
                          {...field}
                        />
                        {
                          modalForm.formState.errors.businessName &&
                          (
                            <span className="error-message">
                              {modalForm.formState.errors.businessName.message}
                            </span>
                          )
                        }
                      </>
                    )}
                  />
                </Col>
                <Col sm={4}>
                  <Form.Label>
                    Documento empresa *
                  </Form.Label>
                  <Controller
                    control={modalForm.control}
                    name="nit"
                    rules={{ required: 'El documento es requerido y debe ser único por empresa' }}
                    render={({ field }) => (
                      <>
                        <Form.Control
                          className={`form-control ${modalForm.formState.errors.nit && "is-invalid"}`}
                          {...field}
                        />
                        {
                          modalForm.formState.errors.nit &&
                          (
                            <span className="error-message">
                              {modalForm.formState.errors.nit.message}
                            </span>
                          )
                        }
                      </>
                    )}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Col md={4}>
                  <Form.Label>
                    Teléfono *
                  </Form.Label>
                  <Controller
                    control={modalForm.control}
                    name="phone"
                    rules={
                      {
                        required: 'El teléfono es requerido',
                        pattern: {
                          value: /^(\+){0,1}\d*$/,
                          message: 'Digite un teléfono válido'
                        }
                      }
                    }
                    render={({ field }) => (
                      <>
                        <Form.Control
                          className={`form-control ${modalForm.formState.errors.phone && "is-invalid"}`}
                          type="number"
                          {...field}
                        />
                        {
                          modalForm.formState.errors.phone &&
                          (
                            <span className="error-message">
                              {modalForm.formState.errors.phone.message}
                            </span>
                          )
                        }
                      </>
                    )}
                  />
                </Col>
                <Col md={4}>
                  <Form.Label>
                    Correo *
                  </Form.Label>
                  <Controller
                    control={modalForm.control}
                    name="email"
                    rules={
                      {
                        required: 'El correo es requerido',
                        pattern: {
                          value: /\S+@\S+\.\S+/,
                          message: 'Digite un correo electrónico válido'
                        }
                      }
                    }
                    render={({ field }) => (
                      <>
                        <Form.Control
                          className={`form-control ${modalForm.formState.errors.email && "is-invalid"}`}
                          {...field}
                        />
                        {
                          modalForm.formState.errors.email &&
                          (
                            <span className="error-message">
                              {modalForm.formState.errors.email.message}
                            </span>
                          )
                        }
                      </>
                    )}
                  />
                </Col>
                <Col sm={4}>
                  <Form.Label>
                    Código postal *
                  </Form.Label>
                  <Controller
                    control={modalForm.control}
                    name="postalCode"
                    rules={
                      {
                        required: 'El código postal es requerido',
                        pattern: {
                          value: /^\d*$/,
                          message: 'Digite un código postal válido'
                        }
                      }
                    }
                    render={({ field }) => (
                      <>
                        <Form.Control
                          className={`form-control ${modalForm.formState.errors.postalCode && "is-invalid"}`}
                          {...field}
                          type="number"
                        />
                        {
                          modalForm.formState.errors.postalCode &&
                          (
                            <span className="error-message">
                              {modalForm.formState.errors.postalCode.message}
                            </span>
                          )
                        }
                      </>
                    )}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} style={{ padding: '30px' }}>
                <Col sm={12}>
                  <GoogleMapsAutocomplete
                    id={"currentAddress"}
                    required={true}
                    value={valueManualAddress}
                    onChange={autocompleteAddresses}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Col md={3}>
                  <Form.Label>
                    País *
                  </Form.Label>
                  <Controller
                    control={modalForm.control}
                    name="country"
                    rules={
                      {
                        required: 'El país es requerido',
                      }
                    }
                    render={({ field }) => (
                      <>
                        <Select
                          isClearable
                          value={currentCountry}
                          onChange={(value) => modalForm.setValue('country', value?.value || '')}
                          options={
                            countries && Object.keys(countries).length > 0
                              ? countries.items
                                .filter((f) => f.isActive)
                                .map((ele, key) => ({
                                  value: ele.idCountry,
                                  label: ele.description,
                                }))
                              : []
                          }
                        />
                        {
                          modalForm.formState.errors.country &&
                          (
                            <span className="error-message">
                              {modalForm.formState.errors.country.message}
                            </span>
                          )
                        }
                      </>
                    )}
                  />
                </Col>
                <Col md={3}>
                  <Form.Label>
                    Departamento *
                  </Form.Label>
                  <Controller
                    control={modalForm.control}
                    name="department"
                    rules={
                      {
                        required: 'El departamento y/o provincia es requerido',
                      }
                    }
                    render={({ field }) => (
                      <>
                        <Select
                          isClearable
                          value={currentDepartment}
                          onChange={(value) => modalForm.setValue('department', value?.value || '')}
                          options={
                            departmentsByCountry && Object.keys(departmentsByCountry).length > 0
                              ? departmentsByCountry
                                .filter((f) => f.isActive)
                                .map((ele, key) => ({
                                  value: ele.idDepartment,
                                  label: ele.description,
                                }))
                              : []
                          }
                        />
                        {
                          modalForm.formState.errors.department &&
                          (
                            <span className="error-message">
                              {modalForm.formState.errors.department.message}
                            </span>
                          )
                        }
                      </>
                    )}
                  />
                </Col>
                <Col sm={3}>
                  <Form.Label>
                    Ciudad *
                  </Form.Label>
                  <Controller
                    control={modalForm.control}
                    name="city"
                    rules={
                      {
                        required: 'La ciudad es requerida',
                      }
                    }
                    render={({ field }) => (
                      <>
                        <Select
                          isClearable
                          value={currentCity}
                          onChange={(value) => modalForm.setValue('city', value?.value || '')}
                          options={
                            citiesByDepartment && Object.keys(citiesByDepartment).length > 0
                              ? citiesByDepartment
                                .filter((f) => f.isActive)
                                .map((ele, key) => ({
                                  value: ele.idCity,
                                  label: ele.description,
                                }))
                              : []
                          }
                        />
                        {
                          modalForm.formState.errors.city &&
                          (
                            <span className="error-message">
                              {modalForm.formState.errors.city.message}
                            </span>
                          )
                        }
                      </>
                    )}
                  />
                </Col>
                <Col sm={3}>
                  <Form.Label>
                    Barrio
                  </Form.Label>
                  <Controller
                    control={modalForm.control}
                    name="neighborhood"
                    render={({ field }) => (
                      <>
                        <Select
                          isClearable
                          value={currentNeighborhood}
                          onChange={(value) => modalForm.setValue('neighborhood', value?.value || '')}
                          options={
                            neighborhoodsByCity && Object.keys(neighborhoodsByCity).length > 0
                              ? neighborhoodsByCity
                                .filter((f) => f.isActive)
                                .map((ele, key) => ({
                                  value: ele.idNeighborhood,
                                  label: ele.description,
                                }))
                              : []
                          }
                        />
                      </>
                    )}
                  />
                </Col>
              </Form.Group>

              { /* IMPLEMENTACION DE DSP -> Operador por defecto y Sucursal por defecto pasan a ser DEPRECADOS

              <Form.Group as={Row}>
                <Col md={6}>
                  <Form.Label>
                    Operador por defecto
                  </Form.Label>
                  {
                    allCompanies.items && allCompanies.items.length > 0 ?
                      (
                        <>
                          <Controller
                            control={modalForm.control}
                            name="idDefaultOperator"
                            render={({ field }) => (
                              <>
                                <Select
                                  isClearable
                                  value={currentOperator}
                                  onChange={(value) => modalForm.setValue('idDefaultOperator', value?.value || '')}
                                  options={
                                    allCompanies && Object.keys(allCompanies).length > 0
                                      ? allCompanies.items
                                        .filter((f) => f.isActive)
                                        .map((ele, key) => ({
                                          value: ele.idCompany,
                                          label: ele.description,
                                        }))
                                      : []
                                  }
                                />
                              </>
                            )}
                          />
                        </>
                      ) : (<p>Cargando datos...</p>)
                  }
                </Col>
                <Col md={6}>
                  <Form.Label>
                    Sucursal por defecto
                  </Form.Label>
                  <Controller
                    control={modalForm.control}
                    name="idBranch"
                    render={({ field }) => (
                      <>
                        <Select
                          isClearable
                          isDisabled={!idDefaultOperator.current}
                          value={currentBranch}
                          onChange={(value) => modalForm.setValue('idBranch', value?.value || '')}
                          options={
                            branches && Object.keys(branches).length > 0
                              ? branches.items
                                .filter((f) => f.isActive)
                                .map((ele, key) => ({
                                  value: ele.idBranch,
                                  label: ele.branchName,
                                }))
                              : []
                          }
                        />
                      </>
                    )}
                  />
                </Col>
              </Form.Group>
               */ }

              <Row>
                <Col sm={4}>
                  <Form.Group as={Row}>
                    <Form.Label column sm={6}>
                      Llave del sistema
                    </Form.Label>
                    <Col sm={12}>
                      <Controller
                        control={modalForm.control}
                        name="systemKey"
                        render={({ field }) => (
                          <>
                            <Form.Control
                            {...field}
                            disabled={companyType.current === CompanyTypesEnum.DSP}
                            />
                          </>
                        )}
                      />
                    </Col>
                  </Form.Group>
                </Col>
                {companyType.current === CompanyTypesEnum.DSP && (
                  <>
                    <Col sm={4}>
                      <Form.Group as={Row}>
                        <Form.Label column sm={6}>
                          Prefijo *
                        </Form.Label>
                        <Col sm={12}>
                        <Controller
                            control={modalForm.control}
                            name="prefix"
                            rules={{
                              minLength: {
                                value: 6,
                                message: "El prefijo debe tener exactamente 6 caracteres",
                              },
                              maxLength: {
                                value: 6,
                                message: "El prefijo debe tener exactamente 6 caracteres",
                              },
                              pattern: {
                                value: /^[A-Z0-9]{6}$/,
                                message: "El prefijo no debe incluir caracteres especiales",
                              },
                            }}
                            render={({ field, fieldState: { error } }) => (
                              <>
                                <Form.Control {...field}
                                value={field.value?.toUpperCase()}
                                placeholder="Prefijo"
                                onChange={(e) => field.onChange(e.target.value.toUpperCase())}
                                required={companyType.current === CompanyTypesEnum.DSP}
                                disabled={rowEdited} />
                              </>
                            )}
                          />
                          {
                            modalForm.formState.errors.prefix &&
                            (
                              <span className="error-message">
                                {modalForm.formState.errors.prefix.message}
                              </span>
                            )
                          }
                        </Col>
                      </Form.Group>
                    </Col>
                  </>
                )}
                <Col sm={4}>
                  <Form.Group as={Row}>
                    <Form.Label column sm={6}>
                      Nombre Genérico
                    </Form.Label>
                    <Col sm={12}>
                      <Controller
                        control={modalForm.control}
                        name="genericName"
                        render={({ field }) => (
                          <>
                            <Form.Control {...field} />
                          </>
                        )}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Form.Group as={Row}>
                {
                  isCartaPorteChecked && companyType.current === CompanyTypesEnum.SHIPPER &&
                  (
                    <>
                      <Col sm={3}>
                        <Form.Label>
                          Código producto/servicio *
                        </Form.Label>
                        <Controller
                          control={modalForm.control}
                          name="productServiceCode"
                          rules={{ required: 'Código producto/servicio requerido' }}
                          render={({ field }) => (
                            <>
                              <Form.Control
                                {...field}
                                className="form-control"
                                placeholder="Código producto / servicio"
                                maxLength={100}
                              />
                              {
                                modalForm.formState.errors.productServiceCode &&
                                (
                                  <span className="error-message">
                                    {modalForm.formState.errors.productServiceCode.message}
                                  </span>
                                )
                              }
                            </>
                          )}
                        />
                      </Col>
                      <Col sm={3}>
                        <Form.Label>
                          Descripción producto/servicio *
                        </Form.Label>
                        <Controller
                          control={modalForm.control}
                          name="productServiceDescription"
                          rules={{ required: 'Descripción producto/servicio requerido' }}
                          render={({ field }) => (
                            <>
                              <Form.Control
                                {...field}
                                className="form-control"
                                placeholder="Descripción producto / servicio"
                                maxLength={100}
                              />
                              {
                                modalForm.formState.errors.productServiceDescription &&
                                (
                                  <span className="error-message">
                                    {modalForm.formState.errors.productServiceDescription.message}
                                  </span>
                                )
                              }
                            </>
                          )}
                        />
                      </Col>
                      <Col sm={3}>
                        <Form.Label>
                          Código unidad peso *
                        </Form.Label>
                        <Controller
                          control={modalForm.control}
                          name="weightUnitCode"
                          rules={{ required: 'Código unidad peso es requerido' }}
                          render={({ field }) => (
                            <>
                              <Form.Control
                                {...field}
                                className="form-control"
                                placeholder="Código unidad peso"
                                maxLength={100}
                              />
                              {
                                modalForm.formState.errors.weightUnitCode &&
                                (
                                  <span className="error-message">
                                    {modalForm.formState.errors.weightUnitCode.message}
                                  </span>
                                )
                              }
                            </>
                          )}
                        />
                      </Col>
                      <Col sm={3}>
                        <Form.Label>
                          Descripción unidad peso *
                        </Form.Label>
                        <Controller
                          control={modalForm.control}
                          name="weightUnitDescription"
                          rules={{ required: 'Descripción unidad peso es requerido' }}
                          render={({ field }) => (
                            <>
                              <Form.Control
                                {...field}
                                className="form-control"
                                placeholder="Descripción unidad peso"
                                maxLength={100}
                              />
                              {
                                modalForm.formState.errors.weightUnitDescription &&
                                (
                                  <span className="error-message">
                                    {modalForm.formState.errors.weightUnitDescription.message}
                                  </span>
                                )
                              }
                            </>
                          )}
                        />
                      </Col>
                    </>
                  )}
              </Form.Group>
              <Form.Group as={Row} style={{ padding: '20px' }}>
                {
                  companyType.current === CompanyTypesEnum.LOGISTICS_OPERATOR &&
                  (
                    <>
                      <Table striped bordered className="mb-4">
                        <thead>
                          <tr>
                            <th className="text-bold text-center" colSpan="6">Asociar HUB</th>
                          </tr>
                          <tr>
                            <th className="col-3">HUB</th>
                            <th className="col-2">Cuenta</th>
                            <th className="col-1"></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <Controller
                                control={formControl}
                                name="branch"
                                render={({ field }) => (
                                  <>
                                    <Select
                                      {...field}
                                      isClearable
                                      options={
                                        branches && Object.keys(branches).length > 0
                                          ? branches.items
                                            .filter((f) => f.isActive && f.idCountry == currentCountry?.value)
                                            .map((ele, key) => ({
                                              value: ele.idBranch,
                                              label: ele.branchName,
                                            }))
                                          : []
                                      }
                                    />
                                    {
                                      formErrors.branch &&
                                      <span className="error-message">
                                        {formErrors.branch.message}
                                      </span>
                                    }
                                  </>
                                )}
                              />
                            </td>
                            <td>
                              <Controller
                                control={formControl}
                                name="account"
                                render={({ field }) => (
                                  <>
                                    <input
                                      {...field}
                                      className="form-control"
                                      type="text"
                                      name="account"
                                      placeholder="Numero de cuenta"
                                      maxLength={100}
                                    />
                                    {
                                      formErrors.account &&
                                      <span className="error-message">
                                        {formErrors.account.message}
                                      </span>
                                    }
                                  </>
                                )}
                              />
                            </td>
                            <td>
                              <button className='btn btn-primary' type="button" onClick={addBranch}>Agregar</button>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                      <Table bordered>
                        <thead>
                          <tr>
                            <th className="text-bold text-center" colSpan="6">HUBs</th>
                          </tr>
                          {
                            OperatorBranchWatcher.length > 0 && (
                              <tr>
                                <th className="col-5">HUB</th>
                                <th className="col-5">Cuenta</th>
                                <th className="col-2"></th>
                              </tr>
                            )
                          }
                        </thead>
                        <tbody>
                          <Controller
                            control={formControl}
                            name="operatorsBranch"
                            render={() => (
                              <>
                                {
                                  OperatorBranchWatcher.map((item, index) => (
                                    <tr>
                                      <td>{item.branch?.label}</td>
                                      <td>{item.account}</td>
                                      <td>
                                        {
                                          <>
                                            <button 
                                              title="Eliminar" 
                                              className='btn btn-danger btn-sm btn-circle' 
                                              type="button" 
                                              onClick={() => handleRemoveOperator(item)}>
                                              <i className="fas fa-trash fa-xs"></i>
                                            </button>
                                          </>
                                        }
                                      </td>
                                    </tr>
                                  ))}
                                {
                                  formErrors.operatorsBranch &&
                                  <tr>
                                    <td>
                                      <span className="error-message text-center">
                                      {formErrors.operatorsBranch.message}
                                    </span>
                                    </td>
                                  </tr>
                                }
                              </>
                            )}
                          />
                        </tbody>
                      </Table>
                    </>
                  )
                }
              </Form.Group>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Cancelar
                </Button>
                <Button variant="primary" type="submit">
                  Guardar
                </Button>
              </Modal.Footer>
            </Form>
          </FormProvider>
        </Modal>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    companies: state.companyState.companies,
    allCompanies: state.companyState.allCompanies,
    company: state.companyState.company,
    rowEdited: state.companyState.rowEdited,
    rowDeleted: state.companyState.rowDeleted,
    rowUpdated: state.companyState.rowUpdated,
    cities: state.cityState.cities,
    neighborhoodsByCity: state.neighborhoodState.neighborhoodsByCity,
    countries: state.countryState.countries,
    departmentsByCountry: state.departmentState.departmentsByCountry,
    citiesByDepartment: state.cityState.citiesByDepartment,
    branches: state.branchState.branches,
    errorsUpdateCompany: state.companyState.errorsUpdateCompany,
    errorsCreateCompany: state.companyState.errorsCreateCompany,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    reduxGetCompanies: (payload) =>
      dispatch({
        type: "FETCH_COMPANIES_REQUEST",
        value: payload,
      }),
    reduxPostCompany: (payload) =>
      dispatch({
        type: "CREATE_COMPANY_REQUEST",
        value: payload,
      }),
    reduxGetCompany: (payload) =>
      dispatch({
        type: "READ_COMPANY_REQUEST",
        value: payload,
      }),
    reduxDeleteCompany: (payload) =>
      dispatch({
        type: "DELETE_COMPANY_REQUEST",
        value: payload,
      }),
    reduxPatchCompany: (payload) =>
      dispatch({
        type: "UPDATE_COMPANY_REQUEST",
        value: payload,
      }),
    reduxResetCompanyForm: () =>
      dispatch({
        type: "RESET_COMPANY_FORM",
      }),
    reduxGetAllCompanies: (payload) =>
      dispatch({
        type: "FETCH_ALLCOMPANIES_REQUEST",
        value: payload,
      }),
    reduxGetCities: (payload) =>
      dispatch({
        type: "FETCH_CITIES_REQUEST",
        value: payload,
      }),
    reduxGetCountries: (payload) =>
      dispatch({
        type: "FETCH_COUNTRIES_REQUEST",
        value: payload,
      }),
    reduxGetDepartmentsByCountry: (payload) =>
      dispatch({
        type: "READBYCOUNTRY_DEPARTMENT_REQUEST",
        value: payload,
      }),
    reduxGetCitiesByDepartment: (payload) =>
      dispatch({
        type: "READBYDEPARTMENT_CITY_REQUEST",
        value: payload,
      }),
    reduxGetNeighborhoodsByCity: (payload) =>
      dispatch({
        type: "READBYCITY_NEIGHBORHOOD_REQUEST",
        value: payload,
      }),
    reduxSetDepartment: (payload) =>
      dispatch({
        type: "RESET_BYCOUNTRY_DEPARTMENT",
        value: payload,
      }),
    reduxSetCity: (payload) =>
      dispatch({
        type: "RESET_BYDEPARTMENT_CITY",
        value: payload,
      }),
    reduxSetNeighborhood: (payload) =>
      dispatch({
        type: "RESET_BYCITY_NEIGHBORHOOD",
        value: payload,
      }),
    reduxGetBranches: (payload) =>
      dispatch({
        type: "FETCH_BRANCHES_REQUEST",
        value: payload,
      }),
    reduxDeleteOperatorBranch: (payload) =>
      dispatch({
        type: "DELETE_OPERATOR_BRANCH_REQUEST",
        value: payload,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Company);
