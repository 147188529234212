import React, { useState, useRef, useEffect } from "react";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { connect } from "react-redux";
import { Table } from "react-bootstrap";
import Select from "react-select";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useLocation } from "react-router-dom";

import { isNullOrUndefined } from "../../utils/functionsUtils";
import { Show } from "../../hooks/Show";
import Sidebar from "../../components/Navigation/Sidebar";
import Topbar from "../../components/Navigation/Topbar";
import CreateOrigin from "../../components/Modal/Coverage"

const NewEdit = ({
  reduxGetOneCoverage,
  oneCoverage,
  reduxPostCoverage,
  reduxGetCoverage,
  reduxDeleteCoverage,
  reduxPatchCoverage,
  reduxResetCoverageForm,
  reduxGetNeighborhoodsByCity,
  neighborhoodsByCity,
  reduxGetOrigins,
  origins,
  reduxGetDepartmentsByCountry,
  departmentsByCountry,
  reduxGetCitiesByDepartment,
  citiesByDepartment,
  countries,
  reduxGetCountries,
  reduxSetDepartment,
  reduxSetCity,
  reduxGetCoverageType,
  coverageTypes,
  errorsUpdateCoverage,
  errorsCreateCoverage,
  reduxGetLogisticsOperators,
  logisticsOperators,
  successfulSaveOrigins,
  reduxUpdateCoverageOperator,
  successfulUpdateOperatorCoverage,
  errorsUpdateOperatorCoverage,
  successfulCreateCoverage,
  successfulUpdateCoverage,
  reduxAssignCoverageOperator,
  errorsAssignOperatorCoverage,
}) => {
  const {
    handleSubmit: handleFormSubmit,
    control: formControl,
    formState: { errors: formErrors },
    watch: formWatch,
    setValue: setFormValue,
    getValues: getFormValues,
    setError: formSetError,
    clearErrors: formClearErrors,
    reset: resetForm,
  } = useForm({
    defaultValues: {
      country: null,
      origin: null,
      department: null,
      city: null,
      neighborhood: null,
      coverageType: null,
      zone: null,
      logisticsOperator: null,
      zoneOp: null,
      days: null,
      effectiveRate: null,
      ineffectiveRate: null,
      coverageOperators: [],
    },
    shouldUnregister: true,
  });

  const selectStyles = {
    menu: (base) => ({ ...base, zIndex: 9999 }),
    valueContainer: (baseStyles) => ({ ...baseStyles, maxHeight: "3.5vw", overflowY: "auto" }),
    control: (baseStyles) => ({ ...baseStyles, flexWrap: "nowrap" }) // Ajusta el valor según tus necesidades
  }

  const history = useHistory();
  const location = useLocation();
  const coverageData = location.state ? location.state.coverageData : null;

  const [showNewOriginButton, setShowNewOriginButton] = useState(false);
  const [originInfo, setOriginInfo] = useState();
  const [showCreateOriginModal, setShowCreateOriginModal] = useState();
  const [newCoverageOperatorsMap, setNewCoverageOperatorsMap] = useState([]);
  const [coverageOperatorsMap, setCoverageOperatorsMap] = useState([]);

  const countryWatcher = formWatch('country', null);
  const departmentWatcher = formWatch('department', null);
  const cityWatcher = formWatch('city', null);
  const coverageOperatorsWatcher = formWatch('coverageOperators', []);

  useEffect(() => {
    reduxGetCoverage({
      page: 1,
      offset: 10,
      search: "",
    });
    reduxGetCoverageType();
    reduxGetLogisticsOperators();
    reduxGetOrigins();
    reduxGetCountries({
      page: 1,
      offset: 1000,
      search: "",
      isActive: true,
    });

    if (!isNullOrUndefined(coverageData)) {
      reduxGetOneCoverage(coverageData);
    }
  }, []);

  useEffect(() => {
    if (isNullOrUndefined(oneCoverage) || Object.keys(oneCoverage).length == 0) return;

    var data = oneCoverage.data;

    setFormValue('country', { label: data.country_description, value: data.id_country });
    setFormValue('department', { label: data.department_description, value: data.id_department });
    setFormValue('city', { label: data.city_description, value: data.id_city });
    setFormValue('neighborhood', { label: data.full_neighborhood, value: data.id_neighborhood });
    setFormValue('coverageType', { label: data.coverage_type, value: data.id_coverage_type });
    setFormValue('origin', { label: data.full_origin.split("|")[1], value: data.id_origin });
    setFormValue('zone', data.coverage_zone);

    if (data.operators && data.operators.length == 0) return;

    const operadoresTablaActualizados = data.operators.map(operator => ({
      idOperator: operator.id_company,
      logisticsOperator: { label: operator.operator, value: operator.id_company },
      zoneOp: operator.zone,
      days: operator.deliveryTime,
      effectiveRate: operator.efective,
      ineffectiveRate: operator.noEfective,
      status: operator.status,
    }));

    setCoverageOperatorsMap(operadoresTablaActualizados);
  }, [oneCoverage]);

  useEffect(() => {
    if (isNullOrUndefined(countryWatcher)) {
      setFormValue('department', null)
      setFormValue('city', null)
      setFormValue('neighborhood', null)
      return;
    }

    const selected = countries && Object.keys(countries).length > 0 && countries.items.find((e) => e.idCountry === Number(countryWatcher.value))

    if (isNullOrUndefined(selected)) return;

    reduxGetDepartmentsByCountry({
      idCountry: selected.idCountry
    })
  }, [countryWatcher]);

  useEffect(() => {
    if (isNullOrUndefined(departmentsByCountry) || isNullOrUndefined(departmentWatcher)) {
      setFormValue('city', null)
      setFormValue('neighborhood', null)
      return;
    }

    const selected = departmentsByCountry && Object.keys(departmentsByCountry).length > 0 && departmentsByCountry.find((e) => e.idDepartment === Number(departmentWatcher.value))

    if (isNullOrUndefined(selected)) return;

    reduxGetCitiesByDepartment({
      idDepartment: departmentWatcher.value
    })
  }, [departmentWatcher, departmentsByCountry]);

  useEffect(() => {
    if (isNullOrUndefined(citiesByDepartment) || isNullOrUndefined(cityWatcher)) {
      setFormValue('neighborhood', null);
      return;
    }

    if (isNullOrUndefined(cityWatcher)) return;

    const selected = citiesByDepartment && Object.keys(citiesByDepartment).length > 0 && citiesByDepartment.find((e) => e.idCity === Number(cityWatcher.value))

    if (isNullOrUndefined(selected)) return;

    reduxGetNeighborhoodsByCity({
      idCity: cityWatcher.value
    })
  }, [cityWatcher, citiesByDepartment]);

  useEffect(() => {
    if (coverageOperatorsWatcher) return;

    formSetError('coverageOperators', 'Es necesario al menos un operador logístico');
  }, [coverageOperatorsWatcher])

  useEffect(() => {
    if (!errorsAssignOperatorCoverage && (successfulCreateCoverage || successfulUpdateCoverage)) {
      handleCloseNewModal();
    }
  }, [successfulCreateCoverage, successfulUpdateCoverage, reduxResetCoverageForm, errorsAssignOperatorCoverage])

  useEffect(() => {
    if (successfulSaveOrigins === true) {
      reduxGetOrigins();
    }
  }, [successfulSaveOrigins]);

  useEffect(() => {
    if (!successfulUpdateOperatorCoverage) return;

    reduxGetOneCoverage(coverageData);
  }, [successfulUpdateOperatorCoverage, errorsUpdateOperatorCoverage])

  useEffect(() => {
    var operators = coverageOperatorsMap.concat(newCoverageOperatorsMap);
    setFormValue('coverageOperators', operators);
  }, [coverageOperatorsMap, newCoverageOperatorsMap])

  const handleCloseNewModal = () => {
    resetForm();
    reduxResetCoverageForm();
    history.push("/coverage");
  };

  const handleOpenCreateOrigin = () => {
    const originInfoObj = {
      idCountry: countryWatcher.value
    }

    setOriginInfo(originInfoObj);
    setShowCreateOriginModal(true);
  }

  const handleCloseCreateOrigin = () => {
    setShowCreateOriginModal(false);
  }

  const operatorFormIsValid = () => {
    formClearErrors();
    var isValid = true;
    const data = getFormValues();

    if (isNullOrUndefined(data.logisticsOperator)) {
      formSetError("logisticsOperator", {
        type: "manual",
        message: "El operador logistico es requerido",
      })
      isValid = false;
    }

    if (isNullOrUndefined(data.zoneOp)) {
      formSetError("zoneOp", {
        type: "manual",
        message: "La zona es requerida",
      })
      isValid = false;
    }

    if (isNullOrUndefined(data.days)) {
      formSetError("days", {
        type: "manual",
        message: "Los dias son requeridos",
      })
      isValid = false;
    }

    if (isNullOrUndefined(data.effectiveRate)) {
      formSetError("effectiveRate", {
        type: "manual",
        message: "La tarifa efectiva es obligatoria",
      })
      isValid = false;
    }

    if (isNullOrUndefined(data.ineffectiveRate)) {
      formSetError("ineffectiveRate", {
        type: "manual",
        message: "La tarifa no efectiva es obligatoria",
      })
      isValid = false;
    }

    return isValid;
  }

  const addOperator = () => {
    if (!operatorFormIsValid()) return;

    const data = getFormValues();

    const nuevoOperador = {
      idOperator: data.logisticsOperator.value,
      logisticsOperator: data.logisticsOperator,
      zoneOp: data.zoneOp,
      days: data.days,
      effectiveRate: data.effectiveRate,
      ineffectiveRate: data.ineffectiveRate,
    };

    const isDuplicate = coverageOperatorsWatcher.some(operadorExistente => {
      return (
        operadorExistente.logisticsOperator.value === nuevoOperador.logisticsOperator.value &&
        operadorExistente.zoneOp === nuevoOperador.zoneOp &&
        parseInt(operadorExistente.days) === parseInt(nuevoOperador.days) &&
        parseInt(operadorExistente.effectiveRate) === parseInt(nuevoOperador.effectiveRate) &&
        parseInt(operadorExistente.ineffectiveRate) === parseInt(nuevoOperador.ineffectiveRate)
      );
    });

    if (isDuplicate) {
      Swal.fire('No se puede agregar el operador porque ya existe uno con los mismos valores.');
      return;
    }

    setNewCoverageOperatorsMap([...newCoverageOperatorsMap, nuevoOperador]);

    setFormValue('logisticsOperator', null);
    setFormValue('zoneOp', null);
    setFormValue('days', null);
    setFormValue('effectiveRate', null);
    setFormValue('ineffectiveRate', null);
  };

  const handleUpdateOperator = (index, status) => {
    const operator = coverageOperatorsWatcher[index];

    reduxUpdateCoverageOperator({
      idCoverage: oneCoverage.data.coverage_id,
      idOperator: operator.idOperator,
      status,
    })
  }

  const handleRemoveOperator = (element) => {
    const newOperatorsArray = newCoverageOperatorsMap.filter((item) => item !== element);
    setNewCoverageOperatorsMap(newOperatorsArray);
  }

  const onSubmit = (data) => {
    if (!isNullOrUndefined(coverageData)) {
      if(newCoverageOperatorsMap.length > 0) {
        newCoverageOperatorsMap.forEach((item) => {
          reduxAssignCoverageOperator({
            coverageId: coverageData.coverageId,
            idOperator: item.logisticsOperator.value,
            zone: item.zoneOp,
            deliveryTime: parseInt(item.days),
            effective: parseInt(item.effectiveRate),
            noEffective: parseInt(item.ineffectiveRate),
          })
        })
      }
      reduxPatchCoverage({
        idCoverage: coverageData.coverageId,
        zone: data.zone,
        idCoverageType: data.coverageType.value,
      });
    } else {
      reduxPostCoverage({
        idCountry: data.country.value,
        idOrigin: data.origin.value,
        idDepartment: data.department.value,
        idCity: data.city.value,
        idNeighborhood: data.neighborhood?.value,
        idCoverageType: data.coverageType.value,
        zone: data.zone,
        operators: data.coverageOperators.map((item) => ({
          idOperator: item.logisticsOperator.value,
          zone: item.zoneOp,
          deliveryTime: item.days,
          effective: item.effectiveRate,
          noEffective: item.ineffectiveRate,
        }))
      });
    }
  };

  return (
    <div>
      <div id="wrapper">
        <Sidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Topbar />
            <div className="container-fluid">
              <div className="d-sm-flex align-items-center mb-1">
                <h1 className="h3 mb-0 text-gray-800 mr-3">{coverageData ? 'Editar' : 'Crear'} Cobertura</h1>
                <Show when="feature:coverage-create">
                  <button
                    title="Volver"
                    className="btn btn-primary btn-sm  btn-circle mr-2"
                    type="button"
                    onClick={() => handleCloseNewModal()}
                  >
                    <i className="fas fa-arrow-left fa-sm"></i>
                  </button>
                </Show>
              </div>
              <p className="mb-4">Módulo de Administración de Coberturas</p>
              <div className="card shadow mb-4">
                <form onSubmit={handleFormSubmit(onSubmit)}>
                  <Table striped className="mb-4">
                    <thead>
                      <tr>
                        <th className="col-auto">País</th>
                        <th className="col-auto">Origen (Bodega)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <Controller
                            control={formControl}
                            rules={{
                              required: 'El país es obligatorio'
                            }}
                            name="country"
                            render={({ field }) => (
                              <>
                                <Select
                                  {...field}
                                  isClearable
                                  styles={selectStyles}
                                  isDisabled={coverageData}
                                  onChange={(value) => {
                                    field.onChange(value);
                                    if (value) {
                                      setShowNewOriginButton(true);
                                    } else {
                                      setShowNewOriginButton(false);
                                    }
                                  }}
                                  options={
                                    countries && Object.keys(countries).length > 0
                                      ? countries.items
                                        .filter((f) => f.isActive)
                                        .map((ele) => ({
                                          value: ele.idCountry,
                                          label: ele.description,
                                        }))
                                      : []
                                  }
                                />
                                {
                                  formErrors.country &&
                                  <span className="error-message">
                                    {formErrors.country.message}
                                  </span>
                                }
                              </>
                            )}
                          />
                        </td>
                        <td>
                          <Controller
                            control={formControl}
                            rules={{
                              required: 'El origen es obligatorio'
                            }}
                            name="origin"
                            render={({ field }) => (
                              <>
                                <Select
                                  {...field}
                                  isClearable
                                  isDisabled={coverageData}
                                  styles={selectStyles}
                                  options={
                                    origins && origins.length > 0
                                      ? origins
                                        .filter((origin) => origin.status)
                                        .map((origin) => ({
                                          value: origin.id,
                                          label: origin.description,
                                        }))
                                      : []
                                  }
                                />
                                {
                                  formErrors.origin &&
                                  <span className="error-message">
                                    {formErrors.origin.message}
                                  </span>
                                }
                              </>
                            )}
                          />
                        </td>
                        {
                          showNewOriginButton && (
                            <Show when="feature:coverage-create">
                              <td>
                                <button
                                  title="Nuevo Origen"
                                  className="btn btn-primary btn-sm btn-circle mt-1"
                                  type="button"
                                  onClick={() => handleOpenCreateOrigin()}
                                >
                                  <i className="fas fa-plus fa-sm"></i>
                                </button>
                              </td>
                            </Show>
                          )
                        }
                      </tr>
                    </tbody>
                  </Table>
                  <Table striped bordered className="mb-4">
                    <thead>
                      <tr>
                        <th className="col-2">Departamento/Estado</th>
                        <th className="col-2">Ciudad/Delegacion</th>
                        <th className="col-2">Barrio/Colonia/Localidad</th>
                        <th className="col-2">Tipo</th>
                        <th className="col-1">Zona</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <Controller
                            control={formControl}
                            rules={{
                              required: 'El departamento es obligatorio'
                            }}
                            name="department"
                            render={({ field }) => (
                              <>
                                <Select
                                  {...field}
                                  isClearable
                                  isDisabled={coverageData}
                                  options={
                                    departmentsByCountry && Object.keys(departmentsByCountry).length > 0
                                      ? departmentsByCountry
                                        .filter((f) => f.isActive)
                                        .map((ele) => ({
                                          value: ele.idDepartment,
                                          label: ele.description,
                                        }))
                                      : []
                                  }
                                />
                                {
                                  formErrors.department &&
                                  <span className="error-message">
                                    {formErrors.department.message}
                                  </span>
                                }
                              </>
                            )}
                          />
                        </td>
                        <td>
                          <Controller
                            control={formControl}
                            rules={{
                              required: 'La ciudad es obligatoria'
                            }}
                            name="city"
                            render={({ field }) => (
                              <>
                                <Select
                                  {...field}
                                  isClearable
                                  isDisabled={coverageData}
                                  options={
                                    citiesByDepartment && Object.keys(citiesByDepartment).length > 0
                                      ? citiesByDepartment
                                        .filter((f) => f.isActive)
                                        .map((ele) => ({
                                          value: ele.idCity,
                                          label: ele.description,
                                        }))
                                      : []
                                  }
                                />
                                {
                                  formErrors.city &&
                                  <span className="error-message">
                                    {formErrors.city.message}
                                  </span>
                                }
                              </>
                            )}
                          />
                        </td>
                        <td>
                          <Controller
                            control={formControl}
                            rules={{
                              required: 'El barrio es obligatorio'
                            }}
                            name="neighborhood"
                            render={({ field }) => (
                              <>
                                <Select
                                  {...field}
                                  isClearable
                                  isDisabled={coverageData}
                                  options={
                                    neighborhoodsByCity && Object.keys(neighborhoodsByCity).length > 0
                                      ? neighborhoodsByCity
                                        .filter((f) => f.isActive)
                                        .map((ele, key) => ({
                                          value: ele.idNeighborhood,
                                          label: ele.description,
                                        }))
                                      : []
                                  }
                                />
                                {
                                  formErrors.neighborhood &&
                                  <span className="error-message">
                                    {formErrors.neighborhood.message}
                                  </span>
                                }
                              </>
                            )}
                          />
                        </td>
                        <td>
                          <Controller
                            control={formControl}
                            rules={{
                              required: 'El tipo es obligatorio'
                            }}
                            name="coverageType"
                            render={({ field }) => (
                              <>
                                <Select
                                  {...field}
                                  isClearable
                                  options={
                                    coverageTypes && Object.keys(coverageTypes).length > 0
                                      ? coverageTypes
                                        .map((coverageType) => ({
                                          value: coverageType.idCoverageType,
                                          label: coverageType.description,
                                        }))
                                      : []
                                  }
                                />
                                {
                                  formErrors.coverageType &&
                                  <span className="error-message">
                                    {formErrors.coverageType.message}
                                  </span>
                                }
                              </>
                            )}
                          />
                        </td>
                        <td>
                          <Controller
                            control={formControl}
                            rules={{
                              required: 'La zona es obligatoria'
                            }}
                            name="zone"
                            render={({ field }) => (
                              <>
                                <input
                                  {...field}
                                  className="form-control"
                                  type="text"
                                  placeholder="Zona"
                                  maxLength={100}
                                />
                                {
                                  formErrors.zone &&
                                  <span className="error-message">
                                    {formErrors.zone.message}
                                  </span>
                                }
                              </>
                            )}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                  <Table striped bordered className="mb-4">
                    <thead>
                      <tr>
                        <th className="text-bold text-center" colSpan="6">Asociar Operadores Logisticos</th>
                      </tr>
                      <tr>
                        <th className="col-3">Operador</th>
                        <th className="col-2">Zona</th>
                        <th className="col-2">Días entrega</th>
                        <th className="col-2">Tarifa efectiva</th>
                        <th className="col-2">Tarifa no efectiva</th>
                        <th className="col-1"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <Controller
                            control={formControl}
                            name="logisticsOperator"
                            render={({ field }) => (
                              <>
                                <Select
                                  {...field}
                                  isClearable
                                  options={
                                    logisticsOperators &&
                                    Object.keys(logisticsOperators).length > 0 &&
                                    logisticsOperators.items
                                      .filter((f) => f.isActive === true)
                                      .map((ele, key) => ({
                                        value: ele.idCompany,
                                        label: ele.description,
                                      }))
                                  }
                                />
                                {
                                  formErrors.logisticsOperator &&
                                  <span className="error-message">
                                    {formErrors.logisticsOperator.message}
                                  </span>
                                }
                              </>
                            )}
                          />
                        </td>
                        <td>
                          <Controller
                            control={formControl}
                            name="zoneOp"
                            render={({ field }) => (
                              <>
                                <input
                                  {...field}
                                  className="form-control"
                                  type="text"
                                  name="zoneOp"
                                  placeholder="Zona Operador"
                                  maxLength={100}
                                />
                                {
                                  formErrors.zoneOp &&
                                  <span className="error-message">
                                    {formErrors.zoneOp.message}
                                  </span>
                                }
                              </>
                            )}
                          />
                        </td>
                        <td>
                          <Controller
                            control={formControl}
                            name="days"
                            render={({ field }) => (
                              <>
                                <input
                                  {...field}
                                  className="form-control"
                                  type="number"
                                  name="days"
                                  placeholder="Días Entrega"
                                  maxLength={100}
                                  onKeyPress={(e) => {
                                    const charCode = e.key;
                                    if (isNaN(parseInt(charCode))) e.preventDefault();
                                  }}
                                />
                                {
                                  formErrors.days &&
                                  <span className="error-message">
                                    {formErrors.days.message}
                                  </span>
                                }
                              </>
                            )}
                          />
                        </td>
                        <td>
                          <Controller
                            control={formControl}
                            name="effectiveRate"
                            render={({ field }) => (
                              <>
                                <input
                                  {...field}
                                  className="form-control"
                                  type="number"
                                  name="effectiveRate"
                                  placeholder="Tarifa Efectiva"
                                  maxLength={100}
                                  onKeyPress={(e) => {
                                    const charCode = e.key;
                                    if (isNaN(parseInt(charCode))) e.preventDefault();
                                  }}
                                />
                                {
                                  formErrors.effectiveRate &&
                                  <span className="error-message">
                                    {formErrors.effectiveRate.message}
                                  </span>
                                }
                              </>
                            )}
                          />
                        </td>
                        <td>
                          <Controller
                            control={formControl}
                            name="ineffectiveRate"
                            render={({ field }) => (
                              <>
                                <input
                                  {...field}
                                  className="form-control"
                                  type="number"
                                  name="ineffectiveRate"
                                  placeholder="Tarifa No Efectiva"
                                  maxLength={100}
                                  onKeyPress={(e) => {
                                    const charCode = e.key;
                                    if (isNaN(parseInt(charCode))) e.preventDefault();
                                  }}
                                />
                                {
                                  formErrors.ineffectiveRate &&
                                  <span className="error-message">
                                    {formErrors.ineffectiveRate.message}
                                  </span>
                                }
                              </>
                            )}
                          />
                        </td>
                        <td>
                          <button className='btn btn-primary' type="button" onClick={addOperator}>Agregar</button>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                  <Table bordered>
                    <thead>
                      <tr>
                        <th className="text-bold text-center" colSpan="6">Operadores Logisticos</th>
                      </tr>
                      {
                        coverageOperatorsWatcher.length > 0 && (
                          <tr>
                            <th className="col-3">Operador</th>
                            <th className="col-2">Zona</th>
                            <th className="col-2">Días entrega</th>
                            <th className="col-2">Tarifa efectiva</th>
                            <th className="col-2">Tarifa no efectiva</th>
                            <th className="col-1"></th>
                          </tr>
                        )
                      }
                    </thead>
                    <tbody>
                      <Controller
                        control={formControl}
                        rules={{
                          required: 'Los operadores logísticos son obligatorios',
                          minLength: { value: 1, message: 'Debe ingresar al menos un operador logístico' }
                        }}
                        name="coverageOperators"
                        render={() => (
                          <>
                            {
                              coverageOperatorsWatcher.map((item, index) => (
                                <tr key={index} style={
                                  {
                                    backgroundColor: item.status == 1 || item.status == 3 ? 'transparent' : 'lightgray',
                                  }
                                }>
                                  <td>{item.logisticsOperator.label}</td>
                                  <td>{item.zoneOp}</td>
                                  <td>{item.days}</td>
                                  <td>{item.effectiveRate}</td>
                                  <td>{item.ineffectiveRate}</td>
                                  <td>
                                    {
                                      coverageData && (item.status == 1 || item.status == 0) ?
                                        <button className='btn btn-primary' type="button" onClick={() => handleUpdateOperator(index, item.status == 0 ? 1 : 0)}>
                                          {item.status == 1 ? 'Desactivar' : 'Activar'}
                                        </button>
                                        :
                                        <button className='btn btn-primary' type="button" onClick={() => handleRemoveOperator(item)}>
                                          Eliminar
                                        </button>
                                    }
                                  </td>
                                </tr>
                              ))}
                            {
                              formErrors.coverageOperators &&
                              <tr>
                                <td>
                                  <span className="error-message text-center">
                                  {formErrors.coverageOperators.message}
                                </span>
                                </td>
                              </tr>
                            }
                          </>
                        )}
                      />
                    </tbody>
                  </Table>
                  <div className="d-flex mx-2 my-2 justify-content-center">
                    <button className='btn btn-primary' type="submit">Guardar Cobertura</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <CreateOrigin
          show={showCreateOriginModal}
          handleClose={handleCloseCreateOrigin}
          originInfo={originInfo}
        />
      </div>
    </div >
  );
};

const mapStateToProps = (state) => {
  return {
    coverages: state.coverageState.coverages,
    coverage: state.coverageState.coverage,
    oneCoverage: state.coverageState.oneCoverage,
    rowEdited: state.coverageState.rowEdited,
    rowDeleted: state.coverageState.rowDeleted,
    rowUpdated: state.coverageState.rowUpdated,
    cities: state.cityState.cities,
    origins: state.originState.origins,
    coverageTypes: state.coverageState.coverageTypes,
    neighborhoodsByCity: state.neighborhoodState.neighborhoodsByCity,
    countries: state.countryState.countries,
    departmentsByCountry: state.departmentState.departmentsByCountry,
    citiesByDepartment: state.cityState.citiesByDepartment,
    branches: state.branchState.branches,
    errorsUpdateCoverage: state.coverageState.errorsUpdateCoverage,
    successfulUpdateCoverage: state.coverageState.successfulUpdateCoverage,
    successfulCreateCoverage: state.coverageState.successfulCreateCoverage,
    errorsCreateCoverage: state.coverageState.errorsCreateCoverage,
    logisticsOperators: state.companyState.logisticsOperators,
    successfulSaveOrigins: state.originState.successfulSaveOrigins,
    successfulUpdateOperatorCoverage: state.coverageState.successfulUpdateOperatorCoverage,
    errorsUpdateOperatorCoverage: state.coverageState.errorsUpdateOperatorCoverage,
    successfulAssignOperatorCoverage: state.coverageState.successfulAssignOperatorCoverage,
    errorsAssignOperatorCoverage: state.coverageState.errorsAssignOperatorCoverage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    reduxGetOneCoverage: (payload) =>
      dispatch({
        type: "FETCH_ONECOVERAGE_REQUEST",
        value: payload,
      }),
    reduxPostCoverage: (payload) =>
      dispatch({
        type: "CREATE_COVERAGE_REQUEST",
        value: payload,
      }),
    reduxGetCoverage: (payload) =>
      dispatch({
        type: "FETCH_COVERAGE_REQUEST",
        value: payload,
      }),
    reduxDeleteCoverage: (payload) =>
      dispatch({
        type: "DELETE_COVERAGE_REQUEST",
        value: payload,
      }),
    reduxPatchCoverage: (payload) =>
      dispatch({
        type: "UPDATE_COVERAGE_REQUEST",
        value: payload,
      }),
    reduxResetCoverageForm: () =>
      dispatch({
        type: "RESET_COVERAGE_FORM",
      }),
    reduxGetCities: (payload) =>
      dispatch({
        type: "FETCH_CITIES_REQUEST",
        value: payload,
      }),
    reduxGetCountries: (payload) =>
      dispatch({
        type: "FETCH_COUNTRIES_REQUEST",
        value: payload,
      }),
    reduxGetDepartmentsByCountry: (payload) =>
      dispatch({
        type: "READBYCOUNTRY_DEPARTMENT_REQUEST",
        value: payload,
      }),
    reduxGetCitiesByDepartment: (payload) =>
      dispatch({
        type: "READBYDEPARTMENT_CITY_REQUEST",
        value: payload,
      }),
    reduxGetNeighborhoodsByCity: (payload) =>
      dispatch({
        type: "READBYCITY_NEIGHBORHOOD_REQUEST",
        value: payload,
      }),
    reduxSetDepartment: (payload) =>
      dispatch({
        type: "RESET_BYCOUNTRY_DEPARTMENT",
        value: payload,
      }),
    reduxSetCity: (payload) =>
      dispatch({
        type: "RESET_BYDEPARTMENT_CITY",
        value: payload,
      }),
    reduxSetNeighborhood: (payload) =>
      dispatch({
        type: "RESET_BYCITY_NEIGHBORHOOD",
        value: payload,
      }),
    reduxGetBranches: (payload) =>
      dispatch({
        type: "FETCH_BRANCHES_REQUEST",
        value: payload,
      }),
    reduxGetOrigins: (payload) =>
      dispatch({
        type: "FETCH_ORIGINS_REQUEST",
        value: payload,
      }),
    reduxGetCoverageType: (payload) =>
      dispatch({
        type: "FETCH_COVERAGETYPE_REQUEST",
        value: payload,
      }),
    reduxGetLogisticsOperators: (payload) =>
      dispatch({
        type: 'FETCH_COMPANIESBYLOGISTICSOPERATOR_REQUEST',
        value: payload,
      }),
    reduxUpdateCoverageOperator: (payload) =>
      dispatch({
        type: 'UPDATE_COVERAGE_OPERATOR_REQUEST',
        value: payload,
      }),
    reduxAssignCoverageOperator: (payload) =>
      dispatch({
        type: 'ASSIGN_COVERAGE_OPERATOR_REQUEST',
        value: payload,
      }),
  };

};

export default connect(mapStateToProps, mapDispatchToProps)(NewEdit);