

const initialState = {
    addressInsideTypes:{},
    fetching_address:false,
    requestingFetchAddressInsideType: false,
    successfulFetchAddressInsideType: false,
    errorFetchAddressInsideType: false,
    requestingCreateAddressInsideType: false,
    successfulCreateAddressInsideType: false,
    errorsCreateAddressInsideType: false,
    addressInsideType: null,
    requestingReadAddressInsideType: false,
    successfulReadAddressInsideType: false,
    errorsReadAddressInsideType: false,
    rowEdited: null,
    requestingDeleteAddressInsideType: false,
    successfulDeleteAddressInsideType: false,
    errorsDeleteAddressInsideType: false,
    rowDeleted: null,
    requestingUpdateAddressInsideType: false,
    successfulUpdateAddressInsideType: false,
    errorsUpdateAddressInsideType: false,
    rowUpdated: null,
}

const addressInsideTypeReducer = (state = initialState, payload) => {
    switch (payload.type) {
        case addressInsideTypeActions.FECTCHIN_ALL_ADDRESSINSIDETYPE:
            return {
                ...state,
                fetching_address: payload.value
            };
        case addressInsideTypeActions.FETCH_ADDRESSINSIDETYPE_SUCCESS:
            return {
                ...state,
                errorFetchAddressInsideType: false,
                requestingFetchAddressInsideType: false,
                successfulFetchAddressInsideType: true,
                addressInsideTypes: payload.value,
            };
        case addressInsideTypeActions.FETCH_ADDRESSINSIDETYPE_ERROR:
            return {
                ...state,
                errorFetchAddressInsideType: true,
                requestingFetchAddressInsideType: false,
                successfulFetchAddressInsideType: false,
            };
        case addressInsideTypeActions.CREATE_ADDRESSINSIDETYPE_REQUESTING:
            return {
                ...state,
                requestingCreateAddressInsideType: true,
                successfulCreateAddressInsideType: false,
                errorsCreateAddressInsideType: false,
            };
        case addressInsideTypeActions.CREATE_ADDRESSINSIDETYPE_SUCCESS:
            return {
                ...state,
                errorsCreateAddressInsideType: false,
                requestingCreateAddressInsideType: false,
                successfulCreateAddressInsideType: true,
                addressInsideType: payload.value,
            };
        case addressInsideTypeActions.CREATE_ADDRESSINSIDETYPE_ERROR:
            return {
                ...state,
                errorsCreateAddressInsideType: true,
                requestingCreateAddressInsideType: false,
                successfulCreateAddressInsideType: false,
            };
        case addressInsideTypeActions.READ_ADDRESSINSIDETYPE_REQUESTING:
            return {
                ...state,
                requestingReadAddressInsideType: true,
                successfulReadAddressInsideType: false,
                errorsReadAddressInsideType: false,
            };
        case addressInsideTypeActions.READ_ADDRESSINSIDETYPE_SUCCESS:
            return {
                ...state,
                errorsReadAddressInsideType: false,
                requestingReadAddressInsideType: false,
                successfulReadAddressInsideType: true,
                rowEdited: payload.value,
            };
        case addressInsideTypeActions.READ_ADDRESSINSIDETYPE_ERROR:
            return {
                ...state,
                errorsReadAddressInsideType: true,
                requestingReadAddressInsideType: false,
                successfulReadAddressInsideType: false,
            };
        case addressInsideTypeActions.DELETE_ADDRESSINSIDETYPE_REQUESTING:
            return {
                ...state,
                requestingDeleteAddressInsideType: true,
                successfulDeleteAddressInsideType: false,
                errorsDeleteAddressInsideType: false,
            };
        case addressInsideTypeActions.DELETE_ADDRESSINSIDETYPE_SUCCESS:
            return {
                ...state,
                errorsDeleteAddressInsideType: false,
                requestingDeleteAddressInsideType: false,
                successfulDeleteAddressInsideType: true,
                rowDeleted: payload.value,
            };
        case addressInsideTypeActions.DELETE_ADDRESSINSIDETYPE_ERROR:
            return {
                ...state,
                errorsDeleteAddressInsideType: true,
                requestingDeleteAddressInsideType: false,
                successfulDeleteAddressInsideType: false,
            };
        case addressInsideTypeActions.UPDATE_ADDRESSINSIDETYPE_REQUESTING:
            return {
                ...state,
                requestingUpdateAddressInsideType: true,
                successfulUpdateAddressInsideType: false,
                errorsUpdateAddressInsideType: false,
            };
        case addressInsideTypeActions.UPDATE_ADDRESSINSIDETYPE_SUCCESS:
            return {
                ...state,
                errorsUpdateAddressInsideType: false,
                requestingUpdateAddressInsideType: false,
                successfulUpdateAddressInsideType: true,
                rowUpdated: payload.value,
            };
        case addressInsideTypeActions.UPDATE_UPDATE_ADDRESSINSIDETYPE_ERROR:
            return {
                ...state,
                errorsUpdateAddressInsideType: true,
                requestingUpdateAddressInsideType: false,
                successfulUpdateAddressInsideType: false,
            };
        case addressInsideTypeActions.RESET_ADDRESSINSIDETYPE_FORM:
            return {
                ...state,
                requestingCreateAddressInsideType: false,
                successfulCreateAddressInsideType: false,
                errorsCreateAddressInsideType: false,
                country: null,
                requestingReadAddressInsideType: false,
                successfulReadAddressInsideType: false,
                errorsReadAddressInsideType: false,
                rowEdited: null,
                requestingDeleteAddressInsideType: false,
                successfulDeleteAddressInsideType: false,
                errorsDeleteAddressInsideType: false,
                rowDeleted: null,
                requestingUpdateAddressInsideType: false,
                successfulUpdateAddressInsideType: false,
                errorsUpdateAddressInsideType: false,
                rowUpdated: null,
            };
        default:
            return state;
    }
};

export const addressInsideTypeActions = {
    FECTCHIN_ALL_ADDRESSINSIDETYPE: 'FECTCHIN_ALL_ADDRESSINSIDETYPE',
    CREATE_ADDRESSINSIDETYPE_REQUESTING: 'CREATE_ADDRESSINSIDETYPE_REQUESTING',
    FETCH_ADDRESSINSIDETYPE_REQUEST : 'FETCH_ADDRESSINSIDETYPE_REQUEST',
    FETCH_ADDRESSINSIDETYPE_SUCCESS : 'FETCH_ADDRESSINSIDETYPE_SUCCESS',
    CREATE_ADDRESSINSIDETYPE_REQUEST: 'CREATE_ADDRESSINSIDETYPE_REQUEST',
    CREATE_ADDRESSINSIDETYPE_SUCCESS: "CREATE_ADDRESSINSIDETYPE_SUCCESS",
    FETCH_ADDRESSINSIDETYPE_ERROR: 'FETCH_ADDRESSINSIDETYPE_ERROR',
    CREATE_ADDRESSINSIDETYPE_ERROR: 'CREATE_ADDRESSINSIDETYPE_ERROR',
    READ_ADDRESSINSIDETYPE_REQUESTING: 'READ_ADDRESSINSIDETYPE_REQUESTING',
    READ_ADDRESSINSIDETYPE_SUCCESS: "READ_ADDRESSINSIDETYPE_SUCCESS",
    READ_ADDRESSINSIDETYPE_ERROR: "READ_ADDRESSINSIDETYPE_ERROR",
    DELETE_ADDRESSINSIDETYPE_REQUESTING: "DELETE_ADDRESSINSIDETYPE_REQUESTING",
    DELETE_ADDRESSINSIDETYPE_SUCCESS: "DELETE_ADDRESSINSIDETYPE_SUCCESS",
    DELETE_ADDRESSINSIDETYPE_ERROR: "DELETE_ADDRESSINSIDETYPE_ERROR",
    UPDATE_ADDRESSINSIDETYPE_REQUESTING: "UPDATE_ADDRESSINSIDETYPE_REQUESTING",
    UPDATE_ADDRESSINSIDETYPE_SUCCESS: 'UPDATE_ADDRESSINSIDETYPE_SUCCESS',
    UPDATE_UPDATE_ADDRESSINSIDETYPE_ERROR: 'UPDATE_UPDATE_ADDRESSINSIDETYPE_ERROR',
    RESET_ADDRESSINSIDETYPE_FORM: 'RESET_ADDRESSINSIDETYPE_FORM',
    READ_ADDRESSINSIDETYPE_REQUEST: 'READ_ADDRESSINSIDETYPE_REQUEST',
    DELETE_ADDRESSINSIDETYPE_REQUEST: 'ADDRESSINSIDETYPE',
    UPDATE_ADDRESSINSIDETYPE_REQUEST: 'UPDATE_ADDRESSINSIDETYPE_REQUEST',
    READBYDEPARTMENT_ADDRESSINSIDETYPE_REQUEST: 'READBYDEPARTMENT_ADDRESSINSIDETYPE_REQUEST'
}

export default addressInsideTypeReducer;
