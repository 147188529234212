import { Table } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";

export const TableWithInputs = ({ inputControlName, columns, data, control }) => {
    return (
        <>
            <Table>
                <thead>
                    <tr>
                        {columns.map((column, index) => (
                            <th className="text-bold text-center" key={index}>{column.header}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {data && data.map((data, rowIndex) => (
                        <tr key={rowIndex}>
                            {columns.map((col, colIndex) => (
                                <td key={colIndex}>
                                    <Controller
                                        name={`${inputControlName}[${rowIndex}].${col.field}`}
                                        control={control}
                                        render={({ field }) => col.render(data, field)}
                                    />
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </Table>
        </>
    )
}