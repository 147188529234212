import {Button, Card, Col} from "react-bootstrap";

const ProgressBar = ({procesadas, total}) => {
    const porcentajeProcesado = total > 0 ? (procesadas / total) * 100 : 0;


    return (
        <div className="container-fluid mt-4">
            <Card>
                <div className="row mb-2 mt-3 align-items-start">

                    <div className="col-12 col-md-12 col-sm-12">
                        <div className='row-column w-100'>
                            <Col>
                                <div className="progress mb-2" title={`${procesadas} de ${total} procesadas`}>
                                    <div
                                        className="progress-bar"
                                        role="progressbar"
                                        style={{width: `${porcentajeProcesado}%`}}
                                        aria-valuenow={porcentajeProcesado}
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                    >
                                        {procesadas} de {total} datos procesados
                                    </div>
                                </div>
                            </Col>
                        </div>
                    </div>
                </div>
            </Card>
        </div>
    )
        ;
};

export default ProgressBar;
