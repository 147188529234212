import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { isNullOrUndefined } from "../../utils/functionsUtils";

const CustomTooltip = ({ data, placement = 'top' }) => {

    const renderItems = () => {
        const items = data.map(element => {
            return (
                <div className="text-xs my-1 mx-1" key={element}>
                    <strong>{element}</strong>
                </div>
            )
        });
        return items
    }

    return (
        <>
            <OverlayTrigger
                placement={placement}
                overlay={
                    <Tooltip>
                        <div className="tooltip-elements">
                            {renderItems()}
                        </div>
                    </Tooltip>
                }
            >
            <div className="d-flex justify-content-center" style={{ cursor: 'pointer' }}>
                {
                    !isNullOrUndefined(data) && data.length > 0 &&
                    <strong>...</strong>
                }
            </div>
            </OverlayTrigger>
        </>
    );
}

export default CustomTooltip;