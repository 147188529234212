const initialState = {
    requestingFetchOrders: false,
    successfulFetchOrders: false,
    errorFetchOrders: false,
    orders: {},
    requestingCreateOrder: false,
    successfulCreateOrder: false,
    errorsCreateOrder: false,
    order: null,
    requestingFetchOrdersByUser: false,
    successfulFetchOrdersByUser: false,
    errorFetchOrdersByUser: false,
    ordersByUser: {},
    errorsReadOrderDetail: false,
    requestingReadOrderDetail: false,
    successfulReadOrderDetail: false,
    orderDetail: {},
    requestingGetDSPTracking: false,
    successfulGetDSPTracking: false,
    errorGetDSPTracking: false,
    ordersEvents:{},
  };
  
  const dspReducer = (state = initialState, action) => {
    switch (action.type) {
      case "FETCH_DSP_ORDERS_REQUESTING":
        return {
          ...state,
          requestingFetchOrders: true,
          successfulFetchOrders: false,
          errorsFetchOrders: false,
        };
      case "FETCH_DSP_ORDER_SUCCESS":
        return {
          ...state,
          errorFetchOrders: false,
          requestingFetchOrders: false,
          successfulFetchOrders: true,
          orders: action.value,
        };
      case "FETCH_DSP_ORDER_ERROR":
        return {
          ...state,
          errorFetchOrders: true,
          requestingFetchOrders: false,
          successfulFetchOrders: false,
        };      
      case "FETCH_ORDERSBYUSER_REQUESTING":
        return {
          ...state,
          requestingFetchOrdersByUser: true,
          successfulFetchOrdersByUser: false,
          errorFetchOrdersByUser: false,
        };
      case "FETCH_ORDERBYUSER_SUCCESS":
        return {
          ...state,
          errorFetchOrdersByUser: false,
          requestingFetchOrdersByUser: false,
          successfulFetchOrdersByUser: true,
          ordersByUser: action.value,
        };
      case "FETCH_ORDERBYUSER_ERROR":
        return {
          ...state,
          errorFetchOrdersByUser: true,
          requestingFetchOrdersByUser: false,
          successfulFetchOrdersByUser: false,
        };
      case "RESET_ORDER_FORM":
        return {
          ...state,
          requestingCreateOrder: false,
          successfulCreateOrder: false,
          errorsCreateOrder: false,
          order: null,
          requestingFetchOrdersByUser: false,
          successfulFetchOrdersByUser: false,
          errorFetchOrdersByUser: false,
          ordersByUser: {},
        };
     case "READ_DSP_ORDERDETAIL_REQUEST":
        return {
            ...state,
            requestingReadOrderDetail: true,
            successfulReadOrderDetail: false,
            errorsReadOrderDetail: false,
        };
     case "READ_DSP_ORDERDETAIL_SUCCESS":
        return {
            ...state,
            errorsReadOrderDetail: false,
            requestingReadOrderDetail: false,
            successfulReadOrderDetail: true,
            orderDetail: action.value,
        };
     case "READ_DSP_ORDERDETAIL_ERROR":
        return {
            ...state,
            errorsReadOrderDetail: true,
            requestingReadOrderDetail: false,
            successfulReadOrderDetail: false,
        };
        case "READ_DSPORDER_TRACKING_REQUEST":
          return{
              ...state,
              requestingGetDSPTracking: true,
              successfulGetDSPTracking: false,
              errorGetDSPTracking: false,
          };
      case "READ_DSPORDER_TRACKING_SUCCESS":
          return {
              ...state,
              requestingGetDSPTracking: false,
              successfulGetDSPTracking: true,
              errorGetDSPTracking: false,
              ordersEvents: action.value,
          };
      case "READ_DSPORDER_TRACKING_ERROR":
          return {
              ...state,
              requestingGetDSPTracking: false,
              successfulGetDSPTracking: false,
              errorGetDSPTracking: true,
          };
      case "RESET":
        return initialState;
      default:
        return state;
    }
  };
  
  export default dspReducer;
  