import React, { forwardRef, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import Sidebar from "../../components/Navigation/Sidebar";
import Topbar from "../../components/Navigation/Topbar";
import Grid from "../../components/Grid";
import { connect } from "react-redux";
import { Button, Modal } from "react-bootstrap";
import { Show } from "../../hooks/Show";
import { useAuth } from "../../hooks/useAuth";
import { Multiselect } from "multiselect-react-dropdown";
import { GoogleMap, Polygon, useLoadScript } from "@react-google-maps/api";
import Select from 'react-select'

const ZoneNeighborhood = ({
  reduxGetZonesByCity,
  zonesByCity,
  reduxGetNeighborhoodsByZone,
  neighborhoodsByZone,
  reduxGetZoneNeighborhoods,
  zoneNeighborhoods,
  reduxGetZoneNeighborhood,
  zoneNeighborhood,
  reduxPostZoneNeighborhood,
  rowEdited,
  reduxDeleteZoneNeighborhood,
  rowDeleted,
  reduxPatchZoneNeighborhood,
  rowUpdated,
  reduxResetZoneNeighborhoodForm,
  reduxGetCities,
  cities,
}) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
    setValue,
  } = useForm({});
  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(10);
  const { session } = useAuth();
  const [show, setShow] = useState(false);
  const [itemsSelected, setItemsSelected] = useState([]);
  const DatePickerCustom = forwardRef(({ value, onClick }, ref) => (
    <input
      className="form-control"
      onClick={onClick}
      ref={ref}
      value={value}
      {...register("date", { required: true })}
    />
  ));
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_MAPS_KEY}`,
  });
  const [mapRef, setMapRef] = useState(null);
  const [center] = useState({ lat: 44.076613, lng: -98.362239833 });
  const [zoom, setZoom] = useState(3);
  const [coords, setCoords] = useState([]);
  const [properties, setProperties] = useState(null);
  const [optionsMultiselect, setOptionsMultiselect] = useState([]);
  const zone = useRef({});
  zone.current = watch("idZone", "");

  const city = useRef({});
  city.current = watch("idCity", "");

  const handleClose = () => {
    setShow(false);
    reduxResetZoneNeighborhoodForm();
  };
  const handleShow = () => {
    reduxGetCities({
      page: 1,
      offset: 100000,
      search: "",
      isActive: true,
    });
    setItemsSelected([]);
    reduxResetZoneNeighborhoodForm();
    reset();
    setShow(true);
  };
  const [search, setSearch] = useState("");

  const columns = [
    {
      title: "#",
      render: (rowData) => {
        return <span>{rowData.idZoneNeighborhood}</span>;
      },
      field: 'idZoneNeighborhood',
      searchable: true
    },
    {
      title: "Zona",
      render: (rowData) => {
        return <span>{rowData.zone.description}</span>;
      },
      field: 'zone.description',
      searchable: true
    },
    {
      title: "Barrio",
      render: (rowData) => {
        return <span>{rowData.neighborhood.description}</span>;
      },
      field: 'neighborhood.description',
      searchable: true
    },
    {
      title: "Opciones",
      render: (rowData) => {
        return (
          <>
            {rowData.isActive ? (
              <>
                <Show when="feature:edit-zoneneighborhood">
                  <button
                    title="Editar"
                    className="btn btn-primary btn-sm  btn-circle mr-2"
                    type="button"
                    onClick={(e) => handleOpen(rowData)}
                  >
                    <i className="fas fa-edit fa-xs"></i>
                  </button>
                </Show>
                <Show when="feature:disabled-zoneneighborhood">
                  <button
                    title="Desactivar"
                    className="btn btn-danger btn-sm btn-circle"
                    type="button"
                    onClick={(e) => handleDelete(rowData)}
                  >
                    <i className="fas fa-times-circle fa-xs"></i>
                  </button>
                </Show>
              </>
            ) : (
              <Show when="feature:enabled-zoneneighborhood">
                <button
                  title="Activar"
                  className="btn btn-primary btn-sm  btn-circle mr-2"
                  type="button"
                  onClick={(e) => handleActive(rowData)}
                >
                  <i className="fas fa-check-circle fa-xs"></i>
                </button>
              </Show>
            )}
          </>
        );
      },
    },
  ];

  const handleOpen = (row) => {
    reduxGetCities({
      page: 1,
      offset: 100000,
      search: "",
      isActive: true,
    });
    reduxGetZonesByCity({
      idCity: row.zone.idCity,
    });
    reduxGetNeighborhoodsByZone({
      idZone: row.idZone,
    });
    reduxGetZoneNeighborhood({
      id: row.idZoneNeighborhood,
    });
    setItemsSelected([]);
  };

  const handleActive = (row) => {
    if (!row.isActive) {
      reduxDeleteZoneNeighborhood(row);
    }
  };

  useEffect(() => {
    if (
      rowEdited &&
      neighborhoodsByZone &&
      Object.keys(neighborhoodsByZone).length > 0 &&
      zonesByCity &&
      Object.keys(zonesByCity).length > 0
    ) {
      setShow(true);
      setValue("idCity", rowEdited.zone.idCity);
      setValue("idZone", rowEdited.idZone);
      setValue("idNeighborhood", rowEdited.idNeighborhood);
    }
  }, [rowEdited, neighborhoodsByZone, zonesByCity]);

  const handleDelete = (row) => {
    reduxDeleteZoneNeighborhood(row);
  };

  useEffect(() => {
    reduxGetZoneNeighborhoods({
      page: 1,
      offset: offset,
      search: "",
    });
  }, []);

  useEffect(() => {
    reduxGetZoneNeighborhoods({
      page: currentPage,
      offset: offset,
      search: search,
    });
  }, [currentPage]);

  useEffect(() => {
    if (zoneNeighborhood || rowUpdated || rowDeleted) {
      reduxGetZoneNeighborhoods({
        page: currentPage,
        offset: offset,
        search: search,
      });
      reduxResetZoneNeighborhoodForm();
    }
  }, [zoneNeighborhood, rowUpdated, rowDeleted]);

  const onSubmit = (data) => {
    if (rowEdited) {
      reduxPatchZoneNeighborhood({ ...data, id: rowEdited.idZoneNeighborhood });
    } else {
      reduxPostZoneNeighborhood(data);
    }
    reset();
    reduxResetZoneNeighborhoodForm();
    setShow(false);
  };

  useEffect(() => {
    if (zone.current != "" && zone.current != undefined) {
      let zoneSelected = zonesByCity.filter((element) => {
        return element.idZone == Number(zone.current);
      })[0];
      if (zoneSelected) {
        setProperties(zoneSelected.properties);
        let arrayCoord = zoneSelected.geometry.coordinates
          ? zoneSelected.geometry.coordinates[0].map((coord) => {
              return { lat: coord[1], lng: coord[0] };
            })
          : zoneSelected.geometry.geometries[1]
          ? zoneSelected.geometry.geometries[1].coordinates[0].map((coord) => {
              return { lat: coord[1], lng: coord[0] };
            })
          : [];

        setCoords(arrayCoord);
        const bounds = new window.google.maps.LatLngBounds();
        arrayCoord.forEach((location) => {
          bounds.extend(
            new window.google.maps.LatLng(location.lat, location.lng)
          );
        });
        if (mapRef != null) {
          mapRef.fitBounds(bounds);
        }
      }

      reduxGetNeighborhoodsByZone({
        idZone: zone.current,
      });
    }
  }, [zone.current]);

  useEffect(() => {
    if (neighborhoodsByZone && Object.keys(neighborhoodsByZone).length > 0) {
      setOptionsMultiselect(
        neighborhoodsByZone.map((element, key) => {
          return element;
        })
      );
    }
  }, [neighborhoodsByZone]);

  useEffect(() => {
    if (city.current != "" && city.current != undefined) {
      reduxGetZonesByCity({
        idCity: city.current,
      });
    }
  }, [city.current]);

  const multiselectRef = useRef({});
  multiselectRef.current = watch("multiselectRef", []);

  const onSelect = (selectedList, selectedItem) => {
    setValue("multiselectRef", selectedList);
  };

  const onRemove = (selectedList, removedItem) => {
    setValue("multiselectRef", selectedList);
  };

  const renderMap = () => {
    return (
      <div className="container-fluid p-0 overflow-hidden">
        <div className="row no-gutters">
          <div className={"col-md-12"}>
            <GoogleMap
              onLoad={loadHandler}
              center={center}
              zoom={zoom}
              mapContainerStyle={{
                height: "500px",
                width: "100%",
              }}
            >
              {coords.length > 0 && (
                <Polygon
                  paths={coords}
                  options={{
                    strokeColor: properties ? properties.stroke : "#2A2A57",
                    strokeWeight: properties ? properties["stroke-width"] : 2,
                    strokeOpacity: properties
                      ? properties["stroke-opacity"]
                      : 0.8,
                    fillColor: properties ? properties.fill : "#000",
                    fillOpacity: properties ? properties["fill-opacity"] : 0.35,
                  }}
                />
              )}
            </GoogleMap>
          </div>
        </div>
      </div>
    );
  };

  const loadHandler = (map) => {
    setMapRef(map);
  };

  return (
    <div>
      <div id="wrapper">
        <Sidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Topbar />
            <div className="container-fluid">
              <div className="d-sm-flex align-items-center mb-1">
                <h1 className="h3 mb-0 text-gray-800 mr-3">Zona - Barrio</h1>
                <Show when="feature:create-zoneneighborhood">
                  <button
                    className="btn btn-primary btn-circle"
                    type="button"
                    onClick={handleShow}
                  >
                    <i className="fas fa-plus fa-sm"></i>
                  </button>
                </Show>
              </div>
              <p className="mb-4">Módulo de Administración de zona - barrio</p>
              <div className="card shadow mb-4">
                <div className="card-header py-3">
                  <h6 className="m-0 font-weight-bold text-primary">
                    Listado de Zonas - Barrios
                  </h6>
                </div>
                <div className="card-body">
                  <Grid
                    cols={columns}
                    data={
                      zoneNeighborhoods &&
                      Object.keys(zoneNeighborhoods).length > 0
                        ? zoneNeighborhoods.items
                        : []
                    }
                    page={
                      zoneNeighborhoods &&
                      Object.keys(zoneNeighborhoods).length > 0
                        ? Number(zoneNeighborhoods.page)
                        : currentPage
                    }
                    pages={
                      zoneNeighborhoods &&
                      Object.keys(zoneNeighborhoods).length > 0
                        ? Number(zoneNeighborhoods.totalPages)
                        : 0
                    }
                    total={
                      zoneNeighborhoods &&
                      zoneNeighborhoods.hasOwnProperty("total")
                        ? zoneNeighborhoods.total
                        : 0
                    }
                    offset={offset}
                    onChangePage={(page) => setCurrentPage(page)}
                    onChangeRange={(value) => {
                      setOffset(value);
                      reduxGetZoneNeighborhoods({
                        page: 1,
                        offset: value,
                        search: search,
                      });
                    }}
                    defaultValue={search}
                    onChangeSearch={(value) => {
                      setSearch(value);
                      reduxGetZoneNeighborhoods({
                        page: 1,
                        offset: offset,
                        search: value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Modal
          show={show}
          onHide={handleClose}
          onEscapeKeyDown={handleClose}
          backdrop="static"
          keyboard={true}
          size={rowEdited == undefined || !rowEdited ? "xl" : "lg"}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <Modal.Header closeButton>
              <Modal.Title>Zona - Barrio</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-group">
                <label htmlFor="city" className="form-label">
                  Ciudad
                </label>               
                
                <Select
                  isClearable={true}
                  {...register('idCity')}
                  id='idCity'
                  onChange={(value) => setValue('idCity', value?.value || '')}
                  options=
                  {cities &&
                    Object.keys(cities).length > 0 &&
                    cities.items
                      .map((ele) => ({                                    
                          value: ele.idCity,
                          label: ele.description,
                        }))
                  }
                />
              </div>
              <div className="form-group">
                <label htmlFor="idZone" className="form-label">
                  Zona
                </label>               
                <Select
                  isClearable={true}
                  {...register("idZone", { required: true })}
                  id='idZone'
                  onChange={(value) => setValue('idZone', value?.value || '')}
                  options=
                  {zonesByCity &&
                    Object.keys(zonesByCity).length > 0 &&
                    zonesByCity
                      .map((ele) => ({                                    
                          value: ele.idZone,
                          label: ele.description,
                        }))
                  }
                />
                {errors.idZone && (
                  <span className="invalid-feedback">La zona es requerida</span>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="idNeighborhood" className="form-label">
                  Barrio
                </label>
                {(rowEdited == undefined || !rowEdited) && (
                  <Multiselect
                    options={optionsMultiselect}
                    {...register("multiselectRef", { required: true })}
                    onSelect={onSelect}
                    onRemove={onRemove}
                    displayValue="description"
                    showCheckbox={true}
                    hidePlaceholder={true}
                    selectedValues={itemsSelected}
                  />
                )}
                {rowEdited && (
                  <select
                    {...register("idNeighborhood", { required: true })}
                    id="idNeighborhood"
                    className={`custom-select ${
                      errors.idNeighborhood && "is-invalid"
                    }`}
                  >
                    <option value={""}>Seleccionar…</option>
                    {neighborhoodsByZone &&
                      Object.keys(neighborhoodsByZone).length > 0 &&
                      neighborhoodsByZone.map((ele, key) => (
                        <option key={key} value={ele.idNeighborhood}>
                          {ele.description}
                        </option>
                      ))}
                  </select>
                )}
                {rowEdited && errors.idNeighborhood && (
                  <span className="invalid-feedback">
                    El barrio es requerido
                  </span>
                )}
                {(rowEdited == undefined || !rowEdited) && (
                  <div className="card-body">
                    {isLoaded ? renderMap() : null}
                  </div>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cancelar
              </Button>
              <Button variant="primary" type="submit">
                Guardar
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    neighborhoodsByZone: state.neighborhoodState.neighborhoodsByZone,
    zonesByCity: state.zoneState.zonesByCity,
    zoneNeighborhoods: state.zoneNeighborhoodState.zoneNeighborhoods,
    zoneNeighborhood: state.zoneNeighborhoodState.zoneNeighborhood,
    rowEdited: state.zoneNeighborhoodState.rowEdited,
    rowDeleted: state.zoneNeighborhoodState.rowDeleted,
    rowUpdated: state.zoneNeighborhoodState.rowUpdated,
    cities: state.cityState.cities,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    reduxGetZonesByCity: (payload) =>
      dispatch({
        type: "FETCHBYCITY_ZONE_REQUEST",
        value: payload,
      }),
    reduxGetNeighborhoodsByZone: (payload) =>
      dispatch({
        type: "READBYZONE_NEIGHBORHOOD_REQUEST",
        value: payload,
      }),
    reduxGetZoneNeighborhoods: (payload) =>
      dispatch({
        type: "FETCH_ZONENEIGHBORHOODS_REQUEST",
        value: payload,
      }),
    reduxPostZoneNeighborhood: (payload) =>
      dispatch({
        type: "CREATE_ZONENEIGHBORHOOD_REQUEST",
        value: payload,
      }),
    reduxGetZoneNeighborhood: (payload) =>
      dispatch({
        type: "READ_ZONENEIGHBORHOOD_REQUEST",
        value: payload,
      }),
    reduxDeleteZoneNeighborhood: (payload) =>
      dispatch({
        type: "DELETE_ZONENEIGHBORHOOD_REQUEST",
        value: payload,
      }),
    reduxPatchZoneNeighborhood: (payload) =>
      dispatch({
        type: "UPDATE_ZONENEIGHBORHOOD_REQUEST",
        value: payload,
      }),
    reduxResetZoneNeighborhoodForm: () =>
      dispatch({
        type: "RESET_ZONENEIGHBORHOOD_FORM",
      }),
    reduxGetCities: (payload) =>
      dispatch({
        type: "FETCH_CITIES_REQUEST",
        value: payload,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ZoneNeighborhood);
