import React, { useState, useEffect, useMemo } from 'react';
import DataTable from 'react-data-table-component';
import axios from 'axios';

const paginationComponentOptions = {
  rowsPerPageText: 'Filas por página:',
  rangeSeparatorText: 'de',
  selectAllRowsItem: true,
  selectAllRowsItemText: 'Todos',
  selectAllRowsItemText: 'Todos',
  firstPageText: 'Primera',
  previousPageText: 'Anterior',
  nextPageText: 'Siguiente',
  lastPageText: 'Última',
  noRowsPerPage: false,
  paginationIconFirstPage: () => <span>&laquo;</span>,
  paginationIconLastPage: () => <span>&raquo;</span>,
  paginationIconPrevious: () => <span>&lsaquo;</span>,
  paginationIconNext: () => <span>&rsaquo;</span>,
};

export const LabelDownloadHistory = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);


  const columns = useMemo(()=>(
    [
      {
        name: 'ID',
        selector: (row) => row._id,
        sortable: true,
      },
      {
        name: 'Usuario',
        selector: (row) => row._userName,
        sortable: true,
      },
      {
        name: 'Fecha de Descarga',
        selector: (row) => row._downloadDate,
        sortable: true,
        format: (row) => new Date(row._downloadDate).toLocaleString(),
      },
      {
        name: 'Órdenes Exitosas',
        selector: (row) => row._successfulOrders,
        sortable: true,
      },
      {
        name: 'Órdenes Fallidas',
        selector: (row) => row._failedOrders,
        sortable: true,
      },
      {
        name: 'Enlace de Descarga',
        cell: (row) => (
          <a href={row._downloadLink} target="_blank" rel="noopener noreferrer">
            Descargar
          </a>
        ),
      },
    ]
  ))

  const fetchLabelDownloads = async (page) => {
    setLoading(true);
    const token = window.localStorage.getItem("token");
    try {
      const response = await axios.get(`${process.env.REACT_APP_ASSIGN_URL}/api/v1/wms/order/label/downloadHistoric`, {
        params: { page, perPage },
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        }
      });
      console.log(response.data)
      setData(response.data.data);
      setTotalRows(response.data.total);
    } catch (error) {
      console.error('Error fetching label downloads:', error);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (page) => {
    fetchLabelDownloads(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    const token = window.localStorage.getItem("token");
    try {
      const response = await axios.get(`${process.env.REACT_APP_ASSIGN_URL}/api/v1/wms/order/label/downloadHistoric`, {
        params: { page, perPage: newPerPage },
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        }
      });
      setData(response.data.data);
      setPerPage(newPerPage);
    } catch (error) {
      console.error('Error fetching label downloads:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLabelDownloads(1); 
  }, []);

  return (
    <DataTable
      title="Histórico de Descargas de Etiquetas"
      columns={columns}
      data={data}
      progressPending={loading}
      noDataComponent={<h6>No hay datos para listar</h6>}
      pagination
      paginationServer
      paginationTotalRows={totalRows}
      onChangeRowsPerPage={handlePerRowsChange}
      paginationComponentOptions={paginationComponentOptions}
      onChangePage={handlePageChange}
      paginationRowsPerPageOptions={[10, 25, 50, 100]}
    />
  );

}