import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Sidebar from "../../components/Navigation/Sidebar";
import Topbar from "../../components/Navigation/Topbar";
import Grid from "../../components/Grid";
import { connect } from "react-redux";
import { Button, Modal } from "react-bootstrap";
import { Show } from "../../hooks/Show";
import { useAuth } from "../../hooks/useAuth";
import "react-datepicker/dist/react-datepicker.css";

const Category = ({
  reduxGetCategories,
  categories,
  reduxGetCategory,
  category,
  reduxPostCategory,
  rowEdited,
  reduxDeleteCategory,
  rowDeleted,
  reduxPatchCategory,
  rowUpdated,
  reduxResetCategoryForm,
  reduxGetCompanies,
  companies,
  reduxDeleteSoftCategory,
  rowDeletedSoft,
}) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
    setValue,
  } = useForm();
  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(10);
  const { session } = useAuth();
  const [show, setShow] = useState(false);

  const handleClose = () => {
    reduxResetCategoryForm();
    setShow(false);
  };
  const handleShow = () => {
    reduxResetCategoryForm();
    reset();
    setShow(true);
  };
  const [search, setSearch] = useState("");

  const columns = [
    {
      title: "#",
      render: (rowData) => {
        return <span>{rowData.idCategory}</span>;
      },
      field: 'idCategory',
      searchable: true
    },
    {
      title: "Nombre",
      render: (rowData) => {
        return <span>{rowData.name}</span>;
      },
      field: 'name',
      searchable: true
    },
    {
      title: "Descripción",
      render: (rowData) => {
        return <span>{rowData.description}</span>;
      },
      field: 'description',
      searchable: true
    },
    {
      title: "Acciones",
      render: (rowData) => {
        return (
          <>
            {rowData.isActive ? (
              <>
                <Show when="feature:edit-category">
                  <button
                    title="Editar"
                    className="btn btn-primary btn-sm  btn-circle mr-2"
                    type="button"
                    onClick={(e) => handleOpen(rowData)}
                  >
                    <i className="fas fa-edit fa-xs"></i>
                  </button>
                </Show>
                <Show when="feature:disabled-category">
                  <button
                    title="Desactivar"
                    className="btn btn-danger btn-sm btn-circle mr-2"
                    type="button"
                    onClick={(e) => handleDelete(rowData)}
                  >
                    <i className="fas fa-times-circle fa-xs"></i>
                  </button>
                </Show>
                <Show when="feature:delete-category">
                  <button
                    title="Eliminar"
                    className="btn btn-danger btn-sm btn-circle"
                    type="button"
                    onClick={(e) => handleDeleteSoft(rowData)}
                  >
                    <i className="fas fa-trash-alt fa-xs"></i>
                  </button>
                </Show>
              </>
            ) : (
              <Show when="feature:enabled-category">
                <button
                  title="Activar"
                  className="btn btn-primary btn-sm  btn-circle mr-2"
                  type="button"
                  onClick={(e) => handleActive(rowData)}
                >
                  <i className="fas fa-check-circle fa-xs"></i>
                </button>
              </Show>
            )}
          </>
        );
      },
    },
  ];

  const handleOpen = (row) => {
    reduxGetCategory({
      id: row.idCategory,
    });
  };

  const handleActive = (row) => {
    if (!row.isActive) {
      reduxDeleteCategory(row);
    }
  };

  useEffect(() => {
    if (rowEdited) {
      setShow(true);
      setValue("name", rowEdited.name);
      setValue("description", rowEdited.description);
      setValue("thumbnail", rowEdited.thumbnail);
      setValue("idCompany", rowEdited.idCompany);
    }
  }, [rowEdited]);

  const handleDelete = (row) => {
    reduxDeleteCategory(row);
  };

  useEffect(() => {
    reduxGetCategories({
      page: 1,
      offset: offset,
      search: "",
    });
    reduxGetCompanies({
      page: 1,
      offset: 10000,
      search: "",
      isActive: true,
    });
  }, []);

  useEffect(() => {
    reduxGetCategories({
      page: currentPage,
      offset: offset,
      search: search,
    });
  }, [currentPage]);

  const onSubmit = (data) => {
    if (rowEdited) {
      reduxPatchCategory({ ...data, id: rowEdited.idCategory });
    } else {
      reduxPostCategory(data);
    }
    reset();
    reduxResetCategoryForm();
    setShow(false);
  };

  useEffect(() => {
    if (category || rowUpdated || rowDeleted || rowDeletedSoft) {
      if (
        (category && category.message == undefined) ||
        (rowUpdated && rowUpdated.message == undefined) ||
        (rowDeletedSoft && rowDeletedSoft.message == undefined) ||
        rowDeleted
      ) {
        reduxGetCategories({
          page: currentPage,
          offset: offset,
          search: search,
        });
        reduxResetCategoryForm();
      }
    }
  }, [category, rowUpdated, rowDeleted, rowDeletedSoft]);

  const handleDeleteSoft = (row) => {
    reduxDeleteSoftCategory(row);
  };

  return (
    <div>
      <div id="wrapper">
        <Sidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Topbar />
            <div className="container-fluid">
              <div className="d-sm-flex align-items-center mb-1">
                <h1 className="h3 mb-0 text-gray-800 mr-3">Categorías</h1>
                <Show when="feature:create-category">
                  <button
                    className="btn btn-primary btn-circle"
                    type="button"
                    onClick={handleShow}
                  >
                    <i className="fas fa-plus fa-sm"></i>
                  </button>
                </Show>
              </div>
              <p className="mb-4">Módulo de Administración de categorías</p>
              <div className="card shadow mb-4">
                <div className="card-header py-3">
                  <h6 className="m-0 font-weight-bold text-primary">
                    Listado de Categorías
                  </h6>
                </div>
                <div className="card-body">
                  <Grid
                    cols={columns}
                    data={
                      categories && Object.keys(categories).length > 0
                        ? categories.items
                        : []
                    }
                    page={
                      categories && Object.keys(categories).length > 0
                        ? Number(categories.page)
                        : currentPage
                    }
                    pages={
                      categories && Object.keys(categories).length > 0
                        ? Number(categories.totalPages)
                        : 0
                    }
                    total={
                      categories && categories.hasOwnProperty("total")
                        ? categories.total
                        : 0
                    }
                    offset={offset}
                    onChangePage={(page) => setCurrentPage(page)}
                    onChangeRange={(value) => {
                      setOffset(value);
                      reduxGetCategories({
                        page: 1,
                        offset: value,
                        search: search,
                      });
                    }}
                    defaultValue={search}
                    onChangeSearch={(value) => {
                      setSearch(value);
                      reduxGetCategories({
                        page: 1,
                        offset: offset,
                        search: value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Modal
          size="ml"
          show={show}
          onHide={handleClose}
          onEscapeKeyDown={handleClose}
          backdrop="static"
          keyboard={true}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <Modal.Header closeButton>
              <Modal.Title>
                {rowEdited ? "Editar" : "Nueva"} Categoría
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-group">
                <label htmlFor="name" className="form-label">
                  Nombre *
                </label>
                <input
                  id="name"
                  type="text"
                  className={`form-control form-control-user ${
                    errors.name && "is-invalid"
                  }`}
                  {...register("name", { required: true })}
                />
                {errors.name && (
                  <span className="invalid-feedback">
                    El nombre es requerido
                  </span>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="description" className="form-label">
                  Descripción
                </label>
                <input
                  id="description"
                  type="text"
                  className={`form-control form-control-user ${
                    errors.description && "is-invalid"
                  }`}
                  {...register("description")}
                />
              </div>
              {session && session.role.idCompany == null && (
                <div className="form-group">
                  <label htmlFor="idCompany" className="form-label">
                    Empresa *
                  </label>
                  <select
                    {...register("idCompany", {
                      required: true,
                    })}
                    id="idCompany"
                    className={`custom-select ${
                      errors.idCompany && "is-invalid"
                    }`}
                  >
                    <option value={""}>Seleccionar…</option>
                    {companies &&
                      Object.keys(companies).length > 0 &&
                      companies.items
                        .filter((f) => f.isActive == true)
                        .map((ele, key) => (
                          <option key={key} value={ele.idCompany}>
                            {ele.description}
                          </option>
                        ))}
                  </select>
                  {errors.idCompany && (
                    <span className="invalid-feedback">
                      La empresa es requerida
                    </span>
                  )}
                </div>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cancelar
              </Button>
              <Button variant="primary" type="submit">
                Guardar
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    categories: state.categoryState.categories,
    category: state.categoryState.category,
    rowEdited: state.categoryState.rowEdited,
    rowDeleted: state.categoryState.rowDeleted,
    rowUpdated: state.categoryState.rowUpdated,
    companies: state.companyState.companies,
    rowDeletedSoft: state.categoryState.rowDeletedSoft,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    reduxGetCategories: (payload) =>
      dispatch({
        type: "FETCH_CATEGORIES_REQUEST",
        value: payload,
      }),
    reduxPostCategory: (payload) =>
      dispatch({
        type: "CREATE_CATEGORY_REQUEST",
        value: payload,
      }),
    reduxGetCategory: (payload) =>
      dispatch({
        type: "READ_CATEGORY_REQUEST",
        value: payload,
      }),
    reduxDeleteCategory: (payload) =>
      dispatch({
        type: "DELETE_CATEGORY_REQUEST",
        value: payload,
      }),
    reduxPatchCategory: (payload) =>
      dispatch({
        type: "UPDATE_CATEGORY_REQUEST",
        value: payload,
      }),
    reduxResetCategoryForm: () =>
      dispatch({
        type: "RESET_CATEGORY_FORM",
      }),
    reduxGetCompanies: (payload) =>
      dispatch({
        type: "FETCH_COMPANIES_REQUEST",
        value: payload,
      }),
    reduxDeleteSoftCategory: (payload) =>
      dispatch({
        type: "DELETE_SOFT_CATEGORY_REQUEST",
        value: payload,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Category);
