import React, { useState, useEffect, forwardRef } from "react";
import { Button, Form, FormControl } from "react-bootstrap";
import Feedback from "react-bootstrap/Feedback";
import { connect } from "react-redux";
import Select from "react-select";
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import moment from "moment-timezone";
import { AddressStatus, AddressStatusLabel } from "../../utils/enumsUtil";

const perpage = {
  page: 1,
  offset: 100000,
  search: "",
  isActive: true,
};

const FilterFormComponent = ({
  onSubmit,
  reduxGetCities,
  reduxGetCouriers,
  cities,
  couriers,
}) => {
  const formState = useForm({
    defaultValues: {
      endDate: "",
      startDate: "",
    },
  });
  const {
    formState: { errors: errorRange },
  } = formState;
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const changeInput = (el) => {
    formState.setValue(el.currentTarget.name, el.currentTarget.value);
  };

  const changeStartDate = (date) => {
    setStartDate(date);
    formState.setValue("startDate", moment(date).tz("America/Bogota").format("YYYY-MM-DD"));
  };

  const changeEndDate = (date) => {
    setEndDate(date);
    formState.setValue("endDate", moment(date).tz("America/Bogota").format("YYYY-MM-DD"));
  };

  const handleChangeCourier = (data) => {
    formState.setValue("courier", data);
  };

  const DatePickerCustomFrom = forwardRef(
    ({ value, onClick, onChange }, ref) => (
      <>
        <input
          className={`form-control form-control-user ${
            formState.formState.errors.startDate && "is-invalid"
          }`}
          onClick={onClick}
          onChange={onChange}
          ref={ref}
          value={value}
          autoComplete="Off"
          {...formState.register("startDate", { required: true })}
        />
        {/*  {formState.formState.errors.startDate && (
                    <span className="invalid-feedback">Fecha inicial es requerida</span>
                )}*/}
      </>
    )
  );
  const DatePickerCustomTo = forwardRef(({ value, onClick, onChange }, ref) => (
    <>
      <input
        className={`form-control form-control-user ${
          formState.formState.errors.endDate && "is-invalid"
        }`}
        onClick={onClick}
        onChange={onChange}
        ref={ref}
        value={value}
        autoComplete="Off"
        {...formState.register("endDate", { required: true })}
      />
      {/* {formState.formState.errors.endDate && (
                <span className="invalid-feedback">Fecha inicial es requerida</span>
            )}*/}
    </>
  ));

  useEffect(() => {
    reduxGetCities(perpage);
    reduxGetCouriers(perpage);
    setStartDate(null);
    setEndDate(null);
  }, []);

  return (
    <>
      <form
        onSubmit={formState.handleSubmit(onSubmit)}
        className={"form-row align-items-end"}
      >
        <Form.Group className="mb-0 col-3" controlId="courier">
          <Form.Label>
            Mensajero <span className={"text-danger"}>*</span>
          </Form.Label>
          <Select
            isClearable={true}
            id="courier"
            name="courier"
            onMenuOpen={() => null}
            onMenuClose={() => null}
            styles={{
              control: (provided) => ({
                ...provided,
                borderColor: errorRange.courier ? "red" : "",
              }),
            }}
            className={`${errorRange.courier && "is-invalid"}`}
            {...formState.register("courier", { required: true })}
            onChange={(data) => handleChangeCourier(data?.value)}
            options={
              couriers && Object.keys(couriers).length > 0
                ? couriers.map((element) => {
                    return {
                      value: element.idUser,
                      label:
                        element.nameHeadquarters != null
                          ? `${element.nameHeadquarters} - ${element.firstName} ${element.lastName}`
                          : `${element.firstName} ${element.lastName}`,
                    };
                  })
                : []
            }
          />
        </Form.Group>
        <Form.Group className="mb-0 col-2" controlId="startDate">
          <Form.Label>
            Fecha inicial <span className={"text-danger"}>*</span>
          </Form.Label>
          <div>
            <DatePicker
              selected={startDate}
              onChange={(date) => changeStartDate(date)}
              dateFormat="yyyy-MM-dd"
              customInput={<DatePickerCustomFrom />}
              maxDate={new Date()}
            />
          </div>
        </Form.Group>
        <Form.Group className="mb-0 col-2" controlId="endDate">
          <Form.Label>
            Fecha final <span className={"text-danger"}>*</span>
          </Form.Label>
          <div>
            <DatePicker
              selected={endDate}
              onChange={(date) => changeEndDate(date)}
              dateFormat="yyyy-MM-dd"
              customInput={<DatePickerCustomTo />}
              maxDate={new Date()}
            />
          </div>
        </Form.Group>
        <Form.Group className="mb-0 col-2" controlId="city">
          <Form.Label>Ciudad</Form.Label>
          
          <Select
            isClearable={true}
            id="city"
            name="city"
            onMenuOpen={() => null}
            onMenuClose={() => null}
            styles={{
              control: (provided) => ({
                ...provided,
                borderColor: errorRange.city ? "red" : "",
              }),
            }}
            className={` ${errorRange.city && "is-invalid"}`}
            {...formState.register("city", { required: false })}
            onChange={(data) => formState.setValue("city", data?.value && data?.value)}
            options={
              cities && Object.keys(cities).length > 0
                ? cities.items.map((element) => {
                    return {
                      value: element.idCity,
                      label: element.description,
                    };
                  })
                : []
            }
          />
        </Form.Group>
        <Form.Group className="mb-0 col-1 align-content-end" controlId="city">
          <Button type="submit">Buscar</Button>
        </Form.Group>
      </form>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    cities: state.cityState.cities,
    couriers: state.userState.couriers,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    reduxGetCities: (payload) =>
      dispatch({
        type: "FETCH_CITIES_REQUEST",
        value: payload,
      }),
    reduxGetCouriers: (payload) =>
      dispatch({
        type: "FETCH_COURIERS_REQUEST",
        value: payload,
      }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterFormComponent);
