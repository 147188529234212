import React, { useEffect, useState } from "react";
import { Button, Modal, Form, Spinner } from "react-bootstrap";
import Select from 'react-select'

export const CityModal = ({
    show,
    city,
    editMode,
    submit,
    onHide,
    departments,
    postsubmit
}) => {
    const [codigo, setCodigo] = useState("");
    const [description, setDescription] = useState("");
    const [idDepartment, setIdDepartment] = useState("0");
    const [currentDepartment, setCurrentDepartment] = useState("")

    const [isLoading, setIsLoading] = useState(false);
    const loadData = async () => {
        try {
            setIsLoading(true);
            if (city) {
                resetForm();
                setCodigo(city.code);
                setDescription(city.description);
                setIdDepartment(city.idDepartment);
            } else {
                resetForm();
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    const filterDepartment = (idDepartment) => {
        if (idDepartment && idDepartment !== null) {
            if (departments && departments !== undefined && idDepartment && idDepartment !== undefined) {
                const [department] = departments.filter((f) => f.idDepartment === idDepartment)

                if (department) {
                    setCurrentDepartment({ value: department.idDepartment, label: department.description })
                }
            }
        } else {
            setCurrentDepartment({ value: '0', label: 'Seleccionar' })
        }
    }

    useEffect(async () => {
        filterDepartment(idDepartment)
    }, [idDepartment]);

    useEffect(async () => {
        await loadData();
    }, [city, editMode]);

    const resetForm = () => {
        setCodigo("");
        setDescription("");
        setIdDepartment("");
    };

    const closeModal = () => {
        resetForm();
        onHide();
    };

    const submitWithLoadingAndCloseModal = async (event, currentDepartment) => {
        return new Promise(async (resolve, reject) => {
            setIsLoading(true);
            try {
                await submit(event, currentDepartment);
                setIsLoading(false);
                closeModal();
                resolve();
            } catch (error) {
                reject(error);
            }
        });
    };

    return (
        <div>
            <Modal
                show={show}
                onHide={closeModal}
                onEscapeKeyDown={onHide}
                backdrop="static"
                keyboard={true}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {editMode ? "Editar" : "Nuevo"} Ciudad
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Form
                        onSubmit={async event => {
                            try {
                                await submitWithLoadingAndCloseModal(
                                    event,
                                    currentDepartment.value
                                );
                                await postsubmit();
                            } catch (e) {
                                console.log(e);
                            }
                        }}
                    >
                        <Form.Group>
                            <Form.Control
                            name="idCity"
                            value={city && city.idCity}
                            type="hidden"
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>
                                Código
                            </Form.Label>
                            <Form.Control
                                id="code"
                                type="text"
                                className={`form-control form-control-user`}
                                value={codigo}
                                onChange={(e) => setCodigo(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>
                                Descripción
                            </Form.Label>
                            <input
                                id="description"
                                type="text"
                                className={`form-control form-control-user`}
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>
                                Departamento
                            </Form.Label>
                            <Select
                                id="idDepartment"
                                value={currentDepartment}
                                onChange={(value) => setIdDepartment(value?.value || '')}
                                options={
                                    departments &&
                                        Object.keys(departments).length > 0 ?
                                        departments.map((ele) => ({
                                            value: ele.idDepartment,
                                            label: ele.description,
                                        }))
                                        : []
                                }
                            />
                        </Form.Group>

                        <Modal.Footer>
                            <Button variant="secondary" onClick={closeModal}>
                                Cancelar
                            </Button>
                            <Button variant="primary" type="submit">
                                {editMode ? "Guardar cambios" : "Registrar"}
                                {!isLoading
                                    ? ""
                                    : <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />}
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal.Body>
            </Modal>
        </div>
    );
};
