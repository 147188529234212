import { useEffect } from "react";
import { connect } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { Button, Form, Modal } from "react-bootstrap";
import Select from 'react-select';
import Swal from "sweetalert2";

const CreateOrigin = ({
    show,
    originInfo,
    handleClose,
    reduxGetBranches,
    branches,
    reduxGetDepartmentsByCountry,
    departmentsByCountry,
    reduxSaveOrigin,
    successfulSaveOrigins
}) => {
    const {
        handleSubmit: saveModalSumbit,
        control: createOriginModalControl,
        formState: { errors: createOriginModalErrors },
        reset: resetUploadModal,
    } = useForm({
        defaultValues: {
            description: null,
            originDepartment: null,
            branch: null,
        }
    });

    useEffect(() => {
        resetUploadModal();
    }, [])

    useEffect(() => {
        if (!originInfo) return;
        reduxGetBranches({
            page: 1,
            offset: 1000,
            search: `idCountry|${Number(originInfo.idCountry)}`,
            isActive: true
        });
        reduxGetDepartmentsByCountry({
            idCountry: Number(originInfo.idCountry),
        });
    }, [show])

    useEffect(() => {
        if (successfulSaveOrigins === true) {
            Swal.fire(
                'Origen Creado','',
                'info'
            );
            resetUploadModal()
            handleClose();
        }
    }, [successfulSaveOrigins]);

    const saveOrigin = (data) => {
        Swal.fire({
            html: `¿Está seguro de crear el origen?`,
            icon: 'question',
            showConfirmButton: true,
            confirmButtonText: "OK",
            showCancelButton: true,
            cancelButtonText: "Cancelar",
        }).then(result => {
            if (!result.isConfirmed) return;

            reduxSaveOrigin({
                description: data.description,
                idDepartment: data.originDepartment.value,
                idBranch: data.branch.value,
                idCountry: originInfo.idCountry,
            });

            resetUploadModal();
            handleCloseModal();
        })
    }

    const handleCloseModal = () => {
        resetUploadModal()
        handleClose();
    }

    return (
        <>
            <Modal
                show={show}
                onHide={handleCloseModal}
                onEscapeKeyDown={handleCloseModal}
                backdrop="static"
                keyboard={false}
                size="lg"
            >
                <form onSubmit={saveModalSumbit(saveOrigin)}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <div className="d-flex justify-content-between align-items-center">
                                Crear Origen
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="form-group col-md-12">
                                <label htmlFor="description" className="form-label">
                                    Origen *
                                </label>
                                <Controller
                                    control={createOriginModalControl}
                                    name="description"
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'El origen es requerido'
                                        },
                                    }}
                                    render={({ field }) => (
                                        <>
                                            <Form.Control
                                                className={`form-control`}
                                                {...field}
                                            />
                                            {
                                                createOriginModalErrors.description &&
                                                <span className="error-message">{createOriginModalErrors.description.message}</span>
                                            }
                                        </>
                                    )}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className='form-group col-md-12'>
                                <label
                                    htmlFor='originDepartment'
                                    className='form-label'>
                                    Departamento *
                                </label>
                                <Controller
                                    control={createOriginModalControl}
                                    name="originDepartment"
                                    rules={{
                                        required: 'El departamento es obligatorio'
                                    }}
                                    render={({ field }) => (
                                        <>
                                            <Select
                                                {...field}
                                                isClearable
                                                options={
                                                    departmentsByCountry &&
                                                    Object.keys(departmentsByCountry).length > 0 &&
                                                    departmentsByCountry
                                                      .map((ele, key) => ({
                                                        value: ele.idDepartment,
                                                        label: ele.description,
                                                    }))
                                                }
                                            />
                                            {
                                                createOriginModalErrors.originDepartment &&
                                                <span className="error-message">
                                                    {createOriginModalErrors.originDepartment.message}
                                                </span>
                                            }
                                        </>
                                    )}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className='form-group col-md-12'>
                                <label
                                    htmlFor='branch'
                                    className='form-label'>
                                    Bodega *
                                </label>
                                <Controller
                                    control={createOriginModalControl}
                                    name="branch"
                                    rules={{
                                        required: 'La bodega es obligatoria'
                                    }}
                                    render={({ field }) => (
                                        <>
                                            <Select
                                                {...field}
                                                isClearable
                                                options={
                                                    branches &&
                                                    Object.keys(branches).length > 0 &&
                                                    branches.items
                                                      .map((ele, key) => ({
                                                        value: ele.idBranch,
                                                        label: ele.branchName,
                                                    }))
                                                }
                                            />
                                            {
                                                createOriginModalErrors.branch &&
                                                <span className="error-message">
                                                    {createOriginModalErrors.branch.message}
                                                </span>
                                            }
                                        </>
                                    )}
                                />
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseModal}>
                            Cancelar
                        </Button>
                        <Button variant="primary" type="submit">
                            Guardar
                        </Button>
                    </Modal.Footer>
                </form >
            </Modal>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        departmentsByCountry: state.departmentState.departmentsByCountry,
        branches: state.branchState.branches,
        successfulSaveOrigins: state.originState.successfulSaveOrigins
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        reduxGetBranches: (payload) =>
            dispatch({
                type: 'FETCH_BRANCHES_REQUEST',
                value: payload,
            }),
        reduxGetDepartmentsByCountry: (payload) =>
            dispatch({
                type: 'READBYCOUNTRY_DEPARTMENT_REQUEST',
                value: payload,
            }),
        reduxSaveOrigin: (payload) =>
            dispatch({
                type: 'FETCH_SAVE_ORIGINS_REQUEST',
                value: payload,
            }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateOrigin);