import {call, put, takeLatest} from "redux-saga/effects";
import request from "../../utils/request";

function* getAllLots(payload) {
  try {
    yield put({
      type: "FETCH_LOTS_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/lots?page=${payload.value.page}&search=${payload.value.search}&offset=${payload.value.offset}${payload.value.isActive ? '&isActive=' + payload.value.isActive: '' }`;
    const headers = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "HIDE_LOADING",
    });

    yield put({
      type: "FETCH_LOT_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed load",
        message: "Failed load lots",
      },
    });
    yield put({
      type: "FETCH_LOT_ERROR",
    });
  }
}

function* postLot(payload) {
  try {
    yield put({ type: "CREATE_LOT_REQUESTING" });
    yield put({
      type: "SHOW_LOADING",
    });
    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/lots`;

    const headers = {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(payload.value),
    };

    const response = yield call(request, requestURL, headers);

    yield put({
      type: "HIDE_LOADING",
    });

    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "success",
        title: "Successful save",
        message: "Successful save lot",
      },
    });

    yield put({
      type: "CREATE_LOT_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed save",
        message: "Failed save lot",
      },
    });
    yield put({
      type: "CREATE_LOT_ERROR",
    });
  }
}

function* getLotById(payload) {
  try {
    yield put({
      type: "READ_LOT_REQUESTING",
    });
    yield put({
      type: "SHOW_LOADING",
    });
    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/lots/${payload.value.id}`;

    const headers = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "HIDE_LOADING",
    });

    yield put({
      type: "READ_LOT_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "READ_LOT_ERROR",
    });
  }
}

function* deleteLotById(payload) {
  try {
    yield put({
      type: "DELETE_LOT_REQUESTING",
    });
    yield put({
      type: "SHOW_LOADING",
    });
    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/lots/changeState/${payload.value.idLot}`;

    const headers = {
      method: "PATCH",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(payload.value),
    };

    const response = yield call(request, requestURL, headers);

    yield put({
      type: "HIDE_LOADING",
    });

    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "success",
        title: "Successful disable",
        message: "Successful disable lot",
      },
    });

    yield put({
      type: "DELETE_LOT_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed disable",
        message: "Failed disable lot",
      },
    });
    yield put({
      type: "DELETE_LOT_ERROR",
    });
  }
}

function* updateLot(payload) {
  try {
    yield put({
      type: "UPDATE_LOT_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/lots/${payload.value.id}`;

    const headers = {
      method: "PATCH",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(payload.value),
    };

    const response = yield call(request, requestURL, headers);

    yield put({
      type: "HIDE_LOADING",
    });

    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "success",
        title: "Successful update",
        message: "Successful update lot",
      },
    });

    yield put({
      type: "UPDATE_LOT_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed update",
        message: "Failed update lot",
      },
    });
    yield put({
      type: "UPDATE_LOT_ERROR",
    });
  }
}

function* deleteSoftLotById(payload) {
  try {
    yield put({
      type: "DELETE_SOFT_LOT_REQUESTING",
    });
    yield put({
      type: "SHOW_LOADING",
    });
    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/lots/deleteSoft/${payload.value.idLot}`;

    const headers = {
      method: "PATCH",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(payload.value),
    };

    const response = yield call(request, requestURL, headers);

    yield put({
      type: "DELETE_SOFT_LOT_SUCCESS",
      value: response,
    });
    yield put({
      type: "HIDE_LOADING",
    });

    if (response.message) {
      yield put({
        type: "SHOW_ALERT",
        value: {
          type: "danger",
          title: "Failed delete soft",
          message: response.message,
        },
      });
    } else {
      yield put({
        type: "SHOW_ALERT",
        value: {
          type: "success",
          title: "Successful delete soft",
          message: "Successful delete soft lot",
        },
      });
    }
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed delete soft",
        message: "Failed delete soft lot",
      },
    });
    yield put({
      type: "DELETE_SOFT_LOT_ERROR",
    });
  }
}

export function* watchLot() {
  yield takeLatest("FETCH_LOTS_REQUEST", getAllLots);
  yield takeLatest("CREATE_LOT_REQUEST", postLot);
  yield takeLatest("READ_LOT_REQUEST", getLotById);
  yield takeLatest("DELETE_LOT_REQUEST", deleteLotById);
  yield takeLatest("UPDATE_LOT_REQUEST", updateLot);
  yield takeLatest("DELETE_SOFT_LOT_REQUEST", deleteSoftLotById);
}
