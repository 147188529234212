import { buttonIcon } from "../../utils/buttonsUtil";

const CollapsedSection = ({ show = false, title, sectionKey, children, onToggle }) => {
    return (
        <>
            <div className="card">
                <div className="card-header p-2 d-flex flex-gap-1 align-items-center pointer"
                    onClick={onToggle}
                >
                    <i className={`${show ? buttonIcon.Arrow_AngleDown : buttonIcon.Arrow_AngleRight}`}></i>
                    <h6 className="m-0 p-0 font-weight-bold text-primary">
                        {title}
                    </h6>
                </div>
                <div className={`card card-body collapse ${show ? 'show' : ''} p-3`} id={sectionKey}>
                    {children}
                </div>
            </div>
        </>
    )
}

export default CollapsedSection;