import React, { useState, forwardRef, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import Grid from '../../../../Grid';
import Swal from 'sweetalert2';
import { connect } from "react-redux";
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

const ReturnsModal = forwardRef(({ 
  returnProducts, 
  scannedGuide,
  onOpenModal,
  idReturn,
  reduxConfirmReturn,
  reduxProcessReturn,
  reduxProcessReturnOrder,
  successfulProcessReturn,
  processReturnResult,
  successfulProcessReturnOrder,
  processReturnOrderResult,
  show,
  onHide,
  ...props 
}, ref) => {
  const [filesWatcher, setFilesWatcher] = useState([]);
  const [comments, setComments] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [localReturnProducts, setLocalReturnProducts] = useState([]);
  const [processedOrders, setProcessedOrders] = useState([]);
  const [scannedGuides, setScannedGuides] = useState([]);

  useEffect(() => {
    if (show) {
      reduxProcessReturn(idReturn);
    }
  }, [show, idReturn]);

  useEffect(() => {
    if (successfulProcessReturn && processReturnResult?.returnDetail) {
      processReturnResult.returnDetail.forEach(returnDetail => {
        const trackingOrReturn = returnDetail.returnTrackingId || returnDetail.trackingId;
        
        if (trackingOrReturn) {
          reduxProcessReturnOrder({
            idReturn: processReturnResult.idReturn,
            trackingOrReturn
          });
        }
      });
    }
  }, [successfulProcessReturn, processReturnResult]);

  useEffect(() => {
    if (successfulProcessReturnOrder && processReturnOrderResult?.returnDetail?.[0]) {
      const returnDetail = processReturnOrderResult.returnDetail[0];
      const tracking = returnDetail.order?.address?.returnTrackingId || 
                      returnDetail.order?.address?.trackingId;
      
      if (tracking) {
        const orderProducts = returnDetail.order?.orderDetails?.map(detail => ({
          tracking: tracking,
          numOrder: returnDetail.order.numOrder,
          product: detail.product.description,
          quantity: detail.quantity,
          idProduct: detail.product.idProduct,
          idAddress: returnDetail.order?.address?.idAddress
        })) || [];
        
        setProcessedOrders(prevOrders => {
          const existingOrderIndex = prevOrders.findIndex(
            order => order[0]?.numOrder === returnDetail.order.numOrder
          );
          
          if (existingOrderIndex >= 0) {
            const newOrders = [...prevOrders];
            newOrders[existingOrderIndex] = orderProducts;
            return newOrders;
          }
          
          return [...prevOrders, orderProducts];
        });
      }
    }
  }, [successfulProcessReturnOrder, processReturnOrderResult]);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Enter') {
        onOpenModal(true);
      }
    };

    window.addEventListener('keypress', handleKeyPress);

    return () => {
      window.removeEventListener('keypress', handleKeyPress);
    };
  }, [onOpenModal]);

  useEffect(() => {
    if (scannedGuide) {
        const guideExists = returnProducts.some(product => product.tracking === scannedGuide);
        if (guideExists && !scannedGuides.includes(scannedGuide)) {
            setScannedGuides([...scannedGuides, scannedGuide]);
        }
    }
  }, [scannedGuide, returnProducts]);

  const handleCloseModal = () => {
    onHide(false);
    setFilesWatcher([]);
  };

  const handleChangeFiles = (event) => {
    const newFiles = Array.from(event.target.files).filter(file => 
      file.type.startsWith('image/')
    );
    setFilesWatcher(prevFiles => [...prevFiles, ...newFiles]);

    if (newFiles.length > 0) {
      Swal.fire({
        title: 'Archivo Cargado',
        text: 'Su archivo ha sido cargado exitosamente.',
        icon: 'success',
        confirmButtonText: 'Aceptar'
      });
    }
  };

  const handleRemoveFile = (index) => {
    setFilesWatcher(prevFiles => prevFiles.filter((_, i) => i !== index));
  };

  const handleSave = async () => {
    if (!comments.trim()) {
      Swal.fire({
        title: 'Error',
        text: 'Debe agregar un comentario u observación',
        icon: 'error',
        confirmButtonText: 'Aceptar'
      });
      return;
    }

    if (scannedGuide && filesWatcher.length === 0) {
      Swal.fire({
        title: 'Error',
        text: `Para la orden ${scannedGuide} se necesitan imágenes de evidencia`,
        icon: 'error',
        confirmButtonText: 'Aceptar'
      });
      return;
    }

    Swal.fire({
      title: "¿Está seguro?",
      text: "¿Desea confirmar el procesamiento de esta(s) devolución(es)?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirmar",
      cancelButtonText: "Cancelar"
    }).then(async (result) => {
      if (result.isConfirmed) {
        setIsProcessing(true);
        
        try {
          const orderMap = new Map();

          returnProducts.forEach(product => {
            const isScanned = product.tracking === scannedGuide;
            const orderDetail = {
              idProduct: product.idProduct,
              available: isScanned 
                ? parseInt(document.querySelector(`input[name="available"][data-product-id="${product.idProduct}"]`)?.value) || 0
                : product.quantity, // Para no escaneadas, usar product.quantity
              damaged: isScanned 
                ? parseInt(document.querySelector(`input[name="damaged"][data-product-id="${product.idProduct}"]`)?.value) || 0
                : 0,
              missing: isScanned 
                ? parseInt(document.querySelector(`input[name="missing"][data-product-id="${product.idProduct}"]`)?.value) || 0
                : 0,
              extra: isScanned 
                ? parseInt(document.querySelector(`input[name="extra"][data-product-id="${product.idProduct}"]`)?.value) || 0
                : 0,
              expired: isScanned 
                ? parseInt(document.querySelector(`input[name="expired"][data-product-id="${product.idProduct}"]`)?.value) || 0
                : 0,
              supplanted: isScanned 
                ? parseInt(document.querySelector(`input[name="supplanted"][data-product-id="${product.idProduct}"]`)?.value) || 0
                : 0
            };

            if (orderMap.has(product.numOrder)) {
              orderMap.get(product.numOrder).orderDetail.push(orderDetail);
            } else {
              orderMap.set(product.numOrder, {
                numOrder: product.numOrder,
                idAddress: product.idAddress,
                trackingId: product.tracking,
                comments: isScanned ? comments.trim() : '',
                orderDetail: [orderDetail]
              });
            }
          });

          const returnData = {
            idReturn: parseInt(idReturn),
            returnDetail: Array.from(orderMap.values())
          };

          if (!returnData.returnDetail || returnData.returnDetail.length === 0) {
            Swal.fire({
              title: 'Error',
              text: 'No hay detalles de devolución para procesar.',
              icon: 'error',
              confirmButtonText: 'Aceptar'
            });
            return;
          }

          const formData = new FormData();
          formData.append('data', JSON.stringify(returnData));

          filesWatcher.forEach((file, index) => {
            const extension = file.name.split('.').pop();
            const fileName = `${idReturn}_${scannedGuide}_${index}.${extension}`;
            formData.append('images', file, fileName);
          });

          console.log('Datos a enviar:', returnData);
          console.log('Imágenes a enviar:', filesWatcher);

          await reduxConfirmReturn(formData);
          handleCloseModal();

        } catch (error) {
          console.error('Error al procesar la devolución:', error);
          Swal.fire({
            title: "Error",
            text: "Error al preparar los datos. Por favor, intente nuevamente.",
            icon: "error",
            confirmButtonText: "Aceptar"
          });
        } finally {
          setIsProcessing(false);
        }
      }
    });
  };

  const handleScanGuide = (event) => {
    if (event.key === 'Enter') {
        const newGuide = event.target.value.trim().toUpperCase();
        const guideExists = returnProducts.some(product => product.tracking === newGuide);
        
        if (guideExists && !scannedGuides.includes(newGuide)) {
            setScannedGuides([...scannedGuides, newGuide]);
            event.target.value = ''; // Limpiar el campo de entrada
        } else {
            Swal.fire({
                title: "Error",
                text: "La guía escaneada no coincide con ninguna devolución disponible.",
                icon: "error",
                confirmButtonText: "Aceptar"
            });
        }
    }
  };

  const columns = [
    {
      title: "Guía",
      field: "tracking",
      searchable: false,
      render: (item) => <span>{item.tracking}</span>,
    },
    {
      title: "Producto",
      field: "product",
      searchable: false,
      render: (item) => <span>{item.product}</span>,
    },
    {
      title: "Cantidad Total Declarada",
      field: "quantity",
      searchable: false,
      render: (item) => <span>{item.quantity}</span>,
    },
    {
      title: "Disponible",
      field: "disponible",
      searchable: false,
      render: (item) => (
        <Form.Control 
          type="number"
          min="0"
          defaultValue="0"
          name="available"
          data-product-id={item.idProduct}
          className="form-control-sm"
        />
      ),
    },
    {
      title: "Averiado",
      field: "averiado",
      searchable: false,
      render: (item) => (
        <Form.Control 
          type="number"
          min="0"
          defaultValue="0"
          name="damaged"
          data-product-id={item.idProduct}
          className="form-control-sm"
        />
      ),
    },
    {
      title: "Faltante",
      field: "Faltante",
      searchable: false,
      render: (item) => (
        <Form.Control 
          type="number"
          min="0"
          defaultValue="0"
          name="missing"
          data-product-id={item.idProduct}
          className="form-control-sm"
        />
      ),
    },
    {
      title: "Sobrante",
      field: "sobrante",
      searchable: false,
      render: (item) => (
        <Form.Control 
          type="number"
          min="0"
          defaultValue="0"
          name="extra"
          data-product-id={item.idProduct}
          className="form-control-sm"
        />
      ),
    },
    {
      title: "Caducados",
      field: "caducados",
      searchable: false,
      render: (item) => (
        <Form.Control 
          type="number"
          min="0"
          defaultValue="0"
          name="expired"
          data-product-id={item.idProduct}
          className="form-control-sm"
        />
      ),
    },
    {
      title: "Suplantación",
      field: "suplantacion",
      searchable: false,
      render: (item) => (
        <Form.Control 
          type="number"
          min="0"
          defaultValue="0"
          name="supplanted"
          data-product-id={item.idProduct}
          className="form-control-sm"
        />
      ),
    }
  ];

  const renderGrid = () => {
    const filteredProducts = returnProducts.filter(product => 
      scannedGuides.includes(product.tracking)
    );
    
    return (
      <Grid
        cols={columns}
        data={filteredProducts}
        showFilters={false}
        showRange={false}
      />
    );
  };

  const renderFileNames = () => (
    <>
      {filesWatcher.length > 0 && (
        <div className="custom-multifile-names-container">
          {filesWatcher.map((file, index) => (
            <span className="custom-multifile-names" key={index}>
              {file.name}
              <button
                className={`custom-multifile-remove btn btn-sm btn-danger`}
                onClick={() => handleRemoveFile(index)}
              >
                Eliminar
              </button>
            </span>
          ))}
        </div>
      )}
    </>
  );

  return (
    <>
      <div 
        style={{ width: '100%', height: '50px', cursor: 'pointer' }}
      >
        <input
          className="form-control"
          ref={ref}
          style={{ display: 'none' }}
          {...props}
        />
      </div>

      <Modal
        show={show}
        onHide={handleCloseModal}
        backdrop="static"
        keyboard={false}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>Detalle de Devolución</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>Escanear Guía</Form.Label>
            <Form.Control
              type="text"
              placeholder="Escanea o ingresa la guía"
              onKeyPress={handleScanGuide}
            />
          </Form.Group>

          {renderGrid()}
          
          <Form.Group className="mt-3">
            <Form.Label>Evidencias</Form.Label>
            <div className="d-flex flex-column">
              <label htmlFor="custom-multifile-input" className="custom-multifile-label">Seleccionar archivos</label>
              <input
                id='custom-multifile-input'
                className="custom-multifile-input"
                type="file" 
                multiple
                accept="image/*"
                onChange={handleChangeFiles}
              />
              {renderFileNames()}
            </div>
          </Form.Group>

          <Form.Group className="mt-3">
            <Form.Label>Comentarios/Observaciones</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={comments}
              onChange={(e) => setComments(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal} disabled={isProcessing}>
            Cerrar
          </Button>
          <Button variant="primary" onClick={handleSave} disabled={isProcessing}>
            {isProcessing ? 'Procesando...' : 'Guardar'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
});

ReturnsModal.propTypes = {
  returnProducts: PropTypes.array,
  idReturn: PropTypes.string,
  reduxConfirmReturn: PropTypes.func.isRequired,
  reduxProcessReturn: PropTypes.func.isRequired,
  reduxProcessReturnOrder: PropTypes.func.isRequired,
  successfulProcessReturn: PropTypes.bool,
  processReturnResult: PropTypes.object,
  successfulProcessReturnOrder: PropTypes.bool,
  processReturnOrderResult: PropTypes.object,
  show: PropTypes.bool,
  onHide: PropTypes.func,
  scannedGuide: PropTypes.string,
  onOpenModal: PropTypes.func,
};

const mapStateToProps = (state) => ({
  successfulProcessReturn: state.wmsState.successfulProcessReturn,
  processReturnResult: state.wmsState.processReturnResult,
  successfulProcessReturnOrder: state.wmsState.successfulProcessReturnOrder,
  processReturnOrderResult: state.wmsState.processReturnOrderResult
});

const mapDispatchToProps = (dispatch) => ({
  reduxProcessReturn: (idReturn) =>
    dispatch({
      type: "PROCESS_WMS_RETURN_REQUEST",
      value: idReturn,
    }),
  reduxProcessReturnOrder: (payload) =>
    dispatch({
      type: "PROCESS_WMS_RETURN_ORDER_REQUEST",
      value: payload,
    })
});

export default connect(mapStateToProps, mapDispatchToProps)(ReturnsModal);

