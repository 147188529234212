import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Spinner } from "react-bootstrap";

import Grid from "../../components/Grid";
import { Show } from "../../hooks/Show";
import { alertImportanceEnum, alertStatusEnum } from "../../utils/enumsUtil";
import { formatDateWithCustomFormat, FormatsDate } from "../../utils/formatDate";
import { buttonIcon, buttonTypes } from "../../utils/buttonsUtil";
import AlertTimeline from "../../components/Modal/Alerts/alertTimeline";

const AlertsLog = ({
    activeTab,
    reduxGetDaemonAlerts,
    daemonAlerts,
    reduxResendAlert,
    requestingResendAlert,
}) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [offset, setOffset] = useState(10);
    const [search, setSearch] = useState('');

    const [showTimelineModal, setShowTimelineModal] = useState(false);
    const [daemonAlertId, setDaemonAlertId] = useState(null);
    const [executingResend, setExecutingResend] = useState(false);

    useEffect(() => {
        if(!activeTab) return;
        
        reduxGetDaemonAlerts({
            page: currentPage,
            offset: offset,
            search: search,
        })
    }, [activeTab, currentPage, offset, search, reduxGetDaemonAlerts]);

    useEffect(() => {
        if(requestingResendAlert === true){
            setExecutingResend(true)
        } else {
            setExecutingResend(false)
        }

        reduxGetDaemonAlerts({
            page: currentPage,
            offset: offset,
            search: search,
        })
    }, [requestingResendAlert])

    const showTimeline = (id) => {
        setShowTimelineModal(true);
        setDaemonAlertId(id);
    }

    const handleCloseTimeline = () => {
        setShowTimelineModal(false);
        setDaemonAlertId(null);
    }

    const columns = [
        {
            title: "#",
            render: (rowData) => {
                return <span>{rowData.idDaemonAlert}</span>;  
            },
        },
        {
            title: "Código Alerta",
            render: (rowData) => {
                return <span>{rowData.alert?.code || '-'}</span>;
            },
            field: "TMA.code",
            searchable: true,
        },
        {
            title: "Fuente",
            render: (rowData) => {
                return <span>{rowData.alertSource?.code}</span>;
            },
            field: "TMASRC.code",
            searchable: true,
        },
        {
            title: "Tipo",
            render: (rowData) => {
                return <span>{rowData.alertType?.code}</span>;
            },
            field: "TMAT.code",
            searchable: true,
        },
        {
            title: "Destinatario",
            render: (rowData) => {
                return <span>{rowData.to}</span>;
            },
            field: "TAD.to",
            searchable: true,
        },
        {
            title: "Importancia",
            render: (rowData) => {
                return <span>{alertImportanceEnum[rowData.importance]}</span>;
            },
        },
        {
            title: "Estado",
            render: (rowData) => {
                return <span>{rowData.status.description}</span>;
            },
            field: "TMAS.description",
            searchable: true,
        },
        {
            title: "Intentos",
            render: (rowData) => {
                return <span>{rowData.attempts}</span>;
            },
        },
        {
            title: "Creado",
            render: (rowData) => {
                return <span>{formatDateWithCustomFormat(rowData.createdAt, { format: FormatsDate.FULL24 })}</span>;
            },
        },
        {
            title: "Acciones",
            render: (rowData) => {
                return (
                    <>
                        {
                            (
                                rowData.status.code === alertStatusEnum.NOTSENT &&
                                rowData.attempts === rowData.alertType.maxAttempts
                            ) &&
                            <>
                                <Show when="feature:alerts-resend">
                                    <button
                                        title="Reenviar"
                                        className={`btn ${buttonTypes.Success} btn-circle btn-sm mr-1`}
                                        type="button"
                                        disabled={executingResend}
                                        onClick={() => { reduxResendAlert({ id: rowData.idDaemonAlert }) }}
                                    >
                                        {
                                            !executingResend
                                                ? <i className={`${buttonIcon.Refresh}`}></i>
                                                : <Spinner
                                                    as="span"
                                                    animation="grow"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                    />
                                        }
                                    </button>
                                </Show>
                            </>
                        }
                        <button
                            title="Log"
                            className="btn btn-primary btn-circle btn-sm mr-1"
                            type="button"
                            onClick={() => { showTimeline(rowData.idDaemonAlert) }}
                        >
                            <i className={`${buttonIcon.List}`}></i>
                        </button>
                    </>
                );
            },
        },
    ];

    return (
        <>
            <div className="card-body">
                <Grid
                    className='stickyAddress'
                    cols={columns}
                    data={
                        daemonAlerts && Object.keys(daemonAlerts).length > 0 ? daemonAlerts.items : []
                    }
                    page={
                        daemonAlerts && Object.keys(daemonAlerts).length > 0
                            ? Number(daemonAlerts.page)
                            : currentPage
                    }
                    pages={
                        daemonAlerts && Object.keys(daemonAlerts).length > 0
                            ? Number(daemonAlerts.totalPages)
                            : 0
                    }
                    total={
                        daemonAlerts && daemonAlerts.hasOwnProperty("total") ? daemonAlerts.total : 0
                    }
                    offset={offset}
                    onChangePage={(page) => setCurrentPage(page)}
                    onChangeRange={(value) => {
                        setOffset(value);
                        reduxGetDaemonAlerts({
                            page: 1,
                            offset: value,
                            search: search,
                        });
                    }}
                    defaultValue={search}
                    onChangeSearch={(value) => {
                        setSearch(value);
                        reduxGetDaemonAlerts({
                            page: 1,
                            offset: offset,
                            search: value,
                        });
                    }}
                />
            </div>
            <AlertTimeline daemonAlertId={daemonAlertId} show={showTimelineModal} handleClose={handleCloseTimeline} />;
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        daemonAlerts: state.alertState.daemonAlerts,
        requestingResendAlert: state.alertState.requestingResendAlert,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        reduxGetDaemonAlerts: (payload) =>
            dispatch({
                type: "FETCH_DAEMON_ALERTS_REQUEST",
                value: payload,
            }),
        reduxResendAlert: (payload) =>
            dispatch({
                type: "RESEND_DAEMON_ALERT_REQUEST",
                value: payload,
            }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AlertsLog);