import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import Sidebar from "../../components/Navigation/Sidebar";
import Topbar from "../../components/Navigation/Topbar";
import Grid from "../../components/Grid";
import { connect } from "react-redux";
import { Button, Modal } from "react-bootstrap";
import { Show } from "../../hooks/Show";
import { capitalizeFirstLetter, createWordFromPhrase, onlyLetters } from "../../utils/functionsUtils";
import Select from 'react-select'

const Swal = require("sweetalert2");

const Novelties = ({
  statuses,
  reduxGetStatuses,
  novelties,
  reduxGetNovelties,
  novelty,
  reduxGetNovelty,
  rowEdited,
  reduxPostNovelty,
  rowDeleted,
  reduxDeleteNovelty,
  rowUpdated,
  reduxPatchNovelty,
  reduxResetNoveltyForm,
  errorsCreateNovelty,
  successfulCreateNovelty,
  successfulUpdateNovelty
}) => {
  const customStyles = {
    menu: (base) => ({ ...base, zIndex: 9999 }),
    valueContainer: (baseStyles) => ({...baseStyles, maxHeight:"3.5vw", overflowY: "auto" }),
    control: (baseStyles) => ({ ...baseStyles, flexWrap: "nowrap"}) // Ajusta el valor según tus necesidades
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [statusSelected, setStatusSelected] = useState({
    label: "Seleccionar",
    value: null
  });
  const [offset, setOffset] = useState(10);
  const [search, setSearch] = useState('');
  const [statusSearch, setStatusSearch] = useState('');
  const [disabled, setDisabled] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [filters, setFilters] = useState ({
    page: currentPage,
    offset: offset,
    search: search,
    status: statusSearch
  })
  
  const methods = useForm({});

  const {
    formState: { errors },
    reset,
    setValue,
  } = methods;
  
  const status = useRef({});
  status.current = methods.watch("idStatus");
  
  const description = useRef({});
  description.current = methods.watch("description");
  
  const code = useRef({});
  code.current = methods.watch("code");
  
  const noMobile = useRef({});
  noMobile.current = methods.watch("noMobile");

  const noCronUpdate = useRef({});
  noCronUpdate.current = methods.watch("noCronUpdate");

  const finalNovelty = useRef({});
  finalNovelty.current = methods.watch("finalNovelty");

  const handleChangeDescription = (text) => {
    if (text !== "" && text !== undefined) {
      const textValid = onlyLetters(text, /[^a-zA-Z- ]/g)
      setValue("description", capitalizeFirstLetter(textValid))
      if (!rowEdited) {
        setValue("code", textValid)
      }
    } else {
      setValue("description", '')
      if (!rowEdited) {
        setValue("code", '')
      }
    }
  }

  const searchByStatus = () => {
   const object = statusSearch.map(item => item.value);
    reduxGetNovelties({...filters, status: object});
  }

  useEffect(() => {
    if (statusSelected.value !== null)
    {
      setValue("idStatus", statusSelected.value)    
    }
  }, [statusSelected])

  useEffect(() => {
    if (statusSearch.length === 0)
    {
      reduxGetNovelties({...filters,  status: ''});         
    }
  }, [statusSearch])

  useEffect(() => {
    const textValid = onlyLetters(code.current)
    if (!rowEdited) {
      setValue("code", createWordFromPhrase(textValid, 4))
    } else {
      setValue("code", rowEdited.code)
    }  
  }, [code.current])

  useEffect(() => {
    if (errorsCreateNovelty) {
      reduxResetNoveltyForm();
      Swal.fire("Proceso no exitoso!", `El codigo de la novedad ya existe`, "error");
    }
    reduxGetNovelties(filters);
  }, [errorsCreateNovelty]);
  
  useEffect(() => {
    if (successfulCreateNovelty) {
      reduxResetNoveltyForm();
      Swal.fire("Proceso exitoso!", `Novedad (${novelty.code}) creada exitosamente`, "success");
    }
    reduxGetNovelties({
    page: currentPage,
    offset: offset,
    search: search,
    status: statusSearch ? statusSearch : ''
  });
  }, [successfulCreateNovelty]);
  
  useEffect(() => {
    if (successfulUpdateNovelty) {
      reduxResetNoveltyForm();
      Swal.fire("Proceso exitoso!", `Novedad (${rowUpdated.code}) actualizada exitosamente`, "success");
    }
    reduxGetNovelties(filters);
  }, [successfulUpdateNovelty]);
  
  const onSubmit = (data) => {
    data.finalNovelty = data.finalNovelty === undefined ? false : data.finalNovelty
    data.noCronUpdate = data.noCronUpdate === undefined ? false : data.noCronUpdate

    if (rowEdited) {
      reduxPatchNovelty({
        ...data,
        idNovelty: rowEdited.idNovelty,
        code: rowEdited.code,
      });
    } else {
      data.code = "N-"+data.code
      reduxPostNovelty(data);
    }
    reset();
    reduxResetNoveltyForm();
    setShowModal(false);
  };

  const handleShow = () => { 
    reset();
    reduxResetNoveltyForm();
    setStatusSelected({
      label: "Seleccionar",
      value: null
    })
    setShowModal(true);
  };

  useEffect(() => {
    reduxGetNovelties(filters);
  }, [filters]);

  const columns = [
    {
      title: "#",
      render: (rowData) => {
        return <span>{rowData.idNovelty}</span>;
      },
      field: "idNovelty",
      searchable: true,
    },
    {
      title: "Nombre Novedad",
      render: (rowData) => {
        return <span>{rowData.description}</span>;
      },
      field: "description",
      searchable: true,
    },
    {
      title: "Código de Novedad",
      render: (rowData) => {
        return <span>{rowData.code ? rowData.code : ""}</span>;
      },
      field: "code",
      searchable: true,
    },
    {
      title: "Estado",
      render: (rowData) => {
        return <span>{rowData.Status.name}</span>;
      },
      field: "Status.name",
    },
    {
      title: "Opciones",
      render: (rowData) => {
        return (
          <>
            {rowData.isActive ? (
              <>
                <Show when="feature:edit-record">
                  <button
                    title="Editar"
                    className="btn btn-primary btn-sm  btn-circle mr-2"
                    type="button"
                    onClick={(e) => handleOpen(rowData)}
                  >
                    <i className="fas fa-edit fa-xs"></i>
                  </button>
                </Show>
                <Show when="feature:disabled-record">
                  <button
                    title="Desactivar"
                    className="btn btn-danger btn-sm btn-circle"
                    type="button"
                    onClick={(e) => handleDelete(rowData.idNovelty)}
                  >
                    <i className="fas fa-times-circle fa-xs"></i>
                  </button>
                </Show>
              </>
            ) : (
              <Show when="feature:enabled-record">
                <button
                  title="Activar"
                  className="btn btn-primary btn-sm  btn-circle mr-2"
                  type="button"
                  onClick={(e) => handleDelete(rowData.idNovelty)}
                >
                  <i className="fas fa-check-circle fa-xs"></i>
                </button>
              </Show>
            )}
          </>
        );
      },
    },
  ];
  
  const handleClose = () => {
    setShowModal(false);
  };

  const handleOpen = (row) => {
    reduxGetNovelty({
      idNovelty: row.idNovelty,
    });
  };

  useEffect(() => {
    if (rowEdited) {      
      setValue("description", rowEdited.description);
      setValue("code", rowEdited.code)
      setStatusSelected({value: rowEdited.Status.idStatus, label: rowEdited.Status.name});
      setValue("noMobile", rowEdited.noMobile);
      setValue("finalNovelty", rowEdited.finalNovelty);
      setValue("noCronUpdate", rowEdited.noCronUpdate);
      setDisabled(
        !(rowEdited.Status.code == 'NOENTR' || rowEdited.Status.code == 'ENTREG')
      );
      setShowModal(true);
    }
  }, [rowEdited]);

  const handleDelete = (id) => {
    reduxDeleteNovelty({ idNovelty: id });
    reduxResetNoveltyForm();
    reduxGetNovelties(filters);
  };

  useEffect(() => {
    reduxGetNovelties(filters);
    reduxGetStatuses({
      page: 1,
      search: "",
      offset: 1000
    });
  }, []);

  useEffect(() => {
    if (novelty || rowUpdated || rowDeleted) {
      reduxResetNoveltyForm();
      reduxGetNovelties(filters);
    }
  }, [novelty, rowUpdated, rowDeleted]);

  return (
    <div>
      <div id="wrapper">
        <Sidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Topbar />
            <div className="container-fluid">
              <div className="d-sm-flex align-items-center mb-1">
                <h1 className="h3 mb-0 text-gray-800 mr-3">Novedades</h1>
                <Show when="feature:create-record">
                  <button
                    className="btn btn-primary btn-circle"
                    type="button"
                    onClick={handleShow}
                  >
                    <i className="fas fa-plus fa-sm"></i>
                  </button>
                </Show>
              </div>
              <p className="mb-4">Módulo de Novedades</p>
              
              <div className="card my-4">
                <div className="card-header py-3">
                  <h6 className="m-0 font-weight-bold text-primary">
                    Listado de Novedades
                  </h6>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-3">
                      <div className="form-group">
                        <label htmlFor="state" className="form-label">
                          Estado
                        </label>

                        <Select
                          id="state"
                          isMulti
                          styles={customStyles}
                          onChange={(value) => setStatusSearch(value)}
                          options={
                            statuses && Object.keys(statuses).length > 0
                              ? [
                                ...statuses.items.map((ele, key) => ({
                                  value: ele.idStatus,
                                  label: ele.name,
                                }))
                              ]
                              : [{ value: "", label: "Seleccionar…" }]
                          }
                        />


                      </div>
                    </div>
                    <div className="col">
                      <button className="btn btn-primary" style={{marginTop: "32px"}} onClick={() => searchByStatus()}>Buscar</button>
                    </div>
                  </div>

                  <Grid
                    cols={columns}
                    data={
                      novelties && Object.keys(novelties).length > 0
                        ? novelties.items
                        : []
                    }
                    page={
                      novelties && Object.keys(novelties).length > 0
                        ? Number(novelties.page)
                        : currentPage
                    }
                    pages={
                      novelties && Object.keys(novelties).length > 0
                        ? Number(novelties.totalPages)
                        : 0
                    }
                    total={
                      novelties && novelties.hasOwnProperty("total")
                        ? novelties.total
                        : 0
                    }
                    offset={offset}
                    onChangePage={(page) => setFilters({...filters, page: page})}
                    onChangeRange={(value) => {
                      setFilters({...filters, offset: value});
                    }}
                    defaultValue={search}
                    onChangeSearch={(value) => {
                      setFilters({...filters, search: value});
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Modal
          show={showModal}
          onHide={handleClose}
          onEscapeKeyDown={handleClose}
          backdrop="static"
          keyboard={true}
        >
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Modal.Header closeButton>
              <Modal.Title>
                {rowEdited ? "Editar" : "Nueva"} Novedad
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-group">
                <label htmlFor="idStatus" className="form-label">
                  Estado Asociado
                </label>                                
                <Select
                  value={statusSelected}
                  id='idStatus'
                  onChange={(value) => setStatusSelected({label: value?.label, value: value?.value})}
                  options=
                  {statuses &&
                    Object.keys(statuses).length > 0 &&
                    statuses.items
                      .map((ele) => ({                                    
                          value: ele.idStatus,
                          label: ele.name,
                        }))
                  }
                />
                {errors.idStatus && (
                  <span className="invalid-feedback">
                    El Estado Asociado es requerido
                  </span>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="description" className="form-label">
                  Nombre Novedad
                </label>
                <input
                  id="description"
                  type="text"
                  maxLength={50}
                  className={`form-control form-control-user`}
                  value={description.current}
                  {...methods.register("description", {
                    maxLength: {
                      value: 50,
                      message: "El límite de caracteres es de 50",
                    },
                    required: {
                      value: true,
                      message: "El campo no puede estar vacío",
                    },
                    pattern: {
                      value: /^[a-zA-Z- ]+$/,
                      message: 'Por favor ingresa solo letras',
                    },
                  })}
                  onChange={(e) => handleChangeDescription(e.target.value)}
                  aria-invalid={errors.description ? "true" : "false"}
                  disabled={
                    (statusSelected.value === null || statusSelected.value === '')
                  }
                />
                {errors.description && (
                  <span className="invalid-feedback">
                    La novedad es requerida
                  </span>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="code" className="form-label">
                  Código Novedad
                </label>
                <div className="input-group">
                  <span className={`input-group-text ${rowEdited && 'd-none'}`}id="basic-addon1">N - </span>
                  <input
                    id="code"
                    type="text"
                    maxLength={4}
                    value={code.current}
                    onChange={(e) => setValue("code", e.target.value)}
                    className={`form-control form-control-user`}
                    {...methods.register("code", {
                      maxLength: {
                        value: 6,
                        message: "El límite de caracteres es de 6",
                      },
                      required: {
                        value: true,
                        message: "El campo no puede estar vacío",
                      },
                      pattern: {
                        value: /^[A-Z-]+$/,
                        message: 'Por favor ingresa solo letras mayusculas',
                      }
                    })}
                    disabled={rowEdited || (statusSelected.value === null || statusSelected.value === '')}
                  />
                </div>
                {errors.code && (
                  <span className="invalid-feedback">
                    El código de la novedad es requerido
                  </span>
                )}
              </div>
              <div className="form-group mb-0">
                <label htmlFor="noMobile">
                  <input
                    id="noMobile"
                    {...methods.register("noMobile")}
                    type="checkbox"
                    disabled={rowEdited ? false : disabled}
                    style={{ marginRight: "10px", marginLeft: "10px" }}
                  />
                  No aplica para Mobile
                </label>
              </div>
              <div className="form-group mb-0">
                <label htmlFor="finalNovelty">
                  <input
                      id="finalNovelty"
                      {...methods.register("finalNovelty")}
                      type="checkbox"
                      style={{ marginRight: "10px", marginLeft: "10px" }}
                  />
                  Novedad Final
                </label>
              </div>
              <div className="form-group mb-0">
                <label htmlFor="noCronUpdate">
                  <input
                      id="noCronUpdate"
                      {...methods.register("noCronUpdate")}
                      type="checkbox"
                      style={{ marginRight: "10px", marginLeft: "10px" }}
                  />
                  Evitar actualización automática
                </label>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cancelar
              </Button>
              <Button variant="primary" type="submit"
                disabled={(statusSelected.value === null || statusSelected.value === '') || code.current === '' || description.current === ''}
              >
                Guardar
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    statuses: state.statusesState.statuses,
    novelties: state.noveltyState.novelties,
    novelty: state.noveltyState.novelty,
    rowEdited: state.noveltyState.rowEdited,
    rowDeleted: state.noveltyState.rowDeleted,
    rowUpdated: state.noveltyState.rowUpdated,
    errorsCreateNovelty: state.noveltyState.errorsCreateNovelty,
    successfulCreateNovelty: state.noveltyState.successfulCreateNovelty,
    successfulUpdateNovelty: state.noveltyState.successfulUpdateNovelty,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    reduxGetStatuses: (payload) =>
      dispatch({
        type: "FETCH_NEW_STATUS_REQUEST",
        value: payload,
      }),
    reduxGetNovelties: (payload) =>
      dispatch({
        type: "FETCH_NOVELTIES_REQUEST",
        value: payload,
      }),
    reduxGetNovelty: (payload) =>
      dispatch({
        type: "READ_NOVELTY_REQUEST",
        value: payload,
      }),
    reduxPostNovelty: (payload) =>
      dispatch({
        type: "CREATE_NOVELTY_REQUEST",
        value: payload,
      }),
    reduxPatchNovelty: (payload) =>
      dispatch({
        type: "UPDATE_NOVELTY_REQUEST",
        value: payload,
      }),
    reduxDeleteNovelty: (payload) =>
      dispatch({
        type: "CHANGE_STATE_NOVELTY_REQUEST",
        value: payload,
      }),
    reduxResetNoveltyForm: () =>
      dispatch({
        type: "RESET_NOVELTIES_FORM",
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Novelties);
