const initialState = {
    processingUploadMassiveAssignment: false,
    successUploadMassiveAssignment: false,
    errorUploadMassiveAssignment: false,
    massiveAssignmentResult: null,
};
  
const assignOperatorReducer = (state = initialState, payload) => {
    switch (payload.type) {
        case "UPLOAD_MASSIVE_ASSIGNMENT_FILE_PROCESSING":
        return {
            ...state,
            processingUploadMassiveAssignment: true,
            successUploadMassiveAssignment: false,
            errorUploadMassiveAssignment: false,
        };
        case "UPLOAD_MASSIVE_ASSIGNMENT_FILE_SUCCESS":
        return {
            ...state,
            processingUploadMassiveAssignment: false,
            successUploadMassiveAssignment: true,
            errorUploadMassiveAssignment: false,
            massiveAssignmentResult: payload.value,
        };
        case "UPLOAD_MASSIVE_ASSIGNMENT_FILE_ERROR":
        return {
            ...state,
            processingUploadMassiveAssignment: false,
            successUploadMassiveAssignment: false,
            errorUploadMassiveAssignment: true,
        };
        default:
        return state;
    }
};

export const assignOperatorActions = {
    UPLOAD_MASSIVE_ASSIGNMENT_FILE_PROCESSING: 'UPLOAD_MASSIVE_ASSIGNMENT_FILE_PROCESSING',
    UPLOAD_MASSIVE_ASSIGNMENT_FILE_SUCCESS: 'UPLOAD_MASSIVE_ASSIGNMENT_FILE_SUCCESS',
    UPLOAD_MASSIVE_ASSIGNMENT_FILE_ERROR: 'UPLOAD_MASSIVE_ASSIGNMENT_FILE_ERROR',
}

export default assignOperatorReducer;