import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { Button, Modal } from "react-bootstrap";
import ContentWrapper from "../../components/Common/ContentWrapper";
import Grid from "../../components/Grid";
import { Show } from "../../hooks/Show";
import { addressNomenclatureActions } from "../../redux/reducers/addressNomenclatureReducer";

const AddressNomenclature = ({
  requestStatus,
  reduxResetAddressNomenclatureForm,
  reduxPatchAddressNomenclature,
  reduxPostAddressNomenclature,
  reduxDeleteAddressNomenclature,
  reduxGetAddressNomenclature,
  addressNomenclatures,
  reduxGetAddressNomenclatures,
  addressNomenclature,
  rowEdited,
  rowDeleted,
  rowUpdated,
}) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
    setValue,
  } = useForm();
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [show, setShow] = useState(false);
  const [offset, setOffset] = useState(10);
  const columns = [
    {
      title: "#",
      render: (rowData) => {
        return <span>{rowData.idAddressNomenclature}</span>;
      },
      field: 'idAddressNomenclature',
      searchable: true
    },
    {
      title: "Código",
      render: (rowData) => {
        return <span>{rowData.code}</span>;
      },
      field: 'code',
      searchable: true
    },
    {
      title: "Descripción",
      render: (rowData) => {
        return <span>{rowData.name}</span>;
      },
      field: 'name',
      searchable: true
    },
    {
      title: "Opciones",
      render: (rowData) => {
        return (
          <>
            {rowData.isActive ? (
              <>
                <Show when="feature:edit-addressNomenclature">
                  <button
                    title="Editar"
                    className="btn btn-primary btn-sm  btn-circle mr-2"
                    type="button"
                    onClick={(e) => handleOpen(rowData)}
                  >
                    <i className="fas fa-edit fa-xs"></i>
                  </button>
                </Show>
                <Show when="feature:disabled-addressNomenclature">
                  <button
                    title="Desactivar"
                    className="btn btn-danger btn-sm btn-circle"
                    type="button"
                    onClick={(e) => handleDelete(rowData)}
                  >
                    <i className="fas fa-times-circle fa-xs"></i>
                  </button>
                </Show>
              </>
            ) : (
              <Show when="feature:enabled-addressNomenclature">
                <button
                  title="Activar"
                  className="btn btn-primary btn-sm  btn-circle mr-2"
                  type="button"
                  onClick={(e) => handleActive(rowData)}
                >
                  <i className="fas fa-check-circle fa-xs"></i>
                </button>
              </Show>
            )}
          </>
        );
      },
    },
  ];
  const handleClose = () => {
    reduxResetAddressNomenclatureForm();
    setShow(false);
  };
  const handleShow = () => setShow(true);
  const onSubmit = (data) => {
    if (rowEdited) {
      reduxPatchAddressNomenclature({
        ...data,
        id: rowEdited.idAddressNomenclature,
      });
    } else {
      reduxPostAddressNomenclature({ ...data, id: data.name, code: data.name });
    }
    reset();
    reduxResetAddressNomenclatureForm();
    setShow(false);
  };
  const handleOpen = (row) => {
    reduxGetAddressNomenclature({
      id: row.idAddressNomenclature,
    });
  };

  const handleActive = (row) => {
    if (!row.isActive) {
      reduxDeleteAddressNomenclature(row);
    }
  };

  const handleDelete = (row) => {
    reduxDeleteAddressNomenclature(row);
  };
  useEffect(() => {
    reduxGetAddressNomenclatures({
      page: 1,
      offset: offset,
      search: "",
    });
  }, []);

  useEffect(() => {
    if (rowEdited) {
      setShow(true);
      setValue("name", rowEdited.name);
      setValue("code", rowEdited.code);
    }
  }, [rowEdited]);

  useEffect(() => {
    reduxGetAddressNomenclatures({
      page: currentPage,
      offset: offset,
      search: search,
    });
  }, [currentPage]);

  useEffect(() => {
    if (addressNomenclature || rowUpdated || rowDeleted) {
      reduxGetAddressNomenclatures({
        page: currentPage,
        offset: offset,
        search: search,
      });
      reduxResetAddressNomenclatureForm();
    }
  }, [addressNomenclature, rowUpdated, rowDeleted]);

  return (
    <ContentWrapper>
      <div className="d-sm-flex align-items-center mb-1">
        <h1 className="h3 mb-0 text-gray-800 mr-3">Apéndices</h1>
        <Show when="feature:create-addressNomenclature">
          <button
            className="btn btn-primary btn-circle"
            type="button"
            onClick={handleShow}
          >
            <i className="fas fa-plus fa-sm"></i>
          </button>
        </Show>
      </div>
      <p className="mb-4">Apéndices</p>
      <div className="card shadow mb-4">
        <div className="card-header py-3">
          <h6 className="m-0 font-weight-bold text-primary">
            Listado Apéndices
          </h6>
        </div>
        <div className="card-body">
          <Grid
            cols={columns}
            data={
              addressNomenclatures &&
              Object.keys(addressNomenclatures).length > 0
                ? addressNomenclatures.items
                : []
            }
            page={
              addressNomenclatures &&
              Object.keys(addressNomenclatures).length > 0
                ? Number(addressNomenclatures.page)
                : currentPage
            }
            pages={
              addressNomenclatures &&
              Object.keys(addressNomenclatures).length > 0
                ? Number(addressNomenclatures.totalPages)
                : 1
            }
            total={
              addressNomenclatures &&
              addressNomenclatures.hasOwnProperty("total")
                ? addressNomenclatures.total
                : 0
            }
            offset={offset}
            onChangePage={(page) => setCurrentPage(page)}
            onChangeRange={(value) => {
              setOffset(value);
              reduxGetAddressNomenclatures({
                page: 1,
                offset: value,
                search: search,
              });
            }}
            defaultValue={search}
            onChangeSearch={(value) => {
              setSearch(value);
              reduxGetAddressNomenclatures({
                page: 1,
                offset: offset,
                search: value,
              });
            }}
          />
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        onEscapeKeyDown={handleClose}
        backdrop="static"
        keyboard={true}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton>
            <Modal.Title>
              {rowEdited ? "Editar" : "Nuevo"} Apéndices
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label htmlFor="code" className="form-label">
                Código
              </label>
              <input
                id="code"
                type="text"
                className={`form-control form-control-user ${
                  errors.code && "is-invalid"
                }`}
                {...register("code", { required: true })}
              />
              {errors.code && (
                <span className="invalid-feedback">El código es requerido</span>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="name" className="form-label">
                Descripción
              </label>
              <input
                id="name"
                type="text"
                className={`form-control form-control-user ${
                  errors.name && "is-invalid"
                }`}
                {...register("name", { required: true })}
              />
              {errors.name && (
                <span className="invalid-feedback">
                  La Descripción requerida
                </span>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancelar
            </Button>
            <Button variant="primary" type="submit">
              Guardar
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </ContentWrapper>
  );
};

const mapStateToProps = (state) => ({
  addressNomenclatures: state.addressNomenclature.addressNomenclatures,
  addressNomenclature: state.addressNomenclature.addressNomenclature,
  rowEdited: state.addressNomenclature.rowEdited,
  rowDeleted: state.addressNomenclature.rowDeleted,
  rowUpdated: state.addressNomenclature.rowUpdated,
});

const mapDispatchToProps = (dispatch) => ({
  reduxGetAddressNomenclatures: (payload) =>
    dispatch({
      type: addressNomenclatureActions.FETCH_ADDRESSNOMENCLATURE_REQUEST,
      value: payload,
    }),
  reduxPostAddressNomenclature: (payload) =>
    dispatch({
      type: addressNomenclatureActions.CREATE_ADDRESSNOMENCLATURE_REQUEST,
      value: payload,
    }),
  reduxGetAddressNomenclature: (payload) =>
    dispatch({
      type: addressNomenclatureActions.READ_ADDRESSNOMENCLATURE_REQUEST,
      value: payload,
    }),
  reduxDeleteAddressNomenclature: (payload) =>
    dispatch({
      type: addressNomenclatureActions.DELETE_ADDRESSNOMENCLATURE_REQUEST,
      value: payload,
    }),
  reduxPatchAddressNomenclature: (payload) =>
    dispatch({
      type: addressNomenclatureActions.UPDATE_ADDRESSNOMENCLATURE_REQUEST,
      value: payload,
    }),
  reduxResetAddressNomenclatureForm: () =>
    dispatch({
      type: addressNomenclatureActions.RESET_ADDRESSNOMENCLATURE_FORM,
    }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddressNomenclature);
