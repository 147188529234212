import { Card, Placeholder } from "react-bootstrap"


export const CardWithContentPlaceholder = () => {

    return (
        <Card>
            <Placeholder xs={10} as={'container'} />
            <Placeholder xs={10} as={'container'} />
            <Placeholder xs={10} as={'container'} />
        </Card>
    )

}