import { call, put, takeLatest } from "redux-saga/effects";
import request, {requestAxios} from "../../utils/request";

function* getAllAddresses(payload) {
  try {
    yield put({
      type: "FETCH_ADDRESSES_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const headers = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
    };

    let requestURL = `${process.env.REACT_APP_API_URL}/api/v1/addresses?page=${
      payload.value.page
    }&offset=${payload.value.offset}${
      payload.value.isActive ? "&isActive=" + payload.value.isActive : ""
    }&search=${payload.value.search}`;
    if (payload.value.idCity) {
      requestURL = requestURL + `&idCity=${payload.value.idCity}`;
    }
    if (payload.value.state && payload.value.state != "") {
      requestURL = requestURL + `&state=${payload.value.state}`;
    }
    if (payload.value.from && payload.value.from != "") {
      requestURL = requestURL + `&from=${payload.value.from}`;
    }
    if (payload.value.to && payload.value.to != "") {
      requestURL = requestURL + `&to=${payload.value.to}`;
    }
    if (payload.value.senderIds) {
      requestURL = requestURL + `&senderIds=${payload.value.senderIds}`;
    }
    if (payload.value.operatorIds) {
      requestURL = requestURL + `&operatorIds=${payload.value.operatorIds}`;
    }
    if (payload.value.idBranch) {
      requestURL = requestURL + `&idBranch=${payload.value.idBranch}`;
    }
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "HIDE_LOADING",
    });

    yield put({
      type: "FETCH_ADDRESS_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed load",
        message: "Failed load Addresses",
      },
    });
    yield put({
      type: "FETCH_ADDRESS_ERROR",
    });
  }
}

function* postAddress(payload) {
  try {
    yield put({ type: "CREATE_ADDRESS_REQUESTING" });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/addresses`;

    const headers = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload.value),
    };

    const response = yield call(request, requestURL, headers);

    yield put({
      type: "HIDE_LOADING",
    });

    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "success",
        title: "Successful save",
        message: "Successful save Address",
      },
    });

    yield put({
      type: "CREATE_ADDRESS_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed save",
        message: "Failed save Address",
      },
    });
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "CREATE_ADDRESS_ERROR",
    });
  }
}

function* getAddressById(payload) {
  try {
    yield put({
      type: "READ_ADDRESS_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const headers = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
    };

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/addresses/${payload.value.id}`;

    const response = yield call(request, requestURL, headers);

    yield put({
      type: "HIDE_LOADING",
    });

    yield put({
      type: "READ_ADDRESS_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "READ_ADDRESS_ERROR",
    });
  }
}

function* getAddressByIdCity(payload) {
  try {
    yield put({
      type: "READBYCITY_ADDRESS_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const headers = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
    };

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/addresses/city/${payload.value.idCity}`;

    const response = yield call(request, requestURL, headers);

    yield put({
      type: "HIDE_LOADING",
    });

    yield put({
      type: "READBYCITY_ADDRESS_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "READBYCITY_ADDRESS_ERROR",
    });
  }
}

function* getAddressByIdZone(payload) {
  try {
    yield put({
      type: "READBYZONE_ADDRESS_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const headers = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
    };

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/addresses/zone/${payload.value.idZone}`;

    const response = yield call(request, requestURL, headers);

    yield put({
      type: "HIDE_LOADING",
    });

    yield put({
      type: "READBYZONE_ADDRESS_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "READBYZONE_ADDRESS_ERROR",
    });
  }
}

function* disableAddressById(payload) {
  try {
    yield put({
      type: "DISABLE_ADDRESS_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/addresses/changeState/${payload.value.idAddress}`;

    const headers = {
      method: "PATCH",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(payload.value),
    };

    const response = yield call(request, requestURL, headers);

    yield put({
      type: "HIDE_LOADING",
    });

    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "success",
        title: "Successful disable",
        message: "Successful disable Address",
      },
    });

    yield put({
      type: "DISABLE_ADDRESS_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed disable",
        message: "Failed disable Address",
      },
    });
    yield put({
      type: "DISABLE_ADDRESS_ERROR",
    });
  }
}

function* updateAddress(payload) {
  try {
    yield put({
      type: "UPDATE_ADDRESS_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/addresses/${payload.value.id}`;

    const headers = {
      method: "PATCH",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(payload.value),
    };

    const response = yield call(request, requestURL, headers);

    yield put({
      type: "HIDE_LOADING",
    });

    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "success",
        title: "Successful update",
        message: "Successful update Address",
      },
    });

    yield put({
      type: "UPDATE_ADDRESS_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed update",
        message: "Failed update Address",
      },
    });
    yield put({
      type: "UPDATE_ADDRESS_ERROR",
    });
  }
}

function* uploadFileAddress(payload) {
  try {
    yield put({ type: "UPLOAD_ADDRESS_REQUESTING" });
    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/addresses/upload`;

    const formData = new FormData();
    formData.append("file", payload.value.file);
    formData.append("company", payload.value.company);
    formData.append("city", payload.value.city);
    formData.append("idOperator", payload.value.idOperator);

    const headers = {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
      }),
      body: formData,
    };

    const response = yield call(request, requestURL, headers);

    yield put({
      type: "UPLOAD_ADDRESS_SUCCESS",
      value: response,
    });

    yield put({
      type: "HIDE_LOADING",
    });

    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "success",
        title: "Successful upload",
        message: "Successful upload file",
      },
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed load",
        message: "Failed upload file "+error,
      },
    });
    yield put({
      type: "UPLOAD_ADDRESS_ERROR",
    });
  }
}

function* uploadUpdateAddressFile(payload) {
  try {
    yield put({ type: "UPDATE_UPLOAD_INVALID_ADDRESS_REQUESTING" });
    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/addresses/updateInvalidAddress`;

    const formData = new FormData();
    formData.append("file", payload.value.file);

    const headers = {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
      }),
      body: formData,
    };

    const response = yield call(request, requestURL, headers);
    
    if(response.invalidItems.length > 0){
      const headersDownload = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          fileName: "errores_en_las_direcciones.csv",
          data: response.invalidItems,
        }),
      };
  
      let downloadURL = `${process.env.REACT_APP_API_URL}/api/v1/download/csv`;
  
      yield call(requestAxios, downloadURL, headersDownload);
    }

    yield put({
      type: "UPDATE_UPLOAD_INVALID_ADDRESS_SUCCESS",
      value: response,
    });  

    yield put({
      type: "HIDE_LOADING",
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Error al actualizar las direcciones",
        message: error,
      },
    });
    yield put({
      type: "UPDATE_UPLOAD_INVALID_ADDRESS_ERROR",
    });
  } finally {
    yield put({
      type: "UPDATE_UPLOAD_INVALID_ADDRESS_DONE",
    });
  }
}

function* getAllAddressesWithInvalidAddress(payload) {
  try {
    yield put({
      type: "FETCH_INVALID_ADDRESSES_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    let requestURL = `${process.env.REACT_APP_API_URL}/api/v1/addresses/invalidAddresses`

    const headers = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload.value),
    };
    const response = yield call(fetch, requestURL, headers);
    const blob = yield call([response, 'blob']);

    const url = window.URL.createObjectURL(blob);
    let a = document.createElement("a");
    a.href = url;
    a.download = 'Envios_con_direcciones_invalidas.csv';
    document.body.appendChild(a);
    a.click();
    a.remove();

    yield put({
      type: "HIDE_LOADING",
    });

    yield put({
      type: "FETCH_INVALID_ADDRESSES_SUCCESS"
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    console.log(error)
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Descarga Fallida",
        message: error.message,
      },
    });
    yield put({
      type: "FETCH_INVALID_ADDRESSES_ERROR",
    });
  } finally {
    yield put({
      type: "FETCH_INVALID_ADDRESSES_DONE",
    });
  }
}

function* getAllAddressesAvailable(payload) {
  try {
    yield put({
      type: "FETCH_AVAILABLE_ADDRESSES_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    let requestURL = `${
      process.env.REACT_APP_API_URL
    }/api/v1/addresses/available?page=${payload.value.page}&search=${
      payload.value.search
    }&offset=${payload.value.offset}${
      payload.value.isActive ? "&isActive=" + payload.value.isActive : ""
    }`;
    if (payload.value.state && payload.value.state != "") {
      requestURL = requestURL + `&state=${payload.value.state}`;
    }
    if (payload.value.from && payload.value.from != "") {
      requestURL = requestURL + `&from=${payload.value.from}`;
    }
    if (payload.value.to && payload.value.to != "") {
      requestURL = requestURL + `&to=${payload.value.to}`;
    }
    if (payload.value.senders) {
      requestURL = requestURL + `&senders=${payload.value.senders}`;
    }
    if (payload.value.deliveryDate) {
      requestURL = requestURL + `&deliveryDate=${payload.value.deliveryDate}`;
    }
    const headers = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "HIDE_LOADING",
    });

    yield put({
      type: "FETCH_AVAILABLE_ADDRESS_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed load",
        message: "Failed load Addresses Available",
      },
    });
    yield put({
      type: "FETCH_AVAILABLE_ADDRESS_ERROR",
    });
  }
}

function* deleteAddressById(payload) {
  try {
    yield put({
      type: "DELETE_ADDRESS_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/addresses/deleteSoft/${payload.value.idAddress}`;

    const headers = {
      method: "DELETE",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
    };

    const response = yield call(request, requestURL, headers);

    yield put({
      type: "DELETE_ADDRESS_SUCCESS",
      value: response,
    });
    yield put({
      type: "HIDE_LOADING",
    });

    if (response.message) {
      yield put({
        type: "SHOW_ALERT",
        value: {
          type: "danger",
          title: "Failed delete soft",
          message: response.message,
        },
      });
    } else {
      yield put({
        type: "SHOW_ALERT",
        value: {
          type: "success",
          title: "Successful delete soft",
          message: "Successful delete soft Address",
        },
      });
    }
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed delete",
        message: "Failed delete Address",
      },
    });
    yield put({
      type: "DELETE_ADDRESS_ERROR",
    });
  }
}

function* postBulkAddress(payload) {
  try {
    yield put({ type: "CREATE_BULK_ADDRESS_REQUESTING" });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/addresses/bulkAddress`;

    const headers = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload.value),
    };

    const response = yield call(request, requestURL, headers);

    yield put({
      type: "HIDE_LOADING",
    });

    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "success",
        title: "Successful save",
        message: "Successful save Addresses",
      },
    });

    yield put({
      type: "CREATE_BULK_ADDRESS_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed save",
        message: "Failed save Addresses",
      },
    });
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "CREATE_BULK_ADDRESS_ERROR",
    });
  }
}

function* getAllAddressesWithoutOperator(payload) {
  try {
    yield put({
      type: "FETCH_ADDRESSES_WITHOUT_OPERATOR_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const headers = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
    };
    let requestURL = `${
      process.env.REACT_APP_API_URL
    }/api/v1/addresses/withoutOperator?page=${payload.value.page}&offset=${
      payload.value.offset
    }${
      payload.value.isActive ? "&isActive=" + payload.value.isActive : ""
    }&search=${payload.value.search}`;
    if (payload.value.from) {
      requestURL += `&from=${payload.value.from}`;
    }
    if (payload.value.to) {
      requestURL += `&to=${payload.value.to}`;
    }
    if (payload.value.idDepartment) {
      requestURL += `&idDepartment=${payload.value.idDepartment}`;
    }
    if (payload.value.idZone) {
      requestURL += `&idZone=${payload.value.idZone}`;
    }
    if (payload.value.idClient) {
      requestURL += `&idClient=${payload.value.idClient}`;
    }
    if (payload.value.deliveryDate) {
      requestURL += `&deliveryDate=${payload.value.deliveryDate}`;
    }

    const response = yield call(request, requestURL, headers);

    yield put({
      type: "HIDE_LOADING",
    });

    yield put({
      type: "FETCH_ADDRESS_WITHOUT_OPERATOR_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed load",
        message: "Failed load Addresses without operator",
      },
    });
    yield put({
      type: "FETCH_ADDRESS_WITHOUT_OPERATOR_ERROR",
    });
  }
}

function* patchBulkOperator(payload) {
  try {
    yield put({ type: "CREATE_BULK_OPERATOR_ADDRESS_REQUESTING" });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/addresses/bulkOperator`;

    const headers = {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload.value),
    };

    const response = yield call(request, requestURL, headers);

    yield put({
      type: "HIDE_LOADING",
    });

    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "success",
        title: "Successful save",
        message: "Successful save Addresses",
      },
    });

    yield put({
      type: "CREATE_BULK_OPERATOR_ADDRESS_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed save",
        message: "Failed save Addresses",
      },
    });
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "CREATE_BULK_OPERATOR_ADDRESS_ERROR",
    });
  }
}

function* patchGenerateGuide(payload) {
  try {
    yield put({
      type: "GENERATE_GUIDE_ADDRESS_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/addresses/generateGuide/${payload.value.idAddress}`;

    const headers = {
      method: "PATCH",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(payload.value),
    };

    const response = yield call(request, requestURL, headers);

    yield put({
      type: "HIDE_LOADING",
    });

    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "success",
        title: "Successful generate guide",
        message: "Successful generate Address guide",
      },
    });

    yield put({
      type: "GENERATE_GUIDE_ADDRESS_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed generate guide",
        message: "Failed generate Address guide",
      },
    });
    yield put({
      type: "GENERATE_GUIDE_ADDRESS_ERROR",
    });
  }
}

function* patchGenerateAllGuides(payload) {
  try {
    yield put({
      type: "GENERATE_ALL_GUIDES_ADDRESS_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/addresses/generateAllGuides`;

    const headers = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload.value),
    };

    const response = yield call(request, requestURL, headers);

    yield put({
      type: "HIDE_LOADING",
    });

    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "success",
        title: "Successful generate all guides",
        message: "Successful generate Addresses guides",
      },
    });

    yield put({
      type: "GENERATE_ALL_GUIDES_ADDRESS_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed generate all guides",
        message: "Failed generate Addresses guides",
      },
    });
    yield put({
      type: "GENERATE_ALL_GUIDES_ADDRESS_ERROR",
    });
  }
}

function* updateAddressState(payload) {
  try {
    yield put({ type: "UPDATESTATE_ADDRESS_REQUESTING" });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/addresses/changeAddressState`;

    const headers = {
      method: "PATCH",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(payload.value),
    };

    const response = yield call(request, requestURL, headers);

    yield put({
      type: "UPDATESTATE_ADDRESS_SUCCESS",
      value: response,
    });
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "success",
        title: "Successful update",
        message: "Successful update Address",
      },
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed update",
        message: "Failed update Address",
      },
    });
    yield put({
      type: "UPDATESTATE_ADDRESS_ERROR",
    });
  }
}

function* uploadFileTrackingId(payload) {
  try {
    yield put({ type: "UPLOAD_TRACKING_ID_REQUESTING" });
    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/addresses/uploadTrackingIds`;

    const formData = new FormData();
    formData.append("file", payload.value.file);

    const headers = {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
      }),
      body: formData,
    };

    const response = yield call(request, requestURL, headers);

    yield put({
      type: "UPLOAD_TRACKING_ID_SUCCESS",
      value: response,
    });

    yield put({
      type: "HIDE_LOADING",
    });

    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "success",
        title: "Successful upload",
        message: "Successful upload file",
      },
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed load",
        message: "Failed upload file",
      },
    });
    yield put({
      type: "UPLOAD_TRACKING_ID_ERROR",
    });
  }
}

function* postBulkTrackingIdAddressByOperator(payload) {
  try {
    yield put({ type: "CREATE_BULK_TRACKINGID_ADDRESS_REQUESTING" });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/addresses/bulkTrackingIdAddressByOperator`;

    const headers = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload.value),
    };

    const response = yield call(request, requestURL, headers);

    yield put({
      type: "HIDE_LOADING",
    });

    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "success",
        title: "Successful change operator",
        message: "Successful change operator",
      },
    });

    yield put({
      type: "CREATE_BULK_TRACKINGID_ADDRESS_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed save",
        message: "Failed change operator",
      },
    });
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "CREATE_BULK_TRACKINGID_ADDRESS_ERROR",
    });
  }
}

function* patchChangeTrackingIdAddressByOperator(payload) {
  try {
    yield put({
      type: "GHANGE_OPERATOR_ADDRESS_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/addresses/changeTrackingIdAddressByOperator/${payload.value.idAddress}`;

    const headers = {
      method: "PATCH",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(payload.value),
    };

    const response = yield call(request, requestURL, headers);

    yield put({
      type: "HIDE_LOADING",
    });

    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "success",
        title: "Successful change operator",
        message: "Successful change operator",
      },
    });

    yield put({
      type: "GHANGE_OPERATOR_ADDRESS_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed change operator",
        message: "Failed change operator",
      },
    });
    yield put({
      type: "GHANGE_OPERATOR_ADDRESS_ERROR",
    });
  }
}

function* getAllAddressesRescheduling(payload) {
  try {
    yield put({
      type: "FETCH_ADDRESSES_RESCHEDULING_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    let requestURL = `${process.env.REACT_APP_API_URL}/api/v1/addresses/rescheduling/?`;

    if (payload.value.from) {
      requestURL += `from=${payload.value.from}`;
    }
    if (payload.value.to) {
      requestURL += requestURL.includes("=")
        ? `&to=${payload.value.to}`
        : `to=${payload.value.to}`;
    }
    if (payload.value.idClient) {
      requestURL += requestURL.includes("=")
        ? `&idClient=${payload.value.idClient}`
        : `idClient=${payload.value.idClient}`;
    }
    if (payload.value.idOperator) {
      requestURL += requestURL.includes("=")
        ? `&idOperator=${payload.value.idOperator}`
        : `idOperator=${payload.value.idOperator}`;
    }
    if (payload.value.page) {
      requestURL += requestURL.includes("=")
        ? `&page=${payload.value.page}`
        : `page=${payload.value.page}`;
    }
    if (payload.value.offset) {
      requestURL += requestURL.includes("=")
        ? `&offset=${payload.value.offset}`
        : `offset=${payload.value.offset}`;
    }
    if (payload.value.search) {
      requestURL += requestURL.includes("=")
        ? `&search=${payload.value.search}`
        : `search=${payload.value.search}`;
    }
    if (payload.value.trafficLight) {
      requestURL += requestURL.includes("=")
        ? `&trafficLight=${payload.value.trafficLight}`
        : `trafficLight=${payload.value.trafficLight}`;
    }
    if (payload.value.novelty) {
      requestURL += requestURL.includes("=")
        ? `&novelty=${payload.value.novelty}`
        : `novelty=${payload.value.novelty}`;
    }

    const headers = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
    };

    const response = yield call(request, requestURL, headers);

    yield put({
      type: "FETCH_ADDRESSES_RESCHEDULING_SUCCESS",
      value: response,
    });
    yield put({
      type: "HIDE_LOADING",
    });
  } catch (error) {
    console.log(error);
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed load",
        message: "Failed load rescheduling",
      },
    });
    yield put({
      type: "FETCH_ADDRESSES_RESCHEDULING_ERROR",
    });
  }
}

function* getAllAddressesWithIncidences(payload) {
  try {
    yield put({
      type: "FETCH_ADDRESSES_INCIDENCES_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    let requestURL = `${process.env.REACT_APP_API_URL}/api/v1/addresses/incidences/?`;

    if (payload.value.from) {
      requestURL += `from=${payload.value.from}`;
    }
    if (payload.value.to) {
      requestURL += requestURL.includes("=")
        ? `&to=${payload.value.to}`
        : `to=${payload.value.to}`;
    }
    if (payload.value.idClient) {
      requestURL += requestURL.includes("=")
        ? `&shipper=${payload.value.shipper}`
        : `shipper=${payload.value.shipper}`;
    }
    if (payload.value.idOperator) {
      requestURL += requestURL.includes("=")
        ? `&operator=${payload.value.operator}`
        : `operator=${payload.value.operator}`;
    }
    if (payload.value.page) {
      requestURL += requestURL.includes("=")
        ? `&page=${payload.value.page}`
        : `page=${payload.value.page}`;
    }
    if (payload.value.offset) {
      requestURL += requestURL.includes("=")
        ? `&offset=${payload.value.offset}`
        : `offset=${payload.value.offset}`;
    }
    if (payload.value.search) {
      requestURL += requestURL.includes("=")
        ? `&search=${payload.value.search}`
        : `search=${payload.value.search}`;
    }
    if (payload.value.novelty) {
      requestURL += requestURL.includes("=")
        ? `&novelty=${payload.value.novelty}`
        : `novelty=${payload.value.novelty}`;
    }

    const headers = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
    };

    const response = yield call(request, requestURL, headers);

    yield put({
      type: "FETCH_ADDRESSES_INCIDENCES_SUCCESS",
      value: response,
    });

  } catch (error) {
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed load",
        message: "Failed load rescheduling",
      },
    });
    yield put({
      type: "FETCH_ADDRESSES_INCIDENCES_ERROR",
    });
  } finally {
    yield put({
      type: "HIDE_LOADING",
    });
  }
}

function* uploadReschedulingToGoogleDrive(payload) {
  try {
    yield put({
      type: "FETCH_RESCHEDULING_UPLOAD_DRIVE_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    let requestURL = `${process.env.REACT_APP_API_URL}/api/v1/uploadRescheduleToGoogleSheets/?`;

    if (payload.value.from) {
      requestURL += `from=${payload.value.from}`;
    }
    if (payload.value.to) {
      requestURL += requestURL.includes("=")
        ? `&to=${payload.value.to}`
        : `to=${payload.value.to}`;
    }
    if (payload.value.idClient) {
      requestURL += requestURL.includes("=")
        ? `&idClient=${payload.value.idClient}`
        : `idClient=${payload.value.idClient}`;
    }
    if (payload.value.idOperator) {
      requestURL += requestURL.includes("=")
        ? `&idOperator=${payload.value.idOperator}`
        : `idOperator=${payload.value.idOperator}`;
    }
    if (payload.value.page) {
      requestURL += requestURL.includes("=")
        ? `&page=${payload.value.page}`
        : `page=${payload.value.page}`;
    }
    if (payload.value.offset) {
      requestURL += requestURL.includes("=")
        ? `&offset=${payload.value.offset}`
        : `offset=${payload.value.offset}`;
    }
    if (payload.value.search) {
      requestURL += requestURL.includes("=")
        ? `&search=${payload.value.search}`
        : `search=${payload.value.search}`;
    }
    if (payload.value.trafficLight) {
      requestURL += requestURL.includes("=")
        ? `&trafficLight=${payload.value.trafficLight}`
        : `trafficLight=${payload.value.trafficLight}`;
    }

    const headers = {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
    };
    const response = yield call(request, requestURL, headers);
    console.log(response)
    yield put({
      type: "HIDE_LOADING",
    });
  } catch (error) {
    console.log(error);
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed load",
        message: "Failed load rescheduling",
      },
    });
  }
}


function* patchAddressReschedule(payload) {
  try {
    yield put({
      type: "UPDATE_ADDRESS_RESCHEDULE_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/addresses/reschedule/${payload.value.id}`;

    const headers = {
      method: "PATCH",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(payload.value),
    };

    const response = yield call(request, requestURL, headers);

      yield put({
        type: "HIDE_LOADING",
      });
  
      yield put({
        type: "SHOW_ALERT",
        value: {
          type: "success",
          title: "Successful update",
          message: "Successful update rescheduled Address",
        },
      });
  
      yield put({
        type: "UPDATE_ADDRESS_RESCHEDULE_SUCCESS",
        value: response,
      });
    

    
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed update",
        message: "Address is incorrect or incomplete, please try again.",
      },
    });
    yield put({
      type: "UPDATE_ADDRESS_RESCHEDULE_ERROR",
    });
  }
}

function* getAllAddressesRescheduled(payload) {
  try {
    yield put({
      type: "FETCH_ADDRESSES_RESCHEDULED_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    let requestURL = `${process.env.REACT_APP_API_URL}/api/v1/addresses/rescheduled/?`;

    if (payload.value.from) {
      requestURL += `from=${payload.value.from}`;
    }
    if (payload.value.to) {
      requestURL += requestURL.includes("=")
        ? `&to=${payload.value.to}`
        : `to=${payload.value.to}`;
    }
    if (payload.value.idClient) {
      requestURL += requestURL.includes("=")
        ? `&idClient=${payload.value.idClient}`
        : `idClient=${payload.value.idClient}`;
    }
    if (payload.value.idOperator) {
      requestURL += requestURL.includes("=")
        ? `&idOperator=${payload.value.idOperator}`
        : `idOperator=${payload.value.idOperator}`;
    }
    if (payload.value.page) {
      requestURL += requestURL.includes("=")
        ? `&page=${payload.value.page}`
        : `page=${payload.value.page}`;
    }
    if (payload.value.offset) {
      requestURL += requestURL.includes("=")
        ? `&offset=${payload.value.offset}`
        : `offset=${payload.value.offset}`;
    }
    if (payload.value.search) {
      requestURL += requestURL.includes("=")
        ? `&search=${payload.value.search}`
        : `search=${payload.value.search}`;
    }
    if (payload.value.delivery) {
      requestURL += requestURL.includes("=")
        ? `&delivery=${payload.value.delivery}`
        : `delivery=${payload.value.delivery}`;
    }

    const headers = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "FETCH_ADDRESSES_RESCHEDULED_SUCCESS",
      value: response,
    });
    yield put({
      type: "HIDE_LOADING",
    });
  } catch (error) {
    console.log(error);
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed load",
        message: "Failed load rescheduled",
      },
    });
    yield put({
      type: "FETCH_ADDRESSES_RESCHEDULED_ERROR",
    });
  }
}

function* patchGuidesState(payload) {
  try {
    yield put({
      type: "UPDATE_GUIDES_STATE_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `/api/v1/addresses/updateGuides/states`;

    const formData = new FormData();
    formData.append("byTrackings", payload.value.byTrackings);
    formData.append("trackings", payload.value.trackings);
    formData.append("state", payload.value.state.value);
    formData.append("novelty", payload.value.novelty.value);
    if(payload.value.comment) formData.append("comment", payload.value.comment);

    if(payload.value.evidenceImage)
      formData.append("file", payload.value.evidenceImage[0]);

    if(payload.value.courier)
      formData.append("courier", payload.value.courier.value);

    const headers = {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
      },
      body: formData,
    };

    const response = yield call(requestAxios, requestURL, headers);

    yield put({
      type: "HIDE_LOADING",
    });

    yield put({
      type: "UPDATE_GUIDES_STATE_SUCCESS",
      value: response,
    });

    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "success",
        title: "Successful update",
        message: "Successful update guides states",
      },
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed update",
        message: "Failed update guides states",
      },
    });
    yield put({
      type: "UPDATE_GUIDES_STATE_ERROR",
    });
  }
}

function* uploadFileAddressesStates(payload) {
  try {
    yield put({ type: "UPLOAD_ADDRESSES_STATES_REQUESTING" });
    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/addresses/uploadGuides/states`;

    const formData = new FormData();
    formData.append("file", payload.value.file);

    const headers = {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
      }),
      body: formData,
    };

    const response = yield call(request, requestURL, headers);

    yield put({
      type: "UPLOAD_ADDRESSES_STATES_SUCCESS",
      value: response,
    });

    yield put({
      type: "HIDE_LOADING",
    });

    if (
      response.errorState ||
      response.errorCourier ||
      response.errorRecord ||
      response.errorNote
    ) {
      yield put({
        type: "SHOW_ALERT",
        value: {
          type: "danger",
          title: "Failed upload",
          message: "Failed upload",
        },
      });
    } else {
      yield put({
        type: "SHOW_ALERT",
        value: {
          type: "success",
          title: "Successful upload guides",
          message: "Successful upload file to update states",
        },
      });
    }
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed load guides",
        message: "Failed upload file to update states",
      },
    });
    yield put({
      type: "UPLOAD_ADDRESSES_STATES_ERROR",
    });
  }
}

function* deleteAddresses(payload) {
  try {
    yield put({
      type: "DELETE_ADDRESSES_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/addresses/deleteSoftAddresses`;

    const headers = {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload.value),
    };

    const response = yield call(request, requestURL, headers);

    yield put({
      type: "HIDE_LOADING",
    });

    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "success",
        title: "Successful delete addresses",
        message: "Successful delete addresses",
      },
    });

    yield put({
      type: "DELETE_ADDRESSES_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed delete addresses",
        message: "Failed delete addresses",
      },
    });
    yield put({
      type: "DELETE_ADDRESSES_ERROR",
    });
  }
}

function* reverseAddresses(payload) {
  try {
    yield put({
      type: "REVERSE_ADDRESSES_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/addresses/reverseAddresses`;

    const headers = {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload.value),
    };

    const response = yield call(request, requestURL, headers);

    yield put({
      type: "HIDE_LOADING",
    });

    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "success",
        title: "Successful reverse addresses",
        message: "Successful reverse addresses",
      },
    });

    yield put({
      type: "REVERSE_ADDRESSES_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed reverse addresses",
        message: "Failed reverse addresses",
      },
    });
    yield put({
      type: "REVERSE_ADDRESSES_ERROR",
    });
  }
}

function* reverseAddressById(payload) {
  try {
    yield put({
      type: "REVERSE_ADDRESS_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/addresses/reverse/${payload.value.idAddress}`;

    const headers = {
      method: "PATCH",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
    };

    const response = yield call(request, requestURL, headers);

    yield put({
      type: "REVERSE_ADDRESS_SUCCESS",
      value: response,
    });
    yield put({
      type: "HIDE_LOADING",
    });

    if (response.message) {
      yield put({
        type: "SHOW_ALERT",
        value: {
          type: "danger",
          title: "Failed reverse address",
          message: response.message,
        },
      });
    } else {
      yield put({
        type: "SHOW_ALERT",
        value: {
          type: "success",
          title: "Successful reverse",
          message: "Successful reverse address",
        },
      });
    }
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed reverse",
        message: "Failed reverse Address",
      },
    });
    yield put({
      type: "REVERSE_ADDRESS_ERROR",
    });
  }
}

export function* watchAddress() {
  yield takeLatest("FETCH_ADDRESSES_REQUEST", getAllAddresses);
  yield takeLatest("CREATE_ADDRESS_REQUEST", postAddress);
  yield takeLatest("READ_ADDRESS_REQUEST", getAddressById);
  yield takeLatest("READBYCITY_ADDRESS_REQUEST", getAddressByIdCity);
  yield takeLatest("READBYZONE_ADDRESS_REQUEST", getAddressByIdZone);
  yield takeLatest("DISABLE_ADDRESS_REQUEST", disableAddressById);
  yield takeLatest("DELETE_ADDRESS_REQUEST", deleteAddressById);
  yield takeLatest("UPDATE_ADDRESS_REQUEST", updateAddress);
  yield takeLatest("UPLOAD_ADDRESS_REQUEST", uploadFileAddress);
  yield takeLatest("UPDATE_UPLOAD_ADDRESS_REQUEST", uploadUpdateAddressFile);
  yield takeLatest("FETCH_INVALID_ADDRESSES", getAllAddressesWithInvalidAddress);
  yield takeLatest(
    "FETCH_AVAILABLE_ADDRESSES_REQUEST",
    getAllAddressesAvailable
  );
  yield takeLatest("CREATE_BULK_ADDRESS_REQUEST", postBulkAddress);
  yield takeLatest(
    "FETCH_ADDRESSES_WITHOUT_OPERATOR_REQUEST",
    getAllAddressesWithoutOperator
  );
  yield takeLatest("UPDATE_BULK_OPERATOR_ADDRESS_REQUEST", patchBulkOperator);
  yield takeLatest("GENERATE_GUIDE_ADDRESS_REQUEST", patchGenerateGuide);
  yield takeLatest(
    "GENERATE_ALL_GUIDES_ADDRESS_REQUEST",
    patchGenerateAllGuides
  );
  yield takeLatest("UPDATESTATE_ADDRESS_REQUEST", updateAddressState);
  yield takeLatest("UPLOAD_TRACKING_ID_REQUEST", uploadFileTrackingId);
  yield takeLatest(
    "CREATE_BULK_TRACKINGID_ADDRESS_REQUEST",
    postBulkTrackingIdAddressByOperator
  );
  yield takeLatest(
    "GHANGE_OPERATOR_ADDRESS_REQUEST",
    patchChangeTrackingIdAddressByOperator
  );
  yield takeLatest(
    "FETCH_ADDRESSES_RESCHEDULING_REQUEST",
    getAllAddressesRescheduling
  );
  yield takeLatest(
    "FETCH_RESCHEDULING_UPLOAD_DRIVE_REQUEST",
    uploadReschedulingToGoogleDrive
  );
  yield takeLatest("FETCH_ADDRESS_RESCHEDULE_REQUEST", patchAddressReschedule);
  yield takeLatest(
    "FETCH_ADDRESSES_RESCHEDULED_REQUEST",
    getAllAddressesRescheduled
  );
  yield takeLatest("UPDATE_GUIDES_STATE_REQUEST", patchGuidesState);
  yield takeLatest(
    "UPLOAD_ADDRESSES_STATES_REQUEST",
    uploadFileAddressesStates
  );
  yield takeLatest("DELETE_ADDRESSES_REQUEST", deleteAddresses);
  yield takeLatest("REVERSE_ADDRESSES_REQUEST", reverseAddresses);
  yield takeLatest("REVERSE_ADDRESS_REQUEST", reverseAddressById);
  yield takeLatest("FETCH_ADDRESSES_INCIDENCES_REQUEST", getAllAddressesWithIncidences)
}
