import React, { forwardRef, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import Select from "react-select";
import moment from "moment-timezone";

import Sidebar from "../../../components/Navigation/Sidebar";
import Topbar from "../../../components/Navigation/Topbar";
import Grid from "../../../components/Grid";

const Swal = require("sweetalert2");

const RecordsReport = ({
  reduxGetClients,
  clients,
  reduxGetLogisticsOperators,
  logisticsOperators,
  confirmReport,
  reduxGetConfirmReport,
  reduxGetCompanies,
  companies,
  reduxResetConfirmReportForm,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    getValues,
  } = useForm();
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(10);
  const [search, setSearch] = useState("");
  const [isSearched, setIsSearched] = useState(false);

  const downloadByProduct = () => {
    if (toDate < fromDate) {
      Swal.fire(
        "Información",
        "La fecha inicial debe ser  menor a la fecha final , intenta de nuevo",
        "info"
      );
      return;
    }
    const token = window.localStorage.getItem("token");
    let _data = {
      from: getValues("from"),
      to: getValues("to"),
      senderIds: getValues("senderIds"),
      operatorIds: getValues("operatorIds"),
      confirmType: getValues("confirmType"),
      page: currentPage,
      offset: offset,
      search: search ? search : "",
    };

    fetch(`${process.env.REACT_APP_REPORT_URL}/api/v1/reports/confirm`, {
      method: "POST",
      body: JSON.stringify(_data),
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      }),
    })
      .then((response) => response.blob())
      .then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = `confirm_report_${moment(new Date())
          .tz("America/Bogota")
          .format("YYYYMMDD_hhmm")}.csv`;
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
  };

  const downloadGeneral = () => {
    if (toDate < fromDate) {
      Swal.fire(
        "Información",
        "La fecha inicial debe ser  menor a la fecha final , intenta de nuevo",
        "info"
      );
      return;
    }
    const token = window.localStorage.getItem("token");
    let _data = {
      from: getValues("from"),
      to: getValues("to"),
      senderIds: getValues("senderIds"),
      operatorIds: getValues("operatorIds"),
      confirmType: getValues("confirmType"),
      page: currentPage,
      offset: offset,
      search: search ? search : "",
    };

    fetch(`${process.env.REACT_APP_REPORT_URL}/api/v1/reports/confirmGeneral`, {
      method: "POST",
      body: JSON.stringify(_data),
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      }),
    })
      .then((response) => response.blob())
      .then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = `confirm_general_report_${moment(new Date())
          .tz("America/Bogota")
          .format("YYYYMMDD_hhmm")}.csv`;
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
  };

  const handleChangeMultiSelect = (type, value) => {
    if(type === 'Sender'){
      const newSenderIds = {
        ...getValues('senderIds'),
        value
      }
      setValue("senderIds", String(newSenderIds.value.map(item => item.value)));
    }else if(type === 'Operator'){
      const newOperatorIds = {
        ...getValues('operatorIds'),
        value
      }
      setValue("operatorIds", String(newOperatorIds.value.map(item => item.value)));
    }
  }

  const handleChangeDatePicker = (dates) => {
    const [start, end] = dates;

    setFromDate(start);
    setToDate(end);

    setValue("from", moment(start).tz("America/Bogota").format("MM-DD-YYYY"));
    setValue("to", moment(end).tz("America/Bogota").format("MM-DD-YYYY"));
  };

  const onSubmit = (data) => {
    if (toDate < fromDate) {
      Swal.fire(
        "Información",
        "La fecha inicial debe ser  menor a la fecha final , intenta de nuevo",
        "info"
      );

      return;
    }
    setIsSearched(true);
    reduxGetConfirmReport({
      ...data,
      page: 1,
      search: search ? search : "",
      offset: offset,
    });
  };

  const CustomDateRangePicker = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      className="form-control"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
      value={value}
      autoComplete="Off"
    />
  ));

  useEffect(() => {
    reduxGetClients();
    reduxGetLogisticsOperators();
    reduxGetCompanies({
      page: 1,
      search: "",
      offset: 1000,
    });
    reset();
    reduxResetConfirmReportForm();
    setFromDate(null);
    setToDate(null);
  }, []);

  const columns = [
    {
      title: "# Dirección",
      render: (rowData) => {
        return <span>{rowData.idAddress}</span>;
      },
      field: "idAddress",
      searchable: true,
    },
    {
      title: "Guía",
      render: (rowData) => {
        return <span>{rowData.trackingId}</span>;
      },
      field: "trackingId",
      searchable: true,
    },
    {
      title: "Valor recaudado",
      render: (rowData) => {
        return <span> {rowData.collection} </span>;
      },
      field: "collection",
      searchable: true,
    },
    {
      title: "Operador",
      render: (rowData) => {
        return <span>{rowData.operator}</span>;
      },
      field: "operator",
      searchable: true,
    },
    {
      title: "Ciudad",
      render: (rowData) => {
        return <span>{rowData.city}</span>;
      },
      field: "city",
      searchable: true,
    },
    {
      title: "Departamento",
      render: (rowData) => {
        return <span>{rowData.department}</span>;
      },
      field: "department",
      searchable: true,
    },
    {
      title: "Remitente",
      render: (rowData) => {
        return <span>{rowData.client}</span>;
      },
      field: "sender",
      searchable: true,
    },
    {
      title: "Forma de pago del remitente",
      render: (rowData) => {
        return <span>{rowData.senderPayMethod}</span>;
      },
      field: "senderPayMethod",
      searchable: false,
    },
    {
      title: "Fecha de pago del remitente",
      render: (rowData) => {
        return <span>{rowData.senderConfirmDate}</span>;
      },
      field: "senderConfirmDate",
      searchable: false,
    },
    {
      title: "Obs. Pago del remitente",
      render: (rowData) => {
        return <span>{rowData.senderConfirmComments}</span>;
      },
      field: "senderConfirmComments",
      searchable: false,
    },
    {
      title: "Forma de pago del operador",
      render: (rowData) => {
        return <span>{rowData.operatorPayMethod}</span>;
      },
      field: "operatorPayMethod",
      searchable: false,
    },
    {
      title: "Fecha de pago del operador",
      render: (rowData) => {
        return <span>{rowData.operatorConfirmDate}</span>;
      },
      field: "operatorConfirmDate",
      searchable: false,
    },
    {
      title: "Obs. Pago del operador",
      render: (rowData) => {
        return <span>{rowData.operatorConfirmComments}</span>;
      },
      field: "operatorConfirmComments",
      searchable: false,
    },
  ];

  useEffect(() => {
    if (isSearched) {
      let filters = {
        page: currentPage,
        offset: offset,
        search: search,
      };
      if (getValues("senderIds")) {
        filters = {
          ...filters,
          senderIds: getValues("senderIds"),
        };
      }
      if (getValues("operatorIds")) {
        filters = {
          ...filters,
          operatorIds: getValues("operatorIds"),
        };
      }
      if (getValues("from") && getValues("to")) {
        filters = {
          ...filters,
          from: moment(getValues("from"))
            .tz("America/Bogota")
            .format("MM-DD-YYYY"),
          to: moment(getValues("to")).tz("America/Bogota").format("MM-DD-YYYY"),
        };
      }
      if(getValues("confirmType")){
        filters = {
          ...filters,
          confirmType: getValues("confirmType"),
        }
      }
      reduxGetConfirmReport(filters);
    }
  }, [currentPage, isSearched]);

  return (
    <div>
      <div id="wrapper">
        <Sidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Topbar />
            <div className="container-fluid">
              <div className="d-sm-flex align-items-center mb-1">
                <h1 className="h3 mb-0 text-gray-800 mr-3">
                  Reporte confirmación de recaudo
                </h1>
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="d-flex justify-content-between align-items-start my-4 px-1 flex-wrap">
                  <div className="d-flex">
                    <div className="search-field mx-1">
                      <label htmlFor="from" className="form-label">Desde - Hasta</label>
                        <DatePicker
                            selected={fromDate}
                            onChange={handleChangeDatePicker}
                            startDate={fromDate}
                            endDate={toDate}
                            maxDate={new Date()}
                            dateFormat="MM-dd-yyyy"
                            customInput={<CustomDateRangePicker />}
                            selectsRange
                            />
                    </div>
                    <div className="search-field mx-1">
                      <label htmlFor="senderIds" className="form-label">
                        Remitente
                      </label>
                      <Select
                        {...register("senderIds")}
                        isClearable={true}
                        isMulti
                        id="senderIds"
                        onChange={(value) => handleChangeMultiSelect('Sender', value)}
                        options={
                          clients && Object.keys(clients).length > 0
                            ? clients.items
                              .filter((f) => f.isActive)
                              .map((ele, key) => ({
                                value: ele.idCompany,
                                label: ele.description,
                              }))
                            : []
                        }
                        styles={{
                          valueContainer: (baseStyles) => ({...baseStyles, maxHeight:"3.5vw", overflowY: "auto" }),
                          control: (baseStyles) => ({ ...baseStyles, flexWrap: "nowrap"})
                        }}
                      />
                    </div>
                    <div className="search-field mx-1">
                      <label htmlFor="operatorIds" className="form-label">
                        Operador
                      </label>
                      <Select
                        {...register("operatorIds")}
                        isClearable={true}
                        isMulti
                        maxMenuHeight={250}
                        id="operatorIds"
                        onChange={(value) => handleChangeMultiSelect('Operator', value)}
                        options={
                          logisticsOperators &&
                          Object.keys(logisticsOperators).length > 0 &&
                          logisticsOperators.items
                            .filter((f) => f.isActive === true)
                            .map((ele, key) => ({
                              value: ele.idCompany,
                              label: ele.description,
                            }))
                        }
                        styles={{
                          valueContainer: (baseStyles) => ({...baseStyles, maxHeight:"3.5vw", overflowY: "auto" }),
                          control: (baseStyles) => ({ ...baseStyles, flexWrap: "nowrap"})
                        }}
                      />
                    </div>
                    <div className="search-field mx-2" style={{width: "11vw"}}>
                       <label className="form-label">
                        Confirmación de
                      </label>
                      <div className="d-flex justify-content-between">
                        <label>
                          <input {...register("confirmType")}
                            id="confirmType"
                            type="checkbox"
                            class="mr-2"
                            value={1} />
                            Remitente
                        </label>
                        <label>
                          <input {...register("confirmType")}
                            id="confirmType"
                            type="checkbox"
                            class="mr-2"
                            value={2} />
                            Operador
                        </label>
                      </div>
                    </div>
                    <div className="d-flex justify-content-around align-items-start my-4">
                      <Button variant="primary" type="submit" id="btnSearch" className="mx-3">Buscar</Button>
                    </div>
                  </div>
                  <div className="d-flex justify-content-around align-items-start my-4">
                    {confirmReport &&
                      confirmReport.items &&
                      Object.keys(confirmReport.items).length > 0 && (
                        <>
                          <Button
                            variant="primary"
                            id="btnSearch"
                            className="mx-1"
                            style={{ width: "100" }}
                            onClick={downloadByProduct}
                          >
                            Descarga por producto
                          </Button>
                          <Button
                            variant="primary"
                            id="btnSearch"
                            className="mx-1"
                            onClick={downloadGeneral}
                          >
                            Descarga general
                          </Button>
                        </>
                      )}
                  </div>
                </div>
              </form>
              <div className="card shadow mb-4">
                <div className="card-body">
                  <Grid
                    cols={columns}
                    data={
                      confirmReport && Object.keys(confirmReport).length > 0
                        ? confirmReport.items
                        : []
                    }
                    page={
                      confirmReport && Object.keys(confirmReport).length > 0
                        ? Number(confirmReport.page)
                        : currentPage
                    }
                    total={
                      confirmReport && confirmReport.hasOwnProperty("total")
                        ? confirmReport.total
                        : 0
                    }
                    pages={
                      confirmReport && Object.keys(confirmReport).length > 0
                        ? Number(confirmReport.totalPages)
                        : 1
                    }
                    offset={offset}
                    onChangePage={(page) => setCurrentPage(page)}
                    onChangeRange={(value) => {
                      setOffset(value);
                      let filters = {
                        page: 1,
                        offset: value,
                        search: search,
                      };
                      if (fromDate && toDate) {
                        if (toDate < fromDate) {
                          Swal.fire(
                            "Información",
                            "La fecha inicial debe ser  menor a la fecha final , intenta de nuevo",
                            "info"
                          );
                          return;
                        }
                        filters = {
                          ...filters,
                          from: moment(fromDate)
                            .tz("America/Bogota")
                            .format("YYYY-MM-DD"),
                          to: moment(toDate)
                            .tz("America/Bogota")
                            .format("YYYY-MM-DD"),
                        };
                      }

                      if (
                        getValues("senderIds") != "" &&
                        getValues("senderIds") != undefined
                      ) {
                        filters = {
                          ...filters,
                          senderIds: getValues("senderIds"),
                        };
                      }
                      if (
                        getValues("operatorIds") != "" &&
                        getValues("operatorIds") != undefined
                      ) {
                        filters = {
                          ...filters,
                          operatorIds: getValues("operatorIds"),
                        };
                      }
                      reduxGetConfirmReport(filters);
                    }}
                    defaultValue={search}
                    onChangeSearch={(value) => {
                      setSearch(value);
                      let filters = {
                        page: 1,
                        offset: offset,
                        search: value,
                      };
                      if (fromDate && toDate) {
                        if (toDate < fromDate) {
                          Swal.fire(
                            "Información",
                            "La fecha inicial debe ser  menor a la fecha final , intenta de nuevo",
                            "info"
                          );
                          return;
                        }
                        filters = {
                          ...filters,
                          from: moment(fromDate)
                            .tz("America/Bogota")
                            .format("YYYY-MM-DD"),
                          to: moment(toDate)
                            .tz("America/Bogota")
                            .format("YYYY-MM-DD"),
                        };
                      }

                      if (
                        getValues("senderIds") != "" &&
                        getValues("senderIds") != undefined
                      ) {
                        filters = {
                          ...filters,
                          senderIds: getValues("senderIds"),
                        };
                      }
                      if (
                        getValues("operatorIds") != "" &&
                        getValues("operatorIds") != undefined
                      ) {
                        filters = {
                          ...filters,
                          operatorIds: getValues("operatorIds"),
                        };
                      }
                      reduxGetConfirmReport(filters);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    clients: state.companyState.clients,
    logisticsOperators: state.companyState.logisticsOperators,
    confirmReport: state.reportState.confirm,
    companies: state.companyState.companies,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    reduxGetClients: (payload) =>
      dispatch({
        type: 'FETCH_COMPANIESBYSHIPPER_REQUEST',
        value: payload,
      }),
    reduxGetLogisticsOperators: (payload) =>
      dispatch({
        type: 'FETCH_COMPANIESBYLOGISTICSOPERATOR_REQUEST',
        value: payload,
      }),
    reduxGetConfirmReport: (payload) =>
      dispatch({
        type: "FETCH_CONFIRMREPORT_REQUEST",
        value: payload,
      }),
    reduxResetConfirmReportForm: () =>
      dispatch({
        type: "RESET_CONFIRMREPORT_FORM",
      }),
    reduxGetCompanies: (payload) =>
      dispatch({
        type: "FETCH_COMPANIES_REQUEST",
        value: payload,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RecordsReport);
