import React, { useEffect, useState } from "react";
import Sidebar from "../../components/Navigation/Sidebar";
import Topbar from "../../components/Navigation/Topbar";
import Grid from "../../components/Grid";
import { connect } from "react-redux";
import { Show } from "../../hooks/Show";
import { CityModal } from "./CityModal";

const City = ({
  departments,
  reduxGetDepartments,
  cities,
  city,
  reduxGetCities,
  reduxPostCity,
  reduxGetCity,
  rowEdited,
  reduxDeleteCity,
  reduxPatchCity,
  reduxResetCityForm,
}) => {

  const [offset, setOffset] = useState(10);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const loadData = async () => {
    await reduxGetDepartments({
      page,
      offset,
      offset: 1000,
      search: ''
    });
    await reduxGetCities({
      page,
      offset,
      search
    })
  };

  const handleCreateClick = () => {
    reduxResetCityForm();
    setEditMode(false);
    setShowModal(true);
  };

  const handleEditClick = async (row) => {
    setEditMode(true);
    reduxGetCity({ id: row.idCity });
    setShowModal(true);
  };

  const handleDelete = async (row) => {    
    try {
      await Promise.all([
        reduxDeleteCity(row),
        reduxResetCityForm(),
        loadData()
      ]);
    } catch (error) {
      console.log(`Error al ejecutar las funciones: ${error}`);
    }
    await loadData();
  };

  const handleSubmit = async (event, currentDepartment) => {
    try {
      event.preventDefault();
      const form = event.target.elements;

      const data = {
        code: form.code.value,
        description: form.description.value,
        idDepartment: currentDepartment,
      };

      if (editMode) {
        data.id = form.idCity.value
        await reduxPatchCity(data);
      } else {
        await reduxPostCity(data);
      }
      await reduxResetCityForm();
      await loadData();
    } catch (error) {
      console.log(error);
    }
  };

  const columns = [
    {
      title: "#",
      render: (rowData) => {
        return <span>{rowData.idCity}</span>;
      },
      field: 'idCity',
      searchable: true
    },
    {
      title: "Código",
      render: (rowData) => {
        return <span>{rowData.code}</span>;
      },
      field: 'code',
      searchable: true
    },
    {
      title: "Descripción",
      render: (rowData) => {
        return <span>{rowData.description}</span>;
      },
      field: 'description',
      searchable: true
    },
    {
      title: "Departamento",
      render: (rowData) => {
        return <span>{rowData.department.description}</span>;
      },
      field: 'department.description',
      searchable: true
    },
    {
      title: "Opciones",
      render: (rowData) => {
        return (
          <>
            {rowData.isActive ? (
              <>
                <Show when="feature:edit-city">
                  <button
                    title="Editar"
                    className="btn btn-primary btn-sm  btn-circle mr-2"
                    type="button"
                    onClick={(e) => handleEditClick(rowData)}
                  >
                    <i className="fas fa-edit fa-xs"></i>
                  </button>
                </Show>
                <Show when="feature:disabled-city">
                  <button
                    title="Desactivar"
                    className="btn btn-danger btn-sm btn-circle"
                    type="button"
                    onClick={(e) => handleDelete(rowData)}
                  >
                    <i className="fas fa-times-circle fa-xs"></i>
                  </button>
                </Show>
              </>
            ) : (
              <Show when="feature:enabled-city">
                <button
                  title="Activar"
                  className="btn btn-primary btn-sm  btn-circle mr-2"
                  type="button"
                  onClick={(e) => handleDelete(rowData)}
                >
                  <i className="fas fa-check-circle fa-xs"></i>
                </button>
              </Show>
            )}
          </>
        );
      },
    },
  ];

  useEffect(() => {
    loadData().then();
  }, [page, offset, search, rowEdited]);


  return (
    <div>
      <div id="wrapper">
        <Sidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Topbar />
            <div className="container-fluid">
              <div className="d-sm-flex align-items-center mb-1">
                <h1 className="h3 mb-0 text-gray-800 mr-3">Ciudades</h1>
                <Show when="feature:create-city">
                  <button
                    className="btn btn-primary btn-circle"
                    type="button"
                    onClick={handleCreateClick}
                  >
                    <i className="fas fa-plus fa-sm"></i>
                  </button>
                </Show>
              </div>
              <p className="mb-4">Módulo de Administración de ciudades</p>
              <div className="card shadow mb-4">
                <div className="card-header py-3">
                  <h6 className="m-0 font-weight-bold text-primary">
                    Listado de Ciudades
                  </h6>
                </div>
                <div className="card-body">
                  <Grid
                    cols={columns}
                    data={cities.items}
                    page={page}
                    pages={cities ? Number(cities.totalPages) : 0}
                    total={cities ? cities.total : 0}
                    offset={offset}
                    onChangePage={(page) => setPage(page)}
                    onChangeRange={(value) => {
                      setOffset(value);
                      setPage(1)
                    }}
                    defaultValue={search}
                    onChangeSearch={(value) => {
                      setSearch(value);
                      setPage(1)
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
      
      <CityModal 
        city={rowEdited}
        show={showModal}
        onHide={() => {
          setShowModal(false);
          reduxResetCityForm();
        }}
        editMode={editMode}
        submit={handleSubmit}
        departments={departments.items}
        postsubmit={loadData}
      />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    departments: state.departmentState.departments,
    cities: state.cityState.cities,
    city: state.cityState.city,
    rowEdited: state.cityState.rowEdited,
    rowDeleted: state.cityState.rowDeleted,
    rowUpdated: state.cityState.rowUpdated,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    reduxGetDepartments: (payload) =>
      dispatch({
        type: "FETCH_DEPARTMENTS_REQUEST",
        value: payload,
      }),
    reduxGetCities: (payload) =>
      dispatch({
        type: "FETCH_CITIES_REQUEST",
        value: payload,
      }),
    reduxPostCity: (payload) =>
      dispatch({
        type: "CREATE_CITY_REQUEST",
        value: payload,
      }),
    reduxGetCity: (payload) =>
      dispatch({
        type: "READ_CITY_REQUEST",
        value: payload,
      }),
    reduxDeleteCity: (payload) =>
      dispatch({
        type: "DELETE_CITY_REQUEST",
        value: payload,
      }),
    reduxPatchCity: (payload) =>
      dispatch({
        type: "UPDATE_CITY_REQUEST",
        value: payload,
      }),
    reduxResetCityForm: () =>
      dispatch({
        type: "RESET_CITY_FORM",
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(City);
