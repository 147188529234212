import React, { useEffect, useState, useContext } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import Grid from "../../../components/Grid";
import { PermissionContext } from "../../../hooks/PermissionContext";
import { getStatusBadgeByCode } from "../../../utils/StatusUtils";

const TableFinished = ({ onSubmit, data, paginate, searchRedux, camposDeFiltro }) => {
    const { hasPermission } = useContext(PermissionContext);

    useEffect(() => {
        let filters = {
            ...camposDeFiltro,
            page: paginate.currentPage,
            offset: paginate.offset,
            search: paginate.search,
        };
        if (!!camposDeFiltro.startDate && !!camposDeFiltro.endDate) {
            searchRedux(filters)
        }
    }, [paginate.currentPage])

    const columns = [
        {
            title: "#",
            render: (rowData) => {
                return <span>{rowData.idAddress}</span>;
            },
            field: 'idAddress',
            searchable: true
        },
        {
            title: "Cliente",
            render: (rowData) => {
                return <span>{rowData.client}</span>;
            },
            field: 'address.company.description',
            searchable: true
        },
        {
            title: "Ciudad",
            render: (rowData) => {
                return <span>{rowData.city}</span>;
            },
        },
        {
            title: "Operador",
            render: (rowData) => {
                return <span>{rowData.operator}</span>;
            },
        },
        {
            title: "Guia",
            render: (rowData) => {
                return <span>{rowData.trackingId}</span>;
            },
            field: 'address.trackingId',
            searchable: true
        },
        {
            title: "Mensajero",
            render: (rowData) => {
                return <span>{rowData.courier}</span>;
            },
        },
        ...(hasPermission('feature:show-recipient-address')
            ? [
                {
                    title: "Dirección Destinatario",
                    render: (rowData) => {
                        return <span>{rowData.address}</span>;
                    },
                    field: 'address.address',
                    searchable: true
                },
            ]
            : []),
        {
            title: "Valor recaudo",
            render: (rowData) => {
                return <span>{rowData.collection}</span>;
            },
            field: 'address.collection',
            searchable: true
        },
        {
            title: "Estado",
            render: (rowData) => {
                return <span>{getStatusBadgeByCode(rowData.stateCode)}</span>;
            },
            field: 'address.state',
            searchable: true
        },
        {
            title: "Confirmación de Cierre",
            render: (rowData) => {
                return <span>{rowData.finished}</span>;
            },
            field: 'finished',
            searchable: false
        },
    ];

    return (
        <>
            <Grid
                cols={columns}
                data={
                    !!data && Object.keys(data).length > 0
                        ? data.items
                        : []
                }
                page={
                    data && Object.keys(data).length > 0
                        ? Number(data.page)
                        : paginate.currentPage
                }
                pages={
                    data && Object.keys(data).length > 0
                        ? Number(data.totalPages)
                        : 1
                }
                total={
                    data && data.hasOwnProperty('total') ?
                        data.total :
                        0
                }
                offset={paginate.offset}
                onChangePage={(page) => paginate.setCurrentPage(page)}
                onChangeRange={(value) => {
                    paginate.setOffset(value);
                    let filters = {
                        page: 1,
                        offset: value,
                        search: paginate.search,
                    };
                    if (!!camposDeFiltro.startDate && !!camposDeFiltro.endDate) {
                        searchRedux({
                            ...camposDeFiltro,
                            ...filters
                        });
                    }
                }}
                defaultValue={paginate.search}
                onChangeSearch={(value) => {
                    paginate.setSearch(value);
                    let filters = {
                        ...camposDeFiltro,
                        page: 1,
                        offset: paginate.offset,
                        search: value,
                    };
                    searchRedux(filters)
                }}
            />
        </>
    )

}

export default TableFinished