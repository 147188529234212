import React, { useEffect, useState } from "react";
import { Button, Modal, Form, Spinner } from "react-bootstrap";
import Select from 'react-select'

const EditForm = ({
  show,
  role,
  editMode,
  submit,
  onHide,
  permissions,
  companies,
  postsubmit
}) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [idCompany, setIdCompany] = useState("0");
  const [companySelected, setCompanySelected] = useState({value: "0", label: "Seleccionar"});
  const [permissionsSelected, setPermissionsSelected] = useState([]);
  const [checkAll, setCheckAll] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [permissionsWithSelected, setPermissionsWithSelected] = useState([]);

  const loadRole = async () => {
    try {
      setIsLoading(true);
      if (role) {
        resetForm();
        setPermissionsSelected([]);

        for (const permission of permissionsWithSelected) {
          permission.isSelected = false;
        }
        setPermissionsWithSelected(permissionsWithSelected);

        const ids = role.permissions;
        for (const id of ids) {
          const foundPermission = permissionsWithSelected.find(
            permission => permission.idPermission === Number(id)
          );
          if (foundPermission) {
            await checkPermission(foundPermission);
            foundPermission.isSelected = true;
          }
        }

        setPermissionsSelected(permissionsSelected);
        setPermissionsWithSelected(permissionsWithSelected);
        setName(role.name);
        setDescription(role.description);
        setIdCompany(role.idCompany);
        setCheckAll(permissionsSelected.length === permissions.length);
      } else {
        setName("");
        setDescription("");
        setIdCompany("");
        setPermissionsSelected([]);
        setCheckAll(false);
        setSearchValue("");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if(idCompany && idCompany !== null) {
      if (companies && companies !== undefined && idCompany && idCompany !== undefined) {
        const [company] = companies.filter((f) => f.idCompany === idCompany)
        if (company) {
          setCompanySelected({ value: company.idCompany, label: company.description })
        }
      }
    } else {
      setCompanySelected({ value: "0", label: "Seleccionar" })
    }
  }, [idCompany])

  const checkPermission = async permission => {
    const permissionFound = permissionsSelected.find(
      temp => temp.idPermission === permission.idPermission
    );
    if (!permissionFound) {
      permissionsSelected.push(permission);
      setPermissionsSelected([...permissionsSelected]);
      await setCheckAll(permissionsSelected.length === permissions.length);
    } else {
      setPermissionsSelected([
        ...permissionsSelected.filter(
          temp => temp.idPermission !== permission.idPermission
        )
      ]);
      await setCheckAll(false);
    }
  };

  const checkAllPermissions = async value => {
    const temp = permissionsWithSelected.map(permission => ({
      ...permission,
      isSelected: value
    }));
    if (!value) {
      setCheckAll(false);
      setPermissionsSelected([]);
    } else {
      setCheckAll(true);
      setPermissionsSelected(temp);
    }
    setPermissionsWithSelected(temp);
  };

  const resetForm = () => {
    setName("");
    setDescription("");
    setIdCompany("");
    setPermissionsSelected([]);
    setCheckAll(false);
    setSearchValue("");
    setPermissionsWithSelected(
      permissionsWithSelected.map(permission => ({
        ...permission,
        isSelected: false
      }))
    );
  };

  useEffect(
    async () => {
      await loadRole();
    },
    [role, editMode, permissions]
  );

  useEffect(
    () => {
      const updatedPermissions =
        permissions &&
        permissions.map(permission => ({
          ...permission,
          isSelected: false
        }));
      setPermissionsWithSelected(updatedPermissions);
    },
    [permissions]
  );

  const closeModal = () => {
    resetForm();
    onHide();
  };

  const submitWithLoadingAndCloseModal = async (event, permissionsSelected) => {
    return new Promise(async (resolve, reject) => {
      setIsLoading(true);
      try {
        await submit(event, permissionsSelected);
        setIsLoading(false);
        closeModal();
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  };

  return (
    <div>
      <Modal
        size="xl"
        show={show}
        onHide={closeModal}
        onEscapeKeyDown={onHide}
        backdrop="static"
        keyboard={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {editMode ? "Editar" : "Nuevo"} Rol
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form
            onSubmit={async event => {
              try {
                await submitWithLoadingAndCloseModal(
                  event,
                  permissionsSelected
                );
                await postsubmit();
              } catch (e) {
                console.log(e);
              }
            }}
          >
            <fieldset disabled={isLoading}>
              <Form.Group className="mb-3">
                <Form.Control
                  name="idRole"
                  value={role && role.idRole}
                  type="hidden"
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Nombre</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Nombre"
                  className="form-control form-control-user"
                  required
                  value={name}
                  onChange={e => setName(e.target.value)}
                  name="name"
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Descripción</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Descripción"
                  className="form-control form-control-user"
                  required
                  value={description}
                  onChange={e => setDescription(e.target.value)}
                  name="description"
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Empresa</Form.Label>                
                  <Select
                    isClearable={true}
                    name='idCompany'
                    id='idCompany'
                    value={companySelected}
                    onChange={value => setIdCompany(value?.value)}
                    options=
                    {
                      companies &&
                      Object.keys(companies).length > 0 &&
                      companies.map((ele) => ({                                    
                            value: ele.idCompany,
                            label: ele.description,
                          }))
                    }
                  />
              </Form.Group>
              <Form.Group className="mb-3">
                <div className="row align-items-center">
                  <div className="col-md-8">
                    <input
                      className="form-control"
                      onChange={e => setSearchValue(e.target.value)}
                      value={searchValue}
                      placeholder={"Buscar permiso"}
                    />
                    <span className="small">
                      Permisos seleccionados {permissionsSelected.length} de{" "}
                      {permissions && permissions.length} {" "}
                      {permissionsSelected.length === 0
                        ? <span className="btn-danger">
                            Selecciona uno o mas permisos*
                          </span>
                        : ""}
                    </span>
                  </div>
                  <div className="col-md-4">
                    <Form.Check
                      label="Seleccionar todos"
                      id="checkAll"
                      onChange={e => checkAllPermissions(e.target.checked)}
                      checked={checkAll}
                    />
                  </div>

                  <div
                    style={{
                      height: "300px",
                      overflowY: "scroll",
                      overflowX: "hidden",
                      direction: "rtl"
                    }}
                  >
                    <div
                      className="row align-items-center container"
                      style={{
                        paddingRight: "calc(100% - 100vw)",
                        direction: "ltr"
                      }}
                    >
                      {permissionsWithSelected &&
                        permissionsWithSelected
                          .filter(permission =>
                            permission.description
                              .toLowerCase()
                              .includes(searchValue.toLowerCase())
                          )
                          .map(permission =>
                            <div
                              className="col-6"
                              key={permission.idPermission}
                            >
                              <Form.Check
                                label={permission.description}
                                name={permission.name}
                                id={permission.idPermission}
                                onChange={async () => {
                                  permission.isSelected = !permission.isSelected;
                                  await checkPermission(permission);
                                }}
                                checked={permission.isSelected}
                              />
                            </div>
                          )}
                    </div>
                  </div>
                  
                </div>
              </Form.Group>
              <Modal.Footer>
                <Button variant="secondary" onClick={closeModal}>
                  Cancelar
                </Button>

                <Button
                  variant={
                    isLoading || permissionsSelected.length === 0
                      ? "secondary"
                      : "primary"
                  }
                  type="submit"
                  disabled={isLoading || permissionsSelected.length === 0}
                >
                  {editMode ? "Guardar cambios" : "Registrar"}
                  {!isLoading
                    ? ""
                    : <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />}
                </Button>
              </Modal.Footer>
            </fieldset>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default EditForm;
