import React, { forwardRef, useState, useEffect, useMemo, useContext } from "react";
import DatePicker from "react-datepicker";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { Button, Form, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";

import { auditoriaNotificationsActions } from "../../../redux/reducers/auditoriaNotificationsReducer";

import ContentWrapper from "../../../components/Common/ContentWrapper";
import Grid from "../../../components/Grid";

import { PermissionContext } from '../../../hooks/PermissionContext';

import { isNullOrUndefindedAndEmpty } from "../../../utils/pantallaUtil";
import { getStatusBadgeByCode } from "../../../utils/StatusUtils";

import { DateTime, IANAZone } from "luxon";

const MessagebirdReport = ({ items, filterFields, getAll }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [offset, setOffset] = useState(10);
    const [search, setSearch] = useState("");
    const [dataFilter, setDataFilter] = useState({});
    const [isLoad, setIsLoad] = useState(false)
    const handleSearch = (dataFrom) => {
        let perpage = {
            ...dataFrom,
            page: 1,
            offset: offset,
            search: search,
        };
        setDataFilter(dataFrom)
        getAll(perpage)
    }

    const handleDonwload = () => {
        const token = window.localStorage.getItem("token");
        let _data = dataFilter;
        setIsLoad(true)
        fetch(`${process.env.REACT_APP_REPORT_URL}/api/v1/notificationController/downloadExcel`, {
            method: "POST",
            body: JSON.stringify(_data),
            headers: new Headers({
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            }),
        })
            .then((response) => response.blob())
            .then((blob) => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement("a");
                a.href = url;
                a.download =
                    DateTime.now().toISODate() +
                    "-addressFinished-report.csv";
                document.body.appendChild(a);
                a.click();
                a.remove();
            }).finally(() => setIsLoad(false));
    };

    useEffect(() => {
        let filters = {
            ...dataFilter,
            page: currentPage,
            offset: offset,
            search: search,
        };

        if (dataFilter.startDate && dataFilter.endDate) {
            getAll(filters)
        }

    }, [currentPage, search, offset])

    return (
        <ContentWrapper>
            <div className="d-sm-flex align-items-center mb-1">
                <h1 className="h3 mb-0 text-gray-800 mr-3">Reporte Envío Mensajes</h1>
                <div>
                    <OverlayTrigger
                        placement="top"
                        delay={{ show: 50, hide: 50 }}
                        overlay={<Tooltip id="button-tooltip">Descargar</Tooltip>}
                    >
                        {
                            isLoad ? (
                                <Spinner animation="border" variant="primary" />
                            ) : (
                                <button
                                    disabled={
                                        !(
                                            !!items &&
                                            Object.keys(items).length > 0 &&
                                            items.items.length > 0
                                        )
                                    }
                                    className="btn btn-info btn-circle"
                                    type="button"
                                    onClick={handleDonwload}
                                >
                                    <i className="fas fa-file-download fa-sm"></i>
                                </button>
                            )
                        }

                    </OverlayTrigger>
                </div>
            </div>
            <div className="card pb-0 mt-3">
                <div className="card-body">
                    <FilterFormComponent setDataFilter={setDataFilter} dataFilter={dataFilter} onSubmit={handleSearch} />
                </div>
            </div>
            <div className="card pb-0 mt-3">
                <div className="card-body">
                    <TableComponent
                        data={items}
                        paginate={{
                            ...dataFilter,
                            currentPage,
                            offset,
                            search,
                            setCurrentPage,
                            setOffset,
                            setSearch,
                        }}
                        camposDeFiltro={filterFields}
                        searchRedux={getAll}
                        dataFilter={dataFilter}
                    />
                </div>
            </div>
        </ContentWrapper>
    )
}

const FilterFormComponent = ({ onSubmit, setDataFilter, dataFilter }) => {
    const formState = useForm({
        defaultValues: {
            endDate: "",
            startDate: "",
        },
    });

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [fromMaxDate, setFromMaxDate] = useState(new Date())
    const [toMinDate, setToMinDate] = useState(null)
    const [toMaxDate] = useState(new Date())

    const changeStartDate = (date) => {
        setStartDate(date);
        formState.setValue("startDate", DateTime.fromJSDate(date).setZone(new IANAZone("America/Bogota")).toFormat("yyyy-LL-dd"));
        setToMinDate(date)
        setDataFilter({ ...dataFilter, startDate: date })
    };

    const changeEndDate = (date) => {
        setEndDate(date);
        formState.setValue("endDate", DateTime.fromJSDate(date).setZone(new IANAZone("America/Bogota")).toFormat("yyyy-LL-dd"));
        setFromMaxDate(date)
        setDataFilter({ ...dataFilter, endDate: date })
    };

    const DatePickerCustomFrom = forwardRef(
        ({ value, onClick, onChange }, ref) => (
            <>
                <input
                    className={`form-control form-control-user ${formState.formState.errors.startDate && "is-invalid"
                        }`}
                    onClick={onClick}
                    onChange={onChange}
                    ref={ref}
                    value={value}
                    autoComplete="Off"
                    {...formState.register("startDate", { required: true })}
                />
                {/*  {formState.formState.errors.startDate && (
                        <span className="invalid-feedback">Fecha inicial es requerida</span>
                    )}*/}
            </>
        )
    );

    const DatePickerCustomTo = forwardRef(({ value, onClick, onChange }, ref) => (
        <>
            <input
                className={`form-control form-control-user ${formState.formState.errors.endDate && "is-invalid"
                    }`}
                onClick={onClick}
                onChange={onChange}
                ref={ref}
                value={value}
                maxDate={new Date()}
                autoComplete="Off"
                {...formState.register("endDate", { required: true })}
            />
        </>
    ));

    return (
        <>
            <form
                onSubmit={formState.handleSubmit(onSubmit)}
                className={"form-row align-items-end"}
            >
                <Form.Group className="mb-0 col-2" controlId="startDate">
                    <Form.Label>
                        Fecha inicial <span className={"text-danger"}>*</span>
                    </Form.Label>
                    <div>
                        <DatePicker
                            selected={startDate}
                            onChange={(date) => changeStartDate(date)}
                            dateFormat="yyyy-MM-dd"
                            customInput={<DatePickerCustomFrom />}
                            maxDate={fromMaxDate}
                        />
                    </div>
                </Form.Group>
                <Form.Group className="mb-0 col-2" controlId="endDate">
                    <Form.Label>
                        Fecha final <span className={"text-danger"}>*</span>
                    </Form.Label>
                    <div>
                        <DatePicker
                            selected={endDate}
                            onChange={(date) => changeEndDate(date)}
                            dateFormat="yyyy-MM-dd"
                            customInput={<DatePickerCustomTo />}
                            maxDate={toMaxDate}
                            minDate={toMinDate}
                        />
                    </div>
                </Form.Group>
                <Form.Group className="mb-0 col-1 align-content-end" controlId="city">
                    <Button type="submit">Buscar</Button>
                </Form.Group>
            </form>
        </>
    )
}

const clientColumns = [
    {
        title: "ID Externo",
        render: (rowData) => {
            return <span>{rowData.address.externalId}</span>;
        },
        field: 'address.externalId',
        searchable: true
    },
    {
        title: "Guía",
        render: (rowData) => {
            return <span>{rowData.trackingId}</span>;
        },
        field: 'address.trackingId',
        searchable: true
    },
    {
        title: "Estado Guía",
        render: (rowData) => {
            return <span>{rowData.idStatus.name}</span>;
        },
        field: 'idStatus.name',
        searchable: true
    },
    {
        title: "Teléfono Destinatario",
        render: (rowData) => {
            return <span>{rowData.phone}</span>;
        },
        field: 'phone',
        searchable: true
    },
    {
        title: "Servicio",
        render: (rowData) => {
            return <span>{rowData.servicio}</span>;
        },
        field: 'servicio',
        searchable: false
    },
    {
        title: "Fecha de Envío",
        render: (rowData) => {
            return <span>{DateTime.fromISO(rowData.created_at).setZone(new IANAZone("America/Bogota")).toISODate()}</span>;
        },
    },
    {
        title: "Estado Notificación",
        render: (rowData) => {
            return rowData.status;
        },
        field: 'state',
        searchable: false
    },
]

const TableComponent = ({ data, searchRedux, camposDeFiltro, paginate }) => {
    const { hasPermission } = useContext(PermissionContext);

    const columns = useMemo(() => [
    ...(hasPermission('feature:isClient')? clientColumns : [
        {
            title: "# Dirección",
            render: (rowData) => {
                return <span>{rowData.address.idAddress}</span>;
            },
            field: 'address.idAddress',
            searchable: false
        },
        {
            title: "ID Externo",
            render: (rowData) => {
                return <span>{rowData.address.externalId}</span>;
            },
            field: 'address.externalId',
            searchable: true
        },
        {
            title: "Guía",
            render: (rowData) => {
                return <span>{rowData.trackingId}</span>;
            },
            field: 'address.trackingId',
            searchable: true
        },
        {
            title: "Estado Guía",
            render: (rowData) => {
                return <span>{rowData.idStatus.name}</span>;
            },
            field: 'idStatus.name',
            searchable: true
        },
        {
            title: 'Novedad',
            render: (rowData) => {
              return <span>{rowData.novelty ? rowData.novelty.description : 'N/A'}</span>;
            },
            field: 'novelty.description',
            searchable: true,
        },
        {
            title: "Cliente",
            render: (rowData) => {
                return <span>{rowData.company.description}</span>;
            },
            field: 'company.description',
            searchable: true
        },
        ...(hasPermission('feature:show-reference1-address')
            ? [
                {
                    title: "Teléfono Destinatario",
                    render: (rowData) => {
                        return <span>{rowData.phone}</span>;
                    },
                    field: 'phone',
                    searchable: true
                },
            ]
            : []),

        {
            title: "Servicio",
            render: (rowData) => {
                return <span>{rowData.servicio}</span>;
            },
            field: 'servicio',
            searchable: false
        },
        {
            title: "Mensaje",
            render: (rowData) => {
                return <span>
                    <div data-title={rowData.message}> {rowData.message && (rowData.message.substring(0, 30) || '')}</div>
                </span>;
            },
            field: 'message',
            searchable: false
        },
        {
            title: "Plantilla",
            render: (rowData) => {
                return <span>{rowData.template}</span>;
            },
        },
        {
            title: "Fecha",
            render: (rowData) => {
                return <span>{DateTime.fromISO(rowData.created_at).setZone(new IANAZone("America/Bogota")).toISODate()}</span>;
            },
        },
        {
            title: "Hora",
            render: (rowData) => {
                return <span>{DateTime.fromISO(rowData.created_at).setZone(new IANAZone("America/Bogota")).toFormat("HH:mm:ss")}</span>;
            },
        },
        {
            title: "Estado Notificación",
            render: (rowData) => {
                return rowData.status;
            },
            field: 'state',
            searchable: false
        },
        {
            title: "Descripción de Estado",
            render: (rowData) => {
                return rowData.reason;
            },
            field: 'reason',
            searchable: true
        }
    ])], [data]);

    return (
        <>
            <Grid
                cols={columns}
                data={
                    data && Object.keys(data).length > 0
                        ? data.items
                        : []
                }
                page={
                    data && Object.keys(data).length > 0
                        ? Number(data.page)
                        : paginate.currentPage
                }
                pages={
                    data && Object.keys(data).length > 0
                        ? Number(data.totalPages)
                        : 0
                }
                total={
                    data && data.hasOwnProperty('total') ?
                        data.total :
                        0
                }
                offset={paginate.offset}
                onChangePage={(page) => paginate.setCurrentPage(page)}
                onChangeRange={(value) => {
                    paginate.setOffset(value);
                    let filters = {
                        page: 1,
                        offset: value,
                        search: paginate.search,
                        startDate: camposDeFiltro.startDate,
                        endDate: camposDeFiltro.endDate,
                    };
                    searchRedux({
                        ...camposDeFiltro,
                        ...filters
                    });
                }}
                defaultValue={paginate.search}
                onChangeSearch={(search) => {
                    paginate.setSearch(search);
                    let filters = {
                        page: 1,
                        offset: paginate.offset,
                        search: paginate.search,
                        startDate: paginate.startDate,
                        endDate: paginate.endDate,
                    };
                    if (!isNullOrUndefindedAndEmpty(filters.search)) {
                        searchRedux({
                            ...camposDeFiltro,
                            ...filters
                        });
                    }

                }}
            />
        </>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        getAll: (payload) => {
            dispatch({
                type: auditoriaNotificationsActions.FECTCH_ALL,
                value: payload
            })
        }
    }
}

const mapStateToProps = (state) => {
    return {
        items: state.auditoriaState.items,
        filterFields: state.auditoriaState.filterFields
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MessagebirdReport)