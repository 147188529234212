import { useEffect } from "react";
import { connect } from "react-redux";
import { Button, Modal, Form } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import Swal from "sweetalert2";

const WarehouseUpsert = ({
    show,
    idWarehouse,
    handleClose,
    reduxPostWarehouse,
    successfulCreateWarehouse,
    errorsCreateWarehouse,
    reduxPatchWarehouse,
    successfulUpdateWarehouse,
    errorsUpdateWarehouse,
    upsertResult,
    reduxGetWarehouseById,
    rowEdited,
    reduxResetWarehouseForm,
}) => {
    const {
        handleSubmit,
        control: modalControl,
        formState: { errors: modalErrors },
        setValue,
        reset,
    } = useForm({
        defaultValues: {
            name: '',
            warehouseCode: '',
        }
    });

    useEffect(() => {
        if (show === false) return;

        reduxResetWarehouseForm();
        reset();

        if (idWarehouse)
            reduxGetWarehouseById({
                id: idWarehouse,
            })
    }, [show, idWarehouse])

    useEffect(() => {
        if (successfulCreateWarehouse || successfulUpdateWarehouse) {
          Swal.fire(
            "Proceso Exitoso",
            `${upsertResult.message}`,
            "success"
          );
          handleClose();
        }
        if (errorsCreateWarehouse || errorsUpdateWarehouse) {
          Swal.fire(
            "Proceso Fallido",
            `${upsertResult.message}`,
            "error"
          );
        }
      }, [successfulCreateWarehouse, successfulUpdateWarehouse, errorsCreateWarehouse, errorsUpdateWarehouse, upsertResult])

    useEffect(() => {
        if (!rowEdited) return;

        setValue('name', rowEdited.name);
        setValue('warehouseCode', rowEdited.warehouseCode);
    }, [rowEdited]);

    const onSubmit = (data) => {
        if (rowEdited) {
            const warehouseDto = {
                ...data,
            }
            reduxPatchWarehouse(warehouseDto);
        }
        else {
            const warehouseDto = {
                ...data,
            }
            reduxPostWarehouse(warehouseDto);
        }
    }

    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                onEscapeKeyDown={handleClose}
                backdrop="static"
                keyboard={true}
            >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {rowEdited ? "Editar" : "Nueva"} Bodega
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="form-group col-6">
                                <label className="form-label">
                                    Nombre de Bodega
                                </label>
                                <Controller
                                    control={modalControl}
                                    name="name"
                                    render={({ field }) => (
                                        <div className="input-group">
                                            <Form.Control
                                                {...field}
                                                type="text"
                                                className={`form-control form-control-user ${modalErrors.name && "is-invalid"}`}
                                            />
                                            {
                                                modalErrors.name &&
                                                <span className="error-message">
                                                    {modalErrors.name.message}
                                                </span>
                                            }
                                        </div>
                                    )}
                                />
                            </div>
                            <div className="form-group col-6">
                                <label className="form-label">
                                    Código de Bodega
                                </label>
                                <Controller
                                    control={modalControl}
                                    rules={{
                                        required: 'El código de bodega es obligatorio',
                                        pattern: {
                                            value: /^[A-Z-0-9]+$/,
                                            message: 'Por favor ingresa solo letras mayusculas o números',
                                        }
                                    }}
                                    name="warehouseCode"
                                    render={({ field: { onChange, value } }) => (
                                        <div className="input-group">
                                            <Form.Control
                                                type="text"
                                                className={`form-control form-control-user ${modalErrors?.warehouseCode && "is-invalid"}`}
                                                disabled={rowEdited}
                                                onChange={e => onChange(e.target.value.toUpperCase())}
                                                value={value}
                                            />
                                            {
                                                modalErrors.warehouseCode &&
                                                <span className="error-message">
                                                    {modalErrors.warehouseCode.message}
                                                </span>
                                            }
                                        </div>
                                    )}
                                />
                            </div>
                        </div>
                        {/* <div className="row">
                            <div className="form-group col">
                                <label className="form-label">
                                    ¿Tiene bodega externa?
                                </label>
                                <Controller
                                    control={modalControl}
                                    name="isExternalWarehouse"
                                    defaultValue={false}
                                    render={({ field }) => (
                                        <>
                                            <RadioButton options={externalWarehouseOptions} fieldController={field} />
                                        </>
                                    )}
                                />
                            </div>
                        </div>
                        {
                            externalWarehouseWatcher &&
                            (
                                <>
                                    <div className="row">
                                        <div className="form-group col">
                                            <label className="form-label">
                                                Operador de Inventario
                                            </label>
                                            <Controller
                                                control={modalControl}
                                                rules={{
                                                    required: {
                                                        value: externalWarehouseWatcher,
                                                        message: 'El Operador de inventario es obligatorio'
                                                    }
                                                }}
                                                name="id_wms_operator"
                                                render={({ field }) => (
                                                    <>
                                                        <Select
                                                            {...field}
                                                            isClearable
                                                            styles={customStyles}
                                                            isDisabled={rowEdited}
                                                            options={
                                                                inventoryOperators && Object.keys(inventoryOperators).length > 0
                                                                    ? inventoryOperators.items
                                                                        .filter((f) => f.isActive)
                                                                        .map((ele, key) => ({
                                                                            value: ele.idCompany,
                                                                            label: ele.description,
                                                                        }))
                                                                    : []
                                                            }
                                                        />
                                                        {
                                                            modalErrors.id_wms_operator &&
                                                            <span className="error-message">
                                                                {modalErrors.id_wms_operator.message}
                                                            </span>
                                                        }
                                                    </>
                                                )}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-6">
                                            <div className="form-group">
                                                <label className="form-label">
                                                    Código de Bodega Externa
                                                </label>
                                                <Controller
                                                    control={modalControl}
                                                    rules={{
                                                        required: {
                                                            value: externalWarehouseWatcher,
                                                            message: 'El código de bodega externo es obligatorio'
                                                        },
                                                        maxLength: {
                                                            value: 12,
                                                            message: 'Longitud máxima de 12 caracteres.'
                                                        },
                                                        pattern: {
                                                            value: /^[A-Za-z0-9]+$/,
                                                            message: 'Por favor ingresa solo letras o números',
                                                        }
                                                    }}
                                                    name="externalWarehouseCode"
                                                    render={({ field: { onChange, value } }) => (
                                                        <>
                                                            <Form.Control
                                                                type="text"
                                                                maxLength={12}
                                                                onChange={e => onChange(e.target.value.toUpperCase())}
                                                                value={value}
                                                                className={`form-control ${modalErrors.externalWarehouseCode && "is-invalid"}`}
                                                                disabled={rowEdited}
                                                            />
                                                            {
                                                                modalErrors.externalWarehouseCode &&
                                                                <span className="error-message">
                                                                    {modalErrors.externalWarehouseCode.message}
                                                                </span>
                                                            }
                                                        </>
                                                    )}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )
                        } */}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Cancelar
                        </Button>
                        <Button variant="primary" type="submit">
                            Guardar
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        successfulCreateWarehouse: state.warehouseState.successfulCreateWarehouse,
        errorsCreateWarehouse: state.warehouseState.errorsCreateWarehouse,
        successfulUpdateWarehouse: state.warehouseState.successfulUpdateWarehouse,
        errorsUpdateWarehouse: state.warehouseState.errorsUpdateWarehouse,
        upsertResult: state.warehouseState.upsertResult,
        rowEdited: state.warehouseState.rowEdited,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        reduxGetWarehouseById: (payload) =>
            dispatch({
                type: "READ_WAREHOUSE_REQUEST",
                value: payload,
            }),
        reduxPostWarehouse: (payload) =>
            dispatch({
                type: 'CREATE_WAREHOUSE_REQUEST',
                value: payload,
            }),
        reduxPatchWarehouse: (payload) =>
            dispatch({
                type: 'UPDATE_WAREHOUSE_REQUEST',
                value: payload,
            }),
        reduxResetWarehouseForm: () =>
            dispatch({
                type: 'RESET_WAREHOUSE_FORM',
            }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(WarehouseUpsert);