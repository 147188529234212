const initialState = {
    requestingFetchInventory: false,
    successfulFetchInventory: false,
    errorFetchInventory: false,
    inventory: {},
    processingInventoryUpload: false,
    successInventoryUpload: false,
    errorInventoryUpload: false,
    inventoryUploadResult: null,
    requestingFetchInventoryHistory: false,
    successfulFetchInventoryHistory: true,
    errorFetchInventoryHistory: false,
    inventoryHistory: {},
    requestingDownloadInventory: false,
    successfulDownloadInventory: false,
    errorDownloadInventory: false,
};

const inventoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case "READ_INVENTORY_REQUESTING":
            return {
                ...state,
                requestingFetchInventory: true,
                successfulFetchInventory: false,
                errorFetchInventory: false,
            };
        case "READ_INVENTORY_SUCCESS":
            return {
                ...state,
                requestingFetchInventory: false,
                successfulFetchInventory: true,
                errorFetchInventory: false,
                inventory: action.value,
            };
        case "READ_INVENTORY_ERROR":
            return {
                ...state,
                requestingFetchInventory: false,
                successfulFetchInventory: false,
                errorFetchInventory: true,
            };
        case "UPLOAD_SHIPPERINVENTORY_MOVEMENT_REQUESTING":
            return {
                ...state,
                processingInventoryUpload: true,
                successInventoryUpload: false,
                errorInventoryUpload: false,
            };
        case "UPLOAD_SHIPPERINVENTORY_MOVEMENT_SUCCESS":
            return {
                ...state,
                processingInventoryUpload: false,
                successInventoryUpload: true,
                errorInventoryUpload: false,
                inventoryUploadResult: action.value,
            };
        case "UPLOAD_SHIPPERINVENTORY_MOVEMENT_ERROR":
            return {
                ...state,
                processingInventoryUpload: false,
                successInventoryUpload: false,
                errorInventoryUpload: true,
            };
        case "READ_WAREHOUSE_INVENTORY_HISTORY_REQUESTING":
            return {
                ...state,
                requestingFetchInventoryHistory: true,
                successfulFetchInventoryHistory: false,
                errorFetchInventoryHistory: false,
            };
        case "READ_WAREHOUSE_INVENTORY_HISTORY_SUCCESS":
            return {
                ...state,
                requestingFetchInventoryHistory: false,
                successfulFetchInventoryHistory: true,
                errorFetchInventoryHistory: false,
                inventoryHistory: action.value,
            };
        case "READ_WAREHOUSE_INVENTORY_HISTORY_ERROR":
            return {
                ...state,
                requestingFetchInventoryHistory: false,
                successfulFetchInventoryHistory: false,
                errorFetchInventoryHistory: true,
            };
        case "RESET_WAREHOUSE_INVENTORY_FORM":
            return {
                ...state,
                inventory: {}
            };
        case "RESET_WAREHOUSEINVENTORYUPLOAD_FORM":
            return {
                ...state,
                processingInventoryUpload: false,
                successInventoryUpload: false,
                errorInventoryUpload: false,
                inventoryUploadResult: null,
            };
        case "DOWNLOAD_INVENTORY_REQUESTING":
            return {
                ...state,
                requestingDownloadInventory: true,
                successfulDownloadInventory: false,
                errorDownloadInventory: false,
            };
        case "DOWNLOAD_INVENTORY_SUCCESS":
            return {
                ...state,
                requestingDownloadInventory: false,
                successfulDownloadInventory: true,
                errorDownloadInventory: false,
            };
        case "DOWNLOAD_INVENTORY_ERROR":
            return {
                ...state,
                requestingDownloadInventory: false,
                successfulDownloadInventory: false,
                errorDownloadInventory: true,
            };
        default:
            return state;
    }
};

export default inventoryReducer;  