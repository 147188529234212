import React, { useState, useRef, useEffect, useCallback } from "react";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { connect } from "react-redux";
import { Modal, Button, Form, Row, Col, Table } from "react-bootstrap";
import Grid from "../../components/Grid";
import Sidebar from "../../components/Navigation/Sidebar";
import Topbar from "../../components/Navigation/Topbar";
import Select from "react-select";
import { Show } from "../../hooks/Show";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { isNullOrUndefined } from "../../utils/functionsUtils";
import { isNullOrUndefindedAndEmpty } from "../../utils/pantallaUtil";


const Coverage = ({
  reduxGetOneCoverage, 
  coverages,
  oneCoverage,
  allCoverages,
  reduxPostCoverage,
  coverage,
  reduxGetCoverage,
  rowEdited,
  reduxUpdateStatusCoverage,
  rowUpdateStatus,
  reduxPatchCoverage,
  rowUpdated,
  reduxResetCoverageForm,
  reduxGetNeighborhoodsByCity,
  neighborhoodsByCity,
  reduxGetOrigins,
  origins,
  reduxGetDepartmentsByCountry,
  departmentsByCountry,
  reduxGetCitiesByDepartment,
  citiesByDepartment,
  countries,
  reduxGetCountries,
  reduxSetDepartment,
  reduxSetCity,
  reduxGetCoverageType,
  coverageTypes,
  errorsUpdateCoverage,
  errorsCreateCoverage,
  reduxResetDepartamentForm,
  reduxResetCityForm
}) => {
  const modalForm = useForm({
    defaultValues: {
      city: "",
      neighborhood: "",
      country: "",
      department: "",
      origin: "",
      coverageType: "",
    },
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(10);
  const [show, setShow] = useState(false);
  const [currentCoverage, setCurrentCoverage] = useState("");
  const [currentCity, setCurrentCity] = useState("");
  const [currentCountry, setCurrentCountry] = useState("");
  const [currentDepartment, setCurrentDepartment] = useState("");
  const [currentOrigin, setCurrentOrigin] = useState("");
  const [currentCoverageType, setCurrentCoverageType] = useState({ label: 'Seleccionar', value: '' });
  const [operators, setOperators] = useState([]);
  const [search, setSearch] = useState("");
  const formuState = useForm();
  const formState = formuState;
  const [isEditing, setIsEditing] = useState(false);
  const [showNewEditModal, setShowNewEditModal] = useState(false);
  const [selectedCoverage, setSelectedCoverage] = useState(null);
  const history = useHistory();

  const city = useRef({});
  city.current = modalForm.watch("city", "");

  const origin = useRef({});
  origin.current = modalForm.watch("origin", "");

  const coverageType = useRef({});
  coverageType.current = modalForm.watch("coverageType", "");

  const country = useRef({});
  country.current = modalForm.watch("country", "");

  const department = useRef({});
  department.current = modalForm.watch("department", "");

  useEffect(() => {
    let searchFilter = "";
    switch (search.toLowerCase()) {
      case "coverage.status|activo":
        searchFilter = "coverage.status|1";
        break;
      case "coverage.status|inactivo":
        searchFilter = "coverage.status|0";
        break;
      default:
        searchFilter = search;
        break;
    }
    reduxGetCoverage({
      page: currentPage,
      offset: offset,
      search: searchFilter,
    });
   
  }, [search, currentPage, offset, reduxGetCoverage]);

  useEffect(() => {
    searchByFilter();
  }, [currentPage, offset, search]);

  useEffect(() => {
    reduxGetOrigins();
  }, []);
  
  useEffect(() => {
    reduxGetCoverageType();
  }, []);
  
  useEffect(() => {
    reduxGetCountries({
      page: 1,
      offset: 1000,
      search: "",
      isActive: true,
    });
  }, []);

  useEffect(() => {
    setCurrentCountry({ label: 'Seleccionar', value: '' });
    setCurrentDepartment({ label: 'Seleccionar', value: '' });
    setCurrentCity({ label: 'Seleccionar', value: '' });

    if (country.current !== '' && country.current !== undefined && country.current !== null) {
      const selected = countries && Object.keys(countries).length > 0 && countries.items.find((e) => e.idCountry === Number(country.current))
      setCurrentCountry({ label: selected.description, value: selected.idCountry })

      reduxGetDepartmentsByCountry({
        idCountry: country.current
      })
    } else {
      setCurrentCountry({ label: 'Seleccionar', value: '' })
      setCurrentDepartment({ label: 'Seleccionar', value: '' });
      setCurrentCity({ label: 'Seleccionar', value: '' });
    }
  }, [country.current]);

  useEffect(() => {
    setCurrentDepartment({ label: 'Seleccionar', value: '' });
    setCurrentCity({ label: 'Seleccionar', value: '' });

    if (department.current !== '' && department.current !== undefined && department.current !== null) {
      const selected = departmentsByCountry && Object.keys(departmentsByCountry).length > 0 && departmentsByCountry.find((e) => e.idDepartment === Number(department.current))
      setCurrentDepartment(selected ? { label: selected.description, value: selected.idDepartment } : { label: 'Seleccionar', value: '' })

      selected && reduxGetCitiesByDepartment({
        idDepartment: department.current
      })
    } else {
      setCurrentDepartment({ label: 'Seleccionar', value: '' });      
      setCurrentCity({ label: 'Seleccionar', value: '' });      
    }
  }, [department.current, departmentsByCountry]);

  useEffect(() => {
    setCurrentCity({ label: 'Seleccionar', value: '' });

    if (city.current !== '' && city.current !== undefined && city.current !== null) {
      const selected = citiesByDepartment && Object.keys(citiesByDepartment).length > 0 && citiesByDepartment.find((e) => e.idCity === Number(city.current))
      setCurrentCity(selected ? { label: selected.description, value: selected.idCity } : { label: 'Seleccionar', value: '' })

      selected && reduxGetNeighborhoodsByCity({
        idCity: city.current
      })
    } else {
      setCurrentCity({ label: 'Seleccionar', value: '' })
    }
  }, [city.current, citiesByDepartment]);

  useEffect(() => {
    setCurrentOrigin({ label: 'Seleccionar', value: '' });   
    
    if (origin.current !== '' && origin.current !== undefined && origin.current !== null) {
        const selected = origins.find((e) => e.id === Number(origin.current));
        if (selected) {
            setCurrentOrigin({ label: selected.description, value: selected.id });
        }
    }
  }, [origin.current]);

  useEffect(() => {
    if (coverageType.current !== '' && coverageType.current !== undefined && coverageType.current !== null) {
        const selected = coverageTypes.find((e) => e.id === Number(coverageType.current));
        if (selected) {
            setCurrentCoverageType({ label: selected.description, value: selected.id });
        }
    } else {
        setCurrentCoverageType({ label: 'Seleccionar', value: '' });
    }
}, [coverageType.current, coverageTypes])

  useEffect(()=>{
    if (errorsUpdateCoverage){
      modalForm.reset();
      reduxResetCoverageForm();
      setShow(false);
    }
  }, [errorsUpdateCoverage, modalForm, reduxResetCoverageForm])

  useEffect(()=>{
    if (errorsCreateCoverage){
      modalForm.reset();
      reduxResetCoverageForm();
      setShow(false);
    }
  }, [errorsCreateCoverage, modalForm, reduxResetCoverageForm])

  const handleClose = () => {
    reduxResetCoverageForm();
    modalForm.reset();
    setShow(false);
  };

  const handleOpenModal = (row) => {    
    
    if (!row || Object.keys(row).length === 0) {
      reduxResetCoverageForm();
      modalForm.reset();
    } else {
      reduxGetOneCoverage({
        coverageId: row.coverage_id,
        branchId: row.branch_id,
      });
    }
    setShow(true);
  };   

  const handleOpenNewModalForCreate = () => {
    setSelectedCoverage(null);
    history.push("/coverage/NewEdit"); 
  };
  
  const handleOpenNewModalForEdit = (rowData) => {
    setSelectedCoverage(rowData);
    const coverageData = {
      coverageId: rowData.coverage_id,
      branchId: rowData.branch_id,
    };
    
    history.push({
      pathname: "/coverage/NewEdit",
      state: { coverageData },
    });
  }; 

  useEffect(() => {
    if (oneCoverage.data) {
      setOperators(oneCoverage.data.operators || []);
    }
    
  }, [oneCoverage.data]);

  const handleActive = (rowData) => {
    const payload = {
      idCoverage: rowData.coverage_id,
      status: 1,
    };  
    reduxUpdateStatusCoverage(payload);
    searchByFilter();
  };

  const handleInactive = (rowData) => {
    const payload = {
      idCoverage: rowData.coverage_id,
      status: 0,
    };  
    reduxUpdateStatusCoverage(payload);
    searchByFilter();
  };

  const searchByFilter = () => {
    let searchFilter = "";    
    switch (search.toLowerCase()) {
      case "coverage.status|activo":
        searchFilter = "coverage.status|1";
        break;
      case "coverage.status|inactivo":
        searchFilter = "coverage.status|0";
        break;
      default:
        searchFilter = search;
        break;
    }
    let filters = {
      page: currentPage,
      offset: offset,
      search: searchFilter,
    };
    if (country.current) {
      filters = {
        ...filters,
        idCountry: country.current,
      };
    }
    if (department.current) {
      filters = {
        ...filters,
        idDepartment: department.current,
      };
    }
    if (city.current) {
      filters = {
        ...filters,
        idCity: city.current,
      };
    }
    if (origin.current) {
      filters = {
        ...filters,
        idOrigin: origin.current,
      };
    }
    if (coverageType.current) {
      filters = {
        ...filters,
        idCoverageType: coverageType.current,
      };
    }
    reduxGetCoverage(filters);
  }

  const columns = [
    {
      title: "#",
      render: (rowData) => {
        return <span>{rowData.coverage_id}</span>;
      },
      field: "coverage.id",
      searchable: true,
    },
    {
      title: "País",
      render: (rowData) => {
        return <span>{rowData.country_description}</span>;
      },
      field: "country.description",
      searchable: true,
    },
    {
      title: "Origen",
      render: (rowData) => {
        return <span>{rowData.full_origin}</span>;
      },
      field: "origins.description",
      searchable: true,
    },
    {
      title: "Departamento",
      render: (rowData) => {
        return <span>{rowData.department_description}</span>;
      },
      field: "department.description",
      searchable: true,
    },
    {
      title: "Ciudad/Delegación",
      render: (rowData) => {
        return <span>{rowData.city_description}</span>;
      },
      field: "city.description",
      searchable: true,
    },
    {
      title: "Barrio/Colonia/Localidad",
      render: (rowData) => {
        return <span>{rowData.full_neighborhood}</span>;
      },
      field: "neighborhood.description",
      searchable: true,
    },
    {
      title: "Tipo",
      render: (rowData) => {
        return <span>{rowData.coverage_type}</span>;
      },
      field: "coverage.type",
      searchable: true,
    },
    {
      title: "Zona",
      render: (rowData) => {
        return <span>{rowData.coverage_zone}</span>;
      },
      field: "coverage.zone",
      searchable: true,
    },
    {
      title: "Estado",
      render: (rowData) => {
        const statusText = rowData.coverage_status === 1 ? "Activo" : "Inactivo";
        return <span>{statusText}</span>;
      },
      field: "coverage.status",
      searchable: true,
    },
    {
      title: "Opciones",
      render: (rowData) => {
        return (
          <>            
            <>
              <Show when="feature:coverage-enable">
                <button
                  title="Detalles"
                  className="btn btn-primary btn-sm  btn-circle mr-2"
                  type="button"
                  onClick={e => handleOpenModal(rowData)}
                >
                  <i className="fas fa-info-circle fa-lg"></i>
                </button>
              </Show>
              <Show when="feature:coverage-edit">
                <button
                  title="Editar"
                  className="btn btn-primary btn-sm  btn-circle mr-2"
                  type="button"
                  onClick={() => handleOpenNewModalForEdit(rowData)}
                >                  
                  <i className="fas fa-edit fa-xs"></i>
                </button>
              </Show>           
            </>
          </>
        );
      },
    },
  ];
  return (
    <div>
      <div id="wrapper">
        <Sidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Topbar />
            <div className="container-fluid">
              <div className="d-sm-flex align-items-center mb-1">
                <h1 className="h3 mb-0 text-gray-800 mr-3">Coberturas</h1>
                <Show when="feature:coverage-create">
                <button
                  title="Nueva"
                  className="btn btn-primary btn-sm  btn-circle mr-2"
                  type="button"
                  onClick={() => handleOpenNewModalForCreate()}
                >                  
                  <i className="fas fa-plus fa-sm"></i>
                </button>
              </Show>
              </div>
              <p className="mb-4">Módulo de Administración de Coberturas</p>
              <div className="card shadow py-3 mb-4 d-flex justify-content-center">
                <form 
                    className={"form-row align-items-end"}
                >
                    <Form.Group className="mb-2 col-2" controlId="pais">
                    <Form.Label className="mb-1 col-12 font-weight-bold text-primary">País</Form.Label> 
                    <Select
                        className="col-12"
                        value={currentCountry}
                        onChange={(value) => {
                          modalForm.setValue('country', value?.value || '');
                          reduxResetDepartamentForm();
                          reduxResetCityForm();
                        }}
                        isClearable={!isNullOrUndefindedAndEmpty(currentCountry.value) ? true : false}
                        options={
                        countries && Object.keys(countries).length > 0
                            ? countries.items
                                .filter((f) => f.isActive)
                                .map((ele) => ({
                                value: ele.idCountry,
                                label: ele.description,
                                }))
                            : []
                        }
                    />
                    </Form.Group>
                    <Form.Group className="mb-2 col-2" controlId="origen">
                    <Form.Label className="mb-1 col-12 font-weight-bold text-primary">Origen</Form.Label> 
                    <Select
                      className="col-12"                      
                      value={currentOrigin}
                      onChange={(value) => modalForm.setValue('origin', value?.value || '')}
                      isClearable={!isNullOrUndefindedAndEmpty(currentOrigin.value) ? true : false}
                      options={
                          origins && origins.length > 0
                          ? origins
                              .filter((origin) => origin.status)
                              .map((origin) => ({
                                  value: origin.id,
                                  label: origin.description,
                              }))
                          : []
                      }
                    />
                    </Form.Group>
                    <Form.Group className="mb-2 col-2" controlId="departamento">
                    <Form.Label className="mb-1 col-12 font-weight-bold text-primary">Departamento</Form.Label> 
                    <Select
                        className="col-12"                        
                        value={currentDepartment}
                        onChange={(value) => modalForm.setValue('department', value?.value || '')}
                        isClearable={!isNullOrUndefindedAndEmpty(currentDepartment.value) ? true : false}
                        options={
                        departmentsByCountry && Object.keys(departmentsByCountry).length > 0
                            ? departmentsByCountry
                                .filter((f) => f.isActive)
                                .map((ele) => ({
                                value: ele.idDepartment,
                                label: ele.description,
                                }))
                            : []
                        }
                    />
                    </Form.Group>
                    <Form.Group className="mb-2 col-2" controlId="ciudad">
                    <Form.Label className="mb-1 col-12 font-weight-bold text-primary">Ciudad</Form.Label>
                    <Select
                        className="col-12"
                        value={currentCity}
                        onChange={(value) => modalForm.setValue('city', value?.value || '')}
                        isClearable={!isNullOrUndefindedAndEmpty(currentCity.value) ? true : false}
                        options={
                        citiesByDepartment && Object.keys(citiesByDepartment).length > 0
                            ? citiesByDepartment
                                .filter((f) => f.isActive)
                                .map((ele) => ({
                                value: ele.idCity,
                                label: ele.description,
                                }))
                            : []
                        }
                    />
                    </Form.Group>
                    <Form.Group className="mb-2 col-2" controlId="tipo">
                    <Form.Label className="mb-1 col-12 font-weight-bold text-primary">Tipo</Form.Label> 
                    <Select
                        className="col-12"
                        value={currentCoverageType}
                        onChange={(value) => {modalForm.setValue('coverageType', value?.value || '');
                            setCurrentCoverageType(value || { label: 'Seleccionar', value: '' });
                        }}
                        isClearable={!isNullOrUndefindedAndEmpty(currentCoverageType.value) ? true : false}
                        options={
                            coverageTypes && coverageTypes.length > 0
                                ? coverageTypes
                                    .map((coverageType) => ({
                                        value: coverageType.idCoverageType,
                                        label: coverageType.description,
                                    }))
                                : []
                        }
                    />
                    </Form.Group>
                    <Form.Group className="mb-2 col-2 text-center" controlId="filter">
                      <button type="button" className='btn btn-primary' onClick={searchByFilter} style={{marginTop: "32px"}}>Buscar</button> 
                    </Form.Group>
                </form>
              </div>
              <div className="card shadow mb-4">
                <div className="card-header py-3">
                  <h6 className="m-0 font-weight-bold text-primary">
                    Listado de Coberturas
                  </h6>
                </div>
                <div className="card-body">
                  <Grid
                    cols={columns}
                    data={
                      coverage && Object.keys(coverage).length > 0
                        ? coverage.items
                        : []
                    }
                    page={
                      coverage && Object.keys(coverage).length > 0
                        ? Number(coverage.page)
                        : currentPage
                    }
                    pages={
                      coverage && Object.keys(coverage).length > 0
                        ? Number(coverage.totalPages)
                        : 0
                    }
                    total={
                      coverage && coverage.hasOwnProperty("total")
                        ? coverage.total
                        : 0
                    }
                    offset={offset}
                    onChangePage={(page) => {
                      setCurrentPage(page);
                    }}
                    onChangeRange={(value) => {
                      setOffset(value);
                      setCurrentPage(1);
                    }}
                    defaultValue={search}
                    onChangeSearch={(value) => {
                      setSearch(value);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
      <Modal
        size="xl"
        show={show}
        onHide={handleClose}
        onEscapeKeyDown={handleClose}
        backdrop="static"
        keyboard={true} 
      >
      <div style={{ padding: '10px' }}>
        <FormProvider {...modalForm}>
          <Modal.Header closeButton>
            <Modal.Title>
              Detalle de cobertura
            </Modal.Title>
          </Modal.Header>
          <Table striped bordered hover style={{ padding: '10px' }}>
            <thead>
              <tr>
                <th>Origen</th>
                <th>Departamento/Estado</th>
                <th>Ciudad/Delegación</th>
                <th>Barrio/Colonia/Localidad</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{oneCoverage.data && oneCoverage.data.full_origin}</td>
                <td>{oneCoverage.data && oneCoverage.data.department_description}</td>
                <td>{oneCoverage.data && oneCoverage.data.city_description}</td>
                <td>{oneCoverage.data && oneCoverage.data.full_neighborhood}</td>
              </tr>
            </tbody>
          </Table>
          <Table striped bordered hover style={{ padding: '10px' }}>
            <thead>
              <tr>
                <th>Tipo</th>
                <th>Zona</th>
                <th>Estado</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{oneCoverage.data && oneCoverage.data.coverage_type}</td>
                <td>{oneCoverage.data && oneCoverage.data.coverage_zone}</td>
                <td>{oneCoverage.data && (oneCoverage.data.coverage_status === 1 ? 'Activo' : 'Inactivo')}</td>
              </tr>
            </tbody>
          </Table>
          <Table striped bordered hover style={{ padding: '10px' }}>
            <thead>
              <tr>
                <th>Operador</th>
                <th>Zona</th>
                <th>Días de entrega</th>
                <th>Tarifa efectiva</th>
                <th>Tarifa no efectiva</th>
              </tr>
            </thead>
            <tbody>
              {oneCoverage && operators.map(operator => (
                <tr key={oneCoverage && operator.coverage_operator_id}>
                  <td>{oneCoverage && operator.operator}</td>
                  <td>{oneCoverage && operator.zone}</td>
                  <td>{oneCoverage && operator.deliveryTime}</td>
                  <td>{oneCoverage && operator.efective}</td>
                  <td>{oneCoverage && operator.noEfective}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </FormProvider>
        </div>
      </Modal>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    coverages: state.coverageState.coverages,
    coverage: state.coverageState.coverage,
    oneCoverage: state.coverageState.oneCoverage,
    rowEdited: state.coverageState.rowEdited,
    rowUpdateStatus: state.coverageState.rowUpdateStatus,
    rowUpdated: state.coverageState.rowUpdated,
    cities: state.cityState.cities,
    origins: state.originState.origins,
    coverageTypes: state.coverageState.coverageTypes,
    neighborhoodsByCity: state.neighborhoodState.neighborhoodsByCity,
    countries: state.countryState.countries,
    departmentsByCountry: state.departmentState.departmentsByCountry,
    citiesByDepartment: state.cityState.citiesByDepartment,
    branches: state.branchState.branches,
    errorsUpdateCoverage: state.coverageState.errorsUpdateCoverage,
    errorsCreateCoverage: state.coverageState.errorsCreateCoverage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    reduxGetOneCoverage: (payload) =>
      dispatch({
        type: "FETCH_ONECOVERAGE_REQUEST",
        value: payload,
      }),
    reduxPostCoverage: (payload) =>
      dispatch({
        type: "CREATE_COVERAGE_REQUEST",
        value: payload,
      }),
    reduxGetCoverage: (payload) =>
      dispatch({
        type: "FETCH_COVERAGE_REQUEST",
        value: payload,
      }),
    reduxUpdateStatusCoverage: (payload) =>
      dispatch({
        type: "UPDATE_STATUS_COVERAGE_REQUEST",
        value: payload,
      }),
    reduxPatchCoverage: (payload) =>
      dispatch({
        type: "UPDATE_COVERAGE_REQUEST",
        value: payload,
      }),
    reduxResetCoverageForm: () =>
      dispatch({
        type: "RESET_COVERAGE_FORM",
      }),
    reduxGetCities: (payload) =>
      dispatch({
        type: "FETCH_CITIES_REQUEST",
        value: payload,
      }),
    reduxGetCountries: (payload) =>
      dispatch({
        type: "FETCH_COUNTRIES_REQUEST",
        value: payload,
      }),
    reduxGetDepartmentsByCountry: (payload) =>
      dispatch({
        type: "READBYCOUNTRY_DEPARTMENT_REQUEST",
        value: payload,
      }),
    reduxGetCitiesByDepartment: (payload) =>
      dispatch({
        type: "READBYDEPARTMENT_CITY_REQUEST",
        value: payload,
      }),
    reduxGetNeighborhoodsByCity: (payload) =>
      dispatch({
        type: "READBYCITY_NEIGHBORHOOD_REQUEST",
        value: payload,
      }),
    reduxSetDepartment: (payload) =>
      dispatch({
        type: "RESET_BYCOUNTRY_DEPARTMENT",
        value: payload,
      }),
    reduxSetCity: (payload) =>
      dispatch({
        type: "RESET_BYDEPARTMENT_CITY",
        value: payload,
      }),
    reduxSetNeighborhood: (payload) =>
      dispatch({
        type: "RESET_BYCITY_NEIGHBORHOOD",
        value: payload,
      }),
    reduxGetBranches: (payload) =>
      dispatch({
        type: "FETCH_BRANCHES_REQUEST",
        value: payload,
      }),
    reduxGetOrigins: (payload) =>
      dispatch({
        type: "FETCH_ORIGINS_REQUEST",
        value: payload,
      }),
    reduxGetCoverageType: (payload) =>
      dispatch({
        type: "FETCH_COVERAGETYPE_REQUEST",
        value: payload,
      }),
    reduxResetDepartamentForm: () =>
    dispatch({
      type: "RESET_DEPARTMENT_FORM",
    }),
    reduxResetCityForm: () =>
    dispatch({
      type: "RESET_CITY_FORM",
    }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Coverage);