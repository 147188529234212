import Spinner from 'react-bootstrap/Spinner';

function BasicSpinner({...rest}) {
    return (
        <Spinner size={2} animation="grow" role="status">
            <small className="visually-hidden"></small>
        </Spinner>
    );
}

export default BasicSpinner;