import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import {
  StatusEnum,
  ConfirmacionCierreEnum,
  EstadoEnum,
} from "../../utils/enumsUtil";
import { isNullOrUndefindedAndEmpty } from "../../utils/pantallaUtil";
import { connect } from "react-redux";
import { addressCloseConfirmationActions } from "../../redux/reducers/AddressCloseConfirmationReducer";
import { useForm } from "react-hook-form";

const ModalsComponent = ({
  handleClose,
  address,
  reduxSave,
  modalShow,
  resetForm,
}) => {
  const [showSelectRecaudo, setShowSelectRecaudo] = useState(false);
  const [showSelectPaquete, setShowSelectPaquete] = useState(false);

  const form = useForm({
    defaultValues: {
      paquete: "",
      recaudo: "",
      dscripcion: "",
    },
  });
  const {
    formState: { errors: errorRange },
  } = form;

  useEffect(() => {
    // if addres has ids arrays to confirm
    if (address.hasOwnProperty("ids")) {
      setShowSelectPaquete(address.state === StatusEnum.NOENTR);
      setShowSelectRecaudo(address.state === StatusEnum.ENTREG);
    } else {
      setShowSelectPaquete(address.state === StatusEnum.NOENTR);
      setShowSelectRecaudo(
        address.state === StatusEnum.ENTREG && !isNullOrUndefindedAndEmpty(address.collection)
      );
    }
  });

  useEffect(() => {
    if (resetForm) {
      form.setValue("paquete", "");
      form.setValue("recaudo", "");
      form.setValue("dscripcion", "");
    }
  }, [resetForm]);

  const handleSubmit = (data) => {
    const mapdata = {
      idAddress: address.hasOwnProperty("ids")
        ? address.ids
        : [address.idAddress],
      withPack: data.paquete,
      withCollect: data.recaudo,
      description: data.dscripcion,
    };
    reduxSave(mapdata);
  };

  return (
    <>
      <Modal
        size="md"
        show={modalShow}
        onHide={handleClose}
        onEscapeKeyDown={handleClose}
        backdrop="static"
        keyboard={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirmación de cierre</Modal.Title>
        </Modal.Header>
        <form onSubmit={form.handleSubmit(handleSubmit)}>
          <Modal.Body>
            <div className={"row"}>
              {showSelectPaquete && (
                <div
                  className={
                    !showSelectRecaudo
                      ? "col-12 form-group"
                      : "col-6 form-group"
                  }
                >
                  <label>Recibí paquete</label>
                  <select
                    {...form.register("paquete", { required: true })}
                    type="text"
                    name={"paquete"}
                    className="form-control custom-select"
                  >
                    <option value={ConfirmacionCierreEnum.PAQUETE_COMPLETO}>
                      {EstadoEnum[ConfirmacionCierreEnum.PAQUETE_COMPLETO]}
                    </option>
                    <option value={ConfirmacionCierreEnum.PAQUETE_INCOMPLETO}>
                      {EstadoEnum[ConfirmacionCierreEnum.PAQUETE_INCOMPLETO]}
                    </option>
                    <option value={ConfirmacionCierreEnum.PAQUETE_NO_ENTREGADO}>
                      {EstadoEnum[ConfirmacionCierreEnum.PAQUETE_NO_ENTREGADO]}
                    </option>
                  </select>
                </div>
              )}
              {showSelectRecaudo && (
                <div
                  className={
                    !showSelectPaquete
                      ? "col-12 form-group"
                      : "col-6 form-group"
                  }
                >
                  <label>Recaudo</label>
                  <select
                    {...form.register("recaudo", { required: true })}
                    type="text"
                    name={"recaudo"}
                    className="form-control custom-select"
                  >
                    <option value={ConfirmacionCierreEnum.EFECTIVO_COMPLETO}>
                      {EstadoEnum[ConfirmacionCierreEnum.EFECTIVO_COMPLETO]}
                    </option>
                    <option value={ConfirmacionCierreEnum.EFECTIVO_INCOMPLETO}>
                      {EstadoEnum[ConfirmacionCierreEnum.EFECTIVO_INCOMPLETO]}
                    </option>
                    <option
                      value={ConfirmacionCierreEnum.EFECTIVO_NO_ENTREGADO}
                    >
                      {EstadoEnum[ConfirmacionCierreEnum.EFECTIVO_NO_ENTREGADO]}
                    </option>
                  </select>
                </div>
              )}
            </div>
            <div className={"row"}>
              <div className={"col-12 form-group"}>
                <label>Descripción</label>
                <textarea
                  {...form.register("dscripcion", { required: false })}
                  name={"dscripcion"}
                  className={"form-control"}
                ></textarea>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancelar
            </Button>
            <Button variant="primary" type={"submit"}>
              Guardar
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    cities: state.cityState.cities,
    modalShow: state.addressCloseConfirmation.modalShow,
    resetForm: state.addressCloseConfirmation.resetForm,
    couriers: state.userState.couriers,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    reduxSave: (payload) => {
      dispatch({
        type: addressCloseConfirmationActions.POST_CLOSECONFIRMATION,
        value: payload,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalsComponent);

export class EstadosRecibidoEnum {
  static incompleto = "Incompleto";
  static no_entregado = "No entregado";
  static completo = "Completo";
}
