import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { Modal, Button, Col, Row } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { SpanishMovementSubTypesEnum, SpanishMovementTypesEnum } from '../../../../../utils/enumsUtil';
import formatDateWithCustomFormat, { FormatsDate } from '../../../../../utils/formatDate';
import Grid from '../../../../Grid';

const InventoryHistory = ({ 
    show, 
    handleClose, 
    historyInfo, 
    historyMovements, 
    reduxGetHistoryMovements
 }) => {
    useEffect(() => {
        setStartDateFrom(null);
        setStartDateTo(null);

        if (show && historyInfo && historyInfo) {
            let filters = {
                idInventory: historyInfo.idInventory,
                page: currentPage,
                offset: offset,
                search: search,
              };
              reduxGetHistoryMovements(filters);
        }
    }, [show, historyInfo, reduxGetHistoryMovements]);

    const [offset, setOffset] = useState(10);
    const [search, setSearch] = useState(''); 
    const [currentPage, setCurrentPage] = useState(1);
    
    const [startDateFrom, setStartDateFrom] = useState(null);
    const [startDateTo, setStartDateTo] = useState(null);    

    const customStyles = {
        menu: (base) => ({ ...base, zIndex: 9999 }),
        valueContainer: (baseStyles) => ({ ...baseStyles, maxHeight: "3.5vw", overflowY: "auto" })
    };
    const handledChangeFrom = (date) => {
        setStartDateFrom(date);
        if (!date) {
          return setStartDateTo(date);
        }
        const toDate = new Date(date);
        toDate.setHours(23, 59, 59, 0);
        setStartDateTo(toDate);
      };
    
      const handledChangeTo = (date) => {
        setStartDateTo(date);
      };

    useEffect(() => {
        if (historyInfo && historyInfo) {
            let filters = {
                idInventory: historyInfo.idInventory,
                page: currentPage,
                offset: offset,
                search: search,
              };
              if (startDateFrom && startDateTo) {
                filters = {
                  ...filters,
                  from: formatDateWithCustomFormat(startDateFrom, {
                    format: FormatsDate.FULL24,
                  }),
                  to: formatDateWithCustomFormat(startDateTo, {
                    format: FormatsDate.FULL24,
                  }),
                };
            }
            reduxGetHistoryMovements(filters);
        }          
    }, [currentPage, offset, reduxGetHistoryMovements, search, startDateFrom, startDateTo]);

    const searchByFilter = () => {
        let filters = {
          idInventory: historyInfo.idInventory,
          page: currentPage,
          offset: offset,
          search: search,
        };
        if (startDateFrom && startDateTo) {
          filters = {
            ...filters,
            from: formatDateWithCustomFormat(startDateFrom, {
              format: FormatsDate.FULL24,
            }),
            to: formatDateWithCustomFormat(startDateTo, {
              format: FormatsDate.FULL24,
            }),
          };
        }
        reduxGetHistoryMovements(filters);
      }

      function replaceSpecialChars(str) {
        const replacements = {
            'á': 'a', 'é': 'e', 'í': 'i', 'ó': 'o', 'ú': 'u',
            'Á': 'A', 'É': 'E', 'Í': 'I', 'Ó': 'O', 'Ú': 'U',
            'ñ': 'n', 'Ñ': 'N'
        };
        return str.replace(/[áéíóúÁÉÍÓÚñÑ]/g, (match) => replacements[match] || match);
    }

    const columns = [
        {
            title: "# Movimiento",
            render: (rowData) => {
                return <span>{rowData.idMovement}</span>;
            },
            field: "idMovement",
            searchable: false,
        },
        {
            title: "Fecha",
            render: (rowData) => {
                return <span>{new Date(rowData.date).toLocaleDateString('es-ES')}</span>;
            },
            field: "date",
            searchable: false,
        },
        {
            title: "Tipo",
            render: (rowData) => {
                return <span>{SpanishMovementTypesEnum[rowData.type]}</span>;
            },
            field: "type",
            searchable: true,
        },
        {
            title: "Subtipo",
            render: (rowData) => {
                return <span>{SpanishMovementSubTypesEnum[rowData.subtype]}</span>;
            },
            field: "subtype",
            searchable: true,
        },
        {
            title: "Cantidad",
            render: (rowData) => {
                return <span>{`${rowData.quantity}`}</span>;
            },
            field: "quantity",
            searchable: false,
        },
        {
            title: "Motivo",
            render: (rowData) => {
                const formattedNotes = replaceSpecialChars(rowData.notes);
                return <span>{formattedNotes}</span>;
            },
            field: "notes",
            searchable: false,
        },
        {
            title: "Nombre",
            render: (rowData) => {
                const formattedNames = replaceSpecialChars(rowData.name);
                return <span>{formattedNames}</span>;
            },
            field: "name",
            searchable: true,
        },
    ];
    
    return (
        <Modal 
            show={show} 
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            size="xl"
            scrollable={true}
            dialogClassName= {customStyles}
        >
            <Modal.Header closeButton>
                <div className="d-flex justify-content-between align-items-center">
                    <Modal.Title>Detalles de Movimiento</Modal.Title>
                </div>
            </Modal.Header>
            <Modal.Body style={{ overflowX: 'hidden', maxHeight: '55vh' }}>
                <div className="card-body">
                    <Row>
                    <Col md={2.5} className='mx-3'>
                      <label>Desde</label>
                      <br />
                      <DatePicker
                        selected={startDateFrom}
                        onChange={(date) => handledChangeFrom(date)}
                        dateFormat='MM-dd-yyyy hh:mm a'
                        maxDate={new Date()}
                        showTimeSelect
                        peekNextMonth
                        className='form-control'
                        isClearable
                        timeIntervals={10}
                      />
                    </Col>
                    <Col md={2.5} >
                      <label>Hasta</label>
                      <br />
                      <DatePicker
                        selected={startDateTo}
                        onChange={(date) => handledChangeTo(date)}
                        dateFormat='MM-dd-yyyy hh:mm a'
                        maxDate={new Date()}
                        minDate={startDateFrom}
                        showTimeSelect
                        peekNextMonth
                        className='form-control'
                        isClearable
                        timeIntervals={10}
                        filterTime={(time) => {
                          if (!startDateFrom) {
                            return true;
                          }
                          const selectedTime = startDateFrom.getTime();
                          const currentTime = time.getTime();
                          return currentTime >= selectedTime;
                        }}
                      />
                    </Col>
                    </Row>
                    <Grid
                        cols={columns}
                        data={historyMovements && Object.keys(historyMovements).length > 0
                            ? historyMovements.items
                            : []}
                        page={historyMovements && Object.keys(historyMovements).length > 0
                            ? Number(historyMovements.page)
                            : 1}
                        pages={historyMovements && Object.keys(historyMovements).length > 0
                            ? Number(historyMovements.totalPages)
                            : 0}
                        total={historyMovements && historyMovements.hasOwnProperty("total") ? historyMovements.total : 0}
                        offset={offset}
                        
                    onChangePage={(page) => setCurrentPage(page)}
                    setCurrentPage={(setCurrentPage) => reduxGetHistoryMovements({
                            idInventory: historyInfo.idInventory,
                            page: setCurrentPage,
                            offset: offset,
                            search: search,
                        })}
                        onChangeRange={(value) => {
                            setOffset(value);
                            reduxGetHistoryMovements({
                                idInventory: historyInfo.idInventory,
                                page: 1,
                                offset: value,
                                search: search,
                            });
                        } }
                        defaultValue={search}
                        onChangeSearch={(value) => {
                            setSearch(value);
                            reduxGetHistoryMovements({
                                idInventory: historyInfo.idInventory,
                                page: 1,
                                offset: offset,
                                search: value,
                            });
                        } } 
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cerrar
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

const mapStateToProps = (state) => {
    return {
        historyMovements: state.warehouseState.historyMovements,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        reduxGetHistoryMovements: (payload) =>
            dispatch({
                type: "READ_HISTORY_MOVEMENTS_REQUEST",
                value: payload,
            }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(InventoryHistory);