import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { Button, Modal } from "react-bootstrap";
import ContentWrapper from "../../components/Common/ContentWrapper";
import { addressCardinalActions } from "../../redux/reducers/addressCardinalReducer";
import Grid from "../../components/Grid";
import { Show } from "../../hooks/Show";

const AddressCardinal = ({
  requestStatus,
  reduxResetAddressCardinalForm,
  reduxPatchAddressCardinal,
  reduxPostAddressCardinal,
  reduxDeleteAddressCardinal,
  reduxGetAddressCardinal,
  addressCardinals,
  reduxGetAddressCardinals,
  addressCardinal,
  rowEdited,
  rowDeleted,
  rowUpdated,
}) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
    setValue,
  } = useForm();
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [show, setShow] = useState(false);
  const [offset, setOffset] = useState(10);
  const columns = [
    {
      title: "#",
      render: (rowData) => {
        return <span>{rowData.idAddressCardinalPoint}</span>;
      },
      field: 'idAddressCardinalPoint',
      searchable: true
    },
    {
      title: "Código",
      render: (rowData) => {
        return <span>{rowData.code}</span>;
      },
      field: 'code',
      searchable: true
    },
    {
      title: "Descripción",
      render: (rowData) => {
        return <span>{rowData.name}</span>;
      },
      field: 'name',
      searchable: true
    },
    {
      title: "Opciones",
      render: (rowData) => {
        return (
          <>
            {rowData.isActive ? (
              <>
                <Show when="feature:edit-addressCardinal">
                  <button
                    title="Editar"
                    className="btn btn-primary btn-sm  btn-circle mr-2"
                    type="button"
                    onClick={(e) => handleOpen(rowData)}
                  >
                    <i className="fas fa-edit fa-xs"></i>
                  </button>
                </Show>
                <Show when="feature:disabled-addressCardinal">
                  <button
                    title="Desactivar"
                    className="btn btn-danger btn-sm btn-circle"
                    type="button"
                    onClick={(e) => handleDelete(rowData)}
                  >
                    <i className="fas fa-times-circle fa-xs"></i>
                  </button>
                </Show>
              </>
            ) : (
              <Show when="feature:enabled-addressCardinal">
                <button
                  title="Activar"
                  className="btn btn-primary btn-sm  btn-circle mr-2"
                  type="button"
                  onClick={(e) => handleActive(rowData)}
                >
                  <i className="fas fa-check-circle fa-xs"></i>
                </button>
              </Show>
            )}
          </>
        );
      },
    },
  ];
  const handleClose = () => {
    reduxResetAddressCardinalForm();
    setShow(false);
  };
  const handleShow = () => setShow(true);
  const onSubmit = (data) => {
    if (rowEdited) {
      reduxPatchAddressCardinal({
        ...data,
        id: rowEdited.idAddressCardinalPoint,
      });
    } else {
      reduxPostAddressCardinal({ ...data, id: data.name, code: data.name });
    }
    reset();
    reduxResetAddressCardinalForm();
    setShow(false);
  };
  const handleOpen = (row) => {
    reduxGetAddressCardinal({
      id: row.idAddressCardinalPoint,
    });
  };

  const handleActive = (row) => {
    if (!row.isActive) {
      reduxDeleteAddressCardinal(row);
    }
  };

  const handleDelete = (row) => {
    reduxDeleteAddressCardinal(row);
  };
  useEffect(() => {
    reduxGetAddressCardinals({
      page: 1,
      offset: offset,
      search: "",
    });
  }, []);

  useEffect(() => {
    if (rowEdited) {
      setShow(true);
      setValue("name", rowEdited.name);
      setValue("code", rowEdited.code);
    }
  }, [rowEdited]);

  useEffect(() => {
    reduxGetAddressCardinals({
      page: currentPage,
      offset: offset,
      search: search,
    });
  }, [currentPage]);

  useEffect(() => {
    if (addressCardinal || rowUpdated || rowDeleted) {
      reduxGetAddressCardinals({
        page: currentPage,
        offset: offset,
        search: search,
      });
      reduxResetAddressCardinalForm();
    }
  }, [addressCardinal, rowUpdated, rowDeleted]);

  return (
    <ContentWrapper>
      <div className="d-sm-flex align-items-center mb-1">
        <h1 className="h3 mb-0 text-gray-800 mr-3">Orientación</h1>
        <Show when="feature:create-addressCardinal">
          <button
            className="btn btn-primary btn-circle"
            type="button"
            onClick={handleShow}
          >
            <i className="fas fa-plus fa-sm"></i>
          </button>
        </Show>
      </div>
      <p className="mb-4">Orientaciónes</p>
      <div className="card shadow mb-4">
        <div className="card-header py-3">
          <h6 className="m-0 font-weight-bold text-primary">
            Listado de Orientaciónes
          </h6>
        </div>
        <div className="card-body">
          <Grid
            cols={columns}
            data={
              addressCardinals && Object.keys(addressCardinals).length > 0
                ? addressCardinals.items
                : []
            }
            page={
              addressCardinals && Object.keys(addressCardinals).length > 0
                ? Number(addressCardinals.page)
                : currentPage
            }
            pages={
              addressCardinals && Object.keys(addressCardinals).length > 0
                ? Number(addressCardinals.totalPages)
                : 1
            }
            total={
              addressCardinals && addressCardinals.hasOwnProperty("total")
                ? addressCardinals.total
                : 0
            }
            offset={offset}
            onChangePage={(page) => setCurrentPage(page)}
            onChangeRange={(value) => {
              setOffset(value);
              reduxGetAddressCardinals({
                page: 1,
                offset: value,
                search: search,
              });
            }}
            defaultValue={search}
            onChangeSearch={(value) => {
              setSearch(value);
              reduxGetAddressCardinals({
                page: 1,
                offset: offset,
                search: value,
              });
            }}
          />
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        onEscapeKeyDown={handleClose}
        backdrop="static"
        keyboard={true}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton>
            <Modal.Title>
              {rowEdited ? "Editar" : "Nueva"} Orientación
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label htmlFor="code" className="form-label">
                Código
              </label>
              <input
                id="code"
                type="text"
                className={`form-control form-control-user ${
                  errors.code && "is-invalid"
                }`}
                {...register("code", { required: true })}
              />
              {errors.code && (
                <span className="invalid-feedback">El código es requerido</span>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="name" className="form-label">
                Descripción
              </label>
              <input
                id="name"
                type="text"
                className={`form-control form-control-user ${
                  errors.name && "is-invalid"
                }`}
                {...register("name", { required: true })}
              />
              {errors.name && (
                <span className="invalid-feedback">
                  La Descripción requerida
                </span>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancelar
            </Button>
            <Button variant="primary" type="submit">
              Guardar
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </ContentWrapper>
  );
};

const mapStateToProps = (state) => ({
  addressCardinals: state.addressCardinal.addressCardinals,
  addressCardinal: state.addressCardinal.addressCardinal,
  rowEdited: state.addressCardinal.rowEdited,
  rowDeleted: state.addressCardinal.rowDeleted,
  rowUpdated: state.addressCardinal.rowUpdated,
});

const mapDispatchToProps = (dispatch) => ({
  reduxGetAddressCardinals: (payload) =>
    dispatch({
      type: addressCardinalActions.FETCH_ADDRESSES_CARDINAL_POINTS_REQUEST,
      value: payload,
    }),
  reduxPostAddressCardinal: (payload) =>
    dispatch({
      type: addressCardinalActions.CREATE_ADDRESSCARDINAL_REQUEST,
      value: payload,
    }),
  reduxGetAddressCardinal: (payload) =>
    dispatch({
      type: addressCardinalActions.READ_ADDRESSCARDINAL_REQUEST,
      value: payload,
    }),
  reduxDeleteAddressCardinal: (payload) =>
    dispatch({
      type: addressCardinalActions.DELETE_ADDRESSCARDINAL_REQUEST,
      value: payload,
    }),
  reduxPatchAddressCardinal: (payload) =>
    dispatch({
      type: addressCardinalActions.UPDATE_ADDRESSCARDINAL_REQUEST,
      value: payload,
    }),
  reduxResetAddressCardinalForm: () =>
    dispatch({
      type: addressCardinalActions.RESET_ADDRESSCARDINAL_FORM,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddressCardinal);
