import { useState, useContext } from "react";
import { Tabs, Tab } from "react-bootstrap";

import { PermissionContext } from '../../hooks/PermissionContext';

import Sidebar from "../../components/Navigation/Sidebar";
import Topbar from "../../components/Navigation/Topbar";
import Header from "../../components/Page/header";
import AlertsList from "./alertsList";
import AlertTypes from "./alertTypes";
import AlertSources from "./alertSources";
import AlertsLog from "./alertsLog";

const Alerts = ({ }) => {
    const [activeTab, setActiveTab] = useState('');
    const [alertListTabActive, setAlertListTabActive] = useState(false);
    const [alertTypeTabActive, setAlertTypeTabActive] = useState(false);
    const [alertSourceTabActive, setAlertSourceTabActive] = useState(false);
    const [alertLogTabActive, setAlertLogTabActive] = useState(false);
    const { hasPermission } = useContext(PermissionContext);

    const ChangeTab = (tab) => {
        setActiveTab(tab);
        switch (tab) {
            case 'tab1':
                setAlertListTabActive(true);
                setAlertTypeTabActive(false);
                setAlertSourceTabActive(false);
                setAlertLogTabActive(false);
                break;
            case 'tab2':
                setAlertListTabActive(false);
                setAlertTypeTabActive(true);
                setAlertSourceTabActive(false);
                setAlertLogTabActive(false);
                break;
            case 'tab3':
                setAlertListTabActive(false);
                setAlertTypeTabActive(false);
                setAlertSourceTabActive(true);
                setAlertLogTabActive(false);
                break;
            case 'tab4':
                setAlertListTabActive(false);
                setAlertTypeTabActive(false);
                setAlertSourceTabActive(false);
                setAlertLogTabActive(true);
                break;
            default:
                break;
        }
    }

    return (
        <div id="wrapper">
            <Sidebar />
            <div id="content-wrapper" className="d-flex flex-column">
                <div id="content">
                    <Topbar />
                    <div className="container-fluid">
                        <Header title={"Alertas"} subtitle={"Módulo para la gestión de Alertas"} />
                        <div className="card shadow mb-4">
                            <div className="card-body">
                                <Tabs activeKey={activeTab} onSelect={ChangeTab} >
                                    <Tab eventKey="tab1" title="Listado de Alertas" disabled={!hasPermission('feature:alerts-tab-show')}>
                                        <AlertsList activeTab={alertListTabActive}/>
                                    </Tab>
                                    <Tab eventKey="tab2" title="Tipo de Alertas"  disabled={!hasPermission('feature:alerts-type-tab-show')}>
                                        <AlertTypes activeTab={alertTypeTabActive}/>
                                    </Tab>
                                    <Tab eventKey="tab3" title="Fuente de Alertas" disabled={!hasPermission('feature:alerts-source-tab-show')}>
                                        <AlertSources activeTab={alertSourceTabActive}/>
                                    </Tab>
                                    <Tab eventKey="tab4" title="Log de Alertas" disabled={!hasPermission('feature:alerts-log-tab-show')}>
                                        <AlertsLog activeTab={alertLogTabActive}/>
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Alerts;