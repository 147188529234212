import {call, put, takeLatest} from "redux-saga/effects";
import request from "../../utils/request";

function* changePassword({ data }) {
  try {
    yield put({
      type: "CHANGEPASSWORD_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/auth/change-password/${data.idUser}`;

    // eslint-disable-next-line no-unused-vars
    const response = yield call(request, requestURL, {
      method: "PATCH",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(data),
    });

    yield put({
      type: "CHANGEPASSWORD_SUCCESS",
      value: response,
    });
    yield put({
      type: "HIDE_LOADING",
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "CHANGEPASSWORD_ERROR",
    });
  }
}

export function* watchChangePassword() {
  yield takeLatest("CHANGEPASSWORD_REQUEST", changePassword);
}
