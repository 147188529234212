import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import Sidebar from "../../components/Navigation/Sidebar";
import Topbar from "../../components/Navigation/Topbar";
import Grid from "../../components/Grid";
import Map from "../../components/Map";
import { connect } from "react-redux";
import { Button, Modal } from "react-bootstrap";
import { Show } from "../../hooks/Show";
import { useAuth } from "../../hooks/useAuth";
import Select from 'react-select'
import moment from "moment-timezone";

const Swal = require("sweetalert2");

const Zone = ({
  reduxGetCompanies,
  companies,
  reduxGetAreas,
  areas,
  reduxGetZones,
  zones,
  reduxGetZone,
  zone,
  reduxPostZone,
  rowEdited,
  reduxDeleteZone,
  rowDeleted,
  reduxPatchZone,
  rowUpdated,
  reduxResetZoneForm,
  reduxUploadFile,
  fileUploaded,
  reduxGetCities,
  cities,
  errorsUploadFileAddress,
  reduxGetZoneAudits,
  zoneAuditsByZone,
  reduxPathReverseZone,
  zoneAudit,
}) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
    setValue,
    getValues,
  } = useForm();
  const {
    register: registerUpload,
    handleSubmit: handleSubmitUpload,
    watch: watchUpload,
    formState: { errors: errorsUpload },
  } = useForm();
  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(10);
  const { session } = useAuth();
  const [show, setShow] = useState(false);
  const [fileName, setFileName] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const file = useRef({});
  file.current = watchUpload("file", "");

  const handleClose = () => {
    reduxResetZoneForm();
    setShow(false);
  };
  const handleCloseEdit = () => {
    reduxResetZoneForm();
    setShowEditModal(false);
  };
  const handleCloseHistory = () => {
    reduxResetZoneForm();
    setShowHistory(false);
  };
  const handleShow = () => {
    reduxGetCities({
      page: 1,
      search: "",
      offset: 100000,
    });
    reduxGetCompanies({
      page: 1,
      search: "",
      offset: 1000,
    });
    reduxResetZoneForm();
    setFileName(null);
    reset();
    setShow(true);
  };
  const [search, setSearch] = useState("");

  const columns = [
    {
      title: "#",
      render: (rowData) => {
        return <span>{rowData.idZone}</span>;
      },
      field: 'idZone',
      searchable: true
    },
    {
      title: "Descripción",
      render: (rowData) => {
        return <span>{rowData.description}</span>;
      },
      field: 'description',
      searchable: true
    },
    {
      title: "Ciudad",
      render: (rowData) => {
        return <span>{rowData.city.description}</span>;
      },
      field: 'city.description',
      searchable: true
    },
    {
      title: "Empresa",
      render: (rowData) => {
        return (
          <span>{rowData.company ? rowData.company.description : ""}</span>
        );
      },
      field: 'company.description',
      searchable: true
    },
    {
      title: "Opciones",
      render: (rowData) => {
        return (
          <>
            {rowData.isActive ? (
              <>
                <Show when="feature:edit-zone">
                  <button
                    title="Editar"
                    className="btn btn-primary btn-sm  btn-circle mr-2"
                    type="button"
                    onClick={(e) => handleOpen(rowData)}
                  >
                    <i className="fas fa-edit fa-xs"></i>
                  </button>
                </Show>
                <Show when="feature:disabled-zone">
                  <button
                    title="Desactivar"
                    className="btn btn-danger btn-sm btn-circle mr-2"
                    type="button"
                    onClick={(e) => handleDelete(rowData)}
                  >
                    <i className="fas fa-times-circle fa-xs"></i>
                  </button>
                </Show>
                <Show when="feature:edit-zone">
                  <button
                    title="Historial"
                    className="btn btn-danger btn-sm btn-circle"
                    type="button"
                    onClick={(e) => handleRollback(rowData)}
                  >
                    <i className="fas fa-history fa-xs"></i>
                  </button>
                </Show>
              </>
            ) : (
              <Show when="feature:enabled-zone">
                <button
                  title="Activar"
                  className="btn btn-primary btn-sm  btn-circle mr-2"
                  type="button"
                  onClick={(e) => handleActive(rowData)}
                >
                  <i className="fas fa-check-circle fa-xs"></i>
                </button>
              </Show>
            )}
          </>
        );
      },
    },
  ];

  const handleReverseZone = (row) => {
    reduxPathReverseZone(row);
  };

  const handleOpen = (row) => {
    reduxGetZone({
      id: row.idZone,
    });
  };

  const handleActive = (row) => {
    if (!row.isActive) {
      reduxDeleteZone(row);
    }
  };

  useEffect(() => {
    if (file.current != null && file.current.length > 0) {
      setFileName(file.current[0].name);
    }
  }, [file.current]);

  useEffect(() => {
    if (rowEdited) {
      setShowEditModal(true);
      setValue("description", rowEdited.description);
      setValue("properties", JSON.stringify(rowEdited.properties));
      setValue("geometry", JSON.stringify(rowEdited.geometry));
      setValue("idCity2", {value: rowEdited.idCity, label: rowEdited.city.description});
    }
  }, [rowEdited]);

  const handleDelete = (row) => {
    reduxDeleteZone(row);
  };

  const handleRollback = (row) => {
    reduxGetZoneAudits({
      page: 1,
      search: "",
      offset: 100000,
      idZone: row.idZone,
    });
  };

  useEffect(() => {
    if (zoneAuditsByZone != null && zoneAuditsByZone.items[0]) {
      setShowHistory(true);
    } else if (zoneAuditsByZone != null) {
      Swal.fire(
        "Notificación!",
        "La zona no tiene historial de cambios.",
        "warning"
      );
    }
  }, [zoneAuditsByZone]);

  useEffect(() => {
    reduxGetZones({
      page: 1,
      offset: offset,
      search: "",
    });
    reduxGetCities({
      page: 1,
      search: "",
      offset: 100000,
    });
    zoneAuditsByZone = null;
  }, []);

  useEffect(() => {
    if (currentPage) {
      reduxGetZones({
        page: currentPage,
        offset: offset,
        search: search,
      });
    }
  }, [currentPage]);

  const onSubmitUpload = (data) => {
    const file = data.file[0];
    reduxUploadFile({
      file: file,
      company:
        session && session.role.idCompany
          ? session.role.idCompany
          : data.idCompany,
      city: data.idCity,
    });
  };

  const onSubmit = (data) => {
    data = {
      ...data,
      idZone: rowEdited.idZone,
      properties: JSON.parse(getValues("properties")),
      geometry: JSON.parse(getValues("geometry")),
      isActive: rowEdited.isActive == 1 ? true : false,
      idCompany: rowEdited.idCompany,
      idCity: data.idCity2,
    };
    reduxPatchZone({ ...data, id: rowEdited.idZone });
    reset();
    reduxResetZoneForm();
    setShowEditModal(false);
  };

  useEffect(() => {
    if (fileUploaded) {
      setShow(false);
      reduxGetZones({
        page: currentPage,
        offset: offset,
        search: search,
      });
      reduxResetZoneForm();
      reset();
    }
  }, [fileUploaded]);

  useEffect(() => {
    if (errorsUploadFileAddress) {
      setShow(false);
      reduxResetZoneForm();
      reset();
    }
  }, [errorsUploadFileAddress]);

  useEffect(() => {
    if (zone || rowUpdated || rowDeleted) {
      reduxGetZones({
        page: currentPage,
        offset: offset,
        search: search,
      });
      reduxResetZoneForm();
    }
  }, [zone, rowUpdated, rowDeleted]);

  useEffect(() => {
    if (zoneAudit) {
      setShowHistory(false);
      reduxGetZones({
        page: currentPage,
        offset: offset,
        search: search,
      });
      reduxResetZoneForm();
      reset();
    }
  }, [zoneAudit]);

  return (
    <div>
      <div id="wrapper">
        <Sidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Topbar />
            <div className="container-fluid">
              <div className="d-sm-flex align-items-center mb-1">
                <h1 className="h3 mb-0 text-gray-800 mr-3">Zonas</h1>
                <Show when="feature:create-zone">
                  <button
                    className="btn btn-primary btn-circle"
                    type="button"
                    onClick={handleShow}
                  >
                    <i className="fas fa-upload fa-sm"></i>
                  </button>
                </Show>
              </div>
              <p className="mb-4">Módulo de Administración de zonas</p>
              <div className="card shadow mb-4">
                <div className="card-header py-3">
                  <h6 className="m-0 font-weight-bold text-primary">
                    Listado de Zonas
                  </h6>
                </div>
                <div className="card-body">
                  <Grid
                    cols={columns}
                    data={
                      zones && Object.keys(zones).length > 0 ? zones.items : []
                    }
                    page={
                      zones && Object.keys(zones).length > 0
                        ? Number(zones.page)
                        : currentPage
                    }
                    pages={
                      zones && Object.keys(zones).length > 0
                        ? Number(zones.totalPages)
                        : 0
                    }
                    total={
                      zones && zones.hasOwnProperty("total") ? zones.total : 0
                    }
                    offset={offset}
                    onChangePage={(page) => setCurrentPage(page)}
                    onChangeRange={(value) => {
                      setOffset(value);
                      reduxGetZones({
                        page: 1,
                        offset: value,
                        search: search,
                      });
                    }}
                    defaultValue={search}
                    onChangeSearch={(value) => {
                      setSearch(value);
                      reduxGetZones({
                        page: 1,
                        offset: offset,
                        search: value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Modal
          show={show}
          onHide={handleClose}
          onEscapeKeyDown={handleClose}
          backdrop="static"
          keyboard={true}
        >
          <form onSubmit={handleSubmitUpload(onSubmitUpload)}>
            <Modal.Header closeButton>
              <Modal.Title>Cargar archivo KML</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-group">
                <label htmlFor="idCity" className="form-label">
                  Ciudad
                </label>
                
                <Select
                  isClearable={true}
                  {...register('idCity')}
                  id='idCity'
                  onChange={(value) => setValue('idCity', value?.value || '')}
                  options=
                  {cities &&
                    Object.keys(cities).length > 0 &&
                    cities.items
                      .map((ele) => ({                                    
                          value: ele.idCity,
                          label: ele.description,
                        }))
                  }
                />
                {errorsUpload.idCity && (
                  <span className="invalid-feedback">
                    La ciudad es requerida
                  </span>
                )}
              </div>
              {session && session.role.idCompany == null && (
                <div className="form-group">
                  <label htmlFor="idCompany" className="form-label">
                    Empresa
                  </label>
                  
                <Select
                  isClearable={true}
                  {...register('idCompany')}
                  id='idCompany'
                  onChange={(value) => setValue('idCompany', value?.value || '')}
                  options=
                  {companies &&
                    Object.keys(companies).length > 0 &&
                    companies.items
                    .filter((f) => f.isActive == true)
                    .map((ele) => ({                                    
                          value: ele.idCompany,
                          label: ele.description,
                        }))
                  }
                />
                  {errorsUpload.idCompany && (
                    <span className="invalid-feedback">
                      La empresa es requerida
                    </span>
                  )}
                </div>
              )}
              <div className="row">
                <div className="form-group col-md-12">
                  <div className="custom-file">
                    <input
                      type="file"
                      id="file"
                      name="file"
                      {...registerUpload("file", { required: true })}
                      className={`custom-file-input ${
                        errorsUpload.file && "is-invalid"
                      }`}
                    />{" "}
                    <label
                      className="custom-file-label"
                      htmlFor="validatedCustomFile"
                    >
                      {fileName ? fileName : "Seleccionar archivo..."}
                    </label>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cancelar
              </Button>
              <Button variant="primary" type="submit">
                Guardar
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      </div>
      <div>
        <Modal
          show={showEditModal}
          onHide={handleCloseEdit}
          onEscapeKeyDown={handleCloseEdit}
          backdrop="static"
          keyboard={true}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <Modal.Header closeButton>
              <Modal.Title>Editar Zona</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-group">
                <label htmlFor="description" className="form-label">
                  Descripción
                </label>
                <input
                  id="description"
                  type="text"
                  className={`form-control form-control-user ${
                    errors.description && "is-invalid"
                  }`}
                  {...register("description", { required: true })}
                />
                {errors.description && (
                  <span className="invalid-feedback">
                    La descripción es requerida
                  </span>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="properties" className="form-label">
                  Propiedades
                </label>
                <input
                  id="properties"
                  type="text"
                  className={`form-control form-control-user ${
                    errors.properties && "is-invalid"
                  }`}
                  {...register("properties", { required: true })}
                />
                {errors.properties && (
                  <span className="invalid-feedback">
                    Las propiedades son requeridas
                  </span>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="geometry" className="form-label">
                  Geometria
                </label>
                <input
                  id="geometry"
                  type="text"
                  className={`form-control form-control-user ${
                    errors.geometry && "is-invalid"
                  }`}
                  {...register("geometry", { required: true })}
                />
                {errors.geometry && (
                  <span className="invalid-feedback">
                    La geometria es requerida
                  </span>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="idCity2" className="form-label">
                  Ciudad
                </label>
                <Select
                  isClearable={true}
                  {...register('idCity2')}
                  id='idCity2'
                  value={getValues("idCity2")}
                  onChange={(value) => setValue('idCity2', value?.value || '')}
                  options=
                  {cities &&
                    Object.keys(cities).length > 0 &&
                    cities.items
                      .map((ele) => ({                                    
                          value: ele.idCity,
                          label: ele.description,
                        }))
                  }
                />
                {errors.idCity2 && (
                  <span className="invalid-feedback">
                    La ciudad es requerida
                  </span>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseEdit}>
                Cancelar
              </Button>
              <Button variant="primary" type="submit">
                Guardar
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      </div>
      <div>
        <Modal
          show={showHistory}
          onHide={handleCloseHistory}
          onEscapeKeyDown={handleCloseHistory}
          backdrop="static"
          keyboard={true}
          size="xl"
        >
          <form>
            <Modal.Header closeButton>
              <Modal.Title>Historial Zona</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {zoneAuditsByZone && Object.keys(zoneAuditsByZone).length > 0 && (
                <h1>
                  {"Zona: "}
                  {zoneAuditsByZone.items[0] &&
                    zoneAuditsByZone.items[0].idZone}
                </h1>
              )}
              {zoneAuditsByZone &&
                Object.keys(zoneAuditsByZone).length > 0 &&
                zoneAuditsByZone.items.map((ele, key) => (
                  <>
                    <div className="card shadow mb-4" key={key}>
                      <div>
                        <h6 className="my-0 font-weight-bold">
                          {"Auditoria #"}
                          {ele.idZoneAudit}
                        </h6>
                        <h6 className="my-0 font-weight-bold">
                          {"Descripción: "}
                          {ele.data.newObject.description}
                        </h6>
                        <h6 className="my-0 font-weight-bold">
                          {"Fecha de modificación: "}
                          {moment(ele.createdAt)
                            .tz("America/Bogota")
                            .format("MM-DD-YYYY")}
                        </h6>
                        <a href="#" onClick={(e) => handleReverseZone(ele)}>
                          {"Actualizar"}
                        </a>
                        <Map item={ele} key={key}></Map>
                      </div>
                    </div>
                  </>
                ))}
            </Modal.Body>
          </form>
        </Modal>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    areas: state.areaState.areas,
    zones: state.zoneState.zones,
    zone: state.zoneState.zone,
    rowEdited: state.zoneState.rowEdited,
    rowDeleted: state.zoneState.rowDeleted,
    rowUpdated: state.zoneState.rowUpdated,
    companies: state.companyState.companies,
    fileUploaded: state.zoneState.fileUploaded,
    cities: state.cityState.cities,
    errorsUploadFileAddress: state.zoneState.errorsUploadFileAddress,
    zoneAuditsByZone: state.zoneState.zoneAuditsByZone,
    zoneAudit: state.zoneState.zoneAudit,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    reduxGetAreas: (payload) =>
      dispatch({
        type: "FETCH_AREAS_REQUEST",
        value: payload,
      }),
    reduxGetCompanies: (payload) =>
      dispatch({
        type: "FETCH_COMPANIES_REQUEST",
        value: payload,
      }),
    reduxGetZones: (payload) =>
      dispatch({
        type: "FETCH_ZONES_REQUEST",
        value: payload,
      }),
    reduxPostZone: (payload) =>
      dispatch({
        type: "CREATE_ZONE_REQUEST",
        value: payload,
      }),
    reduxGetZone: (payload) =>
      dispatch({
        type: "READ_ZONE_REQUEST",
        value: payload,
      }),
    reduxDeleteZone: (payload) =>
      dispatch({
        type: "DELETE_ZONE_REQUEST",
        value: payload,
      }),
    reduxPatchZone: (payload) =>
      dispatch({
        type: "UPDATE_ZONE_REQUEST",
        value: payload,
      }),
    reduxResetZoneForm: () =>
      dispatch({
        type: "RESET_ZONE_FORM",
      }),
    reduxUploadFile: (payload) =>
      dispatch({
        type: "UPLOAD_ZONE_REQUEST",
        value: payload,
      }),
    reduxGetCities: (payload) =>
      dispatch({
        type: "FETCH_CITIES_REQUEST",
        value: payload,
      }),
    reduxGetZoneAudits: (payload) =>
      dispatch({
        type: "FETCH_ZONE_AUDITS_BYZONE_REQUEST",
        value: payload,
      }),
    reduxPathReverseZone: (payload) =>
      dispatch({
        type: "REVERSE_ZONE_REQUEST",
        value: payload,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Zone);
