import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { Button, Form, Modal, Row, Table } from "react-bootstrap";
import Select from 'react-select'
import Swal from "sweetalert2";
import "react-datepicker/dist/react-datepicker.css";

import { Show } from "../../hooks/Show";

import Sidebar from "../../components/Navigation/Sidebar";
import Topbar from "../../components/Navigation/Topbar";
import Grid from "../../components/Grid";
import GoogleMapsAutocomplete from "../../components/GoogleMapsAutocomplete";

import { isNullOrUndefined } from "../../utils/functionsUtils";
import { hubTypeEnum } from "../../utils/enumsUtil";

const Branch = ({
  reduxGetBranches,
  branches,
  reduxGetCountries,
  countries,
  reduxSetDepartment,
  reduxSetCity,
  reduxGetDepartmentsByCountry,
  departmentsByCountry,
  citiesByDepartment,
  reduxGetCitiesByDepartment,
  rowEdited,
  reduxResetBranchForm,
  reduxPostBranch,
  reduxPatchBranch,
  reduxGetBranch,
  reduxDeleteBranch,
  reduxFetchAvailableDSPOperators,
  availableDSP,
  reduxFetchAvailableWarehouses,
  warehousesForHubs,
  reduxFetchAllBranches,
  allBranches,
  successfulCreateBranch,
  errorsCreateBranch,
  successfulUpdateBranch,
  errorsUpdateBranch,
  upsertResult,
}) => {
  const methods = useForm({
    defaultValues: {
      parent: null,
      type: hubTypeEnum.REGIONAL,
      city: "",
      neighborhood: "",
      country: "",
      department: "",
      dspOperator: null,
      hubWarehouses: [],
      warehouse: null,
    },
  });
  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
    clearErrors,
    setValue,
    getValues,
  } = methods;

  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(10);
  const [show, setShow] = useState(false);
  const [valueManualAddress, setValueManualAddress] = useState("");
  const [search, setSearch] = useState("");

  const [disabledAddHubWarehouses, setDisabledAddHubWarehouses] = useState(true);

  const [hubWarehousesMap, setHubWarehousesMap] = useState([]);
  const [availableDSPMap, setAvailableDSPMap] = useState(null);

  const [currentCity, setCurrentCity] = useState("");
  const [currentCountry, setCurrentCountry] = useState("");
  const [currentDepartment, setCurrentDepartment] = useState("");

  const country = useRef({});
  country.current = watch("idCountry", "");

  const department = useRef({});
  department.current = watch("idDepartment", "");

  const city = useRef({});
  city.current = watch("idCity", "");

  const warehouseWatcher = watch("warehouse", null);
  const hubTypeWatcher = watch("type", hubTypeEnum.REGIONAL);

  useEffect(() => {
    reduxGetBranches({
      page: 1,
      offset: offset,
      search: "",
    });
    reduxGetCountries({
      page: 1,
      offset: 100000,
      search: "",
    });
  }, []);

  useEffect(() => {
    let filters = {
      page: currentPage,
      offset: offset,
      search: search,
    };
    reduxGetBranches(filters);
  }, [currentPage]);

  useEffect(() => {
    setCurrentCountry({ label: 'Seleccionar', value: '' })
    setCurrentDepartment({ label: 'Seleccionar', value: '' })
    setCurrentCity({ label: 'Seleccionar', value: '' })

    if (country.current !== '' && country.current !== undefined && country.current !== null) {
      const selected = countries && Object.keys(countries).length > 0 && countries.items.find((e) => e.idCountry === Number(country.current))
      setCurrentCountry({ label: selected.description, value: selected.idCountry })
      
      if ((Array.isArray(availableDSP) || availableDSP.length > 0) && country.current !== null){

        const filteredDSP = availableDSP.filter(e => e.isActive === true && e.country == country.current);
      
        if (filteredDSP.length > 0) setAvailableDSPMap(filteredDSP);

      }

      reduxGetDepartmentsByCountry({
        idCountry: country.current
      })
    } else {
      setCurrentCountry({ label: 'Seleccionar', value: '' })
      setValue("dspOperator", null)
    }
  }, [country.current]);

  useEffect(() => {
    setCurrentDepartment({ label: 'Seleccionar', value: '' })
    setCurrentCity({ label: 'Seleccionar', value: '' })

    if (department.current !== '' && department.current !== undefined && department.current !== null) {
      const selected = departmentsByCountry && Object.keys(departmentsByCountry).length > 0 && departmentsByCountry.find((e) => e.idDepartment === Number(department.current))
      setCurrentDepartment(selected ? { label: selected.description, value: selected.idDepartment } : { label: 'Seleccionar', value: '' })

      selected && reduxGetCitiesByDepartment({
        idDepartment: department.current
      })
    } else {
      setCurrentDepartment({ label: 'Seleccionar', value: '' })
    }
  }, [department.current, departmentsByCountry]);

  useEffect(() => {
    setCurrentCity({ label: 'Seleccionar', value: '' })

    if (city.current !== '' && city.current !== undefined && city.current !== null) {
      const selected = citiesByDepartment && Object.keys(citiesByDepartment).length > 0 && citiesByDepartment.find((e) => e.idCity === Number(city.current))
      setCurrentCity(selected ? { label: selected.description, value: selected.idCity } : { label: 'Seleccionar', value: '' })
    } else {
      setCurrentCity({ label: 'Seleccionar', value: '' })
    }
  }, [city.current, citiesByDepartment]);

  useEffect(() => {
    if (successfulCreateBranch || successfulUpdateBranch) {
      Swal.fire(
        "Proceso Exitoso",
        `${upsertResult.message}`,
        "success"
      );
      handleClose();
    }
    if (errorsCreateBranch || errorsUpdateBranch) {
      Swal.fire(
        "Proceso Fallido",
        `${upsertResult.message}`,
        "error"
      );
    }
  }, [successfulCreateBranch, successfulUpdateBranch, errorsCreateBranch, errorsUpdateBranch, upsertResult])

  useEffect(()=> {
    setAvailableDSPMap(availableDSP);

    if(rowEdited){
      if(!isNullOrUndefined(rowEdited.dspOperator)) setAvailableDSPMap([rowEdited.dspOperator, ...availableDSP])
    }
  }, [availableDSP, rowEdited])

  useEffect(() => {
    if (rowEdited) {
      setShow(true);
      setValue("branchName", rowEdited.branchName);
      setValueManualAddress(rowEdited.address);
      setValue("manualAddress", rowEdited.address);
      setValue("idCountry", rowEdited.idCountry);
      setValue("idDepartment", rowEdited.idDepartment);
      setValue("idCity", rowEdited.idCity);
      setValue("postalCode", rowEdited.postalCode);
      setValue("branchCode", rowEdited.branchCode);
      setValue("type", rowEdited.type);
      setValue("dspOperator", rowEdited.dspOperator && { value: rowEdited.dspOperator.idCompany, label: rowEdited.dspOperator.description });
      setValue("parent", rowEdited.parent && { value: rowEdited.parent.idBranch, label: rowEdited.parent.branchName });
      setHubWarehousesMap(rowEdited.hubWarehouses && rowEdited.hubWarehouses.map(e => ({label: e.warehouse.name || e.warehouse.warehouseCode, value: e.idWarehouse})))
    }
  }, [rowEdited]);

  useEffect(() => {
    if(hubWarehousesMap.length === 0) {
      setDisabledAddHubWarehouses(false);
    }
    else{
      clearErrors("hubWarehouses")
      setDisabledAddHubWarehouses(true);
    }
  }, [hubWarehousesMap, warehouseWatcher])

  useEffect(() => {
    if(hubTypeWatcher.value === 1) setValue("parent", null)    
  }, [hubTypeWatcher])

  const handleDelete = (row) => {
    reduxDeleteBranch(row);
  };

  const handleActive = (row) => {
    if (!row.isActive) {
      reduxDeleteBranch(row);
    }
  };

  const handleOpenModal = (branchData) => {
    resetModalForm();
    reduxFetchAvailableDSPOperators();
    reduxFetchAvailableWarehouses();
    reduxFetchAllBranches();

    if(branchData) reduxGetBranch({id: branchData.idBranch});

    setShow(true);
  };

  const resetModalForm = () => {
    reset();
    setValueManualAddress("");
    reduxResetBranchForm();
    setAvailableDSPMap(null);
    setHubWarehousesMap([])
  }

  const onSubmit = (data) => {
    if (rowEdited) {
      reduxPatchBranch({
        idBranch: rowEdited.idBranch,
        branchName: data.branchName,
        address: getValues("manualAddress"),
        postalCode: data.postalCode,
        idCountry: Number(data.idCountry),
        idDepartment: Number(data.idDepartment),
        idCity: Number(data.idCity),
        branchCode: data.branchCode,
        dspOperator: data.dspOperator?.value,
        hubWarehouses: hubWarehousesMap.map(e => e.value),
        idParent: data.parent?.value,
        type: data.type,
      });
    } else {
      reduxPostBranch({
        branchName: data.branchName,
        address: getValues("manualAddress"),
        postalCode: data.postalCode,
        idCountry: Number(data.idCountry),
        idDepartment: Number(data.idDepartment),
        idCity: Number(data.idCity),
        branchCode: data.branchCode,
        dspOperator: data.dspOperator?.value,
        hubWarehouses: hubWarehousesMap.map(e => e.value),
        idParent: data.parent?.value,
        type: data.type,
      });
    }
  };

  const handleClose = () => {
    reduxGetBranches({
      page: currentPage,
      offset: offset,
      search: search,
    });
    resetModalForm();
    setShow(false);
  };

  const addWarehouse = () => {
    const warehouseSelected = getValues("warehouse");
    if(hubWarehousesMap.length > 0){
      setHubWarehousesMap([...hubWarehousesMap, warehouseSelected]);
    }else{
      setHubWarehousesMap([warehouseSelected]);
    }
    setValue('warehouse', null);
  };

  const handleRemoveWarehouse = (element) => {
    Swal.fire({
      html: `¿Está seguro de eliminar esta bodega?`,
      icon: 'question',
      showConfirmButton: true,
      confirmButtonText: "Aceptar",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
    }).then(result => {
      if(result.isConfirmed){
        const newOperatorsArray = hubWarehousesMap.filter((item) => item !== element);
        setHubWarehousesMap(newOperatorsArray)
      }
    })
  }

  const columns = [
    {
      title: "#",
      render: (rowData) => {
        return <span>{rowData.idBranch}</span>;
      },
      field: "idBranch",
      searchable: true,
    },
    {
      title: "Nombre",
      render: (rowData) => {
        return <span>{rowData.branchName}</span>;
      },
      field: "branchName",
      searchable: true,
    },
    {
      title: "Dirección",
      render: (rowData) => {
        return <span>{rowData.address}</span>;
      },
      field: "address",
      searchable: false,
    },
    {
      title: "Ciudad de origen",
      render: (rowData) => {
        return <span>{rowData.city.description}</span>;
      },
      field: "city.description",
      searchable: true,
    },
    {
      title: "Tipo",
      render: (rowData) => {
        return <span>{rowData.type}</span>;
      },
      field: "type",
      searchable: true,
    },
    {
      title: "Código HUB",
      render: (rowData) => {
        return <span>{rowData.branchCode}</span>;
      },
      field: "branchCode",
      searchable: true,
    },
    {
      title: "DSP",
      render: (rowData) => {
        return <span>{rowData.dspOperator?.description}</span>;
      },
      field: "dspOperator.description",
      searchable: true,
    },
    {
      title: "Bodega",
      render: (rowData) => {
        return <span>{rowData.hubWarehouses[0]?.warehouse.name || rowData.hubWarehouses[0]?.warehouse.warehouseCode}</span>;
      },
    },
    {
      title: "Opciones",
      render: (rowData) => {
        return (
          <>
            {rowData.isActive ? (
              <>
                <Show when="feature:edit-branch">
                  <button
                    title="Editar"
                    className="btn btn-primary btn-sm  btn-circle mr-2"
                    type="button"
                    onClick={(e) => handleOpenModal(rowData)}
                  >
                    <i className="fas fa-edit fa-xs"></i>
                  </button>
                </Show>
                <Show when="feature:disabled-branch">
                  <button
                    title="Desactivar"
                    className="btn btn-danger btn-sm btn-circle mr-2"
                    type="button"
                    onClick={(e) => handleDelete(rowData)}
                  >
                    <i className="fas fa-times-circle fa-xs"></i>
                  </button>
                </Show>
              </>
            ) : (
              <Show when="feature:enabled-branch">
                <button
                  title="Activar"
                  className="btn btn-primary btn-sm  btn-circle mr-2"
                  type="button"
                  onClick={(e) => handleActive(rowData)}
                >
                  <i className="fas fa-check-circle fa-xs"></i>
                </button>
              </Show>
            )}
          </>
        );
      },
    },
  ];

  return (
    <div>
      <div id="wrapper">
        <Sidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Topbar />
            <div className="container-fluid">
              <div className="d-sm-flex align-items-center mb-1">
                <h1 className="h3 mb-0 text-gray-800 mr-3">HUBs</h1>
                <Show when="feature:create-branch">
                  <button
                    className="btn btn-primary btn-circle"
                    type="button"
                    onClick={() => handleOpenModal()}
                  >
                    <i className="fas fa-plus fa-sm"></i>
                  </button>
                </Show>
              </div>
              <p className="mb-4">Módulo de Administración de HUBs</p>
              <div className="card shadow mb-4">
                <div className="card-header py-3">
                  <h6 className="m-0 font-weight-bold text-primary">
                    Listado de HUBs
                  </h6>
                </div>
                <div className="card-body">
                  <Grid
                    cols={columns}
                    data={
                      branches && Object.keys(branches).length > 0
                        ? branches.items
                        : []
                    }
                    page={
                      branches && Object.keys(branches).length > 0
                        ? Number(branches.page)
                        : currentPage
                    }
                    pages={
                      branches && Object.keys(branches).length > 0
                        ? Number(branches.totalPages)
                        : 0
                    }
                    total={
                      branches && branches.hasOwnProperty("total")
                        ? branches.total
                        : 0
                    }
                    offset={offset}
                    onChangePage={(page) => setCurrentPage(page)}
                    onChangeRange={(value) => {
                      setOffset(value);
                      let filters = {
                        page: 1,
                        offset: value,
                        search: search,
                      };
                      reduxGetBranches(filters);
                    }}
                    defaultValue={search}
                    onChangeSearch={(value) => {
                      setSearch(value);
                      let filters = {
                        page: 1,
                        offset: offset,
                        search: value,
                      };
                      reduxGetBranches(filters);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Modal
          show={show}
          onHide={() => handleClose()}
          onEscapeKeyDown={() => handleClose()}
          backdrop="static"
          keyboard={true}
          size="lg"
        >
          <FormProvider {...methods}>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Modal.Header closeButton>
                <Modal.Title>
                  {rowEdited ? "Editar" : "Crear"} HUB
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <div className="col s12 m12">
                    <div className="form-group">
                      <label htmlFor="branchName" className="form-label">
                        Nombre *
                      </label>
                      <input
                        id="branchName"
                        type="text"
                        className={`form-control form-control-user ${errors.branchName && "is-invalid"
                          }`}
                        {...register("branchName", { required: true })}
                      />
                      {errors.branchName && (
                        <span className="invalid-feedback">
                          El nombre es requerido
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col s12 m6">
                    <div className="form-group">
                      <label htmlFor="type" className="form-label">
                        Tipo
                      </label>
                      <Controller
                        control={control}
                        name="type"
                        render={({ field: { onChange, onBlur, value, name, ref } }) => (
                          <>
                            <Select
                              inputRef={ref}
                              name={name}
                              value={hubTypesOption.find(option => option.value === value)}
                              onChange={(selected) => onChange(selected.value)}
                              options={hubTypesOption}
                              onBlur={onBlur}
                            />
                          </>
                        )}
                      />
                    </div>
                  </div>
                </Row>
                <Row>
                  <div className="col s12 m12">
                    <GoogleMapsAutocomplete
                      id={"manualAddress"}
                      required={true}
                      value={valueManualAddress}
                      onChange={(data) => {
                        setValueManualAddress(
                          data.address_components
                            .map((ad) => {
                              return ad.long_name;
                            })
                            .join(", ")
                        );
                        data.address_components
                          ? setValue("manual", data.address_components)
                          : setValue("manual", data);
                        setValue("idCountry", "")
                        setValue("idDepartment", "");
                        setValue("idCity", "");
                        reduxSetDepartment();
                        reduxSetCity();
                        const x = data.address_components.filter((ad) => {
                          if (ad.types[0] === "country") return ad;
                        });
                        if (x.length > 0) {
                          const countrySelected = countries.items.filter(
                            (ele) =>
                              ele.description ==
                              String(x[0].long_name)
                                .replace("á", "a")
                                .replace("é", "e")
                                .replace("í", "i")
                                .replace("ó", "o")
                                .replace("ú", "u")
                                .toUpperCase()
                          );
                          if (countrySelected.length > 0) {
                            setValue("idCountry", countrySelected[0].idCountry);
                            reduxGetDepartmentsByCountry({
                              idCountry: countrySelected[0].idCountry,
                            });
                          }
                        }
                      }}
                    />
                  </div>
                </Row>
                <Row>
                  <div className="form-group col-md-4">
                    <label htmlFor="idCountry" className="form-label">
                      Pais *
                    </label>
                    <Select
                      isClearable
                      {...register("idCountry", { required: true })}
                      value={currentCountry}
                      id='country'
                      onChange={(value)=> setValue('idCountry', value?.value || '')}
                      options={
                        countries && Object.keys(countries).length > 0
                        ? countries.items
                          .filter((f) => f.isActive)
                          .map((ele, key) => ({
                            value: ele.idCountry,
                            label: ele.description,
                          }))
                        : []
                      }
                    />
                    {getValues("manualAddress") != "" &&
                      errors.idCountry &&
                      getValues("idCountry") == "" && (
                        <span className="invalid-feedback">
                          El pais es requerido
                        </span>
                      )}
                  </div>
                  <div className="form-group col-md-4">
                    <label htmlFor="idDepartment" className="form-label">
                      Departamento *
                    </label>
                    <Select
                      isClearable
                      {...register("idDepartment", { required: true })}
                      value={currentDepartment}
                      id='department'
                      onChange={(value)=> setValue('idDepartment', value?.value || '')}
                      options={
                        departmentsByCountry && Object.keys(departmentsByCountry).length > 0
                        ? departmentsByCountry
                          .filter((f) => f.isActive)
                          .map((ele, key) => ({
                            value: ele.idDepartment,
                            label: ele.description,
                          }))
                        : []
                      }
                    />
                    {getValues("manualAddress") != "" &&
                      (errors.idDepartment ||
                        errors.idCountry ||
                        errors.idCity) && (
                        <span className="invalid-feedback">
                          El departamento es requerido
                        </span>
                      )}
                  </div>
                  <div className="form-group col-md-4">
                    <label htmlFor="idCity" className="form-label">
                      Ciudad *
                    </label>
                    <Select
                      isClearable
                      {...register("idCity", { required: true })}
                      id='city'
                      value={currentCity}
                      onChange={(value)=> setValue('idCity', value?.value || '')}
                      options={
                        citiesByDepartment && Object.keys(citiesByDepartment).length > 0
                        ? citiesByDepartment
                          .filter((f) => f.isActive)
                          .map((ele, key) => ({
                            value: ele.idCity,
                            label: ele.description,
                          }))
                        : []
                      }
                    />
                    {getValues("manualAddress") != "" &&
                      (errors.idDepartment ||
                        errors.idCountry ||
                        errors.idCity) && (
                        <span className="invalid-feedback">
                          La ciudad es requerida
                        </span>
                      )}
                  </div>
                </Row>
                <Row>
                  <div className="col s12 m6">
                    <div className="form-group">
                      <label htmlFor="postalCode" className="form-label">
                        Código postal *
                      </label>
                      <input
                        id="postalCode"
                        type="number"
                        className={`form-control form-control-user ${
                          errors.postalCode && "is-invalid"
                        }`}
                        {...register("postalCode", {
                          required: true,
                        })}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                      {errors.postalCode && (
                        <span className="invalid-feedback">
                          El código postal es requerido
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col s12 m6">
                    <div className="form-group">
                      <label htmlFor="branchCode" className="form-label">
                        Código HUB *
                      </label>
                      <input
                        id="branchCode"
                        type="text"
                        className={`form-control form-control-user ${
                          errors.branchCode && "is-invalid"
                        }`}
                        {...register("branchCode", { required: true })}
                      />
                      {errors.branchCode && (
                        <span className="invalid-feedback">
                          El código HUB es requerido
                        </span>
                      )}
                    </div>
                  </div>
                </Row>
                {
                  hubTypeWatcher === hubTypeEnum.LOCAL &&
                  <Row>
                    <div className="col s12 m6">
                      <div className="form-group">
                        <label htmlFor="parent" className="form-label">
                          Hub Regional
                        </label>
                        <Controller
                          control={control}
                          name="parent"
                          rules={{
                            required: {
                              value: hubTypeWatcher === hubTypeEnum.LOCAL,
                              message: 'El HUB regional es requerido'
                            }
                          }}
                          render={({ field }) => (
                            <>
                              <Select
                                {...field}
                                isClearable
                                options={
                                  allBranches && Object.keys(allBranches).length > 0
                                    ? allBranches
                                      .filter((f) => f.isActive && f.type === hubTypeEnum.REGIONAL)
                                      .map((ele) => ({
                                        value: ele.idBranch,
                                        label: ele.branchName,
                                      }))
                                    : []
                                }
                              />
                              {errors.parent && (
                                <span className="error-message">
                                  {errors.parent.message}
                                </span>
                              )}
                            </>
                          )}
                        />
                      </div>
                    </div>
                  </Row>
                }
                <div className="row">
                  <div className="col s12 m6">
                    <div className="form-group">
                      <label htmlFor="dspOperator" className="form-label">
                        DSP
                      </label>
                      <Controller
                          control={control}
                          name="dspOperator"
                          render={({ field }) => (
                            <>
                              <Select
                                {...field}
                                isClearable
                                isDisabled={isNullOrUndefined(country.current)}
                                options={
                                  availableDSPMap && Object.keys(availableDSPMap).length > 0
                                    ? availableDSPMap
                                      .filter((f) => f.isActive && f.country == country.current)
                                      .map((ele) => ({
                                        value: ele.idCompany,
                                        label: ele.description,
                                      }))
                                    : []
                                }
                              />
                            </>
                          )}
                        />
                    </div>
                  </div>
                  <div className="col s12 m6">
                    <div className="form-group">
                      <label htmlFor="warehouse" className="form-label">
                        Agregar Bodega *
                      </label>
                      <div className="d-flex justify-content-between flex-nowrap">
                        <Select
                          {...register("warehouse")}
                          className="w-100"
                          isClearable
                          value={warehouseWatcher}
                          isDisabled={disabledAddHubWarehouses}
                          onChange={(selected) => setValue('warehouse', selected)}
                          options={
                            warehousesForHubs && Object.keys(warehousesForHubs).length > 0
                              ? warehousesForHubs
                                .filter(ele => hubWarehousesMap && !hubWarehousesMap.some(item => item.label === ele.name || item.label === ele.warehouseCode))
                                .map((ele) => ({
                                  value: ele.idWarehouse,
                                  label: ele.name || ele.warehouseCode,
                                }))
                              : []
                          }
                        />
                        <button
                          className='btn btn-primary ml-1'
                          type="button"
                          onClick={addWarehouse}
                          disabled={disabledAddHubWarehouses}>
                          Agregar
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <Table bordered className="w-50">
                    <thead>
                      <tr>
                          <th className="text-bold text-center" colSpan="6">Bodegas</th>
                        </tr>
                      </thead>
                      <tbody className="d-block overflow-y-auto" style={{maxHeight: '20vh'}}>
                        <Controller
                          control={control}
                          name="hubWarehouses"
                          rules={{
                            required: {
                              value: hubWarehousesMap.length === 0,
                              message: 'La bodega es requerida'
                            }
                          }}
                          render={() => (
                            <>
                              {
                                hubWarehousesMap.map((item, key) => (
                                  <tr key={key}>
                                    <td className="text-center">{item.label}</td>
                                    <td className="col-1">
                                      {
                                        <>
                                          <button
                                            title="Eliminar"
                                            className='btn btn-danger btn-sm btn-circle'
                                            type="button"
                                            onClick={() => handleRemoveWarehouse(item)}>
                                            <i className="fas fa-trash fa-xs"></i>
                                          </button>
                                        </>
                                      }
                                    </td>
                                  </tr>
                                ))
                            }
                            {
                              errors.hubWarehouses &&
                              <tr className="text-center error-message m-auto">
                                {errors.hubWarehouses.message}
                              </tr>
                            }
                          </>
                        )}
                      />
                    </tbody>
                  </Table>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => handleClose()}>
                  Cancelar
                </Button>
                <Button variant="primary" type="submit">
                  Guardar
                </Button>
              </Modal.Footer>
            </Form>
          </FormProvider>
        </Modal>
      </div>
    </div>
  );
};

const hubTypesOption = [
  { value: hubTypeEnum.REGIONAL, label: "Regional" },
  { value: hubTypeEnum.LOCAL, label: "Local" }
];

const mapStateToProps = (state) => {
  return {
    branches: state.branchState.branches,
    allBranches: state.branchState.allBranches,
    rowEdited: state.branchState.rowEdited,
    rowDeleted: state.branchState.rowDeleted,
    cities: state.cityState.cities,
    countries: state.countryState.countries,
    departmentsByCountry: state.departmentState.departmentsByCountry,
    citiesByDepartment: state.cityState.citiesByDepartment,
    availableDSP: state.branchState.availableDSP,
    warehousesForHubs: state.warehouseState.warehousesForHubs,
    successfulCreateBranch: state.branchState.successfulCreateBranch,
    errorsCreateBranch: state.branchState.errorsCreateBranch,
    successfulUpdateBranch: state.branchState.successfulUpdateBranch,
    errorsUpdateBranch: state.branchState.errorsUpdateBranch,
    upsertResult: state.branchState.upsertResult,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    reduxGetBranches: (payload) =>
      dispatch({
        type: "FETCH_BRANCHES_REQUEST",
        value: payload,
      }),
    reduxGetBranch: (payload) =>
      dispatch({
        type: "READ_BRANCH_REQUEST",
        value: payload,
      }),
    reduxPostBranch: (payload) =>
      dispatch({
        type: "CREATE_BRANCH_REQUEST",
        value: payload,
      }),
    reduxDeleteBranch: (payload) =>
      dispatch({
        type: "DELETE_BRANCH_REQUEST",
        value: payload,
      }),
    reduxResetBranchForm: () =>
      dispatch({
        type: "RESET_BRANCH_FORM",
      }),
    reduxPatchBranch: (payload) =>
      dispatch({
        type: "UPDATE_BRANCH_REQUEST",
        value: payload,
      }),
    reduxGetCities: (payload) =>
      dispatch({
        type: "FETCH_CITIES_REQUEST",
        value: payload,
      }),
    reduxGetCountries: (payload) =>
      dispatch({
        type: "FETCH_COUNTRIES_REQUEST",
        value: payload,
      }),
    reduxGetDepartmentsByCountry: (payload) =>
      dispatch({
        type: "READBYCOUNTRY_DEPARTMENT_REQUEST",
        value: payload,
      }),
    reduxGetCitiesByDepartment: (payload) =>
      dispatch({
        type: "READBYDEPARTMENT_CITY_REQUEST",
        value: payload,
      }),
    reduxSetDepartment: (payload) =>
      dispatch({
        type: "RESET_BYCOUNTRY_DEPARTMENT",
        value: payload,
      }),
    reduxSetCity: (payload) =>
      dispatch({
        type: "RESET_BYDEPARTMENT_CITY",
        value: payload,
      }),
    reduxFetchAvailableDSPOperators: () =>
      dispatch({
        type: "FETCH_AVAILABLE_DSP_REQUEST"
      }),
    reduxFetchAvailableWarehouses: () =>
      dispatch({
        type: "FETCH_WAREHOUSES_FORHUBS_REQUEST"
      }),
    reduxFetchAllBranches: () =>
      dispatch({
        type: "FETCH_ALL_BRANCHES_REQUEST"
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Branch);
