const initialState = {
  requestingFetchProducts: false,
  successfulFetchProducts: false,
  errorFetchProducts: false,
  products: {},
  requestingCreateProduct: false,
  successfulCreateProduct: false,
  errorsCreateProduct: false,
  product: null,
  requestingReadProduct: false,
  successfulReadProduct: false,
  errorsReadProduct: false,
  rowEdited: null,
  requestingDeleteProduct: false,
  successfulDeleteProduct: false,
  errorsDeleteProduct: false,
  rowDeleted: null,
  requestingUpdateProduct: false,
  successfulUpdateProduct: false,
  errorsUpdateProduct: false,
  rowUpdated: null,
  requestingDeleteSoftProduct: false,
  successfulDeleteSoftProduct: false,
  errorsDeleteSoftProduct: false,
  rowDeletedSoft: null,
};

const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_PRODUCTS_REQUESTING":
      return {
        ...state,
        requestingFetchProducts: true,
        successfulFetchProducts: false,
        errorsFetchProducts: false,
      };
    case "FETCH_PRODUCT_SUCCESS":
      return {
        ...state,
        errorFetchProducts: false,
        requestingFetchProducts: false,
        successfulFetchProducts: true,
        products: action.value,
      };
    case "FETCH_PRODUCT_ERROR":
      return {
        ...state,
        errorFetchProducts: true,
        requestingFetchProducts: false,
        successfulFetchProducts: false,
      };
    case "CREATE_PRODUCT_REQUESTING":
      return {
        ...state,
        requestingCreateProduct: true,
        successfulCreateProduct: false,
        errorsCreateProduct: false,
      };
    case "CREATE_PRODUCT_SUCCESS":
      return {
        ...state,
        errorsCreateProduct: false,
        requestingCreateProduct: false,
        successfulCreateProduct: true,
        product: action.value,
      };
    case "CREATE_PRODUCT_ERROR":
      return {
        ...state,
        errorsCreateProduct: true,
        requestingCreateProduct: false,
        successfulCreateProduct: false,
      };
    case "READ_PRODUCT_REQUESTING":
      return {
        ...state,
        requestingReadProduct: true,
        successfulReadProduct: false,
        errorsReadProduct: false,
      };
    case "READ_PRODUCT_SUCCESS":
      return {
        ...state,
        errorsReadProduct: false,
        requestingReadProduct: false,
        successfulReadProduct: true,
        rowEdited: action.value,
      };
    case "READ_PRODUCT_ERROR":
      return {
        ...state,
        errorsReadProduct: true,
        requestingReadProduct: false,
        successfulReadProduct: false,
      };
    case "DELETE_PRODUCT_REQUESTING":
      return {
        ...state,
        requestingDeleteProduct: true,
        successfulDeleteProduct: false,
        errorsDeleteProduct: false,
      };
    case "DELETE_PRODUCT_SUCCESS":
      return {
        ...state,
        errorsDeleteProduct: false,
        requestingDeleteProduct: false,
        successfulDeleteProduct: true,
        rowDeleted: action.value,
      };
    case "DELETE_PRODUCT_ERROR":
      return {
        ...state,
        errorsDeleteProduct: true,
        requestingDeleteProduct: false,
        successfulDeleteProduct: false,
      };
    case "UPDATE_PRODUCT_REQUESTING":
      return {
        ...state,
        requestingUpdateProduct: true,
        successfulUpdateProduct: false,
        errorsUpdateProduct: false,
      };
    case "UPDATE_PRODUCT_SUCCESS":
      return {
        ...state,
        errorsUpdateProduct: false,
        requestingUpdateProduct: false,
        successfulUpdateProduct: true,
        rowUpdated: action.value,
      };
    case "UPDATE_PRODUCT_ERROR":
      return {
        ...state,
        errorsUpdateProduct: true,
        requestingUpdateProduct: false,
        successfulUpdateProduct: false,
      };
    case "DELETE_SOFT_PRODUCT_REQUESTING":
      return {
        ...state,
        requestingDeleteSoftProduct: true,
        successfulDeleteSoftProduct: false,
        errorsDeleteSoftProduct: false,
      };
    case "DELETE_SOFT_PRODUCT_SUCCESS":
      return {
        ...state,
        errorsDeleteSoftProduct: false,
        requestingDeleteSoftProduct: false,
        successfulDeleteSoftProduct: true,
        rowDeletedSoft: action.value,
      };
    case "DELETE_SOFT_PRODUCT_ERROR":
      return {
        ...state,
        errorsDeleteSoftProduct: true,
        requestingDeleteSoftProduct: false,
        successfulDeleteSoftProduct: false,
      };
    case "RESET_PRODUCT_FORM":
      return {
        ...state,
        requestingCreateProduct: false,
        successfulCreateProduct: false,
        errorsCreateProduct: false,
        product: null,
        requestingReadProduct: false,
        successfulReadProduct: false,
        errorsReadProduct: false,
        rowEdited: null,
        requestingDeleteProduct: false,
        successfulDeleteProduct: false,
        rowDeleted: null,
        requestingUpdateProduct: false,
        successfulUpdateProduct: false,
        errorsUpdateProduct: false,
        rowUpdated: null,
        requestingDeleteSoftProduct: false,
        successfulDeleteSoftProduct: false,
        errorsDeleteSoftProduct: false,
        rowDeletedSoft: null,
      };
    case "RESET":
      return initialState;
    default:
      return state;
  }
};

export default productReducer;
