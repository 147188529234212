import React, {useContext} from "react";
import {PermissionContext} from "./PermissionContext";

export const Show = ({ when = "", whenNot = [], whenMultiple = [], fallback, children, ...rest }) => {
  const { hasPermission } = useContext(
    PermissionContext
  );

  let show = true;

  if(when.length > 0){
    show = hasPermission(when);
  }

  if(whenNot.length > 0){
    whenNot.forEach(element => {
      if(hasPermission(element)){
        show = false;
        return;
      }
    });
  }

  if (whenMultiple.length > 0) {
    whenMultiple.forEach(element => {
      if(!hasPermission(element)){
        show = false;
        return;
      }
    });
  }

  if (show) {
    return React.Children.map(children, child =>
      React.cloneElement(child, rest)
    );
  }

  return fallback || null;
};