import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import Sidebar from "../../../components/Navigation/Sidebar";
import Topbar from "../../../components/Navigation/Topbar";
import Grid from "../../../components/Grid";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import { Show } from "../../../hooks/Show";
import { LabelByState } from "../../../utils/functionsUtils";

const ColectsReport = ({
  cities,
  reduxGetCities,
  reduxGetCompanies,
  companies,
  reduxGetCustomers,
  customers,
  collectsReports,
  reduxGetCollectsReports,
}) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
    setValue,
    getValues,
  } = useForm();

  const onSubmit = (data) => {
    reduxGetCollectsReports({
      ...data,
      page: 1,
      search: "",
      offset: 1000,
    });
  };

  useEffect(() => {
    reduxGetCities({
      page: 1,
      search: "",
      offset: 100000,
    });
    reduxGetCompanies({
      page: 1,
      search: "",
      offset: 1000,
    });
    reduxGetCustomers({
      page: 1,
      search: "",
      offset: 1000,
    });
    reduxGetCollectsReports({
      page: 1,
      search: "",
      offset: 1000,
    });
  }, []);

  const columns = [
    {
      title: "# dirección",
      render: (rowData) => {
        return <span>{rowData.idAddress}</span>;
      },
      field: "idAddress",
      searchable: true,
    },
    {
      title: "Guía",
      render: (rowData) => {
        return <span>{rowData.trackingId}</span>;
      },
    },
    {
      title: "Nombre",
      render: (rowData) => {
        return <span>{rowData.name}</span>;
      },
    },
    {
      title: "Dirección",
      render: (rowData) => {
        return <span>{rowData.address}</span>;
      },
    },
    {
      title: "Referencia 1",
      render: (rowData) => {
        return (
          <Show when="feature:show-reference1-address">
            <span>{rowData.reference1}</span>
          </Show>
        );
      },
    },
    {
      title: "Referencia 2",
      render: (rowData) => {
        return <span>{rowData.reference2}</span>;
      },
    },
    {
      title: "Guía del cliente",
      render: (rowData) => {
        return <span>{rowData.clientTrackingId}</span>;
      },
    },
    {
      title: "Valor a recaudar",
      render: (rowData) => {
        return <span>{rowData.declaredValue}</span>;
      },
    },
    {
      title: "Estado",
      render: (rowData) => LabelByState(rowData.state),
    },
    {
      title: "Barrio",
      render: (rowData) => {
        return <span>{rowData.neighborhood}</span>;
      },
    },
    {
      title: "Zona",
      render: (rowData) => {
        return <span>{rowData.zone}</span>;
      },
    },
    {
      title: "Observación ruta",
      render: (rowData) => {
        return <span>{rowData.routeObservation}</span>;
      },
    },
    {
      title: "Descripción observación ruta",
      render: (rowData) => {
        return <span>{rowData.detailObservation}</span>;
      },
    },
    {
      title: "Novedad ruta",
      render: (rowData) => {
        return <span>{rowData.record}</span>;
      },
    },
    {
      title: "Descripción novedad ruta",
      render: (rowData) => {
        return <span>{rowData.descriptionRecord}</span>;
      },
    },
  ];

  return (
    <div>
      <div id="wrapper">
        <Sidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Topbar />
            <div className="container-fluid">
              <div className="d-sm-flex align-items-center mb-1">
                <h1 className="h3 mb-0 text-gray-800 mr-3">
                  Reporte de Recaudos
                </h1>
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="col s1 m1">
                    <div className="form-group">
                      <label htmlFor="idCustomer" className="form-label">
                        Cliente
                      </label>
                      <select
                        {...register("idCustomer")}
                        id="idCustomer"
                        className={`custom-select`}
                      >
                        <option value={""}>Seleccionar…</option>
                        {customers &&
                          Object.keys(customers).length > 0 &&
                          customers.items.map((ele, key) => (
                            <option key={key} value={ele.idCustomer}>
                              {ele.firstName} {ele.lastName}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <div className="col s1 m1">
                    <div className="form-group">
                      <label htmlFor="idOperator" className="form-label">
                        Operador
                      </label>
                      <select
                        id="idOperator"
                        {...register("idOperator")}
                        className={`custom-select`}
                      >
                        <option value={""}>Seleccionar…</option>
                        {companies &&
                          Object.keys(companies).length > 0 &&
                          companies.items
                            .filter((f) => f.isActive == true)
                            .map((ele, key) => (
                              <option key={key} value={ele.idCompany}>
                                {ele.description}
                              </option>
                            ))}
                      </select>
                    </div>
                  </div>
                  <div className="col s3 m3">
                    <div className="form-group">
                      <label htmlFor="idCity" className="form-label">
                        Ciudad
                      </label>
                      <select
                        {...register("idCity")}
                        id="idCity"
                        className={`custom-select`}
                      >
                        <option value={""}>Seleccionar…</option>
                        {cities &&
                          Object.keys(cities).length > 0 &&
                          cities.items.map((ele, key) => (
                            <option key={key} value={ele.idCity}>
                              {ele.description}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <div className="col s12 m1">
                    <Button
                      variant="primary"
                      style={{ marginTop: "32px" }}
                      type="submit"
                      id="btnSearch"
                    >
                      Buscar
                    </Button>
                  </div>
                </div>
              </form>
              <div className="card shadow mb-4">
                <div className="card-body">
                  <Grid
                    cols={columns}
                    data={
                      collectsReports && Object.keys(collectsReports).length > 0
                        ? collectsReports
                        : []
                    }
                    total={
                      collectsReports && collectsReports.hasOwnProperty("total")
                        ? collectsReports.total
                        : 0
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    cities: state.cityState.cities,
    companies: state.companyState.companies,
    customers: state.customerState.customers,
    collectsReports: state.reportState.collectsReports,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    reduxGetCities: (payload) =>
      dispatch({
        type: "FETCH_CITIES_REQUEST",
        value: payload,
      }),
    reduxGetCompanies: (payload) =>
      dispatch({
        type: "FETCH_COMPANIES_REQUEST",
        value: payload,
      }),
    reduxGetCustomers: (payload) =>
      dispatch({
        type: "FETCH_CUSTOMERS_REQUEST",
        value: payload,
      }),
    reduxGetCollectsReports: (payload) =>
      dispatch({
        type: "FETCH_COLLECTSREPORTS_REQUEST",
        value: payload,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ColectsReport);
