import {call, put, takeLatest} from "redux-saga/effects";
import request from "../../utils/request";

function* refreshToken(data) {
  try {
    yield put({
      type: "REFRESH_TOKEN_REQUESTING",
    });

    const tokenRefresh = {token: data.payload};
    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/auth/refresh`;
    const headers = {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(tokenRefresh),
    }; 
    const response = yield call(request, requestURL, headers);
    const { payload } = response;
    
    if (payload.refreshToken.length >0) {
        
        const newRefreshToken = payload.refreshToken;
        localStorage.removeItem('refreshToken');
        localStorage.setItem('refreshToken', newRefreshToken);
        
        yield put({
          type: 'REFRESH_TOKEN_SUCCESS',
        });
    }
  } catch (error) {
     yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed Refresh",
        message: "Failed Refresh Token",
      },
    });
    yield put({
      type: "REFRESH_TOKEN_ERROR",
    });
  }
}

export function* watchAuth() {
    yield takeLatest("REFRESH_TOKEN", refreshToken);
  }