import React, { forwardRef, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import moment from "moment-timezone";

import Sidebar from "../../../../components/Navigation/Sidebar";
import Topbar from "../../../../components/Navigation/Topbar";
import Grid from "../../../../components/Grid";

const Swal = require("sweetalert2");

const ConfirmCollectionShipperReport = ({
  shipperConfirm,
  reduxGetShipperConfirmReport,
  reduxDownloadShipperConfirmReport,
  reduxResetShipperConfirmReportForm,
}) => {
  const {
    handleSubmit,
    reset,
    setValue,
    getValues,
  } = useForm();

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(10);
  const [search, setSearch] = useState("");
  const [isSearched, setIsSearched] = useState(false);

  useEffect(() => {
    reset();
    reduxResetShipperConfirmReportForm();
    setFromDate(null);
    setToDate(null);
  }, []);

  useEffect(() => {
    if (isSearched) {
      let filters = {
        page: currentPage,
        offset: offset,
        search: search,
      };
      if (getValues("from") && getValues("to")) {
        filters = {
          ...filters,
          from: moment(getValues("from"))
            .tz("America/Bogota")
            .format("MM-DD-YYYY"),
          to: moment(getValues("to")).tz("America/Bogota").format("MM-DD-YYYY"),
        };
      }
      reduxGetShipperConfirmReport(filters);
    }
  }, [currentPage, isSearched]);


  const downloadGeneral = () => {
    if (toDate < fromDate) {
      Swal.fire(
        "Información",
        "La fecha inicial debe ser  menor a la fecha final , intenta de nuevo",
        "info"
      );
      return;
    }
    reduxDownloadShipperConfirmReport({
      fileName: `collectionConfirmedReport_${moment(new Date())
          .tz("America/Bogota")
          .format("YYYYMMDD_hhmm")}.csv`,
      search: search ? search : "",
      from: getValues("from"),
      to: getValues("to"),
    })
  };

  const handleChangeDatePicker = (dates) => {
    const [start, end] = dates;

    setFromDate(start);
    setToDate(end);

    setValue("from", moment(start).tz("America/Bogota").format("MM-DD-YYYY"));
    setValue("to", moment(end).tz("America/Bogota").format("MM-DD-YYYY"));
  };

  const onSubmit = (data) => {
    if (toDate < fromDate) {
      Swal.fire(
        "Información",
        "La fecha inicial debe ser  menor a la fecha final , intenta de nuevo",
        "info"
      );

      return;
    }
    setIsSearched(true);
    reduxGetShipperConfirmReport({
      ...data,
      page: 1,
      search: search ? search : "",
      offset: offset,
    });
  };

  const CustomDateRangePicker = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      className="form-control"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
      value={value}
      autoComplete="Off"
    />
  ));

  const columns = [
    {
      title: "Id Externo",
      render: (rowData) => {
        return <span>{rowData.externalId}</span>;
      },
      field: "externalId",
      searchable: true,
    },
    {
      title: "Guía",
      render: (rowData) => {
        return <span>{rowData.trackingId}</span>;
      },
      field: "trackingId",
      searchable: true,
    },
    {
      title: "Fecha de creación del envío",
      render: (rowData) => {
        return <span>{rowData.createdAt}</span>;
      },
      field: "createdAt",
      searchable: false,
    },
    {
      title: "Valor recaudado",
      render: (rowData) => {
        return <span> {rowData.collection} </span>;
      },
      field: "collection",
      searchable: true,
    },
    {
      title: "Forma de pago del remitente",
      render: (rowData) => {
        return <span>{rowData.senderPayMethod}</span>;
      },
      field: "senderPayMethod",
      searchable: false,
    },
    {
      title: "Fecha de pago del remitente",
      render: (rowData) => {
        return <span>{rowData.senderConfirmDate}</span>;
      },
      field: "senderConfirmDate",
      searchable: false,
    },
    {
      title: "Observación de pago del remitente",
      render: (rowData) => {
        return <span>{rowData.senderConfirmComments}</span>;
      },
      field: "senderConfirmComments",
      searchable: false,
    },
  ];

  return (
    <div>
      <div id="wrapper">
        <Sidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Topbar />
            <div className="container-fluid">
              <div className="d-sm-flex align-items-center mb-1">
                <h1 className="h3 mb-0 text-gray-800 mr-3">
                  Reporte confirmación de recaudo
                </h1>
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="d-flex justify-content-between align-items-end mt-4 mb-2">
                  <div className="d-flex align-items-end">
                    <div className="search-field">
                      <label htmlFor="from" className="form-label">Desde - Hasta</label>
                      <DatePicker
                        selected={fromDate}
                        onChange={handleChangeDatePicker}
                        startDate={fromDate}
                        endDate={toDate}
                        maxDate={new Date()}
                        dateFormat="MM-dd-yyyy"
                        customInput={<CustomDateRangePicker />}
                        selectsRange
                      />
                    </div>
                    <Button variant="primary" type="submit" id="btnSearch" className="mx-1">Buscar</Button>
                  </div>
                  {shipperConfirm &&
                      shipperConfirm.items &&
                      Object.keys(shipperConfirm.items).length > 0 && (
                        <>
                          <Button variant="primary" id="btnSearch" onClick={downloadGeneral}>
                            Descarga general
                          </Button>
                        </>
                      )}
                </div>
              </form>
              <div className="card shadow mb-4">
                <div className="card-body">
                  <Grid
                    cols={columns}
                    data={
                      shipperConfirm && Object.keys(shipperConfirm).length > 0
                        ? shipperConfirm.items
                        : []
                    }
                    page={
                      shipperConfirm && Object.keys(shipperConfirm).length > 0
                        ? Number(shipperConfirm.page)
                        : currentPage
                    }
                    total={
                      shipperConfirm && shipperConfirm.hasOwnProperty("total")
                        ? shipperConfirm.total
                        : 0
                    }
                    pages={
                      shipperConfirm && Object.keys(shipperConfirm).length > 0
                        ? Number(shipperConfirm.totalPages)
                        : 1
                    }
                    offset={offset}
                    onChangePage={(page) => setCurrentPage(page)}
                    onChangeRange={(value) => {
                      setOffset(value);
                      let filters = {
                        page: 1,
                        offset: value,
                        search: search,
                      };
                      if (fromDate && toDate) {
                        if (toDate < fromDate) {
                          Swal.fire(
                            "Información",
                            "La fecha inicial debe ser  menor a la fecha final , intenta de nuevo",
                            "info"
                          );
                          return;
                        }
                        filters = {
                          ...filters,
                          from: moment(fromDate)
                            .tz("America/Bogota")
                            .format("YYYY-MM-DD"),
                          to: moment(toDate)
                            .tz("America/Bogota")
                            .format("YYYY-MM-DD"),
                        };
                      }
                      reduxGetShipperConfirmReport(filters);
                    }}
                    defaultValue={search}
                    onChangeSearch={(value) => {
                      setSearch(value);
                      let filters = {
                        page: 1,
                        offset: offset,
                        search: value,
                      };
                      if (fromDate && toDate) {
                        if (toDate < fromDate) {
                          Swal.fire(
                            "Información",
                            "La fecha inicial debe ser  menor a la fecha final , intenta de nuevo",
                            "info"
                          );
                          return;
                        }
                        filters = {
                          ...filters,
                          from: moment(fromDate)
                            .tz("America/Bogota")
                            .format("YYYY-MM-DD"),
                          to: moment(toDate)
                            .tz("America/Bogota")
                            .format("YYYY-MM-DD"),
                        };
                      }
                      reduxGetShipperConfirmReport(filters);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    shipperConfirm: state.reportState.shipperConfirm,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    reduxGetShipperConfirmReport: (payload) =>
      dispatch({
        type: "FETCH_SHIPPERCONFIRMREPORT_REQUEST",
        value: payload,
      }),
    reduxDownloadShipperConfirmReport: (payload) =>
      dispatch({
        type: "DOWNLOAD_SHIPPERCONFIRMREPORT_REQUEST",
        value: payload,
      }),
    reduxResetShipperConfirmReportForm: () =>
      dispatch({
        type: "RESET_CONFIRMREPORT_FORM",
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmCollectionShipperReport);
