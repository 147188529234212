

const initialState = {
    addressCloseConfirmations:[],
    requestingFetchAddresCloseConfirmation: false,
    successfulFetchAddresCloseConfirmation: false,
    errorFetchAddresCloseConfirmation: false,
    fetching_address: false,
    modalShow: false,
    resetForm: false,
    filterFields: {},
    report: {
        requesting: false,
        successfulFetch: false,
        errorFetch: false,
        data: []
    }

}

const addressCloseConfirmationReducer = (state = initialState, payload) => {
    switch (payload.type) {
        case addressCloseConfirmationActions.FECTCHIN_ALL_CLOSECONFIRMATION:
            return {
                ...state,
                fetching_address: payload.value
            };
        case addressCloseConfirmationActions.FETCH_ADDRESSES_CLOSECONFIRMATION_SUCCESS:
            return {
                ...state,
                errorFetchAddresCloseConfirmation: false,
                requestingFetchAddresCloseConfirmation: false,
                successfulFetchAddresCloseConfirmation: true,
                addressCloseConfirmations: payload.value
            };
        case addressCloseConfirmationActions.FETCH_ADDRESSES_CLOSECONFIRMATION_ERROR:
            return {
                ...state,
                errorFetchAddresCloseConfirmation: true,
                requestingFetchAddresCloseConfirmation: false,
                successfulFetchAddresCloseConfirmation: false,
            };
        case addressCloseConfirmationActions.POST_CLOSECONFIRMATION_SUCCESS:
            return {
                ...state,
                errorFetchAddresCloseConfirmation: false,
                requestingFetchAddresCloseConfirmation: false,
                successfulFetchAddresCloseConfirmation: true,
                addressCloseConfirmations: {
                    ...state.addressCloseConfirmations,
                    items: state.addressCloseConfirmations.items.filter((el) => !payload.value.some(addr => addr.idAddress === el.idAddress) )
                },
                modalShow: false,
                resetForm: true
            };
        case addressCloseConfirmationActions.HANDLE_SHOW_MODAL:
            return {
                ...state,
                resetForm: !payload.value,
                modalShow: payload.value
            };
        case addressCloseConfirmationActions.SET_FIELD_FILTER:
            return {
                ...state,
                filterFields: payload.value
            };
        case addressCloseConfirmationReportActions.FECTCHIN_CLOSECONFIRMATION_REPORT:
            return {
                ...state,
                report: {
                    ...state.report,
                    requesting: true
                }
            };
        case addressCloseConfirmationReportActions.FETCH_ADDRESSES_CLOSECONFIRMATION_REPORT_SUCCESS:
            return {
                ...state,
                report: {
                    ...state.report,
                    requesting: false,
                    successfulFetch: false,
                    errorFetch: false,
                    data: payload.value
                }
            };
        default:
            return state;
    }
};

export const addressCloseConfirmationActions = {
    FECTCHIN_ALL_CLOSECONFIRMATION: 'FECTCHIN_ALL_CLOSECONFIRMATION',
    FECTCH_ALL_CLOSECONFIRMATION: 'FECTCH_ALL_CLOSECONFIRMATION',
    FETCH_ADDRESSES_CLOSECONFIRMATION_SUCCESS: 'FETCH_ADDRESSES_CLOSECONFIRMATION_SUCCESS',
    FETCH_ADDRESSES_CLOSECONFIRMATION_ERROR: 'FETCH_ADDRESSES_CLOSECONFIRMATION_ERROR',
    FETCHING_POST_CLOSECONFIRMATION: 'FETCHING_POST_CLOSECONFIRMATION',
    POST_CLOSECONFIRMATION: 'POST_CLOSECONFIRMATION',
    POST_CLOSECONFIRMATION_ERROR: 'POST_CLOSECONFIRMATION_ERROR',
    POST_CLOSECONFIRMATION_SUCCESS: 'POST_CLOSECONFIRMATION_SUCCESS',
    HANDLE_SHOW_MODAL: 'HANDLE_SHOW_MODAL',
    RESET_FORM: 'RESET_FORM',
    SET_FIELD_FILTER: 'SET_FIELD_FILTER'
}

export const addressCloseConfirmationReportActions = {
    FECTCHIN_CLOSECONFIRMATION_REPORT: 'FECTCHIN_CLOSECONFIRMATION_REPORT',
    FECTCH_CLOSECONFIRMATION_REPORT: 'FECTCH_CLOSECONFIRMATION_REPORT',
    FETCH_ADDRESSES_CLOSECONFIRMATION_REPORT_SUCCESS: 'FETCH_ADDRESSES_CLOSECONFIRMATION_REPORT_SUCCESS',
    FETCH_ADDRESSES_CLOSECONFIRMATION_REPORT_ERROR: 'FETCH_ADDRESSES_CLOSECONFIRMATION_REPORT_ERROR',
    EXPORT_CLOSECONFIRMATION_REPORT: 'EXPORT_CLOSECONFIRMATION_REPORT',
    EXPORT_CLOSECONFIRMATION_REPORT_ERROR: 'EXPORT_CLOSECONFIRMATION_REPORT_ERROR',
    EXPORT_CLOSECONFIRMATION_REPORT_SUCCESS: 'EXPORT_CLOSECONFIRMATION_REPORT_SUCCESS',
}

export default addressCloseConfirmationReducer;
