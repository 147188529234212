import { call, put, takeLatest } from "redux-saga/effects";
import request, {requestAxios, requestAxiosDownload} from "../../utils/request";

function* updateMasivRescheduling(payload) {
  try {
    yield put({
      type: "UPDATE_MASIV_RESCHEDULING_REQUESTING"
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/rescheduling/masivRescheduling`;

    const headers = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload.value),
    };

    const response = yield call(request, requestURL, headers);
    
    yield put({
      type: "UPDATE_MASIV_RESCHEDULING_SUCCESS",
      value: response,
    });
    
    if(response.errorState){
      const downloadErrorsURL = `${process.env.REACT_APP_API_URL}/api/v1/rescheduling/downloadExcelErrors`;

      const headersDownloadErrors = {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(response.errors),
      };

      yield call(requestAxios, downloadErrorsURL, headersDownloadErrors);
      yield put({
        type: "SHOW_ALERT",
        value: {
          type: "warning",
          title: "Failed save",
          message: "The file has the errors",
        },
      });
    } else {
      yield put({
        type: "SHOW_ALERT",
        value: {
          type: "success",
          title: "Successful save",
          message: "Successful save Reschedule",
        },
      });
    }
  } catch (error) {
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed save",
        message: "Failed save Reschedule",
      },
    });
    yield put({
      type: "UPDATE_MASIV_RESCHEDULING_ERROR",
    });
  } finally {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "UPDATE_MASIV_RESCHEDULING_DONE",
    });
  }
}

function* downloadTemplateMasivRescheduling() {
  try {
    yield put({
      type: "SHOW_LOADING",
    });

    const downloadTemplateURL = `${process.env.REACT_APP_API_URL}/api/v1/rescheduling/downloadTemplate`;

    const headersDownloadTemplate = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
      },
    };
    const response = yield call(requestAxiosDownload, downloadTemplateURL, headersDownloadTemplate);
    if(response && response.status === 200){
      yield put({
        type: "SHOW_ALERT",
        value: {
          type: "success",
          title: "Successful download",
          message: "Successful download template",
        },
      });
    }
  } catch (error) {
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed download",
        message: "Failed download template",
      },
    });
  } finally {
    yield put({
      type: "HIDE_LOADING",
    });
  }
}


function* uploadMasivReschedule(payload) {
  try {
    yield put({
      type: "UPLOAD_MASIV_RESCHEDULE_REQUESTING"
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/rescheduling/uploadMasivRescheduling`;

    const formData = new FormData();
    formData.append("file", payload.value.file);
    formData.append("guideOrExternalId", payload.value.guideOrExternalId);

    const headers = {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
      }),
      body: formData,
    };

    const response = yield call(request, requestURL, headers);

    yield put({
      type: "UPLOAD_MASIV_RESCHEDULE_SUCCESS",
      value: response,
    });

    if(response.errorState){
      const downloadErrorsURL = `${process.env.REACT_APP_API_URL}/api/v1/rescheduling/downloadExcelErrors`;

      const headersDownloadErrors = {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(response.errors),
      };

      yield call(requestAxios, downloadErrorsURL, headersDownloadErrors);
      yield put({
        type: "SHOW_ALERT",
        value: {
          type: "warning",
          title: "Failed save",
          message: "The file has the errors",
        },
      });
    } else {
      yield put({
        type: "SHOW_ALERT",
        value: {
          type: "success",
          title: "Successful save",
          message: "Successful save reschedule",
        },
      });
    }
  } catch (error) {
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed save",
        message: "Failed save reschedule",
      },
    });
    yield put({
      type: "UPLOAD_MASIV_RESCHEDULE_ERROR",
    });
  } finally {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "UPLOAD_MASIV_RESCHEDULE_DONE",
    });
  }
}

function* downloadErrorsMasivRescheduling(payload) {
  try {
    yield put({
      type: "SHOW_LOADING",
    });

    const downloadErrorsURL = `${process.env.REACT_APP_API_URL}/api/v1/rescheduling/downloadExcelErrors`;

    const headersDownloadErrors = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload.value),
    };

    yield call(requestAxios, downloadErrorsURL, headersDownloadErrors);
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "warning",
        title: "Failed save",
        message: "The file has the errors",
      },
    });
  } catch (error) {
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed save",
        message: "Failed save reschedule",
      },
    });
  } finally {
    yield put({
      type: "HIDE_LOADING",
    });
  }
}

export function* watchRescheduling() {
  yield takeLatest("UPDATE_MASIV_RESCHEDULING_REQUEST", updateMasivRescheduling);
  yield takeLatest("UPLOAD_MASIV_RESCHEDULING_REQUEST", uploadMasivReschedule);
  yield takeLatest("DOWNLOAD_ERRORS_MASIV_RESCHEDULING_REQUEST", downloadErrorsMasivRescheduling);
  yield takeLatest("DOWNLOAD_TEMPLATE_MASIV_RESCHEDULING_REQUEST", downloadTemplateMasivRescheduling);
}
