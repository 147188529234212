import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { connect } from "react-redux";
import { Button, Modal } from "react-bootstrap";
import { isNullOrUndefined } from "../../../utils/functionsUtils";

const UpsertAlertSource = ({
    show,
    alertSourceData,
    handleClose,
    reduxGetOneAlertSource,
    alertSource,
    reduxCreateAlertSource,
    reduxUpdateAlertSource,
}) => {
    const {
        handleSubmit,
        control,
        formState: { errors },
        setValue,
        reset,
    } = useForm({
        defaultValues: {
            code: '',
        }
    });

    useEffect(() => {
        reset();

        if (!isNullOrUndefined(alertSourceData)) {
            reduxGetOneAlertSource({
                idAlertSource: alertSourceData.idAlertSource
            })
        }
    }, [alertSourceData, reduxGetOneAlertSource]);

    useEffect(() => {
        if (!alertSource) return;

        setValue("code", alertSource.code);
    }, [alertSource])

    const submit = (data) => {
        var dataObj = {
            ...data,
        }

        if (alertSource) {
            dataObj = { idAlertSource: alertSource.idAlertSource, ...dataObj };
            reduxUpdateAlertSource(dataObj);
        } else {
            reduxCreateAlertSource(dataObj);
        }
        
        handleClose();
    }

    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                onEscapeKeyDown={handleClose}
                backdrop="static"
                keyboard={false}
                size="sl"
            >
                <form onSubmit={handleSubmit(submit)}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <div className="d-flex justify-content-between align-items-center">
                                {alertSourceData ? "Editar" : "Nueva"} fuente de alerta
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className='form-group col'>
                                <label
                                    htmlFor='code'
                                    className='form-label'>
                                    Código *
                                </label>
                                <Controller
                                    control={control}
                                    rules={{
                                        required: 'El código es requerido',
                                    }}
                                    name="code"
                                    render={({ field }) => (
                                        <>
                                            <input {...field} className="form-control" type="text" />
                                            {
                                                errors.code &&
                                                <span className="error-message">
                                                    {errors.code.message}
                                                </span>
                                            }
                                        </>
                                    )}
                                />
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Cancelar
                        </Button>
                        <Button variant="primary" type="submit">
                            Guardar
                        </Button>
                    </Modal.Footer>
                </form >
            </Modal>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        alertSource: state.alertSourceState.alertSource,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        reduxGetOneAlertSource: (payload) =>
            dispatch({
                type: "FETCH_ONE_ALERT_SOURCE_REQUEST",
                value: payload,
            }),
        reduxCreateAlertSource: (payload) =>
            dispatch({
                type: "CREATE_ALERT_SOURCE_REQUEST",
                value: payload,
            }),
        reduxUpdateAlertSource: (payload) =>
            dispatch({
                type: "UPDATE_ALERT_SOURCE_REQUEST",
                value: payload,
            }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpsertAlertSource);