import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Sidebar from "../../components/Navigation/Sidebar";
import Topbar from "../../components/Navigation/Topbar";
import Grid from "../../components/Grid";
import { connect } from "react-redux";
import { Button, Modal } from "react-bootstrap";
import { Show } from "../../hooks/Show";
import { useAuth } from "../../hooks/useAuth";
import Select from 'react-select'

const MappingCity = ({
  reduxGetMappingCities,
  externalMappings,
  reduxGetMappingCity,
  externalMapping,
  reduxPostMappingCity,
  rowEdited,
  reduxDeleteMappingCity,
  rowDeleted,
  reduxPatchMappingCity,
  rowUpdated,
  reduxResetMappingCityForm,
  reduxGetCompanies,
  companies,
  reduxGetCities,
  cities,
}) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
    setValue,
    getValues
  } = useForm();
  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(10);
  const { session } = useAuth();
  const [show, setShow] = useState(false);

  const handleClose = () => {
    reduxResetMappingCityForm();
    setShow(false);
  };
  const handleShow = () => {
    reduxResetMappingCityForm();
    reset();
    setShow(true);
  };
  const [search, setSearch] = useState("");

  const columns = [
    {
      title: "#",
      render: (rowData) => {
        return <span>{rowData.idExternalMapping}</span>;
      },
      field: 'idExternalMapping',
      searchable: true
    },
    {
      title: "Empresa",
      render: (rowData) => {
        return (
          <span>{rowData.company ? rowData.company.description : ""}</span>
        );
      },
      field: 'company.description',
      searchable: true
    },
    {
      title: "Ciudad",
      render: (rowData) => {
        return (
          <span>
            {rowData.idEntity && cities.items && Object.keys(cities).length > 0
              ? cities.items.filter((c) => c.idCity == rowData.idEntity)
                  .length > 0
                ? cities.items.filter((c) => c.idCity == rowData.idEntity)[0]
                    .description
                : ""
              : ""}
          </span>
        );
      },
      field: 'idEntity',
      searchable: true
    },
    {
      title: "ID Externo",
      render: (rowData) => {
        return <span>{rowData.idExternalEntity}</span>;
      },
      field: 'idExternalEntity',
      searchable: true
    },
    {
      title: "Opciones",
      render: (rowData) => {
        return (
          <>
            {rowData.isActive ? (
              <>
                <Show when="feature:edit-mapping-cities">
                  <button
                    title="Editar"
                    className="btn btn-primary btn-sm  btn-circle mr-2"
                    type="button"
                    onClick={(e) => handleOpen(rowData)}
                  >
                    <i className="fas fa-edit fa-xs"></i>
                  </button>
                </Show>
                <Show when="feature:disabled-mapping-cities">
                  <button
                    title="Desactivar"
                    className="btn btn-danger btn-sm btn-circle"
                    type="button"
                    onClick={(e) => handleDelete(rowData)}
                  >
                    <i className="fas fa-times-circle fa-xs"></i>
                  </button>
                </Show>
              </>
            ) : (
              <Show when="feature:enabled-mapping-cities">
                <button
                  title="Activar"
                  className="btn btn-primary btn-sm  btn-circle mr-2"
                  type="button"
                  onClick={(e) => handleActive(rowData)}
                >
                  <i className="fas fa-check-circle fa-xs"></i>
                </button>
              </Show>
            )}
          </>
        );
      },
    },
  ];

  const handleOpen = (row) => {
    reduxGetMappingCity({
      id: row.idExternalMapping,
    });
  };

  const handleActive = (row) => {
    if (!row.isActive) {
      reduxDeleteMappingCity(row);
    }
  };

  useEffect(() => {
    if (rowEdited) {
      const [selectedCompany] = (companies.items.filter((f)=>f.idCompany === rowEdited.idCompany));
      const [cityCompany] = (cities.items.filter((f)=>f.idCity === rowEdited.idEntity));
      setShow(true);
      setValue("idExternalEntity", rowEdited.idExternalEntity);
      setValue("idEntity", {value: cityCompany.idEntity, label: cityCompany.description});
      setValue("idCompany", {value: selectedCompany.idCompany, label: selectedCompany.description});
    }
  }, [rowEdited]);

  const handleDelete = (row) => {
    reduxDeleteMappingCity(row);
  };

  useEffect(() => {
    reduxGetMappingCities({
      page: 1,
      offset: offset,
      search: "",
      mappingType: "city",
    });
    reduxGetCompanies({
      page: 1,
      search: "",
      offset: 1000,
    });
    reduxGetCities({
      page: 1,
      search: "",
      offset: 100000,
    });
  }, []);

  useEffect(() => {
    reduxGetMappingCities({
      page: currentPage,
      offset: offset,
      search: search,
      mappingType: "city",
    });
  }, [currentPage]);

  const onSubmit = (data) => {
    if (rowEdited) {
      reduxPatchMappingCity({
        ...data,
        idExternalMapping: rowEdited.idExternalMapping,
        mappingType: "city",
        idCompany:
          session && session.role.idCompany
            ? session.role.idCompany
            : data.idCompany,
      });
    } else {
      reduxPostMappingCity({
        ...data,
        mappingType: "city",
        idCompany:
          session && session.role.idCompany
            ? session.role.idCompany
            : data.idCompany,
      });
    }
  };

  useEffect(() => {
    if (externalMapping || rowUpdated || rowDeleted) {
      if (
        (externalMapping && externalMapping.message == undefined) ||
        (rowUpdated && rowUpdated.message == undefined) ||
        rowDeleted
      ) {
        reduxGetMappingCities({
          page: currentPage,
          offset: offset,
          search: search,
          mappingType: "city",
        });
        reset();
        reduxResetMappingCityForm();
        setShow(false);
      }
    }
  }, [externalMapping, rowUpdated, rowDeleted]);

  return (
    <div>
      <div id="wrapper">
        <Sidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Topbar />
            <div className="container-fluid">
              <div className="d-sm-flex align-items-center mb-1">
                <h1 className="h3 mb-0 text-gray-800 mr-3">
                  Mapeo ciudades externas
                </h1>
                <Show when="feature:create-mapping-cities">
                  <button
                    className="btn btn-primary btn-circle"
                    type="button"
                    onClick={handleShow}
                  >
                    <i className="fas fa-plus fa-sm"></i>
                  </button>
                </Show>
              </div>
              <p className="mb-4">Módulo de Mapeo de ciudades</p>
              <div className="card shadow mb-4">
                <div className="card-header py-3">
                  <h6 className="m-0 font-weight-bold text-primary">
                    Listado de Mapeo de ciudades
                  </h6>
                </div>
                <div className="card-body">
                  <Grid
                    cols={columns}
                    data={
                      externalMappings &&
                      Object.keys(externalMappings).length > 0
                        ? externalMappings.items
                        : []
                    }
                    page={
                      externalMappings &&
                      Object.keys(externalMappings).length > 0
                        ? Number(externalMappings.page)
                        : currentPage
                    }
                    pages={
                      externalMappings &&
                      Object.keys(externalMappings).length > 0
                        ? Number(externalMappings.totalPages)
                        : 0
                    }
                    total={
                      externalMappings &&
                      externalMappings.hasOwnProperty("total")
                        ? externalMappings.total
                        : 0
                    }
                    offset={offset}
                    onChangePage={(page) => setCurrentPage(page)}
                    onChangeRange={(value) => {
                      setOffset(value);
                      reduxGetMappingCities({
                        page: 1,
                        offset: value,
                        search: search,
                        mappingType: "city",
                      });
                    }}
                    defaultValue={search}
                    onChangeSearch={(value) => {
                      setSearch(value);
                      reduxGetMappingCities({
                        page: 1,
                        offset: offset,
                        search: value,
                        mappingType: "city",
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Modal
          show={show}
          onHide={handleClose}
          onEscapeKeyDown={handleClose}
          backdrop="static"
          keyboard={true}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <Modal.Header closeButton>
              <Modal.Title>
                {rowEdited ? "Editar" : "Nuevo"} Mapeo de ciudad
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {session && session.role.idCompany == null && (
                <div className="form-group">
                  <label htmlFor="idCompany" className="form-label">
                    Empresa
                  </label>
                  <Select
                    isClearable={true}
                    value={getValues('idCompany')}
                    {...register('idCompany')}
                    id='idCompany'
                    onChange={(value) => setValue('idCompany', value?.value || '')}
                    options=
                    {companies &&
                      Object.keys(companies).length > 0 &&
                      companies.items
                        .map((ele) => ({                                    
                            value: ele.idCompany,
                            label: ele.description,
                          }))
                    }
                  />
                  
                  {errors.idCompany && (
                    <span className="invalid-feedback">
                      La empresa es requerida
                    </span>
                  )}
                </div>
              )}
              <div className="form-group">
                <label htmlFor="idEntity" className="form-label">
                  Ciudad
                </label>
                  <Select
                    isClearable={true}
                    value={getValues('idEntity')}
                    {...register('idEntity')}
                    id='idEntity'
                    onChange={(value) => setValue('idEntity', value?.value || '')}
                    options=
                    {cities &&
                      Object.keys(cities).length > 0 &&
                      cities.items
                        .map((ele) => ({                                    
                            value: ele.idCity,
                            label: ele.description,
                          }))
                    }
                  />
                {errors.idEntity && (
                  <span className="invalid-feedback">
                    La ciudad es requerida
                  </span>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="idExternalEntity" className="form-label">
                  ID externo
                </label>
                <input
                  id="idExternalEntity"
                  type="text"
                  className={`form-control form-control-user ${
                    errors.idExternalEntity && "is-invalid"
                  }`}
                  {...register("idExternalEntity", { required: true })}
                />
                {errors.idExternalEntity && (
                  <span className="invalid-feedback">El ID es requerido</span>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cancelar
              </Button>
              <Button variant="primary" type="submit">
                Guardar
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    externalMappings: state.externalMappingState.externalMappings,
    externalMapping: state.externalMappingState.externalMapping,
    rowEdited: state.externalMappingState.rowEdited,
    rowDeleted: state.externalMappingState.rowDeleted,
    rowUpdated: state.externalMappingState.rowUpdated,
    companies: state.companyState.companies,
    cities: state.cityState.cities,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    reduxGetMappingCities: (payload) =>
      dispatch({
        type: "FETCH_EXTERNALMAPPINGS_REQUEST",
        value: payload,
      }),
    reduxPostMappingCity: (payload) =>
      dispatch({
        type: "CREATE_EXTERNALMAPPING_REQUEST",
        value: payload,
      }),
    reduxGetMappingCity: (payload) =>
      dispatch({
        type: "READ_EXTERNALMAPPING_REQUEST",
        value: payload,
      }),
    reduxDeleteMappingCity: (payload) =>
      dispatch({
        type: "DELETE_EXTERNALMAPPING_REQUEST",
        value: payload,
      }),
    reduxPatchMappingCity: (payload) =>
      dispatch({
        type: "UPDATE_EXTERNALMAPPING_REQUEST",
        value: payload,
      }),
    reduxResetMappingCityForm: () =>
      dispatch({
        type: "RESET_EXTERNALMAPPING_FORM",
      }),
    reduxGetCompanies: (payload) =>
      dispatch({
        type: "FETCH_COMPANIES_REQUEST",
        value: payload,
      }),
    reduxGetCities: (payload) =>
      dispatch({
        type: "FETCH_CITIES_REQUEST",
        value: payload,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MappingCity);
