
const TrafficLight = ({days}) => {

    const getColor = () => {
        if(days <= 1) return 'red-light'
        if(days === 2) return 'orange-light'
        if(days === 3) return 'yellow-light'
        if(days >= 4) return 'blue-light'
    }

    return (
        <>
        <div className={`btn-sm btn-circle ${getColor()}`}>
            {days}
        </div>
        </>
    );
}

export default TrafficLight;