

const initialState = {
    pendingOrders:[],
    lockedOrders:[],
    totalPendingOrders: 0,

    requestingFetchPendingOrders: false,
    successfulFetchPendingOrders: false,
    errorFetchAddressPendingOrders: false,

    requestingFetchCountPendingOrders: false,
    successfulFetchCountPendingOrders: false,
    errorFetchCountPendingOrders: false,

    requestingFetchLockedOrders: false,
    successfulFetchLockedOrders: false,
    errorFetchAddressLockedOrders: false,

    requestingFetchCountLockedOrders: false,
    successfulFetchCountLockedOrders: false,
    errorFetchCountLockedOrders: false,

    requestingFetchConfirmOrder: false,
    successfulFetchConfirmOrder: false,
    errorRequestConfirmOrder: false,
    totalOrdersConfirmed: 0,

    requestingUploadOrdersToConfirm: false,
    successUploadOrdersToConfirm: false,
    errorUploadOrdersToConfirm: false,
    ordersUploaded: null,

    requestingFetchDeleteOrder: false,
    successfulFetchDeleteOrder: false,
    errorRequestDeleteOrder: false,

    endDate: null,
    startDate: null,
}

const OrdersLockedReducer = (state = initialState, payload) => {
    switch (payload.type) {
        case ordersLockedActions.FECTCHING_ALL_PENDING_ORDERS:
            return {
                ...state,
                requestingFetchPendingOrders: true,
                successfulFetchPendingOrders: false,
                errorFetchAddressPendingOrders: false,
            };
        case ordersLockedActions.FECTCHING_ALL_PENDING_ORDERS_SUCCESS:
            return {
                ...state,
                requestingFetchPendingOrders: false,
                successfulFetchPendingOrders: true,
                errorFetchAddressPendingOrders: false,
                pendingOrders: payload.value,
            };
        case ordersLockedActions.FECTCHING_ALL_PENDING_ORDERS_ERROR:
            return {
                ...state,
                errorFetchAddressPendingOrders: true,
                requestingFetchPendingOrders: false,
                successfulFetchPendingOrders: false,
            };
        
        case ordersLockedActions.FECTCHING_COUNT_ALL_PENDING_ORDERS:
            return {
                ...state,
                requestingFetchCountPendingOrders: true,
                successfulFetchCountPendingOrders: false,
                errorFetchCountPendingOrders: false,
            };
        case ordersLockedActions.FECTCHING_COUNT_ALL_PENDING_ORDERS_SUCCESS:
            return {
                ...state,
                requestingFetchCountPendingOrders: false,
                successfulFetchCountPendingOrders: true,
                errorFetchCountPendingOrders: false,
                totalPendingOrders: payload.value.total
            };
        case ordersLockedActions.FECTCHING_COUNT_ALL_PENDING_ORDERS_ERROR:
            return {
                ...state,
                requestingFetchCountPendingOrders: false,
                successfulFetchCountPendingOrders: false,
                errorFetchCountPendingOrders: true
            };
        case ordersLockedActions.FECTCHING_ALL_LOCKED_ORDERS:
            return {
                ...state,
                requestingFetchLockedOrders: true,
                successfulFetchLockedOrders: false,
                errorFetchAddressLockedOrders: false,
            };
        case ordersLockedActions.FECTCHING_ALL_LOCKED_ORDERS_SUCCESS:
            return {
                ...state,
                requestingFetchLockedOrders: false,
                successfulFetchLockedOrders: true,
                errorFetchAddressLockedOrders: false,
                lockedOrders: payload.value,
            };
        case ordersLockedActions.FECTCHING_ALL_LOCKED_ORDERS_ERROR:
            return {
                ...state,
                errorFetchAddressLockedOrders: true,
                requestingFetchLockedOrders: false,
                successfulFetchLockedOrders: false,
            };
        
        case ordersLockedActions.FECTCHING_COUNT_ALL_LOCKED_ORDERS:
            return {
                ...state,
                requestingFetchCountLockedOrders: true,
                successfulFetchCountLockedOrders: false,
                errorFetchCountLockedOrders: false,
            };
        case ordersLockedActions.FECTCHING_COUNT_ALL_LOCKED_ORDERS_SUCCESS:
            return {
                ...state,
                requestingFetchCountLockedOrders: false,
                successfulFetchCountLockedOrders: true,
                errorFetchCountLockedOrders: false,
            };
        case ordersLockedActions.FECTCHING_COUNT_ALL_LOCKED_ORDERS_ERROR:
            return {
                ...state,
                requestingFetchCountLockedOrders: false,
                successfulFetchCountLockedOrders: false,
                errorFetchCountLockedOrders: true
            };
        case ordersLockedActions.SET_START_DATE:
            return {
                ...state,
                startDate: payload.value
            };
        case ordersLockedActions.SET_END_DATE:
            return {
                ...state,
                endDate: payload.value
            };
        
        case ordersLockedActions.FECTCHING_CONFIRM_ORDER:
            return {
                ...state,
                requestingFetchConfirmOrder: true,
                successfulFetchConfirmOrder: false,
                errorRequestConfirmOrder: false,
            };
        case ordersLockedActions.FECTCHING_CONFIRM_ORDER_SUCCESS:
            return {
                ...state,
                requestingFetchConfirmOrder: false,
                successfulFetchConfirmOrder: true,
                errorRequestConfirmOrder: false,
            };
        case ordersLockedActions.FECTCHING_CONFIRM_ORDER_ERROR:
            return {
                ...state,
                requestingFetchConfirmOrder: false,
                successfulFetchConfirmOrder: false,
                errorRequestConfirmOrder: true,
            };
        
        case ordersLockedActions.FECTCHING_CONFIRM_ALL_ORDERS_PENDING:
            return {
                ...state,
                requestingFetchConfirmOrder: true,
                successfulFetchConfirmOrder: false,
                errorRequestConfirmOrder: false,
            };
        case ordersLockedActions.FECTCHING_CONFIRM_ALL_ORDERS_PENDING_SUCCESS:
            return {
                ...state,
                requestingFetchConfirmOrder: false,
                successfulFetchConfirmOrder: true,
                errorRequestConfirmOrder: false,
                totalOrdersConfirmed: payload.value.total,
            };
        case ordersLockedActions.FECTCHING_CONFIRM_ALL_ORDERS_PENDING_ERROR:
            return {
                ...state,
                requestingFetchConfirmOrder: false,
                successfulFetchConfirmOrder: false,
                errorRequestConfirmOrder: true,
            };
        
        case ordersLockedActions.UPLOAD_ORDERS_TO_CONFIRM:
            return {
                ...state,
                requestingUploadOrdersToConfirm: true,
                successUploadOrdersToConfirm: false,
                errorUploadOrdersToConfirm: false,
            };
        case ordersLockedActions.UPLOAD_ORDERS_TO_CONFIRM_SUCCESS:
            return {
                ...state,
                requestingUploadOrdersToConfirm: false,
                successUploadOrdersToConfirm: true,
                errorUploadOrdersToConfirm: false,
                ordersUploaded: payload.value,
            };
        case ordersLockedActions.UPLOAD_ORDERS_TO_CONFIRM_ERROR:
            return {
                ...state,
                requestingUploadOrdersToConfirm: false,
                successUploadOrdersToConfirm: false,
                errorUploadOrdersToConfirm: false,
            };
        case ordersLockedActions.UPLOAD_ORDERS_TO_CONFIRM_DONE:
            return {
                ...state,
                requestingUploadOrdersToConfirm: false,
                successUploadOrdersToConfirm: false,
                errorUploadOrdersToConfirm: false,
            };
        case ordersLockedActions.FECTCHING_DELETE_ORDER:
            return {
                ...state,
                requestingFetchDeleteOrder: true,
                successfulFetchDeleteOrder: false,
                errorRequestDeleteOrder: false,
            };
        case ordersLockedActions.FECTCHING_DELETE_ORDER_SUCCESS:
            return {
                ...state,
                requestingFetchDeleteOrder: false,
                successfulFetchDeleteOrder: true,
                errorRequestDeleteOrder: false,
            };
        case ordersLockedActions.FECTCHING_DELETE_ORDER_ERROR:
            return {
                ...state,
                requestingFetchDeleteOrder: false,
                successfulFetchDeleteOrder: false,
                errorRequestDeleteOrder: true,
            };
        case ordersLockedActions.FECTCHING_DELETE_ORDER_DONE:
            return {
                ...state,
                requestingFetchDeleteOrder: false,
                successfulFetchDeleteOrder: false,
                errorRequestDeleteOrder: false,
            };    
        default:
            return state;
    }
};

export const ordersLockedActions = {
    FECTCHING_ALL_PENDING_ORDERS: 'FECTCHING_ALL_PENDING_ORDERS',
    FECTCHING_ALL_PENDING_ORDERS_SUCCESS: 'FECTCHING_ALL_PENDING_ORDERS_SUCCESS',
    FECTCHING_ALL_PENDING_ORDERS_ERROR: 'FECTCHING_ALL_PENDING_ORDERS_ERROR',

    FECTCHING_COUNT_ALL_PENDING_ORDERS: 'FECTCHING_COUNT_ALL_PENDING_ORDERS',
    FECTCHING_COUNT_ALL_PENDING_ORDERS_SUCCESS: 'FECTCHING_COUNT_ALL_PENDING_ORDERS_SUCCESS',
    FECTCHING_COUNT_ALL_PENDING_ORDERS_ERROR: 'FECTCHING_COUNT_ALL_PENDING_ORDERS_ERRORS',

    FECTCHING_ALL_LOCKED_ORDERS: 'FECTCHING_ALL_LOCKED_ORDERS',
    FECTCHING_ALL_LOCKED_ORDERS_SUCCESS: 'FECTCHING_ALL_LOCKED_ORDERS_SUCCESS',
    FECTCHING_ALL_LOCKED_ORDERS_ERROR: 'FECTCHING_ALL_LOCKED_ORDERS_ERROR',

    FECTCHING_COUNT_ALL_LOCKED_ORDERS: 'FECTCHING_COUNT_ALL_LOCKED_ORDERS',
    FECTCHING_COUNT_ALL_LOCKED_ORDERS_SUCCESS: 'FECTCHING_COUNT_ALL_LOCKED_ORDERS_SUCCESS',
    FECTCHING_COUNT_ALL_LOCKED_ORDERS_ERROR: 'FECTCHING_COUNT_ALL_LOCKED_ORDERS_ERRORS',

    FECTCHING_CONFIRM_ORDER: 'FECTCHING_CONFIRM_ORDER',
    FECTCHING_CONFIRM_ORDER_SUCCESS: 'FECTCHING_CONFIRM_ORDER_SUCCESS',
    FECTCHING_CONFIRM_ORDER_ERROR: 'FECTCHING_CONFIRM_ORDER_ERROR',
    
    FECTCHING_CONFIRM_ALL_ORDERS_PENDING: 'FECTCHING_CONFIRM_ALL_ORDERS_PENDING',
    FECTCHING_CONFIRM_ALL_ORDERS_PENDING_SUCCESS: 'FECTCHING_CONFIRM_ALL_ORDERS_PENDING_SUCCESS',
    FECTCHING_CONFIRM_ALL_ORDERS_PENDING_ERROR: 'FECTCHING_CONFIRM_ALL_ORDERS_PENDING_ERROR',
    
    UPLOAD_ORDERS_TO_CONFIRM: 'UPLOAD_ORDERS_TO_CONFIRM',
    UPLOAD_ORDERS_TO_CONFIRM_SUCCESS: 'UPLOAD_ORDERS_TO_CONFIRM_SUCCESS',
    UPLOAD_ORDERS_TO_CONFIRM_DONE: 'UPLOAD_ORDERS_TO_CONFIRM_DONE',
    UPLOAD_ORDERS_TO_CONFIRM_ERROR: 'UPLOAD_ORDERS_TO_CONFIRM_ERROR',

    FECTCHING_DELETE_ORDER: 'FECTCHING_DELETE_ORDER',
    FECTCHING_DELETE_ORDER_SUCCESS: 'FECTCHING_DELETE_ORDER_SUCCESS',
    FECTCHING_DELETE_ORDER_ERROR: 'FECTCHING_DELETE_ORDER_ERROR',
    FECTCHING_DELETE_ORDER_DONE: 'FECTCHING_DELETE_ORDER_DONE',

    SET_START_DATE: 'SET_START_DATE',
    SET_END_DATE: 'SET_END_DATE'
}

export default OrdersLockedReducer;
