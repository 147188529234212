import {call, put, takeLatest} from "redux-saga/effects";
import request from "../../utils/request";

function* getStatuses(payload) {
  try {
    yield put({
      type: "FETCH_STATUSES_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/addressState?page=${payload.value.page}&search=${payload.value.search}&offset=${payload.value.offset}${payload.value.isActive ? '&isActive=' + payload.value.isActive: '' }`;
    const headers = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "FETCH_STATUS_SUCCESS",
      value: response,
    });
    yield put({
      type: "HIDE_LOADING",
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed load",
        message: "Failed load status",
      },
    });
    yield put({
      type: "FETCH_STATUS_ERROR",
    });
  }
}

function* postStatus(payload) {
  try {
    yield put({ type: "CREATE_STATUS_REQUESTING" });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/AddressStatus`;

    const headers = {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(payload.value),
    };

    const response = yield call(request, requestURL, headers);

    yield put({
      type: "CREATE_STATUS_SUCCESS",
      value: response,
    });
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "success",
        title: "Successful save",
        message: "Successful save Status",
      },
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed save",
        message: "Failed save Status",
      },
    });
    yield put({
      type: "CREATE_STATUS_ERROR",
    });
  }
}

function* getStatusById(payload) {
  try {
    yield put({
      type: "READ_STATUS_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/AddressStatus/${payload.value.id}`;

    const headers = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "READ_STATUS_SUCCESS",
      value: response,
    });
    yield put({
      type: "HIDE_LOADING",
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "READ_STATUS_ERROR",
    });
  }
}

function* deleteStatusById(payload) {
  try {
    yield put({
      type: "DELETE_STATUS_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/AddressStatus/delete/${payload.value.idStatus}`;

    const headers = {
      method: "PATCH",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(payload.value),
    };

    const response = yield call(request, requestURL, headers);

    yield put({
      type: "DELETE_STATUS_SUCCESS",
      value: response,
    });
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "success",
        title: "Successful disable",
        message: "Successful disable Status",
      },
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed disable",
        message: "Failed disable Status",
      },
    });
    yield put({
      type: "DELETE_STATUS_ERROR",
    });
  }
}

function* updateStatus(payload) {
  try {
    yield put({
      type: "UPDATE_STATUS_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/AddressStatus/${payload.value.id}`;

    const headers = {
      method: "PATCH",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(payload.value),
    };

    const response = yield call(request, requestURL, headers);

    yield put({
      type: "UPDATE_STATUS_SUCCESS",
      value: response,
    });
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "success",
        title: "Successful update",
        message: "Successful update Status",
      },
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed update",
        message: "Failed update Status",
      },
    });
    yield put({
      type: "UPDATE_STATUS_ERROR",
    });
  }
}

export function* watchStatus() {
  yield takeLatest("FETCH_STATUSES_REQUEST", getStatuses);
  yield takeLatest("CREATE_STATUS_REQUEST", postStatus);
  yield takeLatest("READ_STATUS_REQUEST", getStatusById);
  yield takeLatest("DELETE_STATUS_REQUEST", deleteStatusById);
  yield takeLatest("UPDATE_STATUS_REQUEST", updateStatus);
}
