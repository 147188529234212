import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { Button, Modal } from "react-bootstrap";
import Select from 'react-select';
import { DateTime, IANAZone } from "luxon";
import DatePicker from 'react-datepicker';
import formatDateWithCustomFormat, { FormatsDate } from "../../../../../utils/formatDate";

const InventoryReport = ({
    show,
    handleClose,
    reduxGetClients,
    shippers,
    reduxDownloadInventoryMovementsReport,
}) => {
    const {
        handleSubmit: downloadModalSumbit,
        control: downloadModalControl,
        formState: { errors: downloadModalErrors },
        setError: downloadModalSetError,
        clearErrors: downloadModalClearErrors,
        reset: downloadModalReset,
    } = useForm({
        defaultValues: {
            shipper: null,
            dates: null,
        }
    });

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const customStyles = {
        menu: (base) => ({ ...base, zIndex: 9999 }),
        valueContainer: (baseStyles) => ({ ...baseStyles, maxHeight: "3.5vw", overflowY: "auto" }),
        control: (baseStyles) => ({ ...baseStyles, flexWrap: "nowrap" }) // Ajusta el valor según tus necesidades
    };

    const handleResetModal = () => {
        downloadModalReset();
        setStartDate(null);
        setEndDate(null);
    }

    useEffect(() => {
        handleResetModal();
        reduxGetClients();
    }, [])

    const handleCloseModal = () => {
        handleResetModal()
        handleClose();
    }

    const handleDownloadReport = (data) => {
        const fromDateFormatted = formatDateWithCustomFormat(startDate)
        const toDateFormatted = formatDateWithCustomFormat(endDate)

        if(fromDateFormatted === 'Invalid DateTime' || toDateFormatted === "Invalid DateTime"){
            downloadModalSetError('dates', {
                message: 'El valor de fecha es invalido',
              })
        }else{
            downloadModalClearErrors("dates");
        }

        reduxDownloadInventoryMovementsReport({
            fileName: `movements_general_report_${DateTime.now().setZone(new IANAZone('America/Bogota')).toFormat('yyyyLLdd_hhmm')}.csv`,
            start: `${fromDateFormatted} 00:00:00`,
            end: `${toDateFormatted} 23:59:59`,
            shipper: data.shipper.value,
        })
    }

    const changeDates = (dates) => {
        const [start, end] = dates;

        setStartDate(start);
        setEndDate(end);
      };

    return (
        <>
            <Modal
                show={show}
                onHide={handleCloseModal}
                onEscapeKeyDown={handleCloseModal}
                backdrop="static"
                keyboard={false}
            >
                <form onSubmit={downloadModalSumbit(handleDownloadReport)}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <div className="d-flex justify-content-between align-items-center">
                                Descargar reporte de movimientos
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className='form-group col'>
                                <label
                                    htmlFor='shipper'
                                    className='form-label'>
                                    Remitente *
                                </label>
                                <Controller
                                    control={downloadModalControl}
                                    rules={{
                                        required: 'El remitente es obligatorio'
                                    }}
                                    name="shipper"
                                    render={({ field }) => (
                                        <>
                                            <Select
                                                {...field}
                                                isClearable
                                                styles={customStyles}
                                                options={
                                                    shippers && Object.keys(shippers).length > 0
                                                        ? shippers.items
                                                            .map((ele, key) => ({
                                                                value: ele.idCompany,
                                                                label: ele.description,
                                                            }))
                                                        : []
                                                }
                                            />
                                            {
                                                downloadModalErrors.shipper &&
                                                <span className="error-message">
                                                    {downloadModalErrors.shipper.message}
                                                </span>
                                            }
                                        </>
                                    )}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className='form-group col'>
                                <label
                                    htmlFor='dates'
                                    className='form-label'>
                                    Desde - Hasta *
                                </label>
                                <Controller
                                    control={downloadModalControl}
                                    name="dates"
                                    rules={{
                                        required: 'Debe seleccionar una rango de fechas válido'
                                    }}
                                    render={({ field }) => (
                                        <>
                                            <DatePicker
                                                className={'form-control'}
                                                startDate={startDate}
                                                endDate={endDate}
                                                selectsRange
                                                onChange={(value) => {
                                                    field.onChange(value);
                                                    changeDates(value);
                                                }}
                                                dateFormat="yyyy-MM-dd"
                                                maxDate={new Date()}
                                                shouldCloseOnSelect={false}
                                                isClearable
                                            />
                                            {
                                                downloadModalErrors.dates &&
                                                <span className="error-message">
                                                    {downloadModalErrors.dates.message}
                                                </span>
                                            }
                                        </>
                                    )}
                                />
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseModal}>
                            Cancelar
                        </Button>
                        <Button variant="primary" type="submit">
                            Descargar
                        </Button>
                    </Modal.Footer>
                </form >
            </Modal>
        </>
    )
};


const mapStateToProps = (state) => {
    return {
        shippers: state.companyState.clients,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        reduxGetClients: (payload) =>
            dispatch({
                type: 'FETCH_COMPANIESBYSHIPPER_REQUEST',
                value: payload,
            }),
        reduxDownloadInventoryMovementsReport: (payload) =>
            dispatch({
                type: "DOWNLOAD_INVENTORY_MOVEMENTS_REPORT_REQUEST",
                value: payload,
            }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(InventoryReport);