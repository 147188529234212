const initialState = {
  pdfBase64: null,
};

const pdfTrackingReducer = (state = initialState, action) => {
  switch (action.type) {
    case "TRACKING_PDF_SUCCESS":
      return {
        ...state,
        pdfBase64: action.value,
      };
    case "RESET_PDF_TRACKING_FORM":
      return {
        ...state,
        pdfBase64: null,
      };
      case "TRACKING_OR_EXTERNALID_PDF_SUCCESS":
      return {
        ...state,
        pdfBase64: action.value,
      };
    default:
      return state;
  }
};

export default pdfTrackingReducer;
