import React, { useState, useEffect, forwardRef } from "react";
import { useForm } from "react-hook-form";
import Sidebar from "../../../components/Navigation/Sidebar";
import Topbar from "../../../components/Navigation/Topbar";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import moment from "moment-timezone";
import Select from "react-select";
import formatDateWithCustomFormat, {FormatsDate} from "../../../utils/formatDate";
const Swal = require("sweetalert2");

const ConciliationsReport = ({ 
  reduxGetCompanies, 
  companies,   
  reduxGetStatuses,
  statuses
}) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
    setValue,
    getValues,
  } = useForm();
  const [startDateFrom, setStartDateFrom] = useState(null);
  const [startDateTo, setStartDateTo] = useState(null);

  const download = () => {
    if (startDateTo < startDateFrom) {
      Swal.fire(
        "Información",
        "La fecha inicial debe ser  menor a la fecha final , intenta de nuevo",
        "info"
      );
      return;
    }
    const token = window.localStorage.getItem("token");
    let _data = {
      from: formatDateWithCustomFormat(getValues("from"), {format: FormatsDate.FULL24}),
      to: formatDateWithCustomFormat(getValues("to"), {format: "YYYY-MM-DD 23:59:59"}),
      idClient: getValues("idClient"),
      state: getValues("state"),
    };

    fetch(`${process.env.REACT_APP_API_URL}/api/v1/reports/conciliations`, {
      method: "POST",
      body: JSON.stringify(_data),
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      }),
    })
      .then((response) => response.blob())
      .then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download =
          moment(new Date()).tz("America/Bogota").format("YYYY-MM-DD") +
          "-conciliations-report.xlsx";
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
  };

  const DatePickerCustomFrom = forwardRef(
    ({ value, onClick, onChange }, ref) => (
      <>
        <input
          className={`form-control form-control-user ${
            errors.from && "is-invalid"
          }`}
          onClick={onClick}
          onChange={onChange}
          ref={ref}
          value={value}
          autoComplete="Off"
          {...register("from", { required: true })}
        />
        {errors.from && (
          <span className="invalid-feedback">Fecha inicial es requerida</span>
        )}
      </>
    )
  );
  const DatePickerCustomTo = forwardRef(({ value, onClick, onChange }, ref) => (
    <>
      <input
        className={`form-control form-control-user ${
          errors.to && "is-invalid"
        }`}
        onClick={onClick}
        onChange={onChange}
        ref={ref}
        value={value}
        autoComplete="Off"
        {...register("to", { required: true })}
      />
      {errors.to && (
        <span className="invalid-feedback">Fecha final es requerida</span>
      )}
    </>
  ));
  const handledChangeFrom = (date) => {
    setStartDateFrom(date);
    setValue("from", moment(date).tz("America/Bogota").format("MM-DD-YYYY"));
  };
  const handledChangeTo = (date) => {
    setStartDateTo(date);
    setValue("to", moment(date).tz("America/Bogota").format("MM-DD-YYYY"));
  };

  useEffect(() => {
    reduxGetStatuses({
      page: 1,
      search: "",
      offset: 1000,
    });
    reduxGetCompanies({
      page: 1,
      search: "",
      offset: 1000,
    });
  }, []);

  return (
    <div>
      <div id="wrapper">
        <Sidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Topbar />
            <div className="container-fluid">
              <div className="d-sm-flex align-items-center mb-1">
                <h1 className="h3 mb-0 text-gray-800 mr-3">
                  Reporte de Conciliaciones
                </h1>
              </div>
              <form onSubmit={handleSubmit(download)}>
                <div className="row">
                  <div className="col s1 m1">
                    <div className="form-group">
                      <label htmlFor="from" className="form-label">
                        Desde
                      </label>
                      <div>
                        <DatePicker
                          selected={startDateFrom}
                          onChange={(date) => handledChangeFrom(date)}
                          dateFormat="MM-dd-yyyy"
                          customInput={<DatePickerCustomFrom />}
                          maxDate={new Date()}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col s1 m1">
                    <div className="form-group">
                      <label htmlFor="to" className="form-label">
                        Hasta
                      </label>
                      <div>
                        <DatePicker
                          selected={startDateTo}
                          onChange={(date) => handledChangeTo(date)}
                          dateFormat="MM-dd-yyyy"
                          customInput={<DatePickerCustomTo />}
                          maxDate={new Date()}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col s3 m3">
                    <div className="form-group">
                      <label htmlFor="idClient" className="form-label">
                        Cliente
                      </label>
                      <Select
                        isClearable={true}
                        {...register('idClient')}
                        id='idClient'
                        onChange={(value) => setValue('idClient', value?.value || '')}
                        options=
                        {companies &&
                          Object.keys(companies).length > 0 &&
                          companies.items
                            .map((ele) => ({                                    
                                value: ele.idCompany,
                                label: ele.description,
                              }))
                        }
                      />
                    </div>
                  </div>
                  <div className="col s3 m3">
                    <div className="form-group">
                      <label htmlFor="state" className="form-label">
                        Estado
                      </label>
                      <Select
                        isClearable={true}
                        {...register('state')}
                        id='state'
                        onChange={(value) => setValue('state', value?.value || '')}
                        options=
                        {statuses &&
                          Object.keys(statuses).length > 0 &&
                          statuses.items
                            .map((ele) => ({                                    
                                value: ele.idStatus,
                                label: ele.name,
                              }))
                        }
                      />
                    </div>
                  </div>

                  <div className="col s12 m1">
                    <Button
                      variant="primary"
                      style={{ marginTop: "32px" }}
                      id="btnSearch"
                      type="submit"
                    >
                      Descargar
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    statuses: state.statusesState.statuses,
    companies: state.companyState.companies,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    reduxGetStatuses: (payload) =>
      dispatch({
        type: "FETCH_NEW_STATUS_REQUEST",
        value: payload,
      }),
    reduxGetCompanies: (payload) =>
      dispatch({
        type: "FETCH_COMPANIES_REQUEST",
        value: payload,
      }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConciliationsReport);
