import {applyMiddleware, createStore, compose} from "redux";
import {createLogger} from "redux-logger";
import createSagaMiddleware from "redux-saga";
import {rootReducer} from "../reducers";
import {rootSaga} from "../sagas/index";

const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(sagaMiddleware)),
);

sagaMiddleware.run(rootSaga);

export { store };
