import { useEffect, useState } from "react";
import { connect } from "react-redux";

import { Show } from "../../hooks/Show";

import Grid from "../../components/Grid";
import UpsertAlert from "../../components/Modal/Alerts/upsertAlert";

const AlertsList = ({
    activeTab,
    reduxGetAlerts,
    alerts,
    reduxResetAlertTab,
    reduxUpdateAlert,
}) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [offset, setOffset] = useState(10);
    const [search, setSearch] = useState('');

    const [showUpsertAlertModal, setShowUpsertAlertModal] = useState(false);
    const [upsertAlertData, setUpsertAlertData] = useState(null);

    useEffect(() => {
        if(!activeTab) return;
        
        reduxGetAlerts({
            page: currentPage,
            offset: offset,
            search: search,
        })
    }, [activeTab, currentPage, offset, search, reduxGetAlerts]);

    const handleOpenUpsertModal = (data) => {
        setUpsertAlertData(data)
        setShowUpsertAlertModal(true);
    }

    const handleCloseUpsertModal = () => {
        setUpsertAlertData(null)
        setShowUpsertAlertModal(false);
        reduxResetAlertTab();
        reduxGetAlerts({
            page: currentPage,
            offset: offset,
            search: search,
        })
    }

    const handleChangeState = (data) => {
        const dataObj = {
          idAlert: data.idAlert,
          isActive: !data.isActive
        }
        reduxUpdateAlert(dataObj);
        reduxGetAlerts({
            page: currentPage,
            offset: offset,
            search: search,
        })
      };

    const columns = [
        {
            title: "#",
            render: (rowData) => {
                return <span>{rowData.idAlert}</span>;
            },
        },
        {
            title: "Código",
            render: (rowData) => {
                return <span>{rowData.code}</span>;
            },
            field: "code",
            searchable: true,
        },
        {
            title: "Destinatario",
            render: (rowData) => {
                return <span>{rowData.to}</span>;
            },
            field: "to",
            searchable: true,
        },
        {
            title: "Asunto",
            render: (rowData) => {
                return <span>{rowData.subject}</span>;
            },
            field: "subject",
            searchable: true,
        },
        {
            title: "Tipo",
            render: (rowData) => {
                return <span>{rowData.alertType.code}</span>;
            },
            field: "alertType.code",
            searchable: true,
        },
        {
            title: "Fuente",
            render: (rowData) => {
                return <span>{rowData.alertSource.code}</span>;
            },
            field: "alertSource.code",
            searchable: true,
        },
        {
            title: "Acciones",
            render: (rowData) => {
                return (
                    <>
                        {
                            rowData.isActive ?
                                (
                                    <>
                                        <Show when="feature:alerts-upsert">
                                            <button
                                                title="Editar"
                                                className="btn btn-primary btn-sm btn-circle mr-2"
                                                type="button"
                                                onClick={e => handleOpenUpsertModal(rowData)}
                                            >
                                                <i className="fas fa-edit fa-xs"></i>
                                            </button>
                                        </Show>
                                        <Show when="feature:alerts-disable">
                                            <button
                                                title="Desactivar"
                                                className="btn btn-danger btn-sm btn-circle"
                                                type="button"
                                                onClick={e => handleChangeState(rowData)}
                                            >
                                                <i className="fas fa-times-circle fa-xs"></i>
                                            </button>
                                        </Show>
                                    </>
                                ) : (
                                    <Show when="feature:alerts-enable">
                                        <button
                                            title="Activar"
                                            className="btn btn-primary btn-sm btn-circle mr-2"
                                            type="button"
                                            onClick={e => handleChangeState(rowData)}
                                        >
                                            <i className="fas fa-check-circle fa-xs"></i>
                                        </button>
                                    </Show>
                                )
                        }
                    </>
                );
            },
        },
    ];

    return (
        <>
            <div className="card-body">
                <Show when="feature:alerts-upsert">
                    <div className="pb-3">
                        <button className='btn btn-primary' onClick={() => handleOpenUpsertModal()}>Nueva alerta</button>
                    </div>
                </Show>
                <Grid
                    cols={columns}
                    data={
                        alerts && Object.keys(alerts).length > 0 ? alerts.items : []
                    }
                    page={
                        alerts && Object.keys(alerts).length > 0
                            ? Number(alerts.page)
                            : currentPage
                    }
                    pages={
                        alerts && Object.keys(alerts).length > 0
                            ? Number(alerts.totalPages)
                            : 0
                    }
                    total={
                        alerts && alerts.hasOwnProperty("total") ? alerts.total : 0
                    }
                    offset={offset}
                    onChangePage={(page) => setCurrentPage(page)}
                    onChangeRange={(value) => {
                        setOffset(value);
                        reduxGetAlerts({
                            page: 1,
                            offset: value,
                            search: search,
                        });
                    }}
                    defaultValue={search}
                    onChangeSearch={(value) => {
                        setSearch(value);
                        reduxGetAlerts({
                            page: 1,
                            offset: offset,
                            search: value,
                        });
                    }}
                />
            </div>
            <UpsertAlert show={showUpsertAlertModal} handleClose={handleCloseUpsertModal} alertData={upsertAlertData} />
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        alerts: state.alertState.alerts,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        reduxGetAlerts: (payload) =>
            dispatch({
                type: "FETCH_ALERTS_REQUEST",
                value: payload,
            }),
        reduxResetAlertTab: () =>
            dispatch({
                type: "RESET_ALERT_TAB"
            }),
        reduxUpdateAlert: (payload) =>
            dispatch({
                type: "UPDATE_ALERT_REQUEST",
                value: payload,
            }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AlertsList);