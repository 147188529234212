import React, {useState} from "react";
import Grid from "../../components/Grid";
import { useMemo } from "react";
import formatDateWithCustomFormat, { FormatsDate } from "../../utils/formatDate";
import { useEffect } from "react";
import { Show } from "../../hooks/Show";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { isNullOrUndefindedAndEmpty } from "../../utils/pantallaUtil";
import { useContext } from "react";
import { PermissionContext } from "../../hooks/PermissionContext";
import momentCustom from "../../utils/momentCustomInstance";

const OrdersPendingTableComponent = ({ data, reduxGetOrders, handleConfirmAllOrders, handleConfirm, searchRedux, filterFields, paginate, handleDelete, successfulFetchDeleteOrder }) => {

    const { register, setValue, getValues } = useForm();
    const { hasPermission } = useContext(PermissionContext);

    useEffect(() => {
      let filters = {
        ...filterFields,
        page: paginate.currentPage,
        offset: paginate.offset,
        search: paginate.search,
      };
      searchRedux(filters);
    }, [paginate.currentPage]);

    useEffect(() => {
      if (successfulFetchDeleteOrder) {
        let message = `Guía ${getValues('guideOrQuantityToDelete')} eliminada exitosamente.`;
        if(getValues('deleteAllSelected')){
          message = `${getValues('guideOrQuantityToDelete')} guías eliminadas exitosamente.`;
          setValue('deleteAllSelected', false);
        }
        Swal.fire(
          'Proceso exitoso',
          message,
          'success'
        );
      }
    }, [successfulFetchDeleteOrder]);

    const checkAll = (e) => {
      if (e.target.checked) {
        setValue('ordersChecked', data ? data.items.map((f) => f.order_locked_id.toString()) : []);
      } else {
        setValue('ordersChecked', null);
      }
    };

    const validateDataSelected = () => {
      const filterOrders = data.items
        .map((ele) => ele.order_locked_id);

      let objOrdersChecked = getValues('ordersChecked');
      if(isNullOrUndefindedAndEmpty(objOrdersChecked) || objOrdersChecked.length === 0) {
        Swal.fire(
          'Información',
          'Debe seleccionar una guía',
          'info'
        );
        return false;
      }
      objOrdersChecked = typeof objOrdersChecked == 'string'? [objOrdersChecked] : objOrdersChecked

      return objOrdersChecked.filter((el) => filterOrders.includes(Number(el)));
    }

    const confirmOrdersSelected = (e) => {
      const data = validateDataSelected();
      if (data && data.length > 0) {
        handleConfirmAllOrders({
          ordersChecked: data,
        });
      }
    }

    const handleDeleteOrder = (rowData) => {
      Swal.fire({
          title: 'Confirmación',
          text: `¿Está seguro que desea eliminar la guía ${rowData.address.trackingId}?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#21b5ea',
          cancelButtonColor: '#858796',
          confirmButtonText: 'Sí',
          cancelButtonText: 'No'
      }).then((result) => {
          if (result.isConfirmed) {
              setValue('guideOrQuantityToDelete', rowData.address.trackingId);
              handleDelete({
                  ordersChecked: [rowData.order_locked_id.toString()],
                  page: paginate.currentPage,
                  offset: paginate.offset,
                  search: paginate.search
              })
          }
      });
    }

    const handleDeleteOrdersSelected = (e) => {
      const data = validateDataSelected();
      if (data && data.length > 0) {
        Swal.fire({
            title: 'Confirmación',
            text: `¿Está seguro que desea eliminar ${data.length} guías?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#21b5ea',
            cancelButtonColor: '#858796',
            confirmButtonText: 'Sí',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.isConfirmed) {
                setValue('guideOrQuantityToDelete', data.length);
                setValue('deleteAllSelected', true);
                handleDelete({
                    ordersChecked: data,
                    page: paginate.currentPage,
                    offset: paginate.offset,
                    search: paginate.search
                })
            }
        });
      }
    }

    const columns = useMemo(
        () => [
          {
            title: (rowData) => {
              return (
                <>
                  <div className='dropdown btn btn-light options-btn'>
                    <input
                      type={'checkbox'}
                      onClick={(el) => checkAll(el)}
                      id={'all'}
                      defaultChecked={false}
                      {...register('all')}
                    />
                    <span
                      className='dropdown-toggle ml-1'
                      type='button'
                      id='sampleDropdownMenu'
                      data-bs-toggle='dropdown'></span>
                    <div className='dropdown-menu'>
                      <>
                        <Show when='feature:confirm-orders-locked-pending'>
                          <button
                            className='dropdown-item'
                            type='button'
                            onClick={(e) => confirmOrdersSelected(e)}>
                            Confirmar Bloqueo
                          </button>
                        </Show>

                        <Show when='feature:delete-orders-locked-pending'>
                          <button
                            className='dropdown-item'
                            type='button'
                            onClick={(e) => handleDeleteOrdersSelected(e)}>
                            Eliminar Bloqueo
                          </button>
                        </Show>
                      </>
                    </div>
                  </div>
                </>
              );
            },
            render: (rowData) => {
              return (
                <>
                  <input
                    name='ordersChecked'
                    key={rowData.order_locked_id}
                    type='checkbox'
                    checked={rowData.isChecked}
                    value={rowData.order_locked_id}
                    onChange={(e) => {
                      rowData.isChecked = e.target.checked;
                    }}
                    {...register('ordersChecked')}
                  />
                </>
              );
            },
          },
          {
            title: 'Guía',
            render: (rowData) => {
              return <span>{rowData.address.trackingId}</span>;
            },
            field: 'address.trackingId',
            searchable: true,
          },
          {
            title: 'Id Externo',
            render: (rowData) => {
              return <span>{rowData.address.externalId}</span>;
            },
            field: 'address.externalId',
            searchable: true,
          },
          {
            title: 'Fecha solicitud',
            render: (rowData) => {
              return <span>{momentCustom(rowData.request_date).format('YYYY-MM-DD hh:mm A')}</span>;
            },
            searchable: false,
          },
          {
            title: 'Usuario solicitud',
            searchable: true,
            field: 'userRequest.firstName-lastName',
            render: (rowData) => {
              return <span>{rowData.userRequest.firstName} {rowData.userRequest.lastName}</span>;
            },
          },
          ...(hasPermission('feature:confirm-orders-locked-pending') ? [{
            title: 'Acciones',
            render: (rowData) => {
              return (
                <>
                  <button title="Confirmar" 
                    class="btn btn-primary btn-sm  btn-circle mr-2"
                    type='button'
                    onClick={() => handleConfirm(rowData)}>
                    <i className="fas fa-check-circle"></i>
                  </button>
                  <Show when='feature:delete-orders-locked-pending'>
                    <button title="Eliminar" 
                      class="btn btn-danger btn-sm  btn-circle mr-2"
                      type='button'
                      onClick={() => handleDeleteOrder(rowData)}>
                      <i className="fas fa-trash fa-xs"></i>
                    </button>
                  </Show>
                </>
              );
            },
          }]: []),
        ],
        [data]
    );

    return (
        <Grid
            cols={columns}
            data={data && Object.keys(data).length > 0 ? data.items : []}
            total={data && data.hasOwnProperty('total') ? data.total : 0}
            page={data && Object.keys(data).length > 0 ? Number(data.page) : paginate.currentPage}
            pages={data && Object.keys(data).length > 0 ? Number(data.totalPages) : 0}
            offset={paginate.offset}
            onChangePage={(page) => paginate.setCurrentPage(page)}
            onChangeRange={(value) => {
                paginate.setOffset(value);
                let filters = {
                    page: 1,
                    offset: value,
                    search: paginate.search,
                };
                if (filterFields.startDate && filterFields.endDate) {
                    filters = {
                      ...filters,
                      startDate: formatDateWithCustomFormat(filterFields.startDate, {
                          format: FormatsDate.FULL24,
                      }),
                      endDate: formatDateWithCustomFormat(filterFields.endDate, {
                          format: FormatsDate.FULL24,
                      }),
                    };
                }
                reduxGetOrders(filters);
            }}
            defaultValue={paginate.search}
            onChangeSearch={(value) => {
              paginate.setSearch(value);
              let filters = {
                  page: 1,
                  offset: paginate.offset,
                  search: value,
              };
              if (filterFields.startDate && filterFields.endDate) {
                  filters = {
                    ...filters,
                    startDate: formatDateWithCustomFormat(filterFields.startDate, {
                        format: FormatsDate.FULL24,
                    }),
                    endDate: formatDateWithCustomFormat(filterFields.endDate, {
                        format: FormatsDate.FULL24,
                    }),
                  };
              }
              reduxGetOrders(filters);
            }}
        />
    )
}

export default OrdersPendingTableComponent;