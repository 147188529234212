const initialState = {
    errorOrigins: false,
    requestingOrigins: false,
    successfulOrigins: false,
    errorSaveOrigins: false,
    requestingSaveOrigins: false,
    successfulSaveOrigins: false,
    origins: null,
  };
  
  const originReducer = (state = initialState, action) => {
    switch (action.type) {
      case "FETCH_ORIGINS_REQUESTING":
        return {
          ...state,
          requestingOrigins: true,
          successfulOrigins: false,
          errorsOrigins: false,
        };
      case "FETCH_ORIGINS_SUCCESS":
        return {
          ...state,
          errorOrigins: false,
          requestingOrigins: false,
          successfulOrigins: true,
          origins: action.value,
        };
      case "FETCH_ORIGINS_ERROR":
        return {
          ...state,
          errorOrigins: true,
          requestingOrigins: false,
          successfulOrigins: false,
        };
      case "FETCH_SAVE_ORIGINS_REQUESTING":
        return {
          ...state,
          requestingSaveOrigins: true,
          successfulSaveOrigins: false,
          errorsSaveOrigins: false,
        };
      case "FETCH_SAVE_ORIGINS_SUCCESS":
        return {
          ...state,
          errorOrigins: false,
          requestingSaveOrigins: false,
          successfulSaveOrigins: true,
        };
      case "FETCH_SAVE_ORIGINS_ERROR":
        return {
          ...state,
          errorSaveOrigins: true,
          requestingSaveOrigins: false,
          successfulSaveOrigins: false,
        };
      case "FETCH_FINISH_SAVE_ORIGINS":
        return {
          ...state,
          errorSaveOrigins: false,
          requestingSaveOrigins: false,
          successfulSaveOrigins: false,
        };
      case "RESET":
        return initialState;
      default:
        return state;
    }
  };
  
  export default originReducer;  