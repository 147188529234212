export const ConvertToMoney = (value) => {
    if(isNaN(value)){
        return value;
    }else{
        return Intl.NumberFormat('es-co', {
            style: 'currency',
            currency: 'COP',
            currencyDisplay: 'narrowSymbol',
        }).format(value);
    }
}