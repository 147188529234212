const initialState = {
    requestingUpdateMasivRescheduling: false,
    successfulUpdateMasivRescheduling: false,
    errorsUpdateMasivRescheduling: false,
    masivGuidesUpdated: null,
    
    requestingUploadRescheduling: false,
    successfulUploadRescheduling: false,
    errorsUploadRescheduling: false,
    masivGuidesUploaded: null,
};

const reschedulingReducer = (state = initialState, action) => {
    switch (action.type) {
        case "UPDATE_MASIV_RESCHEDULING_REQUESTING":
            return {
                ...state,
                requestingUpdateMasivRescheduling: true,
                successfulUpdateMasivRescheduling: false,
                errorsUpdateMasivRescheduling: false,
            };
        case "UPDATE_MASIV_RESCHEDULING_SUCCESS":
            return {
                ...state,
                requestingUpdateMasivRescheduling: false,
                successfulUpdateMasivRescheduling: true,
                errorsUpdateMasivRescheduling: false,
                masivGuidesUpdated: action.value,
            };
        case "UPDATE_MASIV_RESCHEDULING_ERROR":
            return {
                ...state,
                requestingUpdateMasivRescheduling: false,
                successfulUpdateMasivRescheduling: false,
                errorsUpdateMasivRescheduling: true,
            };
        case "UPDATE_MASIV_RESCHEDULING_DONE":
            return {
                ...state,
                requestingUpdateMasivRescheduling: false,
                successfulUpdateMasivRescheduling: false,
                errorsUpdateMasivRescheduling: false,
                masivGuidesUpdated: null,
            };
        case "UPLOAD_MASIV_RESCHEDULE_REQUESTING":
            return {
                ...state,
                requestingUploadRescheduling: true,
                successfulUploadRescheduling: false,
                errorsUploadRescheduling: false,
            };
        case "UPLOAD_MASIV_RESCHEDULE_SUCCESS":
            return {
                ...state,
                requestingUploadRescheduling: false,
                successfulUploadRescheduling: true,
                errorsUploadRescheduling: false,
                masivGuidesUploaded: action.value,
            };
        case "UPLOAD_MASIV_RESCHEDULE_ERROR":
            return {
                ...state,
                requestingUploadRescheduling: false,
                successfulUploadRescheduling: false,
                errorsUploadRescheduling: true,
                masivGuidesUploaded: null,
            };
        case "UPLOAD_MASIV_RESCHEDULE_DONE":
            return {
                ...state,
                requestingUploadRescheduling: false,
                successfulUploadRescheduling: false,
                errorsUploadRescheduling: false,
                masivGuidesUploaded: null,
            };
        default:
            return state;
    }
};

export default reschedulingReducer;  