import React, {useCallback, useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {connect} from "react-redux";
import {Button, Image, ProgressBar} from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import { useParams } from "react-router-dom";
import SteperComponent from "./steperComponent";
import { Avatar, AvatarOpen, Company, Hand1, Hand2, Pointer, Face, Paper, Phone} from '../../utils/icons'

const Swal = require("sweetalert2");

const Tracking = ({
  reduxGetRouteByGuide,
  routeByGuide,
  errorsReadRouteByGuide,
}) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
    setValue,
  } = useForm();
  const [showDetail, setShowDetail] = useState(false);
  const [validateRecaptcha, setValidateRecaptcha] = useState(false);
  const [showForm, setShowForm] = useState(true);
  const { idTracking } = useParams();
  const onSubmit = (data) => {
    if (validateRecaptcha) {
      reduxGetRouteByGuide(data);
    }
  };

  useEffect(() => {
    reset();
    if (idTracking) {
      setValue('trackingId',idTracking.toString())
      setShowForm(false)
      reduxGetRouteByGuide({trackingId:idTracking.toString()})
    } else {
      reset();
      setShowForm(true)
    }

  }, []);

  useEffect(() => {
    if (routeByGuide) {
      setShowDetail(true);
    }
  }, [routeByGuide]);

  useEffect(() => {
    if (errorsReadRouteByGuide) {
      setShowDetail(false);
      Swal.fire(
        "Aviso",
        "No se pudo encontrar la guia con la información ingresada, por favor intente nuevamente.",
        "Info"
      );
    }
  }, [errorsReadRouteByGuide]);

  const render = useCallback(() => {
    let now;
    let stateName;
    switch (routeByGuide.state) {
      case 2:
        now = 0;
        stateName = "Asignada a un operador";
        break;
      case 5:
        now = 1;
        stateName = "Asignada a un mensajero";
        break;
      case 6:
        now = 2;
        stateName = "En ruta/distribución";
        break;
      case 7:
        now = 3;
        stateName = "Entregado";
        break;
      case 8:
        now = 4;
        stateName = "No entregado";
        break;
      case 9:
        now = 5;
        stateName = "Devolucion en transito";
        break;
      default:
        now = 6;
        stateName = "Sin Asignar";
        break;
    }
    return (
      <div className="container-fluid p-0 overflow-hidden">
       <div className={'card'} style={{boxShadow:'7px 7px 37px -13px rgba(0, 0, 0, 0.25)',border: "none",  borderRadius: '5px'}}>
         <div className="row no-gutters">
           <div className={"col-md-12"}>
             <SteperComponent activeStep={now}/>
             <div className={'container'}>
               <hr className="dropdown-divider"/>
             </div>
           </div>
           <div className={'row'} style={{ margin: "40px" }}>
             <Item label={routeByGuide.firstName + ' ' + routeByGuide.lastName || '' } filedName={'Nombre'} icon={Avatar}/>
             <Item label={routeByGuide.trackingId} filedName={'Guia del cliente'} icon={Paper}/>
             <Item label={routeByGuide.reference1} filedName={'Referencia 1'} icon={Phone}/>
             <Item label={routeByGuide.reference2} filedName={'Referencia 2'} icon={Face}/>
             <Item label={routeByGuide.collection || 0} filedName={'Valor a Recaudar'} icon={Hand1}/>
             <Item label={routeByGuide.company.description} filedName={'Remitente'} icon={AvatarOpen}/>
             <Item label={!!routeByGuide.city ? routeByGuide.city.description : ''} filedName={'Ciudad'} icon={Company}/>
             <Item label={routeByGuide.address} filedName={'Dirección'} icon={Pointer} classN={'col-7'}/>
           </div>
         </div>
       </div>
      </div>
    );
  },[routeByGuide]);

  const onChange = (value) => {
    setValidateRecaptcha(true);
  };

  return (
    <div>
      <div id="wrapper d-flex flex-column">
        <ul
          className={"navbar navbar-expand-lg navbar-light bg-default"}
        >
          <a
            className="sidebar-brand d-flex align-items-center justify-content-center"
            href="index.html"
          >
            <img
              height="60"
              className="img-responsive sidebar-brand-image"
              src="https://app.kikilogistics.co/logorgb_kiki.svg"
            />
          </a>
        </ul>
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            {/* <Topbar /> */}
            <div className="container-fluid">
              <div
                className="d-sm-flex align-items-center mb-1"
                style={{ marginTop: "50px", marginLeft: "20px" }}
              >
                <h1 className="h3 mb-0 text-gray-800 mr-3">Rastreo</h1>
              </div>
              { showForm && (
                  <div style={{ textAlign: "center" }}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="row" style={{ margin: "20px" }}>
                        {/* <div className="form-group"> */}
                        <label htmlFor="trackingId" className="form-label">
                          Número de guía
                        </label>
                        <input
                            id="trackingId"
                            type="text"
                            className={`form-control form-control-user ${errors.trackingId && "is-invalid"
                            }`}
                            style={{
                              width: "300px",
                              marginLeft: "10px",
                              marginRight: "10px",
                            }}
                            {...register("trackingId", { required: true })}
                            onChange={() => setShowDetail(false)}
                        />
                        {errors.trackingId && (
                            <span className="invalid-feedback">
                        El número de guía es requerido
                      </span>
                        )}

                        {/* </div> */}
                        <Button
                            variant="primary"
                            type="submit"
                            disabled={!validateRecaptcha}
                        >
                          Buscar
                        </Button>
                      </div>
                      <div className="col-md-12">
                        <ReCAPTCHA
                            sitekey="6LfuwlodAAAAAPcbOpwtO1vaFbCexiRfaXvduASC"
                            onChange={onChange}
                        />
                      </div>
                    </form>
                  </div>
              )}
              {showDetail && <div className="card-body">{render()}</div>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    routeByGuide: state.routeState.routeByGuide,
    errorsReadRouteByGuide: state.routeState.errorsReadRouteByGuide,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    reduxGetRouteByGuide: (payload) =>
      dispatch({
        type: "READBYGUIDE_ROUTE_REQUEST",
        value: payload,
      }),
  };
};

const Item = ({label, icon, filedName, classN}) => {
  return (
   <div className={classN || ' mb-3 item-content'}  >
     <div className={'row item-tracking align-items-center'}>
       <div className={'col-auto'}>
         <Image src={icon}/>
       </div>
       <div style={{lineHeight:'15px'}} className={classN ? 'label-content col-10' : 'label-content col-auto'}>
         <label>{filedName}: </label>
         <p>{label}</p>
       </div>
     </div>
   </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Tracking);
