import React, {useState, useRef, useEffect} from "react";
import {useForm,  FormProvider } from "react-hook-form";
import Sidebar from "../../components/Navigation/Sidebar";
import Topbar from "../../components/Navigation/Topbar";
import {connect} from "react-redux";
import {addressRoadTypeActions} from "../../redux/reducers/addressRoadTypeReducer";
import {addressInsideTypeActions} from "../../redux/reducers/addressInsideTypeReducer";
import {addressNomenclatureActions} from "../../redux/reducers/addressNomenclatureReducer";
import InputList, {SelectConstEnabled} from "../../components/Common/inputList";
import {OverlayTrigger, Popover} from "react-bootstrap";
import {GoogleMap, Marker, useLoadScript,} from "@react-google-maps/api";
import Select from 'react-select'
import {isNullOrUndefined} from "../../utils/functionsUtils"

const AddressGenerator = ({
    reduxGetNeighborhoodsByCity,
    neighborhoodsByCity,
    reduxGetDepartmentsByCountry,
    departmentsByCountry,
    reduxGetCitiesByDepartment,
    citiesByDepartment,
    countries,
    reduxGetCountries,
    reduxSetDepartment,
    reduxSetCity,
    reduxSetNeighborhood,
    getAllListAditionalAddress,
    reduxGetAddressInsideTypes,
    reduxGetAddressNomenclatures,
    reduxGetAddressRoadTypes,
    reduxGetAddressCardinals
}) => {

    const methods = useForm()
    const [address, setAddress] = useState("");
    const [cityFound, setCityFound] = useState("");
    const [countryFound, setCountryFound] = useState("");
    const [departmentFound, setDepartmentFound] = useState("");
    const [neighborhoodFound, setNeighborhoodFound] = useState("");
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);

    const {isLoaded} = useLoadScript({
        googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_MAPS_KEY}`,
    });

    const [mapRef, setMapRef] = useState(null);
    const [center, setCenter] = useState({lat: 44.076613, lng: -98.362239833});
    const [zoom, setZoom] = useState(3);

    const popover = (
        <Popover id="popover-basic">
            <Popover.Title as="h3">Ayuda</Popover.Title>
            <Popover.Content>
                A continuación el detalle de una <strong>dirección</strong> generada.
                <br></br>
                SECTOR 67BG OESTE #89BF OESTE-34 SOTANO 102, SAN JOSÉ, MEDELLIN,
                ANTIOQUIA, COLOMBIA
            </Popover.Content>
        </Popover>
    );

    const indicativeRoad = useRef({});
    indicativeRoad.current = methods.watch("indicativeRoad", "");

    const roadNumber = useRef({});
    roadNumber.current = methods.watch("roadNumber", "");

    const appendixRoad = useRef({});
    appendixRoad.current = methods.watch("appendixRoad", "");

    const crossoverRoad = useRef({});
    crossoverRoad.current = methods.watch("crossoverRoad", "");

    const crossoverNumber = useRef({});
    crossoverNumber.current = methods.watch("crossoverNumber", "");

    const appendixCrossingNumber = useRef({});
    appendixCrossingNumber.current = methods.watch("appendixCrossingNumber", "");

    const orientationCrossingNumber = useRef({});
    orientationCrossingNumber.current = methods.watch("orientationCrossingNumber", "");

    const insideTypes = useRef({});
    insideTypes.current = methods.watch("insideTypes", "");

    const doorNumber = useRef({});
    doorNumber.current = methods.watch("doorNumber", "");

    const inside = useRef({});
    inside.current = methods.watch("inside", "");

    const city = useRef({});
    city.current = methods.watch("city", "");

    const neighborhood = useRef({});
    neighborhood.current = methods.watch("neighborhood", "");

    const country = useRef({});
    country.current = methods.watch("country", "");

    const department = useRef({});
    department.current = methods.watch("department", "");

    const loadLIsts = () => {
        let perpage = {
            page: 1,
            offset: 1000,
            search: "",
        }
        reduxGetCountries(perpage);
        getAllListAditionalAddress(perpage)
        reduxGetAddressInsideTypes(perpage)
        reduxGetAddressNomenclatures(perpage)
    }

    useEffect(() => {
        loadLIsts();
    }, []);

    useEffect(() => {
        if (!isNullOrUndefined(country.current)) {
            methods.setValue("department", {value: "0", label: "Seleccionar"});
            methods.setValue("city", {value: "0", label: "Seleccionar"});
            methods.setValue("neighborhood", {value: "0", label: "Seleccionar"});
            reduxSetDepartment();
            reduxSetCity();
            reduxSetNeighborhood();
            const countrySelected =
                countries &&
                Object.keys(countries).length > 0 &&
                countries.items.find((value) => value.idCountry == country.current);
            setCountryFound(countrySelected ? countrySelected.description : "");
            reduxGetDepartmentsByCountry({
                idCountry: country.current,
            });
        } else {            
            methods.setValue("department", {value: "0", label: "Seleccionar"});
            methods.setValue("city", {value: "0", label: "Seleccionar"});
            methods.setValue("neighborhood", {value: "0", label: "Seleccionar"});
        }
    }, [country.current]);

    useEffect(() => {
        if (department.current != "") {
            methods.setValue("city", {value: "0", label: "Seleccionar"});
            methods.setValue("neighborhood", {value: "0", label: "Seleccionar"});
            reduxSetCity();
            reduxSetNeighborhood();
            const departmentSelected =
                departmentsByCountry &&
                Object.keys(departmentsByCountry).length > 0 &&
                departmentsByCountry.find(
                    (value) => value.idDepartment == department.current.value
                );
            setDepartmentFound(
                departmentSelected ? departmentSelected.description : ""
            );
            reduxGetCitiesByDepartment({
                idDepartment: department.current.value,
            });
        } else {            
            methods.setValue("city", {value: "0", label: "Seleccionar"});
            methods.setValue("neighborhood", {value: "0", label: "Seleccionar"});
        }
    }, [department.current]);

    useEffect(() => {
        if (city.current != "") {
            methods.setValue("neighborhood", {value: "0", label: "Seleccionar"});
            reduxSetNeighborhood();
            const citySelected =
                citiesByDepartment &&
                Object.keys(citiesByDepartment).length > 0 &&
                citiesByDepartment.find((value) => value.idCity == city.current.value);
            setCityFound(citySelected ? citySelected.description : "");
            reduxGetNeighborhoodsByCity({
                idCity: city.current.value,
            });
        } else {            
            methods.setValue("neighborhood", {value: "0", label: "Seleccionar"});
        }
    }, [city.current]);

    useEffect(() => {
        if (neighborhood.current !== "") {
            const neighborhoodSelected =
                neighborhoodsByCity &&
                Object.keys(neighborhoodsByCity).length > 0 &&
                neighborhoodsByCity.find((value) => value.idNeighborhood == neighborhood.current.value);
            setNeighborhoodFound(neighborhoodSelected ? neighborhoodSelected.description : "");
        } else {            
            methods.setValue("neighborhood", {value: "0", label: "Seleccionar"});
        }
    }, [neighborhood.current]);

    const loadHandler = (map) => {
        setMapRef(map);
    };


    const renderMap = () => {
        return (
            <GoogleMap
                onLoad={loadHandler}
                center={center}
                zoom={zoom}
                mapContainerStyle={{
                    height: "400px",
                    width: "100%",
                }}
            >
                {
                    latitude != null && longitude != null && (
                        <Marker
                            key={'key'}
                            position={{
                                lat: latitude,
                                lng: longitude,
                            }}
                        />
                    )
                }
            </GoogleMap>
        );
    };

    useEffect(() => {
        setAddress(
            `${indicativeRoad.current == undefined ? '' : indicativeRoad.current} ${roadNumber.current && roadNumber.current}${appendixRoad.current== undefined ? '' :appendixRoad.current} ${
                crossoverRoad.current == undefined ? '' : crossoverRoad.current
            } ${crossoverNumber.current ? "#" : ""}${crossoverNumber.current}${
                appendixCrossingNumber.current == undefined ? '' : appendixCrossingNumber.current
            }${orientationCrossingNumber.current ? " " : ""}${
                orientationCrossingNumber.current == undefined ? '' : orientationCrossingNumber.current
            }${doorNumber.current ? "-" : ""}${doorNumber.current}${
                insideTypes.current ? " " + insideTypes.current + " " : ""
            }${inside.current}${neighborhood.current ? (neighborhood.current.value !== '0' ? ", "+ neighborhood.current.label : '')  : ""
            }${cityFound ? ", " + cityFound : ""}${
                departmentFound ? ", " + departmentFound : ""
            }${countryFound ? ", " + countryFound : ""}`
        );
    }, [
        indicativeRoad.current,
        roadNumber.current,
        appendixRoad.current,
        crossoverRoad.current,
        crossoverNumber.current,
        appendixCrossingNumber.current,
        orientationCrossingNumber.current,
        doorNumber.current,
        insideTypes.current,
        neighborhood.current,
        city.current,
        department.current,
        country.current,
        cityFound,
        departmentFound,
        countryFound,
        neighborhood,
    ]);

    useEffect(() => {
        updateCoordinates();
    }, [address])

    const updateCoordinates = () => {
        const encodedAddress = encodeURIComponent(address)
        const request = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodedAddress}&key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}`;
        fetch(request, {
            "method": "GET",
        })
            .then(response => response.json())
            .then(response => {
                if (response.status === 'OK') {
                    const {lat, lng} = response.results[0].geometry.location;
                    setLatitude(lat)
                    setLongitude(lng)
                    setCenter({lat, lng});
                    const bounds = new window.google.maps.LatLngBounds();
                    bounds.extend(
                        new window.google.maps.LatLng(lat, lng)
                    );
                    if (mapRef != null) {
                        mapRef.fitBounds(bounds);
                    }
                }
            })
            .catch(err => console.log(err))
    }

    return (
        <div>
            <div id="wrapper">
                <Sidebar/>
                <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <Topbar/>
                        <div className="container-fluid">
                            <div className="d-sm-flex align-items-center mb-1">
                                <h1 className="h3 mb-0 text-gray-800 mr-3">
                                    Generador de Direcciones
                                </h1>
                            </div>
                            <p className="mb-4">Módulo Generador de direcciones</p>
                            <div className="card shadow mb-4">
                                <div
                                    className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                    <h6 className="m-0 font-weight-bold text-primary">
                                        Generador
                                    </h6>
                                    <OverlayTrigger
                                        trigger="click"
                                        placement="left"
                                        overlay={popover}
                                    >
                                        <button
                                            className="btn btn-primary btn-circle btn-sm"
                                            type="button"
                                        >
                                            <i className="fas fa-info fa-sm"></i>
                                        </button>
                                    </OverlayTrigger>
                                </div>
                                <div className="card-body">
                                    <FormProvider {...methods} >
                                        <form onSubmit={methods.handleSubmit(updateCoordinates) } className="container-fluid">
                                            {isLoaded ? renderMap() : null}
                                            <div className="row">
                                                <div className="form-group col-md-12">
                                                    <label htmlFor="document" className="form-label">
                                                        Dirección
                                                    </label>
                                                    <input
                                                        {...methods.register("address", {required: true})}
                                                        id="address"
                                                        type="text"
                                                        className={`form-control`}
                                                        value={address}
                                                        disabled={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="mb-2">
                                                <h6 className="m-0 font-weight-bold text-primary">Vía</h6>
                                            </div>
                                            <div
                                                className="row"
                                                style={{
                                                    border: "1px solid #e3e6f0",
                                                    borderRadius: "0.35rem ",
                                                }}
                                            >
                                                <div className="form-group col-md-3">
                                                    <label htmlFor="document" className="form-label">
                                                        Indicativo de la vía
                                                    </label>
                                                    
                                                    <InputList id={'indicativeRoad'} type={SelectConstEnabled._addressRoadType}></InputList>
                                                </div>
                                                <div className="form-group col-md-3">
                                                    <label htmlFor="document" className="form-label">
                                                        Numero de la vía
                                                    </label>
                                                    <input
                                                        {...methods.register("roadNumber", {required: true})}
                                                        id="roadNumber"
                                                        type="text"
                                                        className={`form-control`}
                                                    />
                                                </div>
                                                <div className="form-group col-md-3">
                                                    <label htmlFor="document" className="form-label">
                                                        Apéndice
                                                    </label>
                                                                                                        
                                                    <InputList id={'appendixRoad'} type={SelectConstEnabled._addressNomenclature}></InputList>
                                                </div>
                                                <div className="form-group col-md-3">
                                                    <label htmlFor="document" className="form-label">
                                                        Orientación
                                                    </label>
                                                    <InputList id={'crossoverRoad'} type={SelectConstEnabled._addressCardinal}></InputList>
                                                </div>
                                            </div>
                                            <div className="mb-2 mt-2">
                                                <h6 className="m-0 font-weight-bold text-primary">
                                                    Cruce
                                                </h6>
                                            </div>
                                            <div
                                                className="row"
                                                style={{
                                                    border: "1px solid #e3e6f0",
                                                    borderRadius: "0.35rem ",
                                                }}
                                            >
                                                <div className="form-group col-md-3">
                                                    <label htmlFor="document" className="form-label">
                                                        Numero de cruce
                                                    </label>
                                                    <input
                                                        {...methods.register("crossoverNumber", {required: true})}
                                                        id="crossoverNumber"
                                                        type="text"
                                                        className={`form-control`}
                                                    />
                                                </div>
                                                <div className="form-group col-md-3">
                                                    <label htmlFor="document" className="form-label">
                                                        Apéndice
                                                    </label>
                                                    <InputList id={'appendixCrossingNumber'} type={SelectConstEnabled._addressNomenclature}></InputList>
                                                </div>

                                                <div className="form-group col-md-3">
                                                    <label htmlFor="document" className="form-label">
                                                        Orientación
                                                    </label>
                                                    <InputList id={'orientationCrossingNumber'} type={SelectConstEnabled._addressCardinal}></InputList>

                                                </div>
                                                <div className="form-group col-md-3">
                                                    <label htmlFor="document" className="form-label">
                                                        Numero de la puerta
                                                    </label>
                                                    <input
                                                        {...methods.register("doorNumber", {required: true})}
                                                        id="doorNumber"
                                                        type="text"
                                                        className={`form-control`}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="form-group col-md-3">
                                                    <label htmlFor="insideTypes" className="form-label">
                                                        Tipos de Interior
                                                    </label>
                                                    <InputList id={'insideTypes'} type={SelectConstEnabled._addressInsideType}></InputList>
                                                </div>
                                                <div className="form-group col-md-3">
                                                    <label htmlFor="inside" className="form-label">
                                                        Interior
                                                    </label>
                                                    <input
                                                        {...methods.register("inside", {required: true})}
                                                        id="inside"
                                                        type="text"
                                                        className={`form-control`}
                                                    />
                                                </div>
                                                <div className="form-group col-md-3">
                                                    <label htmlFor="country" className="form-label">
                                                        Pais
                                                    </label>
                                                    <Select
                                                        isClearable={true}
                                                        {...methods.register("country", {required: true})}
                                                        id='country'
                                                        onChange={(value) => methods.setValue('country', value?.value || '')}
                                                        options={
                                                        countries && Object.keys(countries).length > 0
                                                            ? [
                                                            ...countries.items
                                                            .map((ele) => ({                                    
                                                                value: ele.idCountry,
                                                                label: ele.description,
                                                            })),
                                                            ]
                                                            : []
                                                        }
                                                    />
                                                </div>
                                                <div className="form-group col-md-3">
                                                    <label htmlFor="department" className="form-label">
                                                        Departamento
                                                    </label>
                                                    <Select
                                                        isClearable={true}
                                                        {...methods.register("department", {required: true})}
                                                        id='department'
                                                        value={methods.getValues("department")}
                                                        onChange={(value) => methods.setValue('department', {value: value?.value, label: value?.label})}
                                                        options={
                                                        departmentsByCountry && Object.keys(departmentsByCountry).length > 0
                                                            ? [
                                                            ...departmentsByCountry
                                                            .map((ele) => ({                                    
                                                                value: ele.idDepartment,
                                                                label: ele.description,
                                                            })),
                                                            ]
                                                            : []
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="form-group col-md-3">
                                                    <label htmlFor="city" className="form-label">
                                                        Ciudad
                                                    </label>
                                                    <Select
                                                        isClearable={true}
                                                        {...methods.register("city", {required: true})}
                                                        value={methods.getValues("city")}
                                                        id='city'
                                                        onChange={(value) => methods.setValue('city', {value: value?.value, label: value?.label})}
                                                        options={
                                                        citiesByDepartment && Object.keys(citiesByDepartment).length > 0
                                                            ? [
                                                            ...citiesByDepartment
                                                            .map((ele) => ({                                    
                                                                value: ele.idCity,
                                                                label: ele.description,
                                                            })),
                                                            ]
                                                            : []
                                                        }
                                                    />
                                                </div>
                                                <div className="form-group col-md-3">
                                                    <label htmlFor="neighborhood" className="form-label">
                                                        Barrio
                                                    </label>                                                    
                                                    <Select
                                                        isClearable={true}
                                                        {...methods.register("neighborhood", {required: true})}
                                                        id='neighborhood'
                                                        value={methods.getValues("neighborhood")}
                                                        onChange={(value) => methods.setValue('neighborhood', {value: value?.value, label: value?.label})}
                                                        options={
                                                        neighborhoodsByCity && Object.keys(neighborhoodsByCity).length > 0
                                                            ? [
                                                            ...neighborhoodsByCity
                                                            .map((ele) => ({                                    
                                                                value: ele.idNeighborhood,
                                                                label: ele.description,
                                                            })),
                                                            ]
                                                            : []
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </form>

                                    </FormProvider>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        countries: state.countryState.countries,
        neighborhoodsByCity: state.neighborhoodState.neighborhoodsByCity,
        departmentsByCountry: state.departmentState.departmentsByCountry,
        citiesByDepartment: state.cityState.citiesByDepartment,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        reduxGetCountries: (payload) =>
            dispatch({
                type: "FETCH_COUNTRIES_REQUEST",
                value: payload,
            }),
        reduxGetDepartmentsByCountry: (payload) =>
            dispatch({
                type: "READBYCOUNTRY_DEPARTMENT_REQUEST",
                value: payload,
            }),
        reduxGetCitiesByDepartment: (payload) =>
            dispatch({
                type: "READBYDEPARTMENT_CITY_REQUEST",
                value: payload,
            }),
        reduxGetNeighborhoodsByCity: (payload) =>
            dispatch({
                type: "READBYCITY_NEIGHBORHOOD_REQUEST",
                value: payload,
            }),
        reduxSetDepartment: (payload) =>
            dispatch({
                type: "RESET_BYCOUNTRY_DEPARTMENT",
                value: payload,
            }),
        reduxSetCity: (payload) =>
            dispatch({
                type: "RESET_BYDEPARTMENT_CITY",
                value: payload,
            }),
        reduxSetNeighborhood: (payload) =>
            dispatch({
                type: "RESET_BYCITY_NEIGHBORHOOD",
                value: payload,
            }),
        getAllListAditionalAddress: (payload) =>
            dispatch({
                type: addressRoadTypeActions.FETCH_ADDRESSROADTYPE_REQUEST,
                value:payload
            }),
        reduxGetAddressInsideTypes: (payload) =>
            dispatch({
                type: addressInsideTypeActions.FETCH_ADDRESSINSIDETYPE_REQUEST,
                value:payload
            }),
        reduxGetAddressNomenclatures: (payload) =>
            dispatch({
                type: addressNomenclatureActions.FETCH_ADDRESSNOMENCLATURE_REQUEST,
                value:payload
            }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddressGenerator);
