import React, {useRef, useState} from "react";




export default function  SearchTable({onChangeSearch, columns, handleFilterValueChange, filterEnabled, filterValue}){

    const inputRef = useRef(null);
    const makeOptions = () => {
        let dataFiltre = columns.filter(col => Boolean(col.searchable));
        return dataFiltre.map((col, index) => (
            <option key={index} value={col.field}>{col.title || col.header}</option>
        ))
    }

    return (
        <div className="d-flex align-items-center h-100">
            <label>Buscar por:</label>
            <label className="my-0 mx-1">
                <select className="form-control"  onChange={e => {
                    onChangeSearch(e);
                    inputRef.current.focus();
                }}>
                        <option value={''}>Seleccionar</option>
                    {makeOptions()}
                </select>
            </label>
            <label className="my-0 mr-1">
                {
                   <input ref={inputRef} className="form-control" type="text" disabled={filterEnabled} onChange={handleFilterValueChange} value={filterValue} />
                }
            </label>
        </div>
    )
}