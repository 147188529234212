

const initialState = {
    addressRoadTypes:{},
    fetching_address:false,
    requestingFetchAddressRoadType: false,
    successfulFetchAddressRoadType: false,
    errorFetchAddressRoadType: false,
    requestingCreateAddressRoadType: false,
    successfulCreateAddressRoadType: false,
    errorsCreateAddressRoadType: false,
    addressRoadType: null,
    requestingReadAddressRoadType: false,
    successfulReadAddressRoadType: false,
    errorsReadAddressRoadType: false,
    rowEdited: null,
    requestingDeleteAddressRoadType: false,
    successfulDeleteAddressRoadType: false,
    errorsDeleteAddressRoadType: false,
    rowDeleted: null,
    requestingUpdateAddressRoadType: false,
    successfulUpdateAddressRoadType: false,
    errorsUpdateAddressRoadType: false,
    rowUpdated: null,
}

const addressRoadTypeReducer = (state = initialState, payload) => {
    switch (payload.type) {
        case addressRoadTypeActions.FECTCHIN_ALL_ADDRESSROADTYPE:
            return {
                ...state,
                fetching_address: payload.value
            };
        case addressRoadTypeActions.FETCH_ADDRESSROADTYPE_SUCCESS:
            return {
                ...state,
                errorFetchAddressRoadType: false,
                requestingFetchAddressRoadType: false,
                successfulFetchAddressRoadType: true,
                addressRoadTypes: payload.value,
            };
        case addressRoadTypeActions.FETCH_ADDRESSROADTYPE_ERROR:
            return {
                ...state,
                errorFetchAddressRoadType: true,
                requestingFetchAddressRoadType: false,
                successfulFetchAddressRoadType: false,
            };
        case addressRoadTypeActions.CREATE_ADDRESSROADTYPE_REQUESTING:
            return {
                ...state,
                requestingCreateAddressRoadType: true,
                successfulCreateAddressRoadType: false,
                errorsCreateAddressRoadType: false,
            };
        case addressRoadTypeActions.CREATE_ADDRESSROADTYPE_SUCCESS:
            return {
                ...state,
                errorsCreateAddressRoadType: false,
                requestingCreateAddressRoadType: false,
                successfulCreateAddressRoadType: true,
                addressRoadType: payload.value,
            };
        case addressRoadTypeActions.CREATE_ADDRESSROADTYPE_ERROR:
            return {
                ...state,
                errorsCreateAddressRoadType: true,
                requestingCreateAddressRoadType: false,
                successfulCreateAddressRoadType: false,
            };
        case addressRoadTypeActions.READ_ADDRESSROADTYPE_REQUESTING:
            return {
                ...state,
                requestingReadAddressRoadType: true,
                successfulReadAddressRoadType: false,
                errorsReadAddressRoadType: false,
            };
        case addressRoadTypeActions.READ_ADDRESSROADTYPE_SUCCESS:
            return {
                ...state,
                errorsReadAddressRoadType: false,
                requestingReadAddressRoadType: false,
                successfulReadAddressRoadType: true,
                rowEdited: payload.value,
            };
        case addressRoadTypeActions.READ_ADDRESSROADTYPE_ERROR:
            return {
                ...state,
                errorsReadAddressRoadType: true,
                requestingReadAddressRoadType: false,
                successfulReadAddressRoadType: false,
            };
        case addressRoadTypeActions.DELETE_ADDRESSROADTYPE_REQUESTING:
            return {
                ...state,
                requestingDeleteAddressRoadType: true,
                successfulDeleteAddressRoadType: false,
                errorsDeleteAddressRoadType: false,
            };
        case addressRoadTypeActions.DELETE_ADDRESSROADTYPE_SUCCESS:
            return {
                ...state,
                errorsDeleteAddressRoadType: false,
                requestingDeleteAddressRoadType: false,
                successfulDeleteAddressRoadType: true,
                rowDeleted: payload.value,
            };
        case addressRoadTypeActions.DELETE_ADDRESSROADTYPE_ERROR:
            return {
                ...state,
                errorsDeleteAddressRoadType: true,
                requestingDeleteAddressRoadType: false,
                successfulDeleteAddressRoadType: false,
            };
        case addressRoadTypeActions.UPDATE_ADDRESSROADTYPE_REQUESTING:
            return {
                ...state,
                requestingUpdateAddressRoadType: true,
                successfulUpdateAddressRoadType: false,
                errorsUpdateAddressRoadType: false,
            };
        case addressRoadTypeActions.UPDATE_ADDRESSROADTYPE_SUCCESS:
            return {
                ...state,
                errorsUpdateAddressRoadType: false,
                requestingUpdateAddressRoadType: false,
                successfulUpdateAddressRoadType: true,
                rowUpdated: payload.value,
            };
        case addressRoadTypeActions.UPDATE_UPDATE_ADDRESSROADTYPE_ERROR:
            return {
                ...state,
                errorsUpdateAddressRoadType: true,
                requestingUpdateAddressRoadType: false,
                successfulUpdateAddressRoadType: false,
            };
        case addressRoadTypeActions.RESET_ADDRESSROADTYPE_FORM:
            return {
                ...state,
                requestingCreateAddressRoadType: false,
                successfulCreateAddressRoadType: false,
                errorsCreateAddressRoadType: false,
                country: null,
                requestingReadAddressRoadType: false,
                successfulReadAddressRoadType: false,
                errorsReadAddressRoadType: false,
                rowEdited: null,
                requestingDeleteAddressRoadType: false,
                successfulDeleteAddressRoadType: false,
                errorsDeleteAddressRoadType: false,
                rowDeleted: null,
                requestingUpdateAddressRoadType: false,
                successfulUpdateAddressRoadType: false,
                errorsUpdateAddressRoadType: false,
                rowUpdated: null,
            };
        default:
            return state;
    }
};

export const addressRoadTypeActions = {
    FECTCHIN_ALL_ADDRESSROADTYPE: 'FECTCHIN_ALL_ADDRESSROADTYPE',
    CREATE_ADDRESSROADTYPE_REQUESTING: 'CREATE_ADDRESSROADTYPE_REQUESTING',
    FETCH_ADDRESSROADTYPE_REQUEST : 'FETCH_ADDRESSROADTYPE_REQUEST',
    FETCH_ADDRESSROADTYPE_SUCCESS : 'FETCH_ADDRESSROADTYPE_SUCCESS',
    CREATE_ADDRESSROADTYPE_REQUEST: 'CREATE_ADDRESSROADTYPE_REQUEST',
    CREATE_ADDRESSROADTYPE_SUCCESS: "CREATE_ADDRESSROADTYPE_SUCCESS",
    FETCH_ADDRESSROADTYPE_ERROR: 'FETCH_ADDRESSROADTYPE_ERROR',
    CREATE_ADDRESSROADTYPE_ERROR: 'CREATE_ADDRESSROADTYPE_ERROR',
    READ_ADDRESSROADTYPE_REQUESTING: 'READ_ADDRESSROADTYPE_REQUESTING',
    READ_ADDRESSROADTYPE_SUCCESS: "READ_ADDRESSROADTYPE_SUCCESS",
    READ_ADDRESSROADTYPE_ERROR: "READ_ADDRESSROADTYPE_ERROR",
    DELETE_ADDRESSROADTYPE_REQUESTING: "DELETE_ADDRESSROADTYPE_REQUESTING",
    DELETE_ADDRESSROADTYPE_SUCCESS: "DELETE_ADDRESSROADTYPE_SUCCESS",
    DELETE_ADDRESSROADTYPE_ERROR: "DELETE_ADDRESSROADTYPE_ERROR",
    UPDATE_ADDRESSROADTYPE_REQUESTING: "UPDATE_ADDRESSROADTYPE_REQUESTING",
    UPDATE_ADDRESSROADTYPE_SUCCESS: 'UPDATE_ADDRESSROADTYPE_SUCCESS',
    UPDATE_UPDATE_ADDRESSROADTYPE_ERROR: 'UPDATE_UPDATE_ADDRESSROADTYPE_ERROR',
    RESET_ADDRESSROADTYPE_FORM: 'RESET_ADDRESSROADTYPE_FORM',
    READ_ADDRESSROADTYPE_REQUEST: 'READ_ADDRESSROADTYPE_REQUEST',
    DELETE_ADDRESSROADTYPE_REQUEST: 'ADDRESSROADTYPE',
    UPDATE_ADDRESSROADTYPE_REQUEST: 'UPDATE_ADDRESSROADTYPE_REQUEST',
    READBYDEPARTMENT_ADDRESSROADTYPE_REQUEST: 'READBYDEPARTMENT_ADDRESSROADTYPE_REQUEST'
}

export default addressRoadTypeReducer;
