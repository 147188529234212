const initialState = {
    requestingFetchAlertSources: false,
    successfulFetchAlertSources: false,
    errorFetchAlertSources: false,
    alertSources: [],
    requestingFetchOneAlertSources: false,
    successfulFetchOneAlertSources: false,
    errorFetchOneAlertSources: false,
    alertSource: null,
    requestingCreateAlertSource: false,
    successfulCreateAlertSource: false,
    errorCreateAlertSource: false,
    createResult: null,
    requestingUpdateAlertSource: false,
    successfulUpdateAlertSource: false,
    errorUpdateAlertSource: false,
    updateResult: null,
};

const alertSourceReducer = (state = initialState, action) => {
    switch (action.type) {
        case "FETCH_ALERT_SOURCES_REQUESTING":
            return {
                ...state,
                requestingFetchAlertSources: true,
                successfulFetchAlertSources: false,
                errorFetchAlertSources: false,
            };
        case "FETCH_ALERT_SOURCES_SUCCESS":
            return {
                ...state,
                requestingFetchAlertSources: false,
                successfulFetchAlertSources: true,
                errorFetchAlertSources: false,
                alertSources: action.value,
            };
        case "FETCH_ALERT_SOURCES_ERROR":
            return {
                ...state,
                requestingFetchAlertSources: false,
                successfulFetchAlertSources: false,
                errorFetchAlertSources: true,
            };
        case "FETCH_ONE_ALERT_SOURCE_REQUESTING":
            return {
                ...state,
                requestingFetchOneAlertSources: true,
                successfulFetchOneAlertSources: false,
                errorFetchOneAlertSources: false,
            };
        case "FETCH_ONE_ALERT_SOURCE_SUCCESS":
            return {
                ...state,
                requestingFetchOneAlertSources: false,
                successfulFetchOneAlertSources: true,
                errorFetchOneAlertSources: false,
                alertSource: action.value,
            };
        case "FETCH_ONE_ALERT_SOURCE_ERROR":
            return {
                ...state,
                requestingFetchOneAlertSources: false,
                successfulFetchOneAlertSources: false,
                errorFetchOneAlertSources: true,
            };
        case "CREATE_ALERT_SOURCE_REQUESTING":
            return {
                ...state,
                requestingCreateAlertSource: true,
                successfulCreateAlertSource: false,
                errorCreateAlertSource: false,
            };
        case "CREATE_ALERT_SOURCE_SUCCESS":
            return {
                ...state,
                requestingCreateAlertSource: false,
                successfulCreateAlertSource: true,
                errorCreateAlertSource: false,
                createResult: action.value,
            };
        case "CREATE_ALERT_SOURCE_ERROR":
            return {
                ...state,
                requestingCreateAlertSource: false,
                successfulCreateAlertSource: false,
                errorCreateAlertSource: true,
            };
        case "UPDATE_ALERT_SOURCE_REQUESTING":
            return {
                ...state,
                requestingUpdateAlertSource: true,
                successfulUpdateAlertSource: false,
                errorUpdateAlertSource: false,
            };
        case "UPDATE_ALERT_SOURCE_SUCCESS":
            return {
                ...state,
                requestingUpdateAlertSource: false,
                successfulUpdateAlertSource: true,
                errorUpdateAlertSource: false,
                updateResult: action.value,
            };
        case "UPDATE_ALERT_SOURCE_ERROR":
            return {
                ...state,
                requestingUpdateAlertSource: false,
                successfulUpdateAlertSource: false,
                errorUpdateAlertSource: true,
            };
        case "RESET_ALERT_SOURCE_FORM":
            return {
                ...state,
                alertSource: null,
                requestingCreateAlertSource: false,
                successfulCreateAlertSource: false,
                errorCreateAlertSource: false,
                createResult: null,
                requestingUpdateAlertSource: false,
                successfulUpdateAlertSource: false,
                errorUpdateAlertSource: false,
                updateResult: null,
            }
        default:
            return state;
    }
};

export default alertSourceReducer;
