import React, {useEffect, useState} from "react";
import CartaPorteTable from "./table";
import FilterCartaPorte from "./filter";
import ContentWrapper from "../../../components/Common/ContentWrapper";
import {connect} from "react-redux";

const CartaPorteIndex = ({ data, findAll }) => {
    const [filter, setFilter] = useState({
        page: 1,
        offset: 10
    })
    const [dataFormFilter, setDataFormFilter] = useState({})

    useEffect(() => {
        findAll(filter)
    },[filter])

    const handleSubmitFilter = (data) => {
        setDataFormFilter(data)
        findAll({
            ...filter,
            page: 1,
            startDate: data.startDate,
            endDate: data.endDate,
            success: data.success
        })
    }

    return (
        <>
            <ContentWrapper>
                <div className="d-sm-flex align-items-center mb-1">
                    <h1 className="h3 mb-0 text-gray-800 mr-3">Log de Carta Porte</h1>
                </div>
                <div className="card pb-0 mt-3">
                    <div className="card-body">
                        <FilterCartaPorte onSubmit={handleSubmitFilter} />
                    </div>
                </div>
                <div className="card pb-0 mt-3">
                    <div className="card-body">
                        <CartaPorteTable
                            data={data}
                            setFilter={setFilter}
                            dataFormFilter={dataFormFilter}
                        />
                    </div>
                </div>
            </ContentWrapper>
        </>
    )
}

const mapStateToProps = (state) => ({
        data: state.fdxCartaPorteLogs.data,
        error: state.fdxCartaPorteLogs.error,
        isLoading: state.fdxCartaPorteLogs.isLoading
})

const mapDispatchToProps = (dispatch) => {
    return {
        findAll: (payload) => {
            return dispatch({
                type: 'FETCH_DATA_REQUEST',
                payload
            })
        }
    }
}
export default  connect(mapStateToProps, mapDispatchToProps)(CartaPorteIndex)

