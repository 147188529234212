import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { Button, Modal } from 'react-bootstrap';
import BarcodeScannerComponent from 'react-qr-barcode-scanner';
import Swal from 'sweetalert2';
import { useHistory, useLocation } from 'react-router-dom';

const WmsScanner = ({ reduxGetOrders, wmsOrders, reduxSearchOrderByTracking, reduxRefresTokenValidate }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(10);
  const [search, setSearch] = useState('');
  const [showScannerDetail, setShowScannerDetail] = useState(false);
  const [activeTab, setActiveTab] = useState('orders');
  const [preparationOrders, setPreparationOrders] = useState([]);
  const [dispatchOrders, setDispatchOrders] = useState([]);
  const [scannerData, setScannerData] = useState({});
  const [scanResult, setScanResult] = useState("Not Found");

  const preparationInputRef = useRef(null);
  const dispatchInputRef = useRef(null);
  const [isMobile, setIsMobile] = useState(false);

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    reduxRefresTokenValidate(false)

    const checkIsMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    checkIsMobile();
    window.addEventListener('resize', checkIsMobile);
    return () => {
      window.removeEventListener('resize', checkIsMobile);
      reduxRefresTokenValidate(true)
    };
  }, []);


  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');
    localStorage.setItem('token', token);

    setOffset(1);
    setSearch('');
    setCurrentPage(1);
    reduxGetOrders({
      page: currentPage,
      offset: offset,
      search: search,
    });
  }, [location.search, reduxGetOrders, currentPage, offset, search, history]);

  useEffect(() => {
    if (activeTab === 'preparation' && preparationInputRef.current) {
      preparationInputRef.current.focus();
    }
    if (activeTab === 'dispatch' && dispatchInputRef.current) {
      dispatchInputRef.current.focus();
    }

    const fetchOrders = () => {
      setOffset(activeTab === 'orders' ? 5000 : 5000);

      let searchCriteria = '';
      if (activeTab === 'preparation') searchCriteria = 'current_status|alistamiento';
      if (activeTab === 'dispatch') searchCriteria = 'current_status|preparada';

      reduxGetOrders({
        page: currentPage,
        offset: offset,
        search: searchCriteria,
      });
    };

    fetchOrders();
  }, [activeTab, reduxGetOrders, currentPage, offset]);

  const handlePreparation = async () => {
    const trackingId = scannerData.tracking_id;
    const token = localStorage.getItem('token');

    const orderInWms = wmsOrders.items.find(order =>
      order.tracking_id === trackingId && order.current_status === "En alistamiento"
    );

    if (orderInWms) {
      const orderInPrev = preparationOrders.find(order => order.tracking_id === trackingId);
      if (!orderInPrev) {
        try {
          const response = await axios.patch(
            `${process.env.REACT_APP_ASSIGN_URL}/api/v1/wms/order/status`,
            { num_order: orderInWms.num_order,
              tracking_id: orderInWms.tracking_id,
              external_id: orderInWms.external_id, 
              operator: orderInWms.operator, 
              current_status: "Preparada",
              id_status: 3, 
              created_at: orderInWms.created_at, 
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
            }
          );
          setPreparationOrders(prevOrders => [...prevOrders, { ...orderInWms, current_status: "Preparada" }]);
          Swal.fire('Éxito', 'La orden ha sido preparada.', 'success');
          setShowScannerDetail(false);
        } catch (error) {
          Swal.fire('Error', 'Error al preparar la orden.', 'error');
          setShowScannerDetail(false);
        }
      } else {
        Swal.fire('Error', 'La orden ya está en la lista de preparación.', 'error');
        setShowScannerDetail(false);
      }
    } else {
      Swal.fire('Error', 'El estado de la orden no corresponde o la orden no se encuentra.', 'error');
      setShowScannerDetail(false);

    }
  };

  const handleDispatch = async () => {
    const trackingId = scannerData.tracking_id;
    const orderInWms = wmsOrders.items.find(order => order.tracking_id === trackingId && order.current_status === "Preparada");
    const token = localStorage.getItem('token');

    if (orderInWms) {
      const orderInPrev = dispatchOrders.find(order => order.tracking_id === trackingId);
      if (!orderInPrev) {
        try {
          const response = await axios.patch(
            `${process.env.REACT_APP_ASSIGN_URL}/api/v1/wms/order/dispatch`,
            {
              num_order: orderInWms.num_order,
              tracking_id: orderInWms.tracking_id,
              external_id: orderInWms.external_id, 
              operator: orderInWms.operator, 
              current_status: "Despachada",
              id_status: 4, 
              created_at: orderInWms.created_at, 

             },
            {
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
            }
          );
          setDispatchOrders(prevOrders => [...prevOrders, { ...orderInWms, current_status: "Despachado" }]);
          const updatedPreparationOrders = preparationOrders.filter(order => order.tracking_id !== trackingId);
          setPreparationOrders(updatedPreparationOrders);
          Swal.fire('Éxito', 'La orden ha sido despachada.', 'success');
          setShowScannerDetail(false);
        } catch (error) {
          Swal.fire('Error', 'Error al despachar la orden.', 'error');
          setShowScannerDetail(false);
        }
      } else {
        Swal.fire('Error', 'La orden ya está en la lista de despacho.', 'error');
      }
    } else {
      Swal.fire('Error', 'El estado de la orden no corresponde o la orden no se encuentra.', 'error');
      setShowScannerDetail(false);
    }
  };

  useEffect(() => {
    if (scanResult !== "Not Found") {
      const token = localStorage.getItem('token');
      if (token) {
        reduxSearchOrderByTracking(scanResult, token);
        setShowScannerDetail(true);
      } else {
        Swal.fire('Error', 'No se encontró un token válido para autenticar la solicitud.', 'error');
      }
    }
  }, [scanResult, reduxSearchOrderByTracking]);

  useEffect(() => {
    if (scanResult !== "Not Found" && scanResult !== "") {
      const order = wmsOrders.items.find(order => order.tracking_id === scanResult);
      if (order) {
        setScannerData(order);
        setShowScannerDetail(true);
      } else {
        Swal.fire('Error', 'La orden no se encuentra.', 'error');
        setShowScannerDetail(false);
      }
    }
  }, [wmsOrders, scanResult]);

  return (
    <div className="container-fluid d-flex flex-column justify-content-center align-items-center vh-100 p-3">
      <h1 className="h3 mb-4 text-dark">Pedidos WMS</h1>
      <h2>Escanea el QR de la orden</h2>

      <div className="d-flex flex-column align-items-center mb-3 w-100">
        <div className="position-relative" style={{ width: '300px', height: '300px' }}>
          <BarcodeScannerComponent
            width="100%"
            height="100%"
            onUpdate={(err, result) => {
              if (result) setScanResult(result.text);
            }}
          />
        </div>

        <p>Guía: {scanResult}</p>
        <p><strong>Status:</strong> {scannerData.current_status}</p>
      </div>

      <Modal show={showScannerDetail} centered>
        <Modal.Header>
          <Modal.Title>Detalles del Pedido</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {scannerData && (
            <div>
              <p><strong>Tracking Id:</strong> {scannerData.tracking_id}</p>
              <p><strong>Status:</strong> {scannerData.current_status}</p>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          {scannerData.current_status === "En alistamiento" && (
            <Button variant="primary" onClick={handlePreparation}>
              Preparar Orden
            </Button>
          )}
          {scannerData.current_status === "Preparada" && (
            <Button variant="success" onClick={handleDispatch}>
              Despachar Orden
            </Button>
          )}
          <Button variant="secondary" onClick={() => setShowScannerDetail(false)}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    wmsOrders: state.warehouseState.wmsOrders,
    wmsOrderDetails: state.warehouseState.wmsOrderDetails,
    orderEnlistment: state.warehouseState.orderEnlistment,
    orderPrepared: state.warehouseState.orderPrepared,
    successfulGetLabel: state.warehouseState.successfulGetLabel,
    logisticsOperators: state.companyState.logisticsOperators,
    shippers: state.companyState.clients,
    branches: state.branchState.branches,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    reduxGetOrders: (payload) =>
      dispatch({
        type: 'GET_WMS_ORDERS_REQUEST',
        value: payload,
      }),


    reduxPreparedWMSOrder: (payload, token) =>
      dispatch({
        type: 'CHANGE_STATUS_WMSORDER_REQUEST',
        value: { ...payload, status: 'Despachado',token },
      }),
    reduxSearchOrderByTracking: (trackingId, token) =>
      dispatch({
        type: 'SEARCH_ORDER_BY_TRACKING_REQUEST',
        value: { trackingId, token },
      }),

      reduxRefresTokenValidate:(validateRefreshToken) => 
        dispatch({
          type:'SET_TOKEN_VALIDATE', 
          value: validateRefreshToken
        }),
      
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WmsScanner);

