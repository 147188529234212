import Select from 'react-select';

const GroupedSelect = ({ groupedOptions, ...props }) => {
    const groupStyles = {
        borderBottom: '1px solid black',
        marginBottom: '5px',
        fontStyle: 'italic',
        fontWeight: 'bold',
        color: 'black'
    };

    const formatGroupLabel = (data) => (
        <div style={groupStyles}>
            <span>{data.label}</span>
        </div>
    );

    return (<>
        <Select
            {...props}
            formatGroupLabel={formatGroupLabel}
            options={groupedOptions}
        />
    </>);
}

export default GroupedSelect;