import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import Select from 'react-select';
import Swal from "sweetalert2";
import { DateTime, IANAZone } from "luxon";

import { isNullOrUndefined, exportCSV, isValidCSV } from '../../../../../utils/functionsUtils';
import { MimeTypesEnum, MovementTypesDetail } from "../../../../../utils/enumsUtil";

const InventoryMovement = ({
    show,
    movementInfo,
    handleClose,
    reduxGetClients,
    shippers,
    reduxGetWarehouses,
    warehouses,
    reduxUploadShipperInventoryMovement,
    reduxResetUploadInventoryForm,
    successInventoryUpload,
    inventoryUploadResult,
}) => {
    const {
        handleSubmit: uploadModalSumbit,
        control: uploadModalControl,
        formState: { errors: uploadModalErrors },
        watch: uploadWatch,
        setValue: uploadModalSetValue,
        reset: resetUploadModal,
    } = useForm({
        defaultValues: {
            subtype: null,
            shipper: null,
            warehouse: null,
            file: null,
        }
    });

    const fileWatcher = uploadWatch('file', null);

    const customStyles = {
        menu: (base) => ({ ...base, zIndex: 9999 }),
        valueContainer: (baseStyles) => ({ ...baseStyles, maxHeight: "3.5vw", overflowY: "auto" }),
        control: (baseStyles) => ({ ...baseStyles, flexWrap: "nowrap" }) // Ajusta el valor según tus necesidades
    };

    useEffect(() => {
        resetUploadModal();
        reduxGetClients();
        reduxGetWarehouses({
            page: 1,
            offset: 1000,
        });
    }, [])

    useEffect(() => {
        if (successInventoryUpload === true) {
            const errors = inventoryUploadResult.errors;

            if (errors.length > 0) {
                const fileName = DateTime.now().setZone(new IANAZone('America/Bogota')).toFormat('yyyy-MM-dd') + '-inventoryMovementsErrors.csv';
                exportCSV(errors, fileName);
            }

            Swal.fire(
                'Proceso finalizado',
                `${inventoryUploadResult.message}`,
                'info'
            );

            reduxResetUploadInventoryForm();
            resetUploadModal();
        }
    }, [successInventoryUpload]);

    const resetFileSelection = () => {
        uploadModalSetValue('file', '');
    };

    const uploadInventoryMovement = (data) => {
        Swal.fire({
            html: `¿Está seguro de <b>${movementInfo.description}</b> de inventario para el remitente <b>${data.shipper.label}</b> en la bodega <b>${data.warehouse.label}</b>?`,
            icon: 'question',
            showConfirmButton: true,
            confirmButtonText: "OK",
            showCancelButton: true,
            cancelButtonText: "Cancelar",
        }).then(result => {
            if (!result.isConfirmed) return;

            const reader = new FileReader();
            reader.readAsText(data.file);
            reader.onload = () => {
                if (!isValidCSV(reader.result, 3, 3)) {
                    resetFileSelection();
                    return;
                }

                reduxUploadShipperInventoryMovement({
                    file: data.file,
                    movementType: movementInfo.type,
                    movementSubtype: data.subtype.value,
                    idWarehouse: data.warehouse.value,
                    idShipper: data.shipper.value,
                });

                resetUploadModal();
                handleCloseModal();
            }
            reader.onerror = (error) => {
                resetFileSelection();
            }
        })
    }

    const handleCloseModal = () => {
        reduxResetUploadInventoryForm();
        resetUploadModal()
        handleClose();
    }

    const renderHelpTooltip = (title) => <Tooltip id='button-tooltip'>
        <h6>El excel debe tener formato CSV y no debe tener encabezado!</h6>
        <ul style={{ listStyle: 'none', padding: 2, margin: 0, textAlign: 'left' }}>
            <li>Id Producto</li>
            <li>Stock</li>
            <li>Observaciones</li>
        </ul>
        <br />
        <strong>
            Nota: Los campos del excel deben estar en el orden indicado
        </strong>
    </Tooltip>;

    return (
        <>
            <Modal
                show={show}
                onHide={handleCloseModal}
                onEscapeKeyDown={handleCloseModal}
                backdrop="static"
                keyboard={false}
                size="lg"
            >
                <form onSubmit={uploadModalSumbit(uploadInventoryMovement)}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <div className="d-flex justify-content-between align-items-center">
                                {movementInfo && movementInfo.description}
                                <div className="mx-2">
                                    <OverlayTrigger
                                        placement='bottom'
                                        overlay={renderHelpTooltip("Formato de Cargue")}>
                                        <i className="fas fa-info-circle fa-sm"></i>
                                    </OverlayTrigger>
                                </div>
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">

                            <div className='form-group col'>
                                <label
                                    htmlFor='subtype'
                                    className='form-label'>
                                    Tipo de Movimiento *
                                </label>
                                <Controller
                                    control={uploadModalControl}
                                    rules={{
                                        required: 'El tipo de movimiento es obligatorio'
                                    }}
                                    name="subtype"
                                    render={({ field }) => (
                                        <>
                                            <Select
                                                {...field}
                                                isClearable
                                                styles={customStyles}
                                                options={
                                                    MovementTypesDetail && Object.keys(MovementTypesDetail).length > 0
                                                        ? MovementTypesDetail
                                                            .filter(ele => ele.type === movementInfo.type)
                                                            .map((ele, key) => ({
                                                                value: ele.subtype.value,
                                                                label: ele.subtype.label,
                                                            }))
                                                        : []
                                                }
                                            />
                                            {
                                                uploadModalErrors.subtype &&
                                                <span className="error-message">
                                                    {uploadModalErrors.subtype.message}
                                                </span>
                                            }
                                        </>
                                    )}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className='form-group col'>
                                <label
                                    htmlFor='shipper'
                                    className='form-label'>
                                    Remitente *
                                </label>
                                <Controller
                                    control={uploadModalControl}
                                    rules={{
                                        required: 'El remitente es obligatorio'
                                    }}
                                    name="shipper"
                                    render={({ field }) => (
                                        <>
                                            <Select
                                                {...field}
                                                isClearable
                                                styles={customStyles}
                                                options={
                                                    shippers && Object.keys(shippers).length > 0
                                                        ? shippers.items
                                                            .filter(e => e.isActive === true)
                                                            .map((ele, key) => ({
                                                                value: ele.idCompany,
                                                                label: ele.description,
                                                            }))
                                                        : []
                                                }
                                            />
                                            {
                                                uploadModalErrors.shipper &&
                                                <span className="error-message">
                                                    {uploadModalErrors.shipper.message}
                                                </span>
                                            }
                                        </>
                                    )}
                                />
                            </div>
                            <div className='form-group col'>
                                <label
                                    htmlFor='warehouse'
                                    className='form-label'>
                                    Bodega *
                                </label>
                                <Controller
                                    control={uploadModalControl}
                                    name="warehouse"
                                    rules={{
                                        required: 'La bodega es obligatoria'
                                    }}
                                    render={({ field }) => (
                                        <>
                                            <Select
                                                {...field}
                                                isClearable
                                                styles={customStyles}
                                                options={
                                                    warehouses && warehouses.items
                                                        ? warehouses.items
                                                            .filter(e => e.isActive === true)
                                                            .map(ele => ({
                                                                value: ele.idWarehouse,
                                                                label: ele.name || ele.warehouseCode,
                                                            }))
                                                        : []
                                                }
                                            />
                                            {
                                                uploadModalErrors.warehouse &&
                                                <span className="error-message">
                                                    {uploadModalErrors.warehouse.message}
                                                </span>
                                            }
                                        </>
                                    )}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col-md-12">
                                <label htmlFor="file" className="form-label">
                                    Archivo *
                                </label>
                                <Controller
                                    control={uploadModalControl}
                                    name="file"
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'Debe seleccionar un archivo'
                                        },
                                        validate: (value) => {
                                            return !isNullOrUndefined(MimeTypesEnum.CSV.find(e => e === value.type)) || "El archivo debe ser formato CSV";
                                        },
                                    }}
                                    render={({ field }) => (
                                        <div className="custom-file">
                                            <input
                                                type='file'
                                                accept=".csv"
                                                onChange={(e) => {
                                                    const file = e.target.files[0];
                                                    uploadModalSetValue('file', file);
                                                }}
                                                onClick={(e) => e.target.value = null}
                                                className={`custom-file-input ${uploadModalErrors.file && 'is-invalid'}`}
                                            />
                                            <label
                                                className='custom-file-label'
                                                htmlFor='validatedCustomFile'
                                                onClick={() => document.getElementById('validatedCustomFile').click()}
                                            >
                                                {fileWatcher ? fileWatcher.name : 'Seleccionar archivo...'}
                                            </label>
                                            {
                                                uploadModalErrors.file &&
                                                <span className="error-message">{uploadModalErrors.file.message}</span>
                                            }
                                        </div>
                                    )}
                                />
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseModal}>
                            Cancelar
                        </Button>
                        <Button variant="primary" type="submit">
                            Guardar
                        </Button>
                    </Modal.Footer>
                </form >
            </Modal>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        shippers: state.companyState.clients,
        successInventoryUpload: state.inventoryState.successInventoryUpload,
        inventoryUploadResult: state.inventoryState.inventoryUploadResult,
        warehouses: state.warehouseState.warehouses,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        reduxGetClients: (payload) =>
            dispatch({
                type: 'FETCH_COMPANIESBYSHIPPER_REQUEST',
                value: payload,
            }),
        reduxUploadShipperInventoryMovement: (payload) =>
            dispatch({
                type: "UPLOAD_SHIPPERINVENTORY_MOVEMENT_REQUEST",
                value: payload,
            }),
        reduxResetUploadInventoryForm: () =>
            dispatch({
                type: "RESET_WAREHOUSEINVENTORYUPLOAD_FORM",
            }),
        reduxResetInventoryForm: (payload) =>
            dispatch({
                type: "RESET_WAREHOUSEINVENTORY_FORM",
                value: payload,
            }),
        reduxGetWarehouses: (payload) =>
            dispatch({
                type: "FETCH_WAREHOUSES_REQUEST",
                value: payload,
            }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(InventoryMovement);