import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { Button, Form, Modal, OverlayTrigger, Tooltip, Col, Row, Tabs, Tab, Table, Select, ProgressBar, Spinner } from 'react-bootstrap';
import Swal from "sweetalert2";
import { DateTime, IANAZone } from "luxon";
import { CompanyTypesEnum } from '../../../../../utils/enumsUtil';
import { isNullOrUndefined, exportCSV, isValidCSV } from '../../../../../utils/functionsUtils';
import { MimeTypesEnum, MovementTypesDetail } from "../../../../../utils/enumsUtil";
import { formToJSON } from "axios";

const WMSOrdesToPrepare = ({
    show,
    handleClose,
    reduxDownloadWMSOrderToPreparation,
    toPreparedWMSOrder,
    reduxGetLogisticsOperators,
    logisticsOperators,
    reduxGetClients,
    shippers,
    reduxGetBranches,
    branches,
    reduxGetCompanies,
    companies,
    reduxResetDownloadMassivePreparation
}) => {
    const {
        handleSubmit: downloadModalSumbit,
        control: downloadModalControl,
        formState: { errors: downloadModalErrors },
        watch: downloadWatch,
        setValue: downloadModalSetValue,
    } = useForm({
        defaultValues: {
            file: null,
        }
    });
    const userData = JSON.parse(window.localStorage.getItem('userData'));
    const countryUser = userData.idCountry ? userData.idCountry : null;
    const countryUserStr = countryUser ? countryUser.toString() : null;
    const [showDownloadPreparation, setShowDownloadPreparation] = useState(false); 
    const [selectedOption, setSelectedOption] = useState('all');
    const [selectedOperator, setSelectedOperator] = useState(null);
    const [selectedShipper, setSelectedShipper] = useState(null);
    const [selectedBranch, setSelectedBranch] = useState(null);  
    const [hubOperatorsOptions, setHubOperatorsOptions] = useState(null);
    useEffect(() => {
        reduxResetDownloadMassivePreparation();
        if (!Array.isArray(companies.items) || companies.items.length === 0) return;
        
        const groupedOptions = [];
        const dspCompanies = companies.items.filter(e => 
          e.companyType === CompanyTypesEnum.DSP  && e.isActive === true &&
          (countryUserStr === null || e.country === countryUserStr)
        );
      
        if (dspCompanies.length > 0) {
          groupedOptions.push({
            label: "DSP",
            options: dspCompanies.map(e => ({ label: e.businessName, value: e.idCompany, country: e.country }))
          });
        }
      
        const logCompanies = companies.items.filter(e => 
          e.companyType === CompanyTypesEnum.LOGISTICS_OPERATOR && e.isActive === true &&
          (countryUserStr === null || e.country === countryUserStr)
        );
      
        if (logCompanies.length > 0) {
          groupedOptions.push({
            label: "Operador Tradicional",
            options: logCompanies.map(e => ({ label: e.businessName, value: e.idCompany, country: e.country }))
          });
        }
        
        setHubOperatorsOptions(groupedOptions);
        reduxGetBranches({
            page: 1,
            offset: 1000,
            search: '',
            isActive: true,
          });
      }, [companies, countryUser]);

    const downloadMassivePreparation = (data) => {
        const payload = {}
        if (selectedOperator !== 'all') {
            payload.idCompany = selectedOperator;
        }
        if (selectedBranch !== 'all') {
            payload.idHub = selectedBranch;
        }
        reduxDownloadWMSOrderToPreparation(payload);
        

    };

    useEffect(() => {
        if (toPreparedWMSOrder) {
            if (toPreparedWMSOrder.results === 'No se encontraron ordenes para descargar') {
                Swal.fire({
                    icon: 'info',
                    title: 'Información',
                    text: 'No se encontraron ordenes para descargar',
                    showConfirmButton: true,
                    confirmButtonText: 'OK',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                }).then(() => {
                    handleClose();
                    setSelectedOperator(null);
                    setSelectedBranch(null);
                });
                
            } else {   
                const csvData = toPreparedWMSOrder.results.split(',').map(id => `${id}`).join('\n');
                const fileName = DateTime.now().setZone(new IANAZone('America/Bogota')).toFormat('yyyy-MM-dd') + '-toPreparedWMSOrderTrackingIds.csv';
                const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
                const link = document.createElement('a');
                if (link.download !== undefined) {
                    const url = URL.createObjectURL(blob);
                    link.setAttribute('href', url);
                    link.setAttribute('download', fileName);
                    link.style.visibility = 'hidden';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
                Swal.fire({
                    title: 'Proceso finalizado con éxito',
                    text: `${toPreparedWMSOrder.message}. Se ha generado un archivo CSV con los trackingId de las órdenes.`,
                    icon: 'success',
                    showConfirmButton: true,
                    confirmButtonText: 'OK',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                }).then(() => {
                    handleClose();
                    setSelectedOperator(null);
                    setSelectedBranch(null);
                });
                reduxResetDownloadMassivePreparation();  
            }        
        }
        
    }, [toPreparedWMSOrder]);

    const handleOperatorChange = (event) => {
        setSelectedOperator(event.target.value); 
    };    
    
    const handleBranchChange = (event) => {
        setSelectedBranch(event.target.value);
    };

    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                onEscapeKeyDown={handleClose}
                backdrop="static"
                keyboard={false}
                size="md"
            >
                <form onSubmit={downloadModalSumbit(downloadMassivePreparation)}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <div className="d-flex justify-content-between align-items-center">
                                <div>Descargar Ordenes sin Despachar</div>
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <Form>
                                <Form.Group as={Col}>
                                    <Row>
                                        <Col>
                                        <Form.Group>
                                        <Form.Label>Operador</Form.Label>
                                        <Form.Control as="select" value={selectedOperator} onChange={handleOperatorChange}>
                                            <option value="all">Todos</option>
                                            {hubOperatorsOptions && hubOperatorsOptions.length > 0 && hubOperatorsOptions
                                            .map((group, groupIndex) => (
                                            <optgroup key={groupIndex} label={group.label}>
                                                {group.options.map((option) => (
                                                <option key={option.value} value={option.value}>
                                                    {option.label}
                                                </option>
                                                ))}
                                            </optgroup>
                                            ))}
                                        </Form.Control>
                                        </Form.Group>
                                        </Col>
                                        <Col>
                                        <Form.Group>
                                        <Form.Label>HUB</Form.Label>
                                        <Form.Control as='select' value={selectedBranch} onChange={handleBranchChange} >
                                        <option value="all">Todos</option>
                                        {branches.items && branches.items.length > 0 && branches.items
                                        .filter(e => e.isActive === true && (countryUserStr === null || e.idCountry == countryUserStr))
                                        .map((ele) => (
                                            <option key={ele.idBranch} value={ele.idBranch}>
                                            {ele.branchName}
                                            </option>
                                        ))}
                                        </Form.Control>
                                        </Form.Group>
                                        </Col>                                                 
                                    </Row>
                                </Form.Group>
                            </Form>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Cancelar
                        </Button>
                        <Button variant="primary" type="submit">
                            Descargar
                        </Button>
                    </Modal.Footer>
                </form >
            </Modal>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        toPreparedWMSOrder: state.warehouseState.toPreparedWMSOrder,
        logisticsOperators: state.companyState.logisticsOperators,
        shippers: state.companyState.clients,
        branches: state.branchState.branches,
        companies: state.companyState.companies,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        reduxDownloadWMSOrderToPreparation: (payload) =>
            dispatch({
                type: "DOWNLOAD_WMSORDER_TO_PREPARED_REQUEST",
                value: payload,
                download: true,
            }),
            reduxGetLogisticsOperators: (payload) =>
                dispatch({
                  type: 'FETCH_COMPANIESBYLOGISTICSOPERATOR_REQUEST',
                  value: payload,
                }),
            reduxGetClients: (payload) =>
                dispatch({
                    type: 'FETCH_COMPANIESBYSHIPPER_REQUEST',
                    value: payload,
            }),
            reduxGetBranches: (payload) =>
                dispatch({
                    type: 'FETCH_BRANCHES_REQUEST',
                    value: payload,
            }),
            reduxGetCompanies: (payload) =>
                dispatch({
                    type: 'FETCH_COMPANIES_REQUEST',
                    value: payload,
            }),
            reduxResetDownloadMassivePreparation: () =>
                dispatch({
                    type: "RESET_DOWNLOAD_MASSIVE_WMSORDER_TO_PREPARED_REQUEST",
                })
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(WMSOrdesToPrepare);