import React from "react";
import Sidebar from "../../components/Navigation/Sidebar";
import Topbar from "../../components/Navigation/Topbar";

const ContentWrapper = ({children}) => {

    return (
        <>
         <div id="wrapper">
             <Sidebar />
                <div id="content-wrapper" className="d-flex flex-column">
                  <div id="content">
                  <Topbar />
                  <div className="container-fluid">
                      {children}
                  </div>
                  </div>
                </div>
            </div>
        </>
    )
}

export default ContentWrapper