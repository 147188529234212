import {connect} from "react-redux";
import OrdersLockedComponent from "./OrdersLockedComponent";
import { ordersLockedActions } from "../../redux/reducers/OrdersLockedReducer";

const mapStateToProps = (state) => ({
    pendingOrders: state.ordersLocked.pendingOrders,
    lockedOrders: state.ordersLocked.lockedOrders,
    totalPendingOrders: state.ordersLocked.totalPendingOrders,
    startDate: state.ordersLocked.startDate,
    endDate: state.ordersLocked.endDate,
    totalOrdersConfirmed: state.ordersLocked.totalOrdersConfirmed,
    successfulFetchConfirmOrder: state.ordersLocked.successfulFetchConfirmOrder,
    successUploadOrdersToConfirm: state.ordersLocked.successUploadOrdersToConfirm,
    successfulFetchDeleteOrder: state.ordersLocked.successfulFetchDeleteOrder
})

const mapDispatchToProps = (dispatch) => {
    return {
        reduxGetPendingOrders: (payload) => {
            return dispatch({
                type: 'FETCH_ALL_PENDING_ORDERS',
                value: payload
            })
        },
        reduxGetLockedOrders: (payload) => {
            return dispatch({
                type: 'FETCH_ALL_LOCKED_ORDERS',
                value: payload
            })
        },
        reduxCountOrdersPending: (payload) => {
            return dispatch({
                type: 'FETCH_COUNT_ALL_PENDING_ORDERS',
                value: payload
            })
        },
        reduxDeleteOrdersPending: (payload) => {
            return dispatch({
                type: 'FETCH_DELETE_ORDER_PENDING_REQUEST',
                value: payload
            })
        },
        reduxConfirmAllOrders: (payload) => {
            return dispatch({
                type: 'FETCH_CONFIRM_ALL_ORDER_REQUEST',
                value: payload
            })
        },
        reduxConfirmOrder: (payload) => {
            return dispatch({
                type: 'FETCH_CONFIRM_ORDER_REQUEST',
                value: payload
            })
        },
        setEndDateFilter: (payload) => {
            dispatch({
                type: ordersLockedActions.SET_END_DATE,
                value: payload
            })
        },
        setStartDateFilter: (payload) => {
            dispatch({
                type: ordersLockedActions.SET_START_DATE,
                value: payload
            })
        }
    }
}
export default  connect(mapStateToProps, mapDispatchToProps)(OrdersLockedComponent)

