const initialState = {
  requestingFetchNotes: false,
  successfulFetchNotes: false,
  errorFetchNotes: false,
  notes: {},
  requestingCreateNote: false,
  successfulCreateNote: false,
  errorsCreateNote: false,
  note: null,
  requestingReadNote: false,
  successfulReadNote: false,
  errorsReadNote: false,
  rowEdited: null,
  requestingDeleteNote: false,
  successfulDeleteNote: false,
  errorsDeleteNote: false,
  rowDeleted: null,
  requestingUpdateNote: false,
  successfulUpdateNote: false,
  errorsUpdateNote: false,
  rowUpdated: null,
};

const noteReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_NOTES_REQUESTING":
      return {
        ...state,
        requestingFetchNotes: true,
        successfulFetchNotes: false,
        errorsFetchNotes: false,
      };
    case "FETCH_NOTE_SUCCESS":
      return {
        ...state,
        errorFetchNotes: false,
        requestingFetchNotes: false,
        successfulFetchNotes: true,
        notes: action.value,
      };
    case "FETCH_NOTE_ERROR":
      return {
        ...state,
        errorFetchNotes: true,
        requestingFetchNotes: false,
        successfulFetchNotes: false,
      };
    case "CREATE_NOTE_REQUESTING":
      return {
        ...state,
        requestingCreateNote: true,
        successfulCreateNote: false,
        errorsCreateNote: false,
      };
    case "CREATE_NOTE_SUCCESS":
      return {
        ...state,
        errorsCreateNote: false,
        requestingCreateNote: false,
        successfulCreateNote: true,
        note: action.value,
      };
    case "CREATE_NOTE_ERROR":
      return {
        ...state,
        errorsCreateNote: true,
        requestingCreateNote: false,
        successfulCreateNote: false,
      };
    case "READ_NOTE_REQUESTING":
      return {
        ...state,
        requestingReadNote: true,
        successfulReadNote: false,
        errorsReadNote: false,
      };
    case "READ_NOTE_SUCCESS":
      return {
        ...state,
        errorsReadNote: false,
        requestingReadNote: false,
        successfulReadNote: true,
        rowEdited: action.value,
      };
    case "READ_NOTE_ERROR":
      return {
        ...state,
        errorsReadNote: true,
        requestingReadNote: false,
        successfulReadNote: false,
      };
    case "DELETE_NOTE_REQUESTING":
      return {
        ...state,
        requestingDeleteNote: true,
        successfulDeleteNote: false,
        errorsDeleteNote: false,
      };
    case "DELETE_NOTE_SUCCESS":
      return {
        ...state,
        errorsDeleteNote: false,
        requestingDeleteNote: false,
        successfulDeleteNote: true,
        rowDeleted: action.value,
      };
    case "DELETE_NOTE_ERROR":
      return {
        ...state,
        errorsDeleteNote: true,
        requestingDeleteNote: false,
        successfulDeleteNote: false,
      };
    case "UPDATE_NOTE_REQUESTING":
      return {
        ...state,
        requestingUpdateNote: true,
        successfulUpdateNote: false,
        errorsUpdateNote: false,
      };
    case "UPDATE_NOTE_SUCCESS":
      return {
        ...state,
        errorsUpdateNote: false,
        requestingUpdateNote: false,
        successfulUpdateNote: true,
        rowUpdated: action.value,
      };
    case "UPDATE_NOTE_ERROR":
      return {
        ...state,
        errorsUpdateNote: true,
        requestingUpdateNote: false,
        successfulUpdateNote: false,
      };
    case "RESET_NOTE_FORM":
      return {
        ...state,
        requestingCreateNote: false,
        successfulCreateNote: false,
        errorsCreateNote: false,
        note: null,
        requestingReadNote: false,
        successfulReadNote: false,
        errorsReadNote: false,
        rowEdited: null,
        requestingDeleteNote: false,
        successfulDeleteNote: false,
        errorsDeleteNote: false,
        rowDeleted: null,
        requestingUpdateNote: false,
        successfulUpdateNote: false,
        errorsUpdateNote: false,
        rowUpdated: null,
        requestingUpdateNote: false,
        successfulUpdateNote: false,
        errorsUpdateNote: false,
        rowUpdated: null,
      };
    case "RESET":
      return initialState;
    default:
      return state;
  }
};

export default noteReducer;
