import { call, put, takeLatest } from "redux-saga/effects";
import request, {requestAxios} from "../../utils/request";

function* fetchAll(payload) {
  try {
    yield put({
      type: "SHOW_LOADING",
    });

    yield put({
      type: "FETCH_ALERTS_REQUESTING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/alerts?page=${payload.value.page}&offset=${payload.value.offset}&search=${payload.value.search}`;

    const headers = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "FETCH_ALERTS_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Load failed",
        message: "Loading Alerts",
      },
    });
    yield put({
      type: "FETCH_ALERTS_ERROR"
    });
  }
  finally {
    yield put({
      type: "HIDE_LOADING",
    });
  }
}

function* fetchDaemonAlerts(payload) {
  try {
    yield put({
      type: "SHOW_LOADING",
    });

    yield put({
      type: "FETCH_DAEMON_ALERTS_REQUESTING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/alerts/daemon/all?page=${payload.value.page}&offset=${payload.value.offset}&search=${payload.value.search}`;

    const headers = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "FETCH_DAEMON_ALERTS_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Load failed",
        message: "Loading Alerts",
      },
    });
    yield put({
      type: "FETCH_DAEMON_ALERTS_ERROR"
    });
  }
  finally {
    yield put({
      type: "HIDE_LOADING",
    });
  }
}

function* fetchDaemonAlertLog(payload) {
  try {
    yield put({
      type: "SHOW_LOADING",
    });

    yield put({
      type: "FETCH_DAEMON_ALERT_LOG_REQUESTING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/alerts/daemon/log/${payload.value.id}?page=${payload.value.page}&offset=${payload.value.offset}`;

    const headers = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "FETCH_DAEMON_ALERT_LOG_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Load failed",
        message: "Loading Alert Log",
      },
    });
    yield put({
      type: "FETCH_DAEMON_ALERT_LOG_ERROR"
    });
  }
  finally {
    yield put({
      type: "HIDE_LOADING",
    });
  }
}

function* fetchOne(payload) {
  try {
    yield put({
      type: "SHOW_LOADING",
    });

    yield put({
      type: "FETCH_ONE_ALERT_REQUESTING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/alerts/${payload.value.idAlert}`;

    const headers = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "FETCH_ONE_ALERT_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Load failed",
        message: "Loading Alert",
      },
    });
    yield put({
      type: "FETCH_ONE_ALERT_ERROR"
    });
  }
  finally {
    yield put({
      type: "HIDE_LOADING",
    });
  }
}

function* create(payload) {
  try {
    yield put({
      type: "SHOW_LOADING",
    });

    yield put({
      type: "CREATE_ALERT_REQUESTING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/alerts`;

    const headers = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload.value),
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "CREATE_ALERT_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Create failed",
        message: "Alert was not created",
      },
    });
    yield put({
      type: "CREATE_ALERT_ERROR"
    });
  }
  finally {
    yield put({
      type: "HIDE_LOADING",
    });
  }
}

function* update(payload) {
  try {
    yield put({
      type: "SHOW_LOADING",
    });

    yield put({
      type: "UPDATE_ALERT_REQUESTING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/alerts`;

    const headers = {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload.value),
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "UPDATE_ALERT_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Update failed",
        message: "Alert was not updated",
      },
    });
    yield put({
      type: "UPDATE_ALERT_ERROR"
    });
  }
  finally {
    yield put({
      type: "HIDE_LOADING",
    });
  }
}

function* resendAlert(payload) {
  try {
    yield put({
      type: "SHOW_LOADING",
    });

    yield put({
      type: "RESEND_DAEMON_ALERT_REQUESTING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/alerts/daemon/${payload.value.id}/resend`;

    const headers = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload.value),
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "RESEND_DAEMON_ALERT_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Resend failed",
        message: "Alert was not resent",
      },
    });
    yield put({
      type: "RESEND_DAEMON_ALERT_ERROR"
    });
  }
  finally {
    yield put({
      type: "HIDE_LOADING",
    });
  }
}

export function* watchAlerts() {
  yield takeLatest("FETCH_ALERTS_REQUEST", fetchAll);
  yield takeLatest("FETCH_DAEMON_ALERTS_REQUEST", fetchDaemonAlerts);
  yield takeLatest("FETCH_DAEMON_ALERT_LOG_REQUEST", fetchDaemonAlertLog);
  yield takeLatest("FETCH_ONE_ALERT_REQUEST", fetchOne);
  yield takeLatest("CREATE_ALERT_REQUEST", create);
  yield takeLatest("UPDATE_ALERT_REQUEST", update);
  yield takeLatest("RESEND_DAEMON_ALERT_REQUEST", resendAlert);
}
