import {call, put, takeLatest} from "redux-saga/effects";
import request from "../../utils/request";
import {addressCardinalActions} from "../reducers/addressCardinalReducer";

function* getAllAddressesCardinalPoint(payload) {
    try {
        yield put({
            type: addressCardinalActions.FECTCHIN_ALL_ADDRESSCARDINAL,
        });

        yield put({
            type: "SHOW_LOADING",
        });

        const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/addressCardinalPoints?page=${payload.value.page}&search=${payload.value.search}&offset=${payload.value.offset}${payload.value.isActive ? '&isActive=' + payload.value.isActive: '' }`;
        const headers = {
            method: "GET",
            headers: new Headers({
                Authorization: `Bearer ${window.localStorage.getItem("token")}`,
                "Content-Type": "application/json",
            }),
        };
        const response = yield call(request, requestURL, headers);

        yield put({
            type: addressCardinalActions.FETCH_ADDRESSES_CARDINAL_SUCCESS,
            value: response,
        });
        yield put({
            type: "HIDE_LOADING",
        });
    } catch (error) {
        yield put({
            type: "HIDE_LOADING",
          });
        console.log(error);

    }
}

function* postAddressCardinal(payload) {
    try {
        yield put({type: addressCardinalActions.CREATE_ADDRESSCARDINAL_REQUESTING});

        const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/addressCardinalPoints`;

        const headers = {
            method: "POST",
            headers: new Headers({
                Authorization: `Bearer ${window.localStorage.getItem("token")}`,
                "Content-Type": "application/json",
            }),
            body: JSON.stringify(payload.value),
        };

        const response = yield call(request, requestURL, headers);

        yield put({
            type: "HIDE_LOADING",
        });
        yield put({
            type: "SHOW_ALERT",
            value: {
                type: "success",
                title: "Successful save",
                message: "Successful save Address Cardinal Point ",
            },
        });
        yield put({
            type: addressCardinalActions.CREATE_ADDRESSCARDINAL_SUCCESS,
            value: response,
        });
    } catch (error) {
        yield put({
            type: "HIDE_LOADING",
        });
        yield put({
            type: "SHOW_ALERT",
            value: {
                type: "danger",
                title: "Failed save",
                message: "Failed save City",
            },
        });
        yield put({
            type: addressCardinalActions.CREATE_ADDRESSCARDINAL_ERROR,
        });
    }
}

function* getAddressCardinalById(payload) {
    try {
        yield put({
            type: addressCardinalActions.READ_ADDRESSCARDINAL_REQUESTING,
        });

        yield put({
            type: "SHOW_LOADING",
        });

        const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/addressCardinalPoints/${payload.value.id}`;

        const headers = {
            method: "GET",
            headers: new Headers({
                Authorization: `Bearer ${window.localStorage.getItem("token")}`,
                "Content-Type": "application/json",
            }),
        };
        const response = yield call(request, requestURL, headers);

        yield put({
            type: addressCardinalActions.READ_ADDRESSCARDINAL_SUCCESS,
            value: response,
        });

        yield put({
            type: addressCardinalActions.READ_ADDRESSCARDINAL_SUCCESS,
            value: response,
        });

        yield put({
            type: "HIDE_LOADING",
        });
    } catch (error) {
        yield put({
            type: "HIDE_LOADING",
        });
        yield put({
            type: addressCardinalActions.READ_ADDRESSCARDINAL_ERROR,
        });
    }
}

function* deleteAddressCardinalById(payload) {
    try {
        yield put({
            type: addressCardinalActions.DELETE_ADDRESSCARDINAL_REQUESTING,
        });

        yield put({
            type: "SHOW_LOADING",
        });

        const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/addressCardinalPoints/changeState/${payload.value.idAddressCardinalPoint}`;

        const headers = {
            method: "PATCH",
            headers: new Headers({
                Authorization: `Bearer ${window.localStorage.getItem("token")}`,
                "Content-Type": "application/json",
            }),
            body: JSON.stringify(payload.value),
        };

        const response = yield call(request, requestURL, headers);

        yield put({
            type: addressCardinalActions.DELETE_ADDRESSCARDINAL_SUCCESS,
            value: response,
        });
        yield put({
            type: "HIDE_LOADING",
        });
        yield put({
            type: "SHOW_ALERT",
            value: {
                type: "success",
                title: "Successful disable",
                message: "Successful disable addressCardinalPoint",
            },
        });
    } catch (error) {
        yield put({
            type: "HIDE_LOADING",
        });
        yield put({
            type: "SHOW_ALERT",
            value: {
                type: "danger",
                title: "Failed disable",
                message: "Failed disable addressCardinalPoint",
            },
        });
        yield put({
            type: addressCardinalActions.DELETE_ADDRESSCARDINAL_ERROR,
        });
    }
}

function* updateAddressCardinalById(payload) {
    try {
        yield put({
            type: addressCardinalActions.UPDATE_ADDRESSCARDINAL_REQUESTING,
        });

        yield put({
            type: "SHOW_LOADING",
        });

        const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/addressCardinalPoints/${payload.value.idAddressCardinalPoint}`;

        const headers = {
            method: "PATCH",
            headers: new Headers({
                Authorization: `Bearer ${window.localStorage.getItem("token")}`,
                "Content-Type": "application/json",
            }),
            body: JSON.stringify(payload.value),
        };

        const response = yield call(request, requestURL, headers);

        yield put({
            type: addressCardinalActions.UPDATE_ADDRESSCARDINAL_SUCCESS,
            value: response,
        });
        yield put({
            type: "HIDE_LOADING",
        });
        yield put({
            type: "SHOW_ALERT",
            value: {
                type: "success",
                title: "Successful update",
                message: "Successful update Country",
            },
        });
    } catch (error) {
        yield put({
            type: "HIDE_LOADING",
        });
        yield put({
            type: "SHOW_ALERT",
            value: {
                type: "danger",
                title: "Failed update",
                message: "Failed update Country",
            },
        });
        yield put({
            type: addressCardinalActions.UPDATE_UPDATE_ADDRESSCARDINAL_ERROR,
        });
    }
}


export function* watchAddresCardinalPoint() {
    yield takeLatest(addressCardinalActions.FETCH_ADDRESSES_CARDINAL_POINTS_REQUEST, getAllAddressesCardinalPoint);
    yield takeLatest(addressCardinalActions.CREATE_ADDRESSCARDINAL_REQUEST, postAddressCardinal);
    yield takeLatest(addressCardinalActions.READ_ADDRESSCARDINAL_REQUEST, getAddressCardinalById);
    yield takeLatest(addressCardinalActions.DELETE_ADDRESSCARDINAL_REQUEST, deleteAddressCardinalById);
    yield takeLatest(addressCardinalActions.UPDATE_ADDRESSCARDINAL_REQUEST, updateAddressCardinalById);
}
