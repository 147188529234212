const initialState = {
  isAuthenticated: false,
  session: null,
  errorsRefreshToken: false,
  requestingRefreshToken: false,
  successfulRefreshToken: false,
  token:{},
  validateRefreshToken: true
}

export const getInitialState = () => JSON.parse(JSON.stringify(initialState));

const authReducer = (
  state = getInitialState(),
  action
) => {
  switch (action.type) {
    case "SIGN_IN":
      return Object.assign({}, state, {
        isAuthenticated: true,
        session: action.payload,
      });
    case "RESTORE_SESION":
      return Object.assign({}, state, {
        isAuthenticated: true,
        session: action.payload,
      });
    case "SIGN_OUT":
      return getInitialState();
    default:
      return state;      
    case "REFRESH_TOKEN_REQUESTING":
      return {
        ...state,
        requestingRefreshToken: true,
        successfulRefreshToken: false,
        errorsRefreshToken: false,
      };
    case "REFRESH_TOKEN_SUCCESS":
      return {
        ...state,
        errorRefreshToken: false,
        requestingRefreshToken: false,
        successfulRefreshToken: true,
        token: action.value,
      };
    case "REFRESH_TOKEN_ERROR":
      return {
        ...state,
        errorRefreshToken: true,
        requestingRefreshToken: false,
        successfulRefreshToken: false,
      };
    case "SET_TOKEN_VALIDATE":
      return{
        ...state,
        validateRefreshToken: action.value
      }
  }
}

export default authReducer;
