import React from "react";

const NumberInput = React.forwardRef(({ value, onChange, placeholder, tabIndex, ...props }, ref) => {
    const handleChange = (value) => {
        const valueInNumber = Number(value.target.value);
        onChange(valueInNumber)
    }

    return (
        <input
            {...props}
            className={`form-control`}
            type="text"
            value={value}
            onChange={(value) => {
                handleChange(value);
            }}
            placeholder={placeholder}
            tabIndex={tabIndex}
            ref={ref}
            onKeyDown={(e) => {
                if (!/[0-9]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete' && e.key !== 'Tab') {
                    e.preventDefault();
                }
            }}
            onPaste={(e) => {
                const pastedData = e.clipboardData.getData('Text');
                if (!/^[0-9]+$/.test(pastedData)) {
                    e.preventDefault();
                }
            }}
        />
    )
});

export default NumberInput;