const initialState = {
  requestingReadAddressesEventByIdAddress: false,
  successfulReadAddressesEventByIdAddress: false,
  errorsReadAddressesEventByIdAddress: false,
  addressesEventsByIdAddress: null,
};

const addressesEventReducer = (state = initialState, action) => {
  switch (action.type) {
    case "READ_BY_IDADDRESS_ADDRESSES_EVENT_REQUESTING":
      return {
        ...state,
        requestingReadAddressesEventByIdAddress: true,
        successfulReadAddressesEventByIdAddress: false,
        errorsReadAddressesEventByIdAddress: false,
      };
    case "READ_BY_IDADDRESS_ADDRESSES_EVENT_SUCCESS":
      return {
        ...state,
        errorsReadAddressesEventByIdAddress: false,
        requestingReadAddressesEventByIdAddress: false,
        successfulReadAddressesEventByIdAddress: true,
        addressesEventsByIdAddress: action.value,
      };
    case "READ_BY_IDADDRESS_ADDRESSES_EVENT_ERROR":
      return {
        ...state,
        errorsReadAddressesEventByIdAddress: true,
        requestingReadAddressesEventByIdAddress: false,
        successfulReadAddressesEventByIdAddress: false,
      };
    case "RESET_READ_BY_IDADDRESS_ADDRESSES_EVENT":
      return initialState;
    default:
      return state;
  }
};

export default addressesEventReducer;
