import Autocomplete, { usePlacesWidget } from "react-google-autocomplete";
import React, { useEffect } from "react";
import { useFormContext, useForm, Controller } from "react-hook-form";

const GoogleMapsAutocomplete = ({ id, required = false, value = '', onChange, disabled = false }) => {
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext();

  const { ref: addressRef, autocompleteRef } = usePlacesWidget({
    apiKey: `${process.env.REACT_APP_GOOGLE_MAPS_KEY}`,
    onPlaceSelected: (place) => onChange(place),
    options: {
      fields: ["address_components", "geometry", "icon", "name"],
      strictBounds: false,
      types: ["address"],
    },
    libraries: ["places"]
  });

  useEffect(() => {
    setValue(id, value);
  }, [value]);

  return (
    <Controller
      name={id}
      id={id}
      {...register(id, { required: required })}
      rules={{ required: required }}
      render={({ field: { onChange, onBlur, value, ref } }) => (
        <div className="form-group">
          <label htmlFor={id} className="form-label">
            Dirección{required ? '*' : ''}
          </label>
          <input
            className={`form-control form-control-user ${
              errors[id] && "is-invalid"
            }`}
            ref={addressRef}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            disabled={disabled}
            placeholder="Ingrese una ubicación"
          />
          {errors[id] && (
            <span className="invalid-feedback">La dirección es requerida.</span>
          )}
        </div>
      )}
    />
  );
};

export default GoogleMapsAutocomplete;
