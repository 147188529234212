import React, { useState, useEffect } from 'react';
import { Button, Modal, Form, Spinner } from 'react-bootstrap';
import BarcodeScannerComponent from 'react-qr-barcode-scanner';
import Swal from 'sweetalert2';
import axios from 'axios';
import { useLocation } from 'react-router-dom';

const DspScanner = () => {
  const [showScannerDetail, setShowScannerDetail] = useState(false);
  const [scannerData, setScannerData] = useState(null);
  const [scanResult, setScanResult] = useState("Not Found");
  const [isMobile, setIsMobile] = useState(false);
  const [couriers, setCouriers] = useState([]);
  const [selectedCourier, setSelectedCourier] = useState('');
  const [loading, setLoading] = useState(false);

  const location = useLocation();

  useEffect(() => {
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkIsMobile();
    window.addEventListener('resize', checkIsMobile);

    return () => {
      window.removeEventListener('resize', checkIsMobile);
    };
  }, []);

  useEffect(() => {
    const fetchCouriers = async () => {
      try {
        const token = localStorage.getItem('token');
        if (token) {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/api/v1/users/couriers`, 
            {
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
            }
          );
          setCouriers(response.data);
        }
      } catch (error) {
        console.error('Error al obtener los mensajeros', error);
        Swal.fire('Error', 'Error al obtener los mensajeros.', 'error');
      }
    };

    fetchCouriers();
  }, []);

  const handleAssignCarrier = async () => {
    const token = localStorage.getItem('token');
  
    if (token && scannerData && selectedCourier) {
      try {
        setLoading(true);
        
        const payload = {
          assignedDate: new Date().toISOString().split('.')[0].replace('T', ' '),
          addressesChecked: [scannerData.idAddress],
          idUser: selectedCourier,
          updateDspOrder: true,
        };
  
        await axios.post(
          `${process.env.REACT_APP_API_URL}/api/v1/routes`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );
        
        Swal.fire('Éxito', 'El mensajero ha sido asignado.', 'success');
        setShowScannerDetail(false);
      } catch (error) {
        console.error('Error al asignar el mensajero', error);
        Swal.fire('Error', 'Error al asignar el mensajero.', 'error');
      } finally {
        setLoading(false);
      }
    } else {
      Swal.fire('Error', 'No se encontró un token válido o no se seleccionó un mensajero.', 'error');
    }
  };

  const handleScan = async (err, result) => {
    if (err) {
      console.error('Error al escanear el QR', err);
      return;
    }

    if (result) {
      const scannedTrackingId = result.text.trim();
      setScanResult(scannedTrackingId);

      try {
        const token = localStorage.getItem('token');
        if (token) {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/api/v1/dsp/order/assignToCourier?page=1&search=&offset=100000&isActive=true`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
            }
          );

          const order = response.data.items.find(o => o.trackingId === scannedTrackingId);

          if (order) {
            setScannerData(order);
            setShowScannerDetail(true);
          } else {
            Swal.fire('Orden No Encontrada', 'La guía escaneada no corresponde a una orden activa.', 'warning');
          }
        }
      } catch (error) {
        console.error('Error al verificar la orden', error);
        Swal.fire('Error', 'Error al verificar la orden.', 'error');
      }
    }
  };

  if (!isMobile) {
    return <div className="d-none d-md-block">Esta vista solo está disponible en dispositivos móviles.</div>;
  }

  return (
    <div className="container-fluid d-flex flex-column justify-content-center align-items-center vh-100 p-3">
      <h1 className="h3 mb-4 text-dark">Pedidos DSP</h1>
      <h2 className="mb-4">Escanea el QR de la orden</h2>

      <div className="d-flex flex-column align-items-center mb-3 w-100">
        <div className="position-relative" style={{ width: '300px', height: '300px' }}>
          <BarcodeScannerComponent
            width="100%"
            height="100%"
            onUpdate={handleScan}
          />
        </div>

        <p className="mt-3">Guía: {scanResult}</p>
      </div>

      {loading && <Spinner animation="border" role="status" />}

      <Modal show={showScannerDetail} onHide={() => setShowScannerDetail(false)} centered fullscreen>
        <Modal.Header closeButton>
          <Modal.Title>Detalles del Pedido</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {scannerData && (
            <div>
              <p><strong>Tracking Id:</strong> {scannerData.trackingId}</p>
              <p><strong>Dirección:</strong> {scannerData.address}</p>
              <p><strong>Referencia 1:</strong> {scannerData.reference1}</p>
              <p><strong>Referencia 2:</strong> {scannerData.reference2}</p>
              <p><strong>Status:</strong> {scannerData.code}</p>
              <Form.Control
                as="select"
                value={selectedCourier}
                onChange={(e) => setSelectedCourier(e.target.value)}
                className="mt-3"
                style={{ width: '100%', height: '60vh' }} // Ocupa toda la pantalla
              >
                <option value=""></option>
                {couriers.map(courier => (
                  <option key={courier.idUser} value={courier.idUser}>
                    {`${courier.firstName} ${courier.lastName} (${courier.nameHeadquarters})`}
                  </option>
                ))}
              </Form.Control>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={handleAssignCarrier}
            disabled={!selectedCourier || loading}
            style={{ width: '100%' }} // Ocupa toda la pantalla
          >
            Asignar Mensajero
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DspScanner;
