import React, { useCallback, useEffect, useMemo, useState } from "react"
import DataTable from 'react-data-table-component';
import 'styled-components';
import { Badge, Button, Form, Row } from "react-bootstrap";
import { isNullOrUndefined } from "../../utils/functionsUtils";
import { FiZap } from 'react-icons/fi';

const extractMessage = (data) => {

    if (Array.isArray(data)) {
        return data.map(error => error.message).join(' ')
    }

    if (isNullOrUndefined(data)) return 'N/A';
    if (data?.reason) {
        return data?.reason;
    }
    if (data.errors && Array.isArray(data.errors) && data.errors.length > 0) {
        return data.errors.map(error => error.message).join(' ');
    }

    return 'N/A';
};

function convertArrayOfObjectsToCSV(array) {
    let result;

    const columnDelimiter = ',';
    const lineDelimiter = '\n';
    const keys = Object.keys(
        {
            "usuario_name": "SUPER ADMIN",
            "idaddress": "822029",
            "company_name": "FEDEX MX",
            "cur_status": "En Curso",
            "intents": 3,
            "data_response": {
                "reason": "Ya tiene un operador asignado",
                "idAddress": 822029
            },
            "created_at": "2024-02-07T19:48:56.971Z"
        }
    );

    const keyNames = ["Usuario", "ID_address", "Operador", "Estado", "Intentos", "Respuesta", "Fecha"]

    result = '';
    result += keyNames
    result += lineDelimiter;

    array.forEach(item => {
        let ctr = 0;
        keys.forEach(key => {
            if (ctr > 0) result += columnDelimiter;

            if (key === 'data_response') {
                item[key] = extractMessage(item[key])
            }

            result += item[key];

            ctr++;
        });
        result += lineDelimiter;
    });

    return result;
}

const Export = ({ onExport }) => <Button size="sm" onClick={e => onExport(e.target.value)}>Exportar CSV</Button>;
let interval = null;
export const ProgressDetail = ({activeTab, handleDetail, parent}) => {

    const [data, setData] = useState([]);
    const [perPage, setPerPage] = useState(10);
    const [page, setPage] = useState(1);
    const [totalRows, setTotalRows] = useState(10);
    const [filterText, setFilterText] = useState('');
    const [loadding, setLoadding] = useState(false);
    const [isActive, setIsActive] = useState(false);

    const [intervalDuration, setIntervalDuration] = useState('60000');

    const downloadCSV = (array) => {
        console.log(array)
        const link = document.createElement('a');
        let csv = convertArrayOfObjectsToCSV(array);
        if (csv == null) return;

        const filename = 'export.csv';

        if (!csv.match(/^data:text\/csv/i)) {
            csv = `data:text/csv;charset=utf-8,${csv}`;
        }

        link.setAttribute('href', encodeURI(csv));
        link.setAttribute('download', filename);
        link.click();
    }
    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPage(newPerPage);
        setPage(page);
        await getData(page, newPerPage);
    };

    const actionsMemo = useMemo(() => {
        if (data.length === 0) return ''
       return <Export onExport={() => downloadCSV(filteredData)}/>
    },[data]);

    const filteredData = useMemo(() => {
        if (!filterText) return data;
        return data.filter(item =>
            Object.keys(item).some(key => !isNullOrUndefined(item[key]) ? item[key].toString().toLowerCase().includes(filterText.toLowerCase()) : false
            )
        );
    }, [data, filterText]);

    const getData = useCallback(async () => {
        const token = window.localStorage.getItem("token");
        try {
            setLoadding(true)
            const response = await fetch(`${process.env.REACT_APP_ASSIGN_URL}/api/v1/assignOperator/getAllAssignment`, {
                method: 'POST',
                headers: new Headers({
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }),
                body: JSON.stringify({idParent: parent.id})
            });
            if (!response.ok) {
                setLoadding(false)
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const result = await response.json();
            console.log(result)
            setData(result);
            setLoadding(false)
        } catch (err) {
            console.log(err);
        }
    }, []);

    const columns = useMemo(() => [
        {
            name: 'ID Address',
            selector: row => row.idaddress,
            sortable: true
        },
        {
            name: 'Usuario',
            selector: row => row.usuario_name,
            sortable: true,
        },
        {
            name: 'Operador',
            selector: row => row.company_name,
            sortable: true,
        },
        {
            name: 'Estado Actual',
            selector: row => row.cur_status,
            sortable: true,
        },
        {
            name: 'Intentos',
            selector: row => row.intents,
            sortable: true,
        },
        {
            name: 'Respuesta de Datos',
            cell: row => {
                const text = extractMessage(row.data_response)
                const contentString = JSON.stringify(text);
                const previewContent = contentString.length > 100 ? `${contentString.substring(0, 100)}...` : contentString;

                return (
                    <div title={contentString}>
                        {previewContent}
                    </div>
                );
            },
            width: '550px',
            sortable: true,
        },
        {
            name: 'Fecha',
            selector: row => row.created_at,
            sortable: true,
            format: row => new Date(row.created_at).toLocaleDateString(),
        },
    ]);

    useEffect(() => {
        getData(page, perPage);
    }, [page, perPage]);

    useEffect(() => {
        let interval = null;

        if (isActive) {
            interval = setInterval(() => {
                getData()
            }, intervalDuration);
        } else if (!isActive && interval) {
            clearInterval(interval);
        }

        return () => clearInterval(interval);
    }, [isActive, intervalDuration, activeTab]);

    return (
        <>
            <div className="d-flex justify-content-start align-items-end">
                <div className="mr-5 mb-2">
                    <Button onClick={handleDetail}>ir atrás</Button>
                </div>
                <div className="mr-5 mb-2">
                    <span>Listado de Asignaciones fallidas</span>
                </div>
                {/*<div className="mr-2 mb-2">*/}
                {/*    <span style={{ cursor: 'pointer' }} onClick={() => setIsActive(!isActive)}>*/}
                {/*        <FiZap color={isActive ? 'blue' : 'gray'} title={isActive ? "Recarga Automatica Activa" : "Activar Recarga Automatica"} size={30} />*/}
                {/*    </span>*/}
                {/*</div>*/}
                <div className="mr-2 mb-2">
                    {/*<label>Frecuencia</label>*/}
                    {/*<select disabled={!isActive} className="custom-select" value={intervalDuration} onChange={(e) => setIntervalDuration(e.target.value)}>*/}
                    {/*    <option value="30000">cada 30 segundos</option>*/}
                    {/*    <option value="60000">cada minuto</option>*/}
                    {/*</select>*/}
                </div>
                <div className="mr-2">
                    <Form.Control
                        id="search"
                        type="text"
                        placeholder="Buscar..."
                        aria-label="Buscar"
                        value={filterText}
                        onChange={e => setFilterText(e.target.value)}
                        style={{ marginBottom: '10px' }}
                    />
                </div>
            </div>

            <DataTable
                columns={columns}
                data={filteredData}
                progressPending={loadding}
                noDataComponent={<h6>No hay datos para listar</h6>}
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                onChangeRowsPerPage={handlePerRowsChange}
                //onChangePage={handlePageChange}
                paginationRowsPerPageOptions={[10, 15, 20, 25, 30]}
                actions={actionsMemo}
            />
        </>
    )

}