import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import Select from 'react-select';
import { isNullOrUndefined } from "../../../../../utils/functionsUtils";
import Grid from "../../../../Grid";

const InventoryProductLocation = ({ 
    show, 
    handleClose, 
    locationInfo, 
    reduxGetWarehouses,
    warehouses,
    reduxGetWarehouseById,
    rowEdited,
    reduxGetProductLocation,
    productLocations,
    reduxCreateProductLocation,
    reduxGetWarehouseInventoryByShipper,
    reduxGetLots,
    lots,
    productLocation,
}) => {
    const { control, setValue, handleSubmit, reset, watch } = useForm({ defaultValues: { warehouse: null, col: '', stowage: '', rack: '', level: '', idLot: '' } });
    const [showLocationForm, setShowLocationForm] = useState(false);
    const [offset, setOffset] = useState(10);
    const [search, setSearch] = useState(null);
    const [lotsOptions, setLotsOptions] = useState(null);
    const [selectedLot, setSelectedLot] = useState(null);

    useEffect(() => {
        setShowLocationForm(false);
        setSelectedLot(null);
        reset();
        if (show) {
            reduxGetWarehouses({
                page: 1,
                offset: 1000,
            });

            if (locationInfo && locationInfo.idWarehouse) {
                reduxGetWarehouseById({
                    id: locationInfo.idWarehouse
                });
            }
            
            if (locationInfo && locationInfo.idProduct && locationInfo.idWarehouse) {
                reduxGetProductLocation({
                    idProduct: locationInfo.idProduct,
                    idWarehouse: locationInfo.idWarehouse,
                    page: 1,
                    offset: offset,
                    search: search
                });

                reduxGetLots({
                    idProduct: locationInfo.idProduct,
                    page: 1,
                    offset: 1000
                });
                
            }
        }
    }, [show, locationInfo, reduxGetWarehouses, reduxGetWarehouseById, reduxGetProductLocation, reduxGetLots, offset, search]);

    useEffect(() => {
        if(lots && lots.items) {
            const options = lots.items.map(lot => ({
                value: lot.idLot,
                label: `${lot.idLot} - ${lot.number}`,
            }));
            setLotsOptions(options);
        };
    },[lots]);

    
    useEffect(() => {
        if (!isNullOrUndefined(rowEdited)) {
            setValue('warehouse', {
                value: rowEdited.idWarehouse,
                label: `${rowEdited.name || rowEdited.warehouseCode}`,
            });
        }
    }, [rowEdited, setValue]);

    const onSubmit = (data) => {
        const { col, stowage, rack, level, idLot } = data;
        const selectedLotId = selectedLot ? selectedLot.value : null;
        
        reduxCreateProductLocation({
            ...(col ? { col: col.toUpperCase() } : {}),
            ...(rack ? { rack: rack.toUpperCase() } : {}),
            ...(level ? { level: level.toUpperCase() } : {}),
            stowage: stowage.toUpperCase(),
            idProduct: locationInfo.idProduct,
            idWarehouse: locationInfo.idWarehouse,
            idLot: selectedLotId
        })
        .then(() => {
            setShowLocationForm(false);
            reset();
            setSelectedLot(null);
            setSearch('');
            reduxGetProductLocation({
                idProduct: locationInfo.idProduct,
                idWarehouse: locationInfo.idWarehouse,
                page: 1,
                offset: offset,
                search: search
            });
        });
    
        reduxGetWarehouseInventoryByShipper({
            page: 1,
            shipper: locationInfo.idCompany,
            warehouses: locationInfo.idWarehouse,
        });
    };


    useEffect(() => {
        if(productLocation && locationInfo && locationInfo.idProduct && locationInfo.idWarehouse) {
            setShowLocationForm(false);
            reset();
            setSelectedLot(null);
            setSearch('');
            reduxGetProductLocation({
                idProduct: locationInfo.idProduct,
                idWarehouse: locationInfo.idWarehouse,
                page: 1,
                offset: offset,
                search: search
            });
            reduxGetWarehouseInventoryByShipper({
                page: 1,
                shipper: locationInfo.idCompany,
                warehouses: locationInfo.idWarehouse,
            });
            
        };
    },[productLocation]);

    const customStyles = {
        menu: (base) => ({ ...base, zIndex: 9999 }),
        valueContainer: (baseStyles) => ({ ...baseStyles, maxHeight: "3.5vw", overflowY: "auto" }),
        control: (baseStyles) => ({ ...baseStyles, flexWrap: "nowrap" })
    };

    const columns = [
        {
            title: "Pasillo",
            render: (rowData) => <span>{rowData.col}</span>,
            field: "col",
            searchable: true,
        },
        {
            title: "Rack",
            render: (rowData) => <span>{rowData.rack}</span>,
            field: "rack",
            searchable: true,
        },
        {
            title: "Nivel",
            render: (rowData) => <span>{rowData.level}</span>,
            field: "level",
            searchable: true,
        },
        {
            title: "Estiba",
            render: (rowData) => <span>{rowData.stowage}</span>,
            field: "stowage",
            searchable: true,
        },
        {
            title: "ID de Lote",
            render: (rowData) => <span>{rowData.idLot || ''}</span>,
            field: "idLot",
            searchable: true,
        },
    ];

    const handleSearchChange = (value) => {
        setSearch(value);
        reduxGetProductLocation({
            idProduct: locationInfo.idProduct,
            idWarehouse: locationInfo.idWarehouse,
            page: 1,
            offset: offset,
            search: value
        });
    };

    return (
        <Modal 
            show={show} 
            onHide={() => {
                handleClose();
                setSearch(null);
            }}
            backdrop="static"
            keyboard={false}
            size="xl"
        >
            <Modal.Header closeButton>
                <div className="d-flex justify-content-between align-items-justify w-25">
                    <Modal.Title>Ubicación en Bodega</Modal.Title>
                    <button
                        title='Crear Nueva Ubicación'
                        className="btn btn-success btn-sm btn-circle ml-auto"
                        type="button"                        
                        onClick={() => setShowLocationForm(true)}>
                        <i className='fas fa-plus fa-sm'></i>
                    </button>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="card shadow mb-4">
                    {!showLocationForm && (
                        <>
                            <div className="card-header py-3">
                                <h6 className="m-0 font-weight-bold text-primary">
                                    Ubicaciones
                                </h6>
                            </div>
                            <div className="card-body">
                                <Grid
                                    cols={columns}
                                    data={productLocations ? productLocations.items : []}
                                    page={productLocations ? productLocations.page : 1}
                                    pages={productLocations ? productLocations.totalPages : 1}
                                    total={productLocations ? productLocations.total : 0}
                                    offset={offset}
                                    onChangePage={(page) => reduxGetProductLocation({
                                        idProduct: locationInfo.idProduct,
                                        idWarehouse: locationInfo.idWarehouse,
                                        page: page,
                                        offset: offset,
                                        search: search
                                    })}
                                    onChangeRange={(value) => {
                                        setOffset(value);
                                        reduxGetProductLocation({
                                            idProduct: locationInfo.idProduct,
                                            idWarehouse: locationInfo.idWarehouse,
                                            page: 1,
                                            offset: value,
                                            search: search
                                        });
                                    }}
                                    onChangeSearch={handleSearchChange}
                                />
                            </div>
                        </>
                    )}
                    {showLocationForm && (
                        <div className="card-body">
                            <div className="card-header py-3 d-flex justify-content-between align-items-center">
                                <h6 className="m-0 font-weight-bold text-primary">
                                    Crear nueva ubicación
                                </h6>
                                <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={<Tooltip>Cancelar Creación</Tooltip>}
                                >
                                    <Button variant="secondary" size="sm" 
                                    onClick={() => {
                                        setShowLocationForm(false);
                                        reset();
                                        reduxGetProductLocation({
                                            idProduct: locationInfo.idProduct,
                                            idWarehouse: locationInfo.idWarehouse,
                                            page: 1,
                                            offset: offset,
                                            search: search
                                        });
                                    }}>x</Button>
                                </OverlayTrigger>
                            </div>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="row">
                                    <div className="form-group col-md-2">
                                        <label htmlFor="col" className="form-label">Pasillo</label>
                                        <Controller
                                            control={control}
                                            name="col"
                                            render={({ field }) => (
                                                <input type='text' {...field} className='form-control'/>
                                            )}
                                        />
                                    </div>
                                    <div className="form-group col-md-2">
                                        <label htmlFor="rack" className="form-label">Rack</label>
                                        <Controller
                                            control={control}
                                            name="rack"
                                            render={({ field }) => (
                                                <input type='text' {...field} className='form-control'/>
                                            )}
                                        />
                                    </div>
                                    <div className="form-group col-md-2">
                                        <label htmlFor="level" className="form-label">Nivel</label>
                                        <Controller
                                            control={control}
                                            name="level"
                                            render={({ field }) => (
                                                <input type='text' {...field} className='form-control'/>
                                            )}
                                        />
                                    </div>
                                    <div className="form-group col-md-3">
                                        <label htmlFor="stowage" className="form-label">Estiba *</label>
                                        <Controller
                                            control={control}
                                            name="stowage"
                                            rules={{ required: 'Este campo es requerido' }}
                                            render={({ field }) => (
                                                <input type='text' {...field} className='form-control'/>
                                            )}
                                        />
                                    </div>
                                    <div className="form-group col-md-3">
                                        <label htmlFor="idLot" className="form-label">Lote</label>
                                        <Controller
                                            control={control}
                                            name="idLot"
                                            render={({ field }) => (
                                                <Select
                                                    options={lotsOptions}
                                                    {...field}
                                                    placeholder="Seleccione un lote"
                                                    onChange={(option) => setSelectedLot(option)}
                                                    value={selectedLot}
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                                <Button variant="primary" type="submit">
                                    Guardar
                                </Button>
                            </form>
                        </div>
                    )}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => {
                    handleClose();
                    setSearch(null);
                }}>
                    Cerrar
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

const mapStateToProps = (state) => {
    return {
        warehouses: state.warehouseState.warehouses,
        warehouse: state.warehouseState.warehouse,
        productLocations: state.warehouseState.productLocations,
        rowEdited: state.warehouseState.rowEdited,
        productLocation: state.warehouseState.productLocation,
        lots: state.warehouseState.lots,
        lot: state.warehouseState.lot,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        reduxGetWarehouses: (payload) =>
            dispatch({
                type: "FETCH_WAREHOUSES_REQUEST",
                value: payload,
            }),
        reduxGetWarehouseById: (payload) =>
            dispatch({
                type: "READ_WAREHOUSE_REQUEST",
                value: payload,
            }),
        reduxGetProductLocation: (payload) =>
            dispatch({
                type: "GET_PRODUCTLOCATION_REQUEST",
                value: payload,
            }),
        reduxCreateProductLocation: (payload) =>
            dispatch({
                type: "CREATE_PRODUCTLOCATION_REQUEST",
                value: payload,
            }),
        reduxGetWarehouseInventoryByShipper: (payload) =>
            dispatch({
                type: "READ_WAREHOUSE_SHIPPERINVENTORY_REQUEST",
                value: payload,
            }),
        reduxGetLots: (payload) => 
            dispatch({ 
                type: "GET_LOTS_REQUEST", 
                value: payload 
            }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(InventoryProductLocation);