import {call, put, takeLatest} from "redux-saga/effects";
import request from "../../utils/request";

function* getAllRoles(payload) {
  try {
    yield put({
      type: "FETCH_ROLES_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/roles?page=${payload.value.page}&search=${payload.value.search}&offset=${payload.value.offset}${payload.value.isActive ? '&isActive=' + payload.value.isActive: '' }`;
    const headers = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "FETCH_ROLE_SUCCESS",
      value: response,
    });
    yield put({
      type: "HIDE_LOADING",
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed load",
        message: "Failed load Roles",
      },
    });
    yield put({
      type: "FETCH_ROLE_ERROR",
    });
  }
}

function* postRole(payload) {
  try {
    yield put({ type: "CREATE_ROLE_REQUESTING" });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/roles`;

    const headers = {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(payload.value),
    };

    const response = yield call(request, requestURL, headers);

    yield put({
      type: "CREATE_ROLE_SUCCESS",
      value: response,
    });
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "success",
        title: "Successful save",
        message: "Successful save Role",
      },
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed save",
        message: "Failed save Role",
      },
    });
    yield put({
      type: "CREATE_ROLE_ERROR",
    });
  }
}

function* getRoleById(payload) {
  try {
    yield put({
      type: "READ_ROLE_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/roles/${payload.value.id}`;

    const headers = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
    };
    const response = yield call(request, requestURL, headers);
    console.log(response, "este es el responde")
    yield put({
      type: "READ_ROLE_SUCCESS",
      value: response,
    });
    yield put({
      type: "HIDE_LOADING",
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "READ_ROLE_ERROR",
    });
  }
}

function* deleteRoleById(payload) {
  try {
    yield put({
      type: "DELETE_ROLE_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/roles/changeState/${payload.value.idRole}`;

    const headers = {
      method: "PATCH",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(payload.value),
    };

    const response = yield call(request, requestURL, headers);

    yield put({
      type: "DELETE_ROLE_SUCCESS",
      value: response,
    });
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "success",
        title: "Successful disable",
        message: "Successful disable Role",
      },
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed disable",
        message: "Failed disable Role",
      },
    });
    yield put({
      type: "DELETE_ROLE_ERROR",
    });
  }
}

function* updateRole(payload) {
  try {
    yield put({
      type: "UPDATE_ROLE_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/roles/${payload.value.id}`;

    const headers = {
      method: "PATCH",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(payload.value),
    };

    const response = yield call(request, requestURL, headers);

    yield put({
      type: "UPDATE_ROLE_SUCCESS",
      value: response,
    });
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "success",
        title: "Successful update",
        message: "Successful update Role",
      },
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed update",
        message: "Failed update Role",
      },
    });
    yield put({
      type: "UPDATE_ROLE_ERROR",
    });
  }
}

export function* watchRole() {
  yield takeLatest("FETCH_ROLES_REQUEST", getAllRoles);
  yield takeLatest("CREATE_ROLE_REQUEST", postRole);
  yield takeLatest("READ_ROLE_REQUEST", getRoleById);
  yield takeLatest("DELETE_ROLE_REQUEST", deleteRoleById);
  yield takeLatest("UPDATE_ROLE_REQUEST", updateRole);
}
