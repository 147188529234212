import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Sidebar from "../../components/Navigation/Sidebar";
import Topbar from "../../components/Navigation/Topbar";
import Grid from "../../components/Grid";
import { connect } from "react-redux";
import { Button, Modal } from "react-bootstrap";
import { Show } from "../../hooks/Show";
import { useAuth } from "../../hooks/useAuth";
import "react-datepicker/dist/react-datepicker.css";

const Lot = ({
  reduxGetLots,
  lots,
  reduxGetLot,
  lot,
  reduxPostLot,
  rowEdited,
  reduxDeleteLot,
  rowDeleted,
  reduxPatchLot,
  rowUpdated,
  reduxResetLotForm,
  reduxGetCompanies,
  companies,
  reduxDeleteSoftLot,
  rowDeletedSoft,
}) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
    setValue,
  } = useForm();
  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(10);
  const { session } = useAuth();
  const [show, setShow] = useState(false);

  const handleClose = () => {
    reduxResetLotForm();
    setShow(false);
  };
  const handleShow = () => {
    reduxResetLotForm();
    reset();
    setShow(true);
  };
  const [search, setSearch] = useState("");

  const columns = [
    {
      title: "#",
      render: (rowData) => {
        return <span>{rowData.idLot}</span>;
      },
      field: 'idLot',
      searchable: true
    },
    {
      title: "Nombre",
      render: (rowData) => {
        return <span>{rowData.name}</span>;
      },
      field: 'name',
      searchable: true
    },
    {
      title: "ID Externo",
      render: (rowData) => {
        return <span>{rowData.externalId}</span>;
      },
      field: 'externalId',
      searchable: true
    },
    {
      title: "Opciones",
      render: (rowData) => {
        return (
          <>
            {rowData.isActive ? (
              <>
                <Show when="feature:edit-lot">
                  <button
                    title="Editar"
                    className="btn btn-primary btn-sm  btn-circle mr-2"
                    type="button"
                    onClick={(e) => handleOpen(rowData)}
                  >
                    <i className="fas fa-edit fa-xs"></i>
                  </button>
                </Show>
                <Show when="feature:disabled-lot">
                  <button
                    title="Desactivar"
                    className="btn btn-danger btn-sm btn-circle mr-2"
                    type="button"
                    onClick={(e) => handleDelete(rowData)}
                  >
                    <i className="fas fa-times-circle fa-xs"></i>
                  </button>
                </Show>
                <Show when="feature:delete-lot">
                  <button
                    title="Eliminar"
                    className="btn btn-danger btn-sm btn-circle"
                    type="button"
                    onClick={(e) => handleDeleteSoft(rowData)}
                  >
                    <i className="fas fa-trash-alt fa-xs"></i>
                  </button>
                </Show>
              </>
            ) : (
              <Show when="feature:enabled-lot">
                <button
                  title="Activar"
                  className="btn btn-primary btn-sm  btn-circle mr-2"
                  type="button"
                  onClick={(e) => handleActive(rowData)}
                >
                  <i className="fas fa-check-circle fa-xs"></i>
                </button>
              </Show>
            )}
          </>
        );
      },
    },
  ];

  const handleOpen = (row) => {
    reduxGetLot({
      id: row.idLot,
    });
  };

  const handleActive = (row) => {
    if (!row.isActive) {
      reduxDeleteLot(row);
    }
  };

  useEffect(() => {
    if (rowEdited) {
      setShow(true);
      setValue("name", rowEdited.name);
      setValue("externalId", rowEdited.externalId);
      setValue("idCompany", rowEdited.idCompany);
    }
  }, [rowEdited]);

  const handleDelete = (row) => {
    reduxDeleteLot(row);
  };

  useEffect(() => {
    reduxGetLots({
      page: 1,
      offset: offset,
      search: "",
    });
    reduxGetCompanies({
      page: 1,
      offset: 10000,
      search: "",
      isActive: true,
    });
  }, []);

  useEffect(() => {
    reduxGetLots({
      page: currentPage,
      offset: offset,
      search: search,
    });
  }, [currentPage]);

  const onSubmit = (data) => {
    if (rowEdited) {
      reduxPatchLot({ ...data, id: rowEdited.idLot });
    } else {
      reduxPostLot({
        ...data,
        externalId: data.externalId ? data.externalId : null,
      });
    }
    reset();
    reduxResetLotForm();
    setShow(false);
  };

  useEffect(() => {
    if (lot || rowUpdated || rowDeleted || rowDeletedSoft) {
      if (
        (lot && lot.message == undefined) ||
        (rowUpdated && rowUpdated.message == undefined) ||
        (rowDeletedSoft && rowDeletedSoft.message == undefined) ||
        rowDeleted
      ) {
        reduxGetLots({
          page: currentPage,
          offset: offset,
          search: search,
        });
        reduxResetLotForm();
      }
    }
  }, [lot, rowUpdated, rowDeleted, rowDeletedSoft]);

  const handleDeleteSoft = (row) => {
    reduxDeleteSoftLot(row);
  };

  return (
    <div>
      <div id="wrapper">
        <Sidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Topbar />
            <div className="container-fluid">
              <div className="d-sm-flex align-items-center mb-1">
                <h1 className="h3 mb-0 text-gray-800 mr-3">Lotes</h1>
                <Show when="feature:create-lot">
                  <button
                    className="btn btn-primary btn-circle"
                    type="button"
                    onClick={handleShow}
                  >
                    <i className="fas fa-plus fa-sm"></i>
                  </button>
                </Show>
              </div>
              <p className="mb-4">Módulo de Administración de lotes</p>
              <div className="card shadow mb-4">
                <div className="card-header py-3">
                  <h6 className="m-0 font-weight-bold text-primary">
                    Listado de Lotes
                  </h6>
                </div>
                <div className="card-body">
                  <Grid
                    cols={columns}
                    data={
                      lots && Object.keys(lots).length > 0 ? lots.items : []
                    }
                    page={
                      lots && Object.keys(lots).length > 0
                        ? Number(lots.page)
                        : currentPage
                    }
                    pages={
                      lots && Object.keys(lots).length > 0
                        ? Number(lots.totalPages)
                        : 0
                    }
                    total={
                      lots && lots.hasOwnProperty("total") ? lots.total : 0
                    }
                    offset={offset}
                    onChangePage={(page) => setCurrentPage(page)}
                    onChangeRange={(value) => {
                      setOffset(value);
                      reduxGetLots({
                        page: 1,
                        offset: value,
                        search: search,
                      });
                    }}
                    defaultValue={search}
                    onChangeSearch={(value) => {
                      setSearch(value);
                      reduxGetLots({
                        page: 1,
                        offset: offset,
                        search: value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Modal
          show={show}
          onHide={handleClose}
          onEscapeKeyDown={handleClose}
          backdrop="static"
          keyboard={true}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <Modal.Header closeButton>
              <Modal.Title>{rowEdited ? "Editar" : "Nuevo"} Lote</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-group">
                <label htmlFor="name" className="form-label">
                  Nombre
                </label>
                <input
                  id="name"
                  type="text"
                  className={`form-control form-control-user ${
                    errors.name && "is-invalid"
                  }`}
                  {...register("name", { required: true })}
                />
                {errors.name && (
                  <span className="invalid-feedback">
                    El nombre es requerido
                  </span>
                )}
              </div>

              <div className="form-group">
                <label htmlFor="externalId" className="form-label">
                  ID externo
                </label>
                <input
                  id="externalId"
                  type="text"
                  className={`form-control form-control-user ${
                    errors.externalId && "is-invalid"
                  }`}
                  {...register("externalId")}
                />
              </div>
              {session && session.role.idCompany == null && (
                <div className="form-group">
                  <label htmlFor="idCompany" className="form-label">
                    Empresa
                  </label>
                  <select
                    {...register("idCompany", {
                      required: true,
                    })}
                    id="idCompany"
                    className={`custom-select ${
                      errors.idCompany && "is-invalid"
                    }`}
                  >
                    <option value={""}>Seleccionar…</option>
                    {companies &&
                      Object.keys(companies).length > 0 &&
                      companies.items
                        .filter((f) => f.isActive == true)
                        .map((ele, key) => (
                          <option key={key} value={ele.idCompany}>
                            {ele.description}
                          </option>
                        ))}
                  </select>
                  {errors.idCompany && (
                    <span className="invalid-feedback">
                      La empresa es requerida
                    </span>
                  )}
                </div>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cancelar
              </Button>
              <Button variant="primary" type="submit">
                Guardar
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    lots: state.lotState.lots,
    lot: state.lotState.lot,
    rowEdited: state.lotState.rowEdited,
    rowDeleted: state.lotState.rowDeleted,
    rowUpdated: state.lotState.rowUpdated,
    companies: state.companyState.companies,
    rowDeletedSoft: state.lotState.rowDeletedSoft,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    reduxGetLots: (payload) =>
      dispatch({
        type: "FETCH_LOTS_REQUEST",
        value: payload,
      }),
    reduxPostLot: (payload) =>
      dispatch({
        type: "CREATE_LOT_REQUEST",
        value: payload,
      }),
    reduxGetLot: (payload) =>
      dispatch({
        type: "READ_LOT_REQUEST",
        value: payload,
      }),
    reduxDeleteLot: (payload) =>
      dispatch({
        type: "DELETE_LOT_REQUEST",
        value: payload,
      }),
    reduxPatchLot: (payload) =>
      dispatch({
        type: "UPDATE_LOT_REQUEST",
        value: payload,
      }),
    reduxResetLotForm: () =>
      dispatch({
        type: "RESET_LOT_FORM",
      }),
    reduxGetCompanies: (payload) =>
      dispatch({
        type: "FETCH_COMPANIES_REQUEST",
        value: payload,
      }),
    reduxDeleteSoftLot: (payload) =>
      dispatch({
        type: "DELETE_SOFT_LOT_REQUEST",
        value: payload,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Lot);
