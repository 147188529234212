const Statuses = {
    "REPROG": { label: "Reprogramado", class: { badge: "badge-info", bg: "danger", icon: "zmdi zmdi-calendar-check" } },
    "CREADO": { label: "Creado", class: { badge: "badge-info", bg: "info", icon: "zmdi zmdi-assignment-alert" } },
    "ENESPE": { label: "En Espera", class: { badge: "badge-warning", bg: "warning", icon: "zmdi zmdi-assignment-alert" } },
    "ASIGNA": { label: "Asignado", class: { badge: "badge-info", bg: "info", icon: "zmdi zmdi-assignment" } },
    "ALISTA": { label: "Alistamiento", class: { badge: "badge-success", bg: "success", icon: "zmdi zmdi-store" } },
    "DESPAC": { label: "Despachado", class: { badge: "badge-success", bg: "success", icon: "zmdi zmdi-bike" } },
    "CENDIS": { label: "Centro de Distribución", class: { badge: "badge-success", bg: "success", icon: "zmdi zmdi-assignment-alert" } },
    "ENTRAN": { label: "En Tránsito", class: { badge: "badge-success", bg: "success", icon: "zmdi zmdi-airplane" } },
    "CENRET": { label: "Centro Retorno", class: { badge: "badge-warning", bg: "warning", icon: "zmdi zmdi-store" } },
    "ENTREG": { label: "Entregado", class: { badge: "badge-success", bg: "success", icon: "zmdi zmdi-badge-check" } },
    "NOENTR": { label: "No Entregado", class: { badge: "badge-danger", bg: "danger", icon: "zmdi zmdi-assignment-alert" } },
    "ANULAD": { label: "Anulado", class: { badge: "badge-danger", bg: "danger", icon: "zmdi zmdi-alert-octagon" } },
}

const Novelties = {
    "N-EPDI": { label: "En proceso de inspeccion", badgeClass: "badge-warning" },
    "N-RVU": { label: "Restriccion vias urbanas", badgeClass: "badge-warning" },
    "N-GURE": { label: "Guia reprogramada" },
    "N-PPA": { label: "Pendiente por asignar" },
    "N-SOOM": { label: "Sin operador - mensajero" },
    "N-NOIN": { label: "No integra" },
    "N-PAOS": { label: "Por alistar - salir a ruta" },
    "N-PACK": { label: "Packing" },
    "N-PICK": { label: "Picking" },
    "N-AAUM": { label: "Asignado a un mensajero" },
    "N-AAUO": { label: "Asignado a un operador" },
    "N-RPO": { label: "Recogido por operador" },
    "N-EHOP": { label: "En hub operador para distribucion" },
    "N-ETAC": { label: "En transito a cliente final" },
    "N-TACD": { label: "Transito a ciudad de destino" },
    "N-EHOD": { label: "En hub operador para devolucion" },
    "N-ENEF": { label: "Entrega efectiva" },
    "N-PCVT": { label: "Problemas con vehiculo" },
    "N-PCVN": { label: "Problemas con vehiculo" },
    "N-CLIM": { label: "Clima" },
    "N-ZRPD": { label: "Zona roja - peligro de transitar" },
    "N-RES": { label: "Recoger en sucursal" },
    "N-EES": { label: "Entregado en sucursal" },
    "N-ORPU": { label: "Orden publico" },
    "N-REAN": { label: "Recibido anteriormente" },
    "N-DEST": { label: "Destruccion" },
    "N-INCA": { label: "Incautado" },
    "N-FALL": { label: "Fallecido" },
    "N-LOCE": { label: "Local cerrado" },
    "N-CNL": { label: "Cliente no localizado" },
    "N-CNRS": { label: "Cliente no recibe - sin dinero" },
    "N-CNRV": { label: "Cliente no recibe - valor diferente" },
    "N-CNRN": { label: "Cliente no recibe -no se permitio abrir el paquete" },
    "N-DIIN": { label: "Direccion incompleta" },
    "N-DIER": { label: "Direccion errada" },
    "N-DAR": { label: "Devolucion al remitente" },
    "N-DET": { label: "Devolucion en transito" },
    "N-PRRO": { label: "Producto robado" },
    "N-PRPE": { label: "Producto perdido" },
    "N-PRDA": { label: "Producto danado" },
    "N-NUDE": { label: "Nunca despachado" },
    "N-CNR": { label: "Cliente no recibe - rechazado" },
    "N-VNAA": { label: "Vehiculo no alcanza a realizar entrega" },
    "N-CNLD": { label: "Cliente no localizado - Desconocido" },
    "N-CNLI": { label: "Cliente no localizado - Informacion de guia errada" },
    "N-ENIN": { label: "En investigacion" },
    "N-FDD": { label: "Falta de datos" },
    "N-SECM": { label: "Supera el COD maximo permitido" },
    "N-SICO": { label: "Sin cobertura" },
    "N-SPEC": { label: "Solicitado" },
}

export const getStatusBadgeByCode = (code) => {
    const status = Statuses[code]
    return getBadge(status)
}

export const getStatusDataByCode = (code) => {
    const status = Statuses[code]
    return status
}

const getBadge = (data) => {
    if (data) {
        return (
            <span className={`badge badge-pill ${data.class.badge}`}>
                {data.label}
            </span>
        );
    }
    return null;
};
