import React, { forwardRef, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Sidebar from "../../../components/Navigation/Sidebar";
import Topbar from "../../../components/Navigation/Topbar";
import Grid from "../../../components/Grid";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import moment from "moment-timezone";

const SessionsReport = ({
  cities,
  reduxGetCities,
  sessionsReports,
  reduxGetSessionsReports,
}) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
    setValue,
    getValues,
  } = useForm();
  const [startDate, setStartDate] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(10);
  const [search, setSearch] = useState("");

  const DatePickerCustom = forwardRef(({ value, onClick, onChange }, ref) => (
    <>
      <input
        className={`form-control form-control-user ${
          errors.date && "is-invalid"
        }`}
        onClick={onClick}
        onChange={onChange}
        ref={ref}
        value={value}
        autoComplete="Off"
        {...register("date", { required: true })}
      />
      {errors.date && (
        <span className="invalid-feedback">La fecha es requerida</span>
      )}
    </>
  ));

  const handledChange = (date) => {
    setStartDate(date);
    setValue("date", moment(date).tz("America/Bogota").format("MM-DD-YYYY"));
  };

  const onSubmit = (data) => {
    reduxGetSessionsReports({
      ...data,
      page: 1,
      search: "",
      offset: offset,
    });
  };

  useEffect(() => {
    reduxGetCities({
      page: 1,
      search: "",
      offset: 100000,
    });
  }, []);

  const columns = [
    {
      title: "Mensajero",
      render: (rowData) => {
        return <span>{rowData.name}</span>;
      },
    },
    {
      title: "Asignaciones",
      render: (rowData) => {
        return <span>{rowData.assigned}</span>;
      },
    },
    {
      title: "Ciudad",
      render: (rowData) => {
        return <span>{rowData.city}</span>;
      },
    },
    {
      title: "Gestionadas",
      render: (rowData) => {
        return <span>{rowData.managed}</span>;
      },
    },
    {
      title: "Sin gestionar",
      render: (rowData) => {
        return <span>{rowData.unmanaged}</span>;
      },
    },
    {
      title: "Última fecha inicio sesión",
      render: (rowData) => {
        return <span>{rowData.lastsession}</span>;
      },
    },
  ];

  useEffect(() => {
    if (
      currentPage &&
      sessionsReports &&
      Object.keys(sessionsReports).length > 0
    ) {
      let filters = {
        page: currentPage,
        offset: offset,
        search: search,
      };
      if (getValues("idCity")) {
        filters = {
          ...filters,
          idCity: getValues("idCity"),
        };
      }
      if (getValues("date")) {
        filters = {
          ...filters,
          date: new Date(getValues("date")),
        };
      }
      reduxGetSessionsReports(filters);
    }
  }, [currentPage]);

  return (
    <div>
      <div id="wrapper">
        <Sidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Topbar />
            <div className="container-fluid">
              <div className="d-sm-flex align-items-center mb-1">
                <h1 className="h3 mb-0 text-gray-800 mr-3">
                  Reporte de Sesión de Mensajeros
                </h1>
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="col s1 m1">
                    <div className="form-group">
                      <label htmlFor="from" className="form-label">
                        Fecha
                      </label>
                      <div>
                        <DatePicker
                          selected={startDate}
                          onChange={(date) => handledChange(date)}
                          dateFormat="MM-dd-yyyy"
                          customInput={<DatePickerCustom />}
                          maxDate={new Date()}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col s3 m3">
                    <div className="form-group">
                      <label htmlFor="idCity" className="form-label">
                        Ciudad
                      </label>
                      <select
                        {...register("idCity")}
                        id="idCity"
                        className={`custom-select ${
                          errors.idCity && "is-invalid"
                        }`}
                      >
                        <option value={""}>Seleccionar…</option>
                        {cities &&
                          Object.keys(cities).length > 0 &&
                          cities.items.map((ele, key) => (
                            <option key={key} value={ele.idCity}>
                              {ele.description}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <div className="col s12 m1">
                    <Button
                      variant="primary"
                      style={{ marginTop: "32px" }}
                      type="submit"
                      id="btnSearch"
                    >
                      Buscar
                    </Button>
                  </div>
                </div>
              </form>
              <div className="card shadow mb-4">
                <div className="card-body">
                  <Grid
                    cols={columns}
                    data={
                      sessionsReports && Object.keys(sessionsReports).length > 0
                        ? sessionsReports.items
                        : []
                    }
                    page={
                      sessionsReports && Object.keys(sessionsReports).length > 0
                        ? Number(sessionsReports.page)
                        : currentPage
                    }
                    pages={
                      sessionsReports && Object.keys(sessionsReports).length > 0
                        ? Number(sessionsReports.totalPages)
                        : 0
                    }
                    total={
                      sessionsReports && sessionsReports.hasOwnProperty("total")
                        ? sessionsReports.total
                        : 0
                    }
                    offset={offset}
                    onChangePage={(page) => setCurrentPage(page)}
                    onChangeRange={(value) => {
                      setOffset(value);
                      let filters = {
                        page: 1,
                        offset: value,
                        search: search,
                      };
                      if (getValues("idCity")) {
                        filters = {
                          ...filters,
                          idCity: getValues("idCity"),
                        };
                      }
                      if (getValues("date")) {
                        filters = {
                          ...filters,
                          date: new Date(getValues("date")),
                        };
                      }
                      reduxGetSessionsReports(filters);
                    }}
                    defaultValue={search}
                    onChangeSearch={(value) => {
                      setSearch(value);
                      let filters = {
                        page: 1,
                        offset: offset,
                        search: value,
                      };
                      if (getValues("idCity")) {
                        filters = {
                          ...filters,
                          idCity: getValues("idCity"),
                        };
                      }
                      if (getValues("date")) {
                        filters = {
                          ...filters,
                          date: new Date(getValues("date")),
                        };
                      }
                      reduxGetSessionsReports(filters);
                    }}
                  />
                  {sessionsReports && Object.keys(sessionsReports).length > 0 && (
                    <div>
                      <label style={{ fontSize: "1rem", fontWeight: "bold" }}>
                        {`Total de Asignaciones del día ${getValues("date")}: `}
                      </label>
                      <label
                        style={{
                          fontSize: "2rem",
                          fontWeight: "bold",
                          marginLeft: "10px",
                        }}
                      >
                        {sessionsReports &&
                        Object.keys(sessionsReports).length > 0
                          ? Number(sessionsReports.totalAssigned)
                          : 0}
                      </label>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    cities: state.cityState.cities,
    sessionsReports: state.reportState.sessionsReports,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    reduxGetCities: (payload) =>
      dispatch({
        type: "FETCH_CITIES_REQUEST",
        value: payload,
      }),
    reduxGetSessionsReports: (payload) =>
      dispatch({
        type: "FETCH_SESSIONSREPORTS_REQUEST",
        value: payload,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SessionsReport);
