import AddressFinishedReport from './addressFinished'
import {connect} from "react-redux";
import {addressCloseConfirmationReportActions} from "../../../redux/reducers/AddressCloseConfirmationReducer";


const mapStateToProps = (state, ownProps) => {
    return {
        addressCloseConfirmationsReport: state.addressCloseConfirmation.report.data,
        filterData: state.addressCloseConfirmation.filterFields,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getAll: (payload) => {
            dispatch({
                type: addressCloseConfirmationReportActions.FECTCH_CLOSECONFIRMATION_REPORT,
                value: payload
            })
        },
        download: (payload) => {
            dispatch({

            })
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddressFinishedReport)