const initialState = {
  requestingFetchBranches: false,
  successfulFetchBranches: false,
  errorFetchBranches: false,
  requestingFetchBranchesByOperator: true,
  successfulFetchBranchesByOperator: false,
  errorFetchBranchesByOperator: false,
  branches: {},
  requestingFetchBranchesWithoutWarehouse: false,
  successfulFetchBranchesWithoutWarehouse: false,
  errorFetchBranchesWithoutWarehouse: false,
  branchesWithoutWarehouse: {},
  requestingCreateBranch: false,
  successfulCreateBranch: false,
  errorsCreateBranch: false,
  requestingUpdateBranch: false,
  successfulUpdateBranch: false,
  errorsUpdateBranch: false,
  upsertResult: null,
  requestingReadBranch: false,
  successfulReadBranch: false,
  errorsReadBranch: false,
  rowEdited: null,
  requestingDeleteBranch: false,
  successfulDeleteBranch: false,
  errorsDeleteBranch: false,
  rowDeleted: null,
  requestingFetchAvailableDSP: true,
  successfulFetchAvailableDSP: false,
  errorFetchAvailableDSP: false,
  availableDSP: [],
  requestingFetchHubOperators: true,
  successfulFetchHubOperators: false,
  errorFetchHubOperators: false,
  hubOperators: [],
  requestingFetchAllBranches: false,
  successfulFetchAllBranches: false,
  errorFetchAllBranches: false,
  allBranches: {},
};

const branchReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_BRANCHES_REQUESTING":
      return {
        ...state,
        requestingFetchBranches: true,
        successfulFetchBranches: false,
        errorFetchBranches: false,
      };
    case "FETCH_BRANCH_SUCCESS":
      return {
        ...state,
        errorFetchBranches: false,
        requestingFetchBranches: false,
        successfulFetchBranches: true,
        branches: action.value,
      };
    case "FETCH_BRANCH_ERROR":
      return {
        ...state,
        errorFetchBranches: true,
        requestingFetchBranches: false,
        successfulFetchBranches: false,
      };
    case "FETCH_ALL_BRANCHES_REQUESTING":
      return {
        ...state,
        requestingFetchAllBranches: true,
        successfulFetchAllBranches: false,
        errorFetchAllBranches: false,
      };
    case "FETCH_ALL_BRANCHES_SUCCESS":
      return {
        ...state,
        requestingFetchAllBranches: false,
        successfulFetchAllBranches: true,
        errorFetchAllBranches: false,
        allBranches: action.value,
      };
    case "FETCH_ALL_BRANCHES_ERROR":
      return {
        ...state,
        requestingFetchAllBranches: false,
        successfulFetchAllBranches: false,
        errorFetchAllBranches: true,
      };
    case "FETCH_BRANCH_BY_OPERATOR_REQUESTING":
      return {
        ...state,
        requestingFetchBranchesByOperator: true,
        successfulFetchBranchesByOperator: false,
        errorFetchBranchesByOperator: false,
      };
    case "FETCH_BRANCH_BY_OPERATOR_SUCCESS":
      return {
        ...state,
        errorFetchBranchesByOperator: false,
        requestingFetchBranchesByOperator: false,
        successfulFetchBranchesByOperator: true,
        branchesByOperator: action.value,
      };
    case "FETCH_BRANCH_BY_OPERATOR_ERROR":
      return {
        ...state,
        errorFetchBranchesByOperator: true,
        requestingFetchBranchesByOperator: false,
        successfulFetchBranchesByOperator: false,
      };
    case "FETCH_BRANCHES_WITHOUTWAREHOUSE_REQUESTING":
      return {
        ...state,
        requestingFetchBranchesWithoutWarehouse: true,
        successfulFetchBranchesWithoutWarehouse: false,
        errorFetchBranchesWithoutWarehouse: false,
      };
    case "FETCH_BRANCHES_WITHOUTWAREHOUSE_SUCCESS":
      return {
        ...state,
        requestingFetchBranchesWithoutWarehouse: false,
        successfulFetchBranchesWithoutWarehouse: true,
        errorFetchBranchesWithoutWarehouse: false,
        branchesWithoutWarehouse: action.value,
      };
    case "FETCH_BRANCHES_WITHOUTWAREHOUSE_ERROR":
      return {
        ...state,
        requestingFetchBranchesWithoutWarehouse: false,
        successfulFetchBranchesWithoutWarehouse: false,
        errorFetchBranchesWithoutWarehouse: true,
      };
    case "CREATE_BRANCH_REQUESTING":
      return {
        ...state,
        requestingCreateBranch: true,
        successfulCreateBranch: false,
        errorsCreateBranch: false,
      };
    case "CREATE_BRANCH_SUCCESS":
      return {
        ...state,
        errorsCreateBranch: false,
        requestingCreateBranch: false,
        successfulCreateBranch: true,
        upsertResult: action.value,
      };
    case "CREATE_BRANCH_ERROR":
      return {
        ...state,
        errorsCreateBranch: true,
        requestingCreateBranch: false,
        successfulCreateBranch: false,
        upsertResult: action.value,
      };
    case "UPDATE_BRANCH_REQUESTING":
      return {
        ...state,
        requestingUpdateBranch: true,
        successfulUpdateBranch: false,
        errorsUpdateBranch: false,
      };
    case "UPDATE_BRANCH_SUCCESS":
      return {
        ...state,
        errorsUpdateBranch: false,
        requestingUpdateBranch: false,
        successfulUpdateBranch: true,
        upsertResult: action.value,
      };
    case "UPDATE_BRANCH_ERROR":
      return {
        ...state,
        errorsUpdateBranch: true,
        requestingUpdateBranch: false,
        successfulUpdateBranch: false,
        upsertResult: action.value,
      };
    case "READ_BRANCH_REQUESTING":
      return {
        ...state,
        requestingReadBranch: true,
        successfulReadBranch: false,
        errorsReadBranch: false,
      };
    case "READ_BRANCH_SUCCESS":
      return {
        ...state,
        errorsReadBranch: false,
        requestingReadBranch: false,
        successfulReadBranch: true,
        rowEdited: action.value,
      };
    case "READ_BRANCH_ERROR":
      return {
        ...state,
        errorsReadBranch: true,
        requestingReadBranch: false,
        successfulReadBranch: false,
      };
    case "DELETE_BRANCH_REQUESTING":
      return {
        ...state,
        requestingDeleteBranch: true,
        successfulDeleteBranch: false,
        errorsDeleteBranch: false,
      };
    case "DELETE_BRANCH_SUCCESS":
      return {
        ...state,
        errorsDeleteBranch: false,
        requestingDeleteBranch: false,
        successfulDeleteBranch: true,
        rowDeleted: action.value,
      };
    case "DELETE_BRANCH_ERROR":
      return {
        ...state,
        errorsDeleteBranch: true,
        requestingDeleteBranch: false,
        successfulDeleteBranch: false,
      };
    case "FETCH_AVAILABLE_DSP_REQUESTING":
      return {
        ...state,
        requestingFetchAvailableDSP: true,
        successfulFetchAvailableDSP: false,
        errorFetchAvailableDSP: false,
      };
    case "FETCH_AVAILABLE_DSP_SUCCESS":
      return {
        ...state,
        requestingFetchAvailableDSP: false,
        successfulFetchAvailableDSP: true,
        errorFetchAvailableDSP: false,
        availableDSP: action.value,
      };
    case "FETCH_AVAILABLE_DSP_ERROR":
      return {
        ...state,
        requestingFetchAvailableDSP: false,
        successfulFetchAvailableDSP: false,
        errorFetchAvailableDSP: true,
      };
    case "FETCH_HUB_OPERATORS_REQUESTING":
      return {
        ...state,
        requestingFetchHubOperators: true,
        successfulFetchHubOperators: false,
        errorFetchHubOperators: false,
      };
    case "FETCH_HUB_OPERATORS_SUCCESS":
      return {
        ...state,
        requestingFetchHubOperators: false,
        successfulFetchHubOperators: true,
        errorFetchHubOperators: false,
        hubOperators: action.value,
      };
    case "FETCH_HUB_OPERATORS_ERROR":
      return {
        ...state,
        requestingFetchHubOperators: false,
        successfulFetchHubOperators: false,
        errorFetchHubOperators: true,
      };
    case "RESET_HUB_OPERATORS":
      return {
        ...state,
        hubOperators: []
      };
    case "RESET_BRANCH_FORM":
      return {
        ...state,
        requestingCreateBranch: false,
        successfulCreateBranch: false,
        errorsCreateBranch: false,
        branch: null,
        requestingFetchAllBranches: false,
        successfulFetchAllBranches: false,
        errorFetchAllBranches: false,
        allBranches: [],
        requestingUpdateBranch: false,
        successfulUpdateBranch: false,
        errorsUpdateBranch: false,
        rowUpdated: null,
        requestingReadBranch: false,
        successfulReadBranch: false,
        errorsReadBranch: false,
        rowEdited: null,
        requestingDeleteBranch: false,
        successfulDeleteBranch: false,
        errorsDeleteBranch: false,
        rowDeleted: null,
      };
    case "RESET":
      return initialState;
    default:
      return state;
  }
};

export default branchReducer;
