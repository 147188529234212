import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { addressCardinalActions } from "../../redux/reducers/addressCardinalReducer";
import { addressInsideTypeActions } from "../../redux/reducers/addressInsideTypeReducer";
import { addressNomenclatureActions } from "../../redux/reducers/addressNomenclatureReducer";
import { useFormContext } from "react-hook-form";
import { addressRoadTypeActions } from "../../redux/reducers/addressRoadTypeReducer";
import Select from 'react-select'

const InputList = ({
  id,
  type,
  reduxGetAddressInsideTypes,
  reduxGetAddressNomenclatures,
  reduxGetAddressCardinals,
  reduxGetAddressRoadTypes,
  addressRoadTypeList,
  addressNomenclatures,
  addressCardinals,
  addressInsideTypes,
  required,
  value,
  name,
}) => {
  const [data, setData] = useState({});
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext();

  useEffect(() => {
    let perpage = {
      page: 1,
      offset: 1000,
      search: "",
      isActive: true,
    };
    switch (type) {
      case SelectConstEnabled._addressRoadType:
        reduxGetAddressRoadTypes(perpage);
        break;
      case SelectConstEnabled._addressNomenclature:
        reduxGetAddressNomenclatures(perpage);
        break;
      case SelectConstEnabled._addressInsideType:
        reduxGetAddressInsideTypes(perpage);
        break;
      case SelectConstEnabled._addressCardinal:
        reduxGetAddressCardinals(perpage);
        break;
    }
  }, []);

  useEffect(() => {
    switch (type) {
      case SelectConstEnabled._addressRoadType:
        setData(addressRoadTypeList);
        break;
      case SelectConstEnabled._addressNomenclature:
        setData(addressNomenclatures);
        break;
      case SelectConstEnabled._addressInsideType:
        setData(addressInsideTypes);
        break;
      case SelectConstEnabled._addressCardinal:
        setData(addressCardinals);
        break;
    }
    if (value) {
      setValue(id, value);
    }
  }, [
    addressRoadTypeList,
    addressNomenclatures,
    addressCardinals,
    addressInsideTypes,
  ]);

  return (
    <>
    <Select
      isClearable={true}
      {...register(id, { required: required })}
      id={id}
      name={id}
      onChange={(value) => setValue(id, value?.value || '')}
      options={
      data && Object.keys(data).length > 0
          ? [
          ...data.items
          .map((ele) => ({                                    
              value: ele.name,
              label: ele.name,
          })),
          ]
          : []
      }
    />
      {errors[id] && name && (
        <span className="invalid-feedback">{`${name} es requerido`}</span>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    addressRoadTypeList: state.addressRoadType.addressRoadTypes,
    addressNomenclatures: state.addressNomenclature.addressNomenclatures,
    addressCardinals: state.addressCardinal.addressCardinals,
    addressInsideTypes: state.addressInsideType.addressInsideTypes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    reduxGetAddressCardinals: (payload) =>
      dispatch({
        type: addressCardinalActions.FETCH_ADDRESSES_CARDINAL_POINTS_REQUEST,
        value: payload,
      }),
    reduxGetAddressInsideTypes: (payload) =>
      dispatch({
        type: addressInsideTypeActions.FETCH_ADDRESSINSIDETYPE_REQUEST,
        value: payload,
      }),
    reduxGetAddressNomenclatures: (payload) =>
      dispatch({
        type: addressNomenclatureActions.FETCH_ADDRESSNOMENCLATURE_REQUEST,
        value: payload,
      }),
    reduxGetAddressRoadTypes: (payload) =>
      dispatch({
        type: addressRoadTypeActions.FETCH_ADDRESSROADTYPE_REQUEST,
        value: payload,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InputList);

export class SelectConstEnabled {
  static _addressCardinal = "addressCardinal";
  static _addressInsideType = "addressInsideType";
  static _addressNomenclature = "addressNomenclature";
  static _addressRoadType = "addressRoadType";
}
