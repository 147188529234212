import React, {useEffect, useState} from "react";
import {GoogleMap, Polygon, useLoadScript,} from "@react-google-maps/api";

const Map = ({ item }) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_MAPS_KEY}`,
  });
  const [mapRef, setMapRef] = useState(null);
  const [center] = useState({ lat: 44.076613, lng: -98.362239833 });
  const [zoom, setZoom] = useState(3);
  const [coords, setCoords] = useState([]);
  const [properties, setProperties] = useState(null);

  const renderMap = () => {
    return (
      <div className="container-fluid p-0 overflow-hidden">
        <div className="row no-gutters">
          <div className={"col-md-12"}>
            <GoogleMap
              onLoad={loadHandler}
              center={center}
              zoom={zoom}
              mapContainerStyle={{
                height: "300px",
                width: "100%",
              }}
            >
              {coords.length > 0 && (
                <Polygon
                  paths={coords}
                  options={{
                    strokeColor: properties ? properties.stroke : "#2A2A57",
                    strokeWeight: properties ? properties["stroke-width"] : 2,
                    strokeOpacity: properties
                      ? properties["stroke-opacity"]
                      : 0.8,
                    strokeColor: properties ? properties.stroke : "#2A2A57",
                    fillColor: properties ? properties.fill : "#000",
                    fillOpacity: properties ? properties["fill-opacity"] : 0.35,
                  }}
                />
              )}
            </GoogleMap>
          </div>
        </div>
      </div>
    );
  };

  const loadHandler = (map) => {
    setMapRef(map);
  };

  useEffect(() => {
    if (window.google) {
      setProperties(item.data.newObject.properties);
      let arrayCoord = item.data.newObject.geometry.coordinates
        ? item.data.newObject.geometry.coordinates[0].map((c) => {
          return { lat: c[1], lng: c[0] };
        })
        : item.data.newObject.geometry[1]
          ? item.data.newObject.geometry[1].geometries[0].map((c) => {
            return { lat: c[1], lng: c[0] };
          })
          : [];

      setCoords(arrayCoord);
      const bounds = new window.google.maps.LatLngBounds();
      arrayCoord.forEach((location) => {
        bounds.extend(
          new window.google.maps.LatLng(location.lat, location.lng)
        );
      });
      if (mapRef != null) {
        mapRef.fitBounds(bounds);
      }
    }
  }, [mapRef, item]);

  return (
    <div className="container">
      {item != null && (
        <div className="card-body">{isLoaded ? renderMap() : null}</div>
      )}
    </div>
  );
};

export default Map;
