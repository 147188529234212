import React, { useEffect, useMemo, useState, useContext } from 'react';
import { Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import Select from 'react-select';

import Grid from '../../components/Grid';
import { PermissionContext } from '../../hooks/PermissionContext';
import { getStatusBadgeByCode } from "../../utils/StatusUtils";
import { isNullOrUndefindedAndEmpty } from '../../utils/pantallaUtil';
import { connect } from 'react-redux';

const TableComponent = (
  {
    reduxGetStatuses,
    statuses,
    data,
    handleConfirm,
    handleConfirmAll,
    searchRedux,
    camposDeFiltro,
    paginate
  }) => {

  const { hasPermission } = useContext(PermissionContext);

  const [rowSelected, setRowSelected] = useState([]);
  const [stateSelected, setStateSelected] = useState('');
  const { setValue, register } = useForm();

  useEffect(() => {
    reduxGetStatuses({
      page: 1,
      search: '',
      offset: 1000,
    });
  }, [])

  useEffect(() => {
    let filters = {
      ...camposDeFiltro,
      state: stateSelected.value,
      page: paginate.currentPage,
      offset: paginate.offset,
      search: paginate.search,
    };
    if (
      (camposDeFiltro.startDate != undefined && camposDeFiltro.endDate != undefined) ||
      !isNullOrUndefindedAndEmpty(filters.search)
    ) {
      searchRedux(filters);
    }
  }, [paginate.currentPage]);

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      let filters = {
        ...camposDeFiltro,
        state: stateSelected.value,
        page: 1,
        offset: paginate.offset,
        search: paginate.search,
      };
      searchRedux(filters);
    }
    if (stateSelected.value === '') {
      setRowSelected([]);
    }
  }, [stateSelected]);

  useEffect(() => {
    setRowSelected([]);
  }, [data]);

  useEffect(() => { }, [rowSelected]);

  const selectOne = (el, data) => {
    let id = data.idAddress;
    let isChecked = el.currentTarget.checked;
    if (isChecked) {
      setRowSelected((current) => {
        return [...current, id.toString()];
      });
    } else {
      setRowSelected((current) => {
        return current.filter((el) => el !== id.toString());
      });
    }
  };

  const selectAll = (el) => {
    if (el.currentTarget.checked) {
      const dataRow = data && Object.keys(data).length > 0 ? data.items : [];
      let onlyIdsAddress = dataRow.map((el) => {
        return el.idAddress.toString();
      });
      setRowSelected(onlyIdsAddress);
      setValue('routesChecked', onlyIdsAddress);
    } else {
      setRowSelected([]);
      setValue('routesChecked', []);
    }
  };

  const columns = useMemo(
    () => [
      {
        title: (rowData) => {
          return (
            <>
              <input
                type={'checkbox'}
                disabled={stateSelected.value === ''}
                onClick={(el) => selectAll(el)}
                id={'all'}
                defaultChecked={false}
                {...register('all')}
              />
            </>
          );
        },
        render: (rowData) => {
          return (
            <>
              <input
                name={'routesChecked'}
                key={rowData.idAddress}
                type='checkbox'
                disabled={stateSelected.value === ''}
                checked={rowSelected.some((el) => el === rowData.idAddress.toString())}
                value={rowData.idAddress}
                onChange={(el) => selectOne(el, rowData)}
              />{' '}
            </>
          );
        },
      },
      {
        title: '#',
        render: (rowData) => {
          return <span>{rowData.idAddress}</span>;
        },
        field: 'idAddress',
        searchable: true,
      },
      {
        title: 'Guía',
        render: (rowData) => {
          return <span>{rowData.trackingId}</span>;
        },
        field: 'trackingId',
        searchable: true,
      },
      ...(hasPermission('feature:show-recipient-address')
        ? [
          {
            title: 'Dirección Destinatario',
            render: (rowData) => {
              return <span>{rowData.address}</span>;
            },
            field: 'address',
            searchable: true,
          },
        ]
        : []),
      {
        title: 'Recaudo',
        render: (rowData) => {
          return <span>{rowData.collection}</span>;
        },
        field: 'collection',
        searchable: true,
      },
      {
        title: 'Fecha',
        render: (rowData) => {
          return <span>{rowData.assignedDate}</span>;
        },
      },
      {
        title: 'Estado',
        render: (rowData) => {
          return getStatusBadgeByCode(rowData.state);
        },
        field: 'state',
        searchable: false,
      },
      {
        title: 'Opciones',
        render: (rowData) => {
          return (
            <Button
              size={'sm'}
              variant='outline-primary'
              onClick={() => handleConfirm(rowData)}>
              Confirmar
            </Button>
          );
        },
      },
    ],
    [rowSelected, stateSelected, data]
  );

  const handleChangeState = (el) => {
    setStateSelected(el);
  };

  const actionsButtons = {
    isVisible: true,
    buttons: [
      {
        isVisible: data && Object.keys(data).length > 0,
        label: 'Confirmar Todos',
        classNames: 'btn-primary',
        render: () => {
          return (
            <div className='form-group mb-0 col-3'>
              <label>Estado</label>
              <Select
                value={stateSelected}
                onChange={handleChangeState}
                options={
                  statuses && Object.keys(statuses).length > 0
                    ? [
                      ...statuses.items
                        .filter((f) => f.closureStates === true)
                        .map((ele) => ({
                          value: ele.code,
                          label: ele.name,
                        })),
                    ]
                    : []
                }
              />
            </div>
          );
        },
      },
      {
        isVisible: rowSelected.length > 0 && data && Object.keys(data).length > 0,
        onclick: () => handleConfirmAll(rowSelected, stateSelected.value),
        label: 'Confirmar Todos',
        classNames: 'btn-primary',
      },
    ],
  };

  return (
    <>
      <Grid
        cols={columns}
        actionsButton={actionsButtons}
        data={data && Object.keys(data).length > 0 ? data.items : []}
        page={data && Object.keys(data).length > 0 ? Number(data.page) : paginate.currentPage}
        pages={data && Object.keys(data).length > 0 ? Number(data.totalPages) : 0}
        total={data && data.hasOwnProperty('total') ? data.total : 0}
        offset={paginate.offset}
        onChangePage={(page) => paginate.setCurrentPage(page)}
        onChangeRange={(value) => {
          paginate.setOffset(value);
          let filters = {
            page: 1,
            offset: value,
            search: paginate.search,
          };
          if (
            (camposDeFiltro.courier != undefined && camposDeFiltro.startDate != undefined && camposDeFiltro.endDate) ||
            !isNullOrUndefindedAndEmpty(filters.search)
          ) {
            searchRedux({
              ...camposDeFiltro,
              ...filters,
              state: stateSelected.value,
            });
          }
        }}
        defaultValue={paginate.search}
        onChangeSearch={(value) => {
          paginate.setSearch(value);
          let filters = {
            ...camposDeFiltro,
            state: stateSelected.value,
            page: 1,
            offset: paginate.offset,
            search: value,
          };
          if (
            (camposDeFiltro.courier != undefined && camposDeFiltro.startDate != undefined && camposDeFiltro.endDate) ||
            !isNullOrUndefindedAndEmpty(filters.search)
          ) {
            searchRedux(filters);
          }
        }}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    statuses: state.statusesState.statuses,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    reduxGetStatuses: (payload) =>
      dispatch({
        type: "FETCH_NEW_STATUS_REQUEST",
        value: payload,
      }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TableComponent);
