import React, { forwardRef, useEffect, useRef, useState, useContext } from 'react';
import { connect } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import moment from 'moment-timezone';
import { Button, Modal } from 'react-bootstrap';
import Select from 'react-select';
import 'react-datepicker/dist/react-datepicker.css';

import Sidebar from '../../components/Navigation/Sidebar';
import Topbar from '../../components/Navigation/Topbar';
import Grid from '../../components/Grid';
import Timeline from '../../components/Timeline';
import { Show } from '../../hooks/Show';
import { PermissionContext } from '../../hooks/PermissionContext';
import formatDateWithCustomFormat, { FormatsDate } from '../../utils/formatDate';
import { getStatusBadgeByCode } from '../../utils/StatusUtils';
import { HideByRole } from '../../utils/functionsUtils';
import { useAuth } from '../../hooks/useAuth';

const Swal = require('sweetalert2');
const AssignAddress = ({
  reduxGetRecords,
  records,
  reduxGetClients,
  clients,
  reduxGetStatuses,
  statuses,
  reduxGetAddresses,
  addressesAvailable,
  reduxGetCouriers,
  couriers,
  route,
  reduxPostRoute,
  reduxResetRouteForm,
  reduxGetZoneNeighborhoods,
  reduxGetCities,
  reduxResetAddressesEvents,
}) => {
  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
    setValue,
    getValues,
  } = useForm();

  const { session } = useAuth();

  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(10);
  const [search, setSearch] = useState('');
  const [addressError, setAddressError] = useState(false);
  const [dateError, setDateError] = useState(false);
  const [courierError, setCourierError] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [deliveryDate, setDeliveryDate] = useState(null);

  const state = useRef({});
  state.current = watch('state', []);

  const senders = useRef({});
  senders.current = watch('senders', '');

  const [showTimeline, setShowTimeline] = useState(false);
  const [idAddressSelected, setIdAddressSelected] = useState(null);
  const [idUser, setIdUser] = useState(null);
  const selectInputRef = useRef();

  const { hasPermission } = useContext(PermissionContext);

  const DatePickerCustom = forwardRef(({ value, onClick, onChange }, ref) => (
    <>
      <input
        className='form-group form-control'
        onClick={onClick}
        onChange={onChange}
        ref={ref}
        value={value}
        {...register('assignedDate')}
      />
      {errors.date && <span className='invalid-feedback'>La fecha es requerida</span>}
    </>
  ));

  const CustomDateRangePicker = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      className="form-control"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
      value={value}
      autoComplete="Off"
    />
  ));

  const DatePickerCustomDeliveryDate = forwardRef(({ value, onClick, onChange, readOnly }, ref) => (
    <input
      disabled={readOnly}
      className='form-control'
      onClick={onClick}
      onChange={onChange}
      ref={ref}
      value={value}
      autoComplete='Off'
    />
  ));

  useEffect(() => {
    reduxGetAddresses({
      page: 1,
      offset: offset,
      search: '',
      isActive: true,
    });
    reduxGetClients();
    reduxGetRecords({
      page: 1,
      search: "",
      offset: 1000,
    });
    reduxGetStatuses({
      page: 1,
      search: '',
      offset: 1000,
    });
    reduxGetCouriers({});
    reduxGetCities({
      page: 1,
      offset: 100000,
      search: '',
      isActive: true,
    });
    reduxGetZoneNeighborhoods({
      page: 1,
      offset: 100000,
      search: '',
      isActive: true,
    });
    onClear();
  }, []);

  useEffect(() => {
    let filters = {
      page: currentPage,
      offset: offset,
      search: search,
      isActive: true,
    };
    if (senders.current) {
      filters = {
        ...filters,
        senders: String(senders.current.map(item => item.value)),
      };
    }
    if (state.current) {
      filters = {
        ...filters,
        state: state.current.value,
      };
    }
    if (fromDate && toDate) {
      filters = {
        ...filters,
        from: formatDateWithCustomFormat(fromDate, { format: FormatsDate.FULL24 }),
        to: formatDateWithCustomFormat(toDate, { format: 'YYYY-MM-DD 23:59:59' }),
      };
    }
    if (deliveryDate) {
      filters = {
        ...filters,
        deliveryDate: moment(deliveryDate).tz('America/Bogota').format('YYYY-MM-DD'),
      };
    }
    reduxGetAddresses(filters);
  }, [currentPage]);

  useEffect(() => {
    if (route) {
      const courierFullName = route.courierSelected.firstName + ' ' + route.courierSelected.lastName + ' ';
      Swal.fire(
        'Proceso exitoso!',
        (route.total == 1 ? 'Se asignó ' + route.total + ' registro ' : 'Se asignaron ' + route.total + ' registros ') +
        'al mensajero ' +
        courierFullName,
        'success'
      );
      reduxGetAddresses({
        page: currentPage,
        offset: offset,
        search: search,
        isActive: true,
      });
      reduxGetCouriers({});
      reduxResetRouteForm();
      onClear();
    }
  }, [route]);

  // useEffect(() => {
  // }, [state.current, fromDate, toDate, deliveryDate, senders.current]);

  const searchByFilter = () => {
    let filters = {
      page: 1,
      offset: 10,
      search: '',
      isActive: true,
    };
    if (fromDate && toDate) {
      if (toDate < fromDate) {
        Swal.fire('Información', 'La fecha inicial debe ser  menor a la fecha final , intenta de nuevo', 'info');
        return;
      }
      filters = {
        ...filters,
        from: formatDateWithCustomFormat(fromDate, { format: FormatsDate.FULL24 }),
        to: formatDateWithCustomFormat(toDate, { format: 'YYYY-MM-DD 23:59:59' }),
      };
    }
    if (deliveryDate) {
      filters = {
        ...filters,
        deliveryDate: moment(deliveryDate).tz('America/Bogota').format('YYYY-MM-DD'),
      };
    }
    if (senders.current) {
      filters = {
        ...filters,
        senders: String(senders.current.map(item => item.value)),
      };
    }
    if (state.current) {
      filters = {
        ...filters,
        state: state.current.value,
      };
    }
    reduxGetAddresses(filters);
    setCurrentPage(1);
    setOffset(10);
  }

  const handledChange = (date) => {
    setStartDate(date);
    setValue('date', moment(date).tz('America/Bogota').format('YYYY-MM-DD'));
  };

  const handledChangeDatePicker = (dates) => {
    const [start, end] = dates;

    setFromDate(start);
    setToDate(end);

    setValue(
      "from",
      moment(start).tz("America/Bogota").format('YYYY-MM-DD')
    );
    setValue(
      "to",
      moment(end).tz("America/Bogota").format('YYYY-MM-DD')
    );
  };

  const handledDeliveryDate = (date) => {
    setDeliveryDate(date);
    setValue('deliveryDate', moment(date).tz('America/Bogota').format('YYYY-MM-DD'));
  };

  const checkAll = (e) => {
    if (e.target.checked) {
      setValue(
        'addressesChecked',
        addressesAvailable.items.map((address) => {
          return address.idAddress.toString();
        })
      );
    } else {
      setValue('addressesChecked', []);
    }
  };

  const selectCourier = (e) => {
    if (e) {
      setIdUser(e.value);
      setCourierError(false);
    }
  };

  const customStyles = {
    menu: (base) => ({ ...base, zIndex: 9999 }),
    valueContainer: (baseStyles) => ({...baseStyles, maxHeight:"3.5vw", overflowY: "auto" }),
    control: (baseStyles) => ({ ...baseStyles, flexWrap: "nowrap"}) // Ajusta el valor según tus necesidades
  };

  const onClear = () => {
    selectInputRef.current.select.clearValue();
  };

  const handleOpenTimeline = (id) => {
    setIdAddressSelected(id);
    setShowTimeline(true);
  };

  const handleCloseTimeline = () => {
    reduxResetAddressesEvents();
    setIdAddressSelected(null);
    setShowTimeline(false);
  };

  const onSubmit = (data) => {
    const objaddressesChecked = getValues('addressesChecked');
    const assignedDate = moment(data.date).tz('America/Bogota').format('YYYY-MM-DD') + ' 00:00:01';

    if (idUser == null || idUser == undefined) {
      setCourierError(true);
      return;
    }
    if (data.addressesChecked == false || data.addressesChecked == undefined) {
      setAddressError(true);
      return;
    }
    if (startDate == null || startDate == undefined) {
      setDateError(true);
      return;
    }
    reduxPostRoute({
      assignedDate: assignedDate,
      addressesChecked: typeof objaddressesChecked == 'string' ? [objaddressesChecked] : objaddressesChecked,
      idUser: idUser,
    });
    reset();
    reduxResetRouteForm();
  };
  
  const columns = [
    {
      title: (rowData) => {
        return (
          <input
            id='all'
            onClick={(e) => checkAll(e)}
            type='checkbox'
            defaultChecked={false}
          />
        );
      },
      render: (rowData) => {
        return (
          <>
            <input
              name='addressesChecked'
              key={rowData.idAddress}
              type='checkbox'
              checked={rowData.isChecked}
              value={rowData.idAddress}
              onChange={(e) => {
                rowData.isChecked = e.target.checked;
              }}
              {...register('addressesChecked')}
            />{' '}
          </>
        );
      },
    },
    {
      title: '#',
      render: (rowData) => {
        return <span>{rowData.idAddress}</span>;
      },
      field: 'idAddress',
      searchable: true,
    },
    {
      title: 'Guía',
      render: (rowData) => {
        return <span>{rowData.trackingId}</span>;
      },
      field: 'trackingId',
      searchable: true,
    },
    ...(hasPermission('feature:show-recipient-address')
      ? [
        {
          title: 'Dirección Destinatario',
          render: (rowData) => {
            return <span>{rowData.address}</span>;
          },
          field: 'address',
          searchable: true,
        },
      ]
      : []),
    {
      title: 'Ciudad',
      render: (rowData) => {
        return <span>{rowData.city ? rowData.city : ''}</span>;
      },
      field: 'C.description',
      searchable: true,
    },
    {
      title: 'Remitente',
      render: (rowData) => {
        return <span>{rowData.company ? rowData.company : ''}</span>;
      },
    },
    ...(hasPermission('feature:show-reference1-address')
      ? [
        {
          title: 'Teléfono Destinatario',
          render: (rowData) => {
            return (
              <span>{rowData.reference1}</span>
            );
          },
          field: 'reference1',
          searchable: true,
        },
      ]
      : []),
    {
      title: 'Observaciones',
      render: (rowData) => {
        return <span>{rowData.reference2}</span>;
      },
      field: 'reference2',
      searchable: true,
      visible: !HideByRole(session?.role.name),
    },
    {
      title: 'Fecha de Entrega',
      render: (rowData) => {
        return (
          <span>
            {rowData.deliveryDate && String(rowData.deliveryDate).includes('/')
              ? moment(String(rowData.deliveryDate).replaceAll('/', '-').substring(0, 10) + ' 00:00:01')
                .tz('America/Bogota')
                .format('DD-MM-YYYY')
              : rowData.deliveryDate
                ? moment(String(rowData.deliveryDate).substring(0, 10) + ' 00:00:01')
                  .tz('America/Bogota')
                  .format('DD-MM-YYYY')
                : ''}
          </span>
        );
      },
    },
    {
      title: 'Estado',
      render: (rowData) => getStatusBadgeByCode(rowData.code),
      field: 'state',
      searchable: false,
    },
    {
      title: 'Opciones',
      render: (rowData) => {
        return (
          <Show when='feature:see-timeline'>
            <button
              title='Timeline'
              className='btn btn-primary btn-sm  btn-circle mr-2'
              type='button'
              onClick={(e) => handleOpenTimeline(rowData.idAddress)}>
              <i className='fas fa-info fa-xs'></i>
            </button>
          </Show>
        );
      },
    },
  ];

  return (
    <>
      <div>
        <div id='wrapper'>
          <Sidebar />
          <div
            id='content-wrapper'
            className='d-flex flex-column'>
            <div id='content'>
              <Topbar />
              <div className='container-fluid'>
                <div className='d-sm-flex align-items-center mb-1'>
                  <h1 className='h3 mb-0 text-gray-800 mr-3'>Asignar Direcciones</h1>
                </div>
                <p className='mb-4'>Módulo para asignar direcciones a un mensajero específico</p>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className='card shadow mb-4'>
                    <div className='card-header py-3'>
                      <h6 className='m-0 font-weight-bold text-primary'>Listado de Direcciones</h6>
                    </div>
                    <div className='card-body'>
                      <div className='row'>
                        <div className='col col-md-2'>
                          <div className='form-group'>
                            <label htmlFor="from" className="form-label">Desde - Hasta</label>
                            <DatePicker
                              selected={fromDate}
                              onChange={handledChangeDatePicker}
                              startDate={fromDate}
                              endDate={toDate}
                              maxDate={new Date()}
                              dateFormat="MM-dd-yyyy"
                              customInput={<CustomDateRangePicker />}
                              selectsRange
                            />
                          </div>
                        </div>
                        <div className='col col-md-2'>
                          <div className='form-group'>
                            <label
                              htmlFor='to'
                              className='form-label'>
                              Fecha de entrega
                            </label>
                            <div>
                              <Controller
                                control={control}
                                name="deliveryDate"
                                render={() => (
                                  <DatePicker
                                    readOnly={!fromDate || !toDate}
                                    selected={deliveryDate}
                                    onChange={(date) => handledDeliveryDate(date)}
                                    dateFormat='MM-dd-yyyy'
                                    customInput={<DatePickerCustomDeliveryDate />}
                                  />
                                )}
                              />
                            </div>
                          </div>
                        </div>
                        <div className='form-group col-md-3'>
                          <label
                            htmlFor='senders'
                            className='form-label'>
                            Remitente
                          </label>
                          <Controller
                            control={control}
                            name="senders"
                            render={({ field }) => (
                              <Select
                                {...field}
                                isDisabled={!fromDate || !toDate}
                                isMulti
                                isClearable={true}
                                styles={customStyles}
                                options={
                                  clients && Object.keys(clients).length > 0
                                    ? clients.items
                                      .filter((f) => f.isActive)
                                      .map((ele, key) => ({
                                        value: ele.idCompany,
                                        label: ele.description,
                                      }))
                                    : []
                                }
                              />
                            )}
                          />
                        </div>
                        <div className='form-group col-md-2'>
                          <label
                            htmlFor='state'
                            className='form-label'>
                            Estado
                          </label>
                          <Controller
                            control={control}
                            name="state"
                            render={({ field }) => (
                              <Select
                                {...field}
                                isDisabled={!fromDate || !toDate}
                                isClearable={true}
                                styles={customStyles}
                                options={
                                  statuses && Object.keys(statuses).length > 0
                                    ? [
                                      ...statuses.items
                                        .filter((f) => f.routeManagement === true)
                                        .map((ele) => ({
                                          value: ele.code,
                                          label: ele.name,
                                        })),
                                    ]
                                    : []
                                }
                              />
                            )}
                          />
                        </div>
                        <div className='form-group col-md-2'>
                          <button class="btn btn-primary" type='button' onClick={searchByFilter} style={{
                            marginTop: '32px',
                          }} >Buscar</button>
                        </div>
                      </div>
                      <Grid
                        className='stickyAddress'
                        cols={columns}
                        data={
                          addressesAvailable && Object.keys(addressesAvailable).length > 0
                            ? addressesAvailable.items
                            : []
                        }
                        page={
                          addressesAvailable && Object.keys(addressesAvailable).length > 0
                            ? Number(addressesAvailable.page)
                            : currentPage
                        }
                        pages={
                          addressesAvailable && Object.keys(addressesAvailable).length > 0
                            ? Number(addressesAvailable.totalPages)
                            : 1
                        }
                        total={
                          addressesAvailable && addressesAvailable.hasOwnProperty('total')
                            ? addressesAvailable.total
                            : 0
                        }
                        offset={offset}
                        onChangePage={(page) => setCurrentPage(page)}
                        onChangeRange={(value) => {
                          setOffset(value);
                          let filters = {
                            page: 1,
                            offset: value,
                            search: search,
                            isActive: true,
                          };
                          if (state.current) {
                            filters = {
                              ...filters,
                              state: state.current.value,
                            };
                          }
                          if (senders.current) {
                            filters = {
                              ...filters,
                              senders: String(senders.current.map(item => item.value)),
                            };
                          }
                          if (fromDate && toDate) {
                            filters = {
                              ...filters,
                              from: formatDateWithCustomFormat(fromDate, { format: FormatsDate.FULL24 }),
                              to: formatDateWithCustomFormat(toDate, { format: 'YYYY-MM-DD 23:59:59' }),
                            };
                          }
                          if (deliveryDate) {
                            filters = {
                              ...filters,
                              deliveryDate: moment(deliveryDate).tz('America/Bogota').format('YYYY-MM-DD'),
                            };
                          }
                          reduxGetAddresses(filters);
                        }}
                        defaultValue={search}
                        onChangeSearch={(value) => {
                          setSearch(value);
                          let filters = {
                            page: 1,
                            offset: 10,
                            search: value,
                            isActive: true,
                          };

                          if (state.current) {
                            filters = {
                              ...filters,
                              state: state.current.value,
                            };
                          }
                          if (senders.current) {
                            filters = {
                              ...filters,
                              senders: String(senders.current.map(item => item.value)),
                            };
                          }
                          if (fromDate && toDate) {
                            filters = {
                              ...filters,
                              from: formatDateWithCustomFormat(fromDate, { format: FormatsDate.FULL24 }),
                              to: formatDateWithCustomFormat(toDate, { format: 'YYYY-MM-DD 23:59:59' }),
                            };
                          }
                          if (deliveryDate) {
                            filters = {
                              ...filters,
                              deliveryDate: moment(deliveryDate).tz('America/Bogota').format('YYYY-MM-DD'),
                            };
                          }
                          reduxGetAddresses(filters);
                        }}
                      />
                    </div>
                  </div>
                  <div className='card shadow mb-4'>
                    <div className='card-body'>
                      <div className='row'>
                        <div className='form-group col-md-3'>
                          <label
                            htmlFor='date'
                            className='form-label'>
                            Fecha de Asignación
                          </label>
                          <div>
                            <DatePicker
                              selected={startDate}
                              onChange={(date) => handledChange(date)}
                              minDate={new Date()}
                              dateFormat='MM-dd-yyyy'
                              customInput={<DatePickerCustom />}
                            />
                          </div>
                        </div>
                        <div className='form-group col-md-6'>
                          <label
                            htmlFor='idUser'
                            className='form-label'>
                            Mensajero
                          </label>
                          <Select
                            isClearable
                            id='idUser'
                            name='idUser'
                            onChange={selectCourier}
                            ref={selectInputRef}
                            options={
                              couriers && Object.keys(couriers).length > 0
                                ? couriers.map((element) => {
                                  return {
                                    value: element.idUser,
                                    label:
                                      element.nameHeadquarters != null
                                        ? `${element.nameHeadquarters} - ${element.firstName} ${element.lastName}`
                                        : `${element.firstName} ${element.lastName}`,
                                  };
                                })
                                : []
                            }
                          />
                          {addressError && (
                            <p
                              style={{
                                color: 'red',
                                fontStyle: 'italic',
                                textAlign: 'center',
                              }}>
                              Debe seleccionar mínimo una dirección.
                            </p>
                          )}
                          {dateError && (
                            <p
                              style={{
                                color: 'red',
                                fontStyle: 'italic',
                                textAlign: 'center',
                              }}>
                              La fecha es requerida.
                            </p>
                          )}
                          {courierError && (
                            <p
                              style={{
                                color: 'red',
                                fontStyle: 'italic',
                                textAlign: 'center',
                              }}>
                              Debe seleccionar un mensajero.
                            </p>
                          )}
                        </div>
                        <div className='form-group col-md-3'>
                          <Button
                            variant='primary'
                            type='submit'
                            style={{ marginTop: '32px' }}>
                            Añadir mensajero a ruta
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Modal
          size='xl'
          show={showTimeline}
          onHide={handleCloseTimeline}
          onEscapeKeyDown={handleCloseTimeline}
          backdrop='static'
          keyboard={true}>
          <Modal.Header closeButton>
            <Modal.Title>Timeline</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Timeline idAddress={idAddressSelected}></Timeline>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    records: state.recordState.records,
    clients: state.companyState.clients,
    statuses: state.statusesState.statuses,
    addressesAvailable: state.addressState.addressesAvailable,
    couriers: state.userState.couriers,
    route: state.routeState.route,
    cities: state.cityState.cities,
    zoneNeighborhoods: state.zoneNeighborhoodState.zoneNeighborhoods,
    addressesByCity: state.addressState.addressesByCity,
    addressesByZone: state.addressState.addressesByZone,
    zonesByCity: state.zoneState.zonesByCity,
    companies: state.companyState.companies,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    reduxGetStatuses: (payload) =>
    dispatch({
      type: "FETCH_NEW_STATUS_REQUEST",
      value: payload,
    }),
    reduxGetRecords: (payload) =>
      dispatch({
        type: "FETCH_RECORDS_REQUEST",
        value: payload,
      }),
    reduxGetClients: (payload) =>
      dispatch({
        type: 'FETCH_COMPANIESBYSHIPPER_REQUEST',
        value: payload,
      }),
    reduxGetZoneNeighborhoods: (payload) =>
      dispatch({
        type: 'FETCH_ZONENEIGHBORHOODS_REQUEST',
        value: payload,
      }),
    reduxGetAddresses: (payload) =>
      dispatch({
        type: 'FETCH_AVAILABLE_ADDRESSES_REQUEST',
        value: payload,
      }),
    reduxGetCouriers: (payload) =>
      dispatch({
        type: 'FETCH_COURIERS_REQUEST',
        value: payload,
      }),
    reduxPostRoute: (payload) =>
      dispatch({
        type: 'CREATE_ROUTE_REQUEST',
        value: payload,
      }),
    reduxResetRouteForm: () =>
      dispatch({
        type: 'RESET_ROUTE_FORM',
      }),
    reduxGetCities: (payload) =>
      dispatch({
        type: 'FETCH_CITIES_REQUEST',
        value: payload,
      }),
    reduxGetZonesByCity: (payload) =>
      dispatch({
        type: 'FETCHBYCITY_ZONE_REQUEST',
        value: payload,
      }),
    reduxGetAddressesByCity: (payload) =>
      dispatch({
        type: 'READBYCITY_ADDRESS_REQUEST',
        value: payload,
      }),
    reduxGetAddressesByZone: (payload) =>
      dispatch({
        type: 'READBYZONE_ADDRESS_REQUEST',
        value: payload,
      }),
    reduxResetAddressesEvents: () =>
      dispatch({
        type: 'RESET_READ_BY_IDADDRESS_ADDRESSES_EVENT',
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AssignAddress);
