import { useEffect, useState } from "react";
import { connect } from "react-redux";

import Grid from "../../components/Grid";
import UpsertAlertType from "../../components/Modal/Alerts/upsertAlertType";
import { Show } from "../../hooks/Show";

const AlertTypes = ({
    activeTab,
    reduxGetAlertTypes,
    alertTypes,
    reduxResetAlertTypeForm,
}) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [offset, setOffset] = useState(10);
    const [search, setSearch] = useState('');

    const [showUpsertAlertTypeModal, setShowUpsertAlertTypeModal] = useState(false);
    const [upsertAlertTypeData, setUpsertAlertTypeData] = useState(null);

    useEffect(() => {
        if(!activeTab) return;

        reduxGetAlertTypes({
            page: currentPage,
            offset: offset,
            search: search,
        })
    }, [activeTab, currentPage, offset, search, reduxGetAlertTypes]);

    const handleOpenUpsertModal = (data) => {
        setUpsertAlertTypeData(data)
        setShowUpsertAlertTypeModal(true);
    }

    const handleCloseUpsertModal = () => {
        setUpsertAlertTypeData(null)
        setShowUpsertAlertTypeModal(false);
        reduxResetAlertTypeForm();
        reduxGetAlertTypes({
            page: currentPage,
            offset: offset,
            search: search,
        })
    }

    const columns = [
        {
            title: "#",
            render: (rowData) => {
                return <span>{rowData.idAlertType}</span>;
            },
        },
        {
            title: "Código",
            render: (rowData) => {
                return <span>{rowData.code}</span>;
            },
            field: "code",
            searchable: true,
        },
        {
            title: "Intentos máximos",
            render: (rowData) => {
                return <span>{rowData.maxAttempts}</span>;
            },
        },
        {
            title: "Acciones",
            render: (rowData) => {
                return (
                    <>
                        <Show when="feature:alerts-type-upsert">
                            <button
                                title="Editar"
                                className="btn btn-primary btn-sm btn-circle mr-2"
                                type="button"
                                onClick={e => handleOpenUpsertModal(rowData)}
                            >
                                <i className="fas fa-edit fa-xs"></i>
                            </button>
                        </Show>
                    </>
                );
            },
        },
    ];

    return (
        <>
            <div className="card-body">
                <Show when="feature:alerts-type-upsert">
                    <div className="pb-3">
                        <button className='btn btn-primary' onClick={() => handleOpenUpsertModal()}>Nuevo tipo de alerta</button>
                    </div>
                </Show>
                <Grid
                    cols={columns}
                    data={
                        alertTypes && Object.keys(alertTypes).length > 0 ? alertTypes.items : []
                    }
                    page={
                        alertTypes && Object.keys(alertTypes).length > 0
                            ? Number(alertTypes.page)
                            : currentPage
                    }
                    pages={
                        alertTypes && Object.keys(alertTypes).length > 0
                            ? Number(alertTypes.totalPages)
                            : 0
                    }
                    total={
                        alertTypes && alertTypes.hasOwnProperty("total") ? alertTypes.total : 0
                    }
                    offset={offset}
                    onChangePage={(page) => setCurrentPage(page)}
                    onChangeRange={(value) => {
                        setOffset(value);
                        reduxGetAlertTypes({
                            page: 1,
                            offset: value,
                            search: search,
                        });
                    }}
                    defaultValue={search}
                    onChangeSearch={(value) => {
                        setSearch(value);
                        reduxGetAlertTypes({
                            page: 1,
                            offset: offset,
                            search: value,
                        });
                    }}
                />
            </div>
            <UpsertAlertType show={showUpsertAlertTypeModal} handleClose={handleCloseUpsertModal} alertTypeData={upsertAlertTypeData} />
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        alertTypes: state.alertTypeState.alertTypes,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        reduxGetAlertTypes: (payload) =>
            dispatch({
                type: "FETCH_ALERT_TYPES_REQUEST",
                value: payload,
            }),
        reduxResetAlertTypeForm: () =>
            dispatch({
                type: "RESET_ALERT_TYPE_FORM"
            }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AlertTypes);