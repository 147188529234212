import React, { useEffect, useState } from "react";
import ContentWrapper from "../../../components/Common/ContentWrapper";
import FormFilter from "./formFilter";
import TableFinished from "./tableFinished";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import moment from "moment-timezone";
const Swal = require("sweetalert2");

const AddressFinishedReport = ({
  getAll,
  addressCloseConfirmationsReport,
  filterData,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(10);
  const [search, setSearch] = useState("");
  const [dataFilter, setDataFilter] = useState({});

  const handleFilter = (data) => {
    if (data.startDate && data.endDate) {
      if (data.endDate < data.startDate) {
        Swal.fire(
          "Información",
          "La fecha inicial debe ser  menor a la fecha final , intenta de nuevo",
          "info"
        );
        return;
      }
    }
    let perpage = {
      ...data,
      page: 1,
      offset: 10,
      search: "",
    };
    setDataFilter(data);
    getAll(perpage);
  };

  const handleDonwload = () => {
    const token = window.localStorage.getItem("token");
    let _data = dataFilter;

    fetch(`${process.env.REACT_APP_API_URL}/api/v1/reports/addressFinished`, {
      method: "POST",
      body: JSON.stringify(_data),
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      }),
    })
      .then((response) => response.blob())
      .then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download =
          moment(new Date()).tz("America/Bogota").format("YYYY-MM-DD") +
          "-addressFinished-report.xlsx";
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
  };

  return (
    <ContentWrapper>
      <div className="d-sm-flex align-items-center mb-3">
        <h1 className="h3 mb-0 text-gray-800 mr-3">Reporte de cierre</h1>
        <div>
          <OverlayTrigger
            placement="top"
            delay={{ show: 50, hide: 50 }}
            overlay={<Tooltip id="button-tooltip">Descargar</Tooltip>}
          >
            <button
              disabled={
                !(
                  !!addressCloseConfirmationsReport &&
                  Object.keys(addressCloseConfirmationsReport).length > 0 &&
                  addressCloseConfirmationsReport.items.length > 0
                )
              }
              className="btn btn-info btn-circle"
              type="button"
              onClick={handleDonwload}
            >
              <i className="fas fa-file-download fa-sm"></i>
            </button>
          </OverlayTrigger>
        </div>
      </div>
      <FormFilter onSubmit={handleFilter} />
      <div className="card pb-0 mt-3">
        <div className="card-body">
          <TableFinished
            data={addressCloseConfirmationsReport}
            paginate={{
              currentPage,
              offset,
              search,
              setCurrentPage,
              setOffset,
              setSearch,
            }}
            camposDeFiltro={dataFilter}
            searchRedux={getAll}
          />
        </div>
      </div>
    </ContentWrapper>
  );
};

export default AddressFinishedReport;
