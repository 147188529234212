const initialState = {
  requestingFetchAlerts: false,
  successfulFetchAlerts: false,
  errorFetchAlerts: false,
  alerts: [],
  requestingFetchDaemonAlerts: false,
  successfulFetchDaemonAlerts: false,
  errorFetchDaemonAlerts: false,
  daemonAlerts: [],
  requestingFetchDaemonAlertLog: false,
  successfulFetchDaemonAlertLog: false,
  errorFetchDaemonAlertLog: false,
  daemonAlertLog: [],
  requestingFetchOneAlert: false,
  successfulFetchOneAlert: false,
  errorFetchOneAlert: false,
  alert: null,
  requestingCreateAlert: false,
  successfulCreateAlert: false,
  errorCreateAlert: false,
  createResult: null,
  requestingUpdateAlert: false,
  successfulUpdateAlert: false,
  errorUpdateAlert: false,
  updateResult: null,
  requestingResendAlert: false,
  successfulResendAlert: false,
  errorResendAlert: false,
  showAlert: false,
  type: '',
  title: '',
  message: '',
};

const alertReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_ALERTS_REQUESTING":
      return {
        ...state,
        requestingFetchAlerts: true,
        successfulFetchAlerts: false,
        errorFetchAlerts: false,
      };
    case "FETCH_ALERTS_SUCCESS":
      return {
        ...state,
        requestingFetchAlerts: false,
        successfulFetchAlerts: true,
        errorFetchAlerts: false,
        alerts: action.value,
      };
    case "FETCH_ALERTS_ERROR":
      return {
        ...state,
        requestingFetchAlerts: false,
        successfulFetchAlerts: false,
        errorFetchAlerts: true,
      };
    case "FETCH_ONE_ALERT_REQUESTING":
      return {
        ...state,
        requestingFetchOneAlert: true,
        successfulFetchOneAlert: false,
        errorFetchOneAlert: false,
      };
    case "FETCH_ONE_ALERT_SUCCESS":
      return {
        ...state,
        requestingFetchOneAlert: false,
        successfulFetchOneAlert: true,
        errorFetchOneAlert: false,
        alert: action.value,
      };
    case "FETCH_ONE_ALERT_ERROR":
      return {
        ...state,
        requestingFetchOneAlert: false,
        successfulFetchOneAlert: false,
        errorFetchOneAlert: true,
      };
    case "FETCH_DAEMON_ALERTS_REQUESTING":
      return {
        ...state,
        requestingFetchDaemonAlerts: true,
        successfulFetchDaemonAlerts: false,
        errorFetchDaemonAlerts: false,
      };
    case "FETCH_DAEMON_ALERTS_SUCCESS":
      return {
        ...state,
        requestingFetchDaemonAlerts: false,
        successfulFetchDaemonAlerts: true,
        errorFetchDaemonAlerts: false,
        daemonAlerts: action.value,
      };
    case "FETCH_DAEMON_ALERTS_ERROR":
      return {
        ...state,
        requestingFetchDaemonAlerts: false,
        successfulFetchDaemonAlerts: false,
        errorFetchDaemonAlerts: true,
      };
    case "FETCH_DAEMON_ALERT_LOG_REQUESTING":
      return {
        ...state,
        requestingFetchDaemonAlertLog: true,
        successfulFetchDaemonAlertLog: false,
        errorFetchDaemonAlertLog: false,
      };
    case "FETCH_DAEMON_ALERT_LOG_SUCCESS":
      return {
        ...state,
        requestingFetchDaemonAlertLog: false,
        successfulFetchDaemonAlertLog: true,
        errorFetchDaemonAlertLog: false,
        daemonAlertLog: action.value,
      };
    case "FETCH_DAEMON_ALERT_LOG_ERROR":
      return {
        ...state,
        requestingFetchDaemonAlertLog: false,
        successfulFetchDaemonAlertLog: false,
        errorFetchDaemonAlertLog: true,
      };
    case "CREATE_ALERT_REQUESTING":
      return {
        ...state,
        requestingCreateAlert: true,
        successfulCreateAlert: false,
        errorCreateAlert: false,
      };
    case "CREATE_ALERT_SUCCESS":
      return {
        ...state,
        requestingCreateAlert: false,
        successfulCreateAlert: true,
        errorCreateAlert: false,
        createResult: action.value,
      };
    case "CREATE_ALERT_ERROR":
      return {
        ...state,
        requestingCreateAlert: false,
        successfulCreateAlert: false,
        errorCreateAlert: true,
      };
    case "UPDATE_ALERT_REQUESTING":
      return {
        ...state,
        requestingUpdateAlert: true,
        successfulUpdateAlert: false,
        errorUpdateAlert: false,
      };
    case "UPDATE_ALERT_SUCCESS":
      return {
        ...state,
        requestingUpdateAlert: false,
        successfulUpdateAlert: true,
        errorUpdateAlert: false,
        updateResult: action.value,
      };
    case "UPDATE_ALERT_ERROR":
      return {
        ...state,
        requestingUpdateAlert: false,
        successfulUpdateAlert: false,
        errorUpdateAlert: true,
      };
    case "RESEND_DAEMON_ALERT_REQUESTING":
      return {
        ...state,
        requestingResendAlert: true,
        successfulResendAlert: false,
        errorResendAlert: false,

      };
    case "RESEND_DAEMON_ALERT_SUCCESS":
      return {
        ...state,
        requestingResendAlert: false,
        successfulResendAlert: true,
        errorResendAlert: false,
      };
    case "RESEND_DAEMON_ALERT_ERROR":
      return {
        ...state,
        requestingResendAlert: false,
        successfulResendAlert: false,
        errorResendAlert: true,
      };
    case "RESET_ALERT_TAB":
      return {
        ...state,
        requestingFetchAlerts: false,
        successfulFetchAlerts: false,
        errorFetchAlerts: false,
        alerts: [],
        requestingFetchOneAlert: false,
        successfulFetchOneAlert: false,
        errorFetchOneAlert: false,
        alert: null,
        requestingCreateAlert: false,
        successfulCreateAlert: false,
        errorCreateAlert: false,
        createResult: null,
      }
    case "SHOW_ALERT":
      return {
        ...state,
        showAlert: true,
        type: action.value.type,
        title: action.value.title,
        message: action.value.message,
      };
    case "RESET_ALERT":
      return {
        ...state,
        showAlert: false,
        type: '',
        title: '',
        message: '',
      };
    default:
      return state;
  }
};

export default alertReducer;
