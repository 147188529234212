import { call, put, takeLatest } from "redux-saga/effects";
import request, { requestAxios } from "../../utils/request";

function* getAllBranchesWithPagination(payload) {
  try {
    yield put({
      type: "FETCH_BRANCHES_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/branches?page=${payload.value.page
      }&search=${payload.value.search}&offset=${payload.value.offset}${payload.value.isActive ? "&isActive=" + payload.value.isActive : ""
      }`;
    const headers = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "HIDE_LOADING",
    });

    yield put({
      type: "FETCH_BRANCH_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed load",
        message: "Failed load branches",
      },
    });
    yield put({
      type: "FETCH_BRANCH_ERROR",
    });
  }
}

function* getAllBranches() {
  try {
    yield put({
      type: "FETCH_ALL_BRANCHES_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/branches/all`;

    const headers = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "FETCH_ALL_BRANCHES_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "FETCH_ALL_BRANCHES_ERROR",
    });
  } finally {
    yield put({
      type: "HIDE_LOADING",
    });
  }
}

function* getBranchesByOperator(payload) {
  try {
    yield put({
      type: "FETCH_BRANCH_BY_OPERATOR_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/branches/findByOperator/${payload.value.idOperator}`;
    const headers = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "HIDE_LOADING",
    });

    yield put({
      type: "FETCH_BRANCH_BY_OPERATOR_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed load",
        message: "Failed load branches",
      },
    });
    yield put({
      type: "FETCH_BRANCH_BY_OPERATOR_ERROR",
    });
  }
}

function* getHubOperators(payload) {
  try {
    yield put({
      type: "FETCH_HUB_OPERATORS_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/branches/${payload.value.idHub}/findOperators`;

    const headers = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "FETCH_HUB_OPERATORS_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed load",
        message: "Failed load branches",
      },
    });
    yield put({
      type: "FETCH_HUB_OPERATORS_ERROR",
    });
  } finally {
    yield put({
      type: "HIDE_LOADING",
    });
  }
}

function* getBranchesWithoutWarehouse(payload) {
  try {
    yield put({
      type: "FETCH_BRANCHES_WITHOUTWAREHOUSE_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/branches/withoutWarehouse`;
    const headers = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "FETCH_BRANCHES_WITHOUTWAREHOUSE_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed load",
        message: "Failed load branches",
      },
    });
    yield put({
      type: "FETCH_BRANCHES_WITHOUTWAREHOUSE_ERROR",
    });
  } finally {
    yield put({
      type: "HIDE_LOADING",
    });
  }
}

function* postBranch(payload) {
  try {
    yield put({ type: "CREATE_BRANCH_REQUESTING" });
    yield put({
      type: "SHOW_LOADING",
    });
    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/branches`;

    const headers = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload.value),
    };

    const response = yield call(requestAxios, requestURL, headers);

    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "success",
        title: "Successful save",
        message: "Successful save branch",
      },
    });

    yield put({
      type: "CREATE_BRANCH_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed save",
        message: "Failed save branch",
      },
    });
    yield put({
      type: "CREATE_BRANCH_ERROR",
      value: error.response
    });
  } finally {
    yield put({
      type: "HIDE_LOADING",
    });
  }
}

function* updateBranch(payload) {
  try {
    yield put({
      type: "UPDATE_BRANCH_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/branches/${payload.value.idBranch}`;

    const headers = {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload.value),
    };

    const response = yield call(requestAxios, requestURL, headers);

    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "success",
        title: "Successful update",
        message: "Successful update branch",
      },
    });

    yield put({
      type: "UPDATE_BRANCH_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed update",
        message: "Failed update branch",
      },
    });
    yield put({
      type: "UPDATE_BRANCH_ERROR",
      value: error.response
    });
  } finally {
    yield put({
      type: "HIDE_LOADING",
    });
  }
}

function* getBranchById(payload) {
  try {
    yield put({
      type: "READ_BRANCH_REQUESTING",
    });
    yield put({
      type: "SHOW_LOADING",
    });
    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/branches/${payload.value.id}`;

    const headers = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "HIDE_LOADING",
    });

    yield put({
      type: "READ_BRANCH_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "READ_BRANCH_ERROR",
    });
  }
}

function* fetchAvailableDSP(payload) {
  try {
    yield put({
      type: "FETCH_AVAILABLE_DSP_REQUESTING",
    });
    yield put({
      type: "SHOW_LOADING",
    });
    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/branches/availableDSP`;

    const headers = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "HIDE_LOADING",
    });

    yield put({
      type: "FETCH_AVAILABLE_DSP_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "FETCH_AVAILABLE_DSP_ERROR",
    });
  }
}

function* deleteBranchById(payload) {
  try {
    yield put({
      type: "DELETE_BRANCH_REQUESTING",
    });
    yield put({
      type: "SHOW_LOADING",
    });
    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/branches/changeState/${payload.value.idBranch}`;

    const headers = {
      method: "PATCH",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(payload.value),
    };

    const response = yield call(request, requestURL, headers);

    yield put({
      type: "HIDE_LOADING",
    });

    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "success",
        title: "Successful disable",
        message: "Successful disable branch",
      },
    });

    yield put({
      type: "DELETE_BRANCH_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed disable",
        message: "Failed disable branch",
      },
    });
    yield put({
      type: "DELETE_BRANCH_ERROR",
    });
  }
}

export function* watchBranch() {
  yield takeLatest("FETCH_BRANCHES_REQUEST", getAllBranchesWithPagination);
  yield takeLatest("FETCH_ALL_BRANCHES_REQUEST", getAllBranches);
  yield takeLatest("FETCH_BRANCHES_BY_OPERATOR_REQUEST", getBranchesByOperator);
  yield takeLatest("FETCH_BRANCHES_WITHOUTWAREHOUSE_REQUEST", getBranchesWithoutWarehouse);
  yield takeLatest("CREATE_BRANCH_REQUEST", postBranch);
  yield takeLatest("UPDATE_BRANCH_REQUEST", updateBranch);
  yield takeLatest("READ_BRANCH_REQUEST", getBranchById);
  yield takeLatest("FETCH_AVAILABLE_DSP_REQUEST", fetchAvailableDSP);
  yield takeLatest("DELETE_BRANCH_REQUEST", deleteBranchById);
  yield takeLatest("FETCH_HUB_OPERATORS_REQUEST", getHubOperators);
}
