import { call, put, takeLatest } from "redux-saga/effects";
import request from "../../utils/request";

function* getAllOrders(payload) {
  try {
    yield put({
      type: "FETCH_ORDERS_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/orders?page=${
      payload.value.page
    }&search=${payload.value.search}&offset=${payload.value.offset}${
      payload.value.isActive ? "&isActive=" + payload.value.isActive : ""
    }${payload.value.date ? "&date=" + payload.value.date : ""}${
      payload.value.idOrder ? "&idOrder=" + payload.value.idOrder : ""
    }${payload.value.idCompany ? "&idCompany=" + payload.value.idCompany : ""}${
      payload.value.idOperator ? "&idOperator=" + payload.value.idOperator : ""
    }${
      payload.value.deliveryDate
        ? "&deliveryDate=" + payload.value.deliveryDate
        : ""
    }`;
    const headers = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "HIDE_LOADING",
    });

    yield put({
      type: "FETCH_ORDER_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed load",
        message: "Failed load orders",
      },
    });
    yield put({
      type: "FETCH_ORDER_ERROR",
    });
  }
}

function* postOrder(payload) {
  try {
    yield put({ type: "CREATE_ORDER_REQUESTING" });
    yield put({
      type: "SHOW_LOADING",
    });
    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/orders`;

    const headers = {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(payload.value),
    };

    const response = yield call(request, requestURL, headers);

    yield put({
      type: "HIDE_LOADING",
    });

    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "success",
        title: "Successful save",
        message: "Successful save order",
      },
    });

    yield put({
      type: "CREATE_ORDER_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed save",
        message: "Failed save order",
      },
    });
    yield put({
      type: "CREATE_ORDER_ERROR",
    });
  }
}

function* getOrderById(payload) {
  try {
    yield put({
      type: "READ_ORDER_REQUESTING",
    });
    yield put({
      type: "SHOW_LOADING",
    });
    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/orders/${payload.value.id}`;

    const headers = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "HIDE_LOADING",
    });

    yield put({
      type: "READ_ORDER_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "READ_ORDER_ERROR",
    });
  }
}

function* deleteOrderById(payload) {
  try {
    yield put({
      type: "DELETE_ORDER_REQUESTING",
    });
    yield put({
      type: "SHOW_LOADING",
    });
    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/orders/changeState/${payload.value.idOrder}`;

    const headers = {
      method: "PATCH",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(payload.value),
    };

    const response = yield call(request, requestURL, headers);

    yield put({
      type: "HIDE_LOADING",
    });

    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "success",
        title: "Successful disable",
        message: "Successful disable order",
      },
    });

    yield put({
      type: "DELETE_ORDER_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed disable",
        message: "Failed disable order",
      },
    });
    yield put({
      type: "DELETE_ORDER_ERROR",
    });
  }
}

function* updateOrder(payload) {
  try {
    yield put({
      type: "UPDATE_ORDER_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/orders/${payload.value.id}`;

    const headers = {
      method: "PATCH",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(payload.value),
    };

    const response = yield call(request, requestURL, headers);

    yield put({
      type: "HIDE_LOADING",
    });

    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "success",
        title: "Successful update",
        message: "Successful update order",
      },
    });

    yield put({
      type: "UPDATE_ORDER_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed update",
        message: "Failed update order",
      },
    });
    yield put({
      type: "UPDATE_ORDER_ERROR",
    });
  }
}

function* getAllOrdersByUser(payload) {
  try {
    yield put({
      type: "FETCH_ORDERSBYUSER_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${
      process.env.REACT_APP_API_URL
    }/api/v1/orders/ordersByUser?page=${payload.value.page}&search=${
      payload.value.search
    }&offset=${payload.value.offset}${
      payload.value.isActive ? "&isActive=" + payload.value.isActive : ""
    }`;
    const headers = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "HIDE_LOADING",
    });

    yield put({
      type: "FETCH_ORDERBYUSER_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed load",
        message: "Failed load orders by user",
      },
    });
    yield put({
      type: "FETCH_ORDERBYUSER_ERROR",
    });
  }
}

function* getAllOrdersAssigned(payload) {
  try {
    yield put({
      type: "FETCH_ORDERSASSIGNED_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${
      process.env.REACT_APP_API_URL
    }/api/v1/orders/allAssigned?page=${payload.value.page}&search=${
      payload.value.search
    }&offset=${payload.value.offset}${
      payload.value.isActive ? "&isActive=" + payload.value.isActive : ""
    }${payload.value.date ? "&date=" + payload.value.date : ""}${
      payload.value.idOrder ? "&idOrder=" + payload.value.idOrder : ""
    }${payload.value.idCompany ? "&idCompany=" + payload.value.idCompany : ""}${
      payload.value.idOperator ? "&idOperator=" + payload.value.idOperator : ""
    }`;
    const headers = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "HIDE_LOADING",
    });

    yield put({
      type: "FETCH_ORDERASSIGNED_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed load",
        message: "Failed load orders assigned",
      },
    });
    yield put({
      type: "FETCH_ORDERASSIGGNED_ERROR",
    });
  }
}

function* deleteSoftOrderById(payload) {
  try {
    yield put({
      type: "DELETE_SOFT_ORDER_REQUESTING",
    });
    yield put({
      type: "SHOW_LOADING",
    });
    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/orders/deleteSoft/${payload.value.idOrder}`;

    const headers = {
      method: "PATCH",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(payload.value),
    };

    const response = yield call(request, requestURL, headers);

    yield put({
      type: "DELETE_SOFT_ORDER_SUCCESS",
      value: response,
    });
    yield put({
      type: "HIDE_LOADING",
    });

    if (response.message) {
      yield put({
        type: "SHOW_ALERT",
        value: {
          type: "danger",
          title: "Failed delete soft",
          message: response.message,
        },
      });
    } else {
      yield put({
        type: "SHOW_ALERT",
        value: {
          type: "success",
          title: "Successful delete soft",
          message: "Successful delete soft order",
        },
      });
    }
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed delete soft",
        message: "Failed delete soft order",
      },
    });
    yield put({
      type: "DELETE_SOFT_ORDER_ERROR",
    });
  }
}

export function* watchOrder() {
  yield takeLatest("FETCH_ORDERS_REQUEST", getAllOrders);
  yield takeLatest("CREATE_ORDER_REQUEST", postOrder);
  yield takeLatest("READ_ORDER_REQUEST", getOrderById);
  yield takeLatest("DELETE_ORDER_REQUEST", deleteOrderById);
  yield takeLatest("UPDATE_ORDER_REQUEST", updateOrder);
  yield takeLatest("FETCH_ORDERSBYUSER_REQUEST", getAllOrdersByUser);
  yield takeLatest("FETCH_ORDERSASSIGNED_REQUEST", getAllOrdersAssigned);
  yield takeLatest("DELETE_SOFT_ORDER_REQUEST", deleteSoftOrderById);
}
