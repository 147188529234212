import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import Sidebar from "../../components/Navigation/Sidebar";
import Topbar from "../../components/Navigation/Topbar";
import Grid from "../../components/Grid";
import { connect } from "react-redux";
import { Button, Modal } from "react-bootstrap";
import { Show } from "../../hooks/Show";
import Select from 'react-select'

const Note = ({
  notes,
  reduxGetNotes,
  reduxPostNote,
  note,
  reduxGetNote,
  rowEdited,
  reduxDeleteNote,
  rowDeleted,
  reduxPatchNote,
  rowUpdated,
  reduxResetNoteForm,
  reduxGetRecords,
  records,
}) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
    getValues,
    setValue,
  } = useForm();
  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(10);

  const [currentNote, setCurrentNote] = useState("");
  const [show, setShow] = useState(false);

  const record = useRef({})
  record.current = watch("idRecord", "")

  const handleClose = () => {
    reduxResetNoteForm();
    setShow(false);
  };
  const handleShow = () => {
    reduxGetRecords({
      page: 1,
      offset: 10000,
      search: "",
      isActive: true,
    });
    reduxResetNoteForm();
    reset();
    setShow(true);
  };
  const [search, setSearch] = useState("");

  const columns = [
    {
      title: "#",
      render: (rowData) => {
        return <span>{rowData.idNote}</span>;
      },
      field: "idNote",
      searchable: true,
    },
    {
      title: "Novedad",
      render: (rowData) => {
        return <span>{rowData.record ? rowData.record.description : ""}</span>;
      },
      field: "record.description",
      searchable: true,
    },
    {
      title: "Nota",
      render: (rowData) => {
        return <span>{rowData.description}</span>;
      },
      field: "description",
      searchable: true,
    },
    {
      title: "Codigo",
      render: (rowData) => {
        return <span>{rowData.code}</span>;
      },
      field: "code",
      searchable: true,
    },
    {
      title: "Opciones",
      render: (rowData) => {
        return (
          <>
            {rowData.isActive ? (
              <>
                <Show when="feature:edit-note">
                  <button
                    title="Editar"
                    className="btn btn-primary btn-sm  btn-circle mr-2"
                    type="button"
                    onClick={(e) => handleOpen(rowData)}
                  >
                    <i className="fas fa-edit fa-xs"></i>
                  </button>
                </Show>
                <Show when="feature:disabled-note">
                  <button
                    title="Desactivar"
                    className="btn btn-danger btn-sm btn-circle"
                    type="button"
                    onClick={(e) => handleDelete(rowData)}
                  >
                    <i className="fas fa-times-circle fa-xs"></i>
                  </button>
                </Show>
              </>
            ) : (
              <Show when="feature:enabled-note">
                <button
                  title="Activar"
                  className="btn btn-primary btn-sm  btn-circle mr-2"
                  type="button"
                  onClick={(e) => handleActive(rowData)}
                >
                  <i className="fas fa-check-circle fa-xs"></i>
                </button>
              </Show>
            )}
            <input
              id="showMobile"
              type="checkbox"
              checked={rowData.noMobile}
              style={{ marginRight: "10px", marginLeft: "10px" }}
              title="No aplica para Mobile"
              onChange={(e) => handleCheck(rowData, e.target.checked)}
            />
          </>
        );
      },
    },
  ];

  const handleOpen = (row) => {
    reduxGetRecords({
      page: 1,
      offset: 10000,
      search: "",
      isActive: true,
    });
    reduxGetNote({
      id: row.idNote,
    });
  };

  const handleActive = (row) => {
    if (!row.isActive) {
      reduxDeleteNote(row);
    }
  };

  useEffect(() => {
    if (record.current !== '' && record.current !== undefined && record.current !== null) {
      const selected = records && Object.keys(records).length > 0 && records.items.find((e) => e.idRecord === record.current)
      setCurrentNote({ label: selected.description, value: selected.idRecord })
    } else {
      setCurrentNote({ label: 'Seleccionar', value: '' })
    }
  }, [record.current])

  useEffect(() => {
    if (rowEdited) {
      setShow(true);
      setValue("description", rowEdited.description);
      setValue("noMobile", rowEdited.noMobile);
      setValue("idRecord", rowEdited.idRecord);
    }
  }, [rowEdited]);

  const handleDelete = (row) => {
    reduxDeleteNote(row);
  };

  useEffect(() => {
    reduxGetNotes({
      page: 1,
      offset: offset,
      search: "",
    });
    notes = {
      items: [
        {
          idNote: 1,
          record: "Novedad 1",
          description: "Note 1",
          noMobile: true,
          isActive: true,
        },
        {
          idNote: 2,
          record: "Novedad 2",
          description: "Note 2",
          noMobile: false,
          isActive: true,
        },
        {
          idNote: 3,
          record: "Novedad 1",
          description: "Note 3",
          noMobile: true,
          isActive: true,
        },
      ],
      next: "2",
      page: "1",
      prev: null,
      total: 2,
      totalPages: 1,
    };
  }, []);

  useEffect(() => {
    reduxGetNotes({
      page: currentPage,
      offset: offset,
      search: search,
    });
  }, [currentPage]);

  const onSubmit = (data) => {
    if (rowEdited) {
      reduxPatchNote({ ...data, idNote: rowEdited.idNote });
    } else {
      reduxPostNote(data);
    }
    reset();
    reduxResetNoteForm();
    setShow(false);
  };

  useEffect(() => {
    if (note || rowUpdated || rowDeleted) {
      reduxGetNotes({
        page: currentPage,
        offset: offset,
        search: search,
      });
      reduxResetNoteForm();
    }
  }, [note, rowUpdated, rowDeleted]);

  const handleCheck = (row, event) => {
    row.noMobile = event;
    reduxPatchNote(row);
  };

  return (
    <div>
      <div id="wrapper">
        <Sidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Topbar />
            <div className="container-fluid">
              <div className="d-sm-flex align-items-center mb-1">
                <h1 className="h3 mb-0 text-gray-800 mr-3">Notas</h1>
                <Show when="feature:create-note">
                  <button
                    className="btn btn-primary btn-circle"
                    type="button"
                    onClick={handleShow}
                  >
                    <i className="fas fa-plus fa-sm"></i>
                  </button>
                </Show>
              </div>
              <p className="mb-4">Módulo de Administración de notas</p>
              <div className="card shadow mb-4">
                <div className="card-header py-3">
                  <h6 className="m-0 font-weight-bold text-primary">
                    Listado de Notas
                  </h6>
                </div>
                <div className="card-body">
                  <Grid
                    cols={columns}
                    data={
                      notes && Object.keys(notes).length > 0 ? notes.items : []
                    }
                    page={
                      notes && Object.keys(notes).length > 0
                        ? Number(notes.page)
                        : currentPage
                    }
                    pages={
                      notes && Object.keys(notes).length > 0
                        ? Number(notes.totalPages)
                        : 0
                    }
                    total={
                      notes && notes.hasOwnProperty("total") ? notes.total : 0
                    }
                    offset={offset}
                    onChangePage={(page) => setCurrentPage(page)}
                    onChangeRange={(value) => {
                      setOffset(value);
                      reduxGetNotes({
                        page: 1,
                        offset: value,
                        search: search,
                      });
                    }}
                    defaultValue={search}
                    onChangeSearch={(value) => {
                      setSearch(value);
                      reduxGetNotes({
                        page: 1,
                        offset: offset,
                        search: value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Modal
          show={show}
          onHide={handleClose}
          onEscapeKeyDown={handleClose}
          backdrop="static"
          keyboard={true}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <Modal.Header closeButton>
              <Modal.Title>{rowEdited ? "Editar" : "Nueva"} Nota</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-group">
                <label htmlFor="idRecord" className="form-label">
                  Novedad
                </label>
                <Select
                  isClearable={true}
                  value={currentNote}
                  {...register('idRecord')}
                  id='idRecord'
                  onChange={(value) => setValue('idRecord', value?.value || '')}
                  options=
                  {records &&
                    Object.keys(records).length > 0 &&
                    records.items
                      .map((ele) => ({
                          value: ele.idRecord,
                          label: ele.description,
                        }))
                  }
                />
                {errors.idRecord && (
                  <span className="invalid-feedback">
                    La novedad es requerida
                  </span>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="description" className="form-label">
                  Descripción
                </label>
                <input
                  id="description"
                  type="text"
                  className={`form-control form-control-user ${
                    errors.description && "is-invalid"
                  }`}
                  {...register("description", { required: true })}
                />
                {errors.description && (
                  <span className="invalid-feedback">
                    La descripción es requerida
                  </span>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="showMobile">
                  <input
                    id="noMobile"
                    type="checkbox"
                    {...register("noMobile")}
                    style={{ marginRight: "10px", marginLeft: "10px" }}
                  />
                  No aplica para Mobile
                </label>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cancelar
              </Button>
              <Button variant="primary" type="submit">
                Guardar
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    notes: state.noteState.notes,
    note: state.noteState.note,
    rowEdited: state.noteState.rowEdited,
    rowDeleted: state.noteState.rowDeleted,
    rowUpdated: state.noteState.rowUpdated,
    records: state.recordState.records,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    reduxGetNotes: (payload) =>
      dispatch({
        type: "FETCH_NOTES_REQUEST",
        value: payload,
      }),
    reduxPostNote: (payload) =>
      dispatch({
        type: "CREATE_NOTE_REQUEST",
        value: payload,
      }),
    reduxGetNote: (payload) =>
      dispatch({
        type: "READ_NOTE_REQUEST",
        value: payload,
      }),
    reduxDeleteNote: (payload) =>
      dispatch({
        type: "DELETE_NOTE_REQUEST",
        value: payload,
      }),
    reduxPatchNote: (payload) =>
      dispatch({
        type: "UPDATE_NOTE_REQUEST",
        value: payload,
      }),
    reduxResetNoteForm: () =>
      dispatch({
        type: "RESET_NOTE_FORM",
      }),
    reduxGetRecords: (payload) =>
      dispatch({
        type: "FETCH_RECORDS_REQUEST",
        value: payload,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Note);
