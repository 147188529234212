import {call, put, takeLatest} from "redux-saga/effects";
import request from "../../utils/request";

function* getAddressesEventByIdAddress(payload) {
  try {
    yield put({
      type: "READ_BY_IDADDRESS_ADDRESSES_EVENT_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/addressRecords/findRecordsByAddress/${payload.value.idAddress}`;

    const headers = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "READ_BY_IDADDRESS_ADDRESSES_EVENT_SUCCESS",
      value: response,
    });
    yield put({
      type: "HIDE_LOADING",
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "READ_BY_IDADDRESS_ADDRESSES_EVENT_ERROR",
    });
  }
}

function* getAddressEventByIdAddress(payload) {
  try {
    yield put({
      type: "READ_BY_IDADDRESS_ADDRESSES_EVENT_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/addressRecords/tracking/${payload.value.idAddress}`;

    const headers = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "READ_BY_IDADDRESS_ADDRESSES_EVENT_SUCCESS",
      value: response,
    });
    yield put({
      type: "HIDE_LOADING",
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "READ_BY_IDADDRESS_ADDRESSES_EVENT_ERROR",
    });
  }
}
export function* watchAddressesEvent() {
  yield takeLatest("READ_BY_IDADDRESS_ADDRESSES_EVENT_REQUEST", getAddressEventByIdAddress);
}
