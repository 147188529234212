const initialState = {
  requestingFetchLotProducts: false,
  successfulFetchLotProducts: false,
  errorFetchLotProducts: false,
  lotProducts: {},
  requestingCreateLotProduct: false,
  successfulCreateLotProduct: false,
  errorsCreateLotProduct: false,
  lotProduct: null,
  requestingReadLotProduct: false,
  successfulReadLotProduct: false,
  errorsReadLotProduct: false,
  rowEdited: null,
  requestingDeleteLotProduct: false,
  successfulDeleteLotProduct: false,
  errorsDeleteLotProduct: false,
  rowDeleted: null,
  requestingUpdateLotProduct: false,
  successfulUpdateLotProduct: false,
  errorsUpdateLotProduct: false,
  rowUpdated: null,
  requestingDeleteSoftLotProduct: false,
  successfulDeleteSoftLotProduct: false,
  errorsDeleteSoftLotProduct: false,
  rowDeletedSoft: null,
};

const LotProductReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_LOTPRODUCTS_REQUESTING":
      return {
        ...state,
        requestingFetchLotProducts: true,
        successfulFetchLotProducts: false,
        errorsFetchLotProducts: false,
      };
    case "FETCH_LOTPRODUCT_SUCCESS":
      return {
        ...state,
        errorFetchLotProducts: false,
        requestingFetchLotProducts: false,
        successfulFetchLotProducts: true,
        lotProducts: action.value,
      };
    case "FETCH_LOTPRODUCT_ERROR":
      return {
        ...state,
        errorFetchLotProducts: true,
        requestingFetchLotProducts: false,
        successfulFetchLotProducts: false,
      };
    case "CREATE_LOTPRODUCT_REQUESTING":
      return {
        ...state,
        requestingCreateLotProduct: true,
        successfulCreateLotProduct: false,
        errorsCreateLotProduct: false,
      };
    case "CREATE_LOTPRODUCT_SUCCESS":
      return {
        ...state,
        errorsCreateLotProduct: false,
        requestingCreateLotProduct: false,
        successfulCreateLotProduct: true,
        lotProduct: action.value,
      };
    case "CREATE_LOTPRODUCT_ERROR":
      return {
        ...state,
        errorsCreateLotProduct: true,
        requestingCreateLotProduct: false,
        successfulCreateLotProduct: false,
      };
    case "READ_LOTPRODUCT_REQUESTING":
      return {
        ...state,
        requestingReadLotProduct: true,
        successfulReadLotProduct: false,
        errorsReadLotProduct: false,
      };
    case "READ_LOTPRODUCT_SUCCESS":
      return {
        ...state,
        errorsReadLotProduct: false,
        requestingReadLotProduct: false,
        successfulReadLotProduct: true,
        rowEdited: action.value,
      };
    case "READ_LOTPRODUCT_ERROR":
      return {
        ...state,
        errorsReadLotProduct: true,
        requestingReadLotProduct: false,
        successfulReadLotProduct: false,
      };
    case "DELETE_LOTPRODUCT_REQUESTING":
      return {
        ...state,
        requestingDeleteLotProduct: true,
        successfulDeleteLotProduct: false,
        errorsDeleteLotProduct: false,
      };
    case "DELETE_LOTPRODUCT_SUCCESS":
      return {
        ...state,
        errorsDeleteLotProduct: false,
        requestingDeleteLotProduct: false,
        successfulDeleteLotProduct: true,
        rowDeleted: action.value,
      };
    case "DELETE_LOTPRODUCT_ERROR":
      return {
        ...state,
        errorsDeleteLotProduct: true,
        requestingDeleteLotProduct: false,
        successfulDeleteLotProduct: false,
      };
    case "UPDATE_LOTPRODUCT_REQUESTING":
      return {
        ...state,
        requestingUpdateLotProduct: true,
        successfulUpdateLotProduct: false,
        errorsUpdateLotProduct: false,
      };
    case "UPDATE_LOTPRODUCT_SUCCESS":
      return {
        ...state,
        errorsUpdateLotProduct: false,
        requestingUpdateLotProduct: false,
        successfulUpdateLotProduct: true,
        rowUpdated: action.value,
      };
    case "UPDATE_LOTPRODUCT_ERROR":
      return {
        ...state,
        errorsUpdateLotProduct: true,
        requestingUpdateLotProduct: false,
        successfulUpdateLotProduct: false,
      };
    case "DELETE_SOFT_LOTPRODUCT_REQUESTING":
      return {
        ...state,
        requestingDeleteSoftLotProduct: true,
        successfulDeleteSoftLotProduct: false,
        errorsDeleteSoftLotProduct: false,
      };
    case "DELETE_SOFT_LOTPRODUCT_SUCCESS":
      return {
        ...state,
        errorsDeleteSoftLotProduct: false,
        requestingDeleteSoftLotProduct: false,
        successfulDeleteSoftLotProduct: true,
        rowDeletedSoft: action.value,
      };
    case "DELETE_SOFT_LOTPRODUCT_ERROR":
      return {
        ...state,
        errorsDeleteSoftLotProduct: true,
        requestingDeleteSoftLotProduct: false,
        successfulDeleteSoftLotProduct: false,
      };
    case "RESET_LOTPRODUCT_FORM":
      return {
        ...state,
        requestingFetchLotProducts: false,
        successfulFetchLotProducts: false,
        errorFetchLotProducts: false,
        lotProducts: {},
        requestingCreateLotProduct: false,
        successfulCreateLotProduct: false,
        errorsCreateLotProduct: false,
        lotProduct: null,
        requestingReadLotProduct: false,
        successfulReadLotProduct: false,
        errorsReadLotProduct: false,
        rowEdited: null,
        requestingDeleteLotProduct: false,
        successfulDeleteLotProduct: false,
        errorsDeleteLotProduct: false,
        rowDeleted: null,
        requestingUpdateLotProduct: false,
        successfulUpdateLotProduct: false,
        errorsUpdateLotProduct: false,
        rowUpdated: null,
        requestingDeleteSoftLotProduct: false,
        successfulDeleteSoftLotProduct: false,
        errorsDeleteSoftLotProduct: false,
        rowDeletedSoft: null,
      };
    case "RESET":
      return initialState;
    default:
      return state;
  }
};

export default LotProductReducer;
