import React, { useState, useRef, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import Sidebar from "../../components/Navigation/Sidebar";
import Topbar from "../../components/Navigation/Topbar";
import { connect } from "react-redux";
import Geocode from "react-geocode";
import { addressRoadTypeActions } from "../../redux/reducers/addressRoadTypeReducer";
import { addressCardinalActions } from "../../redux/reducers/addressCardinalReducer";
import { addressInsideTypeActions } from "../../redux/reducers/addressInsideTypeReducer";
import { addressNomenclatureActions } from "../../redux/reducers/addressNomenclatureReducer";
import InputList, { SelectConstEnabled } from "../../components/Common/inputList";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { GoogleMap, Marker, useLoadScript, } from "@react-google-maps/api";


const AddressValidator = ({
    reduxGetNeighborhoodsByCity,
    neighborhoodsByCity,
    reduxGetDepartmentsByCountry,
    departmentsByCountry,
    reduxGetCitiesByDepartment,
    citiesByDepartment,
    countries,
    reduxGetCountries,
    reduxSetDepartment,
    reduxSetCity,
    reduxSetNeighborhood,
    addressRoadTypeList,
    getAllListAditionalAddress,
    reduxGetAddressInsideTypes,
    reduxGetAddressNomenclatures
}) => {

    const methods = useForm()
    const [currentPage, setCurrentPage] = useState(1);
    const [offset, setOffset] = useState(10);
    const [address, setAddress] = useState("");
    const [cityFound, setCityFound] = useState("");
    const [countryFound, setCountryFound] = useState("");
    const [departmentFound, setDepartmentFound] = useState("");
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_MAPS_KEY}`,
    });

    const [mapRef, setMapRef] = useState(null);
    const [center, setCenter] = useState({ lat: 4.653148, lng: -74.120746 });
    const [zoom, setZoom] = useState(5);
    const [formatedAddress, setFormatedAddress] = useState('');
    const [coords, setCoords] = useState([]);
    const [properties, setProperties] = useState(null);

    const popover = (
        <Popover id="popover-basic">
            <Popover.Title as="h3">Ayuda</Popover.Title>
            <Popover.Content>
                A continuación el detalle de una <strong>dirección</strong> generada.
                <br></br>
                SECTOR 67BG OESTE #89BF OESTE-34 SOTANO 102, SAN JOSÉ, MEDELLIN,
                ANTIOQUIA, COLOMBIA
            </Popover.Content>
        </Popover>
    );

    const indicativeRoad = useRef({});
    indicativeRoad.current = methods.watch("indicativeRoad", "");

    const roadNumber = useRef({});
    roadNumber.current = methods.watch("roadNumber", "");

    const appendixRoad = useRef({});
    appendixRoad.current = methods.watch("appendixRoad", "");

    const crossoverRoad = useRef({});
    crossoverRoad.current = methods.watch("crossoverRoad", "");

    const crossoverNumber = useRef({});
    crossoverNumber.current = methods.watch("crossoverNumber", "");

    const appendixCrossingNumber = useRef({});
    appendixCrossingNumber.current = methods.watch("appendixCrossingNumber", "");

    const orientationCrossingNumber = useRef({});
    orientationCrossingNumber.current = methods.watch("orientationCrossingNumber", "");

    const insideTypes = useRef({});
    insideTypes.current = methods.watch("insideTypes", "");

    const doorNumber = useRef({});
    doorNumber.current = methods.watch("doorNumber", "");

    const inside = useRef({});
    inside.current = methods.watch("inside", "");

    const city = useRef({});
    city.current = methods.watch("city", "");

    const neighborhood = useRef({});
    neighborhood.current = methods.watch("neighborhood", "");

    const country = useRef({});
    country.current = methods.watch("country", "");

    const department = useRef({});
    department.current = methods.watch("department", "");

    const loadHandler = (map) => {
        setMapRef(map);
    };

    const validateHandler = () => {
        if (address) {
            setZoom(17);
            console.log(address); // Log the input data to the console
            updateCoordinates(); // Call the function to update coordinates based on the new address            
        } else {
            setLatitude(0);
            setLongitude(0);
            setZoom(5)
            setCenter({ lat: 4.653148, lng: -74.120746 });
            setFormatedAddress('')
        }
        // Call the function to update coordinates based on the new address            
    }



    const renderMap = () => {
        return (
            <GoogleMap
                onLoad={loadHandler}
                center={center}
                zoom={zoom}
                mapContainerStyle={{
                    height: "calc(100vh - 21rem)",
                    width: "100%",
                }}
                mapContainerClassName="rounded overflow-hidden border"
            >
                {
                    latitude != null && longitude != null && (
                        <Marker
                            key={'key'}
                            position={{
                                lat: latitude,
                                lng: longitude,
                            }}
                        />
                    )
                }
            </GoogleMap>
        );
    };

    const updateCoordinates = () => {
        console.log(process.env.REACT_APP_GOOGLE_MAPS_KEY)
        const encodedAddress = encodeURIComponent(address)
        const request = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodedAddress}&key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}`;
        console.log(request);
        fetch(request, {
            "method": "GET",
        })
            .then(response => response.json())
            .then(response => {
                if (response.status === 'OK') {
                    const { lat, lng } = response.results[0].geometry.location;
                    setZoom(17);
                    setLatitude(lat)
                    setLongitude(lng)
                    setCenter({ lat, lng });
                    setFormatedAddress(response.results[0].formatted_address)
                    console.log(JSON.stringify(response))

                }
            })
            .catch(err => console.log(err))
    }

    return (
        <div>
            <div id="wrapper">
                <Sidebar />
                <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <Topbar />
                        <div className="container-fluid">
                            <div className="d-sm-flex align-items-center mb-1">
                                <h1 className="h3 mb-0 text-gray-800 mr-3">
                                    Validador de Direcciones
                                </h1>
                            </div>
                            <p className="mb-4">Módulo Validador de direcciones</p>
                            <div className="card shadow" style={{ height: 'calc(100vh - 12rem)' }}>
                                <div className="card-body">

                                   

                                        <div className="d-flex align-items-end justify-content-center">
                                            <label htmlFor="document" className="form-label col-md-10 ml-0 my-0 mr-2 p-0">
                                                Ingresa la dirección a validar
                                                <input
                                                    {...methods.register("address", { required: true })}
                                                    id="address"
                                                    type="text"
                                                    className={`form-control`}
                                                    onChange={(event) => {
                                                        const inputData = event.target.value; // Get the input data from the event
                                                        setAddress(inputData); // Update the address state with the input data
                                                    }} />
                                            </label>
                                            <button
                                                id="validate"
                                                type="button"
                                                className="form-control btn btn-primary"
                                                onClick={validateHandler}
                                            >Validar</button>

                                        </div>
                                        <div className="py-2">
                                            <b>Direccion:</b> <span className="text-success">{formatedAddress && formatedAddress}</span>
                                        </div>
                                        {isLoaded ? renderMap() : null}
                                 


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        countries: state.countryState.countries,
        neighborhoodsByCity: state.neighborhoodState.neighborhoodsByCity,
        departmentsByCountry: state.departmentState.departmentsByCountry,
        citiesByDepartment: state.cityState.citiesByDepartment,
        addressRoadTypeList: state.addressRoadType.addressRoadTypes
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        reduxGetCountries: (payload) =>
            dispatch({
                type: "FETCH_COUNTRIES_REQUEST",
                value: payload,
            }),
        reduxGetDepartmentsByCountry: (payload) =>
            dispatch({
                type: "READBYCOUNTRY_DEPARTMENT_REQUEST",
                value: payload,
            }),
        reduxGetCitiesByDepartment: (payload) =>
            dispatch({
                type: "READBYDEPARTMENT_CITY_REQUEST",
                value: payload,
            }),
        reduxGetNeighborhoodsByCity: (payload) =>
            dispatch({
                type: "READBYCITY_NEIGHBORHOOD_REQUEST",
                value: payload,
            }),
        reduxSetDepartment: (payload) =>
            dispatch({
                type: "RESET_BYCOUNTRY_DEPARTMENT",
                value: payload,
            }),
        reduxSetCity: (payload) =>
            dispatch({
                type: "RESET_BYDEPARTMENT_CITY",
                value: payload,
            }),
        reduxSetNeighborhood: (payload) =>
            dispatch({
                type: "RESET_BYCITY_NEIGHBORHOOD",
                value: payload,
            }),
        getAllListAditionalAddress: (payload) =>
            dispatch({
                type: addressRoadTypeActions.FETCH_ADDRESSROADTYPE_REQUEST,
                value: payload
            }),
        reduxGetAddressCardinals: (payload) =>
            dispatch({
                type: addressCardinalActions.FETCH_ADDRESSES_CARDINAL_POINTS_REQUEST,
                value: payload
            }),
        reduxGetAddressInsideTypes: (payload) =>
            dispatch({
                type: addressInsideTypeActions.FETCH_ADDRESSINSIDETYPE_REQUEST,
                value: payload
            }),
        reduxGetAddressNomenclatures: (payload) =>
            dispatch({
                type: addressNomenclatureActions.FETCH_ADDRESSNOMENCLATURE_REQUEST,
                value: payload
            }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddressValidator);
