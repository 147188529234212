import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { connect } from "react-redux";
import { Button, Modal } from "react-bootstrap";
import Swal from "sweetalert2";

import { isNullOrUndefined } from "../../../utils/functionsUtils";

const UpsertAlertType = ({
    show,
    alertTypeData,
    handleClose,
    reduxGetOneAlertType,
    alertType,
    reduxCreateAlertType,
    reduxUpdateAlertType,
    successfulCreateAlertType,
    errorCreateAlertType,
    successfulUpdateAlertType,
    errorUpdateAlertType,
    upsertResult,
}) => {
    const {
        handleSubmit,
        control,
        formState: { errors },
        setValue,
        reset,
    } = useForm({
        defaultValues: {
            code: '',
            maxAttempts: 1,
        }
    });

    useEffect(() => {
        reset();
        if (!isNullOrUndefined(alertTypeData)) {
            reduxGetOneAlertType({
                idAlertType: alertTypeData.idAlertType
            })
        }
    }, [alertTypeData, reduxGetOneAlertType]);

    useEffect(() => {
        if (!alertType) return;

        setValue("code", alertType.code);
        setValue("maxAttempts", alertType.maxAttempts);
    }, [alertType])

    useEffect(() => {
        if (successfulCreateAlertType || successfulUpdateAlertType) {
            Swal.fire(
                "Proceso Exitoso",
                `${upsertResult.message}`,
                "success"
            );
            handleClose();
        }
        if (errorCreateAlertType || errorUpdateAlertType) {
            Swal.fire(
                "Proceso Fallido",
                `${upsertResult.message}`,
                "error"
            );
        }
    }, [successfulCreateAlertType, successfulUpdateAlertType, errorCreateAlertType, errorUpdateAlertType, upsertResult])

    const submit = (data) => {
        var dataObj = {
            ...data,
        }

        if (alertType) {
            dataObj = { idAlertType: alertType.idAlertType, ...dataObj };
            reduxUpdateAlertType(dataObj);
        } else {
            reduxCreateAlertType(dataObj);
        }        
    }

    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                onEscapeKeyDown={handleClose}
                backdrop="static"
                keyboard={false}
                size="m"
            >
                <form onSubmit={handleSubmit(submit)}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <div className="d-flex justify-content-between align-items-center">
                                {alertType ? "Editar" : "Nuevo"} tipo de alerta
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className='form-group col'>
                                <label
                                    htmlFor='code'
                                    className='form-label'>
                                    Código *
                                </label>
                                <Controller
                                    control={control}
                                    rules={{
                                        required: 'El código es requerido',
                                    }}
                                    name="code"
                                    render={({ field }) => (
                                        <>
                                            <input {...field} className="form-control" type="text" />
                                            {
                                                errors.code &&
                                                <span className="error-message">
                                                    {errors.code.message}
                                                </span>
                                            }
                                        </>
                                    )}
                                />
                            </div>
                            <div className='form-group col'>
                                <label
                                    htmlFor='maxAttempts'
                                    className='form-label'>
                                    Intentos máximos
                                </label>
                                <Controller
                                    control={control}
                                    name="maxAttempts"
                                    defaultValue={1}
                                    render={({ field }) => (
                                        <>
                                            <input {...field} className="form-control" type="number" />
                                        </>
                                    )}
                                />
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Cancelar
                        </Button>
                        <Button variant="primary" type="submit">
                            Guardar
                        </Button>
                    </Modal.Footer>
                </form >
            </Modal>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        alertType: state.alertTypeState.alertType,
        successfulCreateAlertType: state.alertTypeState.successfulCreateAlertType,
        errorCreateAlertType: state.alertTypeState.errorCreateAlertType,
        successfulUpdateAlertType: state.alertTypeState.successfulUpdateAlertType,
        errorUpdateAlertType: state.alertTypeState.errorUpdateAlertType,
        upsertResult: state.alertTypeState.upsertResult,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        reduxGetOneAlertType: (payload) =>
            dispatch({
                type: "FETCH_ONE_ALERT_TYPE_REQUEST",
                value: payload,
            }),
        reduxCreateAlertType: (payload) =>
            dispatch({
                type: "CREATE_ALERT_TYPE_REQUEST",
                value: payload,
            }),
        reduxUpdateAlertType: (payload) =>
            dispatch({
                type: "UPDATE_ALERT_TYPE_REQUEST",
                value: payload,
            }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpsertAlertType);