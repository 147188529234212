import React, { forwardRef, useEffect, useRef, useState, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { Button, Modal } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import { GoogleMap, Polygon, useLoadScript } from '@react-google-maps/api';
import moment, { invalid } from 'moment';
import 'react-datepicker/dist/react-datepicker.css';

import Sidebar from '../../components/Navigation/Sidebar';
import Topbar from '../../components/Navigation/Topbar';
import Timeline from '../../components/Timeline';
import Grid from '../../components/Grid';
import { useAuth } from '../../hooks/useAuth';
import { Show } from '../../hooks/Show';
import { PermissionContext } from '../../hooks/PermissionContext';

import {getStatusBadgeByCode} from "../../utils/StatusUtils";
import {StatusEnum} from "../../utils/enumsUtil";
import { HideByRole } from '../../utils/functionsUtils';

const Swal = require('sweetalert2');

const Assignments = ({
  reduxGetStatuses,
  statuses,
  reduxGetCouriers,
  couriers,
  reduxGetCurrentRouteByCourier,
  currentRoute,
  reduxResetForm,
  rowDeleted,
  reduxGetNeighborhoodsByCity,
  neighborhoodsByCity,
  reduxGetDepartmentsByCountry,
  departmentsByCountry,
  reduxGetCitiesByDepartment,
  citiesByDepartment,
  countries,
  reduxSetDepartment,
  reduxSetCity,
  reduxSetNeighborhood,
  reduxPatchRoutesCourier,
  routesCourier,
  reduxDeleteAllRoutes,
  rowsDeleted,
  couriers2,
  reduxGetCompanyByUser,
  reduxGetNoveltiesByCode,
  novelties,
  reduxGetNotes,
  notes,
}) => {

  const { hasPermission } = useContext(PermissionContext);
  const { session } = useAuth();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
    setValue,
    getValues,
  } = useForm();
  const {
    register: registerModal,
    handleSubmit: handleSubmitModal,
    formState: { errors: errorsModal },
    reset: resetModal,
    setValue: setValueModal,
    getValues: getValuesModal,
    watch: watchUpload,
  } = useForm();
  const {
    register: registerModalRecord,
    handleSubmit: handleSubmitModalRecord,
    formState: { errors: errorsModalRecord },
    reset: resetModalRecord,
    setValue: setValueModalRecord,
    getValues: getValuesModalRecord,
  } = useForm();
  const { watch: watchUpdate } = useForm({
    defaultValues: {
      city: '',
      neighborhood: '',
      country: '',
      department: '',
    },
  });
  const [fileName, setFileName] = useState(null);
  const [fileError, setFileError] = useState(null);
  const [showOther, setShowOther] = useState(false);
  const [showTitleNoDelivery, setShowTitleNoDelivery] = useState(false);
  const [showTitleReturned, setShowTitleReturned] = useState(false);
  const [showTitleDelivery, setShowTitleDelivery] = useState(false);

  const [showTimeline, setShowTimeline] = useState(false);
  const [idAddressSelected, setIdAddressSelected] = useState(null);

  const handleOpenTimeline = (id) => {
    setIdAddressSelected(id);
    setShowTimeline(true);
  };

  const handleCloseTimeline = () => {
    setIdAddressSelected(null);
    setShowTimeline(false);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(10);
  const [startDate, setStartDate] = useState(new Date());
  const [startDate2, setStartDate2] = useState(null);

  const statesInModal = [StatusEnum.ENTRAN, StatusEnum.ENTREG, StatusEnum.NOENTR, StatusEnum.CENRET, StatusEnum.DESPAC, StatusEnum.CENDIS];

  const DatePickerCustom = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      className='form-control'
      onClick={onClick}
      onChange={onChange}
      ref={ref}
      value={value}
      autoComplete='Off'
      {...register('date')}
    />
  ));
  const DatePickerCustom2 = forwardRef(({ value, onClick, onChange }, ref) => (
    <>
      <input
        className={`form-control ${showDate && errorsModal.idUser2 && 'is-invalid'}`}
        onClick={onClick}
        onChange={onChange}
        ref={ref}
        value={value}
        autoComplete='Off'
        {...registerModal('date2', { required: showDate })}
      />
      {(!startDate2 || startDate2 == invalid || errorsModal.date2) && (
        <p style={{ color: 'red', fontStyle: 'italic', textAlign: 'center' }}>La fecha es requerida.</p>
      )}
    </>
  ));
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_MAPS_KEY}`,
  });
  const [mapRef, setMapRef] = useState(null);
  const [center] = useState({ lat: 44.076613, lng: -98.362239833 });
  const [zoom, setZoom] = useState(3);
  let [coords, setCoords] = useState([]);
  let [properties, setProperties] = useState(null);
  let [directions, setDirections] = useState('');
  const [cityFound, setCityFound] = useState('');
  const [countryFound, setCountryFound] = useState('');
  const [departmentFound, setDepartmentFound] = useState('');
  const [neighborhoodFound, setNeighborhoodFound] = useState('');
  const [show, setShow] = useState(false);
  const [showCourier, setShowCourier] = useState(true);
  const [courierError, setCourierError] = useState(false);
  const [courierError2, setCourierError2] = useState(false);
  const [showState, setShowState] = useState(false);
  const [showDate, setShowDate] = useState(false);
  const [idUser, setIdUser] = useState(null);
  const [idUser2, setIdUser2] = useState(null);
  const selectInputRef = useRef();
  const selectInputRef2 = useRef();
  const [idRoute, setIdRoute] = useState(null);
  const [showNovelty, setShowNovelty] = useState(true);
  const [showPicture, setShowPicture] = useState(false);
  const [showObservation, setShowObservation] = useState(false);

  const [showDownload, setShowDownload] = useState(false);

  const onClear = () => {
    selectInputRef.current.select.clearValue();
  };

  const onClear2 = () => {
    selectInputRef2.current.select.clearValue();
  };

  const city = useRef({});
  city.current = watchUpdate('city', '');

  const neighborhood = useRef({});
  neighborhood.current = watchUpdate('idNeighborhood', '');

  const country = useRef({});
  country.current = watchUpdate('country', '');

  const department = useRef({});
  department.current = watchUpdate('department', '');

  const state = useRef({});
  state.current = watch('state', '');

  const novelty = useRef({});
  novelty.current = watch('novelty', '');

  const columns = [
    {
      title: (rowData) => {
        return (
          <>
            <div className='dropdown btn btn-light options-btn'>
              <input
                id='all'
                onClick={(e) => checkAll(e)}
                type='checkbox'
                defaultChecked={false}
                {...register('all')}
              />
              <span
                className='dropdown-toggle ml-1'
                id='sampleDropdownMenu'
                data-bs-toggle='dropdown'></span>
              <div className='dropdown-menu'>
                <button
                  className='dropdown-item'
                  type='button'
                  onClick={(e) => deleteAll(e)}>
                  Desasignar
                </button>
                <button
                  className='dropdown-item'
                  type='button'
                  onClick={(e) => openModal(1)}>
                  Reasignar
                </button>
                <button
                  className='dropdown-item'
                  type='button'
                  onClick={(e) => openModal(2)}>
                  Cambiar fecha
                </button>
                <button
                  className='dropdown-item'
                  type='button'
                  onClick={(e) => openModal(3)}>
                  Cambiar estado de la ruta
                </button>
              </div>
            </div>
          </>
        );
      },
      render: (rowData) => {
        return (
          <>
            <input
              name='routesChecked'
              key={rowData.idRoute}
              type='checkbox'
              checked={rowData.isChecked}
              value={rowData.idRoute}
              onChange={(e) => {
                rowData.isChecked = e.target.checked;
              }}
              {...register('routesChecked')}
            />{' '}
          </>
        );
      },
    },
    {
      title: '# ruta',
      render: (rowData) => {
        return <span>{rowData.idRoute}</span>;
      },
      field: 'idRoute',
      searchable: true,
    },
    {
      title: 'Guía',
      render: (rowData) => {
        return <span>{rowData.trackingId}</span>;
      },
    },
    {
      title: '# dirección',
      render: (rowData) => {
        return <span>{rowData.idAddress}</span>;
      },
    },
    {
      title: 'Nombre',
      render: (rowData) => {
        return <span>{rowData.name}</span>;
      },
    },
    ...(hasPermission('feature:show-recipient-address')
      ? [
        {
          title: 'Dirección Destinatario',
          render: (rowData) => {
            return <span>{rowData.address}</span>;
          },
        },
      ]
      : []),
    ...(hasPermission('feature:show-reference1-address')
      ? [
        {
          title: 'Teléfono Destinatario',
          render: (rowData) => {
            return (
              <span>{rowData.reference1}</span>
            );
          },
        },
      ]
      : []),
    {
      title: 'Observaciones',
      render: (rowData) => {
        return <span>{rowData.reference2}</span>;
      },
      visible: !HideByRole(session?.role.name),
    },
    {
      title: 'Guía del cliente',
      render: (rowData) => {
        return <span>{rowData.clientTrackingId}</span>;
      },
    },
    {
      title: 'Estado',
      render: (rowData) => getStatusBadgeByCode(rowData.state),
    },
    {
      title: 'Opciones',
      render: (rowData) => {
        return (
          <>
            <Show when='feature:see-timeline'>
              <button
                title='Timeline'
                className='btn btn-primary btn-sm  btn-circle mr-2'
                type='button'
                onClick={(e) => handleOpenTimeline(rowData.idAddress)}>
                <i className='fas fa-info fa-xs'></i>
              </button>
            </Show>
          </>
        );
      },
    },
  ];

  const deleteAll = (e) => {
    const routesChecked = getValues('routesChecked');
    if (routesChecked.length > 0) {
      reduxDeleteAllRoutes({
        routesChecked: typeof routesChecked == 'string' ? [routesChecked] : routesChecked,
      });
    } else {
      Swal.fire('Información', 'Debe seleccionar todas las rutas que serán desasignadas', 'info');
    }
  };

  useEffect(() => {
    if (rowsDeleted) {
      resetRoute();
      const data = {
        idUser: rowsDeleted.route.idUser,
        date: moment(rowsDeleted.route.assignedDate).tz('America/Bogota').format('MM-DD-YYYY'),
      };
      reduxGetCurrentRouteByCourier(data);
      Swal.fire(
        'Proceso exitoso!',
        (rowsDeleted.total == 1
          ? 'Se desasignó ' + rowsDeleted.total + ' ruta'
          : 'Se desasignaron ' + rowsDeleted.total + ' rutas') +
        ' para el mensajero ' +
        rowsDeleted.courier,
        'success'
      );
    }
  }, [rowsDeleted]);

  useEffect(() => {
    if (rowDeleted) {
      resetRoute();
      const data = {
        idUser: rowDeleted.idUser,
        date: moment(rowDeleted.assignedDate).tz('America/Bogota').format('MM-DD-YYYY'),
      };
      reduxGetCurrentRouteByCourier(data);
    }
  }, [rowDeleted]);

  const openModal = (type) => {
    if (getValues('routesChecked') && getValues('routesChecked').length > 0) {
      switch (type) {
        case 1:
          setShowCourier(true);
          setShowDate(true);
          setShowState(false);
          break;
        case 2:
          setShowDate(true);
          setShowCourier(false);
          setShowState(false);
          break;
        case 3:
          setShowDate(false);
          setShowCourier(false);
          setShowState(true);
          break;
      }
      setShow(true);
      resetModal();
      setStartDate2(null);
      setFileError(null);
      setFileName(null);
      setShowNovelty(false);
      setShowPicture(false);
      setShowObservation(false);
      setShowOther(false);
    } else {
      Swal.fire('Información', 'Debe seleccionar todas las rutas que desea procesar', 'info');
    }
  };

  const handleClose = () => {
    setShow(false);
    resetModal();
    setStartDate2(null);
    setFileError(null);
    setFileName(null);
  };

  useEffect(() => {
    reduxGetCouriers({});
    reduxGetStatuses({
      page: 1,
      search: '',
      offset: 1000,
    });
    reduxGetCompanyByUser();
    reduxResetForm();
    reset();
    onClear();
    setValue('routesChecked', []);
    setValue('all', false);
    resetModal();
    routesCourier = null;
  }, []);

  const onSubmit = (data) => {
    if (idUser == null || idUser == undefined) {
      setCourierError(true);
      return;
    }
    resetRoute();
    if (data.state === undefined) { data.state = '' }
    reduxGetCurrentRouteByCourier({ ...data, idUser: idUser });
    setShowDownload(true);
    setValue('routesChecked', []);
    setValue('all', false);
    reset();
  };

  useEffect(() => {
    if (currentRoute && currentRoute.route.length > 0 && mapRef) {
      let { 0: first, length: l, [l - 1]: last } = currentRoute.route;
      var service = new window.google.maps.DirectionsService();

      // list of points
      var addresses = [];

      // add initial position route
      // addresses.push({
      //   lat: parseFloat(currentRoute.company.lat),
      //   lng: parseFloat(currentRoute.company.lon),
      //   name: currentRoute.company.description,
      // });

      // add others position
      currentRoute.route.forEach((elem) => {
        addresses.push({
          lat: parseFloat(elem.lat),
          lng: parseFloat(elem.lon),
          name: elem.address,
        });
      });

      // add last position
      addresses.push({
        lat: parseFloat(last.lat),
        lng: parseFloat(last.lon),
        name: last.address,
      });

      // Zoom and center map automatically by stations (each station will be in visible map area)
      var lngs = addresses.map(function (station) {
        return station.lng;
      });
      var lats = addresses.map(function (station) {
        return station.lat;
      });
      mapRef.fitBounds({
        west: Math.min.apply(null, lngs),
        east: Math.max.apply(null, lngs),
        north: Math.min.apply(null, lats),
        south: Math.max.apply(null, lats),
      });

      // Show stations on the map as markers
      for (var i = 0; i < addresses.length; i++) {
        new window.google.maps.Marker({
          position: addresses[i],
          map: mapRef,
          title: addresses[i].name,
        });
      }

      // Divide route to several parts because max stations limit is 25 (23 waypoints + 1 origin + 1 destination)
      for (var i = 0, parts = [], max = 25 - 1; i < addresses.length; i = i + max)
        parts.push(addresses.slice(i, i + max + 1));

      // Service callback to process service results
      var service_callback = function (response, status) {
        if (status != 'OK') {
          return;
        }
        var renderer = new window.google.maps.DirectionsRenderer();
        if (!window.gRenderers) window.gRenderers = [];
        window.gRenderers.push(renderer);
        renderer.setMap(mapRef);
        renderer.setOptions({ suppressMarkers: true, preserveViewport: true });
        renderer.setDirections(response);
      };

      // Send requests to service to get route (for stations count <= 25 only one request will be sent)
      for (var i = 0; i < parts.length; i++) {
        // Waypoints does not include first station (origin) and last station (destination)
        var waypoints = [];
        for (var j = 1; j < parts[i].length - 1; j++) waypoints.push({ location: parts[i][j], stopover: false });
        // Service options
        var service_options = {
          origin: parts[i][0],
          destination: parts[i][parts[i].length - 1],
          waypoints: waypoints,
          travelMode: window.google.maps.TravelMode.DRIVING,
        };
        // Send request
        service.route(service_options, service_callback);
      }
    }
  }, [currentRoute, mapRef]);

  const resetRoute = () => {
    if (window.gRenderers) {
      for (var r of window.gRenderers) {
        r.setMap(null);
      }
    }

    if (mapRef) {
      mapRef.data.forEach((feature) => {
        if (feature.getGeometry().getType() === 'Point') {
          mapRef.data.remove(feature);
        }
      });
    }
  };

  const renderMap = () => {
    return (
      <div className='container-fluid p-0 overflow-hidden'>
        <div className='row no-gutters'>
          <div className={'col-md-12'}>
            <GoogleMap
              onLoad={loadHandler}
              center={center}
              zoom={zoom}
              mapContainerStyle={{
                height: '500px',
                width: '100%',
              }}>
              {currentRoute &&
                currentRoute.zones.map((elem, key) => (
                  <Polygon
                    paths={
                      elem.zone_geometry.coordinates
                        ? elem.zone_geometry.coordinates[0].map((coord) => {
                          return { lat: coord[1], lng: coord[0] };
                        })
                        : elem.zone_geometry.geometries[1]
                          ? elem.zone_geometry.geometries[1].coordinates[0].map((coord) => {
                            return { lat: coord[1], lng: coord[0] };
                          })
                          : []
                    }
                    options={{
                      strokeColor: elem.zone_properties ? elem.zone_properties.stroke : '#2A2A57',
                      strokeWeight: elem.zone_properties ? elem.zone_properties['stroke-width'] : 2,
                      strokeOpacity: elem.zone_properties ? elem.zone_properties['stroke-opacity'] : 0.8,
                      fillColor: elem.zone_properties ? elem.zone_properties.fill : '#000',
                      fillOpacity: elem.zone_properties ? elem.zone_properties['fill-opacity'] : 0.35,
                    }}
                  />
                ))}
            </GoogleMap>
          </div>
        </div>
      </div>
    );
  };

  const loadHandler = (map) => {
    setMapRef(map);
  };

  const handledChange = (date) => {
    setStartDate(date);
    setValue('date', moment(date).tz('America/Bogota').format('MM-DD-YYYY'));
    setShowDownload(false);
  };

  const handledStateChange = (e) => {
    setShowDownload(false);
  };

  const handledChange2 = (date) => {
    setStartDate2(date);
    setValueModal('date2', moment(date).tz('America/Bogota').format('MM-DD-YYYY'));
  };

  useEffect(() => {
    if (country.current != '' && country.current != undefined && country.current != null) {
      setValue('department', '');
      setValue('city', '');
      setValue('neighborhood', '');
      reduxSetDepartment();
      reduxSetCity();
      reduxSetNeighborhood();
      const countrySelected =
        countries &&
        Object.keys(countries).length > 0 &&
        countries.items.find((value) => value.idCountry == country.current);
      setCountryFound(countrySelected ? countrySelected.description : '');
      reduxGetDepartmentsByCountry({
        idCountry: country.current,
      });
    }
  }, [country.current]);

  useEffect(() => {
    if (department.current != '' && department.current != undefined) {
      setValue('city', '');
      setValue('neighborhood', '');
      reduxSetCity();
      reduxSetNeighborhood();
      const departmentSelected =
        departmentsByCountry &&
        Object.keys(departmentsByCountry).length > 0 &&
        departmentsByCountry.find((value) => value.idDepartment == department.current);
      setDepartmentFound(departmentSelected ? departmentSelected.description : '');
      reduxGetCitiesByDepartment({
        idDepartment: department.current,
      });
    }
  }, [department.current]);

  useEffect(() => {
    if (city.current != '' && city.current != undefined) {
      setValue('neighborhood', '');
      reduxSetNeighborhood();
      const citySelected =
        citiesByDepartment &&
        Object.keys(citiesByDepartment).length > 0 &&
        citiesByDepartment.find((value) => value.idCity == city.current);
      setCityFound(citySelected ? citySelected.description : '');
      reduxGetNeighborhoodsByCity({
        idCity: city.current,
      });
    }
  }, [city.current]);

  useEffect(() => {
    if (neighborhood.current != '' && neighborhood.current != undefined) {
      const neighborhoodSelected =
        neighborhoodsByCity &&
        Object.keys(neighborhoodsByCity).length > 0 &&
        neighborhoodsByCity.find((value) => value.idNeighborhood == neighborhood.current);
      setNeighborhoodFound(neighborhoodSelected ? neighborhoodSelected.description : '');
    }
  }, [neighborhood.current]);

  const checkAll = (e) => {
    if (e.target.checked) {
      setValue(
        'routesChecked',
        currentRoute
          ? currentRoute.route.map((route) => {
            return route.idRoute.toString();
          })
          : []
      );
    } else {
      setValue('routesChecked', []);
    }
  };

  const onSubmitModal = async (data) => {
    const objroutesChecked = getValues('routesChecked');

    console.log(data);
    if (showCourier && (idUser2 == null || idUser2 == undefined)) {
      setCourierError2(true);
      return;
    }

    const file = data.file && data.file[0];

    if (data.date2 || (showState && data.state2 != undefined && data.state2 != '')) {
      reduxPatchRoutesCourier({
        idUser: showCourier ? String(idUser2) : '',
        routesChecked: typeof objroutesChecked == 'string' ? [objroutesChecked] : objroutesChecked,
        date: data.date2
          ? String(moment(String(data.date2).substring(0, 10)).tz('America/Bogota').format('YYYY-MM-DD'))
          : null,
        state: showState ? String(data.state2) : '',
        picture: file ? file : null,
        idRouteObservation: showObservation ? String(data.idRecord2) : '',
        record: showNovelty && data.idNote ? String(data.idNote) : '',
        txtNote: data.note ? data.note : '',
        fileType: data.file && data.file.length > 0 ? data.file[0].name.split('.').pop() : '',
      });
      resetModal();
    } else {
      Swal.fire('Información', 'Debe seleccionar el mensajero al que serán asignadas las rutas seleccionadas', 'info');
    }
  };

  useEffect(() => {
    if (routesCourier) {
      setShow(false);
      if (routesCourier.newUser) {
        Swal.fire(
          'Proceso exitoso!',
          (routesCourier.total == 1
            ? 'Se asignó ' + routesCourier.total + ' ruta '
            : 'Se asignaron ' + routesCourier.total + ' rutas ') +
          'del mensajero ' +
          routesCourier.nameOldUser +
          ' al mensajero ' +
          routesCourier.nameNewUser,
          'success'
        );
      } else if (routesCourier.newState) {
        Swal.fire(
          'Proceso exitoso!',
          'Se cambiaron ' +
          routesCourier.total +
          ' rutas del mensajero ' +
          routesCourier.nameOldUser +
          ' al estado ' +
          routesCourier.state,
          'success'
        );
      } else {
        Swal.fire(
          'Proceso exitoso!',
          'Se cambiaron ' +
          routesCourier.total +
          ' rutas del mensajero ' +
          routesCourier.nameOldUser +
          ' a la fecha ' +
          routesCourier.route.assignedDate.substring(0, 10),
          'success'
        );
      }

      if (routesCourier.newState) {
        resetRoute();
        reduxGetCurrentRouteByCourier({
          date: getValues('date'),
          idUser: idUser,
          state: getValues('state'),
        });
        setValue('routesChecked', []);
        setValue('all', false);
        reset();
      } else {
        reduxGetCouriers({});
        reduxResetForm();
        reset();
        onClear();
        setValue('routesChecked', []);
        setValue('all', false);
        reset();
      }
    }
  }, [routesCourier]);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const onMenuOpen = () => setIsMenuOpen(true);
  const onMenuClose = () => setIsMenuOpen(false);
  const selectCourier = (e) => {
    if (e) {
      setIdUser(e.value);
      setCourierError(false);
      setShowDownload(false);
    }
  };

  const [isMenuOpen2, setIsMenuOpen2] = useState(false);
  const onMenuOpen2 = () => setIsMenuOpen2(true);
  const onMenuClose2 = () => setIsMenuOpen2(false);
  const selectCourier2 = (e) => {
    if (e) {
      setIdUser2(e.value);
      setCourierError2(false);
    }
  };

  const handledChangeState = (e) => {
    let status = e.target.value
    setValueModal('state2', status);

    status && reduxGetNoveltiesByCode({
      page: 1,
      search: '',
      offset: 1000,
      isActive: true,
      status: status
    });

    if (status === StatusEnum.ENTREG || status === StatusEnum.NOENTR) {
      setShowPicture(true);
      setShowNovelty(true);
      setShowOther(false);
    } else if(status === '') {
      setShowPicture(false);
      setShowNovelty(false);
    } else {
      setShowPicture(false);
      setShowNovelty(true);
    }

    setValueModal('txtNote', '');
    setValueModal('idRecord2', null);
    setValueModal('record', null);
    setFileError(null);
    setFileName(null);
    setValueModal('note', null);

    /*
    switch (e.target.value) {
      case 'NOENTR':
        setShowTitleDelivery(false);
        setShowTitleNoDelivery(true);
        setShowTitleReturned(false);
        break;
      case 'ENTRAN':
        setShowTitleDelivery(false);
        setShowTitleNoDelivery(false);
        setShowTitleReturned(true);
        break;
      default:
        setShowTitleDelivery(true);
        setShowTitleNoDelivery(false);
        setShowTitleReturned(false);
        break;
    }*/
  };

  const handleRecord = (event) => {
    let novelty = event.target.value

    setValueModal('idRecord2', novelty);
    setValueModal('idNote', undefined);
    reduxGetNotes({
      page: 1,
      offset: 10000,
      search: '',
      idRecord: novelty,
      isActive: true,
    });
  };

  const handleNote = (event) => {
    setValueModal('idNote', event.target.value);
    const notesWithComment =
      notes && notes.items.filter((n) => n.description == 'Valor distinto' || n.description == 'Otro');
    if (notesWithComment.length > 0) {
      const selectedWithComment = notesWithComment.filter((nc) => nc.idNote == Number(event.target.value));
      if (selectedWithComment.length > 0) {
        setShowOther(true);
        setValueModal('note', null);
      } else {
        setShowOther(false);
        setValueModal('note', null);
      }
    } else {
      setShowOther(false);
      setValueModal('note', null);
    }
  };

  const handleShowDownload = () => {
    const token = window.localStorage.getItem('token');

    const courierSelected = couriers.find(e => e.idUser === idUser);
    const courierName = courierSelected.nameHeadquarters != null
      ? `${courierSelected.nameHeadquarters} - ${courierSelected.firstName} ${courierSelected.lastName}`
      : `${courierSelected.firstName} ${courierSelected.lastName}`;

    fetch(`${process.env.REACT_APP_API_URL}/api/v1/routes/download?&idCourier=${idUser}&date=${getValues('date')}&state=${getValues('state')}`, {
      method: 'POST',
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      }),
    })
      .then((response) => response.blob())
      .then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = `${getValues('date')}_${courierName}_assignments.xlsx`;
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
  };

  const fileChangedHandler = (e) => {
    const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;

    if (!allowedExtensions.exec(e.target.files[0].name)) {
      Swal.fire('Información', 'Archivo no soportado. Debes usar .jpg, .jpeg, .png, .gif', 'info');
      setFileName('');
      setValueModal('file', '');
    } else {
      setFileName(e.target.files[0].name);
    }
  };

  return (
    <>
      <div>
        <div id='wrapper'>
          <Sidebar />
          <div
            id='content-wrapper'
            className='d-flex flex-column'>
            <div id='content'>
              <Topbar />
              <div className='container-fluid'>
                <div className='d-sm-flex align-items-center mb-1'>
                  <h1 className='h3 mb-0 text-gray-800 mr-3'>Asignaciones</h1>
                </div>
                <div className='ml-2'></div>

                <p className='mb-4'>Módulo para visualizar las rutas de los mensajeros</p>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className='row px-2'>
                    <div className='col s6 m7'>
                      <div className='form-group'>
                        <label
                          htmlFor='idUser'
                          className='form-label'>
                          Mensajero
                        </label>
                        <Select
                          isClearable
                          id='idUser'
                          name='idUser'
                          onMenuOpen={onMenuOpen}
                          onMenuClose={onMenuClose}
                          onChange={selectCourier}
                          ref={selectInputRef}
                          options={
                            couriers &&
                              Object.keys(couriers).length > 0 ?
                              couriers.map((element) => ({
                                value: element.idUser,
                                label:
                                  element.nameHeadquarters != null
                                    ? `${element.nameHeadquarters} - ${element.firstName} ${element.lastName}`
                                    : `${element.firstName} ${element.lastName}`,
                              }))
                              : []
                          }
                        />
                        {courierError && (
                          <p
                            style={{
                              color: 'red',
                              fontStyle: 'italic',
                              textAlign: 'center',
                            }}>
                            Debe seleccionar un mensajero.
                          </p>
                        )}
                      </div>
                    </div>
                    <div className='col s6 m2'>
                      <div className='form-group'>
                        <label
                          htmlFor='date'
                          className='form-label'>
                          Fecha
                        </label>
                        <div>
                          <DatePicker
                            selected={startDate}
                            onChange={(date) => handledChange(date)}
                            dateFormat='MM-dd-yyyy'
                            customInput={<DatePickerCustom />}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='col s6 m6'>
                      <div className='form-group'>
                        <label
                          htmlFor='state'
                          className='form-label'>
                          Estado
                        </label>
                        <Select
                            isClearable={true}
                            {...register('state')}
                            id='state'
                            onChange={(value) => setValue('state', value?.value || '')}
                            options={
                              statuses && Object.keys(statuses).length > 0
                                ? [
                                  ...statuses.items
                                  .filter((f) => f.routeStates === true && f.code !== 'ENTREG')
                                  .map((ele) => ({
                                    value: ele.code,
                                    label: ele.name,
                                  })),
                                ]
                                : []
                            }
                        />
                      </div>
                    </div>
                    <div className='col-1'>
                      <Button
                        variant='primary'
                        style={{ marginTop: '32px' }}
                        type='submit'
                        id='btnSearch'>
                        Buscar
                      </Button>
                    </div>
                    <Show when='feature:download-assignament'> </Show>
                    <div className='col-2'>
                      {currentRoute && currentRoute.route.length > 0 && showDownload && (
                        <button
                          className='btn btn-primary  mr-2'
                          type='button'
                          style={{ marginTop: '32px' }}
                          onClick={(e) => handleShowDownload()}>
                          <i className='fas fa-file-download mr-1'></i>
                          Descargar
                        </button>
                      )}
                    </div>
                  </div>
                  <div className='card shadow mb-4'>
                    <div className='card-header py-3'>
                      <h6 className='m-0 font-weight-bold text-primary'>Listado de Rutas</h6>
                    </div>
                    <div className='card-body'>
                      <Grid
                        cols={columns}
                        offset={offset}
                        data={currentRoute && Object.keys(currentRoute).length > 0 ? currentRoute.route : []}
                        total={currentRoute && currentRoute.hasOwnProperty('total') ? currentRoute.total : 0}
                      />
                    </div>
                    {currentRoute && currentRoute.route.length > 0 && (
                      <div className='card-body'>{isLoaded ? renderMap() : null}</div>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Modal
          show={show}
          onHide={handleClose}
          onEscapeKeyDown={handleClose}
          backdrop='static'
          keyboard={true}>
          <form onSubmit={handleSubmitModal(onSubmitModal)}>
            <Modal.Header closeButton>
              <Modal.Title>
                {showCourier
                  ? 'Cambiar mensajero de la ruta'
                  : showState
                    ? 'Cambiar estado de la ruta'
                    : 'Cambiar fecha de la ruta'}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='col s12 m6'>
                {showCourier && (
                  <div className='form-group'>
                    <label
                      htmlFor='idUser2'
                      className='form-label'>
                      Mensajero:
                    </label>
                    <Select
                      id='idUser2'
                      name='idUser2'
                      onMenuOpen={onMenuOpen2}
                      onMenuClose={onMenuClose2}
                      onChange={selectCourier2}
                      ref={selectInputRef2}
                      options={
                        couriers2 &&
                        Object.keys(couriers2).length > 0 &&
                        couriers2.map((element) => {
                          return {
                            value: element.idUser,
                            label:
                              element.nameHeadquarters != null
                                ? `${element.nameHeadquarters} - ${element.firstName} ${element.lastName}`
                                : `${element.firstName} ${element.lastName}`,
                          };
                        })
                      }
                    />
                    {courierError2 && (
                      <p
                        style={{
                          color: 'red',
                          fontStyle: 'italic',
                          textAlign: 'center',
                        }}>
                        Debe seleccionar un mensajero.
                      </p>
                    )}
                  </div>
                )}
                {showDate && (
                  <div className='form-group'>
                    <label
                      htmlFor='date2'
                      className='form-label'>
                      Fecha
                    </label>
                    <div>
                      <DatePicker
                        selected={startDate2}
                        onChange={(date) => handledChange2(date)}
                        dateFormat='MM-dd-yyyy'
                        customInput={<DatePickerCustom2 />}
                      />
                    </div>
                  </div>
                )}
                {showState && (
                  <div className='form-group'>
                    <label
                      htmlFor='state2'
                      className='form-label'>
                      Estado
                    </label>

                    <select
                      id='state2'
                      name='state2'
                      className={`custom-select ${showState && errorsModal.state2 && 'is-invalid'}`}
                      onChange={(e) => handledChangeState(e)}>
                      <option value={'null'}>Seleccionar…</option>
                      {statuses &&
                        Object.keys(statuses).length > 0 &&
                        statuses.items
                          .filter((obj) => statesInModal.includes(obj.code))
                          .map((ele, key) => (
                              <option
                                key={key}
                                value={ele.code}>
                                {ele.name}
                              </option>
                          ))}
                    </select>
                  </div>
                )}
                {showNovelty && (
                  <div className='form-group'>
                    <label
                      htmlFor='idRecord2'
                      className='form-label'>
                      Novedad
                    </label>
                    <select
                      {...registerModal('idRecord2', {
                        required:
                          showObservation && (!getValuesModal('idRecord2') || getValuesModal('idRecord2') === ''),
                      })}
                      id='idRecord2'
                      className={`custom-select ${errorsModal.idRecord2 && 'is-invalid'}`}
                      onChange={(e) => handleRecord(e)}>
                      <option value={''}>Seleccionar…</option>
                      {novelties &&
                        Object.keys(novelties).length > 0 &&
                        novelties.map((ele, key) =>  (
                            <option
                              key={key}
                              value={ele.idNovelty}>
                              {ele.description}
                            </option>
                          )
                        )}
                    </select>
                    {showObservation && (!getValuesModal('idRecord2') || getValuesModal('idRecord2') == '') && (
                      <span className='invalid-feedback'>La novedad es requerida</span>
                    )}
                  </div>
                )}
                {showState && showNovelty && getValuesModal('idRecord2') == '3' && (
                  <div className='form-group'>
                    <label
                      htmlFor='idNote'
                      className='form-label'>
                      Nota
                    </label>
                    <select
                      {...registerModal('idNote', {
                        required: getValuesModal('idRecord2') == '3' ? true : false,
                      })}
                      id='idNote'
                      className={`custom-select ${errorsModal.idNote && 'is-invalid'}`}
                      disabled={
                        getValuesModal('idRecord2') == '' || getValuesModal('idRecord2') == undefined ? true : false
                      }
                      onChange={(e) => handleNote(e)}>
                      <option value={''}>Seleccionar…</option>
                      {notes &&
                        Object.keys(notes).length > 0 &&
                        notes.items.map((ele, key) => (
                          <option
                            key={key}
                            value={ele.idNote}>
                            {ele.description}
                          </option>
                        ))}
                    </select>
                    {showObservation && errorsModal.idNote && (
                      <span className='invalid-feedback'>La nota es requerida</span>
                    )}
                  </div>
                )}
                {((getValuesModal('idRecord2') &&
                  getValuesModal('idRecord2') != '' &&
                  getValuesModal('idNote') == undefined) ||
                  showOther) && (
                      <>
                        <label
                            className='form-label'>
                          Comentario
                        </label>
                        <input
                            id='note'
                            placeholder='Escriba comentario'
                            type='text'
                            className={`form-control form-control-user`}
                            {...registerModal('note')}
                            style={{ marginTop: '5px', marginBottom: '10px' }}
                        />
                      </>
                  )}
                {showState && showPicture && (
                  <>
                    {showTitleDelivery && (
                      <label
                        htmlFor='file'
                        className='form-label'>
                        Foto de entrega
                      </label>
                    )}
                    {showTitleNoDelivery && (
                      <label
                        htmlFor='file'
                        className='form-label'>
                        Evidencia de la No Entrega
                      </label>
                    )}
                    {showTitleReturned && (
                      <label
                        htmlFor='file'
                        className='form-label'>
                        Evidencia de la Devolución
                      </label>
                    )}
                    <div className='custom-file'>
                      <input
                        type='file'
                        id='file'
                        name='file'
                        {...registerModal('file')}
                        className={`custom-file-input ${errorsModal.file && 'is-invalid'}`}
                        onChange={(e) => fileChangedHandler(e)}
                      />{' '}
                      <label
                        className='custom-file-label'
                        htmlFor='validatedCustomFile'>
                        {fileError ? fileError : fileName ? fileName : 'Seleccionar archivo...'}
                      </label>
                    </div>
                  </>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant='secondary'
                onClick={handleClose}>
                Cancelar
              </Button>
              <Button
                variant='primary'
                type='submit'>
                Guardar
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      </div>
      <div>
        <Modal
          size='xl'
          show={showTimeline}
          onHide={handleCloseTimeline}
          onEscapeKeyDown={handleCloseTimeline}
          backdrop='static'
          keyboard={true}>
          <Modal.Header closeButton>
            <Modal.Title>Timeline</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Timeline idAddress={idAddressSelected}></Timeline>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    statuses: state.statusesState.statuses,
    currentRoute: state.routeState.currentRoute,
    couriers: state.userState.couriers,
    couriers2: state.userState.couriers,
    rowDeleted: state.routeState.rowDeleted,
    cities: state.cityState.cities,
    neighborhoodsByCity: state.neighborhoodState.neighborhoodsByCity,
    countries: state.countryState.countries,
    departmentsByCountry: state.departmentState.departmentsByCountry,
    citiesByDepartment: state.cityState.citiesByDepartment,
    zoneNeighborhoods: state.zoneNeighborhoodState.zoneNeighborhoods,
    rowEdited: state.routeState.rowEdited,
    routesCourier: state.routeState.routesCourier,
    rowsDeleted: state.routeState.rowsDeleted,
    companyByUser: state.companyState.companyByUser,
    novelties: state.noveltyState.novelties,
    routeRecord: state.routeRecordState.routeRecord,
    notes: state.noteState.notes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    reduxGetStatuses: (payload) =>
      dispatch({
        type: "FETCH_NEW_STATUS_REQUEST",
        value: payload,
      }),
    reduxGetNoveltiesByCode: (payload) =>
      dispatch({
        type: 'FETCH_NOVELTIES_BY_STATUS_CODE_REQUEST',
        value: payload,
      }),
    reduxPostRouteRecord: (payload) =>
      dispatch({
        type: 'CREATE_ROUTE_RECORD_REQUEST',
        value: payload,
      }),
    reduxGetCurrentRouteByCourier: (payload) =>
      dispatch({
        type: 'FETCH_CURRENT_ROUTE_REQUEST',
        value: payload,
      }),
    reduxGetCouriers: (payload) =>
      dispatch({
        type: 'FETCH_COURIERS_REQUEST',
        value: payload,
      }),
    reduxResetForm: () => {
      dispatch({
        type: 'RESET_ROUTE_FORM',
      });
    },
    reduxDeleteRoute: (payload) => {
      dispatch({
        type: 'DELETE_ROUTE_FORM',
        value: payload,
      });
    },
    reduxGetCities: (payload) =>
      dispatch({
        type: 'FETCH_CITIES_REQUEST',
        value: payload,
      }),
    reduxGetCountries: (payload) =>
      dispatch({
        type: 'FETCH_COUNTRIES_REQUEST',
        value: payload,
      }),
    reduxGetDepartmentsByCountry: (payload) =>
      dispatch({
        type: 'READBYCOUNTRY_DEPARTMENT_REQUEST',
        value: payload,
      }),
    reduxGetCitiesByDepartment: (payload) =>
      dispatch({
        type: 'READBYDEPARTMENT_CITY_REQUEST',
        value: payload,
      }),
    reduxGetNeighborhoodsByCity: (payload) =>
      dispatch({
        type: 'READBYCITY_NEIGHBORHOOD_REQUEST',
        value: payload,
      }),
    reduxSetDepartment: (payload) =>
      dispatch({
        type: 'RESET_BYCOUNTRY_DEPARTMENT',
        value: payload,
      }),
    reduxSetCity: (payload) =>
      dispatch({
        type: 'RESET_BYDEPARTMENT_CITY',
        value: payload,
      }),
    reduxSetNeighborhood: (payload) =>
      dispatch({
        type: 'RESET_BYCITY_NEIGHBORHOOD',
        value: payload,
      }),
    reduxGetRoute: (payload) =>
      dispatch({
        type: 'READ_ROUTE_REQUEST',
        value: payload,
      }),
    reduxPatchRoutesCourier: (payload) =>
      dispatch({
        type: 'UPDATECOURIER_ROUTE_REQUEST',
        value: payload,
      }),
    reduxDeleteAllRoutes: (payload) =>
      dispatch({
        type: 'DELETEALL_ROUTE_FORM',
        value: payload,
      }),
    reduxGetCompanyByUser: () =>
      dispatch({
        type: 'READBYUSER_COMPANY_REQUEST',
      }),
    reduxResetFormRouteRecord: () => {
      dispatch({
        type: 'RESET_ROUTE_RECORD_FORM',
      });
    },
    reduxGetNotes: (payload) =>
      dispatch({
        type: 'FETCH_NOTES_REQUEST',
        value: payload,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Assignments);
