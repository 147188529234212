import 'moment-timezone';
const moment = require('moment-timezone');
const timezone = 'America/Bogota';
moment.locale("es");

export const TimeZones = {
    AMERICA_BOGOTA: "America/Bogota",
};

export const FormatsDate = {
    FULL24: "YYYY-MM-DD HH:mm:ss",
    SHORT: "YYYY-MM-DD",
    FULL: "YYYY-MM-DD hh:mm:ss A",
    TIME24: "HH:mm:ss",
    TIME: "hh:mm:ss A",
    TIME_HOURS24: "HH:mm",
    TIME_HOURS: "hh:mm A"
};

export const formatDateWithCustomFormat = (date, options = {}) => {
    const {
        timezone = TimeZones.AMERICA_BOGOTA,
        type = true,
        amount = 0,
        unit = 'hours',
        format = FormatsDate.SHORT,
    } = options || {};
    const tz = moment(date).tz(timezone);
    const add = type ? tz.add(amount, unit) : tz.subtract(amount, unit);
    return add.format(format);
}

export const formatDateWithoutTimeZone = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
}


export const formattedDate = (dateto) => {
    const date = new Date(dateto);

    const formattedDate = date.toLocaleDateString('es-ES', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
       // timeZoneName: 'short',
        hour12: false
    });

    return formattedDate
}

export default formatDateWithCustomFormat;

