import React, {useEffect} from "react";
import { Stepper } from 'react-form-stepper';
import check from '../../assets/img/check.svg'

const SteperComponent = ({activeStep}) => {
    return (
        <Stepper
            styleConfig={{
                activeBgColor:'#00BAEA',
                completedBgColor:'#c1c1c1',
            }}
            connectorStyleConfig={{completedColor: '#00BAEA', activeColor: '#00BAEA'}}
            steps={[
                { label: 'Asignada a un operador' },
                { label: 'Asignada a un mensajero' },
                { label: 'En ruta/distribución' },
                { label: 'Entregado' },
                { label: 'No entregado' },
                { label: 'Devolucion en transito' },
                { label: 'Sin Asignar' },
            ]}
            activeStep={activeStep}
        />
    )
}

export default SteperComponent;