import {call, put, takeLatest} from "redux-saga/effects";
import request from "../../utils/request";

function* getAllCollects(payload) {
  try {
    yield put({
      type: "FETCH_COLLECTS_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/collects?page=${payload.value.page}&search=${payload.value.search}&offset=${payload.value.offset}&idCourier=${payload.value.idCourier}`;
    const headers = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "HIDE_LOADING",
    });

    yield put({
      type: "FETCH_COLLECT_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed load",
        message: "Failed load collects",
      },
    });
    yield put({
      type: "FETCH_COLLECT_ERROR",
    });
  }
}

function* postCollect(payload) {
  try {
    yield put({ type: "CREATE_COLLECT_REQUESTING" });
    yield put({
      type: "SHOW_LOADING",
    });
    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/collects`;

    const headers = {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(payload.value),
    };

    const response = yield call(request, requestURL, headers);

    yield put({
      type: "HIDE_LOADING",
    });

    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "success",
        title: "Successful save",
        message: "Successful save collect",
      },
    });

    yield put({
      type: "CREATE_COLLECT_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed save",
        message: "Failed save collect",
      },
    });
    yield put({
      type: "CREATE_COLLECT_ERROR",
    });
  }
}

function* getCollectById(payload) {
  try {
    yield put({
      type: "READ_COLLECT_REQUESTING",
    });
    yield put({
      type: "SHOW_LOADING",
    });
    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/collects/${payload.value.id}`;

    const headers = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "HIDE_LOADING",
    });

    yield put({
      type: "READ_COLLECT_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "READ_COLLECT_ERROR",
    });
  }
}

function* updateCollect(payload) {
  try {
    yield put({
      type: "UPDATE_COLLECT_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/collects/${payload.value.id}`;

    const headers = {
      method: "PATCH",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(payload.value),
    };

    const response = yield call(request, requestURL, headers);

    yield put({
      type: "HIDE_LOADING",
    });

    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "success",
        title: "Successful update",
        message: "Successful update collect",
      },
    });

    yield put({
      type: "UPDATE_COLLECT_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed update",
        message: "Failed update collect",
      },
    });
    yield put({
      type: "UPDATE_COLLECT_ERROR",
    });
  }
}

function* getAllCollectsWithoutVerify(payload) {
  try {
    yield put({
      type: "FETCH_COLLECTS_WITHOUT_VERIFY_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/collects/collectsWithoutVerify?page=${payload.value.page}&search=${payload.value.search}&offset=${payload.value.offset}&idCourier=${payload.value.idCourier}`;
    const headers = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "HIDE_LOADING",
    });

    yield put({
      type: "FETCH_COLLECT_WITHOUT_VERIFY_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed load",
        message: "Failed load collects without verify",
      },
    });
    yield put({
      type: "FETCH_COLLECT_WITHOUT_VERIFY_ERROR",
    });
  }
}

export function* watchCollect() {
  yield takeLatest("FETCH_COLLECTS_REQUEST", getAllCollects);
  yield takeLatest("CREATE_COLLECT_REQUEST", postCollect);
  yield takeLatest("READ_COLLECT_REQUEST", getCollectById);
  yield takeLatest("UPDATE_COLLECT_REQUEST", updateCollect);
  yield takeLatest(
    "FETCH_COLLECTS_WITHOUT_VERIFY_REQUEST",
    getAllCollectsWithoutVerify
  );
}
