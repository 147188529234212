import { connect } from "react-redux";
import { Nav, Tab } from "react-bootstrap";
import { useEffect, useState } from "react";

import { Show } from "../../hooks/Show";
import ReschedulingByIds from "./ReschedulingByIds";
import Topbar from '../../components/Navigation/Topbar';
import Sidebar from '../../components/Navigation/Sidebar';
import UploadMasivReschedule from "./UploadMasivReschedule";
import ReschedulingTableComponent from "./ReschedulingTableComponent";

const Rescheduling = ({
}) => {
  const [ title , setTitle ] = useState();
  const [ subTitle , setSubTitle ] = useState();
  const [ iconUploadMasiv , setIconUploadMasiv ] = useState();

  useEffect(()=> {
    setTitle("Reprogramación");
    setSubTitle("Módulo de Reprogramaciones");
  }, [])

  const handleSelect = (tabSelected) => {
    if(tabSelected === 'single'){
      setTitle("Reprogramación");
      setSubTitle("Módulo de Reprogramaciones");
      setIconUploadMasiv(false)
    } else if(tabSelected === 'masiv'){
      setTitle("Reprogramaciones masivas");
      setSubTitle("Módulo de Reprogramaciones masivas");
      setIconUploadMasiv(true)
    }
  }

  return (
    <div>
      <div id='wrapper'>
        <Sidebar />
        <div
          id='content-wrapper'
          className='d-flex flex-column'>
          <div id='content'>
            <Topbar />
            <div className='container-fluid'>
              <div className='d-sm-flex align-items-center mb-1'>
                <h1 className='h3 mb-0 text-gray-800 mr-3'>{title}</h1>
                {(iconUploadMasiv && <UploadMasivReschedule />)}
              </div>
              <p className='mb-4'>{subTitle}</p>
              <Tab.Container defaultActiveKey="single" mountOnEnter={true} onSelect={(k) => handleSelect(k)}>
                <Nav variant="tabs" className=" d-flex flex-nowrap flex-row mt-2" >
                    <Nav.Item className='d-flex flex-column'>
                        <Nav.Link eventKey="single">
                            Individuales
                        </Nav.Link>
                    </Nav.Item>
                    <Show when='feature:tab-rescheduling-masiv'>
                        <Nav.Item className='d-flex flex-column'>
                            <Nav.Link eventKey="masiv">
                                Masivas
                            </Nav.Link>
                        </Nav.Item>
                    </Show>
                </Nav>
                <Tab.Content>
                    <Tab.Pane eventKey="single">
                      <ReschedulingTableComponent />
                    </Tab.Pane>
                    <Show when='feature:tab-rescheduling-masiv'>
                      <Tab.Pane eventKey="masiv">
                        <ReschedulingByIds />
                      </Tab.Pane>
                    </Show>
                </Tab.Content>
              </Tab.Container>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};
const mapStateToProps = (state) => {
  return {
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Rescheduling);