import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Sidebar from "../../components/Navigation/Sidebar";
import Topbar from "../../components/Navigation/Topbar";
import Grid from "../../components/Grid";
import { connect } from "react-redux";
import { Button, Modal } from "react-bootstrap";
import { Show } from "../../hooks/Show";
import { useAuth } from "../../hooks/useAuth";
import "react-datepicker/dist/react-datepicker.css";

const LotProduct = ({
  reduxGetLotProducts,
  lotProducts,
  reduxGetLotProduct,
  lotProduct,
  reduxPostLotProduct,
  rowEdited,
  reduxDeleteLotProduct,
  rowDeleted,
  reduxPatchLotProduct,
  rowUpdated,
  reduxResetLotProductForm,
  reduxGetLots,
  lots,
  reduxGetProducts,
  products,
  reduxDeleteSoftLotProduct,
  rowDeletedSoft,
}) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
    setValue,
  } = useForm({});
  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(10);
  const { session } = useAuth();
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    reduxResetLotProductForm();
  };
  const handleShow = () => {
    reduxGetLots({
      page: 1,
      offset: 100000,
      search: "",
      isActive: true,
    });
    reduxGetProducts({
      page: 1,
      offset: 100000,
      search: "",
      isActive: true,
    });
    reduxResetLotProductForm();
    reset();
    setShow(true);
  };
  const [search, setSearch] = useState("");

  const columns = [
    {
      title: "#",
      render: (rowData) => {
        return <span>{rowData.idLotProduct}</span>;
      },
      field: 'idLotProduct',
      searchable: true
    },
    {
      title: "Lote",
      render: (rowData) => {
        return <span>{rowData.lot.name}</span>;
      },
      field: 'lot.name',
      searchable: true
    },
    {
      title: "Producto",
      render: (rowData) => {
        return <span>{rowData.product.name}</span>;
      },
      field: 'product.name',
      searchable: true
    },
    {
      title: "Cantidad",
      render: (rowData) => {
        return <span>{rowData.amount}</span>;
      },
      field: 'amount',
      searchable: true
    },
    {
      title: "Precio de compra",
      render: (rowData) => {
        return <span>{rowData.purchasePrice}</span>;
      },
      field: 'purchasePrice',
      searchable: true
    },
    {
      title: "Precio de venta",
      render: (rowData) => {
        return <span>{rowData.salePrice}</span>;
      },
      field: 'salePrice',
      searchable: true
    },
    {
      title: "Opciones",
      render: (rowData) => {
        return (
          <>
            {rowData.isActive ? (
              <>
                <Show when="feature:disabled-lotproduct">
                  <button
                    title="Desactivar"
                    className="btn btn-danger btn-sm btn-circle mr-2"
                    type="button"
                    onClick={(e) => handleDelete(rowData)}
                  >
                    <i className="fas fa-times-circle fa-xs"></i>
                  </button>
                </Show>
                <Show when="feature:delete-lotProduct">
                  <button
                    title="Eliminar"
                    className="btn btn-danger btn-sm btn-circle"
                    type="button"
                    onClick={(e) => handleDeleteSoft(rowData)}
                  >
                    <i className="fas fa-trash-alt fa-xs"></i>
                  </button>
                </Show>
              </>
            ) : (
              <Show when="feature:enabled-lotproduct">
                <button
                  title="Activar"
                  className="btn btn-primary btn-sm  btn-circle mr-2"
                  type="button"
                  onClick={(e) => handleActive(rowData)}
                >
                  <i className="fas fa-check-circle fa-xs"></i>
                </button>
              </Show>
            )}
          </>
        );
      },
    },
  ];

  const handleOpen = (row) => {
    reduxGetLots({
      page: 1,
      offset: 100000,
      search: "",
      isActive: true,
    });
    reduxGetProducts({
      page: 1,
      offset: 100000,
      search: "",
      isActive: true,
    });
    reduxGetLotProduct({
      id: row.idLotProduct,
    });
  };

  const handleActive = (row) => {
    if (!row.isActive) {
      reduxDeleteLotProduct(row);
    }
  };

  useEffect(() => {
    if (rowEdited) {
      setShow(true);
      setValue("idLot", rowEdited.idLot);
      setValue("idProduct", rowEdited.idProduct);
      setValue("amount", rowEdited.amount);
      setValue("purchasePrice", rowEdited.purchasePrice);
      setValue("salePrice", rowEdited.salePrice);
    }
  }, [rowEdited]);

  const handleDelete = (row) => {
    reduxDeleteLotProduct(row);
  };

  useEffect(() => {
    reduxGetLotProducts({
      page: 1,
      offset: offset,
      search: "",
    });
  }, []);

  useEffect(() => {
    reduxGetLotProducts({
      page: currentPage,
      offset: offset,
      search: search,
    });
  }, [currentPage]);

  useEffect(() => {
    if (lotProduct || rowUpdated || rowDeleted || rowDeletedSoft) {
      if (
        (lotProduct && lotProduct.message == undefined) ||
        (rowUpdated && rowUpdated.message == undefined) ||
        (rowDeletedSoft && rowDeletedSoft.message == undefined) ||
        rowDeleted
      ) {
        reduxGetLotProducts({
          page: currentPage,
          offset: offset,
          search: search,
        });
        reduxResetLotProductForm();
      }
    }
  }, [lotProduct, rowUpdated, rowDeleted, rowDeletedSoft]);

  const onSubmit = (data) => {
    if (rowEdited) {
      reduxPatchLotProduct({ ...data, id: rowEdited.idLotProduct });
    } else {
      reduxPostLotProduct(data);
    }
    reset();
    reduxResetLotProductForm();
    setShow(false);
  };

  const handleDeleteSoft = (row) => {
    reduxDeleteSoftLotProduct(row);
  };

  return (
    <div>
      <div id="wrapper">
        <Sidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Topbar />
            <div className="container-fluid">
              <div className="d-sm-flex align-items-center mb-1">
                <h1 className="h3 mb-0 text-gray-800 mr-3">Lote - Producto</h1>
                <Show when="feature:create-lotproduct">
                  <button
                    className="btn btn-primary btn-circle"
                    type="button"
                    onClick={handleShow}
                  >
                    <i className="fas fa-plus fa-sm"></i>
                  </button>
                </Show>
              </div>
              <p className="mb-4">
                Módulo de Administración de lote - producto
              </p>
              <div className="card shadow mb-4">
                <div className="card-header py-3">
                  <h6 className="m-0 font-weight-bold text-primary">
                    Listado de Lotes - Productos
                  </h6>
                </div>
                <div className="card-body">
                  <Grid
                    cols={columns}
                    data={
                      lotProducts && Object.keys(lotProducts).length > 0
                        ? lotProducts.items
                        : []
                    }
                    page={
                      lotProducts && Object.keys(lotProducts).length > 0
                        ? Number(lotProducts.page)
                        : currentPage
                    }
                    pages={
                      lotProducts && Object.keys(lotProducts).length > 0
                        ? Number(lotProducts.totalPages)
                        : 0
                    }
                    total={
                      lotProducts && lotProducts.hasOwnProperty("total")
                        ? lotProducts.total
                        : 0
                    }
                    offset={offset}
                    onChangePage={(page) => setCurrentPage(page)}
                    onChangeRange={(value) => {
                      setOffset(value);
                      reduxGetLotProducts({
                        page: 1,
                        offset: value,
                        search: search,
                      });
                    }}
                    defaultValue={search}
                    onChangeSearch={(value) => {
                      setSearch(value);
                      reduxGetLotProducts({
                        page: 1,
                        offset: offset,
                        search: value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Modal
          show={show}
          onHide={handleClose}
          onEscapeKeyDown={handleClose}
          backdrop="static"
          keyboard={true}
          size={rowEdited == undefined || !rowEdited ? "xl" : "lg"}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <Modal.Header closeButton>
              <Modal.Title>Lote - Producto</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-group">
                <label htmlFor="idLot" className="form-label">
                  Lote
                </label>
                <select
                  {...register("idLot", { required: true })}
                  id="idLot"
                  className={`custom-select ${errors.idLot && "is-invalid"}`}
                >
                  <option value={""}>Seleccionar…</option>
                  {lots.items &&
                    Object.keys(lots.items).length > 0 &&
                    lots.items.map((ele, key) => (
                      <option key={key} value={ele.idLot}>
                        {ele.name}
                      </option>
                    ))}
                </select>
                {errors.idLot && (
                  <span className="invalid-feedback">El lote es requerido</span>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="idProduct" className="form-label">
                  Producto
                </label>
                <select
                  {...register("idProduct", { required: true })}
                  id="idProduct"
                  className={`custom-select ${
                    errors.idProduct && "is-invalid"
                  }`}
                >
                  <option value={""}>Seleccionar…</option>
                  {products &&
                    Object.keys(products).length > 0 &&
                    products.items.map((ele, key) => (
                      <option key={key} value={ele.idProduct}>
                        {ele.name}
                      </option>
                    ))}
                </select>
                {errors.idProduct && (
                  <span className="invalid-feedback">
                    El producto es requerido
                  </span>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="amount" className="form-label">
                  Cantidad
                </label>
                <input
                  id="amount"
                  type="number"
                  className={`form-control form-control-user ${
                    errors.amount && "is-invalid"
                  }`}
                  {...register("amount", { required: true })}
                />
                {errors.amount && (
                  <span className="invalid-feedback">
                    La cantidad es requerida
                  </span>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="purchasePrice" className="form-label">
                  Precio de compra
                </label>
                <input
                  id="purchasePrice"
                  type="number"
                  className={`form-control form-control-user ${
                    errors.purchasePrice && "is-invalid"
                  }`}
                  {...register("purchasePrice", { required: true })}
                />
                {errors.purchasePrice && (
                  <span className="invalid-feedback">
                    El precio de compra es requerido
                  </span>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="salePrice" className="form-label">
                  Precio de venta
                </label>
                <input
                  id="salePrice"
                  type="number"
                  className={`form-control form-control-user ${
                    errors.salePrice && "is-invalid"
                  }`}
                  {...register("salePrice", { required: true })}
                />
                {errors.salePrice && (
                  <span className="invalid-feedback">
                    El precio de venta es requerido
                  </span>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cancelar
              </Button>
              <Button variant="primary" type="submit">
                Guardar
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    lotProducts: state.lotProductState.lotProducts,
    lotProduct: state.lotProductState.lotProduct,
    rowEdited: state.lotProductState.rowEdited,
    rowDeleted: state.lotProductState.rowDeleted,
    rowUpdated: state.lotProductState.rowUpdated,
    lots: state.lotState.lots,
    products: state.productState.products,
    rowDeletedSoft: state.lotProductState.rowDeletedSoft,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    reduxGetLotProducts: (payload) =>
      dispatch({
        type: "FETCH_LOTPRODUCTS_REQUEST",
        value: payload,
      }),
    reduxPostLotProduct: (payload) =>
      dispatch({
        type: "CREATE_LOTPRODUCT_REQUEST",
        value: payload,
      }),
    reduxGetLotProduct: (payload) =>
      dispatch({
        type: "READ_LOTPRODUCT_REQUEST",
        value: payload,
      }),
    reduxDeleteLotProduct: (payload) =>
      dispatch({
        type: "DELETE_LOTPRODUCT_REQUEST",
        value: payload,
      }),
    reduxPatchLotProduct: (payload) =>
      dispatch({
        type: "UPDATE_LOTPRODUCT_REQUEST",
        value: payload,
      }),
    reduxResetLotProductForm: () =>
      dispatch({
        type: "RESET_LOTPRODUCT_FORM",
      }),
    reduxGetLots: (payload) =>
      dispatch({
        type: "FETCH_LOTS_REQUEST",
        value: payload,
      }),
    reduxGetProducts: (payload) =>
      dispatch({
        type: "FETCH_PRODUCTS_REQUEST",
        value: payload,
      }),
    reduxDeleteSoftLotProduct: (payload) =>
      dispatch({
        type: "DELETE_SOFT_LOTPRODUCT_REQUEST",
        value: payload,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LotProduct);
