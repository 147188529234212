import React, { useState, useRef, useEffect, useContext } from 'react';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { connect } from 'react-redux';
import { Button, Modal, Spinner } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import 'react-datepicker/dist/react-datepicker.css';
import { DateTime, IANAZone } from 'luxon';
import moment from "moment-timezone";

import GoogleMapsAutocomplete from '../../components/GoogleMapsAutocomplete';
import Grid from '../../components/Grid';
import { Show } from '../../hooks/Show';
import { PermissionContext } from '../../hooks/PermissionContext';
import formatDateWithCustomFormat, { formatDateWithoutTimeZone } from '../../utils/formatDate';
import { getStatusBadgeByCode } from '../../utils/StatusUtils';
import { getBusinessDate, getDaysDiff, isBusinessDay, isNullOrUndefined } from '../../utils/functionsUtils';
import TrafficLight from '../../components/TrafficLight';
import CustomTooltip from '../../components/CustomTooltip';
import formatDate from "../../utils/formatDate";

const Swal = require('sweetalert2');

const ReschedulingTableComponent = ({
  reduxGetClients,
  clients,
  reduxGetLogisticsOperators,
  logisticsOperators,
  reduxGetAddresses,
  addresses,
  reduxResetAddressForm,
  reduxGetAddress,
  rowEdited,
  reduxPatchReschedule,
  reschedule,
  countries,
  reduxGetDepartmentsByCountry,
  departmentsByCountry,
  citiesByDepartment,
  neighborhoodsByCity,
  reduxGetCitiesByDepartment,
  reduxGetNeighborhoodsByCity,
  reduxGetCountries,
  reduxResetDepartment,
  reduxResetCity,
  reduxResetNeighborhood,
  reduxResetAddressReschedulingForm,
  reduxUploadReschedulesToDrive,
  reduxGetNovelties,
  novelties,
}) => {

  const { hasPermission } = useContext(PermissionContext);

  const modalForm = useForm({
    defaultValues: {
      rescheduleResolution: { value: 1 },
      dateReschedule: null,
      manualAddress: null,
      phone: null,
      country: null,
      countryISO: null,
      department: null,
      city2: null,
      neighborhood: null,
      collection: null,
      observations: null,
    }
  });

  const {
    register: registerSearch,
    handleSubmit: handleSubmitSearch,
    reset: resetSearch,
    setValue: setValueSearch,
    getValues: getValuesSearch,
  } = useForm({});

  const customStyles = {
    menu: (base) => ({ ...base, zIndex: 9999 }),
    valueContainer: (baseStyles) => ({ ...baseStyles, maxHeight: "3.5vw", overflowY: "auto" }),
    singleValue: (baseStyles) => ({ ...baseStyles, width: "100%" }),
    control: (baseStyles) => ({ ...baseStyles, flexWrap: "nowrap" }) // Ajusta el valor según tus necesidades
  };

  const rescheduleResolution = modalForm.watch('rescheduleResolution', { value: 1 });
  const country = modalForm.watch('country', '');
  const countryISOCode = modalForm.watch('countryISO', null);

  const rangeRef = useRef()

  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange
  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(10);
  const [search, setSearch] = useState('');
  const [isDownloading, setIsDownloading] = useState(false)
  const [showModalReschedule, setShowModalReschedule] = useState(false);
  const [valueManualAddress, setValueManualAddress] = useState('');
  const [filters, setFilters] = useState({})

  const trafficLightColors = [
    { show: true, value: 'rojo', label: <div className='row align-items-center'><span className='ml-3 mr-2 btn-xsm btn-circle red-light'></span>Prioridad Alta (1 Días)</div> },
    { show: true, value: 'naranja', label: <div className='row align-items-center'><span className='ml-3 mr-2 btn-xsm btn-circle orange-light'></span>Prioridad Media (2 Días)</div> },
    { show: true, value: 'amarillo', label: <div className='row align-items-center'><span className='ml-3 mr-2 btn-xsm btn-circle yellow-light'></span> Prioridad Baja (3 Días)</div> },
    // { show: !hasPermission("feature:isClient"), value: 'azul', label: <div className='row align-items-center'><span className='ml-3 mr-2 btn-xsm btn-circle blue-light'></span>Azul</div> },
  ]

  useEffect(() => {
    reduxGetNovelties({
      page: 1,
      search: "",
      offset: 1000,
      status: 11,
    });
    reduxGetClients();
    reduxGetLogisticsOperators();
    reduxGetCountries({
      page: 1,
      offset: 1000,
      search: '',
      isActive: true,
    });
    reduxResetAddressForm();
    reduxResetDepartment();
    reduxResetCity();
    reduxResetNeighborhood();

    setDateRange([null, null]);
    resetSearch();
    modalForm.reset();
    setShowModalReschedule(false);
    reschedule = null;
  }, []);

  useEffect(() => {
    if (
      currentPage &&
      addresses &&
      Object.keys(addresses).length > 0 &&
      getValuesSearch('from') &&
      getValuesSearch('to')
    ) {
      let filters = {
        page: currentPage,
        offset: offset,
        search: search,
      };
      if (getValuesSearch('idClient')) {
        filters = {
          ...filters,
          idClient: getValuesSearch('idClient'),
        };
      }
      if (getValuesSearch('idOperator')) {
        filters = {
          ...filters,
          idOperator: getValuesSearch('idOperator'),
        };
      }
      if (getValuesSearch('trafficLight')) {
        filters = {
          ...filters,
          trafficLight: getValuesSearch('trafficLight').value,
        };
      }
      if (getValuesSearch('novelty')) {
        filters = {
          ...filters,
          novelty: getValuesSearch('novelty').value,
        };
      }
      if (getValuesSearch('from') && getValuesSearch('to')) {
        filters = {
          ...filters,
          from: formatDateWithoutTimeZone(startDate),
          to: formatDateWithoutTimeZone(endDate),
        };
      }
      reduxGetAddresses(filters);
    }
  }, [currentPage]);

  useEffect(() => {
    if (reschedule) {
      Swal.fire('Proceso exitoso!', `Registro guardado`, 'success');
      setShowModalReschedule(false);
      let filters = {
        page: currentPage,
        offset: offset,
        search: search,
      };
      if (getValuesSearch('idClient')) {
        filters = {
          ...filters,
          idClient: getValuesSearch('idClient'),
        };
      }
      if (getValuesSearch('idOperator')) {
        filters = {
          ...filters,
          idOperator: getValuesSearch('idOperator'),
        };
      }
      if (getValuesSearch('trafficLight')) {
        filters = {
          ...filters,
          trafficLight: getValuesSearch('trafficLight').value,
        };
      }
      if (getValuesSearch('novelty')) {
        filters = {
          ...filters,
          novelty: getValuesSearch('novelty').value,
        };
      }
      if (getValuesSearch('from') && getValuesSearch('to')) {
        filters = {
          ...filters,
          from: formatDateWithoutTimeZone(startDate),
          to: formatDateWithoutTimeZone(endDate),
        };
      }
      reduxGetAddresses(filters);
    }
  }, [reschedule]);

  useEffect(() => {
    if (isNullOrUndefined(rowEdited))
      return;

    modalForm.setValue('collection', rowEdited.collection);

    const country = countries && countries.items?.find(c => c.description.toUpperCase() === rowEdited.countryNameReceiver.toUpperCase());
    if (!country) return;

    modalForm.setValue('country', { value: country.idCountry, label: country.description })
    modalForm.setValue('countryISO', country.isoCode);

    reduxGetDepartmentsByCountry({
      idCountry: Number(country.idCountry),
    });
  }, [rowEdited])

  useEffect(() => {
    if (!departmentsByCountry?.length > 0)
      return;

    const department = departmentsByCountry && departmentsByCountry.find(d => d.description.toUpperCase() === rowEdited.stateNameReceiver.toUpperCase());
    if (!department) return;

    modalForm.setValue('department', { value: department.idDepartment, label: department.description })
    reduxGetCitiesByDepartment({
      idDepartment: Number(department.idDepartment),
    });
  }, [departmentsByCountry]);

  useEffect(() => {
    if (!citiesByDepartment?.length > 0)
      return;

    const city = citiesByDepartment && citiesByDepartment.find(e => e.description.toUpperCase() === rowEdited.cityNameReceiver.toUpperCase());
    if (!city) return;

    modalForm.setValue('city2', { value: city.idCity, label: city.description });
    reduxGetNeighborhoodsByCity({
      idCity: Number(city.idCity),
    });
  }, [citiesByDepartment]);

  useEffect(() => {
    if (!neighborhoodsByCity?.length > 0)
      return;

    const neighborhood = neighborhoodsByCity && neighborhoodsByCity.find(e => e.description.toUpperCase() === rowEdited.neighborhoodNameReceiver.toUpperCase());
    if (!neighborhood) return;

    modalForm.setValue('neighborhood', {
      value: neighborhood.idNeighborhood,
      label: `${neighborhood.postalCode ? neighborhood.postalCode + ' - ' : ''}${neighborhood.description}`
    });
  }, [neighborhoodsByCity]);

  useEffect(() => {
    if (!rescheduleResolution)
      return;

    if (rescheduleResolution.value !== 2) {
      modalForm.setValue('dateReschedule', null);
      modalForm.setValue('manualAddress', '');
      modalForm.setValue('phone', '');
      modalForm.setValue('observations', '');
    }
  }, [rescheduleResolution])

  const handleCloseReschedule = () => {
    setShowModalReschedule(false);
    reduxResetDepartment();
    reduxResetCity();
    reduxResetNeighborhood();
    reduxResetAddressReschedulingForm();
    modalForm.reset();
  };

  const openReschedule = (row) => {
    if (row.attempts === 2) {
      Swal.fire({
        title: 'Señor usuario',
        text: 'Tener presente que se realizará el último intento de reprogramar esta orden',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#21b5ea',
        cancelButtonColor: '#858796',
        confirmButtonText: 'Continuar',
        cancelButtonText: 'Cerrar',
      }).then((result) => {
        if (result.isConfirmed) {
          reduxGetAddress({
            id: row.idAddress,
          });
          setShowModalReschedule(true);
          setValueManualAddress('');
        }
      });
    } else {
      reduxGetAddress({
        id: row.idAddress,
      });
      setShowModalReschedule(true);
      setValueManualAddress('');
    }
  };

  const onSubmitReschedule = (data) => {
    const obj = {
      id: rowEdited.idAddress,
      address: !isNullOrUndefined(data.manualAddress?.trim()) ? data.manualAddress : rowEdited.address,
      countryNameReceiver: data.country ? data.country.label : rowEdited.countryNameReceiver,
      stateNameReceiver: data.department ? data.department.label : rowEdited.stateNameReceiver,
      cityNameReceiver: data.city2 ? data.city2.label : rowEdited.cityNameReceiver,
      neighborhoodNameReceiver: data.neighborhood ? data.neighborhood.label : rowEdited.neighborhoodNameReceiver,
      idCity: data.city2 ? Number(data.city2.value) : rowEdited.idCity,
      idNeighborhood: data.neighborhood ? Number(data.neighborhood.value) : rowEdited.idNeighborhood,
      date: formatDateWithCustomFormat(DateTime.now().setZone(new IANAZone('America/Bogota')), { format: 'YYYY-MM-DD' }) + ' 00:00:01',
      dateReschedule: data.dateReschedule ? formatDateWithoutTimeZone(data.dateReschedule) : null,
      observations: data.observations ?? null,
      reference1: data.phone ? String(data.phone) : rowEdited.reference1,
      collection: data.collection ? data.collection : rowEdited.collection,
      rescheduleResolution: data.rescheduleResolution && data.rescheduleResolution.label,
    };

    reduxPatchReschedule(obj);
    
  };

  const changeEndDate = (dates) => {
    const [start, end] = dates;
    setValueSearch("from", DateTime.fromJSDate(start).setZone(new IANAZone("America/Bogota")).toFormat("yyyy-LL-dd"))
    setValueSearch("to", DateTime.fromJSDate(end).setZone(new IANAZone("America/Bogota")).toFormat("yyyy-LL-dd"))

    setDateRange(dates)
    rangeRef.current.handleFocus()
  };

  const onSubmitSearch = () => {
    if (endDate < startDate) {
      Swal.fire('Información', 'La fecha inicial debe ser  menor a la fecha final , intenta de nuevo', 'info');
      return;
    }
    let filters = {
      page: 1,
      search: search ? search : '',
      offset: offset,
    };
    if (getValuesSearch('idClient')) {
      filters = {
        ...filters,
        idClient: getValuesSearch('idClient'),
      };
    }
    if (getValuesSearch('idOperator')) {
      filters = {
        ...filters,
        idOperator: getValuesSearch('idOperator'),
      };
    }
    if (getValuesSearch('from') && getValuesSearch('to')) {
      filters = {
        ...filters,
        from: formatDateWithoutTimeZone(startDate),
        to: formatDateWithoutTimeZone(endDate),
      };
    }
    if (getValuesSearch('trafficLight')) {
      filters = {
        ...filters,
        trafficLight: getValuesSearch('trafficLight').value,
      };
    }
    if (getValuesSearch('novelty')) {
      filters = {
        ...filters,
        novelty: getValuesSearch('novelty').value,
      };
    }

    reduxGetAddresses(filters);
    setFilters(filters)
  };

  const handleChangeMultiSelect = (type, value) => {
    const multi = {
      ...getValuesSearch(type),
      value
    }

    setValueSearch(type, String(multi.value.map(item => item.value)));
  }

  const handleDownloadData = () => {
    setIsDownloading(true);
    if (endDate < startDate) {
      Swal.fire('Información', 'La fecha inicial debe ser  menor a la fecha final , intenta de nuevo', 'info');
      setIsDownloading(false);
      return;
    }
    const fileName = moment(new Date()).tz("America/Bogota").format("YYYY-MM-DD_HHmmss") + "-reschedulings-report.csv";

    let _data = {
      search: search ? search : '',
      fileName,
    };
    if (getValuesSearch('idClient')) {
      _data = {
        ..._data,
        shippers: getValuesSearch('idClient'),
      };
    }
    if (getValuesSearch('idOperator')) {
      _data = {
        ..._data,
        operators: getValuesSearch('idOperator'),
      };
    }
    if (getValuesSearch('trafficLight')) {
      _data = {
        ..._data,
        trafficLight: getValuesSearch('trafficLight').value,
      };
    }
    if (getValuesSearch('novelty')) {
      _data = {
        ..._data,
        novelty: getValuesSearch('novelty').value,
      };
    }
    if (getValuesSearch('from') && getValuesSearch('to')) {
      _data = {
        ..._data,
        from: formatDateWithoutTimeZone(startDate),
        to: formatDateWithoutTimeZone(endDate),
      };
    }

    const token = window.localStorage.getItem("token");

    fetch(`${process.env.REACT_APP_REPORT_URL}/api/v1/reports/reschedulings`, {
      method: "POST",
      body: JSON.stringify(_data),
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      }),
    })
      .then((response) => response.blob())
      .then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = fileName
        document.body.appendChild(a);
        a.click();
        a.remove();
      }).finally(() => setIsDownloading(false));;
  }

  const renderDownloadButton = () => {
    if (!addresses || Object.keys(addresses).length === 0)
      return;

    if (isDownloading) {
      return (
        <Spinner animation="border" variant="primary" />
      );
    }

    return (
      <Button
        className='mr-2'
        variant='primary'
        type='button'
        onClick={handleDownloadData}
        id='btnDownload'>
        Descargar
      </Button>
    );
  }

  const renderDownloadDriveButton = () => {
    if (!addresses || Object.keys(addresses).length === 0)
      return;

    return (
      <Button
        className='mr-2'
        variant='primary'
        type='button'
        onClick={handleUploadDataToDrive}
        id='btnDownload'>
        Descargar a Google Drive
      </Button>
    );
  }

  const handleUploadDataToDrive = () => {
    reduxUploadReschedulesToDrive(filters);
  }

  const columns = [
    {
      title: '#',
      render: (rowData) => {
        return <span>{rowData.idAddress}</span>;
      },
      field: 'idAddress',
      searchable: true,
      visible: true,
    },
    {
      title: 'Guía',
      render: (rowData) => {
        return <span>{rowData.trackingId}</span>;
      },
      field: 'trackingId',
      searchable: true,
      visible: hasPermission('feature:rescheduling-show-column-trackingid'),
    },
    {
      title: 'IdExterno',
      render: (rowData) => {
        return <span>{rowData.externalId}</span>;
      },
      field: 'externalId',
      searchable: true,
      visible: hasPermission('feature:rescheduling-show-column-externalid')
    },
    {
      title: 'Nombre Destinatario',
      render: (rowData) => {
        return <span>{rowData.receiverName}</span>;
      },
      visible: hasPermission('feature:rescheduling-show-column-receivername')
    },
    {
      title: 'Dirección Destinatario',
      render: (rowData) => {
        return <span>{rowData.receiverAddress}</span>;
      },
      field: 'address',
      searchable: true,
      visible: hasPermission('feature:show-recipient-address')
    },
    {
      title: 'Teléfono Destinatario',
      render: (rowData) => {
        return (
          <span>{rowData.receiverPhone}</span>
        );
      },
      field: 'reference1',
      searchable: true,
      visible: hasPermission('feature:show-reference1-address')
    },
    {
      title: 'Estado',
      render: (rowData) => getStatusBadgeByCode(rowData.state),
      visible: hasPermission('feature:rescheduling-show-column-state')
    },
    {
      title: 'Ciudad',
      render: (rowData) => {
        return <span>{rowData.city}</span>;
      },
      field: 'city',
      searchable: true,
      visible: hasPermission('feature:rescheduling-show-column-city')
    },
    {
      title: 'Novedad',
      render: (rowData) => {
        return <span>{rowData.novelty}</span>;
      },
      field: 'novelty',
      searchable: true,
      visible: hasPermission('feature:rescheduling-show-column-novelty')
    },
    {
      title: 'Comentario Novedad/Nota',
      render: (rowData) => {
        return <span>{rowData.comment}</span>;
      },
      visible: hasPermission('feature:rescheduling-show-column-noveltynote')
    },
    {
      title: 'Valor de recaudo',
      render: (rowData) => {
        return <span>{rowData.collection}</span>;
      },
      field: 'collection',
      searchable: true,
      visible: hasPermission('feature:rescheduling-show-column-collection')
    },
    {
      title: 'Producto',
      render: (rowData) => {
        return rowData.product?.length > 30
          ? <CustomTooltip data={rowData.product.split(',')} />
          : <span>{rowData.product}</span>;
      },
      visible: hasPermission('feature:rescheduling-show-column-product')
    },
    {
      title: 'Intentos',
      render: (rowData) => (
        <span className='badge badge-pill badge-light d-flex justify-content-center align-items-center'>
          {rowData.attempts}
        </span>
      ),
      visible: hasPermission('feature:rescheduling-show-column-attempts')
    },
    {
      title: 'Fecha Estado',
      render: (rowData) => {
        return (
          <span>
            {rowData.lastUpdateDate && String(rowData.lastUpdateDate).includes('/')
              ? DateTime.fromFormat(String(rowData.lastUpdateDate), "YYYY/MM/dd").toFormat('dd-MM-YYYY')
              : rowData.lastUpdateDate
                ? String(rowData.lastUpdateDate)
                : ''}
          </span>
        );
      },
      visible: hasPermission('feature:rescheduling-show-column-lastupdatedate')
    },
    {
      title: 'Fecha Creado',
      render: (rowData) => {
        return (
          <span>
            {rowData.createdDate && String(rowData.createdDate).includes('/')
              ? DateTime.fromFormat(String(rowData.createdDate), "YYYY/MM/dd").toFormat('dd-MM-YYYY')
              : rowData.createdDate
                ? String(rowData.createdDate)
                : ''}
          </span>
        );
      },
      visible: hasPermission('feature:rescheduling-show-column-createddate')
    },
    {
      title: 'Fecha Tentativa de Entrega',
      render: (rowData) => {
        return (
          <span>
            {rowData.deliveryDate && String(rowData.deliveryDate).includes('/')
              ? DateTime.fromFormat(String(rowData.deliveryDate), "YYYY/MM/dd").toFormat('dd-MM-YYYY')
              : rowData.deliveryDate
                ? String(rowData.deliveryDate)
                : ''}
          </span>
        );
      },
      visible: hasPermission('feature:rescheduling-show-column-rescheduleddate')
    },
    {
      title: 'Remitente',
      render: (rowData) => {
        return <span>{rowData.shipper}</span>;
      },
      visible: hasPermission('feature:show-client-address'),
    },
    {
      title: 'Operador',
      render: (rowData) => {
        return <span>{rowData.operator}</span>;
      },
      visible: hasPermission('feature:show-operator-address')
    },
    {
      title: 'Acciones',
      render: (rowData) => {
        return (
          <>
            <Show when='feature:rescheduling'>
              <button
                title='Reprogramar'
                className='btn btn-warning btn-sm btn-circle mr-2'
                type='button'
                onClick={(e) => openReschedule(rowData)}>
                <i className='fas fa-calendar	fa-xs'></i>
              </button>
            </Show>
            <TrafficLight days={getDaysDiff(new Date(), rowData.lastUpdateDate)} />
          </>
        );
      },
    },
  ];

  return (
    <div>
      <div className='card shadow mb-4'>
        <div className='card-header py-3'>
          <h6 className='m-0 font-weight-bold text-primary'>Listado de Reprogramaciones</h6>
        </div>
        <div className='card-body'>
          <form onSubmit={handleSubmitSearch(onSubmitSearch)}>
            <div className='row mb-1'>
              <div className='form-group col-md-3'>
                <label
                  className='form-label'>
                  Desde - Hasta
                </label>
                <DatePicker
                  startDate={startDate}
                  endDate={endDate}
                  selectsRange
                  ref={rangeRef}
                  selectsDisabledDaysInRange
                  onChange={changeEndDate}
                  dateFormat="yyyy-MM-dd"
                  className={'form-control'}
                  maxDate={new Date()}
                  shouldCloseOnSelect={false}
                  required
                  isClearable
                />
              </div>
              <Show when='feature:show-client-address'>
                <div className='form-group col-md-3'>
                  <label
                    htmlFor='idClient'
                    className='form-label'>
                    Remitente
                  </label>
                  <Select
                    styles={customStyles}
                    isMulti
                    isClearable={true}
                    isDisabled={!startDate || !endDate}
                    {...registerSearch('idClient')}
                    id='idClient'
                    onChange={(value) => handleChangeMultiSelect('idClient', value)}
                    options={
                      clients && Object.keys(clients).length > 0
                        ? clients.items
                          .filter((f) => f.isActive)
                          .map((ele, key) => ({
                            value: ele.idCompany,
                            label: ele.description,
                          }))
                        : []
                    }
                  />
                </div>
              </Show>
              <Show when='feature:show-operator-address'>
                <div className='form-group col-md-3'>
                  <label
                    htmlFor='idOperator'
                    className='form-label'>
                    Operador
                  </label>
                  <Select
                    styles={customStyles}
                    isClearable={true}
                    isDisabled={!startDate || !endDate}
                    {...registerSearch('idOperator')}
                    isMulti
                    id='idOperator'
                    onChange={(value) => handleChangeMultiSelect('idOperator', value)}
                    options={
                      logisticsOperators &&
                      Object.keys(logisticsOperators).length > 0 &&
                      logisticsOperators.items
                        .filter((f) => f.isActive === true)
                        .map((ele, key) => ({
                          value: ele.idCompany,
                          label: ele.description,
                        }))
                    }
                  />
                </div>
              </Show>
              <div className='form-group col-md-3'>
                <label
                  htmlFor='trafficLight'
                  className='form-label'>
                  Semáforo
                </label>
                <Select
                  id='trafficLight'
                  styles={customStyles}
                  isClearable={true}
                  isDisabled={!startDate || !endDate}
                  {...registerSearch('trafficLight')}
                  onChange={value => setValueSearch('trafficLight', value)}
                  options={trafficLightColors.filter(e => e.show)}
                />
              </div>
              <div className='form-group col-md-3'>
                <label
                  htmlFor='novelty'
                  className='form-label'>
                  Novedad
                </label>
                <Select
                  styles={customStyles}
                  isClearable={true}
                  isDisabled={!startDate || !endDate}
                  {...registerSearch('novelty')}
                  id='novelty'
                  onChange={(value) => setValueSearch('novelty', value)}
                  options={
                    novelties &&
                    Object.keys(novelties).length > 0 &&
                    novelties
                      .filter((f) => f.isActive === true)
                      .map((ele, key) => ({
                        value: ele.code,
                        label: ele.description,
                      }))
                  }
                />
              </div>
              <div className='form-group d-flex flex-row align-items-end ml-3'>
                <Button
                  className='mr-2'
                  variant='primary'
                  type='submit'
                  id='btnSearch'>
                  Buscar
                </Button>
                {
                  renderDownloadButton()
                }
                {
                  hasPermission('feature:download-reschedules-drive') && (renderDownloadDriveButton())
                }
              </div>
            </div>
          </form>
          <Grid
            className='stickyAddress'
            cols={columns}
            data={addresses && Object.keys(addresses).length > 0 ? addresses.items : []}
            total={addresses && addresses.hasOwnProperty('total') ? addresses.total : 0}
            page={addresses && Object.keys(addresses).length > 0 ? Number(addresses.page) : currentPage}
            pages={addresses && Object.keys(addresses).length > 0 ? Number(addresses.totalPages) : 0}
            offset={offset}
            onChangePage={(page) => setCurrentPage(page)}
            onChangeRange={(value) => {
              setOffset(value);
              let filters = {
                page: 1,
                offset: value,
                search: search,
              };
              if (getValuesSearch('trafficLight')) {
                filters = {
                  ...filters,
                  trafficLight: getValuesSearch('trafficLight').value,
                };
              }
              if (getValuesSearch('idClient')) {
                filters = {
                  ...filters,
                  idClient: getValuesSearch('idClient'),
                };
              }
              if (getValuesSearch('idOperator')) {
                filters = {
                  ...filters,
                  idOperator: getValuesSearch('idOperator'),
                };
              }
              if (getValuesSearch('novelty')) {
                filters = {
                  ...filters,
                  novelty: getValuesSearch('novelty').value,
                };
              }
              if (getValuesSearch('from') && getValuesSearch('to')) {
                if (endDate < startDate) {
                  Swal.fire(
                    'Información',
                    'La fecha inicial debe ser  menor a la fecha final , intenta de nuevo',
                    'info'
                  );
                  return;
                }
                filters = {
                  ...filters,
                  from: formatDateWithoutTimeZone(startDate),
                  to: formatDateWithoutTimeZone(endDate),
                };
              } else {
                return;
              }
              reduxGetAddresses(filters);
            }}
            defaultValue={search}
            onChangeSearch={(value) => {
              setSearch(value);
              let filters = {
                page: 1,
                offset: offset,
                search: value,
              };
              if (getValuesSearch('trafficLight')) {
                filters = {
                  ...filters,
                  trafficLight: getValuesSearch('trafficLight').value,
                };
              }
              if (getValuesSearch('novelty')) {
                filters = {
                  ...filters,
                  novelty: getValuesSearch('novelty').value,
                };
              }
              if (getValuesSearch('idClient')) {
                filters = {
                  ...filters,
                  idClient: getValuesSearch('idClient'),
                };
              }
              if (getValuesSearch('idOperator')) {
                filters = {
                  ...filters,
                  idOperator: getValuesSearch('idOperator'),
                };
              }
              if (getValuesSearch('from') && getValuesSearch('to')) {
                if (endDate < startDate) {
                  Swal.fire(
                    'Información',
                    'La fecha inicial debe ser  menor a la fecha final , intenta de nuevo',
                    'info'
                  );
                  return;
                }
                filters = {
                  ...filters,
                  from: formatDateWithoutTimeZone(startDate),
                  to: formatDateWithoutTimeZone(endDate),
                };
              } else {
                return;
              }
              reduxGetAddresses(filters);
            }}
          />
        </div>
      </div>
      <div>
        <Modal
          show={showModalReschedule}
          onHide={handleCloseReschedule}
          onEscapeKeyDown={handleCloseReschedule}
          backdrop='static'
          keyboard={true}
          size='lg'>
          <FormProvider {...modalForm}>
            <form onSubmit={modalForm.handleSubmit(onSubmitReschedule)}>
              <Modal.Header closeButton>
                <Modal.Title>Actualización y reprogramación</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className='row'>
                  <div className='form-group col-md-6'>
                    <label
                      htmlFor='rescheduleResolution'
                      className='form-label'>
                      Solución*
                    </label>
                    <Controller
                      control={modalForm.control}
                      name='rescheduleResolution'
                      rules={{ required: 'El campo solución es obligatorio' }}
                      render={({ field }) => (
                        <>
                          <Select
                            {...field}
                            styles={customStyles}
                            options={[
                              { value: 2, label: 'Generar Reprogramación' },
                              { value: 3, label: 'Devolución' },
                              { value: 4, label: 'Ya entregado' },
                            ]}
                          />
                          {modalForm.formState.errors.rescheduleResolution &&
                            <span className='error-message'>{modalForm.formState.errors.rescheduleResolution.message}</span>
                          }
                        </>
                      )}
                    />
                  </div>
                  <div className='form-group col-md-6'>
                    <label
                      htmlFor='dateReschedule'
                      className='form-label'>
                      Fecha de programación
                    </label>
                    <Controller
                      control={modalForm.control}
                      name='dateReschedule'
                      rules={{
                        required: false
                      }}
                      render={({ field: { onChange, value } }) => (
                        <>
                          <DatePicker
                            disabled={rescheduleResolution.value !== 2}
                            selected={value}
                            onChange={onChange}
                            dateFormat={'yyyy/MM/dd'}
                            className={`form-control`}
                            filterDate={(date) => isBusinessDay(date, countryISOCode)}
                            maxDate={getBusinessDate(new Date(), 5, countryISOCode)}
                            minDate={getBusinessDate(new Date(), 2, countryISOCode)}
                          />
                          {modalForm.formState.errors.dateReschedule && (
                            <span className='error-message'>{modalForm.formState.errors.dateReschedule.message}</span>
                          )}
                        </>
                      )}
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='form-group col-md-12'>
                    <GoogleMapsAutocomplete
                      id={'manualAddress'}
                      disabled={rescheduleResolution.value !== 2}
                      required={(valueManualAddress && valueManualAddress !== '' && rescheduleResolution.value === 2)}
                      value={valueManualAddress}
                      onChange={(data) => {
                        setValueManualAddress(
                          data.address_components
                            .map((ad) => {
                              return ad.long_name;
                            })
                            .join(', ')
                        );
                        data.address_components ? modalForm.setValue('manualAddress', data.address_components) : modalForm.setValue('manualAddress', data);
                      }}
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='form-group col-md-6'>
                    <label
                      htmlFor='country'
                      className='form-label'>
                      Pais
                    </label>
                    <Controller
                      control={modalForm.control}
                      name='country'
                      render={({ field }) => (
                        <Select
                          {...field}
                          isClearable
                          isDisabled={true}
                          styles={customStyles}
                          options={countries &&
                            Object.keys(countries).length > 0 ?
                            countries.items.map((ele, key) => ({
                              value: ele.idCountry,
                              label: ele.description,
                            }
                            ))
                            : []
                          }
                        />
                      )}
                    />
                  </div>
                  <div className='form-group col-md-6'>
                    <label
                      htmlFor='department'
                      className='form-label'>
                      Departamento/Estado{!isNullOrUndefined(country) && rescheduleResolution.value === 2 && '*'}
                    </label>
                    <Controller
                      control={modalForm.control}
                      name='department'
                      rules={{
                        required: {
                          value: !isNullOrUndefined(country) && rescheduleResolution.value === 2,
                          message: 'El campo departamento es obligatorio'
                        }
                      }}
                      render={({ field }) => (
                        <>
                          <Select
                            {...field}
                            isClearable
                            isDisabled={true}
                            styles={customStyles}
                            options={departmentsByCountry &&
                              Object.keys(departmentsByCountry).length > 0 ?
                              departmentsByCountry.map((ele, key) => ({
                                value: ele.idDepartment,
                                label: ele.description,
                              }))
                              : []
                            }
                          />
                          {modalForm.formState.errors.department &&
                            <span className='error-message'>{modalForm.formState.errors.department.message}</span>
                          }
                        </>
                      )}
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='form-group col-md-6'>
                    <label
                      htmlFor='city2'
                      className='form-label'>
                      Ciudad/Municipio/Provincia{!isNullOrUndefined(country) && rescheduleResolution.value === 2 && '*'}
                    </label>
                    <Controller
                      control={modalForm.control}
                      name='city2'
                      rules={{
                        required: {
                          value: (!isNullOrUndefined(country)) && rescheduleResolution.value === 2,
                          message: 'El campo ciudad es obligatorio'
                        }
                      }}
                      render={({ field }) => (
                        <>
                          <Select
                            {...field}
                            isClearable
                            isDisabled={true}
                            styles={customStyles}
                            options={citiesByDepartment &&
                              Object.keys(citiesByDepartment).length > 0 ?
                              citiesByDepartment.map((ele, key) => ({
                                value: ele.idCity,
                                label: ele.description,
                              }))
                              : []
                            }
                          />
                          {modalForm.formState.errors.city2 &&
                            <span className='error-message'>{modalForm.formState.errors.city2.message}</span>
                          }
                        </>
                      )}
                    />
                  </div>
                  <div className='form-group col-md-6'>
                    <label
                      htmlFor='neighborhood'
                      className='form-label'>
                      Barrio/Colonia/Distrito
                    </label>
                    <Controller
                      control={modalForm.control}
                      name='neighborhood'
                      render={({ field }) => (
                        <>
                          <Select
                            {...field}
                            isClearable
                            isDisabled={rescheduleResolution.value !== 2}
                            styles={customStyles}
                            getOptionValue={(option) => option["label"]}
                            options={neighborhoodsByCity &&
                              Object.keys(neighborhoodsByCity).length > 0 ?
                              neighborhoodsByCity.map((ele, key) => ({
                                value: ele.idNeighborhood,
                                label: `${ele.postalCode ? ele.postalCode + ' - ' : ''}${ele.description}`,
                              }))
                              : []
                            }
                          />
                        </>
                      )}
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='form-group col-md-6'>
                    <label
                      htmlFor='phone'
                      className='form-label'>
                      Teléfono
                    </label>
                    <Controller
                      control={modalForm.control}
                      name='phone'
                      render={({ field }) => (
                        <>
                          <input
                            name={field.name}
                            value={field.value}
                            onChange={field.onChange}
                            disabled={rescheduleResolution.value !== 2}
                            type='number'
                            className={`form-control`}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                          />
                        </>
                      )}
                    />
                  </div>
                  <div className='form-group col-md-6'>
                    <label
                      htmlFor='collection'
                      className='form-label'>
                      Valor a recaudar
                    </label>
                    <Controller
                      control={modalForm.control}
                      name='collection'
                      render={({ field }) => (
                        <>
                          <input
                            name={field.name}
                            value={field.value}
                            onChange={field.onChange}
                            type='number'
                            className={`form-control form-control-user`}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            disabled={
                              (
                                rowEdited && rowEdited.operator !== null &&
                                (
                                  rowEdited.operator.systemKey === 'TEMPO' ||
                                  rowEdited.operator.systemKey === 'KIKI'
                                )
                              )
                                && (rescheduleResolution && rescheduleResolution.value === 2)
                                ? false
                                : true
                            }
                          />
                        </>
                      )}
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='form-group col-md-12'>
                    <label
                      htmlFor='observations'
                      className='form-label'>
                      Observaciones*
                    </label>
                    <Controller
                      control={modalForm.control}
                      name='observations'
                      rules={{
                        required: 'El campo observaciones es obligatorio'
                      }}
                      render={({ field }) => (
                        <>
                          <input
                            {...field}
                            type='text'
                            className={`form-control form-control-user`}
                          />
                          {modalForm.formState.errors.observations && (
                            <span className='error-message'>{modalForm.formState.errors.observations.message}</span>
                          )}
                        </>
                      )}
                    />
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant='secondary'
                  onClick={handleCloseReschedule}>
                  Cancelar
                </Button>
                <Button
                  variant='primary'
                  type='submit'>
                  Guardar
                </Button>
              </Modal.Footer>
            </form>
          </FormProvider>
        </Modal>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    addresses: state.addressState.reschedulings,
    clients: state.companyState.clients,
    logisticsOperators: state.companyState.logisticsOperators,
    rowEdited: state.addressState.rowEdited,
    reschedule: state.addressState.reschedule,
    cities: state.cityState.cities,
    neighborhoodsByCity: state.neighborhoodState.neighborhoodsByCity,
    countries: state.countryState.countries,
    departmentsByCountry: state.departmentState.departmentsByCountry,
    citiesByDepartment: state.cityState.citiesByDepartment,
    novelties: state.noveltyState.novelties,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    reduxGetClients: (payload) =>
      dispatch({
        type: 'FETCH_COMPANIESBYSHIPPER_REQUEST',
        value: payload,
      }),
    reduxGetLogisticsOperators: (payload) =>
      dispatch({
        type: 'FETCH_COMPANIESBYLOGISTICSOPERATOR_REQUEST',
        value: payload,
      }),
    reduxGetAddresses: (payload) =>
      dispatch({
        type: 'FETCH_ADDRESSES_RESCHEDULING_REQUEST',
        value: payload,
      }),
    reduxUploadReschedulesToDrive: (payload) =>
      dispatch({
        type: 'FETCH_RESCHEDULING_UPLOAD_DRIVE_REQUEST',
        value: payload,
      }),
    reduxResetAddressForm: () =>
      dispatch({
        type: 'RESET_ADDRESS_FORM',
      }),
    reduxPatchReschedule: (payload) =>
      dispatch({
        type: 'FETCH_ADDRESS_RESCHEDULE_REQUEST',
        value: payload,
      }),
    reduxGetAddress: (payload) =>
      dispatch({
        type: 'READ_ADDRESS_REQUEST',
        value: payload,
      }),
    reduxGetNovelties: (payload) =>
      dispatch({
        type: 'FETCH_NOVELTIES_FOR_RESCHEDULE_REQUEST',
        value: payload,
      }),
    reduxGetCities: (payload) =>
      dispatch({
        type: 'FETCH_CITIES_REQUEST',
        value: payload,
      }),
    reduxGetCountries: (payload) =>
      dispatch({
        type: 'FETCH_COUNTRIES_REQUEST',
        value: payload,
      }),
    reduxGetDepartmentsByCountry: (payload) =>
      dispatch({
        type: 'READBYCOUNTRY_DEPARTMENT_REQUEST',
        value: payload,
      }),
    reduxGetCitiesByDepartment: (payload) =>
      dispatch({
        type: 'READBYDEPARTMENT_CITY_REQUEST',
        value: payload,
      }),
    reduxGetNeighborhoodsByCity: (payload) =>
      dispatch({
        type: 'READBYCITY_NEIGHBORHOOD_REQUEST',
        value: payload,
      }),
    reduxResetDepartment: (payload) =>
      dispatch({
        type: 'RESET_BYCOUNTRY_DEPARTMENT',
        value: payload,
      }),
    reduxResetCity: (payload) =>
      dispatch({
        type: 'RESET_BYDEPARTMENT_CITY',
        value: payload,
      }),
    reduxResetNeighborhood: (payload) =>
      dispatch({
        type: 'RESET_BYCITY_NEIGHBORHOOD',
        value: payload,
      }),
    reduxResetAddressReschedulingForm: (payload) =>
      dispatch({
        type: 'RESET_ADDRESS_RESCHEDULING_FORM',
        value: payload,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReschedulingTableComponent);
