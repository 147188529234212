import { forwardRef } from "react";
import DatePicker from 'react-datepicker';

export const DatePickerCustom = forwardRef(({ value, onClick, onChange, ...props }, ref) => {
    return (
        <DatePicker
            {...props}
            onChange={onChange}
            customInput={
                <input
                    className='form-control'
                    onClick={onClick}
                    onChange={onChange}
                    ref={ref}
                    value={value}
                    autoComplete='Off'
                />
            }
        />
    );
});