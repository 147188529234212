import {call, put, takeLatest} from "redux-saga/effects";
import request from "../../utils/request";

function* getAllLotProducts(payload) {
  try {
    yield put({
      type: "FETCH_LOTPRODUCTS_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/lotProducts?page=${payload.value.page}&search=${payload.value.search}&offset=${payload.value.offset}${payload.value.isActive ? '&isActive=' + payload.value.isActive: '' }`;
    const headers = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "FETCH_LOTPRODUCT_SUCCESS",
      value: response,
    });
    yield put({
      type: "HIDE_LOADING",
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed load",
        message: "Failed load Lot Products",
      },
    });
    yield put({
      type: "FETCH_LOTPRODUCT_ERROR",
    });
  }
}

function* postLotProduct(payload) {
  try {
    yield put({ type: "CREATE_LOTPRODUCT_REQUESTING" });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/lotProducts`;

    const headers = {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(payload.value),
    };

    const response = yield call(request, requestURL, headers);

    yield put({
      type: "CREATE_LOTPRODUCT_SUCCESS",
      value: response,
    });
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "success",
        title: "Successful save",
        message: "Successful save Lot product",
      },
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed save",
        message: "Failed save Lot product",
      },
    });
    yield put({
      type: "CREATE_LOTPRODUCT_ERROR",
    });
  }
}

function* getLotProductById(payload) {
  try {
    yield put({
      type: "READ_LOTPRODUCT_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/lotProducts/${payload.value.id}`;

    const headers = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "READ_LOTPRODUCT_SUCCESS",
      value: response,
    });
    yield put({
      type: "HIDE_LOADING",
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "READ_LOTPRODUCT_ERROR",
    });
  }
}

function* deleteLotProductById(payload) {
  try {
    yield put({
      type: "DELETE_LOTPRODUCT_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/lotProducts/changeState/${payload.value.idLotProduct}`;

    const headers = {
      method: "PATCH",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(payload.value),
    };

    const response = yield call(request, requestURL, headers);

    yield put({
      type: "DELETE_LOTPRODUCT_SUCCESS",
      value: response,
    });
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "success",
        title: "Successful disable",
        message: "Successful disable Lot product",
      },
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed disable",
        message: "Failed disable Lot product",
      },
    });
    yield put({
      type: "DELETE_LOTPRODUCT_ERROR",
    });
  }
}

function* updateLotProduct(payload) {
  try {
    yield put({
      type: "UPDATE_LOTPRODUCT_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/lotProducts/${payload.value.id}`;

    const headers = {
      method: "PATCH",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(payload.value),
    };

    const response = yield call(request, requestURL, headers);

    yield put({
      type: "UPDATE_LOTPRODUCT_SUCCESS",
      value: response,
    });
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "success",
        title: "Successful update",
        message: "Successful update Lot product",
      },
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed update",
        message: "Failed update Lot product",
      },
    });
    yield put({
      type: "UPDATE_LOTPRODUCT_ERROR",
    });
  }
}

function* deleteSoftLotProductById(payload) {
  try {
    yield put({
      type: "DELETE_SOFT_LOTPRODUCT_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/lotProducts/deleteSoft/${payload.value.idLotProduct}`;

    const headers = {
      method: "PATCH",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(payload.value),
    };

    const response = yield call(request, requestURL, headers);

    yield put({
      type: "DELETE_SOFT_LOTPRODUCT_SUCCESS",
      value: response,
    });
    yield put({
      type: "HIDE_LOADING",
    });

    if (response.message) {
      yield put({
        type: "SHOW_ALERT",
        value: {
          type: "danger",
          title: "Failed delete soft",
          message: response.message,
        },
      });
    } else {
      yield put({
        type: "SHOW_ALERT",
        value: {
          type: "success",
          title: "Successful delete soft",
          message: "Successful delete soft Lot product",
        },
      });
    }
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed delete soft",
        message: "Failed delete soft Lot product",
      },
    });
    yield put({
      type: "DELETE_SOFT_LOTPRODUCT_ERROR",
    });
  }
}

export function* watchLotProduct() {
  yield takeLatest("FETCH_LOTPRODUCTS_REQUEST", getAllLotProducts);
  yield takeLatest("CREATE_LOTPRODUCT_REQUEST", postLotProduct);
  yield takeLatest("READ_LOTPRODUCT_REQUEST", getLotProductById);
  yield takeLatest("DELETE_LOTPRODUCT_REQUEST", deleteLotProductById);
  yield takeLatest("UPDATE_LOTPRODUCT_REQUEST", updateLotProduct);
  yield takeLatest("DELETE_SOFT_LOTPRODUCT_REQUEST", deleteSoftLotProductById);
}
