const RadioButton = ({ options = [], fieldController: { onChange, value } }) => {
    const renderOptions = () => {
        return options.map((ele, index) => (
            <div className="mx-2 d-flex text-center align-items-center" key={index}>
                <input
                    className="mx-1"
                    id={`rb-${index}`}
                    type='radio'
                    label={ele.name}
                    value={ele.value}
                    disabled={ele.disabled}
                    checked={value === ele.value}
                    onChange={() => onChange(ele.value)}
                />
                <label htmlFor={`rb-${index}`} className="m-auto">{ele.name}</label>
            </div>
        ));
    };

    return (
        <div className="row m-auto flex-nowrap">
            {renderOptions()}
        </div>
    );
}

export default RadioButton;