import {call, put, takeLatest} from "redux-saga/effects";
import request, {requestAxios, requestAxiosDownload} from "../../utils/request";
function* getAllNovelties(payload) {
  try {
    yield put({
      type: "FETCH_NOVELTIES_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/novelties?page=${
      payload.value.page
    }&search=${payload.value.search}&offset=${payload.value.offset}${
      payload.value.isActive ? "&isActive=" + payload.value.isActive : "" + 
      payload.value.status ? "&status=" + payload.value.status :''
    }`;
    const headers = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
    };
    const response = yield call(request, requestURL, headers);
    
    yield put({
      type: "FETCH_NOVELTIES_SUCCESS",
      value: response,
    });
    yield put({
      type: "HIDE_LOADING",
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed load",
        message: "Failed load novelties",
      },
    });
    yield put({
      type: "FETCH_NOVELTIES_ERROR",
    });
  }
}

function* getNovelty(payload) {
  try {
    yield put({
      type: "READ_NOVELTY_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/novelties/${payload.value.idNovelty}`;
    const headers = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "READ_NOVELTY_SUCCESS",
      value: response,
    });
    yield put({
      type: "HIDE_LOADING",
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed load",
        message: "Failed load novelties",
      },
    });
    yield put({
      type: "READ_NOVELTY_ERROR",
    });
  }
}

function* getNoveltiesByStatus(payload) {
  try {
    yield put({
      type: "FETCH_NOVELTIES_BY_STATUS_CODE_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/novelties/byStatusCode/${payload.value.status}`;
    const headers = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "FETCH_NOVELTIES_BY_STATUS_CODE_SUCCESS",
      value: response,
    });
    yield put({
      type: "HIDE_LOADING",
    });
  }
  catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed load",
        message: "Failed load novelties",
      },
    });
    yield put({
      type: "FETCH_NOVELTIES_BY_STATUS_CODE_ERROR",
    });
  }
}

function* findAllForReschedule(payload) {
  try {
    yield put({
      type: "FETCH_NOVELTIES_FOR_RESCHEDULE_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/novelties/forReschedule`;
    const headers = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "FETCH_NOVELTIES_FOR_RESCHEDULE_SUCCESS",
      value: response,
    });
    yield put({
      type: "HIDE_LOADING",
    });
  }
  catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed load",
        message: "Failed load novelties",
      },
    });
    yield put({
      type: "FETCH_NOVELTIES_FOR_RESCHEDULE_ERROR",
    });
  }
}

function* findAllForIncidents() {
  try {
    yield put({
      type: "FETCH_NOVELTIES_FOR_INCIDENTS_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/novelties/forIncidents`;
    const headers = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "FETCH_NOVELTIES_FOR_INCIDENTS_SUCCESS",
      value: response,
    });
    yield put({
      type: "HIDE_LOADING",
    });
  }
  catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed load",
        message: "Failed load novelties",
      },
    });
    yield put({
      type: "FETCH_NOVELTIES_FOR_INCIDENTS_ERROR",
    });
  }
}

function* postNovelty(payload) {
  try {
    yield put({ type: "CREATE_NOVELTY_REQUESTING" });
    yield put({
      type: "SHOW_LOADING",
    });
    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/novelty`;

    const headers = {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(payload.value),
    };

    const response = yield call(request, requestURL, headers);

    yield put({
      type: "HIDE_LOADING",
    });

    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "success",
        title: "Successful save",
        message: "Successful save novelty",
      },
    });

    yield put({
      type: "CREATE_NOVELTY_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed save",
        message: "Failed save novelty",
      },
    });
    yield put({
      type: "CREATE_NOVELTY_ERROR",
    });
  }
}

function* patchNovelty(payload) {
  try {
    yield put({ type: "UPDATE_NOVELTY_REQUESTING" });
    yield put({
      type: "SHOW_LOADING",
    });
    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/novelty/${payload.value.idNovelty}`;

    const headers = {
      method: "PATCH",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(payload.value),
    };

    const response = yield call(request, requestURL, headers);

    yield put({
      type: "HIDE_LOADING",
    });

    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "success",
        title: "Successful update",
        message: "Successful update novelty",
      },
    });

    yield put({
      type: "UPDATE_NOVELTY_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed update",
        message: "Failed update novelty",
      },
    });
    yield put({
      type: "UPDATE_NOVELTY_ERROR",
    });
  }
}

function* changeStateNovelty(payload) {
  try {
    yield put({
      type: "CHANGE_STATE_NOVELTY_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/novelty/changeState/${payload.value.idNovelty}`;

    const headers = {
      method: "PATCH",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
    };

    const response = yield call(request, requestURL, headers);

    yield put({
      type: "CHANGE_STATE_NOVELTY_SUCCESS",
      value: response,
    });
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "success",
        title: "Successfull change state",
        message: "Successfull change state",
      },
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed change state",
        message: "Failed change state",
      },
    });
    yield put({
      type: "CHANGE_STATE_NOVELTY_ERROR",
    });
  }
}


function* changeNoveltyforIncidents(payload) {
  try {
    yield put({
      type: "MANAGE_NOVELTIES_FOR_INCIDENTS_REQUESTING"
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/novelties/changeNoveltyforIncidents`;

    const headers = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload.value),
    };

    const response = yield call(request, requestURL, headers);
    
    yield put({
      type: "MANAGE_NOVELTIES_FOR_INCIDENTS_SUCCESS",
      value: response,
    });
    
    if(response.errorState){
      const downloadErrorsURL = `${process.env.REACT_APP_API_URL}/api/v1/rescheduling/downloadExcelErrors`;

      const headersDownloadErrors = {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(response.errors),
      };

      yield call(requestAxios, downloadErrorsURL, headersDownloadErrors);
      yield put({
        type: "SHOW_ALERT",
        value: {
          type: "warning",
          title: "Failed save",
          message: "The file has the errors",
        },
      });
    } else {
      yield put({
        type: "SHOW_ALERT",
        value: {
          type: "success",
          title: "Successful save",
          message: "Successful save Reschedule",
        },
      });
    }
  } catch (error) {
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed save",
        message: "Failed save Reschedule",
      },
    });
    yield put({
      type: "MANAGE_NOVELTIES_FOR_INCIDENTS_ERROR",
    });
  } finally {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "MANAGE_NOVELTIES_FOR_INCIDENTS_DONE",
    });
  }
}

export function* watchNovelty() {
  yield takeLatest("FETCH_NOVELTIES_REQUEST", getAllNovelties);
  yield takeLatest("READ_NOVELTY_REQUEST", getNovelty);
  yield takeLatest("FETCH_NOVELTIES_BY_STATUS_CODE_REQUEST", getNoveltiesByStatus);
  yield takeLatest("FETCH_NOVELTIES_FOR_RESCHEDULE_REQUEST", findAllForReschedule);
  yield takeLatest("FETCH_NOVELTIES_FOR_INCIDENTS_REQUEST", findAllForIncidents);
  yield takeLatest("CREATE_NOVELTY_REQUEST", postNovelty);
  yield takeLatest("UPDATE_NOVELTY_REQUEST", patchNovelty);
  yield takeLatest("CHANGE_STATE_NOVELTY_REQUEST", changeStateNovelty);
  yield takeLatest("MANAGE_NOVELTIES_FOR_INCIDENTS_REQUEST", changeNoveltyforIncidents);
}
