import React, { useState, useRef, useEffect, forwardRef, useContext } from 'react';
import { connect } from 'react-redux';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { Button, Modal, Tooltip, OverlayTrigger, Table } from 'react-bootstrap';
import moment from 'moment-timezone';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import 'react-datepicker/dist/react-datepicker.css';

import Sidebar from '../../../components/Navigation/Sidebar';
import Topbar from '../../../components/Navigation/Topbar';
import Grid from '../../../components/Grid';
import InputList, { SelectConstEnabled } from '../../../components/Common/inputList';
import { PermissionContext } from '../../../hooks/PermissionContext';
import { Show } from '../../../hooks/Show';
import { useAuth } from '../../../hooks/useAuth';
import { getStatusBadgeByCode } from '../../../utils/StatusUtils';
import formatDateWithCustomFormat, { FormatsDate }  from '../../../utils/formatDate';
import Timeline from '../../../components/Modal/DSP/Orders/Timeline';
import { isNullOrUndefined } from '../../../utils/functionsUtils';
import { formatDateWithoutTimeZone } from '../../../utils/formatDate';

const Swal = require('sweetalert2');

const DspOrder = ({
  reduxGetOrders,
  orders,
  reduxResetOrderForm,
  reduxGetOrderDetails,
  orderDetail,
  reduxGetCompanies,
  reduxGetStatuses,
  statuses,
}) => {
  const { hasPermission } = useContext(PermissionContext);
  const { control } = useForm();
  const methods = useForm({
    defaultValues: {
      indicativeRoad: '',
      roadNumber: '',
      appendixRoad: '',
      crossoverRoad: '',
      crossoverNumber: '',
      appendixCrossingNumber: '',
      orientationCrossingNumber: '',
      insideTypes: '',
      doorNumber: '',
      inside: '',
      city: '',
      neighborhood: '',
      country: '',
      department: '',
      state:'',
    },
  });
  const { 
    register, 
    watch, 
    formState: { errors }, 
    reset, 
    setValue, 
    getValues 
  } = methods;
  
  const {
    register: registerAdd,
    reset: resetAdd,
  } = useForm();
 
  const {
    register: registerSearch,
    handleSubmit: handleSubmitSearch,
    formState: { errors: errorsSearch },
    reset: resetSearch,
    setValue: setValueSearch,
    getValues: getValuesSearch,
  } = useForm({
    defaultValues: {
      idCompany: '',
      idOperator: '',
      date: null,
    },
  });
  const {
    register: registerDates,
    formState: { errors: errorRange },
    handleSubmit: handleSubmitDates,
    setValue: setValueDates,
    getValues: getValuesDates,
    reset: resetDates,
    clearErrors: clearErrorsDates,
  } = useForm({});
  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(10);
  const { session } = useAuth();
  const [show, setShow] = useState(false);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [total, setTotal] = useState(0);
  const [customerSelected, setCustomerSelected] = useState(null);
  const [cityFound, setCityFound] = useState('');
  const [countryFound, setCountryFound] = useState('');
  const [departmentFound, setDepartmentFound] = useState('');
  const [neighborhoodFound, setNeighborhoodFound] = useState('');
  const [showAssign, setShowAssign] = useState(false);
  const [objOrden, setObjOrden] = useState(null);
  const [startDate2, setStartDate2] = useState(null);
  const [ordersError, setOrdersError] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [objOrderDetailEdit, setObjOrderDetailEdit] = useState(null);
  const [isReading, setIsReading] = useState(false);
  const [finishLoad, setFinishLoad] = useState(false);
  const [state, setState] = useState();   
  const [idOrderSelected, setIdOrderSelected] = useState(null);
  const [showTimeline, setShowTimeline] = useState(false);  
  const [selectedOrderDetails, setSelectedOrderDetails] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState({});
  const CustomDateRangePicker = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      className="form-control"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
      value={value}
      autoComplete="Off"
    />
  ));

    
  const token = localStorage.getItem('token');

  
  const [deliveryDate, setDeliveryDate] = useState(null);
  
  const DatePickerCustomDeliveryDate = forwardRef(({ value, onClick, onChange, readOnly }, ref) => (
    <input
      disabled={readOnly}
      className='form-control'
      onClick={onClick}
      onChange={onChange}
      ref={ref}
      value={value}
      autoComplete='Off'
    />
  ));

  const indicativeRoad = useRef({});
  indicativeRoad.current = watch('indicativeRoad', '');

  const roadNumber = useRef({});
  roadNumber.current = watch('roadNumber', '');

  const appendixRoad = useRef({});
  appendixRoad.current = watch('appendixRoad', '');

  const crossoverRoad = useRef({});
  crossoverRoad.current = watch('crossoverRoad', '');

  const crossoverNumber = useRef({});
  crossoverNumber.current = watch('crossoverNumber', '');

  const appendixCrossingNumber = useRef({});
  appendixCrossingNumber.current = watch('appendixCrossingNumber', '');

  const orientationCrossingNumber = useRef({});
  orientationCrossingNumber.current = watch('orientationCrossingNumber', '');

  const insideTypes = useRef({});
  insideTypes.current = watch('insideTypes', '');

  const doorNumber = useRef({});
  doorNumber.current = watch('doorNumber', '');

  const inside = useRef({});
  inside.current = watch('inside', '');

  const city = useRef({});
  city.current = watch('city', '');

  const neighborhood = useRef({});
  neighborhood.current = watch('neighborhood', '');

  const country = useRef({});
  country.current = watch('country', '');

  const department = useRef({});
  department.current = watch('department', '');

  useEffect(() => {
    reduxGetStatuses({
      page: 1,
      search: '',
      offset: 1000,
    });

  }, [ reduxGetStatuses ]);

  const handleClose = () => {
    reduxResetOrderForm();
    setShow(false);
    setIsReading(false);
    setFinishLoad(false);
    setSelectedOrder(null);
    setSelectedOrderDetails(null);
  };  

  const [search, setSearch] = useState('');

  const handleOpenTimeline = (numOrder) => {
    setIdOrderSelected(numOrder);
    setShowTimeline(true);
  };

  const handleCloseTimeline = () => {
    setIdOrderSelected(null);
    setShowTimeline(false);
  };

  const columns = [
    {
      title: '# Orden',
      render: (rowData) => {
        return <span>{rowData.id}</span>;
      },
      field: 'id',
      searchable: true,
    },
    {
      title: '# Guía',
      render: (rowData) => {
        return <span>{rowData.tracking_id}</span>;
      },
      field: 'tracking_id',
      searchable: true,
    },
    {
      title: 'ID externo',
      render: (rowData) => {
        return <span>{rowData.external_id}</span>;
      },
      field: 'external_id',
      searchable: true,
    },
    {
      title: 'Ciudad',
      render: (rowData) => {
        return <span>{rowData.city_name_receiver}</span>;
      },
      field: 'city_name_receiver',
      searchable: true,
    },
    {
      title: 'Departamento',
      render: (rowData) => {
        return (
          <span>
            {rowData.state_name_receiver}
          </span>
        );
      },
      field: 'state_name_receiver',
      searchable: true,
    },      
    {
      title: 'Dirección Destinatario',
      render: (rowData) => {
        return <span>{rowData.street_line_receiver}</span>;
      },
      field: 'street_line_receiver',
    },
    {
      title: 'Dirección Recogida',
      render: (rowData) => {
        return <span>{rowData.street_line_hub}</span>;
      },
      field: 'street_line_hub',
    },    
    {
      title: 'Fecha de Orden',
      render: (rowData) => {
        const orderDate = rowData.created_at ? formatDateWithCustomFormat(rowData.created_at) : '';
        return <span>{orderDate}</span>;
      },
    },
    {
      title: 'Fecha de Entrega',
      render: (rowData) => {
        const deliveryDate = rowData.delivery_date ? formatDateWithCustomFormat(rowData.delivery_date) : '';
        return <span>{deliveryDate}</span>;
      },
    },      
    {
      title: 'Valor a Recaudar',
      render: (rowData) => {
        return <span>{rowData.cod_value}</span>;
      },
      field: 'cod_value',
    },
    {
      title: 'Compañia',
      render: (rowData) => {
        return <span>{rowData.shipper_name}</span>;
      },
      field: 'shipper_name',
    },
    {
      title: 'Estado',
      render: (rowData) => {
        return <span>{getStatusBadgeByCode(rowData.status_code)}</span>;
      },
      field: 'status_code',
    },
    {
      title: 'Opciones',
      render: (rowData) => {
        return (
          <>
            <Show when='feature:dsp-read-order-details'>
              <button
                title='Ver Detalle'
                className='btn btn-primary btn-sm btn-circle mr-2'
                type='button'
                onClick={(e) => handleShow(rowData, true)}
                >
                <i className='fas fa-eye fa-xs'></i>
              </button>
            </Show>
            <Show when='feature:dsp-read-order-timeline'>
              <button title='Timeline' className='btn btn-success btn-sm btn-circle mr-2' type='button' onClick={() => handleOpenTimeline(rowData.id)}>
                <i className='fas fa-info fa-xs'></i>
              </button>
            </Show>            
          </>
        );
      },
    },
  ];

  useEffect(() => {
    reduxGetCompanies({
      page: 1,
      offset: 10000,
      search: '',
      isActive: true,
    });
    reduxGetOrders({
      page: 1,
      offset: offset,
      search: '',
    });
  }, []);

  useEffect(() => {
    if (currentPage && orders && Object.keys(orders).length > 0) {
      let filters = {
        page: currentPage,
        offset: offset,
        search: search,
      };
      if (getValuesSearch('date')) {
        filters = {
          ...filters,
          date: moment(getValuesSearch('date')).tz('America/Bogota').format('MM-DD-YYYY'),
        };
      }
      if (getValuesSearch('deliveryDate')) {
        filters = {
          ...filters,
          deliveryDate: 'deliveryDate',
        };
      }

      if (getValuesSearch('idOrder')) {
        filters = {
          ...filters,
          idOrder: getValuesSearch('idOrder'),
        };
      }
      if (getValuesSearch('idCompany')) {
        filters = {
          ...filters,
          idCompany: getValuesSearch('idCompany'),
        };
      }
      if (getValuesSearch('idOperator')) {
        filters = {
          ...filters,
          idOperator: getValuesSearch('idOperator'),
        };
      }
      if (state){
        filters = {
          ...filters,
          state: state.value,
        };
        
      }
      if (fromDate && toDate) {
        filters = {
          ...filters,
          from: formatDateWithCustomFormat(fromDate, { format: FormatsDate.FULL24 }),
          to: formatDateWithCustomFormat(toDate, { format: 'YYYY-MM-DD 23:59:59' }),
        };
      }
      reduxGetOrders(filters);
    }
  }, [currentPage]);

  const handleShow = (rowData) => {
    setShow(true);   
    if (rowData.id) {
      reduxGetOrderDetails({id: rowData.id,});
    }
    setSelectedOrder(rowData);
    setSelectedOrderDetails(orderDetail);
  };

  useEffect(() => {
    console.log(orderDetail)
    if (orderDetail) {
      let total = [];
      for (let index = 0; index < orderDetail.length; index++) {
        const element = orderDetail[index];
        total.push({
          idOrderDetail: element.idOrderDetail,
          idOrder: element.idOrder,
          idProduct: element.idProduct,
          name: element.product.name,
          description: element.product.name,
          price: element.price,
          quantity: element.quantity,
        });
      }
      setSelectedOrderDetails(total);
    }
  }, [orderDetail]);

  useEffect(() => {
    setValue(
      'shippingAddress',
      `${indicativeRoad.current} ${roadNumber.current}${appendixRoad.current} ${crossoverRoad.current} ${crossoverNumber.current ? '#' : ''
      }${crossoverNumber.current}${appendixCrossingNumber.current}${orientationCrossingNumber.current ? ' ' : ''}${orientationCrossingNumber.current
      }${doorNumber.current ? '-' : ''}${doorNumber.current}${insideTypes.current ? ' ' + insideTypes.current + ' ' : ''
      }${inside.current}${neighborhood.current ? ',' : ''} ${neighborhood.current ? neighborhoodFound : ''}${city.current ? ', ' + cityFound : ''
      }${department.current ? ', ' + departmentFound : ''}${country.current ? ', ' + countryFound : ''}`
    );
  }, [
      indicativeRoad.current,
      roadNumber.current,
      appendixRoad.current,
      crossoverRoad.current,
      crossoverNumber.current,
      appendixCrossingNumber.current,
      orientationCrossingNumber.current,
      doorNumber.current,
      insideTypes.current,
      neighborhood.current,
      city.current,
      department.current,
      country.current,
      cityFound,
      departmentFound,
      countryFound,
      neighborhoodFound,
    ]);

  const handledChange2 = (date) => {
    setStartDate2(date);
    setValueSearch('date', moment(date).tz('America/Bogota').format('YYYY-MM-DD'));
  };

  const onSubmitSearch = (data) => {
    let filters = {
      page: currentPage,
      offset: offset,
      search: search,
      isActive: true,
    };
    if (state){
      filters = {
        ...filters,
        state: state.value,
      };
      
    }
    if (fromDate && toDate) {
      filters = {
        ...filters,
        from: formatDateWithCustomFormat(fromDate, { format: FormatsDate.FULL24 }),
        to: formatDateWithCustomFormat(toDate, { format: 'YYYY-MM-DD 23:59:59' }),
      };
    }
    reduxGetOrders(filters);
  };

  const handledChangeDatePicker = (dates) => {
    const [start, end] = dates;

    setFromDate(start);
    setToDate(end);

    setValue(
      "from",
      moment(start).tz("America/Bogota").format('YYYY-MM-DD')
    );
    setValue(
      "to",
      moment(end).tz("America/Bogota").format('YYYY-MM-DD')
    );
  };


  const handledChangeStatus = (state) => {
    const status = state;

    setState(status);
  };

  return (
    <div>
      <div id='wrapper'>
        <Sidebar />
        <div
          id='content-wrapper'
          className='d-flex flex-column'>
          <div id='content'>
            <Topbar />
            <div className='container-fluid'>
              <div className='d-sm-flex align-items-center mb-1'>
                <h1 className='h3 mb-0 text-gray-800 mr-3'>Órdenes de distribución</h1>
              </div>
              <p className='mb-4'>Módulo de Administración de órdenes de distribución</p>
              <form onSubmit={handleSubmitSearch(onSubmitSearch)}>
                <div className='row'>
                  <div className='col s6 m2'>
                    <div className='form-group'>
                      <label htmlFor="from" className="form-label">Desde - Hasta</label>
                      <DatePicker
                        selected={fromDate}
                        onChange={handledChangeDatePicker}
                        startDate={fromDate}
                        endDate={toDate}
                        maxDate={new Date()}
                        isClearable={true}
                        dateFormat="MM-dd-yyyy"
                        customInput={<CustomDateRangePicker />}
                        selectsRange
                      />
                    </div>
                  </div>                  
                  <div className='form-group col'>
                      <label
                        htmlFor='state'
                        className='form-label'>
                        Estado
                      </label>
                      <Select
                        isClearable={true}
                        {...register('state')}
                        id='state'
                        onChange={handledChangeStatus}
                        options={
                          statuses && Object.keys(statuses).length > 0
                            ? [
                              ...statuses.items.map((ele) => ({
                                value: ele.code,
                                label: ele.name,
                              })),
                              { value: '-2', label: 'Eliminados' },
                            ]
                            : []
                        }
                      />
                    </div>
                  <div className='col s12 m1'>
                    <Button
                      variant='primary'
                      style={{ marginTop: '32px' }}
                      type='submit'
                      id='btnSearch'>
                      Buscar
                    </Button>
                  </div>
                </div>
                <div className='card shadow mb-4'>
                  <div className='card-header py-3'>
                    <h6 className='m-0 font-weight-bold text-primary'>Listado de Órdenes</h6>
                  </div>
                  <div className='card-body'>
                    <Grid
                      cols={columns}
                      data={orders && Object.keys(orders).length > 0 ? orders.items : []}
                      page={orders && Object.keys(orders).length > 0 ? Number(orders.page) : currentPage}
                      pages={orders && Object.keys(orders).length > 0 ? Number(orders.totalPages) : 1}
                      total={orders && orders.hasOwnProperty('total') ? orders.total : 0}
                      offset={offset}
                      onChangePage={(page) => {setCurrentPage(page)}}
                      onChangeRange={(value) => {
                        setOffset(value);
                        let filters = {
                          page: 1,
                          offset: value,
                          search: search,
                        };
                        if (getValuesSearch('date')) {
                          filters = {
                            ...filters,
                            date: moment(getValuesSearch('date')).tz('America/Bogota').format('MM-DD-YYYY'),
                          };
                        }
                        if (getValuesSearch('deliveryDate')) {
                          filters = {
                            ...filters,
                            deliveryDate: 'deliveryDate',
                          };
                        }

                        if (getValuesSearch('idOrder')) {
                          filters = {
                            ...filters,
                            idOrder: getValuesSearch('idOrder'),
                          };
                        }
                        if (getValuesSearch('idCompany')) {
                          filters = {
                            ...filters,
                            idCompany: getValuesSearch('idCompany'),
                          };
                        }
                        if (getValuesSearch('idOperator')) {
                          filters = {
                            ...filters,
                            idOperator: getValuesSearch('idOperator'),
                          };
                        }
                        if (state){
                          filters = {
                            ...filters,
                            state: state.value,
                          };
                          
                        }
                        if (fromDate && toDate) {
                          filters = {
                            ...filters,
                            from: formatDateWithCustomFormat(fromDate, { format: FormatsDate.FULL24 }),
                            to: formatDateWithCustomFormat(toDate, { format: 'YYYY-MM-DD 23:59:59' }),
                          };
                        }
                        reduxGetOrders(filters);
                      }}
                      defaultValue={search}
                      onChangeSearch={(value) => {
                        setSearch(value);
                        let filters = {
                          page: 1,
                          offset: offset,
                          search: value,
                        };
                        if (getValuesSearch('date')) {
                          filters = {
                            ...filters,
                            date: moment(getValuesSearch('date')).tz('America/Bogota').format('MM-DD-YYYY'),
                          };
                        }
                        if (getValuesSearch('deliveryDate')) {
                          filters = {
                            ...filters,
                            deliveryDate: 'deliveryDate',
                          };
                        }
                        if (getValuesSearch('idOrder')) {
                          filters = {
                            ...filters,
                            idOrder: getValuesSearch('idOrder'),
                          };
                        }
                        if (getValuesSearch('idCompany')) {
                          filters = {
                            ...filters,
                            idCompany: getValuesSearch('idCompany'),
                          };
                        }
                        if (getValuesSearch('idOperator')) {
                          filters = {
                            ...filters,
                            idOperator: getValuesSearch('idOperator'),
                          };
                        }
                        if (state){
                          filters = {
                            ...filters,
                            state: state.value,
                          };
                          
                        }
                        if (fromDate && toDate) {
                          filters = {
                            ...filters,
                            from: formatDateWithCustomFormat(fromDate, { format: FormatsDate.FULL24 }),
                            to: formatDateWithCustomFormat(toDate, { format: 'YYYY-MM-DD 23:59:59' }),
                          };
                        }
                        reduxGetOrders(filters);
                      }}
                    />
                  </div>
                </div>
              </form>
              
            </div>
          </div>
        </div>
      </div>
      <div>
        <Modal
          show={show}
          onHide={handleClose}
          onEscapeKeyDown={handleClose}
          backdrop='static'
          keyboard={true}
          size='xl'>
          <Modal.Header closeButton>
            <Modal.Title>Ver detalle de la orden</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormProvider {...methods}>             
                <div>
                  <div className='row'>
                    <div className='col s12 m4'>
                      <div className='form-group'>
                        <label
                          htmlFor='trackinId'
                          className='form-label'>
                          # Guía
                        </label>
                        <input
                          id='trackinId'
                          type='text'
                          className={`form-control form-control-user`}
                          value={selectedOrder ? selectedOrder.tracking_id : ''}
                          disabled={true}
                        />
                      </div>
                    </div>
                    <div className='col s12 m4'>
                      <div className='form-group'>
                        <label
                          htmlFor='orderId'
                          className='form-label'>
                          # Orden
                        </label>
                        <input
                          id='orderId'
                          type='text'
                          className={`form-control form-control-user`}
                          value={selectedOrder ? selectedOrder.id : ''}
                          disabled={true}
                        />
                      </div>
                    </div>
                    <div className='col s12 m4'>
                      <div className='form-group'>
                        <label
                          htmlFor='externalId'
                          className='form-label'>
                          ID externo
                        </label>
                        <input
                          id='externalId'
                          type='text'
                          className={`form-control form-control-user ${errors.externalId && 'is-invalid'}`}
                          value={selectedOrder ? selectedOrder.external_id : ''}
                          disabled={true}
                        />
                      </div>
                    </div>            
                    <div className='col s12 m4'>
                      <div className='form-group'>
                      <label
                          htmlFor='date'
                          className='form-label'>
                          Fecha de asignación
                        </label>
                        <input
                          id='date'
                          type='date'
                          className={`form-control form-control-user ${errors.date && 'is-invalid'}`}
                          value={selectedOrder ? formatDateWithCustomFormat(selectedOrder.created_at): '' }
                          disabled={true}
                        />
                      </div>
                    </div>
                    <div className='col s12 m4'>
                      <div className='form-group'>
                        <div className='form-group'>
                          <label
                            htmlFor='Ciudad'
                            className='form-label'>
                            Ciudad
                          </label>
                          <input
                            id='city'
                            type='text'
                            className={`form-control form-control-user`}
                            value={selectedOrder ? selectedOrder.city_name_receiver : ''}
                            disabled={true}
                          />
                        </div>
                      </div>
                    </div>      
                  </div>
                  <div className='row'>
                  <div className='col s12 m4'>
                      <div className='form-group'>
                        <label
                          htmlFor='address'
                          className='form-label'>
                          Dirección
                        </label>
                        <input
                          id='address'
                          type='text'
                          className={`form-control form-control-user`}
                          value={selectedOrder ? selectedOrder.street_line_receiver : ''}
                          disabled={true}
                        />
                      </div>
                    </div>
                    <div className='col s12 m4'>
                      <div className='form-group'>
                        <label
                          htmlFor='deliveryDate'
                          className='form-label'>
                          Fecha de entrega
                        </label>
                        <input
                          id='deliveryDate'
                          type='date'
                          className={`form-control form-control-user ${errors.deliveryDate && 'is-invalid'}`}
                          value={selectedOrder ? formatDateWithCustomFormat(selectedOrder.delivery_date) : ''}
                          disabled={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <form>
                  <div className='card'>
                    <h5 className='card-header'>Detalle</h5>
                    <div className='card-body'>                     
                    <Table striped bordered hover>                
                      <thead>
                        <tr>
                          <th colSpan="4" className="text-center">Listado de Productos</th>
                        </tr>
                        <tr>
                          <th># Producto</th>
                          <th>Nombre</th>
                          <th>Descripción</th>
                          <th>Cantidad</th>
                        </tr>
                      </thead>
                      <tbody>
                        {selectedOrderDetails && selectedOrderDetails.length > 0 ? (
                            selectedOrderDetails.map((detail, index) => (
                              <tr key={index}>
                                <td>{detail.idProduct}</td>
                                <td>{detail.name}</td>
                                <td>{detail.description}</td>
                                <td>{detail.quantity}</td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="4" className="text-center">No hay detalles disponibles</td>
                            </tr>
                          )}
                      </tbody>
                    </Table>
                    </div>
                  </div>
                </form>

                <div
                  style={{
                    marginTop: '5px',
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}>
                  <label
                    htmlFor='ammount'
                    className='form-label'
                    style={{ marginTop: '5px' }}>
                    Monto
                  </label>
                  <input
                    id='ammount'
                    type='number'
                    style={{
                      width: '250px',
                      textAlign: 'right',
                      marginLeft: '10px',
                    }}
                    className={`form-control form-control-user`}
                    value={selectedOrder ? selectedOrder.cod_value : ''}
                    disabled={true}
                  />
                </div>
            </FormProvider>
          </Modal.Body>
        </Modal>
      </div>
      <div>
        <Show when='feature:dsp-read-order'>
          <Modal
            size='xl'
            show={showTimeline}
            onHide={handleCloseTimeline}
            onEscapeKeyDown={handleCloseTimeline}
            backdrop='static'
            keyboard={true}>
            <Modal.Header closeButton>
              <Modal.Title>Timeline</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Timeline numOrder={idOrderSelected}></Timeline>
            </Modal.Body>
          </Modal>
        </Show>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    orders: state.dspState.orders,
    orderDetail: state.dspState.orderDetail,
    companies: state.companyState.companies,
    statuses: state.statusesState.statuses,
    ordersEvents: state.dspState.ordersEvents,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    reduxGetOrders: (payload) =>
      dispatch({
        type: 'FETCH_DSP_ORDERS_REQUEST',
        value: payload,
      }),
    reduxResetOrderForm: () =>
      dispatch({
        type: 'RESET_ORDER_FORM',
      }),
    reduxGetOrderDetails: (payload) =>
      dispatch({
        type: 'READ_DSP_ORDERDETAIL_REQUEST',
        value: payload,
      }),
    reduxGetCompanies: (payload) =>
      dispatch({
        type: 'FETCH_COMPANIES_REQUEST',
        value: payload,
      }),
    reduxGetStatuses: (payload) =>
      dispatch({
        type: "FETCH_NEW_STATUS_REQUEST",
        value: payload,
      }),
    reduxGetDSPOrderTracking: (payload) =>
      dispatch({
        type: 'READ_DSPORDER_TRACKING_REQUEST',
        value: payload,
      }),      
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DspOrder);
