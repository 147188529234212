import React, { useEffect, useState } from "react";
import { useTable, useExpanded } from "react-table";
import { Pagination } from "react-bootstrap";
import ReactPaginate from "react-paginate";

import SearchTable from "../Common/globalTableSearch";
import { Show } from "../../hooks/Show";

function Table({
  columns,
  data,
  renderRowSubComponent,
  page,
  pages,
  onChangeRange,
  onChangePage,
  onChangeSearch,
  total,
  defaultValue,
  recordsDetails,
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns,
    state: { expanded },
  } = useTable(
    {
      columns,
      data,
    },
    useExpanded
  );


  const ranges = [10, 25, 50, 100, 500, 1000];

  const showPaginationNumbers = (pageNumbers) => {
    let paginationNumbers = [];
    if (pageNumbers) {
      let showMax = 5;
      let endPage;
      let startPage;
      if (pageNumbers <= showMax) {
        startPage = 1;
        endPage = pageNumbers;
      } else {
        startPage = page;
        if (
          startPage != pageNumbers &&
          startPage + 1 != pageNumbers
        ) {
          endPage = page + showMax - 1;
        } else {
          endPage = pageNumbers;
        }
      }
      for (let i = startPage; i <= endPage; i++) {
        paginationNumbers.push(i);
      }
      return showRenderPageNumbers(paginationNumbers);
    }
  };

  const showRenderPageNumbers = (paginationNumbers) => {
    if (paginationNumbers) {
      let result = paginationNumbers.map((number) => {
        return (
          <Pagination.Item
            key={number}
            active={number === page}
            onClick={() => onChangePage(number)}
          >
            {number}
          </Pagination.Item>
        );
      });
      return result;
    }
  };

  const [currentGlobalFilter, setCurrentGlobalFilter] = useState('');

  const [value, setValue] = useState(null);

  const onChangeValue = (event) => {
    setValue(event.target.value)
  };

  const onSubmit = (e) => {
    e.preventDefault();
    onChangeSearch(`${currentGlobalFilter}|${encodeURIComponent(value.trim())}`);
  };

  const handleChangeFilter = (val) => {
    let dev = val.currentTarget.value;
    setCurrentGlobalFilter(dev);
    setValue('')
    if (dev === '') {
      onChangeSearch('')
    }
  }
  const handleSubmitFilter = (e) => {
    e.preventDefault();
    onChangeSearch(`${currentGlobalFilter}|${encodeURIComponent(value.trim())}`);
  }

  const onEnter = (event) => event.key === "Enter" && onSubmit(event);

  return (
    <>
      {onChangeRange && onChangeSearch && (
        <div className="d-flex mb-3 justify-content-between align-items-center">
          <div className="w-auto">
            <div className="dataTables_length" id="dataTable_length">
              <label>
                Mostrar{" "}
                <select
                  name="dataTable_length"
                  aria-controls="dataTable"
                  className="custom-select custom-select-sm form-control form-control-sm"
                  onChange={(event) => {
                    onChangeRange(event.target.value);
                  }}
                >
                  {ranges.map((elem, index) => (
                    <option key={index} value={elem}>
                      {elem}
                    </option>
                  ))}
                </select>{" "}
                registros
              </label>
            </div>
          </div>
          <div className="ml-auto">
            <div id="dataTable_filter" className="dataTables_filter">
              <form onSubmit={handleSubmitFilter}>
                <div className="input-group">
                  <SearchTable columns={columns} onChangeSearch={handleChangeFilter} filterValue={value} filterEnabled={currentGlobalFilter === ''} handleFilterValueChange={onChangeValue} />
                  <div className="input-group-append">
                    {(currentGlobalFilter !== '' && value !== '') && (
                      <button
                        className="btn btn-primary"
                        type="submit"
                        onClick={(e) => onSubmit(e)}
                      >
                        <i className="fas fa-search fa-sm"></i>
                      </button>
                    )}

                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
      <div className="table-responsive">
        <div id="dataTable_wrapper" className="dataTables_wrapper dt-bootstrap4">
          <table className={"table table-bordered dataTable"}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column, k) => {
                    if (
                      (headerGroup.headers.length >= 26 && k <= 10) ||
                      (headerGroup.headers.length <= 26 && k <= 8)
                    )
                      return (
                        <th className="text-nowrap" key={k}>
                          {column.render("Header")}
                        </th>
                      );
                  })}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
                const { key, ...restRowProps } = row.getRowProps();
                return (
                  <React.Fragment key={key}>
                    <tr
                      {...restRowProps}
                      style={{
                        backgroundColor: row.isActive == false ? "#6492ba" : "#fff",
                        color: row.isActive == false ? "#fff" : "#858796",
                      }}
                    >
                      {row.cells.map((cell, j) => {
                        if (
                          (row.cells.length >= 26 && j <= 10) ||
                          (row.cells.length <= 26 && j <= 8)
                        ) {
                          if (
                            j == 1 &&
                            (row.cells[17].value == "Cliente no recibe" ||
                              row.cells[19].value == "Cliente no recibe")
                          ) {
                            return (
                              <td className="text-nowrap" key={j}>
                                {cell.render("Cell")} ⚡
                              </td>
                            );
                          } else {
                            return (
                              <td className="text-nowrap" key={j}>
                                {cell.render("Cell")}
                              </td>
                            );
                          }
                        }
                      })}
                    </tr>
                    {row.isExpanded ? (
                      <tr>
                        <td colSpan={visibleColumns.length}>
                          {renderRowSubComponent({ row, ...recordsDetails })}
                        </td>
                      </tr>
                    ) : null}
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <div className={'row mt-3'}>
        <div className="col-sm-12 col-md-5">
          <strong>{total}</strong> registros encontrados |
          página <strong>{page}</strong> de <strong>{pages}</strong>
        </div>
        <div className="col-sm-12 col-md-7">
          {pages != 0 && (
            <Pagination className="justify-content-start align-items-end">
              <ReactPaginate
                previousLabel="Anterior"
                nextLabel="Siguiente"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                pageCount={pages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={(page) => onChangePage(page.selected + 1)}
                containerClassName="pagination"
                activeClassName="active"
                forcePage={page === 1 ? 0 : page - 1}
              />
            </Pagination>
          )}
        </div>
      </div>

    </>

  );
}

const MultiTable = ({
  data = [],
  columns,
  page,
  pages,
  onChangeRange,
  onChangePage,
  onChangeSearch,
  total,
  defaultValue,
  detailsCache,
}) => {
   const renderRowSubComponent = React.useCallback(   
    ({ row }) => {
      const details = detailsCache && detailsCache[row.original.idAddressRecord] ? detailsCache[row.original.idAddressRecord][0] : {};
      return (
        <pre
          style={{
            overflow: "hidden",
            color: "rgb(133, 135, 150)",
          }}
        >
        <Show when="feature:show-recipient-address">
          <div className="row">
            <div className="col s12 12">
              <label className="form-label" style={{ fontWeight: "bold" }}>
                Dirección Destinatario:{" "}
              </label>
              <label className="form-label">{details && details.address ? details.address : '' }</label>
            </div>
          </div>
        </Show>
        <div className="row">
          <Show when="feature:show-reference1-address">
            <div className="col s12 m4">
              <label className="form-label" style={{ fontWeight: "bold" }}>
                Teléfono Destinatario:{" "}
              </label>
              <label className="form-label">{details && details.reference1 ? details.reference1 : '' }</label>
            </div>
          </Show>
          <div className="col s12 m4">
            <label className="form-label" style={{ fontWeight: "bold" }}>
              Observaciones:{" "}
            </label>
            <label className="form-label">{ details && details.reference2 ? details.reference2 : '' }</label>
          </div>
          <div className="col s12 m4">
            <label className="form-label" style={{ fontWeight: "bold" }}>
              Valor del recaudo:{" "}
            </label>
            <label className="form-label">{ details && details.ammount ? details.ammount : '' }</label>
          </div>
        </div>
        <div className="row">
          <div className="col s12 m4">
            <label className="form-label" style={{ fontWeight: "bold" }}>
              Guía cliente:{" "}
            </label>
            <label className="form-label">{ details && details.clientTrackingId ? details.clientTrackingId : '' }</label>
          </div>
          <div className="col s12 m4">
            <label className="form-label" style={{ fontWeight: "bold" }}>
              Ubicación de Entrega:{" "}
            </label>
            <label className="form-label">{ details && details.deliveryLocation ? details.deliveryLocation : '' }</label>
          </div>
          <div className="col s12 m4">
            <label className="form-label" style={{ fontWeight: "bold" }}>
              Nombre del cliente:{" "}
            </label>
            <label className="form-label">{ details && details.name ? details.name : '' }</label>
          </div>
        </div>
        <div className="row">
          <div className="col s12 m4">
            <label className="form-label" style={{ fontWeight: "bold" }}>
              Mensajero asignado:{" "}
            </label>
            <label className="form-label">{ details && details.courier ? details.courier : '' }</label>
          </div>
          <div className="col s12 m4">
            <label className="form-label" style={{ fontWeight: "bold" }}>
              Novedad:{" "}
            </label>
            <label className="form-label">{ details && details.novelty ? details.novelty : '' }</label>
          </div>
          <div className="col s12 m4">
            <label className="form-label" style={{ fontWeight: "bold" }}>
              Nota:{" "}
            </label>
            <label className="form-label">{ details && details.record ? details.record : '' }</label>
          </div>
        </div>
        <div className="row">
          <div className="col s12 m4">
            <label className="form-label" style={{ fontWeight: "bold" }}>
              Comentario de novedad/nota:{" "}
            </label>
            <label className="form-label">{ details && details.detailObservation ? details.detailObservation : '' }</label>
          </div>
          <div className="col s12 m4">
            <label className="form-label" style={{ fontWeight: "bold" }}>
              Comentario de dirección:{" "}
            </label>
            <label className="form-label">{ details && details.comment ? details.comment : '' }</label>
          </div>
        </div>
        <div className="row">
          <div className="col s12 m4">
            <label className="form-label" style={{ fontWeight: "bold" }}>
              Intento de entrega:{ details && details.attempt ? details.attempt : '' }
            </label>
          </div>
          <div className="col s12 m4">
            <label className="form-label" style={{ fontWeight: "bold" }}>
              Días de retraso:{ details && details.delay ? details.delay : '' }
            </label>
          </div>
        </div>
        <div className="row">
          <div className="col s12 m4">
            <label className="form-label" style={{ fontWeight: "bold" }}>
              Tipo de cierre: { details && details.finishedType ? details.finishedType : '' }
            </label>
          </div>
          <div className="col s12 m4">
            <label className="form-label" style={{ fontWeight: "bold" }}>
              Observación de cierre: { details && details.finishedDescription ? details.finishedDescription : '' }
            </label>
          </div>
          <div className="col s12 m4">
            <label className="form-label" style={{ fontWeight: "bold" }}>
              ID externo: { details && details.externalId ? details.externalId : '' }
            </label>
          </div>
          <div className="col s12 m4">
            <label className="form-label" style={{ fontWeight: "bold" }}>
              Fecha de Entrega: { details && details.deliveryDate ? details.deliveryDate : '' }
            </label>
          </div>
        </div>
        </pre>
    );
  },
  [detailsCache]
);

  return (
    <Table
      columns={columns}
      data={data}
      renderRowSubComponent={renderRowSubComponent}
      page={page}
      pages={pages}
      total={total}
      onChangeRange={onChangeRange}
      onChangePage={onChangePage}
      onChangeSearch={onChangeSearch}
      defaultValue={defaultValue}
      detailsCache={detailsCache}
    />
  );
};

export default MultiTable;
