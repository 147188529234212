import React, { useState } from 'react';
import { Button, Modal } from "react-bootstrap";
import Select from "react-select";
import { connect } from 'react-redux';

const OrderManagementModal = ({ rowData, reduxManageNovelties, successfulManageNoveltiesForIncidents, masivNoveltiesManage }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedNovelty, setSelectedNovelty] = useState(null);
  const [observations, setObservations] = useState("");

  const novelties = [
    { code: "001", description: "Reprogramación" },
    { code: "002", description: "Devolución" },
  ];

  const handleSubmit = () => {
    let payload;
    if (selectedNovelty?.value === "001") {
      payload = {
        byTrackings: '1',
        shipmentIds: String(rowData.idAddress), 
        manageResolution: {
          label: 'Reprogramación',
          value: 1
        },
        comment: observations
      };
    } else if (selectedNovelty?.value === "002") {
      payload = {
        byTrackings: '1',
        shipmentIds: String(rowData.idAddress), 
        manageResolution: { 
          label: 'Devolución',
          value: 2
        },
        comment: observations
      };
    }

    if (payload) {
      reduxManageNovelties(payload);
    }
  };

  React.useEffect(() => {
    if (successfulManageNoveltiesForIncidents) {
      setIsOpen(false);
      // Aquí puedes agregar lógica adicional después de una actualización exitosa
    }
  }, [successfulManageNoveltiesForIncidents]);

  return (
    <>
      <Button
        variant="warning"
        size="sm"
        className="btn-circle"
        title="Gestionar Orden"
        onClick={() => setIsOpen(true)}
      >
        <i className='fas fa-thumbs-up fa-xs'></i>
      </Button>

      <Modal show={isOpen} onHide={() => setIsOpen(false)} dialogClassName="custom-modal-width">
        <Modal.Header closeButton>
          <Modal.Title>Gestión de Ordenes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="py- px-3">
            <div className="mb-3">
              <p className="label">Gestión</p>
              <Select
                options={novelties.map(novelty => ({ value: novelty.code, label: novelty.description }))}
                onChange={(selected) => setSelectedNovelty(selected)}
                placeholder="Seleccione una novedad"
                className="w-50 select-fixed-height"
              />
            </div>
            
            <div className="mb-3 mt-4">
              <p className="label">Observaciones*</p>
              <textarea
                className="form-control textarea-fixed-height"
                value={observations}
                onChange={(e) => setObservations(e.target.value)}
                style={{ height: '38px' }}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setIsOpen(false)}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Enviar
          </Button>
        </Modal.Footer>
      </Modal>

      <style jsx>{`
        .custom-modal-width {
          max-width: 900px;
        }
        .select-fixed-height {
          height: 30px;
        }
        .textarea-fixed-height {
          resize: none;
        }
        .label {
          font-size: 14px;
          margin-bottom: 5px;
          font-weight: bold;
        }
      `}</style>
    </>
  );
};

const mapStateToProps = (state) => ({
  successfulManageNoveltiesForIncidents: state.noveltyState.successfulManageNoveltiesForIncidents,
  masivNoveltiesManage: state.noveltyState.masivNoveltiesManage,
});

const mapDispatchToProps = (dispatch) => ({
  reduxManageNovelties: (payload) =>
    dispatch({
      type: 'MANAGE_NOVELTIES_FOR_INCIDENTS_REQUEST',
      value: payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderManagementModal);