const initialState = {
  requestingFetchCompanies: false,
  successfulFetchCompanies: false,
  errorFetchCompanies: false,
  companies: {},
  requestingCreateCompany: false,
  successfulCreateCompany: false,
  errorsCreateCompany: false,
  company: null,
  requestingReadCompany: false,
  successfulReadCompany: false,
  errorsReadCompany: false,
  rowEdited: null,
  requestingDeleteCompany: false,
  successfulDeleteCompany: false,
  errorsDeleteCompany: false,
  rowDeleted: null,
  requestingUpdateCompany: false,
  successfulUpdateCompany: false,
  errorsUpdateCompany: false,
  rowUpdated: null,
  requestingReadCompanyByUser: false,
  successfulReadCompanyByUser: false,
  errorsReadCompanyByUser: false,
  companyByUser: null,
  requestingFetchAllCompanies: false,
  successfulFetchAllCompanies: false,
  errorFetchAllCompanies: false,
  allCompanies: {},
  requestingFetchCompaniesByType: false,
  successfulFetchCompaniesByType: false,
  errorFetchCompaniesByType: false,
  clients: {},
  dspOperators: {},
  logisticsOperators: {},
  inventoryOperators: {},
};

const companyReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_COMPANIES_REQUESTING":
      return {
        ...state,
        requestingFetchCompanies: true,
        successfulFetchCompanies: false,
        errorsFetchCompanies: false,
      };
    case "FETCH_COMPANY_SUCCESS":
      return {
        ...state,
        errorFetchCompanies: false,
        requestingFetchCompanies: false,
        successfulFetchCompanies: true,
        companies: action.value,
      };
    case "FETCH_COMPANY_ERROR":
      return {
        ...state,
        errorFetchCompanies: true,
        requestingFetchCompanies: false,
        successfulFetchCompanies: false,
      };
    case "CREATE_COMPANY_REQUESTING":
      return {
        ...state,
        requestingCreateCompany: true,
        successfulCreateCompany: false,
        // errorsCreateCompany: false,
      };
    case "CREATE_COMPANY_SUCCESS":
      return {
        ...state,
        errorsCreateCompany: false,
        requestingCreateCompany: false,
        successfulCreateCompany: true,
        company: action.value,
      };
    case "CREATE_COMPANY_ERROR":
      return {
        ...state,
        errorsCreateCompany: true,
        requestingCreateCompany: false,
        successfulCreateCompany: false,
      };
    case "READ_COMPANY_REQUESTING":
      return {
        ...state,
        requestingReadCompany: true,
        successfulReadCompany: false,
        errorsReadCompany: false,
      };
    case "READ_COMPANY_SUCCESS":
      return {
        ...state,
        errorsReadCompany: false,
        requestingReadCompany: false,
        successfulReadCompany: true,
        rowEdited: action.value,
      };
    case "READ_COMPANY_ERROR":
      return {
        ...state,
        errorsReadCompany: true,
        requestingReadCompany: false,
        successfulReadCompany: false,
      };
    case "DELETE_COMPANY_REQUESTING":
      return {
        ...state,
        requestingDeleteCompany: true,
        successfulDeleteCompany: false,
        errorsDeleteCompany: false,
      };
    case "DELETE_COMPANY_SUCCESS":
      return {
        ...state,
        errorsDeleteCompany: false,
        requestingDeleteCompany: false,
        successfulDeleteCompany: true,
        rowDeleted: action.value,
      };
    case "DELETE_COMPANY_ERROR":
      return {
        ...state,
        errorsDeleteCompany: true,
        requestingDeleteCompany: false,
        successfulDeleteCompany: false,
      };
    case "UPDATE_COMPANY_REQUESTING":
      return {
        ...state,
        requestingUpdateCompany: true,
        successfulUpdateCompany: false,
        // errorsUpdateCompany: false,
      };
    case "UPDATE_COMPANY_SUCCESS":
      return {
        ...state,
        errorsUpdateCompany: false,
        requestingUpdateCompany: false,
        successfulUpdateCompany: true,
        rowUpdated: action.value,
      };
    case "UPDATE_COMPANY_ERROR":
      return {
        ...state,
        errorsUpdateCompany: true,
        requestingUpdateCompany: false,
        successfulUpdateCompany: false,
      };
    case "READBYUSER_COMPANY_REQUESTING":
      return {
        ...state,
        requestingReadCompanyByUser: true,
        successfulReadCompanyByUser: false,
        errorsReadCompanyByUser: false,
      };
    case "READBYUSER_COMPANY_SUCCESS":
      return {
        ...state,
        errorsReadCompanyByUser: false,
        requestingReadCompanyByUser: false,
        successfulReadCompanyByUser: true,
        companyByUser: action.value,
      };
    case "READBYUSER_COMPANY_ERROR":
      return {
        ...state,
        errorsReadCompanyByUser: true,
        requestingReadCompanyByUser: false,
        successfulReadCompanyByUser: false,
      };
    case "FETCH_ALLCOMPANIES_REQUESTING":
      return {
        ...state,
        requestingFetchAllCompanies: true,
        successfulFetchAllCompanies: false,
        errorsFetchAllCompanies: false,
      };
    case "FETCH_ALLCOMPANY_SUCCESS":
      return {
        ...state,
        errorsFetchAllCompanies: false,
        requestingFetchAllCompanies: false,
        successfulFetchAllCompanies: true,
        allCompanies: action.value,
      };
    case "FETCH_ALLCOMPANY_ERROR":
      return {
        ...state,
        errorsFetchAllCompanies: true,
        requestingFetchAllCompanies: false,
        successfulFetchAllCompanies: false,
      };
    case "FETCH_COMPANIESBYTYPE_REQUESTING":
      return {
        ...state,
        requestingFetchCompaniesByType: true,
        successfulFetchCompaniesByType: false,
        errorFetchCompaniesByType: false,
      };
    case "FETCH_COMPANYBYSHIPPER_SUCCESS":
      return {
        ...state,
        errorFetchCompaniesByType: false,
        requestingFetchCompaniesByType: false,
        successfulFetchCompaniesByType: true,
        clients: action.value,
      };
    case "FETCH_COMPANIESBYDSP_SUCCESS":
      return {
        ...state,
        errorFetchCompaniesByType: false,
        requestingFetchCompaniesByType: false,
        successfulFetchCompaniesByType: true,
        dspOperators: action.value,
      };
    case "FETCH_COMPANYBYLOGISTICSOPERATOR_SUCCESS":
      return {
        ...state,
        errorFetchCompaniesByType: false,
        requestingFetchCompaniesByType: false,
        successfulFetchCompaniesByType: true,
        logisticsOperators: action.value,
        clients: action.value,
        loading: false,
        error: null
      };
    case "FETCH_COMPANYBYINVENTORYOPERATOR_SUCCESS":
      return {
        ...state,
        errorFetchCompaniesByType: false,
        requestingFetchCompaniesByType: false,
        successfulFetchCompaniesByType: true,
        inventoryOperators: action.value,
      };
    case "FETCH_COMPANY_ERROR":
      return {
        ...state,
        errorFetchCompaniesByType: true,
        requestingFetchCompaniesByType: false,
        successfulFetchCompaniesByType: false,
      };
    case "RESET_COMPANY_FORM":
      return {
        ...state,
        requestingCreateCompany: false,
        successfulCreateCompany: false,
        errorsCreateCompany: false,
        company: null,
        requestingReadCompany: false,
        successfulReadCompany: false,
        errorsReadCompany: false,
        rowEdited: null,
        requestingDeleteCompany: false,
        successfulDeleteCompany: false,
        errorsDeleteCompany: false,
        rowDeleted: null,
        requestingUpdateCompany: false,
        successfulUpdateCompany: false,
        errorsUpdateCompany: false,
        rowUpdated: null,
        requestingReadCompanyByUser: false,
        successfulReadCompanyByUser: false,
        errorsReadCompanyByUser: false,
        companyByUser: null,
        requestingFetchAllCompanies: false,
        successfulFetchAllCompanies: false,
        errorFetchAllCompanies: false,
        allCompanies: {},
        requestingFetchCompaniesByType: false,
        successfulFetchCompaniesByType: false,
        errorFetchCompaniesByType: false,
        clients: {},
        operators: {},
      };
    case "DELETE_OPERATOR_BRANCH_REQUESTING":
      return {
        ...state,
        requestingDeleteOperatorBranch: true,
        successfulDeleteOperatorBranch: false,
        errorsDeleteOperatorBranch: false,
      };
    case "DELETE_OPERATOR_BRANCH_SUCCESS":
      return {
        ...state,
        requestingDeleteOperatorBranch: false,
        successfulDeleteOperatorBranch: false,
        errorsDeleteOperatorBranch: true
      };
    case "DELETE_OPERATOR_BRANCH_ERROR":
      return {
        ...state,
        requestingDeleteOperatorBranch: true,
        successfulDeleteOperatorBranch: false,
        errorsDeleteOperatorBranch: false,
      };
    case "DELETE_OPERATOR_BRANCH_END":
      return {
        ...state,
        requestingDeleteOperatorBranch: false,
        successfulDeleteOperatorBranch: false,
        errorsDeleteOperatorBranch: false,
      };
    case "RESET":
      return initialState;
    default:
      return state;
  }
};

export default companyReducer;
