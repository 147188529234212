import { connect } from 'react-redux'
import AddressCloseConfirmation from './AddressCloseConfirmationComponent'
import {addressCloseConfirmationActions} from "../../redux/reducers/AddressCloseConfirmationReducer";


const mapStateToProps = (state, ownProps) => {
    return {
        addressCloseConfirmations: state.addressCloseConfirmation.addressCloseConfirmations,
        filterFields: state.addressCloseConfirmation.filterFields
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getAll: (payload) => {
            dispatch({
                type: addressCloseConfirmationActions.FECTCH_ALL_CLOSECONFIRMATION,
                value: payload
            })
        },
        setShowModal: (payload) => {
            dispatch({
                type: addressCloseConfirmationActions.HANDLE_SHOW_MODAL,
                value: payload
            })
        },
        setFilterFields: (payload) => {
            dispatch({
                type: addressCloseConfirmationActions.SET_FIELD_FILTER,
                value: payload
            })
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddressCloseConfirmation)