

const initialState = {
    addressNomenclatures:{},
    fetching_address:false,
    requestingFetchAddressNomenclature: false,
    successfulFetchAddressNomenclature: false,
    errorFetchAddressNomenclature: false,
    requestingCreateAddressNomenclature: false,
    successfulCreateAddressNomenclature: false,
    errorsCreateAddressNomenclature: false,
    addressNomenclature: null,
    requestingReadAddressNomenclature: false,
    successfulReadAddressNomenclature: false,
    errorsReadAddressNomenclature: false,
    rowEdited: null,
    requestingDeleteAddressNomenclature: false,
    successfulDeleteAddressNomenclature: false,
    errorsDeleteAddressNomenclature: false,
    rowDeleted: null,
    requestingUpdateAddressNomenclature: false,
    successfulUpdateAddressNomenclature: false,
    errorsUpdateAddressNomenclature: false,
    rowUpdated: null,
}

const addressNomenclatureReducer = (state = initialState, payload) => {
    switch (payload.type) {
        case addressNomenclatureActions.FECTCHIN_ALL_ADDRESSNOMENCLATURE:
            return {
                ...state,
                fetching_address: payload.value
            };
        case addressNomenclatureActions.FETCH_ADDRESSNOMENCLATURE_SUCCESS:
            return {
                ...state,
                errorFetchAddressNomenclature: false,
                requestingFetchAddressNomenclature: false,
                successfulFetchAddressNomenclature: true,
                addressNomenclatures: payload.value,
            };
        case addressNomenclatureActions.FETCH_ADDRESSNOMENCLATURE_ERROR:
            return {
                ...state,
                errorFetchAddressNomenclature: true,
                requestingFetchAddressNomenclature: false,
                successfulFetchAddressNomenclature: false,
            };
        case addressNomenclatureActions.CREATE_ADDRESSNOMENCLATURE_REQUESTING:
            return {
                ...state,
                requestingCreateAddressNomenclature: true,
                successfulCreateAddressNomenclature: false,
                errorsCreateAddressNomenclature: false,
            };
        case addressNomenclatureActions.CREATE_ADDRESSNOMENCLATURE_SUCCESS:
            return {
                ...state,
                errorsCreateAddressNomenclature: false,
                requestingCreateAddressNomenclature: false,
                successfulCreateAddressNomenclature: true,
                addressNomenclature: payload.value,
            };
        case addressNomenclatureActions.CREATE_ADDRESSNOMENCLATURE_ERROR:
            return {
                ...state,
                errorsCreateAddressNomenclature: true,
                requestingCreateAddressNomenclature: false,
                successfulCreateAddressNomenclature: false,
            };
        case addressNomenclatureActions.READ_ADDRESSNOMENCLATURE_REQUESTING:
            return {
                ...state,
                requestingReadAddressNomenclature: true,
                successfulReadAddressNomenclature: false,
                errorsReadAddressNomenclature: false,
            };
        case addressNomenclatureActions.READ_ADDRESSNOMENCLATURE_SUCCESS:
            return {
                ...state,
                errorsReadAddressNomenclature: false,
                requestingReadAddressNomenclature: false,
                successfulReadAddressNomenclature: true,
                rowEdited: payload.value,
            };
        case addressNomenclatureActions.READ_ADDRESSNOMENCLATURE_ERROR:
            return {
                ...state,
                errorsReadAddressNomenclature: true,
                requestingReadAddressNomenclature: false,
                successfulReadAddressNomenclature: false,
            };
        case addressNomenclatureActions.DELETE_ADDRESSNOMENCLATURE_REQUESTING:
            return {
                ...state,
                requestingDeleteAddressNomenclature: true,
                successfulDeleteAddressNomenclature: false,
                errorsDeleteAddressNomenclature: false,
            };
        case addressNomenclatureActions.DELETE_ADDRESSNOMENCLATURE_SUCCESS:
            return {
                ...state,
                errorsDeleteAddressNomenclature: false,
                requestingDeleteAddressNomenclature: false,
                successfulDeleteAddressNomenclature: true,
                rowDeleted: payload.value,
            };
        case addressNomenclatureActions.DELETE_ADDRESSNOMENCLATURE_ERROR:
            return {
                ...state,
                errorsDeleteAddressNomenclature: true,
                requestingDeleteAddressNomenclature: false,
                successfulDeleteAddressNomenclature: false,
            };
        case addressNomenclatureActions.UPDATE_ADDRESSNOMENCLATURE_REQUESTING:
            return {
                ...state,
                requestingUpdateAddressNomenclature: true,
                successfulUpdateAddressNomenclature: false,
                errorsUpdateAddressNomenclature: false,
            };
        case addressNomenclatureActions.UPDATE_ADDRESSNOMENCLATURE_SUCCESS:
            return {
                ...state,
                errorsUpdateAddressNomenclature: false,
                requestingUpdateAddressNomenclature: false,
                successfulUpdateAddressNomenclature: true,
                rowUpdated: payload.value,
            };
        case addressNomenclatureActions.UPDATE_UPDATE_ADDRESSNOMENCLATURE_ERROR:
            return {
                ...state,
                errorsUpdateAddressNomenclature: true,
                requestingUpdateAddressNomenclature: false,
                successfulUpdateAddressNomenclature: false,
            };
        case addressNomenclatureActions.RESET_ADDRESSNOMENCLATURE_FORM:
            return {
                ...state,
                requestingCreateAddressNomenclature: false,
                successfulCreateAddressNomenclature: false,
                errorsCreateAddressNomenclature: false,
                country: null,
                requestingReadAddressNomenclature: false,
                successfulReadAddressNomenclature: false,
                errorsReadAddressNomenclature: false,
                rowEdited: null,
                requestingDeleteAddressNomenclature: false,
                successfulDeleteAddressNomenclature: false,
                errorsDeleteAddressNomenclature: false,
                rowDeleted: null,
                requestingUpdateAddressNomenclature: false,
                successfulUpdateAddressNomenclature: false,
                errorsUpdateAddressNomenclature: false,
                rowUpdated: null,
            };
        default:
            return state;
    }
};

export const addressNomenclatureActions = {
    FECTCHIN_ALL_ADDRESSNOMENCLATURE: 'FECTCHIN_ALL_ADDRESSNOMENCLATURE',
    CREATE_ADDRESSNOMENCLATURE_REQUESTING: 'CREATE_ADDRESSNOMENCLATURE_REQUESTING',
    FETCH_ADDRESSNOMENCLATURE_REQUEST : 'FETCH_ADDRESSNOMENCLATURE_REQUEST',
    FETCH_ADDRESSNOMENCLATURE_SUCCESS : 'FETCH_ADDRESSNOMENCLATURE_SUCCESS',
    CREATE_ADDRESSNOMENCLATURE_REQUEST: 'CREATE_ADDRESSNOMENCLATURE_REQUEST',
    CREATE_ADDRESSNOMENCLATURE_SUCCESS: "CREATE_ADDRESSNOMENCLATURE_SUCCESS",
    FETCH_ADDRESSNOMENCLATURE_ERROR: 'FETCH_ADDRESSNOMENCLATURE_ERROR',
    CREATE_ADDRESSNOMENCLATURE_ERROR: 'CREATE_ADDRESSNOMENCLATURE_ERROR',
    READ_ADDRESSNOMENCLATURE_REQUESTING: 'READ_ADDRESSNOMENCLATURE_REQUESTING',
    READ_ADDRESSNOMENCLATURE_SUCCESS: "READ_ADDRESSNOMENCLATURE_SUCCESS",
    READ_ADDRESSNOMENCLATURE_ERROR: "READ_ADDRESSNOMENCLATURE_ERROR",
    DELETE_ADDRESSNOMENCLATURE_REQUESTING: "DELETE_ADDRESSNOMENCLATURE_REQUESTING",
    DELETE_ADDRESSNOMENCLATURE_SUCCESS: "DELETE_ADDRESSNOMENCLATURE_SUCCESS",
    DELETE_ADDRESSNOMENCLATURE_ERROR: "DELETE_ADDRESSNOMENCLATURE_ERROR",
    UPDATE_ADDRESSNOMENCLATURE_REQUESTING: "UPDATE_ADDRESSNOMENCLATURE_REQUESTING",
    UPDATE_ADDRESSNOMENCLATURE_SUCCESS: 'UPDATE_ADDRESSNOMENCLATURE_SUCCESS',
    UPDATE_UPDATE_ADDRESSNOMENCLATURE_ERROR: 'UPDATE_UPDATE_ADDRESSNOMENCLATURE_ERROR',
    RESET_ADDRESSNOMENCLATURE_FORM: 'RESET_ADDRESSNOMENCLATURE_FORM',
    READ_ADDRESSNOMENCLATURE_REQUEST: 'READ_ADDRESSNOMENCLATURE_REQUEST',
    DELETE_ADDRESSNOMENCLATURE_REQUEST: 'ADDRESSNOMENCLATURE',
    UPDATE_ADDRESSNOMENCLATURE_REQUEST: 'UPDATE_ADDRESSNOMENCLATURE_REQUEST',
    READBYDEPARTMENT_ADDRESSNOMENCLATURE_REQUEST: 'READBYDEPARTMENT_ADDRESSNOMENCLATURE_REQUEST'
}

export default addressNomenclatureReducer;
