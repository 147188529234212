const initialState = {
  requestingFetchExternalMappings: false,
  successfulFetchExternalMappings: false,
  errorFetchExternalMappings: false,
  externalMappings: {},
  requestingCreateExternalMapping: false,
  successfulCreateExternalMapping: false,
  errorsCreateExternalMapping: false,
  externalMapping: null,
  requestingReadExternalMapping: false,
  successfulReadExternalMapping: false,
  errorsReadExternalMapping: false,
  rowEdited: null,
  requestingDeleteExternalMapping: false,
  successfulDeleteExternalMapping: false,
  errorsDeleteExternalMapping: false,
  rowDeleted: null,
  requestingUpdateExternalMapping: false,
  successfulUpdateExternalMapping: false,
  errorsUpdateExternalMapping: false,
  rowUpdated: null,
};

const externalMappingReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_EXTERNALMAPPINGS_REQUESTING":
      return {
        ...state,
        requestingFetchExternalMappings: true,
        successfulFetchExternalMappings: false,
        errorsFetchExternalMappings: false,
      };
    case "FETCH_EXTERNALMAPPING_SUCCESS":
      return {
        ...state,
        errorFetchExternalMappings: false,
        requestingFetchExternalMappings: false,
        successfulFetchExternalMappings: true,
        externalMappings: action.value,
      };
    case "FETCH_EXTERNALMAPPING_ERROR":
      return {
        ...state,
        errorFetchExternalMappings: true,
        requestingFetchExternalMappings: false,
        successfulFetchExternalMappings: false,
      };
    case "CREATE_EXTERNALMAPPING_REQUESTING":
      return {
        ...state,
        requestingCreateExternalMapping: true,
        successfulCreateExternalMapping: false,
        errorsCreateExternalMapping: false,
      };
    case "CREATE_EXTERNALMAPPING_SUCCESS":
      return {
        ...state,
        errorsCreateExternalMapping: false,
        requestingCreateExternalMapping: false,
        successfulCreateExternalMapping: true,
        externalMapping: action.value,
      };
    case "CREATE_EXTERNALMAPPING_ERROR":
      return {
        ...state,
        errorsCreateExternalMapping: true,
        requestingCreateExternalMapping: false,
        successfulCreateExternalMapping: false,
      };
    case "READ_EXTERNALMAPPING_REQUESTING":
      return {
        ...state,
        requestingReadExternalMapping: true,
        successfulReadExternalMapping: false,
        errorsReadExternalMapping: false,
      };
    case "READ_EXTERNALMAPPING_SUCCESS":
      return {
        ...state,
        errorsReadExternalMapping: false,
        requestingReadExternalMapping: false,
        successfulReadExternalMapping: true,
        rowEdited: action.value,
      };
    case "READ_EXTERNALMAPPING_ERROR":
      return {
        ...state,
        errorsReadExternalMapping: true,
        requestingReadExternalMapping: false,
        successfulReadExternalMapping: false,
      };
    case "DELETE_EXTERNALMAPPING_REQUESTING":
      return {
        ...state,
        requestingDeleteExternalMapping: true,
        successfulDeleteExternalMapping: false,
        errorsDeleteExternalMapping: false,
      };
    case "DELETE_EXTERNALMAPPING_SUCCESS":
      return {
        ...state,
        errorsDeleteExternalMapping: false,
        requestingDeleteExternalMapping: false,
        successfulDeleteExternalMapping: true,
        rowDeleted: action.value,
      };
    case "DELETE_EXTERNALMAPPING_ERROR":
      return {
        ...state,
        errorsDeleteExternalMapping: true,
        requestingDeleteExternalMapping: false,
        successfulDeleteExternalMapping: false,
      };
    case "UPDATE_EXTERNALMAPPING_REQUESTING":
      return {
        ...state,
        requestingUpdateExternalMapping: true,
        successfulUpdateExternalMapping: false,
        errorsUpdateExternalMapping: false,
      };
    case "UPDATE_EXTERNALMAPPING_SUCCESS":
      return {
        ...state,
        errorsUpdateExternalMapping: false,
        requestingUpdateExternalMapping: false,
        successfulUpdateExternalMapping: true,
        rowUpdated: action.value,
      };
    case "UPDATE_EXTERNALMAPPING_ERROR":
      return {
        ...state,
        errorsUpdateExternalMapping: true,
        requestingUpdateExternalMapping: false,
        successfulUpdateExternalMapping: false,
      };
    case "RESET_EXTERNALMAPPING_FORM":
      return {
        ...state,
        requestingCreateExternalMapping: false,
        successfulCreateExternalMapping: false,
        errorsCreateExternalMapping: false,
        externalMapping: null,
        requestingReadExternalMapping: false,
        successfulReadExternalMapping: false,
        errorsReadExternalMapping: false,
        rowEdited: null,
        requestingDeleteExternalMapping: false,
        successfulDeleteExternalMapping: false,
        rowDeleted: null,
        requestingUpdateExternalMapping: false,
        successfulUpdateExternalMapping: false,
        errorsUpdateExternalMapping: false,
        rowUpdated: null,
      };
    case "RESET":
      return initialState;
    default:
      return state;
  }
};

export default externalMappingReducer;
