import React, {useEffect, useState} from "react";
import DatePicker from "react-datepicker";
import moment from "moment-timezone";
import { connect } from "react-redux";
import { Col, Row } from "react-bootstrap";

import { ordersLockedActions } from "../../redux/reducers/OrdersLockedReducer";
import UploadOrdersLocked from "./UploadOrdersLocked";
import formatDateWithCustomFormat, { FormatsDate } from "../../utils/formatDate";
import Swal from "sweetalert2";

const FilterComponent = ({ onSubmit, startDateState, endDateState, setEndDateFilter, setStartDateFilter, renderUploadBtn }) => {
    
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange

    useEffect(() => {
        if(!startDateState && !endDateState){
            setDateRange([null, null]);
        }
    }, [startDateState, endDateState])

    const handleChangeDates = (dates) => {
        const [start, end] = dates;
        const startDate = moment(start).tz("America/Bogota").format("YYYY-MM-DD");
        const endDate = moment(end).tz("America/Bogota").format("YYYY-MM-DD");
        const startDateFormatted = formatDateWithCustomFormat(startDate, {
            format: FormatsDate.FULL24,
        })
        const endDateFormatted = formatDateWithCustomFormat(endDate, {
            format: FormatsDate.FULL24,
        })
        setStartDateFilter(startDateFormatted)
        setEndDateFilter(endDateFormatted)

        setDateRange(dates)
    };

    const handleSubmit = () =>{
        if(startDate && !endDate) {
            return Swal.fire('Valide el Filtro!', 'Debe seleccionar un rango de fechas.', 'warning');
        }
        onSubmit()
    }
    

    return (
        <div className="d-flex mb-3 justify-content-between align-items-center">
            <Row>
                <Col md="auto" className='mb-3'>
                    <label
                        className='form-label'>
                        Desde - Hasta
                    </label>
                    <DatePicker
                        startDate={startDate}
                        endDate={endDate}
                        selectsRange
                        onChange={handleChangeDates}
                        dateFormat="yyyy-MM-dd"
                        className={'form-control'}
                        maxDate={new Date()}
                        shouldCloseOnSelect={false}
                        isClearable
                    />
                </Col>
                <Col>
                    <button className='btn btn-primary' onClick={handleSubmit} style={{marginTop: "32px"}}>Buscar</button>  
                </Col>
            </Row>
            {
                renderUploadBtn &&
                    <div className="ml-auto">
                        <UploadOrdersLocked />
                    </div>
            }
        </div>
    )
}
const mapStateToProps = (state) => ({
    startDateState: state.ordersLocked.startDate,
    endDateState: state.ordersLocked.endDate,
})

const mapDispatchToProps = (dispatch) => {
    return {
        setEndDateFilter: (payload) => {
            dispatch({
                type: ordersLockedActions.SET_END_DATE,
                value: payload
            })
        },
        setStartDateFilter: (payload) => {
            dispatch({
                type: ordersLockedActions.SET_START_DATE,
                value: payload
            })
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(FilterComponent);