import React from "react";
import Sidebar from "../../components/Navigation/Sidebar";
import Topbar from "../../components/Navigation/Topbar";
import valores from '../../assets/img/valores.png';
import mision from '../../assets/img/mision.png';
import { Card, Carousel } from 'react-bootstrap';

const styles = {
    carouselImage: {
        maxWidth: '100%',
        maxHeight: '100%',
        objectFit: 'contain'
    }
};

const Welcome = () => {
    return (
        <div>
            <div id="wrapper">
                <Sidebar/>
                <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <Topbar/>
                        <div className="container-fluid">
                            <div className="d-sm-flex align-items-center justify-content-between mb-4">
                                <h1 className="h3 mb-0 text-gray-800">
                                    Bienvenido
                                </h1>
                            </div>                           
                            <Card style={{ backgroundColor: '#ffef2c', borderRadius: '20px', padding: '10px' }}>
                                <Card.Body>
                                    <Carousel>
                                    <Carousel.Item>
                                        <div style={{ backgroundColor: '#FFF', borderRadius: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px' }}>
                                            <img src={mision} alt="Mision" style={styles.carouselImage} />                                         
                                        </div>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <div style={{ backgroundColor: '#FFF', borderRadius: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px' }}>
                                            <img src={valores} alt="Valores" style={styles.carouselImage} />
                                        </div>
                                    </Carousel.Item>
                                    </Carousel>
                                </Card.Body>
                            </Card>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Welcome;
