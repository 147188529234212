import {call, put, takeLatest} from "redux-saga/effects";
import request from "../../utils/request";

function* getAllDepartments(payload) {
  try {
    yield put({
      type: "FETCH_DEPARTMENTS_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/departments?page=${payload.value.page}&search=${payload.value.search}&offset=${payload.value.offset}${payload.value.isActive ? '&isActive=' + payload.value.isActive: '' }`;
    const headers = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "FETCH_DEPARTMENT_SUCCESS",
      value: response,
    });
    yield put({
      type: "HIDE_LOADING",
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed load",
        message: "Failed load Departments",
      },
    });
    yield put({
      type: "FETCH_DEPARTMENT_ERROR",
    });
  }
}

function* postDepartment(payload) {
  try {
    yield put({ type: "CREATE_DEPARTMENT_REQUESTING" });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/departments`;

    const headers = {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(payload.value),
    };

    const response = yield call(request, requestURL, headers);

    yield put({
      type: "CREATE_DEPARTMENT_SUCCESS",
      value: response,
    });
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "success",
        title: "Successful save",
        message: "Successful save Department",
      },
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed save",
        message: "Failed save Department",
      },
    });
    yield put({
      type: "CREATE_DEPARTMENT_ERROR",
    });
  }
}

function* getDepartmentById(payload) {
  try {
    yield put({
      type: "READ_DEPARTMENT_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/departments/${payload.value.id}`;

    const headers = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "READ_DEPARTMENT_SUCCESS",
      value: response,
    });
    yield put({
      type: "HIDE_LOADING",
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "READ_DEPARTMENT_ERROR",
    });
  }
}

function* deleteDepartmentById(payload) {
  try {
    yield put({
      type: "DELETE_DEPARTMENT_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/departments/changeState/${payload.value.idDepartment}`;

    const headers = {
      method: "PATCH",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(payload.value),
    };

    const response = yield call(request, requestURL, headers);

    yield put({
      type: "DELETE_DEPARTMENT_SUCCESS",
      value: response,
    });
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "success",
        title: "Successful disable",
        message: "Successful disable Department",
      },
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed disable",
        message: "Failed disable Department",
      },
    });
    yield put({
      type: "DELETE_DEPARTMENT_ERROR",
    });
  }
}

function* updateDepartment(payload) {
  try {
    yield put({
      type: "UPDATE_DEPARTMENT_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/departments/${payload.value.id}`;

    const headers = {
      method: "PATCH",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(payload.value),
    };

    const response = yield call(request, requestURL, headers);

    yield put({
      type: "UPDATE_DEPARTMENT_SUCCESS",
      value: response,
    });
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "success",
        title: "Successful update",
        message: "Successful update Department",
      },
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed update",
        message: "Failed update Department",
      },
    });
    yield put({
      type: "UPDATE_DEPARTMENT_ERROR",
    });
  }
}

function* getDepartmentsByCountryId(payload) {
  try {
    yield put({
      type: "READBYCOUNTRY_DEPARTMENT_REQUESTING",
    });

    // yield put({
    //   type: "SHOW_LOADING",
    // });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/departments/country/${payload.value.idCountry}`;

    const headers = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "READBYCOUNTRY_DEPARTMENT_SUCCESS",
      value: response,
    });
    // yield put({
    //   type: "HIDE_LOADING",
    // });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "READBYCOUNTRY_DEPARTMENT_ERROR",
    });
  }
}

export function* watchDepartment() {
  yield takeLatest("FETCH_DEPARTMENTS_REQUEST", getAllDepartments);
  yield takeLatest("CREATE_DEPARTMENT_REQUEST", postDepartment);
  yield takeLatest("READ_DEPARTMENT_REQUEST", getDepartmentById);
  yield takeLatest("DELETE_DEPARTMENT_REQUEST", deleteDepartmentById);
  yield takeLatest("UPDATE_DEPARTMENT_REQUEST", updateDepartment);
  yield takeLatest(
    "READBYCOUNTRY_DEPARTMENT_REQUEST",
    getDepartmentsByCountryId
  );
}
