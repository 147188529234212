import { call, put, takeLatest } from "redux-saga/effects";
import request from "../../utils/request";

function* getAllOrders(payload) {
  try {
    yield put({
      type: "FETCH_DSP_ORDERS_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });
    let requestURL = `${process.env.REACT_APP_API_URL}/api/v1/dsp/order?page=${payload.value.page}&offset=${payload.value.offset}&search=${payload.value.search}`;
    if (payload.value.from && payload.value.from != "" && payload.value.to && payload.value.to != "") {
      requestURL = requestURL + `&from=${payload.value.from}&to=${payload.value.to}`;
    }
    if (payload.value.state && payload.value.state != "") {
      requestURL = requestURL + `&state=${payload.value.state}`;
    }
    const headers = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "FETCH_DSP_ORDER_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed load",
        message: "Failed load orders",
      },
    });
    yield put({
      type: "FETCH_DSP_ORDER_ERROR",
    });
  }
}

function* getAllOrdersByUser(payload) {
  try {
    yield put({
      type: "FETCH_ORDERSBYUSER_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${
      process.env.REACT_APP_API_URL
    }/api/v1/orders/ordersByUser?page=${payload.value.page}&search=${
      payload.value.search
    }&offset=${payload.value.offset}${
      payload.value.isActive ? "&isActive=" + payload.value.isActive : ""
    }`;
    const headers = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "HIDE_LOADING",
    });

    yield put({
      type: "FETCH_ORDERBYUSER_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed load",
        message: "Failed load orders by user",
      },
    });
    yield put({
      type: "FETCH_ORDERBYUSER_ERROR",
    });
  }
}
function* getOrderDetailByIdOrder(payload) {
    try {
      yield put({
        type: "READ_DSP_ORDERDETAIL_REQUESTING",
      });
      yield put({
        type: "SHOW_LOADING",
      });
      const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/dsp/order/detail/${payload.value.id}`;
  
      const headers = {
        method: "GET",
        headers: new Headers({
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        }),
      };
      const response = yield call(request, requestURL, headers);
  
      yield put({
        type: "HIDE_LOADING",
      });
  
      yield put({
        type: "READ_DSP_ORDERDETAIL_SUCCESS",
        value: response,
      });
    } catch (error) {
      yield put({
        type: "HIDE_LOADING",
      });
      yield put({
        type: "READ_DSP_ORDERDETAIL_ERROR",
      });
    }
  }

  function* getDSPOrdersTracking(payload) {
    try {
  
      yield put({
        type: "READ_DSPORDER_TRACKING_REQUESTING",
      });
  
      yield put({
        type: "SHOW_LOADING",
      });
  
      const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/dsp/order/track/${payload.value}`;
  
      const headers = {
        method: "GET",
        headers: new Headers({
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        }),
      };
      const response = yield call(request, requestURL, headers);
  
      yield put({
        type: "READ_DSPORDER_TRACKING_SUCCESS",
        value: response,
      });
  
    } catch (error) {
      yield put({
        type: "READ_DSPORDER_TRACKING_ERROR",
      });
    } finally {
      yield put({
        type: "HIDE_LOADING",
      });
    }
  }

export function* watchDSPOrder() {
  yield takeLatest("FETCH_DSP_ORDERS_REQUEST", getAllOrders);
  yield takeLatest("FETCH_ORDERSBYUSER_REQUEST", getAllOrdersByUser);
  yield takeLatest("READ_DSP_ORDERDETAIL_REQUEST", getOrderDetailByIdOrder);
  yield takeLatest("READ_DSPORDER_TRACKING_REQUEST", getDSPOrdersTracking);
}
