import React, { useState, useRef, useEffect, forwardRef } from "react";
import { useForm, FormProvider } from "react-hook-form";
import Sidebar from "../../components/Navigation/Sidebar";
import Topbar from "../../components/Navigation/Topbar";
import Grid from "../../components/Grid";
import { connect } from "react-redux";
import { Button, Modal } from "react-bootstrap";
import { Show } from "../../hooks/Show";
import { useAuth } from "../../hooks/useAuth";
import "react-datepicker/dist/react-datepicker.css";
import InputList, {
  SelectConstEnabled,
} from "../../components/Common/inputList";

const Customer = ({
  reduxGetCustomers,
  customers,
  reduxGetCustomer,
  customer,
  reduxPostCustomer,
  rowEdited,
  reduxDeleteCustomer,
  rowDeleted,
  reduxPatchCustomer,
  rowUpdated,
  reduxResetCustomerForm,
  reduxGetCities,
  cities,
  reduxGetNeighborhoodsByCity,
  neighborhoodsByCity,
  reduxGetDepartmentsByCountry,
  departmentsByCountry,
  reduxGetCitiesByDepartment,
  citiesByDepartment,
  countries,
  reduxGetCountries,
  reduxSetDepartment,
  reduxSetCity,
  reduxSetNeighborhood,
  reduxGetCompanies,
  companies,
  reduxDeleteSoftCustomer,
  rowDeletedSoft,
}) => {
  const methods = useForm({
    defaultValues: {
      indicativeRoad: "",
      roadNumber: "",
      appendixRoad: "",
      crossoverRoad: "",
      crossoverNumber: "",
      appendixCrossingNumber: "",
      orientationCrossingNumber: "",
      insideTypes: "",
      doorNumber: "",
      inside: "",
      city: "",
      neighborhood: "",
      country: "",
      department: "",
    },
  });
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
    setValue,
    getValues,
  } = methods;

  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(10);
  const { session } = useAuth();
  const [show, setShow] = useState(false);
  const [cityFound, setCityFound] = useState("");
  const [countryFound, setCountryFound] = useState("");
  const [departmentFound, setDepartmentFound] = useState("");
  const [neighborhoodFound, setNeighborhoodFound] = useState("");

  const indicativeRoad = useRef({});
  indicativeRoad.current = watch("indicativeRoad", "");

  const roadNumber = useRef({});
  roadNumber.current = watch("roadNumber", "");

  const appendixRoad = useRef({});
  appendixRoad.current = watch("appendixRoad", "");

  const crossoverRoad = useRef({});
  crossoverRoad.current = watch("crossoverRoad", "");

  const crossoverNumber = useRef({});
  crossoverNumber.current = watch("crossoverNumber", "");

  const appendixCrossingNumber = useRef({});
  appendixCrossingNumber.current = watch("appendixCrossingNumber", "");

  const orientationCrossingNumber = useRef({});
  orientationCrossingNumber.current = watch("orientationCrossingNumber", "");

  const insideTypes = useRef({});
  insideTypes.current = watch("insideTypes", "");

  const doorNumber = useRef({});
  doorNumber.current = watch("doorNumber", "");

  const inside = useRef({});
  inside.current = watch("inside", "");

  const city = useRef({});
  city.current = watch("city", "");

  const neighborhood = useRef({});
  neighborhood.current = watch("idNeighborhood", "");

  const country = useRef({});
  country.current = watch("country", "");

  const department = useRef({});
  department.current = watch("department", "");

  const handleClose = () => {
    reduxResetCustomerForm();
    setShow(false);
  };
  const handleShow = () => {
    reduxGetCities({
      page: 1,
      offset: 100000,
      search: "",
      isActive: true,
    });
    reduxGetCountries({
      page: 1,
      offset: 1000,
      search: "",
      isActive: true,
    });
    reduxResetCustomerForm();
    reset();
    setShow(true);
  };
  const [search, setSearch] = useState("");

  const columns = [
    {
      title: "#",
      render: (rowData) => {
        return <span>{rowData.idCustomer}</span>;
      },
      field: 'idCustomer',
      searchable: true
    },
    {
      title: "Nombre",
      render: (rowData) => {
        return (
          <span>
            {rowData.firstName} {rowData.lastName}
          </span>
        );
      },
      field: 'firstName-lastName',
      searchable: true
    },
    {
      title: "Correo",
      render: (rowData) => {
        return <span>{rowData.email}</span>;
      },
      field: 'email',
      searchable: true
    },
    {
      title: "Dirección",
      render: (rowData) => {
        return <span>{rowData.billingAddress}</span>;
      },
      field: 'billingAddress',
      searchable: true
    },
    {
      title: "Teléfono",
      render: (rowData) => {
        return <span>{rowData.phone}</span>;
      },
      field: 'phone',
      searchable: true
    },
    {
      title: "Celular",
      render: (rowData) => {
        return <span>{rowData.cellphone}</span>;
      },
      field: 'cellphone',
      searchable: true
    },
    {
      title: "ID externo",
      render: (rowData) => {
        return <span>{rowData.externalId}</span>;
      },
      field: 'externalId',
      searchable: true
    },
    {
      title: "Opciones",
      render: (rowData) => {
        return (
          <>
            {rowData.isActive ? (
              <>
                <Show when="feature:edit-customer">
                  <button
                    title="Editar"
                    className="btn btn-primary btn-sm  btn-circle mr-2"
                    type="button"
                    onClick={(e) => handleOpen(rowData)}
                  >
                    <i className="fas fa-edit fa-xs"></i>
                  </button>
                </Show>
                <Show when="feature:disabled-customer">
                  <button
                    title="Desactivar"
                    className="btn btn-danger btn-sm btn-circle mr-2"
                    type="button"
                    onClick={(e) => handleDelete(rowData)}
                  >
                    <i className="fas fa-times-circle fa-xs"></i>
                  </button>
                </Show>
                <Show when="feature:delete-customer">
                  <button
                    title="Eliminar"
                    className="btn btn-danger btn-sm btn-circle"
                    type="button"
                    onClick={(e) => handleDeleteSoft(rowData)}
                  >
                    <i className="fas fa-trash-alt fa-xs"></i>
                  </button>
                </Show>
              </>
            ) : (
              <Show when="feature:enabled-customer">
                <button
                  title="Activar"
                  className="btn btn-primary btn-sm  btn-circle mr-2"
                  type="button"
                  onClick={(e) => handleActive(rowData)}
                >
                  <i className="fas fa-check-circle fa-xs"></i>
                </button>
              </Show>
            )}
          </>
        );
      },
    },
  ];

  const handleOpen = (row) => {
    reduxGetCities({
      page: 1,
      offset: 100000,
      search: "",
      isActive: true,
    });
    reduxGetCountries({
      page: 1,
      offset: 1000,
      search: "",
      isActive: true,
    });
    reduxGetCustomer({
      id: row.idCustomer,
    });
    reset();
  };

  const handleActive = (row) => {
    if (!row.isActive) {
      reduxDeleteCustomer(row);
    }
  };

  useEffect(() => {
    if (rowEdited) {
      setShow(true);
      setValue("email", rowEdited.email);
      setValue("firstName", rowEdited.firstName);
      setValue("lastName", rowEdited.lastName);
      setValue("currentAddress", rowEdited.billingAddress);
      setValue("cellphone", rowEdited.cellphone);
      setValue("phone", rowEdited.phone);
      setValue("externalId", rowEdited.externalId);
      setValue("idCompany", rowEdited.idCompany);
      if (rowEdited.boxedAddress) {
        setValue("indicativeRoad", rowEdited.boxedAddress.indicativeRoad);
        setValue("roadNumber", rowEdited.boxedAddress.roadNumber);
        setValue("appendixRoad", rowEdited.boxedAddress.appendixRoad);
        setValue("crossoverRoad", rowEdited.boxedAddress.crossoverRoad);
        setValue("crossoverNumber", rowEdited.boxedAddress.crossoverNumber);
        setValue(
          "appendixCrossingNumber",
          rowEdited.boxedAddress.appendixCrossingNumber
        );
        setValue(
          "orientationCrossingNumber",
          rowEdited.boxedAddress.orientationCrossingNumber
        );
        setValue("doorNumber", rowEdited.boxedAddress.doorNumber);
        setValue("insideTypes", rowEdited.boxedAddress.insideTypes);
        setValue("inside", rowEdited.boxedAddress.inside);
        setValue("country", rowEdited.boxedAddress.country);
        setValue("department", rowEdited.boxedAddress.department);
        setValue("city", rowEdited.boxedAddress.city);
        setValue("neighborhood", rowEdited.boxedAddress.neighborhood);
      }
    }
  }, [rowEdited]);

  const handleDelete = (row) => {
    reduxDeleteCustomer(row);
  };

  useEffect(() => {
    reduxGetCustomers({
      page: 1,
      offset: offset,
      search: "",
    });
    reduxGetCompanies({
      page: 1,
      offset: 10000,
      search: "",
      isActive: true,
    });
  }, []);

  useEffect(() => {
    reduxGetCustomers({
      page: currentPage,
      offset: offset,
      search: search,
    });
  }, [currentPage]);

  const onSubmit = (data) => {
    const newAddress = `${indicativeRoad.current} ${roadNumber.current}${
      appendixRoad.current
    } ${crossoverRoad.current} ${crossoverNumber.current ? "#" : ""}${
      crossoverNumber.current
    }${appendixCrossingNumber.current}${
      orientationCrossingNumber.current ? " " : ""
    }${orientationCrossingNumber.current}${doorNumber.current ? "-" : ""}${
      doorNumber.current
    }${insideTypes.current ? " " + insideTypes.current + " " : ""}${
      inside.current
    }${neighborhood.current ? "," : ""} ${
      neighborhood.current ? neighborhoodFound : ""
    }${city.current ? ", " + cityFound : ""}${
      department.current ? ", " + departmentFound : ""
    }${country.current ? ", " + countryFound : ""}`;
    const objCustomer = {
      externalId: data.externalId ? data.externalId : null,
      phone: data.phone,
      cellphone: data.cellphone,
      boxedAddress: newAddress
        ? {
            indicativeRoad: data.indicativeRoad ? data.indicativeRoad : "",
            roadNumber: data.roadNumber ? data.roadNumber : "",
            appendixRoad: data.appendixRoad ? data.appendixRoad : "",
            crossoverRoad: data.crossoverRoad ? data.crossoverRoad : "",
            crossoverNumber: data.crossoverNumber ? data.crossoverNumber : "",
            appendixCrossingNumber: data.appendixCrossingNumber
              ? data.appendixCrossingNumber
              : "",
            orientationCrossingNumber: data.orientationCrossingNumber
              ? data.orientationCrossingNumber
              : "",
            doorNumber: data.doorNumber ? data.doorNumber : "",
            insideTypes: data.insideTypes ? data.insideTypes : "",
            inside: data.inside ? data.inside : "",
            country: data.country ? data.country : "",
            department: data.department ? data.department : "",
            city: data.city ? data.city : "",
            neighborhood: data.neighborhood ? data.neighborhood : "",
          }
        : null,
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      idCompany: data.idCompany,
      fullName: `${data.firstName} ${data.lastName}`,
    };
    if (rowEdited) {
      reduxPatchCustomer({
        ...objCustomer,
        id: rowEdited.idCustomer,
        billingAddress: newAddress ? newAddress : data.currentAddress,
      });
    } else {
      reduxPostCustomer({
        ...objCustomer,
        billingAddress: newAddress,
      });
    }
    reset();
    reduxResetCustomerForm();
    setShow(false);
  };

  useEffect(() => {
    if (customer || rowUpdated || rowDeleted || rowDeletedSoft) {
      if (
        (customer && customer.message == undefined) ||
        (rowUpdated && rowUpdated.message == undefined) ||
        (rowDeletedSoft && rowDeletedSoft.message == undefined) ||
        rowDeleted
      ) {
        reduxGetCustomers({
          page: currentPage,
          offset: offset,
          search: search,
        });
        reduxResetCustomerForm();
      }
    }
  }, [customer, rowUpdated, rowDeleted, rowDeletedSoft]);

  useEffect(() => {
    if (
      country.current != "" &&
      country.current != undefined &&
      country.current != null
    ) {
      setValue("department", "");
      setValue("city", "");
      setValue("neighborhood", "");
      reduxSetDepartment();
      reduxSetCity();
      reduxSetNeighborhood();
      const countrySelected =
        countries &&
        Object.keys(countries).length > 0 &&
        countries.items.find((value) => value.idCountry == country.current);
      setCountryFound(countrySelected ? countrySelected.description : "");
      reduxGetDepartmentsByCountry({
        idCountry: country.current,
      });
    }
  }, [country.current]);

  useEffect(() => {
    if (department.current != "" && department.current != undefined) {
      setValue("city", "");
      setValue("neighborhood", "");
      reduxSetCity();
      reduxSetNeighborhood();
      const departmentSelected =
        departmentsByCountry &&
        Object.keys(departmentsByCountry).length > 0 &&
        departmentsByCountry.find(
          (value) => value.idDepartment == department.current
        );
      setDepartmentFound(
        departmentSelected ? departmentSelected.description : ""
      );
      reduxGetCitiesByDepartment({
        idDepartment: department.current,
      });
    }
  }, [department.current]);

  useEffect(() => {
    if (city.current != "" && city.current != undefined) {
      setValue("neighborhood", "");
      reduxSetNeighborhood();
      const citySelected =
        citiesByDepartment &&
        Object.keys(citiesByDepartment).length > 0 &&
        citiesByDepartment.find((value) => value.idCity == city.current);
      setCityFound(citySelected ? citySelected.description : "");
      reduxGetNeighborhoodsByCity({
        idCity: city.current,
      });
    }
  }, [city.current]);

  useEffect(() => {
    if (neighborhood.current != "" && neighborhood.current != undefined) {
      const neighborhoodSelected =
        neighborhoodsByCity &&
        Object.keys(neighborhoodsByCity).length > 0 &&
        neighborhoodsByCity.find(
          (value) => value.idNeighborhood == neighborhood.current
        );
      setNeighborhoodFound(
        neighborhoodSelected ? neighborhoodSelected.description : ""
      );
    }
  }, [neighborhood.current]);

  useEffect(() => {
    setValue(
      "billingAddress",
      `${indicativeRoad.current} ${roadNumber.current}${appendixRoad.current} ${
        crossoverRoad.current
      } ${crossoverNumber.current ? "#" : ""}${crossoverNumber.current}${
        appendixCrossingNumber.current
      }${orientationCrossingNumber.current ? " " : ""}${
        orientationCrossingNumber.current
      }${doorNumber.current ? "-" : ""}${doorNumber.current}${
        insideTypes.current ? " " + insideTypes.current + " " : ""
      }${inside.current}${neighborhood.current ? "," : ""} ${
        neighborhood.current ? neighborhoodFound : ""
      }${city.current ? ", " + cityFound : ""}${
        department.current ? ", " + departmentFound : ""
      }${country.current ? ", " + countryFound : ""}`
    );
  }, [
    indicativeRoad.current,
    roadNumber.current,
    appendixRoad.current,
    crossoverRoad.current,
    crossoverNumber.current,
    appendixCrossingNumber.current,
    orientationCrossingNumber.current,
    doorNumber.current,
    insideTypes.current,
    neighborhood.current,
    city.current,
    department.current,
    country.current,
    cityFound,
    departmentFound,
    countryFound,
    neighborhoodFound,
  ]);

  const handleDeleteSoft = (row) => {
    reduxDeleteSoftCustomer(row);
  };

  return (
    <div>
      <div id="wrapper">
        <Sidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Topbar />
            <div className="container-fluid">
              <div className="d-sm-flex align-items-center mb-1">
                <h1 className="h3 mb-0 text-gray-800 mr-3">Clientes</h1>
                <Show when="feature:create-customer">
                  <button
                    className="btn btn-primary btn-circle"
                    type="button"
                    onClick={handleShow}
                  >
                    <i className="fas fa-plus fa-sm"></i>
                  </button>
                </Show>
              </div>
              <p className="mb-4">Módulo de Administración de clientes</p>
              <div className="card shadow mb-4">
                <div className="card-header py-3">
                  <h6 className="m-0 font-weight-bold text-primary">
                    Listado de Clientes
                  </h6>
                </div>
                <div className="card-body">
                  <Grid
                    cols={columns}
                    data={
                      customers && Object.keys(customers).length > 0
                        ? customers.items
                        : []
                    }
                    page={
                      customers && Object.keys(customers).length > 0
                        ? Number(customers.page)
                        : currentPage
                    }
                    pages={
                      customers && Object.keys(customers).length > 0
                        ? Number(customers.totalPages)
                        : 0
                    }
                    total={
                      customers && customers.hasOwnProperty("total")
                        ? customers.total
                        : 0
                    }
                    onChangePage={(page) => setCurrentPage(page)}
                    onChangeRange={(value) => {
                      setOffset(value);
                      reduxGetCustomers({
                        page: 1,
                        offset: value,
                        search: search,
                      });
                    }}
                    offset={offset}
                    defaultValue={search}
                    onChangeSearch={(value) => {
                      setSearch(value);
                      reduxGetCustomers({
                        page: 1,
                        offset: offset,
                        search: value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Modal
          size="xl"
          show={show}
          onHide={handleClose}
          onEscapeKeyDown={handleClose}
          backdrop="static"
          keyboard={true}
        >
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Modal.Header closeButton>
                <Modal.Title>
                  {rowEdited ? "Editar" : "Nuevo"} Cliente
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="form-group col s12 m4">
                    <label htmlFor="firstName" className="form-label">
                      Nombre
                    </label>
                    <input
                      id="firstName"
                      type="text"
                      className={`form-control form-control-user ${
                        errors.firstName && "is-invalid"
                      }`}
                      {...register("firstName", { required: true })}
                    />
                    {errors.firstName && (
                      <span className="invalid-feedback">
                        El nombre es requerido
                      </span>
                    )}
                  </div>
                  <div className="form-group col s12 m4">
                    <label htmlFor="lastName" className="form-label">
                      Apellidos
                    </label>
                    <input
                      id="lastName"
                      type="text"
                      className={`form-control form-control-user ${
                        errors.lastName && "is-invalid"
                      }`}
                      {...register("lastName", { required: true })}
                    />
                    {errors.lastName && (
                      <span className="invalid-feedback">
                        Los apellidos son requerido
                      </span>
                    )}
                  </div>
                  <div className="col s12 m4">
                    <div className="form-group">
                      <label htmlFor="email" className="form-label">
                        Correo
                      </label>
                      <input
                        id="email"
                        type="text"
                        className={`form-control`}
                        {...register("email")}
                      />
                    </div>
                  </div>
                  {rowEdited && (
                    <div className="col s12 m4">
                      <div className="form-group">
                        <label htmlFor="currentAddress" className="form-label">
                          Dirección actual
                        </label>
                        <input
                          id="currentAddress"
                          readOnly="true"
                          type="text"
                          className={`form-control`}
                          {...register("currentAddress")}
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div
                  style={{
                    border: "1px solid #e3e6f0",
                    borderRadius: "0.35rem ",
                    margin: "20px 0px 10px 0px",
                  }}
                >
                  <div
                    className="row"
                    style={{ marginLeft: "5px", marginRight: "5px" }}
                  >
                    <div className="col s12 m6">
                      <div className="form-group">
                        <label htmlFor="billingAddress" className="form-label">
                          Generador de direcciones
                        </label>
                        <input
                          {...register("billingAddress")}
                          id="billingAddress"
                          type="text"
                          className={`form-control`}
                          disabled={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row" style={{ marginLeft: "5px" }}>
                    <div className="col s12 m12">
                      <div className="mb-2">
                        <h6 className="m-0 font-weight-bold text-primary">
                          Vía
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div
                    className="row"
                    style={{
                      border: "1px solid #e3e6f0",
                      borderRadius: "0.35rem ",
                      marginLeft: "5px",
                      marginRight: "5px",
                    }}
                  >
                    <div className="form-group col-md-3">
                      <label htmlFor="indicativeRoad" className="form-label">
                        Indicativo de la vía
                      </label>
                      <InputList
                        id={"indicativeRoad"}
                        type={SelectConstEnabled._addressRoadType}
                      ></InputList>
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="roadNumber" className="form-label">
                        Numero de la vía
                      </label>
                      <input
                        {...register("roadNumber")}
                        id="roadNumber"
                        type="text"
                        className={`form-control`}
                      />
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="appendixRoad" className="form-label">
                        Apéndice
                      </label>
                      <InputList
                        id={"appendixRoad"}
                        type={SelectConstEnabled._addressNomenclature}
                      ></InputList>
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="crossoverRoad" className="form-label">
                        Orientación
                      </label>
                      <InputList
                        id={"crossoverRoad"}
                        type={SelectConstEnabled._addressCardinal}
                      ></InputList>
                    </div>
                  </div>
                  <div
                    className="row"
                    style={{ marginLeft: "5px", marginTop: "5px" }}
                  >
                    <div className="col s12 m12">
                      <div className="mb-2">
                        <h6 className="m-0 font-weight-bold text-primary">
                          Cruce
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div
                    className="row"
                    style={{
                      border: "1px solid #e3e6f0",
                      borderRadius: "0.35rem ",
                      marginLeft: "5px",
                      marginRight: "5px",
                    }}
                  >
                    <div className="form-group col-md-3">
                      <label htmlFor="crossoverNumber" className="form-label">
                        Numero de cruce
                      </label>
                      <input
                        {...register("crossoverNumber")}
                        id="crossoverNumber"
                        type="text"
                        className={`form-control`}
                      />
                    </div>
                    <div className="form-group col-md-3">
                      <label
                        htmlFor="appendixCrossingNumber"
                        className="form-label"
                      >
                        Apéndice
                      </label>
                      <InputList
                        id={"appendixCrossingNumber"}
                        type={SelectConstEnabled._addressNomenclature}
                      ></InputList>
                    </div>
                    <div className="form-group col-md-3">
                      <label
                        htmlFor="orientationCrossingNumber"
                        className="form-label"
                      >
                        Orientación
                      </label>
                      <InputList
                        id={"orientationCrossingNumber"}
                        type={SelectConstEnabled._addressCardinal}
                      ></InputList>
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="doorNumber" className="form-label">
                        Numero de la puerta
                      </label>
                      <input
                        {...register("doorNumber")}
                        id="doorNumber"
                        type="text"
                        className={`form-control`}
                      />
                    </div>
                  </div>
                  <div
                    className="row"
                    style={{
                      marginLeft: "5px",
                      marginTop: "5px",
                      marginRight: "5px",
                    }}
                  >
                    <div className="form-group col-md-3">
                      <label htmlFor="insideTypes" className="form-label">
                        Tipos de Interior
                      </label>
                      <InputList
                        id={"insideTypes"}
                        type={SelectConstEnabled._addressInsideType}
                      ></InputList>
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="inside" className="form-label">
                        Interior
                      </label>
                      <input
                        {...register("inside")}
                        id="inside"
                        type="text"
                        className={`form-control`}
                      />
                    </div>
                  </div>
                  <div
                    className="row"
                    style={{
                      marginLeft: "5px",
                      marginRight: "5px",
                    }}
                  >
                    <div className="form-group col-md-3">
                      <label htmlFor="country" className="form-label">
                        Pais
                      </label>
                      <select
                        {...register("country")}
                        id="country"
                        className={`custom-select`}
                      >
                        <option value={""}>Seleccionar…</option>
                        {countries &&
                          Object.keys(countries).length > 0 &&
                          countries.items.map((ele, key) => (
                            <option key={key} value={ele.idCountry}>
                              {ele.description}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="department" className="form-label">
                        Departamento
                      </label>
                      <select
                        {...register("department")}
                        id="department"
                        className={`custom-select`}
                      >
                        <option value={""}>Seleccionar…</option>
                        {departmentsByCountry &&
                          Object.keys(departmentsByCountry).length > 0 &&
                          departmentsByCountry.map((ele, key) => (
                            <option key={key} value={ele.idDepartment}>
                              {ele.description}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="city" className="form-label">
                        Ciudad
                      </label>
                      <select
                        {...register("city")}
                        id="city"
                        className={`custom-select`}
                      >
                        <option value={""}>Seleccionar…</option>
                        {citiesByDepartment &&
                          Object.keys(citiesByDepartment).length > 0 &&
                          citiesByDepartment.map((ele, key) => (
                            <option key={key} value={ele.idCity}>
                              {ele.description}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="neighborhood" className="form-label">
                        Barrio
                      </label>
                      <select
                        {...register("neighborhood")}
                        id="neighborhood"
                        className={`custom-select`}
                      >
                        <option value={""}>Seleccionar…</option>
                        {neighborhoodsByCity &&
                          Object.keys(neighborhoodsByCity).length > 0 &&
                          neighborhoodsByCity.map((ele, key) => (
                            <option key={key} value={ele.idNeighborhood}>
                              {ele.description}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col s12 m4">
                    <div className="form-group">
                      <label htmlFor="phone" className="form-label">
                        Teléfono
                      </label>
                      <input
                        id="phone"
                        type="text"
                        className={`form-control form-control-user ${
                          errors.phone && "is-invalid"
                        }`}
                        {...register("phone", { required: true })}
                      />
                      {errors.phone && (
                        <span className="invalid-feedback">
                          El teléfono es requerido
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col s12 m4">
                    <div className="form-group">
                      <label htmlFor="cellphone" className="form-label">
                        Celular
                      </label>
                      <input
                        id="cellphone"
                        type="text"
                        className={`form-control`}
                        {...register("cellphone")}
                      />
                    </div>
                  </div>
                  <div className="col s12 m4">
                    <div className="form-group">
                      <label htmlFor="externalId" className="form-label">
                        ID externo
                      </label>
                      <input
                        id="externalId"
                        type="number"
                        className={`form-control form-control-user ${
                          errors.externalId && "is-invalid"
                        }`}
                        {...register("externalId")}
                      />
                    </div>
                  </div>
                  {session && session.role.idCompany == null && (
                    <div className="col s12 m5">
                      <div className="form-group">
                        <label htmlFor="idCompany" className="form-label">
                          Empresa
                        </label>
                        <select
                          {...register("idCompany", {
                            required: true,
                          })}
                          id="idCompany"
                          className={`custom-select ${
                            errors.idCompany && "is-invalid"
                          }`}
                        >
                          <option value={""}>Seleccionar…</option>
                          {companies &&
                            Object.keys(companies).length > 0 &&
                            companies.items
                              .filter((f) => f.isActive == true)
                              .map((ele, key) => (
                                <option key={key} value={ele.idCompany}>
                                  {ele.description}
                                </option>
                              ))}
                        </select>
                        {errors.idCompany && (
                          <span className="invalid-feedback">
                            La empresa es requerida
                          </span>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Cancelar
                </Button>
                <Button variant="primary" type="submit">
                  Guardar
                </Button>
              </Modal.Footer>
            </form>
          </FormProvider>
        </Modal>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    customers: state.customerState.customers,
    customer: state.customerState.customer,
    rowEdited: state.customerState.rowEdited,
    rowDeleted: state.customerState.rowDeleted,
    rowUpdated: state.customerState.rowUpdated,
    cities: state.cityState.cities,
    neighborhoodsByCity: state.neighborhoodState.neighborhoodsByCity,
    countries: state.countryState.countries,
    departmentsByCountry: state.departmentState.departmentsByCountry,
    citiesByDepartment: state.cityState.citiesByDepartment,
    companies: state.companyState.companies,
    rowDeletedSoft: state.customerState.rowDeletedSoft,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    reduxGetCustomers: (payload) =>
      dispatch({
        type: "FETCH_CUSTOMERS_REQUEST",
        value: payload,
      }),
    reduxPostCustomer: (payload) =>
      dispatch({
        type: "CREATE_CUSTOMER_REQUEST",
        value: payload,
      }),
    reduxGetCustomer: (payload) =>
      dispatch({
        type: "READ_CUSTOMER_REQUEST",
        value: payload,
      }),
    reduxDeleteCustomer: (payload) =>
      dispatch({
        type: "DELETE_CUSTOMER_REQUEST",
        value: payload,
      }),
    reduxPatchCustomer: (payload) =>
      dispatch({
        type: "UPDATE_CUSTOMER_REQUEST",
        value: payload,
      }),
    reduxResetCustomerForm: () =>
      dispatch({
        type: "RESET_CUSTOMER_FORM",
      }),
    reduxGetCities: (payload) =>
      dispatch({
        type: "FETCH_CITIES_REQUEST",
        value: payload,
      }),
    reduxGetCountries: (payload) =>
      dispatch({
        type: "FETCH_COUNTRIES_REQUEST",
        value: payload,
      }),
    reduxGetDepartmentsByCountry: (payload) =>
      dispatch({
        type: "READBYCOUNTRY_DEPARTMENT_REQUEST",
        value: payload,
      }),
    reduxGetCitiesByDepartment: (payload) =>
      dispatch({
        type: "READBYDEPARTMENT_CITY_REQUEST",
        value: payload,
      }),
    reduxGetNeighborhoodsByCity: (payload) =>
      dispatch({
        type: "READBYCITY_NEIGHBORHOOD_REQUEST",
        value: payload,
      }),
    reduxSetDepartment: (payload) =>
      dispatch({
        type: "RESET_BYCOUNTRY_DEPARTMENT",
        value: payload,
      }),
    reduxSetCity: (payload) =>
      dispatch({
        type: "RESET_BYDEPARTMENT_CITY",
        value: payload,
      }),
    reduxSetNeighborhood: (payload) =>
      dispatch({
        type: "RESET_BYCITY_NEIGHBORHOOD",
        value: payload,
      }),
    reduxGetCompanies: (payload) =>
      dispatch({
        type: "FETCH_COMPANIES_REQUEST",
        value: payload,
      }),
    reduxDeleteSoftCustomer: (payload) =>
      dispatch({
        type: "DELETE_SOFT_CUSTOMER_REQUEST",
        value: payload,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Customer);
