const initialState = {
  requestingFetchCategories: false,
  successfulFetchCategories: false,
  errorFetchCategories: false,
  categories: {},
  requestingCreateCategory: false,
  successfulCreateCategory: false,
  errorsCreateCategory: false,
  category: null,
  requestingReadCategory: false,
  successfulReadCategory: false,
  errorsReadCategory: false,
  rowEdited: null,
  requestingDeleteCategory: false,
  successfulDeleteCategory: false,
  errorsDeleteCategory: false,
  rowDeleted: null,
  requestingUpdateCategory: false,
  successfulUpdateCategory: false,
  errorsUpdateCategory: false,
  rowUpdated: null,
  requestingDeleteSoftCategory: false,
  successfulDeleteSoftCategory: false,
  errorsDeleteSoftCategory: false,
  rowDeletedSoft: null,
};

const categoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_CATEGORIES_REQUESTING":
      return {
        ...state,
        requestingFetchCategories: true,
        successfulFetchCategories: false,
        errorsFetchCategories: false,
      };
    case "FETCH_CATEGORY_SUCCESS":
      return {
        ...state,
        errorFetchCategories: false,
        requestingFetchCategories: false,
        successfulFetchCategories: true,
        categories: action.value,
      };
    case "FETCH_CATEGORY_ERROR":
      return {
        ...state,
        errorFetchCategories: true,
        requestingFetchCategories: false,
        successfulFetchCategories: false,
      };
    case "CREATE_CATEGORY_REQUESTING":
      return {
        ...state,
        requestingCreateCategory: true,
        successfulCreateCategory: false,
        errorsCreateCategory: false,
      };
    case "CREATE_CATEGORY_SUCCESS":
      return {
        ...state,
        errorsCreateCategory: false,
        requestingCreateCategory: false,
        successfulCreateCategory: true,
        category: action.value,
      };
    case "CREATE_CATEGORY_ERROR":
      return {
        ...state,
        errorsCreateCategory: true,
        requestingCreateCategory: false,
        successfulCreateCategory: false,
      };
    case "READ_CATEGORY_REQUESTING":
      return {
        ...state,
        requestingReadCategory: true,
        successfulReadCategory: false,
        errorsReadCategory: false,
      };
    case "READ_CATEGORY_SUCCESS":
      return {
        ...state,
        errorsReadCategory: false,
        requestingReadCategory: false,
        successfulReadCategory: true,
        rowEdited: action.value,
      };
    case "READ_CATEGORY_ERROR":
      return {
        ...state,
        errorsReadCategory: true,
        requestingReadCategory: false,
        successfulReadCategory: false,
      };
    case "DELETE_CATEGORY_REQUESTING":
      return {
        ...state,
        requestingDeleteCategory: true,
        successfulDeleteCategory: false,
        errorsDeleteCategory: false,
      };
    case "DELETE_CATEGORY_SUCCESS":
      return {
        ...state,
        errorsDeleteCategory: false,
        requestingDeleteCategory: false,
        successfulDeleteCategory: true,
        rowDeleted: action.value,
      };
    case "DELETE_CATEGORY_ERROR":
      return {
        ...state,
        errorsDeleteCategory: true,
        requestingDeleteCategory: false,
        successfulDeleteCategory: false,
      };
    case "UPDATE_CATEGORY_REQUESTING":
      return {
        ...state,
        requestingUpdateCategory: true,
        successfulUpdateCategory: false,
        errorsUpdateCategory: false,
      };
    case "UPDATE_CATEGORY_SUCCESS":
      return {
        ...state,
        errorsUpdateCategory: false,
        requestingUpdateCategory: false,
        successfulUpdateCategory: true,
        rowUpdated: action.value,
      };
    case "UPDATE_CATEGORY_ERROR":
      return {
        ...state,
        errorsUpdateCategory: true,
        requestingUpdateCategory: false,
        successfulUpdateCategory: false,
      };
    case "DELETE_SOFT_CATEGORY_REQUESTING":
      return {
        ...state,
        requestingDeleteSoftCategory: true,
        successfulDeleteSoftCategory: false,
        errorsDeleteSoftCategory: false,
      };
    case "DELETE_SOFT_CATEGORY_SUCCESS":
      return {
        ...state,
        errorsDeleteSoftCategory: false,
        requestingDeleteSoftCategory: false,
        successfulDeleteSoftCategory: true,
        rowDeletedSoft: action.value,
      };
    case "DELETE_SOFT_CATEGORY_ERROR":
      return {
        ...state,
        errorsDeleteSoftCategory: true,
        requestingDeleteSoftCategory: false,
        successfulDeleteSoftCategory: false,
      };
    case "RESET_CATEGORY_FORM":
      return {
        ...state,
        requestingCreateCategory: false,
        successfulCreateCategory: false,
        errorsCreateCategory: false,
        category: null,
        requestingReadCategory: false,
        successfulReadCategory: false,
        errorsReadCategory: false,
        rowEdited: null,
        requestingDeleteCategory: false,
        successfulDeleteCategory: false,
        rowDeleted: null,
        requestingUpdateCategory: false,
        successfulUpdateCategory: false,
        errorsUpdateCategory: false,
        rowUpdated: null,
        requestingDeleteSoftCategory: false,
        successfulDeleteSoftCategory: false,
        errorsDeleteSoftCategory: false,
        rowDeletedSoft: null,
      };
    case "RESET":
      return initialState;
    default:
      return state;
  }
};

export default categoryReducer;
